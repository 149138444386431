import * as React from 'react'

// Components
import { View, Platform, Text, Image } from 'react-native'
import Container from '@components/icpFormContainer/icpFormContainer'
import { TouchableItemFeedback } from '@components/touchable/Touchable'
import ImagesModal from '@components/imagesModal/ImagesModal'

// Store
import useI18n from '@store/i18n/useI18n'

// Style
import styled from '@styles/index'

interface Props {
    values: SiteIcp
}

export default (props: Props) => {
    const { values } = props
    const i18n = useI18n()

    const [imagesToDisplay, setImagesToDisplay] = React.useState<string[]>([])

    const getHandlersString = () => {
        const handlers = []
        if (values.aprrMarkingHandler) {
            handlers.push(i18n.t('components.siteIcp.values.aprr'))
        }
        if (values.otherMarkingHandler) {
            handlers.push(values.otherMarkingHandler)
        }
        return handlers.join(' / ')
    }

    return (
        <Container title={i18n.t('components.siteIcp.title')}>
            <View>
                <ImagesModal
                    visible={imagesToDisplay.length > 0}
                    onRequestClose={() => setImagesToDisplay([])}
                    initImage={0}
                    images={imagesToDisplay}
                />
                <Section zIndex={0}>
                    <SectionTitle>{i18n.t('components.siteIcp.sectionsTitles.marking')}</SectionTitle>
                    <FieldContainer>
                        <LabelContainer>
                            <Label>{i18n.t('components.siteIcp.fields.needsMarking')}</Label>
                        </LabelContainer>
                        <ValueContainer>
                            <Value>
                                {values.needsMarking
                                    ? i18n.t('components.siteIcp.values.yes', { items: getHandlersString() })
                                    : i18n.t('components.siteIcp.values.no')}
                            </Value>
                        </ValueContainer>
                        {values.markingDescription && (
                            <MarkingContainer>
                                <LabelContainer>
                                    <Label>{i18n.t('components.siteIcp.fields.markingDescription')}</Label>
                                </LabelContainer>
                                <ValueContainer>
                                    <Value>{values.markingDescription}</Value>
                                </ValueContainer>
                            </MarkingContainer>
                        )}
                        {!!values.markingDrawing && (
                            <TouchableItemFeedback
                                onPress={() => {
                                    if (!!values.markingDrawing) {
                                        setImagesToDisplay([values.markingDrawing])
                                    }
                                }}
                            >
                                <ImageContainer>
                                    <ImageValue source={{ uri: values.markingDrawing }} />
                                </ImageContainer>
                            </TouchableItemFeedback>
                        )}
                    </FieldContainer>
                </Section>
                <Section zIndex={0}>
                    <SectionTitle>{i18n.t('components.siteIcp.sectionsTitles.installation')}</SectionTitle>
                    <FieldContainer>
                        <LabelContainer>
                            <Label>{i18n.t('components.siteIcp.fields.installationsDescription')}</Label>
                        </LabelContainer>
                        <ValueContainer>
                            <Value>
                                {values.installationsDescription
                                    ? values.installationsDescription
                                    : i18n.t('components.siteIcp.fields.noInstallationsDescription')}
                            </Value>
                        </ValueContainer>
                    </FieldContainer>
                    <FieldContainer>
                        <LabelContainer>
                            <Label>{i18n.t('components.siteIcp.fields.disposalDescription')}</Label>
                        </LabelContainer>
                        <ValueContainer>
                            <Value>
                                {values.disposalDescription
                                    ? values.disposalDescription
                                    : i18n.t('components.siteIcp.fields.noDisposalDescription')}
                            </Value>
                        </ValueContainer>
                    </FieldContainer>
                </Section>
            </View>
        </Container>
    )
}

const Section = styled(View)<{ zIndex: number }>`
    z-index: ${props => props.zIndex};
    margin-bottom: ${Platform.OS === 'web' ? '24px' : '15px'};
`
const SectionTitle = styled(Text)`
    ${props => props.theme.fonts.icpFormTitle}
    font-family: Avenir-Book;
    margin-bottom: 20px;
`
const FieldContainer = styled(View)`
    margin-bottom: 24px;
`

const Value = styled(Text)`
    ${props => props.theme.fonts.inputText}
    color:  ${props => props.theme.colors.inputText};
    
`

const Label = styled(Text)<{ color?: string; minHeight?: number }>`
    ${props => props.theme.fonts.fieldLabel}
    ${props => props.color && `color: ${props.color}`}
    font-size: ${props => props.minHeight && props.minHeight * 0.5}px;
`

const LabelContainer = styled(View)`
    margin-bottom: 5px;
`

const ValueContainer = styled(View)`
    margin-bottom: 5px;
`

const MarkingContainer = styled(View)``
const ImageContainer = styled(View)`
    justify-content: center;
    align-items: center;
    height: 120px;
    width: 240px;
    margin-right: 24px;
    background-color: ${props => props.theme.colors.signaturePadBackground};
`
const ImageValue = styled(Image)`
    height: 120px;
    width: 240px;
`
