import * as React from 'react'

// Components
import { View, Text, Platform } from 'react-native'
import { TouchableItemFeedback } from '@components/touchable/Touchable'
import RequiredIndicator from '@components/requiredIndicator/RequiredIndicator'
import AnswerQuestionSection from '@components/answerQuestionSection/answerQuestionSection'

// Store
import useI18n from '@store/i18n/useI18n'

// Style
import styled from '@styles/index'

interface Props {
    rubrique: RubriqueModele
    value: RubriqueForm
    errors?: any
    onRubriqueChange: (indexQuestion: number, newAnswers: any[]) => void
    index: number
    redacteurs?: UserRedacteur[]
    disabled?: boolean
}

export default (props: Props) => {
    const { rubrique, errors, value, onRubriqueChange, index, redacteurs, disabled } = props

    const i18n = useI18n()

    const [rubriqueState, setRubriqueState] = React.useState<'opened' | 'closed'>('opened')

    return (
        <Container zIndex={-index}>
            <ListTitleContainer>
                <ListTitle numberOfLines={1} ellipsizeMode={'tail'}>
                    {rubrique.nom}
                </ListTitle>
                <Flexbox>
                    {!!errors && Object.keys(errors).length > 0 && (
                        <RequiredIndicator fontSize={Platform.OS === 'web' ? '20px' : '18px'} />
                    )}
                </Flexbox>
                <TouchableItemFeedback
                    onPress={() => setRubriqueState(rubriqueState === 'opened' ? 'closed' : 'opened')}
                >
                    <Action>
                        {i18n.t(
                            `components.answerRubriqueSection.${rubriqueState === 'opened' ? 'reduce' : 'seeMore'}`,
                        )}
                    </Action>
                </TouchableItemFeedback>
            </ListTitleContainer>
            {rubriqueState === 'opened' && (
                <RubriqueContainer>
                    {!!rubrique.description && <Description>{rubrique.description}</Description>}
                    {rubrique.questions.map((question, index) => (
                        <AnswerQuestionSection
                            key={`question${index}`}
                            question={question}
                            index={index}
                            isLast={index === rubrique.questions.length - 1}
                            values={value.questions[index].answer}
                            onAnswerChange={newAnswers => onRubriqueChange(index, newAnswers)}
                            errors={
                                !!errors && !!errors.questions && errors.questions.length > 0
                                    ? errors.questions[index].answer
                                    : undefined
                            }
                            redacteurs={redacteurs}
                            disabled={disabled}
                        />
                    ))}
                </RubriqueContainer>
            )}
        </Container>
    )
}
const Container = styled(View)<{ zIndex: number }>`
    z-index: ${props => props.zIndex};
`

const Flexbox = styled(View)`
    flex: 1;
`
const RubriqueContainer = styled(View)<{ zIndex?: number }>`
    padding-left: ${Platform.OS === 'web' ? '24' : '10'}px;
    padding-right: ${Platform.OS === 'web' ? '24' : '10'}px;
    padding-top: ${Platform.OS === 'web' ? '24' : '10'}px;
    padding-bottom: ${Platform.OS === 'web' ? '24' : '10'}px;
    background-color: ${props => props.theme.colors.rubriqueBackground};
    ${props => props.zIndex && `z-index: ${props.zIndex};`}
`
const ListTitleContainer = styled(View)`
    background-color: ${props => props.theme.colors.rubriqueBackground};
    flex-direction: row;
    height: ${props => props.theme.constants.modelesListTitleContainerHeight};
    align-items: center;
    padding-left: ${Platform.OS === 'web' ? '30' : '15'}px;
    padding-right: ${Platform.OS === 'web' ? '30' : '15'}px;
    margin-top: ${Platform.OS === 'web' ? '24' : '12'}px;

    border-bottom-color: ${props => props.theme.colors.homeListBorderColor};
    border-bottom-width: 2px;
`
const ListTitle = styled(Text)`
    ${props => props.theme.fonts.homeListTitle}
    text-transform: uppercase;
`
const Action = styled(Text)`
    ${props => props.theme.fonts.homeActionLink}
    text-decoration: underline;
    text-decoration-color: ${props => props.theme.fonts.homeActionLink.color};
`
const Description = styled(Text)`
    ${props => props.theme.fonts.inputText}
    font-size: 14px;
    margin-bottom: ${Platform.OS === 'web' ? '24' : '10'}px;
`
