import * as React from 'react'

// Components
import { View, Text, Image, TouchableOpacity } from 'react-native'
import TextInput from '@components/textInput/TextInput'
import { Formik, FormikErrors } from 'formik'
import Container from '@components/icpFormContainer/icpFormContainer'
import Button from '@components/button/Button'
import Modal from '@components/modal/Modal'
import { TouchableItemFeedback } from '@components/touchable/Touchable'
import { Tooltip, TooltipStyles } from 'react-lightweight-tooltip'
import Icon from '@components/icon/Icon'

// Utils
import { produce } from 'immer'

// Store
import useI18n from '@store/i18n/useI18n'

// Style
import styled from '@styles/index'
import Checkboxes from '@components/checkbox/Checkboxes'
import useTheme from '@styles/useTheme'
import useReducer from '@store/useReducer'
import * as CustomInterfaceStore from '@store/customInterfaceStore'

const PICTO_SIZE = 140
const PICTO_PADDING = 10
const SMALL_PICTO_SIZE = 50
const SMALL_PICTO_PADDING = 5
const TI_MAX_LENGTH = 1000

export const validateFamilyRiskIcp = (
    i18n: I18nState,
    onValidate?: (isValid: boolean) => void,
    values?: RisksAndMeasures,
): FormikErrors<RisksAndMeasures> => {
    const errors: FormikErrors<RisksAndMeasures> = {}
    if (values) {
        if (
            !values.risksAndMeasures.find(family =>
                family.risks.find(risk => risk.measures.find(measure => measure.selected)),
            )
        ) {
            errors.risksAndMeasures = i18n.t('components.familyIcp.noFamilySelected')
        } else if (
            values.risksAndMeasures.find(
                family =>
                    family.mandatory && !family.risks.find(risk => risk.measures.find(measure => measure.selected)),
            )
        ) {
            errors.risksAndMeasures = i18n.t('components.familyIcp.noMandatoryFamilySelected')
        } else {
            errors.risksAndMeasures =
                values.risksAndMeasures.reduce((acc1, cur1) => {
                    const risks = cur1.risks.reduce((acc2, cur2) => {
                        const measures: IcpMeasure[] = cur2.measures.reduce(
                            (acc3, cur3) => (!cur3.selected || cur3.responsables.length !== 0 ? acc3 : [...acc3, cur3]),
                            [] as IcpMeasure[],
                        )
                        return measures.length === 0 ? acc2 : [...acc2, { ...cur2, measures }]
                    }, [] as IcpRisk[])
                    return risks.length === 0 ? acc1 : [...acc1, { ...cur1, risks }]
                }, [] as RiskAnalysis[]).length !== 0
                    ? i18n.t('components.familyIcp.noResponsableForMeasure')
                    : undefined
        }
    }

    if (!!onValidate) {
        // eslint-disable-next-line
        onValidate(errors.risksAndMeasures == undefined || errors.risksAndMeasures.length === 0)
    }

    return errors
}

export default ({ initialValues, companies, onValidate, globalSetIcpFieldValue }: FamilyRiskIcpProps) => {
    const i18n = useI18n()
    const [Theme] = useTheme()

    const [addingFamilyOpened, setAddingFamilyOpened] = React.useState<boolean>(false)

    const [selectedFamilyIndex, setSelectedFamilyIndex] = React.useState<number | undefined>(undefined)
    const [selectedRiskIndex, setSelectedRiskIndex] = React.useState<number | undefined>(undefined)

    const [newRisk, setNewRisk] = React.useState<string>('')
    const [newMeasure, setNewMeasure] = React.useState<string>('')

    const [editingRisk, setEditingRisk] = React.useState<EditingRiskMeasure | undefined>(undefined)
    const [editingMeasure, setEditingMeasure] = React.useState<EditingRiskMeasure | undefined>(undefined)

    const instanceName = useReducer(CustomInterfaceStore.store, s => s.name)

    const resetAllEditingState = () => {
        setNewRisk('')
        setNewMeasure('')
        setEditingRisk(undefined)
        setEditingMeasure(undefined)
    }

    const renderFamilyMeasureChoice = (
        allFamilies: RiskAnalysis[],
        selectedFamily: RiskAnalysis | undefined,
        selectedRisk: IcpRisk | undefined,
        setValues: (values: RiskAnalysis[]) => void,
    ) => (
        <Modal
            visible={addingFamilyOpened}
            onRequestClose={() => {
                setAddingFamilyOpened(false)
                setSelectedFamilyIndex(undefined)
                setSelectedRiskIndex(undefined)
                resetAllEditingState()
            }}
        >
            <ModalContent>
                {!selectedFamily ? (
                    <React.Fragment>
                        <ListTitleContainer>
                            <ListTitle numberOfLines={2} ellipsizeMode="tail">
                                {i18n.t('components.familyIcp.familyList')}
                            </ListTitle>
                        </ListTitleContainer>
                        <PictoContainer>
                            {allFamilies.length === 0 ? (
                                <NoItem>
                                    <NoItemLibelle>{i18n.t('components.familyIcp.noFamily')}</NoItemLibelle>
                                </NoItem>
                            ) : (
                                allFamilies.map(renderFamily)
                            )}
                        </PictoContainer>
                    </React.Fragment>
                ) : !selectedRisk ? (
                    <React.Fragment>
                        <ListTitleContainer>
                            <ListTitle numberOfLines={2} ellipsizeMode={'tail'}>
                                {i18n.t('components.familyIcp.riskList')}
                            </ListTitle>
                            <Button
                                libelle={i18n.t('components.familyIcp.back')}
                                onPress={() => {
                                    setSelectedFamilyIndex(undefined)
                                    resetAllEditingState()
                                }}
                                status="active"
                            />
                        </ListTitleContainer>
                        <RisksContainer>
                            {selectedFamily.risks.length === 0 ? (
                                <NoItem>
                                    <NoItemLibelle>{i18n.t('components.familyIcp.noRisk')}</NoItemLibelle>
                                </NoItem>
                            ) : (
                                <React.Fragment>
                                    <SectionName numberOfLines={3} ellipsizeMode={'tail'}>
                                        {selectedFamily.libelle}
                                    </SectionName>
                                    {selectedFamilyIndex !== undefined &&
                                        selectedFamily.risks.map((r, rIdx) =>
                                            renderRisk(
                                                allFamilies,
                                                selectedFamily,
                                                r,
                                                selectedFamilyIndex,
                                                rIdx,
                                                !!editingRisk && editingRisk.index === rIdx,
                                                setValues,
                                            ),
                                        )}
                                </React.Fragment>
                            )}
                            <NewRiskContainer>
                                <TextInput
                                    value={newRisk}
                                    onTextChange={setNewRisk}
                                    maxLength={TI_MAX_LENGTH}
                                    placeholder={i18n.t('components.familyIcp.newRisk')}
                                    flexed
                                />
                                <TouchableItemFeedback
                                    onPress={() => {
                                        setValues(
                                            produce(allFamilies, draft => {
                                                selectedFamilyIndex !== undefined &&
                                                    draft[selectedFamilyIndex].risks.push({
                                                        libelle: newRisk,
                                                        measures: [],
                                                    })
                                            }),
                                        )
                                        resetAllEditingState()
                                    }}
                                    disabled={!newRisk.trim()}
                                >
                                    <AddRiskContainer disabled={!newRisk.trim()}>
                                        <Icon name="plus" size={15} color={Theme.colors.iconColor} />
                                    </AddRiskContainer>
                                </TouchableItemFeedback>
                            </NewRiskContainer>
                        </RisksContainer>
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        <ListTitleContainer>
                            <ListTitle numberOfLines={2} ellipsizeMode={'tail'}>
                                {i18n.t('components.familyIcp.measureList')}
                            </ListTitle>
                            <Button
                                libelle={i18n.t('components.familyIcp.back')}
                                onPress={() => {
                                    setSelectedRiskIndex(undefined)
                                    resetAllEditingState()
                                }}
                                status={'active'}
                            />
                        </ListTitleContainer>
                        <MeasuresContainer>
                            {selectedRisk.measures.length === 0 ? (
                                <NoItem>
                                    <NoItemLibelle>{i18n.t('components.familyIcp.noMeasure')}</NoItemLibelle>
                                </NoItem>
                            ) : (
                                <React.Fragment>
                                    <SectionName numberOfLines={3} ellipsizeMode={'tail'}>
                                        {selectedRisk.libelle}
                                    </SectionName>
                                    {selectedRisk.measures.map(
                                        (m, mIdx) =>
                                            selectedFamilyIndex !== undefined &&
                                            selectedRiskIndex !== undefined &&
                                            renderMeasure(
                                                allFamilies,
                                                m,
                                                selectedFamilyIndex,
                                                selectedRiskIndex,
                                                mIdx,
                                                !!editingMeasure && editingMeasure.index === mIdx,
                                                setValues,
                                            ),
                                    )}
                                </React.Fragment>
                            )}

                            <NewMeasureContainer>
                                <TextInput
                                    value={newMeasure}
                                    onTextChange={setNewMeasure}
                                    maxLength={TI_MAX_LENGTH}
                                    placeholder={i18n.t('components.familyIcp.newMeasure')}
                                    flexed
                                />
                                <TouchableItemFeedback
                                    onPress={() => {
                                        setValues(
                                            produce(allFamilies, draft => {
                                                selectedFamilyIndex !== undefined &&
                                                    selectedRiskIndex !== undefined &&
                                                    draft[selectedFamilyIndex].risks[selectedRiskIndex].measures.push({
                                                        libelle: newMeasure,
                                                        selected: true,
                                                        responsables: [],
                                                    })
                                            }),
                                        )
                                        resetAllEditingState()
                                    }}
                                    disabled={!newMeasure.trim()}
                                >
                                    <AddMeasureContainer disabled={!newMeasure.trim()}>
                                        <Icon name="plus" size={15} color={Theme.colors.iconColor} />
                                    </AddMeasureContainer>
                                </TouchableItemFeedback>
                            </NewMeasureContainer>
                        </MeasuresContainer>
                    </React.Fragment>
                )}
            </ModalContent>
        </Modal>
    )

    const renderFamily = (f: RiskAnalysis, i: number) => (
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        <Tooltip
            key={i}
            styles={tooltipStyles}
            content={
                <TooltipContent>
                    <TooltipDescription>{f.libelle}</TooltipDescription>
                </TooltipContent>
            }
        >
            <TouchableItemFeedback onPress={() => setSelectedFamilyIndex(i)}>
                <ImageContainer mandatory={f.mandatory}>
                    <FamilyImage
                        source={{
                            uri: f.picto,
                        }}
                    />
                </ImageContainer>
            </TouchableItemFeedback>
        </Tooltip>
    )

    const renderRisk = (
        allFamilies: RiskAnalysis[],
        f: RiskAnalysis, // famille selectionnée
        r: IcpRisk, // risk à afficher
        i: number, // family index
        j: number, // risk index
        editing: boolean,
        setValues: (values: RiskAnalysis[]) => void,
    ) => (
        <RiskContainer key={j} editing={editing}>
            {!editing ? (
                <React.Fragment>
                    <TouchableItemFeedback
                        onPress={() => {
                            setEditingRisk({
                                index: j,
                                libelle: r.libelle,
                            })
                        }}
                    >
                        <EditButtonContainer>
                            <View>
                                <Icon name="edit" size={15} color={Theme.colors.editButtonColor} />
                            </View>
                        </EditButtonContainer>
                    </TouchableItemFeedback>
                    <RiskInfoContainer
                        onPress={() => {
                            setSelectedRiskIndex(j)
                            resetAllEditingState()
                        }}
                    >
                        <InfoLibelle numberOfLines={2}>{r.libelle}</InfoLibelle>
                        <Icon name="chevron-right" size={15} color={Theme.colors.classicalText} />
                    </RiskInfoContainer>
                </React.Fragment>
            ) : (
                <EditingRiskInfoContainer>
                    {editingRisk && (
                        <React.Fragment>
                            <TextInput
                                onTextChange={(txt: string) =>
                                    setEditingRisk({
                                        ...editingRisk,
                                        libelle: txt,
                                    })
                                }
                                maxLength={TI_MAX_LENGTH}
                                value={editingRisk.libelle}
                                placeholder={i18n.t('components.familyIcp.newMeasure')}
                                flexed
                            />
                            <TouchableItemFeedback
                                onPress={() => {
                                    setValues(
                                        produce(allFamilies, draft => {
                                            draft[i].risks[j].libelle = editingRisk.libelle
                                        }),
                                    )
                                    resetAllEditingState()
                                }}
                            >
                                <EditButtonContainer margined>
                                    <View>
                                        <Icon name="save" size={15} color={Theme.colors.editButtonColor} />
                                    </View>
                                </EditButtonContainer>
                            </TouchableItemFeedback>
                            <TouchableItemFeedback onPress={resetAllEditingState}>
                                <EditButtonContainer margined>
                                    <View>
                                        <Icon name="cross" size={15} color={Theme.colors.editButtonColor} />
                                    </View>
                                </EditButtonContainer>
                            </TouchableItemFeedback>
                        </React.Fragment>
                    )}
                </EditingRiskInfoContainer>
            )}
        </RiskContainer>
    )

    const renderMeasure = (
        allFamilies: RiskAnalysis[],
        m: IcpMeasure, // mesure à afficher
        i: number, // family index
        j: number, // risk index
        k: number, // measure index
        editing: boolean,
        setValues: (values: RiskAnalysis[]) => void,
    ) => {
        return (
            <React.Fragment key={k}>
                <MeasureContainer key={i} editing={editing}>
                    {!editing ? (
                        <React.Fragment>
                            <TouchableItemFeedback
                                onPress={() => {
                                    setEditingMeasure({
                                        index: k,
                                        libelle: m.libelle,
                                    })
                                }}
                            >
                                <EditButtonContainer>
                                    <View>
                                        <Icon name="edit" size={15} color={Theme.colors.editButtonColor} />
                                    </View>
                                </EditButtonContainer>
                            </TouchableItemFeedback>
                            <MeasureInfoContainer
                                onPress={() => {
                                    setValues(
                                        produce(allFamilies, draft => {
                                            draft[i].risks[j].measures[k].selected = !m.selected
                                        }),
                                    )
                                }}
                            >
                                <InfoLibelle numberOfLines={2}>{m.libelle}</InfoLibelle>
                                <Icon
                                    name={m.selected ? 'checkbox-checked' : 'checkbox-unchecked'}
                                    size={15}
                                    color={Theme.colors.classicalText}
                                />
                            </MeasureInfoContainer>
                        </React.Fragment>
                    ) : (
                        <EditingMeasureInfoContainer>
                            {editingMeasure && (
                                <React.Fragment>
                                    <TextInput
                                        onTextChange={(txt: string) =>
                                            setEditingMeasure({
                                                ...editingMeasure,
                                                libelle: txt,
                                            })
                                        }
                                        maxLength={TI_MAX_LENGTH}
                                        value={editingMeasure.libelle}
                                        placeholder={i18n.t('components.familyIcp.newMeasure')}
                                        flexed
                                    />
                                    <TouchableItemFeedback
                                        onPress={() => {
                                            setValues(
                                                produce(allFamilies, draft => {
                                                    draft[i].risks[j].measures[k].libelle = editingMeasure.libelle
                                                }),
                                            )
                                            resetAllEditingState()
                                        }}
                                    >
                                        <EditButtonContainer margined>
                                            <View>
                                                <Icon name="save" size={15} color={Theme.colors.editButtonColor} />
                                            </View>
                                        </EditButtonContainer>
                                    </TouchableItemFeedback>
                                    <TouchableItemFeedback onPress={resetAllEditingState}>
                                        <EditButtonContainer margined>
                                            <View>
                                                <Icon name="cross" size={15} color={Theme.colors.editButtonColor} />
                                            </View>
                                        </EditButtonContainer>
                                    </TouchableItemFeedback>
                                </React.Fragment>
                            )}
                        </EditingMeasureInfoContainer>
                    )}
                </MeasureContainer>
                {m.selected && (
                    <ResponsableChoiceContainer>
                        <ResponsableChoiceLibelle>
                            {i18n.t('components.familyIcp.responsables')}
                        </ResponsableChoiceLibelle>
                        <Checkboxes
                            labels={[
                                i18n.t('components.familyIcp.groupe', { name: instanceName }),
                                ...companies.sort((a, b) => a.localeCompare(b)),
                            ]}
                            values={m.responsables}
                            onChange={responsables => {
                                setValues(
                                    produce(allFamilies, draft => {
                                        draft[i].risks[j].measures[k].responsables = responsables
                                    }),
                                )
                            }}
                            radio={false}
                        />
                    </ResponsableChoiceContainer>
                )}
            </React.Fragment>
        )
    }

    const renderSelectedMeasure = (families: RiskAnalysis[]) => {
        const icpFamilies = families.reduce((acc1, cur1) => {
            const risks: IcpRisk[] = cur1.risks.reduce((acc2, cur2) => {
                const measures: IcpMeasure[] = cur2.measures.reduce(
                    (acc3, cur3) => (!cur3.selected ? acc3 : [...acc3, cur3]),
                    [] as IcpMeasure[],
                )
                return measures.length === 0 ? acc2 : [...acc2, { ...cur2, measures }]
            }, [] as IcpRisk[])
            return risks.length === 0 ? acc1 : [...acc1, { ...cur1, risks }]
        }, [] as RiskAnalysis[])

        return icpFamilies.length === 0 ? (
            <NoItem>
                <NoItemLibelle>{i18n.t('components.familyIcp.noIcpMeasure')}</NoItemLibelle>
            </NoItem>
        ) : (
            icpFamilies
                .sort((a, b) => a.libelle.localeCompare(b.libelle))
                .map((f, i) => (
                    <SelectedFamilyContainer key={i} lastItem={icpFamilies.length === i + 1}>
                        <FamilySelected>
                            <SelectedImageContainer mandatory={f.mandatory}>
                                <TouchableItemFeedback
                                    onPress={() => {
                                        const familyClickedIndex = families.findIndex(fam => fam.libelle === f.libelle)
                                        if (familyClickedIndex > -1) {
                                            setSelectedFamilyIndex(familyClickedIndex)
                                            setAddingFamilyOpened(true)
                                        }
                                    }}
                                >
                                    <SelectedFamilyImage
                                        source={{
                                            uri: f.picto,
                                        }}
                                    />
                                </TouchableItemFeedback>
                            </SelectedImageContainer>
                            <SelectedFamilyLibelle numberOfLines={2} ellipsizeMode="tail">
                                {f.libelle}
                            </SelectedFamilyLibelle>
                        </FamilySelected>
                        <RiskListContainer>
                            {f.risks.map((r, j) => (
                                <SelectedRiskContainer key={j} lastItem={f.risks.length === j + 1}>
                                    <RiskSelected>
                                        <SelectedRiskLibelle numberOfLines={2} ellipsizeMode="tail">
                                            {r.libelle}
                                        </SelectedRiskLibelle>
                                    </RiskSelected>
                                    <RiskMeasureContainer>
                                        {r.measures.map((m, k) => (
                                            <SelectedMeasureContainer
                                                key={k}
                                                lastItem={r.measures.length === k + 1}
                                                onError={m.responsables.length === 0}
                                            >
                                                <SelectedMeasureLibelle numberOfLines={2} ellipsizeMode="tail">
                                                    {m.libelle}
                                                </SelectedMeasureLibelle>
                                                <TouchableItemFeedback
                                                    onPress={() => {
                                                        const familyClickedIndex = families.findIndex(
                                                            family => family.libelle === f.libelle,
                                                        )
                                                        if (familyClickedIndex > -1) {
                                                            const riskClickedIndex = families[
                                                                familyClickedIndex
                                                            ].risks.findIndex(risk => risk.libelle === r.libelle)
                                                            if (riskClickedIndex > -1) {
                                                                setSelectedFamilyIndex(familyClickedIndex)
                                                                setSelectedRiskIndex(riskClickedIndex)
                                                                setAddingFamilyOpened(true)
                                                            }
                                                        }
                                                    }}
                                                >
                                                    <ResponsableLibelle
                                                        numberOfLines={3}
                                                        ellipsizeMode="tail"
                                                        noResponsable={m.responsables.length === 0}
                                                    >
                                                        {m.responsables.length !== 0
                                                            ? m.responsables.join(', ')
                                                            : i18n.t('components.familyIcp.noResponsable')}
                                                    </ResponsableLibelle>
                                                </TouchableItemFeedback>
                                            </SelectedMeasureContainer>
                                        ))}
                                    </RiskMeasureContainer>
                                </SelectedRiskContainer>
                            ))}
                        </RiskListContainer>
                    </SelectedFamilyContainer>
                ))
        )
    }

    return (
        <Formik
            key={initialValues.risksAndMeasures.length}
            initialValues={initialValues}
            onSubmit={() => undefined} // On passe par le handleSubmit global sans validation
            validate={values => validateFamilyRiskIcp(i18n, onValidate, values)}
        >
            {({ values, setValues, isValid, errors }) => {
                const globalSetValues = (newValues: RisksAndMeasures) => {
                    globalSetIcpFieldValue(newValues)
                    setValues(newValues)
                }

                const selectedFamily: RiskAnalysis | undefined =
                    selectedFamilyIndex !== undefined ? values.risksAndMeasures[selectedFamilyIndex] : undefined

                const selectedRisk: IcpRisk | undefined =
                    selectedRiskIndex !== undefined && selectedFamily
                        ? selectedFamily.risks[selectedRiskIndex]
                        : undefined

                return (
                    <Container title={i18n.t('components.familyIcp.title')} hasErrors={!isValid}>
                        <React.Fragment>
                            <ListTitleContainer>
                                <ListTitle numberOfLines={2} ellipsizeMode={'tail'}>
                                    {i18n.t('components.familyIcp.tabTitle')}
                                </ListTitle>
                                <Button
                                    libelle={i18n.t('components.familyIcp.addMeasure')}
                                    onPress={() => setAddingFamilyOpened(true)}
                                    status={addingFamilyOpened ? 'disabled' : 'active'}
                                />
                                {renderFamilyMeasureChoice(
                                    values.risksAndMeasures,
                                    selectedFamily,
                                    selectedRisk,
                                    risksAndMeasures => globalSetValues({ risksAndMeasures }),
                                )}
                            </ListTitleContainer>
                            <NoMandatoryFamilies>{errors.risksAndMeasures?.toString()}</NoMandatoryFamilies>
                            <MeasureChosenContainer>
                                {renderSelectedMeasure(values.risksAndMeasures)}
                            </MeasureChosenContainer>
                        </React.Fragment>
                    </Container>
                )
            }}
        </Formik>
    )
}
// TOOLTIP STYLE
const tooltipStyles: TooltipStyles = {
    wrapper: { cursor: 'default' },
    tooltip: {},
    content: {},
    arrow: {},
    gap: {},
}

// TITRE DE LA LISTE DES FAMILLES CHOISIES
const ListTitleContainer = styled(View)`
    flex-direction: row;
    height: ${props => props.theme.constants.riskFamiliesListTitleContainerHeight}px;
    justify-content: space-between;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;

    border-bottom-color: ${props => props.theme.colors.homeListBorderColor};
    border-bottom-width: 2px;
`
const ListTitle = styled(Text)`
    ${props => props.theme.fonts.icpRubriqueTitle}
    text-transform: uppercase;
`
const MeasureChosenContainer = styled(View)`
    flex: 1;
    background-color: white;
    margin-top: 20px;
    margin-bottom: 50px;
`
const NoItem = styled(View)`
    flex: 1;
    min-height: 100px;
    align-items: center;
    justify-content: center;

    border: red 1px solid;
    border-radius: 5px;
`
const NoItemLibelle = styled(Text)`
    ${props => props.theme.fonts.noFamilyList}
`

// MODALE D'AJOUT DE MESURE
const ModalContent = styled(View)`
    background-color: ${props => props.theme.colors.documentsModalBackground};
    padding: 24px;
`

// LISTE DES FAMILLES PAR PICTO
const ImageContainer = styled(View)<{ mandatory?: boolean }>`
    margin: 10px;
    padding: ${props => (props.mandatory ? 0 : PICTO_PADDING)}px;
    border-radius: ${2 * PICTO_PADDING}px;
    width: ${PICTO_SIZE + 2 * PICTO_PADDING}px;
    height: ${PICTO_SIZE + 2 * PICTO_PADDING}px;
    background-color: ${props => props.theme.colors.pictoFamilyBackground};
    cursor: pointer;
    &:hover {
        background-color: ${props => props.theme.colors.pictoFamilyBackgroundHover};
    }

    border: ${props =>
        props.mandatory && `${PICTO_PADDING * 0.5}px solid ${props.theme.colors.riskFamilyItemMandatory}`};
`
const FamilyImage = styled(Image)`
    height: ${PICTO_SIZE}px;
    width: ${PICTO_SIZE}px;
`
const PictoContainer = styled(View)`
    padding: 10px;
    flex: 1;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
`
const TooltipContent = styled(View)`
    margin-left: 15px;
    margin-right: 15px;
`
const TooltipDescription = styled(Text)`
    ${props => props.theme.fonts.tooltipContent}
`

// LISTE DES RISQUES
const RisksContainer = styled(View)`
    padding: 10px;
    flex: 1;
    flex-direction: column;
`
const RiskContainer = styled(View)<{ editing?: boolean }>`
    background-color: ${props =>
        props.editing ? props.theme.colors.editContainerBackground : props.theme.colors.riskLine};
    margin-bottom: 10px;
    ${props => props.theme.fonts.riskList}
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    padding: 10px 30px;
    cursor: pointer;

    &:hover {
        background-color: ${props => props.theme.colors.riskLineHover};
        & > div {
            & > div {
                color: ${props => props.theme.colors.riskTextHover};
            }
        }
    }
`

// LISTE DES MESURES
const MeasuresContainer = styled(View)`
    padding: 10px;
    flex: 1;
    flex-direction: column;
`
const MeasureContainer = styled(View)<{ editing?: boolean }>`
    cursor: pointer;
    background-color: ${props =>
        props.editing ? props.theme.colors.editContainerBackground : props.theme.colors.riskLine};
    margin-bottom: 10px;
    ${props => props.theme.fonts.riskList}
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    padding: 10px 30px;

    &:hover {
        background-color: ${props => props.theme.colors.riskLineHover};
        & > div {
            & > div {
                color: ${props => props.theme.colors.riskTextHover};
            }
        }
    }
`
const InfoLibelle = styled(Text)`
    ${props => props.theme.fonts.infoLibelle}
`

// LISTE DES ELEMENTS CHOISIS DANS ICP
const SelectedFamilyContainer = styled(View)<{ lastItem?: boolean }>`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    ${props => !props.lastItem && `border-bottom: 2px solid ${props.theme.colors.borderListColor};`}
`
const FamilySelected = styled(View)`
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`
const RiskSelected = styled(View)`
    flex: 1;
`
const SelectedRiskContainer = styled(View)<{ lastItem?: boolean; onError?: boolean }>`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    ${props =>
        props.onError &&
        `border: 2px solid ${props.theme.colors.onError};
    border-radius: 10px; margin-bottom: 5px;
    `}
    ${props => !props.lastItem && !props.onError && `border-bottom: 2px solid ${props.theme.colors.borderListColor};`}
`
const SelectedMeasureContainer = styled(View)<{ lastItem?: boolean; onError?: boolean }>`
    ${props =>
        props.onError &&
        `border: 2px solid ${props.theme.colors.onError};
    border-radius: 10px; margin-bottom: 5px;
    padding: 5px;
    `}

    padding-top: 5px;
    padding-bottom: 5px;
    ${props => !props.lastItem && !props.onError && `border-bottom: 2px solid ${props.theme.colors.borderListColor};`}
`
const RiskListContainer = styled(View)`
    flex: 2;
`
const RiskMeasureContainer = styled(View)`
    flex: 1;
`

//LIBELLE DES INFOS SELCTED
const SelectedFamilyLibelle = styled(Text)`
    ${props => props.theme.fonts.infoLibelle}
    padding-right: 5px;
`
const SelectedRiskLibelle = styled(Text)`
    ${props => props.theme.fonts.infoLibelle}
    padding-right: 5px;
`
const SelectedMeasureLibelle = styled(Text)`
    ${props => props.theme.fonts.infoLibelle}
    padding-right: 5px;
`

//PICTO DANS PETITE LISTE DES SELECTED
const SelectedImageContainer = styled(View)<{ mandatory?: boolean }>`
    margin: 10px;
    padding: ${SMALL_PICTO_PADDING}px;
    width: ${SMALL_PICTO_SIZE + SMALL_PICTO_PADDING * 2}px;
    height: ${SMALL_PICTO_SIZE + SMALL_PICTO_PADDING * 2}px;
    border-radius: ${2 * SMALL_PICTO_PADDING}px;
    background-color: ${props => props.theme.colors.pictoFamilyBackground};
    cursor: pointer;

    &:hover {
        background-color: ${props => props.theme.colors.pictoFamilyBackgroundHover};
    }

    border: ${props => props.mandatory && `0px solid ${props.theme.colors.riskFamilyItemMandatory}`};
    border-left-width: ${props => props.mandatory && '5px'};
    border-radius: ${props => props.mandatory && '5px'};
`
const SelectedFamilyImage = styled(Image)`
    height: ${SMALL_PICTO_SIZE}px;
    width: ${SMALL_PICTO_SIZE}px;
`
//ADD NEW RISK
const NewRiskContainer = styled(View)`
    flex-direction: row;
    padding-top: 20px;
    padding-bottom: 20px;
`
const AddRiskContainer = styled(View)<{ disabled?: boolean }>`
    background-color: ${props => (props.disabled ? props.theme.colors.addIconDisabled : props.theme.colors.addIcon)};
    height: 50px;
    width: 50px;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
`
//ADD NEW MEASURE
const NewMeasureContainer = styled(View)`
    flex-direction: row;
    padding-top: 20px;
    padding-bottom: 20px;
`
const AddMeasureContainer = styled(View)<{ disabled?: boolean }>`
    background-color: ${props => (props.disabled ? props.theme.colors.addIconDisabled : props.theme.colors.addIcon)};
    height: 50px;
    width: 50px;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
`
const ResponsableChoiceContainer = styled(View)`
    flex-direction: column;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 2px solid ${props => props.theme.colors.borderListColor};
    & > div {
        flex-direction: column;
        & > div {
            flex-direction: column;
        }
    }
`
const ResponsableChoiceLibelle = styled(Text)`
    ${props => props.theme.fonts.companiesChoiceLibelle};
    margin-bottom: 10px;
`
const ResponsableLibelle = styled(Text)<{ noResponsable?: boolean }>`
    ${props => (props.noResponsable ? props.theme.fonts.noCompaniesLibelle : props.theme.fonts.companiesLibelle)};
    padding-top: 10px;
    padding-right: 5px;
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: ${props =>
        props.noResponsable ? props.theme.fonts.noCompaniesLibelle.color : props.theme.fonts.companiesLibelle.color};
`
const NoMandatoryFamilies = styled(Text)`
    ${props => props.theme.fonts.noMandatoryFamilies}
    text-align: end;
    padding-top: 5px;
`
const RiskInfoContainer = styled(TouchableOpacity)`
    flex: 1;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-left: 25px;
`
const MeasureInfoContainer = styled(TouchableOpacity)`
    flex: 1;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-left: 25px;
`
const EditButtonContainer = styled(View)<{ margined?: boolean }>`
    width: 35px;
    height: 35px;
    border-radius: 50px;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.theme.colors.editButtonBackgroundColor};
    ${props => `border: 2px solid ${props.theme.colors.editButtonBorderColor};`}
    ${props => props.margined && 'margin-left: 20px;'}

    &:hover {
        border: 2px solid ${props => props.theme.colors.editButtonBorderColorHovered};
        background-color: ${props => props.theme.colors.editButtonBackgroundColorHovered};
        & > div {
            & > div {
                color: ${props => props.theme.colors.editButtonColorHovered};
            }
        }
    }
`
const EditingRiskInfoContainer = styled(View)`
    flex: 1;
    align-items: center;
    flex-direction: row;
`
const EditingMeasureInfoContainer = styled(View)`
    flex: 1;
    align-items: center;
    flex-direction: row;
`
const SectionName = styled(Text)`
    text-align: center;
    ${props => props.theme.fonts.icpRubriqueTitle}
    margin-bottom: 15px;
`
