import * as React from 'react'

// Components
import { View, Text, ScrollView, ActivityIndicator, Platform } from 'react-native'
import Icon from '@components/icon/Icon'
import { TouchableItemFeedback } from '@components/touchable/Touchable'
import { basicAlert, confirmAlert } from '@components/alert/Alerts'
import PpeePdfMenuItem from '@components/ppeePdfMenu/PpeePdfMenuItem'
import ImagesModal from '@components/imagesModal/ImagesModal'
import DocumentVisualization from '@components/addIcpDocuments/DocumentVisualization'

// Utils
import useNavigation from '@layout/useNavigation'
import Logger from '@utils/logger'
import { isPdf, openPdfInTab } from '@utils/pdfCommonUtils'

// Api
import api from '@app/api/api'

// Store
import useI18n from '@store/i18n/useI18n'

// Style
import styled from '@styles/index'
import useTheme from '@styles/useTheme'

interface Props {
    buttons: ButtonsPPEE
    ppee: Icp
    onClose: () => void
    refreshPpee: () => void
}

const I18N_SCREEN_KEY = 'screens.ppeePdfScreen'

export default (props: Props) => {
    const { buttons, onClose, ppee, refreshPpee } = props
    const { documentsPpee, formulairesPpee, avenantsPpee } = ppee
    const i18n = useI18n()
    const navigation = useNavigation()
    const [Theme] = useTheme()

    const [ppeeRenewing, setPpeeRenewing] = React.useState<boolean>(false)

    const [visitList, setVisitList] = React.useState<SuiviVisite[]>([])

    const [mobilePdfVisible, setMobilePdfVisible] = React.useState<string | undefined>(undefined)
    const [initImage, setInitImage] = React.useState<number>(0)
    const [imagesToDisplay, setImagesToDisplay] = React.useState<string[]>([])
    const annexeImages = React.useMemo(
        () =>
            [...documentsPpee.attachedDocuments, ...documentsPpee.documentsExt].reduce(
                (acc: string[], cur: DocumentIcp) => {
                    const imagesOnly = cur.pages.filter(page => !isPdf(page.image)).map(page => page.image)
                    return [...acc, ...imagesOnly]
                },
                [],
            ),
        [documentsPpee],
    )

    const areThereAnnexes = React.useMemo(
        () =>
            [...documentsPpee.documentsAprr, ...documentsPpee.documentsExt, ...documentsPpee.attachedDocuments].length >
            0,
        [documentsPpee],
    )

    React.useEffect(() => {
        if (!!ppee.id) {
            api.ppee
                .getAllSuivis(ppee.id)
                .then(visits => setVisitList(visits.suiviVisites))
                .catch(Logger.error)
        }
    }, [ppee])

    const renderButton = (button: ButtonPPEE, index: number) => (
        <TouchableItemFeedback
            key={`button${index}`}
            onPress={() => {
                onClose()
                button.onPress(navigation)
            }}
        >
            <ActionLine isNotFirst={index > 0}>
                <ActionLabel>{button.label}</ActionLabel>
                <Bubble>
                    {button.isLoading ? (
                        <ActivityIndicator size={30} />
                    ) : (
                        <Icon name={button.icon as IconName} color={Theme.colors.defaultButtonFontColor} size={30} />
                    )}
                </Bubble>
            </ActionLine>
        </TouchableItemFeedback>
    )

    const handleFormDelete = (formId: string) => {
        confirmAlert(
            i18n,
            I18N_SCREEN_KEY,
            'DeleteForm',
            () =>
                api.formulaire
                    .deleteForm(formId)
                    .then(() => basicAlert(i18n, I18N_SCREEN_KEY, 'DeleteForm', refreshPpee, 'success', Theme))
                    .catch(err => {
                        Logger.error(err)
                        basicAlert(i18n, I18N_SCREEN_KEY, 'DeleteForm', () => undefined, 'error', Theme)
                    }),
            () => undefined,
            Theme,
        )
    }

    const getAmendmentTitle = (avenant: AvenantPpee) => {
        return i18n.t('screens.ppeePdfScreen.listItem', {
            item: i18n.t(`screens.ppeePdfScreen.${avenant.date ? 'amendmentDate' : 'amendmentNoDate'}`, {
                date: avenant.date && new Date(avenant.date),
                reasons: avenant.reasons
                    .map(reason => i18n.t(`screens.ppeePdfScreen.shortValues.amendmentReasons.${reason}`))
                    .join(', ')
                    .toLowerCase(),
            }),
        })
    }

    const alertRenewPpee = (ppeeId: string) =>
        confirmAlert(
            i18n,
            I18N_SCREEN_KEY,
            'RenewPpee',
            () =>
                api.ppee
                    .renewPpee(ppeeId)
                    .then(renewedIcp => {
                        setPpeeRenewing(false)
                        basicAlert(
                            i18n,
                            I18N_SCREEN_KEY,
                            'RenewPpee',
                            () =>
                                navigation.pushFromRoot('/icp/:icpId', {
                                    icpId: renewedIcp.id,
                                    pathParams: { icpId: renewedIcp.id },
                                }),
                            'success',
                            Theme,
                        )
                    })
                    .catch(err =>
                        basicAlert(i18n, I18N_SCREEN_KEY, 'RenewPpee', () => setPpeeRenewing(false), 'error', Theme),
                    ),
            () => {
                setPpeeRenewing(false)
            },
            Theme,
        )

    const renewPpee = (ppeeId: string) => {
        setPpeeRenewing(true)
        if (Platform.OS === 'ios') {
            setTimeout(() => alertRenewPpee(ppeeId), 1)
        } else {
            alertRenewPpee(ppeeId)
        }
    }

    const renderDocName = (doc: DocumentIcp | DocumentAprr, index: number) => (
        <ItemTitle key={`ppeeDocument${index}`}>
            {i18n.t('screens.ppeePdfScreen.listItem', { item: doc.name })}
        </ItemTitle>
    )

    return (
        <ScrollView>
            <ImagesModal
                visible={imagesToDisplay.length > 0}
                onRequestClose={() => {
                    setInitImage(0)
                    setImagesToDisplay([])
                }}
                initImage={initImage}
                images={imagesToDisplay}
            />

            <ButtonsContainer>
                <Separator />
                <SectionTitle>{i18n.t('screens.ppeePdfScreen.titles.amendments')}</SectionTitle>
                <ItemContainer>
                    {!!avenantsPpee && avenantsPpee.length > 0 ? (
                        avenantsPpee.map((avenant, index) => (
                            <RowContainer key={`ppeeAvenant${index}`}>
                                <ItemTitle>{getAmendmentTitle(avenant)}</ItemTitle>
                                <View>
                                    <TouchableItemFeedback
                                        onPress={() => {
                                            navigation.push('/ppee/:ppeeId/modifierAvenant/:icpId', {
                                                ppeeId: ppee.id,
                                                icpId: avenant.id,
                                                pathParams: { ppeeId: ppee.id, icpId: avenant.id },
                                            })
                                            onClose()
                                        }}
                                    >
                                        <Action>{i18n.t('screens.ppeePdfScreen.edit')}</Action>
                                    </TouchableItemFeedback>
                                    {/* <TouchableItemFeedback
                                        onPress={() => {
                                            // TODO : gérer la suppression d'un avenant
                                            refreshPpee()
                                        }}
                                    >
                                        <Action>{i18n.t('screens.ppeePdfScreen.delete')}</Action>
                                    </TouchableItemFeedback> */}
                                </View>
                            </RowContainer>
                        ))
                    ) : (
                        <NoItem>{i18n.t('screens.ppeePdfScreen.noAmendment')}</NoItem>
                    )}
                </ItemContainer>
                {buttons.amendments.map(renderButton)}
                <Separator />
                {!ppee.idParent &&
                    renderButton(
                        {
                            label: i18n.t('screens.ppeePdfScreen.titles.renew'),
                            onPress: () => ppee.id && renewPpee(ppee.id),
                            icon: 'doubleNext',
                            isLoading: ppeeRenewing,
                        },
                        0,
                    )}
                <Separator />
                <SectionTitle>{i18n.t('screens.ppeePdfScreen.titles.visitForms')}</SectionTitle>
                <Separator />
                <ItemContainer>
                    <PpeePdfMenuItem
                        itemList={visitList as FormulairePpee[]}
                        onClose={onClose}
                        ppee={ppee}
                        handleFormDelete={handleFormDelete}
                        itemKey="visit"
                        refreshPpee={refreshPpee}
                    />
                </ItemContainer>
                {buttons.visit.map(renderButton)}
                <Separator />
                <SectionTitle>{i18n.t('screens.ppeePdfScreen.titles.forms')}</SectionTitle>
                <Separator />
                <ItemContainer>
                    <PpeePdfMenuItem
                        itemList={formulairesPpee}
                        onClose={onClose}
                        ppee={ppee}
                        handleFormDelete={handleFormDelete}
                        itemKey="formulaire"
                        refreshPpee={refreshPpee}
                    />
                </ItemContainer>
                {buttons.forms.map(renderButton)}
                <Separator />
                <SectionTitle>{i18n.t('screens.ppeePdfScreen.titles.documents')}</SectionTitle>
                <Separator />
                <ItemContainer>
                    {areThereAnnexes ? (
                        <>
                            {documentsPpee.documentsAprr.map(renderDocName)}
                            {[...documentsPpee.attachedDocuments, ...documentsPpee.documentsExt].map(
                                (document, index) => {
                                    const imagesOnly = document.pages.filter(page => !isPdf(page.image))
                                    return document.pages.map((page, j) => (
                                        <TouchableItemFeedback
                                            key={`doc${index}image${j}`}
                                            onPress={() => {
                                                if (isPdf(page.image)) {
                                                    if (Platform.OS === 'web') {
                                                        openPdfInTab(page.image)
                                                    } else {
                                                        setMobilePdfVisible(page.image)
                                                    }
                                                } else {
                                                    setInitImage(imagesOnly.indexOf(page))
                                                    setImagesToDisplay(annexeImages)
                                                }
                                            }}
                                        >
                                            {renderDocName(document, index)}
                                        </TouchableItemFeedback>
                                    ))
                                },
                            )}
                        </>
                    ) : (
                        <NoItem>{i18n.t('screens.ppeePdfScreen.noDocument')}</NoItem>
                    )}
                </ItemContainer>
                {buttons.documents.map(renderButton)}
                <Separator />
                <SectionTitle>{i18n.t('screens.ppeePdfScreen.titles.info')}</SectionTitle>
                <Separator />
                {buttons.info.map(renderButton)}
                <Separator />
            </ButtonsContainer>
            <DocumentVisualization
                displayingDocument={mobilePdfVisible}
                closeModal={() => setMobilePdfVisible(undefined)}
            />
        </ScrollView>
    )
}
const RowContainer = styled(View)`
    flex-direction: row;
    margin-bottom: 10px;
`
const Bubble = styled(View)<{ alternativeColors?: boolean }>`
    background-color: ${props =>
        props.alternativeColors ? props.theme.colors.defaultButtonFontColor : props.theme.colors.defaultButtonColor};

    justify-content: center;
    align-items: center;

    height: 50px;
    width: 50px;
    border-radius: 50px;

    elevation: 50px;
    box-shadow: 1px 1px 5px ${props => props.theme.colors.homeIndicatorBowShadowColor};
`
const ActionLine = styled(View)<{ isNotFirst: boolean }>`
    height: 50px;
    flex-direction: row;
    align-items: center;
    align-items: center;
    ${props => props.isNotFirst && 'margin-top: 15px;'}
`
const ButtonsContainer = styled(View)`
    margin-bottom: 75px;
`
const ActionLabel = styled(Text)`
    ${props => props.theme.fonts.ppeePdfAction}
    flex: 1;
    text-align: right;
    margin-right: 20px;
`
const Separator = styled(View)`
    background-color: ${props => props.theme.colors.accountSeparator};
    border-radius: 50px;
    margin: 20px;
    height: 5px;
`
const SectionTitle = styled(Text)`
    ${props => props.theme.fonts.ppeePdfSectionTitle}
    text-align: center;
    text-transform: uppercase;
`
const NoItem = styled(Text)`
    text-align: center;
    ${props => props.theme.fonts.ppeePdfItemLine}
`
const ItemTitle = styled(Text)`
    ${props => props.theme.fonts.ppeePdfItemLine}
    padding-left: 20px;
    padding-right: 20px;
    flex: 1;
`
const ItemContainer = styled(View)`
    padding-top: 24px;
    padding-bottom: 24px;
    margin-bottom: -10px;
`
const Action = styled(Text)`
    ${props => props.theme.fonts.ppeeActionLink}
    text-align: right;
    text-decoration: underline;
    text-decoration-color: ${props => props.theme.colors.ppeeActionLink};
    :hover {
        ${props => props.theme.fonts.ppeeActionLinkHovered}
        text-decoration-color: ${props => props.theme.colors.ppeeActionLinkHovered};
    }
`
