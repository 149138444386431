import config from '@app/config'
import { Platform } from 'react-native'

const conf = (env: 'debug' | 'inte' | 're7' | 'release'): AuthConfig => {
    switch (env) {
        case 'debug':
            return {
                clientId: '553039dc-8ec5-4a97-9670-c1d02273cac7',
                tenant: '5b84129b-6b6f-4345-a82f-7d6a3a354bb7',
                redirectUri:
                    Platform.OS === 'web'
                        ? 'http://localhost:3000/connect'
                        : 'msauth.com.datanewroad.aprr.numa.ppee.debug://auth',
                serverAPI: 'api://61d644d2-acab-4d09-b8d5-7c1d8b4dcd44/AccessServer',
                additionalScopes: ['User.Read'],
            }
        case 'inte':
            return {
                clientId: '553039dc-8ec5-4a97-9670-c1d02273cac7',
                tenant: '5b84129b-6b6f-4345-a82f-7d6a3a354bb7',
                redirectUri:
                    Platform.OS === 'web'
                        ? 'https://datanewroad-ppee-testing.amiltone.com/connect'
                        : 'msauth.com.datanewroad.aprr.numa.ppee.inte://auth',
                serverAPI: 'api://61d644d2-acab-4d09-b8d5-7c1d8b4dcd44/AccessServer',
                additionalScopes: ['User.Read'],
            }
        case 're7':
            return {
                clientId: 'ace40a01-38bf-4e25-9650-0323e9a8a94d',
                tenant: 'fdb0591f-51ad-45a5-83ef-5f6bf1b081cb',
                redirectUri:
                    Platform.OS === 'web'
                        ? 'https://ppee-preprodnew.numaprr.fr/connect'
                        : 'msauth.com.datanewroad.aprr.numa.ppee.re7://auth',
                serverAPI: 'api://84bdab6e-d3aa-4b48-9dcc-3d198a6a287d/AccessServer',
                additionalScopes: ['User.Read'],
            }
        case 'release':
            return {
                clientId: 'ace40a01-38bf-4e25-9650-0323e9a8a94d',
                tenant: 'fdb0591f-51ad-45a5-83ef-5f6bf1b081cb',
                redirectUri:
                    Platform.OS === 'web'
                        ? 'https://ppee.numaprr.fr/connect'
                        : 'msauth.com.datanewroad.aprr.numa.ppee://auth',
                serverAPI: 'api://84bdab6e-d3aa-4b48-9dcc-3d198a6a287d/AccessServer',
                additionalScopes: ['User.Read'],
            }
    }
}

export default conf(config.ENV)
