const isAgencyIncomplete = (agency: IcpAgency): boolean =>
    !agency.startDate || !agency.endDate || agency.maxStaff === null || typeof agency.maxStaff === 'undefined'

const getIcpFromList = (icpList: Icp[], icpId: string) => icpList.find(icp => icp.id === icpId)

const icpToSimpleIcp = (icp: Icp): SimpleIcp => ({
    id: icp.id || '',
    companiesName: icp.actors.agencies.map(agency => agency.name),
    icpDate: icp.planification.date,
    operationDate: icp.informations.startDate,
    status: (icp.status as ICPStatus) || 'aPlanifier',
    redacteur: `${icp.planification.redacteur.firstName} ${icp.planification.redacteur.lastName}`,
    redacteurId: icp.planification.redacteur.id,
    approbateur: icp.planification.approbateur
        ? `${icp.planification.approbateur.firstName} ${icp.planification.approbateur.lastName}`
        : '',
    perimeterId: icp.planification.perimeterId || '',
    contributeurs: [],
    reference: icp.reference,
    description: icp.informations.description,
    endDate: icp.informations.endDate,
    isAvenant: icp.raisonsAvenant.length > 0,
})

export { isAgencyIncomplete, getIcpFromList, icpToSimpleIcp }
