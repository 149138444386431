import * as React from 'react'
import { ScrollView } from 'react-native'
// Components
import Modal from 'react-modal'

// Style
import styled from '@styles/index'
import useTheme from '@styles/useTheme'

export default (props: ModalProps) => {
    const { children, onRequestClose, visible, customBackgroundColor, noMinHeight } = props
    const [Theme] = useTheme()

    const [windowHeight, setWindowHeight] = React.useState<number>(window.innerHeight)
    const [windowWidth, setWindowWidth] = React.useState<number>(window.innerWidth)

    React.useLayoutEffect(() => {
        const updateDimensions = () => {
            setWindowHeight(window.innerHeight)
            setWindowWidth(window.innerWidth)
        }
        window.addEventListener('resize', updateDimensions)
        return () => window.removeEventListener('resize', updateDimensions)
    }, [setWindowHeight])

    const customStyles = React.useMemo(() => {
        const height = !noMinHeight ? { height: windowHeight * 0.8 } : {}
        return {
            overlay: {
                backgroundColor: customBackgroundColor || Theme.colors.modalBackground,
            },
            content: {
                width: windowWidth * 0.5,
                minWidth: 500,
                ...height,
                display: 'flex',
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                transform: 'translate(-50%, -50%)',
            },
        }
    }, [windowHeight, windowWidth, noMinHeight, customBackgroundColor, Theme])

    return (
        <Modal isOpen={visible} style={customStyles} onRequestClose={onRequestClose}>
            <Container>{children}</Container>
        </Modal>
    )
}

const Container = styled(ScrollView)`
    background-color: ${props => props.theme.colors.documentsModalBackground};
    padding: 20px;
`
