import * as React from 'react'

// Components
import { View } from 'react-native'
import { TouchableItemFeedback } from '@components/touchable/Touchable'
import Icon from '@components/icon/Icon'
import PpeePdfMenu from './ppeePdfMenu'

// Style
import styled from '@styles/index'
import useTheme from '@styles/useTheme'

export default (props: PpeePdfDisplayProps) => {
    const { buttons, ppee, refreshPpee } = props
    const [Theme] = useTheme()

    const [menuOpened, setMenuOpened] = React.useState<boolean>(true)
    return (
        <Container>
            <LeftContainer>
                {menuOpened && (
                    <LeftMenu>
                        <PpeePdfMenu
                            buttons={buttons}
                            onClose={() => setMenuOpened(false)}
                            ppee={ppee}
                            refreshPpee={refreshPpee}
                        />
                    </LeftMenu>
                )}
                <ButtonContainer>
                    <TouchableItemFeedback onPress={() => setMenuOpened(!menuOpened)}>
                        <MenuButton menuOpened={menuOpened}>
                            <Icon
                                name={menuOpened ? 'cross' : 'settings'}
                                color={!menuOpened ? Theme.colors.closeMenuButton : Theme.colors.defaultButtonColor}
                                size={menuOpened ? 20 : 30}
                            />
                        </MenuButton>
                    </TouchableItemFeedback>
                </ButtonContainer>
            </LeftContainer>
            <iframe title="PpeePdf" width="100%" height="100%" src={ppee.pdfUrl}></iframe>
        </Container>
    )
}

const Container = styled(View)`
    height: 100%;
    width: 100%;
    flex-direction: row;
`
const LeftContainer = styled(View)`
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
`
const LeftMenu = styled(View)`
    background-color: ${props => props.theme.colors.ppeePdfLeftMenuBackground};
    width: 400px;
    padding-top: 24px;
    padding-left: 24px;
    padding-right: 24px;
    flex: 1;
`
const ButtonContainer = styled(View)`
    position: absolute;
    bottom: 50px;
    left: 50px;
`
const MenuButton = styled(View)<{ menuOpened: boolean }>`
    height: 50px;
    width: 50px;
    background-color: ${props =>
        props.menuOpened ? props.theme.colors.closeMenuButton : props.theme.colors.defaultButtonColor};
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    elevation: 50px;
    box-shadow: 1px 1px 5px ${props => props.theme.colors.homeIndicatorBowShadowColor};
    z-index: 1;
`
