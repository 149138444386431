import * as React from 'react'

// Components
import Chart from 'react-apexcharts'
import fr from 'apexcharts/dist/locales/fr.json'

// Style
import styled from '@styles/index'

export default (props: BarChartProps) => {
    const { xAxisData, yAxisData, yLabel } = props
    return (
        <CustomChart
            type={'bar'}
            options={{
                chart: {
                    locales: [fr],
                    defaultLocale: 'fr',
                },
                xaxis: {
                    categories: xAxisData,
                },
            }}
            series={[
                {
                    name: yLabel,
                    data: yAxisData,
                },
            ]}
        />
    )
}

const CustomChart = styled(Chart)`
    .apexcharts-menu-item {
        font-family: Avenir-Book;
    }
`
