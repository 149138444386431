import { useParams as usePar, useLocation } from 'react-router'

const useParams = <T = any>(): T => {
    const params = usePar()
    const location = useLocation<History>()

    return ({ ...params, ...location.state } as unknown) as T
}

export default useParams
