import * as React from 'react'

// Components
import { View, Text, Platform } from 'react-native'
import { TouchableItemFeedback } from '@components/touchable/Touchable'
import Checkbox from '@components/checkbox/Checkbox'
import Icon from '@components/icon/Icon'
import Button from '@components/button/Button'
import { Formik } from 'formik'
import { Tooltip, TooltipStyles } from 'react-lightweight-tooltip'
import RootScreen from '@components/rootScreen/RootScreen'
import Alert from '@components/alert/Alert'

// Store
import useI18n from '@store/i18n/useI18n'

// Utils
import Logger from '@utils/logger'

// Api
import api from '@api/api'

// Style
import styled from '@styles/index'
import useTheme from '@styles/useTheme'

export default () => {
    const i18n = useI18n()
    const [Theme] = useTheme()

    const [selectedRole, setSelectedRole] = React.useState<number>(0)
    const [status, setStatus] = React.useState<Status>('loading')
    const [roles, setRoles] = React.useState<RoleList | undefined>(undefined)
    const [rights, setRights] = React.useState<Rights | undefined>(undefined)

    const updateRolesAndRights = () =>
        Promise.all([api.admin.getRoleList(), api.admin.getAllRights()])
            .then(([roles, rights]) => {
                if (!!roles && !!rights) {
                    setRoles(roles)
                    setRights(rights)
                    setStatus('fetched')
                } else {
                    setStatus('error')
                }
            })
            .catch(err => {
                Logger.error(err)
                setStatus('error')
            })

    React.useEffect(() => {
        updateRolesAndRights()
    }, [])

    const organizedRights = rights
        ? rights.reduce((acc, cur) => {
              if (Object.keys(acc).find(key => key === cur.name)) {
                  acc[cur.name].push(cur)
              } else {
                  acc[cur.name] = [cur]
              }
              return acc
          }, {} as { [key: string]: Right[] })
        : {}

    const handleSubmit = (values: ModifyRole, setSubmitting: (isSubmitting: boolean) => void) => {
        Alert({
            title: i18n.t('screens.rolesRightsAdmin.alerts.confirmTitle'),
            content: i18n.t('screens.rolesRightsAdmin.alerts.confirmContent'),
            icon: 'question',
            buttons: [
                {
                    style: 'default',
                    text: i18n.t('screens.rolesRightsAdmin.alerts.confirmButton'),
                    onPress: () => {
                        api.admin
                            .modifyRole(values)
                            .then(() => {
                                Alert({
                                    title: i18n.t('screens.rolesRightsAdmin.alerts.successTitle'),
                                    content: i18n.t('screens.rolesRightsAdmin.alerts.successContent'),
                                    icon: 'success',
                                    buttons: [
                                        {
                                            style: 'default',
                                            text: 'OK',
                                            onPress: () => {
                                                // MAJ des données
                                                updateRolesAndRights()
                                                setSubmitting(false)
                                            },
                                        },
                                    ],
                                    theme: Theme,
                                })
                            })
                            .catch(err => {
                                Alert({
                                    title: i18n.t('screens.rolesRightsAdmin.alerts.errorTitle'),
                                    content: i18n.t('screens.rolesRightsAdmin.alerts.errorContent'),
                                    icon: 'error',
                                    buttons: [
                                        {
                                            style: 'default',
                                            text: 'OK',
                                            onPress: () => setSubmitting(false),
                                        },
                                    ],
                                    theme: Theme,
                                })
                                Logger.error(err)
                            })
                    },
                },
                {
                    style: 'cancel',
                    text: i18n.t('screens.rolesRightsAdmin.alerts.cancelButton'),
                    onPress: () => setSubmitting(false),
                },
            ],
            cancelable: true,
            theme: Theme,
        })
    }

    const rightsAreEqual = (rights1: Right[], rights2: Right[]): boolean =>
        rights1.length === rights2.length &&
        !rights1.find(r => !rights2.find(ri => ri.name === r.name && ri.code === r.code))

    return (
        <RootScreen status={status} scrollable>
            {!!roles && (
                <Formik
                    initialValues={{ id: roles[0].id, rights: roles[0].rights } as ModifyRole}
                    onSubmit={(values, { setSubmitting }) => handleSubmit(values, setSubmitting)}
                >
                    {({ setValues, handleSubmit, values, setFieldValue, isSubmitting }) => {
                        const handleRoleClicked = (index: number) => {
                            if (!rightsAreEqual(values.rights, roles[selectedRole].rights)) {
                                Alert({
                                    title: i18n.t('screens.rolesRightsAdmin.alerts.unsavedTitle'),
                                    content: i18n.t('screens.rolesRightsAdmin.alerts.unsavedContent'),
                                    icon: 'warning',
                                    buttons: [
                                        {
                                            style: 'default',
                                            text: i18n.t('screens.rolesRightsAdmin.alerts.confirmButton'),
                                            onPress: () => {
                                                setSelectedRole(index)
                                                setValues(roles[index])
                                            },
                                        },
                                        {
                                            style: 'cancel',
                                            text: i18n.t('screens.rolesRightsAdmin.alerts.cancelButton'),
                                            onPress: () => undefined,
                                        },
                                    ],
                                    cancelable: true,
                                    theme: Theme,
                                })
                            } else {
                                setSelectedRole(index)
                                setValues(roles[index])
                            }
                        }
                        return (
                            <Container>
                                <RolesContainer>
                                    {roles.map((role, index) => (
                                        <TouchableItemFeedback
                                            style={{ flex: 1 }}
                                            key={role.id}
                                            onPress={() => handleRoleClicked(index)}
                                        >
                                            <RoleContainer selected={selectedRole === index}>
                                                <RoleName selected={selectedRole === index}>{role.name}</RoleName>
                                            </RoleContainer>
                                        </TouchableItemFeedback>
                                    ))}
                                </RolesContainer>
                                <RightsContainer>
                                    <SubmitLine>
                                        <CurrentRole>
                                            {i18n.t('screens.rolesRightsAdmin.roleRights', {
                                                roleName: roles[selectedRole].name,
                                            })}
                                        </CurrentRole>
                                        <Button
                                            onPress={handleSubmit}
                                            libelle={i18n.t('screens.rolesRightsAdmin.submitButton').toUpperCase()}
                                            status={isSubmitting ? 'loading' : 'active'}
                                        />
                                    </SubmitLine>

                                    {Object.keys(organizedRights).map(categoryKey => {
                                        const userHasAllCategoryRights = !organizedRights[categoryKey].find(
                                            r => !values.rights.find(ri => ri.name === r.name && ri.code === r.code),
                                        )
                                        return (
                                            <RightCategoryContainer key={categoryKey}>
                                                <RightCategoryName>
                                                    {i18n.t(
                                                        `screens.rolesRightsAdmin.rightsCategoriesTitles.${organizedRights[categoryKey][0].name}`,
                                                    )}
                                                </RightCategoryName>
                                                <LegendLine>
                                                    <Checkbox
                                                        onCheck={() =>
                                                            setFieldValue(
                                                                'rights',
                                                                userHasAllCategoryRights
                                                                    ? values.rights.filter(r => r.name !== categoryKey)
                                                                    : [
                                                                          ...values.rights.filter(
                                                                              r => r.name !== categoryKey,
                                                                          ),
                                                                          ...organizedRights[categoryKey],
                                                                      ],
                                                            )
                                                        }
                                                        value={userHasAllCategoryRights}
                                                        smallCheckbox
                                                    />
                                                    <Legend>{i18n.t('screens.rolesRightsAdmin.rightLegend')}</Legend>
                                                </LegendLine>
                                                {organizedRights[categoryKey].map((right, index) => {
                                                    const rightDescription = i18n.t(
                                                        `screens.rolesRightsAdmin.rightsDescriptions.${organizedRights[categoryKey][0].name}.${right.code}`,
                                                    )
                                                    const userHasRight = !!values.rights.find(
                                                        r => r.name === right.name && r.code === right.code,
                                                    )
                                                    return (
                                                        <RightLine key={right.code} odd={index % 2 === 1}>
                                                            <Checkbox
                                                                onCheck={() =>
                                                                    setFieldValue(
                                                                        'rights',
                                                                        userHasRight
                                                                            ? values.rights.filter(
                                                                                  r =>
                                                                                      r.name !== right.name ||
                                                                                      r.code !== right.code,
                                                                              )
                                                                            : [...values.rights, right],
                                                                    )
                                                                }
                                                                value={userHasRight}
                                                                smallCheckbox
                                                            />
                                                            <RightTitle>
                                                                {i18n.t(
                                                                    `screens.rolesRightsAdmin.rights.${organizedRights[categoryKey][0].name}.${right.code}`,
                                                                )}
                                                            </RightTitle>
                                                            {!!rightDescription && (
                                                                // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                                                                // @ts-ignore
                                                                <Tooltip
                                                                    styles={tooltipStyles}
                                                                    content={
                                                                        <TooltipContent>
                                                                            <TooltipDescription>
                                                                                {rightDescription}
                                                                            </TooltipDescription>
                                                                        </TooltipContent>
                                                                    }
                                                                >
                                                                    <InfoIcon>
                                                                        <Icon name={'information'} size={15} />
                                                                    </InfoIcon>
                                                                </Tooltip>
                                                            )}
                                                        </RightLine>
                                                    )
                                                })}
                                            </RightCategoryContainer>
                                        )
                                    })}
                                </RightsContainer>
                            </Container>
                        )
                    }}
                </Formik>
            )}
        </RootScreen>
    )
}

const tooltipStyles: TooltipStyles = {
    wrapper: { cursor: 'default' },
    tooltip: {},
    content: {},
    arrow: {},
    gap: {},
}

const Container = styled(View)`
    background-color: ${props => props.theme.colors.rolesBackground};
`
const RolesContainer = styled(View)`
    flex-direction: row;
    margin-bottom: 24px;
`
const RoleContainer = styled(View)<{ selected: boolean }>`
    flex: 1;
    padding-bottom: 10px;
    margin-top: 10px;
    margin-left: 14px;
    margin-right: 14px;
    border-bottom: 2px solid
        ${props => (props.selected ? props.theme.colors.roleNameSelected : props.theme.colors.roleName)};
    text-align: center;

    &:hover {
        border-bottom: 2px solid ${props => props.theme.colors.roleNameSelected};
        & div {
            color: ${props => props.theme.colors.roleNameSelected};
        }
    }
`
const RoleName = styled(Text)<{ selected: boolean }>`
    ${props => props.theme.fonts.roleName}
    ${props => props.selected && `color: ${props.theme.colors.roleNameSelected};`}
    text-transform: uppercase;
`
const SubmitLine = styled(View)`
    flex-direction: row;
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom: 24px;
    align-items: center;
`
const CurrentRole = styled(Text)`
    ${props => props.theme.fonts.roleRights}
    flex: 1;
    text-transform: uppercase;
`
const RightsContainer = styled(View)`
    padding-left: 14px;
    padding-right: 14px;
`
const RightCategoryName = styled(Text)`
    padding-left: 24px;
    margin-bottom: 10px;
    ${props => props.theme.fonts.roleRights}
    color: ${props => props.theme.colors.rightCategory};
    text-transform: uppercase;
`
const RightCategoryContainer = styled(View)`
    margin-top: 10px;
    margin-bottom: 10px;
`
const RightLine = styled(View)<{ odd: boolean }>`
    flex-direction: row;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
    height: ${props => props.theme.constants.rightLineHeight}px;
    ${props => !props.odd && `background-color: ${props.theme.colors.evenLines}`}
`
const RightTitle = styled(Text)`
    flex: 1;
    padding-left: 30px;
    ${props => props.theme.fonts.rightTitle}
`
const LegendLine = styled(View)`
    flex-direction: row;
    height: 34px;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
`
const Legend = styled(Text)`
    padding-left: 30px;
    ${props => props.theme.fonts.rightLegend}
    text-transform: uppercase;
`
const TooltipContent = styled(View)`
    margin-left: 15px;
    margin-right: 15px;
`
const TooltipDescription = styled(Text)`
    ${props => props.theme.fonts.tooltipContent}
`
const InfoIcon = styled(View)`
    color: ${props => props.theme.colors.infoIcon};
    ${Platform.OS === 'web' && 'cursor: pointer;'}
`
