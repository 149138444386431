import { createEvent, createStore } from 'effector'

export type UserState = UserLogin | null

export const initialState: UserState = null

export const actions = {
    setUser: createEvent<UserState>('login'),
    reset: createEvent(),
}

export const store = createStore<UserState>(initialState)
    .on(actions.setUser, (_, v) => v)
    .on(actions.reset, () => initialState)
