import Emitter from '@utils/Emitter'

export interface TypeConnectionState {
    connected: boolean
}

let emitter: TypeEmitter
let connected: boolean

class Connection {
    constructor() {
        emitter = Emitter('connectivity', { connected: true })
        connected = true // TODO: Gestion de la connectivite sur le web ? WebWorkers ?
    }

    isConnected = (): boolean => {
        return connected
    }
    subscribe = (f: MonoCallback<any>): TypeSubscription => {
        return emitter.subscribe(f)
    }
}

export default new Connection()
