import * as React from 'react'

// Components
import { View, Text, ScrollView, TouchableOpacity, Platform } from 'react-native'
import { TouchableItemFeedback } from '@components/touchable/Touchable'
import Button from '@components/button/Button'
import RootScreen from '@components/rootScreen/RootScreen'
import EditDocumentModal from '@components/editDocumentModal/editDocumentModal'
import Icon from '@components/icon/Icon'

// Store
import useI18n from '@store/i18n/useI18n'

// Utils
import Logger from '@utils/logger'
import { openPdfInTab } from '@utils/pdfCommonUtils'

// Api
import api from '@api/api'

// Style
import styled from '@styles/index'
import produce from 'immer'
import useTheme from '@styles/useTheme'

const emptyDocument: AddedDocument = {
    index: 0,
    name: '',
    description: '',
    link: '',
}

const adaptAdminDocumentToAddedDocument = (document: AdminDocument): AddedDocument => ({
    index: document.index,
    name: document.name,
    description: document.description,
    link: document.link,
})

export default () => {
    const i18n = useI18n()
    const [Theme] = useTheme()

    const [status, setStatus] = React.useState<Status>('loading')
    const [documents, setDocuments] = React.useState<AdminDocument[]>([])
    const [modalState, setModalState] = React.useState<'add' | 'edit' | 'closed'>('closed')
    const [documentModal, setDocumentModal] = React.useState<AdminDocument | undefined>(undefined)

    const fetchAndUpdateDocuments = () => {
        setStatus('loading')
        api.admin
            .getAllDocuments()
            .then(documents => {
                if (!!documents) {
                    setDocuments(documents.sort((a, b) => a.index - b.index))
                    setStatus('fetched')
                } else {
                    setStatus('error')
                }
            })
            .catch(err => {
                Logger.error(err)
                setStatus('error')
            })
    }

    React.useEffect(fetchAndUpdateDocuments, [])

    const displayingDocuments = React.useMemo(() => [...documents].sort((a, b) => a.index - b.index), [documents])

    const documentGoDown = (idx: number) => {
        const newDocuments = produce(displayingDocuments, draft => {
            draft[idx].index = idx + 1
            draft[idx + 1].index = idx
        })
        api.admin.editDocument(newDocuments[idx], newDocuments[idx].id)
        api.admin.editDocument(newDocuments[idx + 1], newDocuments[idx + 1].id)
        setDocuments(newDocuments)
    }

    const documentGoUp = (idx: number) => {
        const newDocuments = produce(displayingDocuments, draft => {
            draft[idx].index = idx - 1
            draft[idx - 1].index = idx
        })
        api.admin.editDocument(newDocuments[idx], newDocuments[idx].id)
        api.admin.editDocument(newDocuments[idx - 1], newDocuments[idx - 1].id)
        setDocuments(newDocuments)
    }

    return (
        <RootScreen status={status}>
            <EditDocumentModal
                modalState={modalState}
                onRequestClose={() => {
                    setModalState('closed')
                    setDocumentModal(undefined)
                }}
                initialValues={
                    documentModal ? adaptAdminDocumentToAddedDocument(documentModal) : (emptyDocument as AddedDocument)
                }
                document={documentModal}
                onDocumentEdited={fetchAndUpdateDocuments}
            />
            {
                <Container>
                    <ListTitleContainer>
                        <ListTitle numberOfLines={2} ellipsizeMode={'tail'}>
                            {i18n.t('screens.documentsAdminScreen.documentsList')}
                        </ListTitle>
                        <Button
                            libelle={i18n.t('screens.documentsAdminScreen.addDocument')}
                            onPress={() => setModalState('add')}
                            status="active"
                        />
                    </ListTitleContainer>
                    <DocumentsContainer>
                        <LegendLine>
                            <Legend>{i18n.t('screens.documentsAdminScreen.name')}</Legend>
                        </LegendLine>

                        {displayingDocuments.length === 0 ? (
                            <NoDocumentMessage>{i18n.t('screens.documentsAdminScreen.noDocument')}</NoDocumentMessage>
                        ) : (
                            <ScrollView>
                                {displayingDocuments.map((document, index) => {
                                    return (
                                        <TouchableItemFeedback
                                            key={`document${index}`}
                                            onPress={() => openPdfInTab(document.link)}
                                        >
                                            <DocumentLine odd={index % 2 === 1}>
                                                {index !== 0 ? (
                                                    <ArrowContainer onPress={() => documentGoUp(index)}>
                                                        <Icon
                                                            name="chevron-up"
                                                            size={15}
                                                            color={Theme.colors.documentChevron}
                                                        />
                                                    </ArrowContainer>
                                                ) : (
                                                    <Arrow />
                                                )}
                                                <DocumentName numberOfLines={2} ellipsizeMode={'tail'}>
                                                    {document.name}
                                                </DocumentName>

                                                <ActionContainer>
                                                    <TouchableItemFeedback
                                                        onPress={() => {
                                                            setDocumentModal(document)
                                                            setModalState('edit')
                                                        }}
                                                    >
                                                        <Action>
                                                            {i18n.t('screens.documentsAdminScreen.editDocument')}
                                                        </Action>
                                                    </TouchableItemFeedback>
                                                </ActionContainer>
                                                {displayingDocuments.length !== index + 1 ? (
                                                    <ArrowContainer onPress={() => documentGoDown(index)}>
                                                        <Icon
                                                            name="chevron-down"
                                                            size={15}
                                                            color={Theme.colors.documentChevron}
                                                        />
                                                    </ArrowContainer>
                                                ) : (
                                                    <Arrow />
                                                )}
                                            </DocumentLine>
                                        </TouchableItemFeedback>
                                    )
                                })}
                            </ScrollView>
                        )}
                    </DocumentsContainer>
                </Container>
            }
        </RootScreen>
    )
}

const Container = styled(View)`
    flex: 1;
    background-color: ${props => props.theme.colors.rolesBackground};
`
const DocumentsContainer = styled(View)`
    flex: 1;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 14px;
    padding-right: 14px;
`
const DocumentLine = styled(View)<{ odd: boolean }>`
    flex-direction: row;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
    height: ${props => props.theme.constants.rightLineHeight}px;
    ${props => !props.odd && `background-color: ${props.theme.colors.evenLines};`}

    &:hover {
        background-color: ${props => props.theme.colors.hoverBackground};
        & > div {
            color: ${props => props.theme.colors.hoverText};
            & > div {
                color: ${props => props.theme.colors.hoverText};
                & > div {
                    color: ${props => props.theme.colors.hoverText};
                }
            }
        }
    }
`
const DocumentName = styled(Text)`
    flex: 1;
    ${props => props.theme.fonts.rightTitle}
`
const LegendLine = styled(View)`
    flex-direction: row;
    height: 34px;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
`
const Legend = styled(Text)`
    ${props => props.theme.fonts.rightLegend}
    text-transform: uppercase;
`
const ListTitleContainer = styled(View)`
    flex-direction: row;
    height: ${props => props.theme.constants.riskFamiliesListTitleContainerHeight}px;
    justify-content: space-between;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;

    border-bottom-color: ${props => props.theme.colors.homeListBorderColor};
    border-bottom-width: 2px;
`
const ListTitle = styled(Text)`
    ${props => props.theme.fonts.homeListTitle}
    text-transform: uppercase;
`
const Action = styled(Text)`
    ${props => props.theme.fonts.homeActionLink}
    flex: 1;
    text-decoration: underline;
`
const NoDocumentMessage = styled(Text)`
    ${props => props.theme.fonts.perimeterName}
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
`
const ActionContainer = styled(View)`
    width: 110px;
`
const ArrowContainer = styled(TouchableOpacity)`
    width: 25px;
    height: 25px;
    border-radius: 50px;
    ${props => `border: 1px solid ${props.theme.colors.documentChevronBorder};`}
    background-color: ${props => props.theme.colors.documentChevronBackground};
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    margin-right: 10px;
    ${props =>
        Platform.OS === 'web' &&
        `&:hover {
        ${`border: 1px solid white;`}
        background-color: ${props.theme.colors.documentChevronBackgroundHovered};
        & > div {
            color: ${props.theme.colors.documentChevronHovered};
        }
    }`}
`
const Arrow = styled(View)`
    height: 25px;
    width: 25px;
    margin-left: 10px;
    margin-right: 10px;
`
