import React from 'react'

// Components
import { View, Image, Text } from 'react-native'
import FieldInformation from '@components/fieldInformation/FieldInformation'
import { TouchableItemFeedback } from '@components/touchable/Touchable'
import Icon from '@components/icon/Icon'
import Dropzone from 'react-dropzone'
import { basicAlert } from '@components/alert/Alerts'

// Store
import useI18n from '@store/i18n/useI18n'

// Style
import styled from '@styles/index'
import useTheme from '@styles/useTheme'

const MAX_IMG_SIZE = 2621440 // 2,5 Mo

export default (props: ImageDropInputProps) => {
    const { onChange, value, required, label, error, isClearable, disabled } = props
    const i18n = useI18n()
    const [Theme] = useTheme()

    const onDropAccepted = (files: File[]) => {
        const reader = new FileReader()
        reader.readAsDataURL(files[0])
        reader.onloadend = () => {
            if (!!reader.result) {
                onChange(reader.result.toString())
            } else {
                onDropError()
            }
        }
        reader.onerror = err => onDropError()
    }

    const onDropError = (oversized?: boolean) =>
        oversized
            ? basicAlert(i18n, 'components.imageDropInput.oversized', 'Drop', () => undefined, 'error', Theme)
            : basicAlert(i18n, 'components.imageDropInput', 'Drop', () => undefined, 'error', Theme)

    return (
        <Container>
            <FieldInformation label={label} required={required} error={error} />
            <Dropzone
                maxSize={MAX_IMG_SIZE}
                accept={'image/png,image/jpeg,image/svg+xml,.pdf'}
                onDropAccepted={acceptedFiles => onDropAccepted(acceptedFiles)}
                onDropRejected={(files, evt) => onDropError(files[0].size > MAX_IMG_SIZE)}
                multiple={false}
                disabled={disabled}
            >
                {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                    <DropZoneContent
                        {...getRootProps()}
                        isDragActive={isDragActive}
                        isDragReject={isDragReject}
                        disabled={disabled}
                        error={!!error}
                    >
                        <input {...getInputProps()} />
                        <ImageContainer>
                            {!!value ? (
                                <ImageValue source={{ uri: value }} />
                            ) : (
                                <View>
                                    <NoImage>{i18n.t('components.imageDropInput.noImage')}</NoImage>
                                    <NoImage information={true}>
                                        {i18n.t('components.imageDropInput.information')}
                                    </NoImage>
                                </View>
                            )}
                            {isClearable && !!value && (
                                <ClearButton>
                                    <TouchableItemFeedback disabled={disabled} onPress={() => onChange('')}>
                                        <Icon
                                            name={'cross'}
                                            size={Theme.constants.imageInputClearIconSize}
                                            color={
                                                disabled
                                                    ? Theme.colors.mobilePickerIconDisabled
                                                    : Theme.colors.clearIcon
                                            }
                                        />
                                    </TouchableItemFeedback>
                                </ClearButton>
                            )}
                        </ImageContainer>
                    </DropZoneContent>
                )}
            </Dropzone>
        </Container>
    )
}

const Container = styled(View)`
    flex-direction: column;
`
const DropZoneContent = styled.div<{
    isDragActive: boolean
    isDragReject: boolean
    error: boolean
    disabled?: boolean
}>`
    background-color: ${props => props.theme.colors.imageDropInputBackground};
    ${props => props.error && 'border: ' + props.theme.colors.textInputBorderError + ' solid 1px;'}
    ${props =>
        props.isDragActive &&
        `box-shadow: 0px 0px 0px 5px ${
            props.isDragReject ? props.theme.colors.imageDropInputReject : props.theme.colors.imageDropInputAccept
        } inset;`};
    ${props => !props.disabled && 'cursor: pointer;'}
`
const ImageContainer = styled(View)`
    height: ${props => props.theme.constants.imageDropInputZoneHeight}px;
    justify-content: center;
    align-items: center;
`
const ImageValue = styled(Image)`
    height: ${props => props.theme.constants.imageDropInputPictureSize}px;
    width: ${props => props.theme.constants.imageDropInputPictureSize}px;
`
const NoImage = styled(Text)<{ information?: boolean }>`
    ${props => props.theme.fonts.perimeterName}
    text-align: center;
    ${props => props.information && 'font-style: italic;'}
`
const ClearButton = styled(View)`
    position: absolute;
    top: 10px;
    right: 10px;
`
