import Alert from './Alert'

const basicAlert = (
    i18n: I18nState,
    itemKey: string,
    actionKey: string,
    onConfirmPress: () => void,
    type: 'success' | 'error',
    theme: Theme,
    content?: string,
) =>
    Alert({
        title: i18n.t(`${itemKey}.alerts.${type}${actionKey}Title`),
        content: content || i18n.t(`${itemKey}.alerts.${type}${actionKey}Content`),
        icon: type,
        buttons: [
            {
                style: 'default',
                text: i18n.t('screens.perimetersAdminScreen.alerts.confirmButton'),
                onPress: onConfirmPress,
            },
        ],
        theme,
    })

const confirmAlert = (
    i18n: I18nState,
    itemKey: string,
    actionKey: string,
    onConfirmPress: () => void,
    onCancelPress: () => void,
    theme: Theme,
    confirmLibelle?: string,
    cancelLibelle?: string,
    cancelable = true,
) =>
    Alert({
        title: i18n.t(`${itemKey}.alerts.confirm${actionKey}Title`),
        content: i18n.t(`${itemKey}.alerts.confirm${actionKey}Content`),
        icon: 'warning',
        buttons: [
            {
                style: 'default',
                text: confirmLibelle || i18n.t('screens.perimetersAdminScreen.alerts.confirmButton'),
                onPress: onConfirmPress,
            },
            {
                style: 'cancel',
                text: cancelLibelle || i18n.t('screens.perimetersAdminScreen.alerts.cancelButton'),
                onPress: onCancelPress,
            },
        ],
        cancelable: cancelable,
        theme,
    })

export { basicAlert, confirmAlert }
