import { NavigationState, actions } from '@store/navigation'
import Alert from '@components/alert/Alert'

const onNavigate = (action: () => void, navigationState: NavigationState, Theme: Theme, noAlert?: boolean) => {
    if (!!navigationState.alert && !noAlert) {
        Alert({
            title: navigationState.alert.title,
            content: navigationState.alert.content,
            icon: 'warning',
            buttons: [
                {
                    style: 'default',
                    text: navigationState.alert.confirm,
                    onPress: () => {
                        action()
                        actions.reset()
                    },
                },
                {
                    style: 'cancel',
                    text: navigationState.alert.cancel,
                    onPress: () => undefined,
                },
            ],
            cancelable: true,
            theme: Theme,
        })
    } else {
        action()
    }
}

export { onNavigate }
