import * as React from 'react'
import { Text, View, Image, ImageBackground } from 'react-native'

import styled from '@styles/index'

import Button from '@components/button/Button'
import { ConnectionError } from './Login'

import Azure from '@utils/azure/Azure'

import useI18n from '@store/i18n/useI18n'
import useReducer from '@store/useReducer'
import * as CustomInterfaceStore from '@store/customInterfaceStore'

interface OwnProps {
    connectionError: ConnectionError
}

export default ({ connectionError }: OwnProps): any => {
    const i18n = useI18n()
    const [buttonStatus, setButtonStatus] = React.useState<ButtonStatus>('active')
    const [windowHeight, setWindowHeight] = React.useState<number>(window.innerHeight)

    const logo = useReducer(CustomInterfaceStore.store, s => s.menuLogo)

    React.useLayoutEffect(() => {
        const updateHeight = () => setWindowHeight(window.innerHeight)
        window.addEventListener('resize', updateHeight)
        return () => window.removeEventListener('resize', updateHeight)
    }, [setWindowHeight])

    const login = () => {
        setButtonStatus('loading')
        Azure.login()
    }

    return (
        <Container source={{ uri: require('./HomePicture.png') }} windowHeight={windowHeight}>
            <Background windowHeight={windowHeight} />
            <LiseretContainer windowHeight={windowHeight}>
                <LogoContainer>
                    <Logo source={{ uri: logo }} resizeMode="contain" />
                </LogoContainer>
                <InformationContainer>
                    <AppTextContainer>
                        <ApplicationWelcome>{i18n.t('screens.connection.welcomeWebText')}</ApplicationWelcome>
                        <ApplicationName>{i18n.t('screens.connection.welcomeAppName')}</ApplicationName>
                    </AppTextContainer>
                    <ConnexionContainer>
                        <Button
                            onPress={login}
                            libelle={i18n.t('screens.connection.buttonLibelle')}
                            status={buttonStatus}
                        />
                        {connectionError && (
                            <React.Fragment>
                                <ErrorText>
                                    <ErroMessage>
                                        {connectionError === 'unknownUser'
                                            ? i18n.t('screens.connection.errorMessage.unknownUser.error')
                                            : connectionError === 'otherError'
                                            ? i18n.t('screens.connection.errorMessage.connectionError')
                                            : i18n.t('screens.connection.errorMessage.noNetwork')}
                                    </ErroMessage>
                                    {connectionError === 'unknownUser' ? (
                                        <ContactMessage>
                                            <ContactText>
                                                {i18n.t('screens.connection.errorMessage.unknownUser.contact')}
                                            </ContactText>
                                            <ContactMail>
                                                {i18n.t('screens.connection.errorMessage.unknownUser.mail')}
                                            </ContactMail>
                                        </ContactMessage>
                                    ) : null}
                                </ErrorText>
                            </React.Fragment>
                        )}
                    </ConnexionContainer>
                </InformationContainer>
            </LiseretContainer>
        </Container>
    )
}
const ContactMessage = styled(View)`
    flex-direction: row;
`
const ErrorText = styled(View)`
    justify-content: center;
    align-items: center;
`
const ContactText = styled(Text)`
    color: ${props => props.theme.colors.errorMessage};
    text-align: center;
`
const ContactMail = styled(Text)`
    color: ${props => props.theme.colors.mailToError};
    text-decoration: underline;
    text-decoration-color: ${props => props.theme.colors.mailToError};
    font-weight: bold;
`

const Background = styled(View)<{ windowHeight: number }>`
    position: absolute;
    height: ${props => props.windowHeight}px;
    width: 100%;
    background-color: ${props => props.theme.colors.webLoginBackground};
`
const Container = styled(ImageBackground)<{ windowHeight: number }>`
    height: ${props => props.windowHeight}px;
    background-color: transparent;
`
const LiseretContainer = styled(View)<{ windowHeight: number }>`
    flex-direction: row;
    height: ${props => props.windowHeight * 0.25}px;
    min-height: 200px;
    background-color: ${props => props.theme.colors.webLoginLiseretBackground};
    margin-top: ${props => props.windowHeight * 0.5}px;
    elevation: 3px;
    box-shadow: 1px 1px 10px ${props => props.theme.colors.webBoxShadow};
`
const InformationContainer = styled(View)`
    flex: 1;
    justify-content: space-around;
    align-items: center;
`
const AppTextContainer = styled(View)`
    align-items: center;
    justify-content: space-around;
    height: ${props => props.theme.constants.webLoginAppTextHeight}px;
`
const Logo = styled(Image)`
    height: 100%;
    width: 80%;
`
const ErroMessage = styled(Text)`
    color: ${props => props.theme.colors.errorMessage};
    text-align: center;
    font-style: italic;
    height: ${props => props.theme.constants.webLoginErrorHeight}px;
`
const ConnexionContainer = styled(View)`
    align-items: center;
    justify-content: space-around;
    height: ${props => props.theme.constants.webLoginAppTextHeight}px;
`
const ApplicationWelcome = styled(Text)`
    ${props => props.theme.fonts.webWelcome}
`
const ApplicationName = styled(Text)`
    ${props => props.theme.fonts.webAppName}
`
const LogoContainer = styled(View)`
    flex: 1;
    justify-content: center;
    align-items: center;
`
