import * as React from 'react'
import { View, Platform } from 'react-native'

import styled from '@styles/index'
import IndicatorButton from '@components/indicator/Indicator'
import HomeList from '@components/homeList/HomeList'
import RootScreen from '@components/rootScreen/RootScreen'
import api from '@app/api/api'
import Logger from '@utils/logger'
import useNavigation from '@layout/useNavigation'
import FloatButton from '@components/floatButton/FloatButton'

import { canUserAddICP } from '@utils/userRight'
import { pushStart } from '@utils/push'

import * as userStore from '@store/user'
import * as icpStore from '@store/icp'
import * as filtersStore from '@store/filters'
import useReducer from '@store/useReducer'

import useI18n from '@store/i18n/useI18n'
import useVisible from '@utils/useVisible'

export default () => {
    const i18n = useI18n()
    const navigation = useNavigation()

    const user = useReducer(userStore.store, s => s)
    const homeIndicators = useReducer(icpStore.store, s => s.homeIndicators)

    const [status, setStatus] = React.useState<Status>('loading')
    const [indicators, setIndicators] = React.useState<Indicators | undefined>(undefined)
    const [icpToCome, setIcpToCome] = React.useState<IcpHome[]>([])
    const [refreshingIndicator, setRefreshingIndicator] = React.useState<boolean>(false)

    React.useEffect(() => {
        if (Platform.OS !== 'web') {
            if (user) {
                pushStart(user.id, navigation)
            }
        }
    }, [navigation, user])

    const refreshIndicators = () => {
        setRefreshingIndicator(true)
        api.home
            .indicators()
            .then(data => {
                if (Platform.OS !== 'web') {
                    icpStore.actions.setHomeIndicators(data)
                }
                setIndicators(data.indicators)
                setIcpToCome(data.nextIcp)
                setStatus('fetched')
                setRefreshingIndicator(false)
            })
            .catch(err => {
                if (Platform.OS !== 'web') {
                    setIndicators(homeIndicators.indicators)
                    setIcpToCome(homeIndicators.nextIcp)
                    setStatus('fetched')
                    setRefreshingIndicator(false)
                } else {
                    Logger.error(err)
                    setStatus('error')
                    setRefreshingIndicator(false)
                }
            })
    }

    React.useEffect(refreshIndicators, [])

    const isVisible = useVisible(navigation)
    React.useEffect(refreshIndicators, [isVisible])

    return (
        <React.Fragment>
            {status === 'fetched' && user && canUserAddICP(user) && (
                <FloatButton
                    iconName="addIcp"
                    onPress={() =>
                        Platform.OS === 'web' ? navigation.push('/ajouterICP') : navigation.push('ajouterICP')
                    }
                    libelle={i18n.t('screens.home.addIcp')}
                />
            )}
            <RootScreen
                status={status}
                scrollable
                pullToRefresh={refreshIndicators}
                refreshing={refreshingIndicator}
                synchronizationFeature={Platform.OS !== 'web'}
            >
                {!!indicators && (
                    <IndicatorContainer>
                        <IndicatorButton
                            label={'icpToPlan'}
                            value={indicators.icpToPlan}
                            onPress={() => {
                                filtersStore.actions.setLastScreen('home')
                                navigation.push(Platform.OS === 'web' ? '/icpAPlanifier' : 'icpToPlan')
                            }}
                            isFirst
                        />
                        <IndicatorButton
                            label={'icpToDo'}
                            value={indicators.icpToDo}
                            onPress={() => {
                                filtersStore.actions.setLastScreen('home')
                                navigation.push(Platform.OS === 'web' ? '/icpARealiser' : 'icpToDo')
                            }}
                        />
                        <IndicatorButton
                            label={'icpToEnd'}
                            value={indicators.icpToEnd}
                            onPress={() => {
                                filtersStore.actions.setLastScreen('home')
                                navigation.push(Platform.OS === 'web' ? '/icpAFinaliser' : 'icpToEnd')
                            }}
                        />
                        <IndicatorButton
                            label={'icpToApprove'}
                            value={indicators.icpToApprove}
                            onPress={() => {
                                filtersStore.actions.setLastScreen('home')
                                navigation.push(Platform.OS === 'web' ? '/icpAApprouver' : 'icpToApprove')
                            }}
                        />
                        {indicators.ppeeInProgess !== undefined && (
                            <IndicatorButton
                                label={'ppeeInProgess'}
                                value={indicators.ppeeInProgess}
                                onPress={() => {
                                    filtersStore.actions.setLastScreen('home')
                                    navigation.push(Platform.OS === 'web' ? '/actifEnCours' : 'ppeeInProgress')
                                }}
                            />
                        )}
                    </IndicatorContainer>
                )}
                <HomeList icpToCome={icpToCome} />
            </RootScreen>
        </React.Fragment>
    )
}

const IndicatorContainer = styled(View)`
    flex-direction: ${Platform.OS === 'web' ? 'row' : 'column'};
    justify-content: space-around;
    ${Platform.OS === 'web' && 'margin-bottom: 50px;'}
`
