import axios from 'axios'

import configuration from '@app/config'
import config from '@app/config'

import { actions } from '@store/version'

import Logger from '@utils/logger'

// const PREFIX = (Platform.OS === 'web' ? '' : configuration.SERVER_PREFIX) + '/services/v1'
const PREFIX = configuration.SERVER_PREFIX + '/services/v1'
const VERSION_NUMBER = Number(config.VERSION_NUMBER)

export const checkNewVersion = () => {
    axios
        .request<MobileVersion>({
            url: `${PREFIX}/version`,
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
        })
        .then(res => {
            actions.setShowNewVersionModal(Number(res.data.version) > VERSION_NUMBER)
        })
        .catch(err => {
            Logger.error(err)
            actions.setShowNewVersionModal(false) //On ne bloque pas l'utilisateur si l'appel du ws échoue
        })
}
