import * as React from 'react'

// Components
import { View, Text, ScrollView } from 'react-native'
import SearchInput from '@components/searchInput/SearchInput'
import Icon from '@components/icon/Icon'
import { TouchableItemFeedback } from '@components/touchable/Touchable'
import RootScreen from '@components/rootScreen/RootScreen'
import Alert from '@components/alert/Alert'

// Store
import useI18n from '@store/i18n/useI18n'

// Api
import api from '@api/api'

// Utils
import * as JsSearch from 'js-search'
import accentSanitizer from '@utils/accentSanitizer'

// Style
import styled from '@styles/index'
import TextInput from '@components/textInput/TextInput'

// Formik
import { Formik, FormikErrors } from 'formik'
import Button from '@components/button/Button'
import Logger from '@utils/logger'

// Yup
import * as yup from 'yup'
import useTheme from '@styles/useTheme'

const sanitizer = accentSanitizer()
let searchjs: JsSearch.Search

const initSearchJs = (documents: any[]) => {
    searchjs = new JsSearch.Search('id')
    searchjs.searchIndex = new JsSearch.UnorderedSearchIndex()
    searchjs.indexStrategy = new JsSearch.AllSubstringsIndexStrategy()
    searchjs.tokenizer = {
        tokenize: (text: string) => text.split(' '),
    }
    searchjs.sanitizer = sanitizer
    searchjs.addIndex('libelle')
    searchjs.addDocuments(documents)
}

const companyYupSchema = (i18n: I18nState, allCompanies: Entreprises, libelle?: string) =>
    yup.object().shape({
        libelle: yup
            .string()
            .required(i18n.t('screens.companyAdminScreen.errors.companyLibelle.tooSmall'))
            .test(
                'unique',
                i18n.t('screens.companyAdminScreen.errors.companyLibelle.alreadyExist'),
                libelle
                    ? name =>
                          !allCompanies
                              .filter(name => name.libelle !== libelle)
                              .find(c => sanitizer.sanitize(c.libelle) === sanitizer.sanitize(name))
                    : name => !allCompanies.find(c => sanitizer.sanitize(c.libelle) === sanitizer.sanitize(name)),
            ),
        mail: yup.string().email(i18n.t('screens.companyAdminScreen.errors.mailLibelle')),
    })

const entityYupSchema = (i18n: I18nState, allEntities: AdminAgence[], editingEntity?: FullAgence) =>
    yup.object().shape({
        libelle: yup
            .string()
            .required(i18n.t('screens.companyAdminScreen.errors.entityLibelle.tooSmall'))
            .test('unique', i18n.t('screens.companyAdminScreen.errors.entityLibelle.alreadyExist'), name =>
                editingEntity
                    ? !allEntities
                          .filter(name => name.libelle !== editingEntity.libelle)
                          .find(e => sanitizer.sanitize(e.libelle) === sanitizer.sanitize(name))
                    : !allEntities.find(e => sanitizer.sanitize(e.libelle) === sanitizer.sanitize(name)),
            ),
        mail: yup.string().email(i18n.t('screens.companyAdminScreen.errors.mailLibelle')),
    })

export default () => {
    const i18n = useI18n()
    const [Theme] = useTheme()

    const [sectionOpened, setSectionOpened] = React.useState<string | undefined>()
    const [entityOpened, setEntityOpened] = React.useState<string | undefined>()
    const [status, setStatus] = React.useState<Status>('loading')
    const [text, setText] = React.useState<string>('')
    const [allCompanies, setAllCompanies] = React.useState<FullEntreprises>([])
    const [addingCompany, setAddingCompany] = React.useState<boolean>(false)
    const [addingEntity, setAddingEntity] = React.useState<boolean>(false)
    const [editingEntity, setEditingEntity] = React.useState<boolean>(false)
    const [editingCompany, setEditingCompany] = React.useState<boolean>(false)

    const fetchCompanies = () => {
        api.admin
            .getAllDatas()
            .then(companies => {
                setAllCompanies(companies.sort((a, b) => a.libelle.localeCompare(b.libelle)))
                setStatus('fetched')
            })
            .catch(err => {
                Logger.error('Erreur...', err)
            })
    }

    React.useEffect(fetchCompanies, [])

    const searchedCompanies: FullEntreprises = React.useMemo((): FullEntreprises => {
        const searchedList = (text.trim()
            ? searchjs.search(sanitizer.sanitize(text.trim()))
            : allCompanies) as FullEntreprises
        return searchedList
    }, [allCompanies, text])

    React.useMemo(() => initSearchJs(allCompanies), [allCompanies])

    const addCompany = () => (
        <Formik
            initialValues={{ libelle: '', mail: '', telephone: '', adresse: '' }}
            onSubmit={(values, { setSubmitting }) => handleAddingCompany(values, setSubmitting)}
            validationSchema={companyYupSchema(i18n, allCompanies)}
        >
            {({ values, errors, setFieldValue, handleSubmit, isSubmitting }) => {
                return displayCompany(values, errors, setFieldValue, handleSubmit, isSubmitting, false)
            }}
        </Formik>
    )

    const handleAddingCompany = (company: EntrepriseAdd, setSubmitting: (isSubmitting: boolean) => void) => {
        const addCompany = {
            libelle: company.libelle,
        }
        api.admin
            .addCompany(addCompany)
            .then(entreprise => {
                const entity = {
                    libelle: company.libelle,
                    idEntreprise: entreprise.id,
                    telephone: company.telephone || '',
                    mail: company.mail || '',
                    adresse: company.adresse || '',
                }
                api.admin
                    .addEntity(entity)
                    .then(() => {
                        setAddingCompany(false)
                        setSubmitting(false)
                        fetchCompanies()
                        actionEnded('succeeded', 'company', 'adding')
                    })
                    .catch(err => {
                        setAddingCompany(false)
                        setSubmitting(false)
                        actionEnded('failed', 'entity', 'adding')
                        Logger.error('Erreur...', err)
                    })
            })
            .catch(err => {
                setAddingCompany(false)
                setSubmitting(false)
                actionEnded('failed', 'company', 'adding')
                Logger.error('Erreur...', err)
            })
    }

    const displayCompany = (
        values: EntrepriseAdd | EntrepriseEdit,
        errors: FormikErrors<{
            libelle: string
            mail: string
            telephone: string
            adresse: string
            idEntreprise: string
        }>,
        setFieldValue: (
            field: 'libelle' | 'mail' | 'telephone' | 'adresse',
            value: any,
            shouldValidate?: boolean | undefined,
        ) => void,
        handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void,
        isSubmitting: boolean,
        editing: boolean,
    ) => {
        return (
            <AddingCompanyContainer>
                <AddingEntityLine>
                    <TextInputContainer>
                        <TextInput
                            label={i18n.t('screens.companyAdminScreen.fieldLabels.companyName')}
                            value={values.libelle}
                            onTextChange={text => setFieldValue('libelle', text)}
                            required
                            error={errors.libelle}
                            placeholder={i18n.t('screens.companyAdminScreen.placeHolder.companyName')}
                            minHeight={25}
                        />
                    </TextInputContainer>
                </AddingEntityLine>
                <AddingEntityLine>
                    <TextInputContainer>
                        <TextInput
                            label={i18n.t('screens.companyAdminScreen.fieldLabels.companyAdresse')}
                            value={values.adresse}
                            onTextChange={text => setFieldValue('adresse', text)}
                            error={errors.adresse}
                            placeholder={i18n.t('screens.companyAdminScreen.placeHolder.companyAdresse')}
                            minHeight={25}
                        />
                    </TextInputContainer>
                </AddingEntityLine>
                <AddingEntityLine>
                    <TextInputContainer>
                        <TextInput
                            label={i18n.t('screens.companyAdminScreen.fieldLabels.companyTelephone')}
                            value={values.telephone}
                            onTextChange={text => setFieldValue('telephone', text)}
                            error={errors.telephone}
                            placeholder={i18n.t('screens.companyAdminScreen.placeHolder.companyTelephone')}
                            minHeight={25}
                        />
                    </TextInputContainer>
                    <Separator />
                    <TextInputContainer>
                        <TextInput
                            label={i18n.t('screens.companyAdminScreen.fieldLabels.companyMail')}
                            value={values.mail}
                            onTextChange={text => setFieldValue('mail', text)}
                            error={errors.mail}
                            placeholder={i18n.t('screens.companyAdminScreen.placeHolder.companyMail')}
                            minHeight={25}
                        />
                    </TextInputContainer>
                </AddingEntityLine>
                <ButtonContainer>
                    <Button
                        libelle={
                            editing
                                ? i18n.t('screens.companyAdminScreen.editCompany')
                                : i18n.t('screens.companyAdminScreen.addCompany')
                        }
                        onPress={handleSubmit}
                        status={isSubmitting ? 'loading' : 'active'}
                    />
                </ButtonContainer>
            </AddingCompanyContainer>
        )
    }

    const displayEntities = (
        values: Agence | CreateAgence,
        errors: FormikErrors<{
            libelle: string
            mail: string
            telephone: string
            adresse: string
            idEntreprise: string
        }>,
        setFieldValue: (
            field: 'libelle' | 'mail' | 'telephone' | 'adresse' | 'idEntreprise',
            value: any,
            shouldValidate?: boolean | undefined,
        ) => void,
        handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void,
        isSubmitting: boolean,
        editing: boolean,
        c?: FullEntreprise,
        e?: FullAgence,
    ) => {
        const disabled = !!c && !!e && c.libelle === e.libelle
        return (
            <AddingCompanyContainer>
                <AddingEntityLine>
                    <TextInputContainer>
                        <TextInput
                            label={i18n.t('screens.companyAdminScreen.fieldLabels.entityName')}
                            value={values.libelle}
                            onTextChange={text => setFieldValue('libelle', text)}
                            required
                            error={errors.libelle}
                            placeholder={i18n.t('screens.companyAdminScreen.placeHolder.entityName')}
                            minHeight={25}
                            disabled={disabled}
                        />
                    </TextInputContainer>
                </AddingEntityLine>
                <AddingEntityLine>
                    <TextInputContainer>
                        <TextInput
                            label={i18n.t('screens.companyAdminScreen.fieldLabels.entityAdresse')}
                            value={values.adresse}
                            onTextChange={text => setFieldValue('adresse', text)}
                            error={errors.adresse}
                            placeholder={i18n.t('screens.companyAdminScreen.placeHolder.entityAdresse')}
                            minHeight={25}
                        />
                    </TextInputContainer>
                </AddingEntityLine>
                <AddingEntityLine>
                    <TextInputContainer>
                        <TextInput
                            label={i18n.t('screens.companyAdminScreen.fieldLabels.entityTelephone')}
                            value={values.telephone}
                            onTextChange={text => setFieldValue('telephone', text)}
                            error={errors.telephone}
                            placeholder={i18n.t('screens.companyAdminScreen.placeHolder.entityTelephone')}
                            minHeight={25}
                        />
                    </TextInputContainer>
                    <Separator />
                    <TextInputContainer>
                        <TextInput
                            label={i18n.t('screens.companyAdminScreen.fieldLabels.entityMail')}
                            value={values.mail}
                            onTextChange={text => setFieldValue('mail', text)}
                            error={errors.mail}
                            placeholder={i18n.t('screens.companyAdminScreen.placeHolder.entityMail')}
                            minHeight={25}
                        />
                    </TextInputContainer>
                </AddingEntityLine>
                <ButtonContainer>
                    <Button
                        libelle={
                            editing
                                ? i18n.t('screens.companyAdminScreen.editEntity')
                                : i18n.t('screens.companyAdminScreen.addEntity')
                        }
                        onPress={handleSubmit}
                        status={isSubmitting ? 'loading' : 'active'}
                    />
                </ButtonContainer>
            </AddingCompanyContainer>
        )
    }

    const addEntity = (c: FullEntreprise) => (
        <Formik
            initialValues={{ libelle: '', mail: '', telephone: '', adresse: '', idEntreprise: c.id }}
            onSubmit={(values, { setSubmitting }) => handleAddingEntity(values, setSubmitting)}
            validationSchema={entityYupSchema(i18n, c.entities)}
        >
            {({ values, errors, setFieldValue, handleSubmit, isSubmitting }) => {
                return displayEntities(values, errors, setFieldValue, handleSubmit, isSubmitting, false)
            }}
        </Formik>
    )

    const editEntity = (e: FullAgence, c: FullEntreprise) => (
        <Formik
            initialValues={{
                id: e.id,
                libelle: e.libelle,
                idEntreprise: e.idEntreprise,
                telephone: e.telephone,
                mail: e.mail,
                adresse: e.adresse,
            }}
            onSubmit={(values, { setSubmitting }) => handleEditingEntity(values, setSubmitting)}
            validationSchema={entityYupSchema(i18n, c.entities, e)}
        >
            {({ values, errors, setFieldValue, handleSubmit, isSubmitting }) => {
                return displayEntities(values, errors, setFieldValue, handleSubmit, isSubmitting, true, c, e)
            }}
        </Formik>
    )

    const editCompany = (c: FullEntreprise, e: EntrepriseEdit) => (
        <Formik
            initialValues={{
                id: c.id,
                libelle: c.libelle,
                mail: e.mail,
                telephone: e.telephone,
                adresse: e.adresse,
            }}
            onSubmit={(values, { setSubmitting }) => handleEditingCompany(values, setSubmitting, c.libelle)}
            validationSchema={companyYupSchema(i18n, allCompanies, c.libelle)}
        >
            {({ values, errors, setFieldValue, handleSubmit, isSubmitting }) => {
                return displayCompany(values, errors, setFieldValue, handleSubmit, isSubmitting, true)
            }}
        </Formik>
    )

    const handleAddingEntity = (values: CreateAgence, setSubmitting: (isSubmitting: boolean) => void) => {
        api.admin
            .addEntity(values)
            .then(() => {
                setAddingEntity(false)
                setSubmitting(false)
                fetchCompanies()
                actionEnded('succeeded', 'entity', 'adding')
            })
            .catch(err => {
                setAddingEntity(false)
                setSubmitting(false)
                actionEnded('failed', 'entity', 'adding')
                Logger.error('Erreur...', err)
            })
    }

    const handleEditingEntity = (values: Agence, setSubmitting: (isSubmitting: boolean) => void) => {
        api.admin
            .editEntity(values)
            .then(() => {
                setEditingEntity(false)
                setSubmitting(false)
                fetchCompanies()
                actionEnded('succeeded', 'entity', 'editing')
            })
            .catch(err => {
                setEditingEntity(false)
                setSubmitting(false)
                actionEnded('failed', 'entity', 'editing')
                Logger.error('Erreur...', err)
            })
    }

    const handleEditingCompany = (
        values: EntrepriseEdit,
        setSubmitting: (isSubmitting: boolean) => void,
        libelle: string,
    ) => {
        const companyToEdit = {
            id: values.id,
            libelle: values.libelle,
        }
        api.admin
            .editCompany(companyToEdit)
            .then(entreprise => {
                const editEntity = entreprise.entities.find(name => name.libelle === libelle)
                if (editEntity) {
                    const entity = {
                        id: editEntity.id,
                        libelle: entreprise.libelle,
                        idEntreprise: entreprise.id,
                        telephone: values.telephone || '',
                        mail: values.mail || '',
                        adresse: values.adresse || '',
                    }
                    api.admin
                        .editEntity(entity)
                        .then(() => {
                            setEditingCompany(false)
                            setSubmitting(false)
                            fetchCompanies()
                            actionEnded('succeeded', 'company', 'editing')
                        })
                        .catch(err => {
                            setEditingCompany(false)
                            setSubmitting(false)
                            actionEnded('failed', 'company', 'editing')
                            Logger.error('Erreur...', err)
                        })
                } else {
                    setEditingCompany(false)
                    setSubmitting(false)
                    fetchCompanies()
                    actionEnded('succeeded', 'company', 'editing')
                }
            })
            .catch(err => {
                setEditingCompany(false)
                setSubmitting(false)
                actionEnded('failed', 'company', 'editing')
                Logger.error('Erreur...', err)
            })
    }

    const deleteFunction = (id: string, type: 'company' | 'entity') => {
        Alert({
            title: i18n.t(`screens.companyAdminScreen.alerts.delete.${type}.title`),
            content: i18n.t(`screens.companyAdminScreen.alerts.delete.${type}.content`),
            icon: 'question',
            buttons: [
                {
                    style: 'default',
                    text: i18n.t(`screens.companyAdminScreen.alerts.delete.${type}.confirm`),
                    onPress: () => (type === 'company' ? deleteCompany(id) : deleteEntity(id)),
                },
                {
                    style: 'cancel',
                    text: i18n.t(`screens.companyAdminScreen.alerts.delete.${type}.cancel`),
                },
            ],
            cancelable: true,
            theme: Theme,
        })
    }

    const actionEnded = (
        succeeded: 'succeeded' | 'failed',
        type: 'company' | 'entity',
        state: 'deleting' | 'editing' | 'adding',
    ) => {
        Alert({
            title: i18n.t(`screens.companyAdminScreen.alerts.${state}.${succeeded}.${type}.title`),
            content: i18n.t(`screens.companyAdminScreen.alerts.${state}.${succeeded}.${type}.content`),
            icon: succeeded === 'succeeded' ? 'success' : 'error',
            buttons: [
                {
                    style: 'default',
                    text: i18n.t(`screens.companyAdminScreen.alerts.${state}.${succeeded}.${type}.confirm`),
                    onPress: () => undefined,
                },
            ],
            cancelable: true,
            theme: Theme,
        })
    }

    const deleteEntity = (id: string) =>
        api.admin
            .deleteEntity(id)
            .then(() => {
                fetchCompanies()
                actionEnded('succeeded', 'entity', 'deleting')
            })
            .catch(() => {
                fetchCompanies()
                actionEnded('failed', 'entity', 'deleting')
            })

    const deleteCompany = (id: string) =>
        api.admin
            .deleteCompany(id)
            .then(() => {
                fetchCompanies()
                actionEnded('succeeded', 'company', 'deleting')
            })
            .catch(err => {
                fetchCompanies()
                actionEnded('failed', 'company', 'deleting')
            })

    const renderCompany = (c: FullEntreprise, i: number) => {
        return (
            <React.Fragment>
                <TouchableItemFeedback
                    key={i}
                    onPress={() => setSectionOpened(c.id === sectionOpened ? undefined : c.id)}
                >
                    <CompanyItemContainer even={i % 2 === 0}>
                        <CompanyItem>{c.libelle}</CompanyItem>
                        <CompanyItem>
                            {c.id !== sectionOpened && c.entities.length !== 0 && (
                                <Text>
                                    {i18n.t('screens.companyAdminScreen.entitiesList', {
                                        e: c.entities[0].libelle,
                                        c: c.entities.length - 1,
                                        count: c.entities.length,
                                    })}
                                </Text>
                            )}
                        </CompanyItem>
                        {c.entities.length === 1 && (
                            <ActionItem>
                                <TouchableItemFeedback onPress={() => deleteFunction(c.id, 'company')}>
                                    <ActionItem>
                                        <Icon name="cross" size={10} color={Theme.colors.infoIconClosed} />
                                    </ActionItem>
                                </TouchableItemFeedback>
                            </ActionItem>
                        )}
                        <ActionItem>
                            <Icon
                                name="information"
                                size={20}
                                color={
                                    c.id === sectionOpened ? Theme.colors.infoIconOpened : Theme.colors.infoIconClosed
                                }
                            />
                        </ActionItem>
                    </CompanyItemContainer>
                </TouchableItemFeedback>
                {c.id === sectionOpened && renderEntities(c, i)}
            </React.Fragment>
        )
    }

    const renderEntities = (c: FullEntreprise, j: number) => {
        const even = j % 2 === 0
        const infosEntreprise = c.entities.find(name => name.libelle === c.libelle)

        const infosToSend = infosEntreprise
            ? {
                  id: infosEntreprise.id,
                  libelle: infosEntreprise.libelle,
                  mail: infosEntreprise.mail,
                  telephone: infosEntreprise.telephone,
                  adresse: infosEntreprise.adresse,
              }
            : { id: '', libelle: '', mail: '', telephone: '', adresse: '' }

        return (
            <EntitiesContainer even={even}>
                <EntitiesMainTitleContainer>
                    <EntitiesMainTitle>
                        {i18n.t('screens.companyAdminScreen.entitiesListTitle', { count: c.entities.length })}
                    </EntitiesMainTitle>
                    <TouchableItemFeedback onPress={() => setEditingCompany(!editingCompany)}>
                        <Action>
                            {editingCompany
                                ? i18n.t('screens.companyAdminScreen.cancel')
                                : i18n.t('screens.companyAdminScreen.editCompany')}
                        </Action>
                    </TouchableItemFeedback>
                    <TouchableItemFeedback onPress={() => setAddingEntity(!addingEntity)}>
                        <Action>
                            {addingEntity
                                ? i18n.t('screens.companyAdminScreen.cancel')
                                : i18n.t('screens.companyAdminScreen.addEntity')}
                        </Action>
                    </TouchableItemFeedback>
                </EntitiesMainTitleContainer>
                {editingCompany && editCompany(c, infosToSend)}
                {addingEntity && addEntity(c)}
                <AllEntitiesContainer even={even}>
                    {c.entities.length ? (
                        <React.Fragment>
                            <EntityListTitles>
                                <EntityListTitle>
                                    {i18n.t('screens.companyAdminScreen.entityNameTitle', {
                                        count: c.entities.length,
                                    })}
                                </EntityListTitle>
                                <EntityListTitle>{i18n.t('screens.companyAdminScreen.contactsTitle')}</EntityListTitle>
                            </EntityListTitles>
                            {c.entities.map((e, k) => renderEntity(e, c, (j % 2) + k))}
                        </React.Fragment>
                    ) : (
                        <NoEntityLibelle>{i18n.t('screens.companyAdminScreen.noEntity')}</NoEntityLibelle>
                    )}
                </AllEntitiesContainer>
            </EntitiesContainer>
        )
    }

    const renderEntity = (e: FullAgence, c: FullEntreprise, k: number) => {
        const even = k % 2 === 0
        return (
            <React.Fragment>
                <TouchableEntityItems
                    onPress={() => {
                        setEntityOpened(e.id === entityOpened ? undefined : e.id)
                        setEditingEntity(false)
                    }}
                >
                    <EntityItemContainer even={even}>
                        <EntitiesItems>
                            <EntityItem hoverable>{e.libelle}</EntityItem>
                            {e.contacts.length !== 0 && (
                                <EntityItem>
                                    {i18n.t('screens.companyAdminScreen.contactsList', {
                                        e: e.contacts[0].nom + ' ' + e.contacts[0].prenom,
                                        c: e.contacts.length - 1,
                                        count: e.contacts.length,
                                    })}
                                </EntityItem>
                            )}
                            <ActionContainer>
                                {e.contacts.length === 0 && e.libelle !== c.libelle && (
                                    <TouchableItemFeedback onPress={() => deleteFunction(e.id, 'entity')}>
                                        <ActionItem>
                                            <Icon name="cross" size={10} color={Theme.colors.infoIconClosed} />
                                        </ActionItem>
                                    </TouchableItemFeedback>
                                )}
                                <ActionItem>
                                    <Icon
                                        name="information"
                                        size={20}
                                        color={
                                            e.id === entityOpened
                                                ? Theme.colors.infoIconOpened
                                                : Theme.colors.infoIconClosed
                                        }
                                    />
                                </ActionItem>
                            </ActionContainer>
                        </EntitiesItems>
                        {e.id === entityOpened && (
                            <EntityInformationContainer>
                                <EntityInformationItem>
                                    {e.mail || i18n.t('screens.companyAdminScreen.noMail')}
                                </EntityInformationItem>
                                <EntityInformationItem>
                                    {e.telephone || i18n.t('screens.companyAdminScreen.noTelephone')}
                                </EntityInformationItem>
                                <EntityInformationItem>
                                    {e.adresse || i18n.t('screens.companyAdminScreen.noAdresse')}
                                </EntityInformationItem>
                                <TouchableItemFeedback onPress={() => setEditingEntity(!editingEntity)}>
                                    <EditButtonContainer>
                                        <Action>
                                            {editingEntity
                                                ? i18n.t('screens.companyAdminScreen.cancel')
                                                : i18n.t('screens.companyAdminScreen.editEntity')}
                                        </Action>
                                    </EditButtonContainer>
                                </TouchableItemFeedback>
                            </EntityInformationContainer>
                        )}
                    </EntityItemContainer>
                </TouchableEntityItems>
                {e.id === entityOpened && editingEntity && editEntity(e, c)}
            </React.Fragment>
        )
    }

    return (
        <RootScreen status={status}>
            <SearchResultsContainer zIndex={-3}>
                <SearchInput
                    onTextChange={setText}
                    placeholder={i18n.t('screens.companyAdminScreen.searchPlaceholder')}
                    resultsCount={searchedCompanies.length}
                />
            </SearchResultsContainer>
            <CompaniesContainer>
                <MainTitleContainer>
                    <MainTitle>
                        {i18n.t('screens.companyAdminScreen.listTitle', { count: searchedCompanies.length })}
                    </MainTitle>
                    <TouchableItemFeedback onPress={() => setAddingCompany(!addingCompany)}>
                        <Action>
                            {addingCompany
                                ? i18n.t('screens.companyAdminScreen.cancel')
                                : i18n.t('screens.companyAdminScreen.addCompany')}
                        </Action>
                    </TouchableItemFeedback>
                </MainTitleContainer>
                {addingCompany && addCompany()}
                <AllCompaniesContainer>
                    {searchedCompanies.length !== 0 ? (
                        <React.Fragment>
                            <CompanyListTitles>
                                <CompanyListTitle>{i18n.t('screens.companyAdminScreen.nameTitle')}</CompanyListTitle>
                                <CompanyListTitle>
                                    {i18n.t('screens.companyAdminScreen.entitiesTitle')}
                                </CompanyListTitle>
                            </CompanyListTitles>
                            <ScrollView>{searchedCompanies.map((c, i) => renderCompany(c, i))}</ScrollView>
                        </React.Fragment>
                    ) : (
                        <NoCompanyLibelle>{i18n.t('screens.companyAdminScreen.noCompany')}</NoCompanyLibelle>
                    )}
                </AllCompaniesContainer>
            </CompaniesContainer>
        </RootScreen>
    )
}

// ADDING COMPANY / ENTITY
const Separator = styled(View)`
    width: 30px;
`
const ButtonContainer = styled(View)`
    align-items: flex-end;
    margin-top: 10px;
`
const AddingCompanyContainer = styled(View)`
    background-color: ${props => props.theme.colors.evenLine};
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 20px;
    margin-bottom: 20px;
`
const AddingEntityLine = styled(View)`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
`
const TextInputContainer = styled(View)`
    flex: 1;
`

//SEARCH
const SearchResultsContainer = styled(View)<{ zIndex: number }>`
    z-index: ${props => props.zIndex};
    margin-bottom: 60px;
`
const Action = styled(Text)`
    ${props => props.theme.fonts.homeActionLink}
    flex: 1;
    text-decoration: underline;
`

// COMPANIES
const ActionItem = styled(View)`
    margin-right: 5px;
    &:hover {
        & > div {
            color: ${props => props.theme.colors.deleteIconHover};
        }
    }
`
const CompanyListTitles = styled(View)`
    flex-direction: row;
    align-items: center;
    padding: 10px;
    margin-right: 20px;
`
const CompanyListTitle = styled(Text)`
    ${props => props.theme.fonts.tabTitle};
    flex: 1;
`
const CompanyItemContainer = styled(View)<{ even: boolean }>`
    flex-direction: row;
    align-items: center;
    padding: 10px;
    background-color: ${props => (props.even ? props.theme.colors.evenLine : props.theme.colors.oddLine)};

    &:hover {
        background-color: ${props => props.theme.colors.hoverBackground};
        & > div {
            color: ${props => props.theme.colors.hoverText};
            & > div {
                color: ${props => props.theme.colors.hoverText};
            }
        }
    }
`
const CompanyItem = styled(Text)`
    ${props => props.theme.fonts.companyName};
    flex: 1;
`
const CompaniesContainer = styled(View)`
    flex: 1;
`
const MainTitleContainer = styled(View)`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
`
const NoCompanyLibelle = styled(Text)`
    ${props => props.theme.fonts.noItemLabel}
`
const MainTitle = styled(Text)`
    ${props => props.theme.fonts.mainTitle}
`
const AllCompaniesContainer = styled(View)`
    flex: 1;
    background-color: white;
    padding: 20px 50px;
`

// ENTITIES
const ActionContainer = styled(View)`
    flex-direction: row;
    align-items: center;
`
const EditButtonContainer = styled(View)`
    margin-top: 10px;
`
const TouchableEntityItems = styled(TouchableItemFeedback)`
    flex-direction: row;
    background-color: green;
`
const EntityInformationItem = styled(Text)`
    ${props => props.theme.fonts.entityInformation}
`
const EntityInformationContainer = styled(View)`
    flex-direction: column;
    align-items: flex-start;
    padding-top: 5px;
    padding-left: 15px;
`
const EntityListTitles = styled(View)`
    flex-direction: row;
    align-items: center;
    margin-right: 40px;
`
const EntityListTitle = styled(Text)`
    ${props => props.theme.fonts.smallTabTitle};
    padding: 10px;
    flex: 1;
`
const EntityItemContainer = styled(View)<{ even: boolean }>`
    flex-direction: column;
    padding: 10px;
    background-color: ${props => (props.even ? props.theme.colors.evenLine : props.theme.colors.oddLine)};
    &:hover {
        background-color: ${props => props.theme.colors.hoverBackground};
        & > div {
            color: ${props => props.theme.colors.hoverText};
            & > div {
                color: ${props => props.theme.colors.hoverText};
                & > div {
                    color: ${props => props.theme.colors.hoverText};
                    & > div {
                        color: ${props => props.theme.colors.hoverText};
                    }
                }
            }
        }
    }
`
const EntityItem = styled(Text)<{ hoverable?: boolean }>`
    ${props => props.theme.fonts.tabTitle};
    flex: 1;
`
const EntitiesContainer = styled(View)<{ even: boolean }>`
    padding: 10px 20px;
    background-color: ${props => (props.even ? props.theme.colors.evenLine : props.theme.colors.oddLine)};
`
const EntitiesMainTitleContainer = styled(View)`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
`
const NoEntityLibelle = styled(Text)`
    ${props => props.theme.fonts.noItemLabel}
`
const EntitiesMainTitle = styled(Text)`
    ${props => props.theme.fonts.entityTitle}
    padding-left: 10px;
`
const AllEntitiesContainer = styled(View)<{ even: boolean }>`
    background-color: white;
    padding: 10px 20px;
    background-color: ${props => (!props.even ? props.theme.colors.evenLine : props.theme.colors.oddLine)};
`
const EntitiesItems = styled(View)`
    flex-direction: row;
`
