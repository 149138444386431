import * as React from 'react'

// Components
import { View, Text, Platform, TouchableOpacity } from 'react-native'
import Checkbox from '@components/checkbox/Checkbox'
import DeclarationInformation from './DeclarationInformation'
import PdfInformation from './PdfInformation'
import Button from '@components/button/Button'
import { basicAlert, confirmAlert } from '@components/alert/Alerts'
import Picker from '@components/picker/Picker'
import Icon from '@components/icon/Icon'

// Utils
import { getDay, getMonth, getYear, getTime } from 'date-fns'

// Api
import { downloadWorkInspection } from '@utils/pdfUtils'

// Utils
import Logger from '@utils/logger'

// Store
import useI18n from '@store/i18n/useI18n'

// Style
import styled from '@styles/index'
import api from '@app/api/api'
import useTheme from '@styles/useTheme'

interface Props {
    onItemChange: (value: Declaration) => void
    values: Declaration
    error?: string
    adresses?: string[]
    wiItems?: ConfigurationEmails
    icpId?: string
    canSendEmail: boolean
    handleSubmitWithoutAlert: () => Promise<void>
    alreadySending: boolean
    setAlreadySending: (sending: boolean) => void
}

const I18N_ITEM_KEY = 'components.planIcp'
export const idPrefix = 'NEW_WI_'

export default (props: Props) => {
    const [downloadingPdf, setDownloadingPdf] = React.useState<boolean>(false)
    const {
        values,
        onItemChange,
        wiItems,
        icpId,
        canSendEmail,
        handleSubmitWithoutAlert,
        alreadySending,
        setAlreadySending,
    } = props
    const i18n = useI18n()
    const [Theme] = useTheme()

    const [sendingWorkInspection, setSendingWorkInspection] = React.useState<boolean>(false)

    const [documentLink, emails] = React.useMemo(() => {
        return !!wiItems ? [wiItems.pdf || '', wiItems.emails.map(adminMailItem => adminMailItem.email)] : ['', []]
    }, [wiItems])

    const handleDownloadPdf = (icpId: string) =>
        downloadWorkInspection(icpId)
            .then(() => setDownloadingPdf(false))
            .catch(err => {
                basicAlert(i18n, I18N_ITEM_KEY, 'DownloadPdf', () => undefined, 'error', Theme)
                setDownloadingPdf(false)
                Logger.error(err)
            })

    const sendInvitationByMail = (id: string) => {
        setAlreadySending(true)
        setSendingWorkInspection(true)
        confirmAlert(
            i18n,
            I18N_ITEM_KEY,
            'sendPdfWi',
            () => {
                handleSubmitWithoutAlert()
                    .then(() =>
                        api.icp
                            .sendToWorkInspection(id)
                            .then(datas =>
                                basicAlert(
                                    i18n,
                                    I18N_ITEM_KEY,
                                    'SendingWi',
                                    () => {
                                        setAlreadySending(false)
                                        setSendingWorkInspection(false)
                                        onItemChange({
                                            ...datas.planification.declaration,
                                        })
                                    },
                                    'success',
                                    Theme,
                                ),
                            )
                            .catch(er =>
                                basicAlert(
                                    i18n,
                                    I18N_ITEM_KEY,
                                    'SendingWi',
                                    () => {
                                        setAlreadySending(false)
                                        setSendingWorkInspection(false)
                                        Logger.error('Error on sending email...', er)
                                    },
                                    'error',
                                    Theme,
                                ),
                            ),
                    )
                    .catch(() => {
                        setAlreadySending(false)
                        setSendingWorkInspection(false)
                        Logger.error("Erreur à l'enregistrement...")
                    })
            },
            () => {
                setAlreadySending(false)
                setSendingWorkInspection(false)
            },
            Theme,
        )
    }

    const generateId = (timestamp: Date) =>
        idPrefix + getDay(timestamp) + getMonth(timestamp) + getYear(timestamp) + getTime(timestamp)

    const workInspections = React.useMemo(() => values.workInspection, [values])
    const checkboxIsDisabled = React.useMemo((): boolean => {
        return !!workInspections && !!workInspections.find(i => i.hasBeenSent || i.hasBeenSentByEmail)
    }, [workInspections])

    const isMobile = Platform.OS !== 'web'

    const emailAlreadySent = React.useMemo(() => values.workInspection.filter(w => !!w.hasBeenSentByEmail), [
        values.workInspection,
    ])
    const emailToSend = React.useMemo(() => values.workInspection.filter(w => !w.hasBeenSentByEmail), [
        values.workInspection,
    ])

    const displayingWiChoice = React.useMemo(
        () => emails.filter(e => values.workInspection.findIndex(v => v.email === e) === -1),
        [values.workInspection, emails],
    )

    const canIndicateSendByPost = !values.workInspection || values.workInspection.length === 0

    const hasBeenSentByPost =
        values.workInspection.length === 1 && !values.workInspection[0].byEmail && values.workInspection[0].hasBeenSent

    return (
        <Container>
            <CheckboxContainer>
                <Checkbox
                    label={i18n.t(`components.planIcp.labels.workInspection`)}
                    value={values.wiOpened}
                    onCheck={() => {
                        onItemChange({
                            ...values,
                            wiOpened: !values.wiOpened,
                        })
                    }}
                    disabled={checkboxIsDisabled}
                />
                <ActionContainer>
                    <PdfInformation pdfLink={documentLink} />
                    <DeclarationInformation propsKey="workInspection" />
                </ActionContainer>
            </CheckboxContainer>
            {values.wiOpened && !!workInspections && (
                <React.Fragment>
                    {!canSendEmail ? (
                        <MissingInformationsText>
                            {i18n.t('components.planIcp.labels.cssctMissingInformations')}
                        </MissingInformationsText>
                    ) : (
                        !hasBeenSentByPost && (
                            <PickerContainer>
                                <PickerBackgroundContainer>
                                    <Picker
                                        key={values.workInspection.length}
                                        value={undefined}
                                        data={displayingWiChoice}
                                        onChange={index => {
                                            onItemChange({
                                                ...values,
                                                workInspection: [
                                                    ...workInspections,
                                                    {
                                                        id: generateId(new Date()),
                                                        email: displayingWiChoice[index],
                                                        byEmail: true,
                                                        hasBeenSent: false,
                                                        hasBeenSentByEmail: false,
                                                    },
                                                ],
                                            })
                                        }}
                                        placeholder={i18n.t('components.planIcp.placeholders.workinspection')}
                                        label={i18n.t('components.planIcp.labels.workinspection')}
                                    />
                                </PickerBackgroundContainer>
                                {values.workInspection.length !== 0 && (
                                    <CollaborateurListContainer>
                                        {values.workInspection.map((w, i) => (
                                            <CollaborateurContainer key={i}>
                                                <CollabotateurLibelle ellipsizeMode="tail" numberOfLines={1}>
                                                    {w.email}
                                                </CollabotateurLibelle>
                                                {!w.hasBeenSentByEmail && (
                                                    <TouchableOpacity
                                                        onPress={() =>
                                                            onItemChange({
                                                                ...values,
                                                                workInspection: values.workInspection.filter(
                                                                    a => a.id !== w.id,
                                                                ),
                                                            })
                                                        }
                                                    >
                                                        <Icon
                                                            name="cross"
                                                            size={10}
                                                            color={Theme.colors.documentChevron}
                                                        />
                                                    </TouchableOpacity>
                                                )}
                                            </CollaborateurContainer>
                                        ))}
                                    </CollaborateurListContainer>
                                )}
                            </PickerContainer>
                        )
                    )}
                    {icpId && emailToSend.length !== 0 && !hasBeenSentByPost && (
                        <ButtonContainer>
                            <Button
                                libelle={i18n.t('components.planIcp.labels.workInspectionSend')}
                                onPress={() => emailToSend[0] && emailToSend[0].email && sendInvitationByMail(icpId)}
                                status={
                                    sendingWorkInspection
                                        ? 'loading'
                                        : !!emailToSend.find(e => !e.id) || alreadySending
                                        ? 'disabled'
                                        : 'active'
                                }
                            />
                        </ButtonContainer>
                    )}
                    {icpId && emailAlreadySent.length !== 0 && !hasBeenSentByPost && (
                        <LineContainer>
                            <SendToLabel>
                                {i18n.t('components.planIcp.values.sentTo', {
                                    email: emailAlreadySent.map(e => e.email).join(', '),
                                })}
                            </SendToLabel>
                        </LineContainer>
                    )}
                    {(canIndicateSendByPost || hasBeenSentByPost) && (
                        <CheckboxContainer>
                            <Checkbox
                                label={i18n.t('components.planIcp.values.sentPost')}
                                value={hasBeenSentByPost}
                                onCheck={() =>
                                    onItemChange({
                                        ...values,
                                        workInspection: hasBeenSentByPost
                                            ? []
                                            : [
                                                  {
                                                      byEmail: false,
                                                      hasBeenSent: true,
                                                      hasBeenSentByEmail: false,
                                                      email: 'noemail',
                                                  },
                                              ],
                                    })
                                }
                            />
                        </CheckboxContainer>
                    )}
                    {icpId && !isMobile && (
                        <LineContainer isRight>
                            <ButtonContainer>
                                <Button
                                    width={300}
                                    libelle={i18n.t('components.planIcp.labels.workInspectionPaper')}
                                    onPress={() => {
                                        setDownloadingPdf(true)
                                        handleDownloadPdf(icpId)
                                    }}
                                    status={downloadingPdf ? 'loading' : 'active'}
                                />
                            </ButtonContainer>
                        </LineContainer>
                    )}
                </React.Fragment>
            )}
        </Container>
    )
}

const Container = styled(View)`
    padding: 10px;
    background-color: ${props => props.theme.colors.rubriqueBackground};
`

const CheckboxContainer = styled(View)`
    z-index: -5;
    padding: 15px;
    border-radius: 5px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`
const ActionContainer = styled(View)`
    flex-direction: row;
`

const PickerContainer = styled(View)``
const CollaborateurListContainer = styled(View)`
    z-index: -5;
    margin: 5px;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
`
const CollaborateurContainer = styled(View)`
    margin: 2px 5px;
    padding: 2px 5px;
    border-radius: 25px;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    border: 1px solid ${props => props.theme.colors.documentChevron};
`
const CollabotateurLibelle = styled(Text)`
    margin-right: 5px;
    ${props => props.theme.fonts.sentToLabel};
`

const MissingInformationsText = styled(Text)`
    ${props => props.theme.fonts.missingInformationsText};
`

const ButtonContainer = styled(View)`
    z-index: -5;
`
const LineContainer = styled(View)<{ isRight?: boolean }>`
    ${props => props.isRight && 'align-items: flex-end;'}
    z-index: -5;
`

const SendToLabel = styled(Text)`
    margin: 10px;
    ${props => props.theme.fonts.sentToLabel}
`
const PickerBackgroundContainer = styled(View)`
    background-color: #f6f5f5;
    border-radius: 5px;
    padding: 5px;
`
