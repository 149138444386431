import * as React from 'react'

// Components
import { Text, View } from 'react-native'
import { TouchableItemFeedback } from '@components/touchable/Touchable'
import Icon from '@components/icon/Icon'
import Alert from '@components/alert/Alert'
import TextInput from '@components/textInput/TextInput'
import Checkbox from '@components/checkbox/Checkbox'
import Button from '@components/button/Button'
import { Formik, FormikErrors } from 'formik'

// Utils
import useI18n from '@store/i18n/useI18n'

// Style
import styled from '@styles/index'
import useTheme from '@styles/useTheme'

const ADD_INDEX = -1

const emptyItem: RiskFamilyItem = { libelle: '', relatedItems: [] }

export default (props: RiskFamilyItemsListProps) => {
    const { itemsKey, items, onItemsChange, subItems, updateRelatedItems, inputOpened, setInputOpened } = props
    const i18n = useI18n()
    const [Theme] = useTheme()

    const [itemToEdit, setItemToEdit] = React.useState<RiskFamilyItem>(emptyItem)
    const [openedItem, setOpenedItem] = React.useState<string | undefined>(undefined)

    const onItemDeleteConfirmed = (itemLibelle: string) => {
        onItemsChange(items.filter(itm => itm.libelle !== itemLibelle))
        updateRelatedItems(
            subItems.reduce((acc, cur) => {
                const indexOfItemToDelete = cur.relatedItems.indexOf(itemLibelle)
                acc.push(
                    indexOfItemToDelete > -1
                        ? {
                              ...cur,
                              relatedItems: cur.relatedItems.filter(subItem => subItem !== itemLibelle),
                          }
                        : cur,
                )
                return acc
            }, [] as RiskFamilyItem[]),
        )
        setOpenedItem(undefined)
        setInputOpened(undefined)
    }

    const onItemDelete = (itemLibelle: string) =>
        Alert({
            title: i18n.t(`components.${itemsKey}List.alerts.confirmDeleteItemTitle`),
            content: i18n.t(`components.${itemsKey}List.alerts.confirmDeleteItemContent`),
            icon: 'warning',
            buttons: [
                {
                    style: 'default',
                    text: i18n.t('screens.perimetersAdminScreen.alerts.confirmButton'),
                    onPress: () => onItemDeleteConfirmed(itemLibelle),
                },
                {
                    style: 'cancel',
                    text: i18n.t('screens.perimetersAdminScreen.alerts.cancelButton'),
                    onPress: () => undefined,
                },
            ],
            theme: Theme,
        })

    const updateSubItems = (newItem: RiskFamilyItem) =>
        updateRelatedItems(
            subItems.reduce((acc, cur) => {
                const indexOfItemToUpdate = cur.relatedItems.indexOf(itemToEdit.libelle)
                const indexOfSubItem = newItem.relatedItems.indexOf(cur.libelle)
                acc.push(
                    indexOfItemToUpdate > -1
                        ? {
                              ...cur,
                              relatedItems:
                                  indexOfSubItem > -1
                                      ? [
                                            ...cur.relatedItems.slice(0, indexOfItemToUpdate),
                                            newItem.libelle,
                                            ...cur.relatedItems.slice(indexOfItemToUpdate + 1),
                                        ]
                                      : cur.relatedItems.filter((item, i) => i !== indexOfItemToUpdate),
                          }
                        : indexOfSubItem > -1
                        ? { ...cur, relatedItems: [...cur.relatedItems, newItem.libelle] }
                        : cur,
                )
                return acc
            }, [] as RiskFamilyItem[]),
        )

    const onEditItemConfirmed = (
        values: RiskFamilyItem,
        setSubmitting: (isSubmitting: boolean) => void,
        index: number,
    ) => {
        onItemsChange(
            index === ADD_INDEX ? [...items, values] : [...items.slice(0, index), values, ...items.slice(index + 1)],
        )
        updateSubItems(values)
        setItemToEdit(emptyItem)
        setInputOpened(undefined)
        setSubmitting(false)
    }

    const onEditItem = (values: RiskFamilyItem, setSubmitting: (isSubmitting: boolean) => void) => {
        const newItem = { ...values, libelle: values.libelle.trim() }
        const index = items.map(it => it.libelle).indexOf(itemToEdit.libelle)

        if (index !== ADD_INDEX) {
            Alert({
                title: i18n.t(`components.${itemsKey}List.alerts.confirmEditItemTitle`),
                content: i18n.t(`components.${itemsKey}List.alerts.confirmEditItemContent`),
                icon: 'warning',
                buttons: [
                    {
                        style: 'default',
                        text: i18n.t('screens.perimetersAdminScreen.alerts.confirmButton'),
                        onPress: () => onEditItemConfirmed(newItem, setSubmitting, index),
                    },
                    {
                        style: 'cancel',
                        text: i18n.t('screens.perimetersAdminScreen.alerts.cancelButton'),
                        onPress: () => setSubmitting(false),
                    },
                ],
                theme: Theme,
            })
        } else {
            onEditItemConfirmed(newItem, setSubmitting, index)
        }
    }

    const validateForm = (values: RiskFamilyItem): FormikErrors<RiskFamilyItem> => {
        const trimedValue = values.libelle.trim()
        const errors: FormikErrors<RiskFamilyItem> = {}

        if (!trimedValue) {
            errors.libelle = i18n.t('screens.addUserAdminScreen.required')
        }
        if (itemToEdit.libelle !== trimedValue && !!items.find(item => item.libelle === trimedValue)) {
            errors.libelle = i18n.t(`components.${itemsKey}List.alreadyUsedLibelle`)
        }

        return errors
    }

    return (
        <Formik
            key={itemToEdit.libelle}
            initialValues={itemToEdit || (emptyItem as RiskFamilyItem)}
            onSubmit={(values, { setSubmitting }) => onEditItem(values, setSubmitting)}
            validate={validateForm}
        >
            {({ values, errors, setFieldValue, handleSubmit, setValues, setErrors }) => {
                return (
                    <Container>
                        <ListHeader>
                            <TitleContainer>
                                <Label numberOfLines={1} ellipsizeMode="tail">
                                    {i18n.t(`components.${itemsKey}List.title`)}
                                </Label>
                            </TitleContainer>
                            <TouchableItemFeedback
                                onPress={() => {
                                    setInputOpened(!inputOpened ? itemsKey : undefined)
                                    setItemToEdit(emptyItem)
                                    setValues(emptyItem)
                                    setErrors({})
                                }}
                            >
                                <Action>
                                    {i18n.t(`components.${itemsKey}List.${inputOpened ? 'cancel' : 'addItem'}`)}
                                </Action>
                            </TouchableItemFeedback>
                        </ListHeader>
                        {inputOpened && (
                            <EditInput>
                                <TextInput
                                    value={values.libelle}
                                    onTextChange={text => setFieldValue('libelle', text)}
                                    customInputBackgroundColor={Theme.colors.riskFamilyItemInput}
                                    placeholder={i18n.t(`components.${itemsKey}List.inputLabel`)}
                                    error={errors.libelle}
                                />
                                <EditListTitle>
                                    <InfoName numberOfLines={1} ellipsizeMode={'tail'}>
                                        {i18n.t(`components.${itemsKey === 'risks' ? 'measures' : 'risks'}List.title`)}
                                    </InfoName>
                                </EditListTitle>
                                <View>
                                    {subItems.length === 0 ? (
                                        <NoItemMessage>
                                            {i18n.t(`components.${itemsKey}List.noItemAvailable`)}
                                        </NoItemMessage>
                                    ) : (
                                        subItems.map((subItem, index) => {
                                            const subItemIsChecked = values.relatedItems.indexOf(subItem.libelle) > -1
                                            const onCheck = () =>
                                                setFieldValue(
                                                    'relatedItems',
                                                    subItemIsChecked
                                                        ? values.relatedItems.filter(
                                                              relatedItem => relatedItem !== subItem.libelle,
                                                          )
                                                        : [...values.relatedItems, subItem.libelle],
                                                )
                                            return (
                                                <TouchableItemFeedback key={`editSubItem${index}`} onPress={onCheck}>
                                                    <EditSubitemLine>
                                                        <CheckboxContainer>
                                                            <Checkbox
                                                                value={subItemIsChecked}
                                                                onCheck={onCheck}
                                                                smallCheckbox
                                                            />
                                                        </CheckboxContainer>
                                                        <EditSubItemName>
                                                            <Libelle>{subItem.libelle}</Libelle>
                                                        </EditSubItemName>
                                                    </EditSubitemLine>
                                                </TouchableItemFeedback>
                                            )
                                        })
                                    )}
                                    <ButtonContainer>
                                        <Button libelle={'Valider'} onPress={handleSubmit} status={'active'} />
                                    </ButtonContainer>
                                </View>
                            </EditInput>
                        )}

                        <ListContainer>
                            <LegendLine>
                                <Legend>{i18n.t(`components.${itemsKey}List.legend`)}</Legend>
                            </LegendLine>
                            {items.length === 0 ? (
                                <NoItemMessage>{i18n.t(`components.${itemsKey}List.noItem`)}</NoItemMessage>
                            ) : (
                                items.map((item, index) => {
                                    const isSelected = openedItem === item.libelle
                                    return (
                                        <TouchableItemFeedback
                                            key={`measure${index}`}
                                            onPress={() => setOpenedItem(isSelected ? undefined : item.libelle)}
                                        >
                                            <ListItem odd={index % 2 === 1}>
                                                <LibelleContainer>
                                                    <Libelle boldItem={true}>{item.libelle}</Libelle>
                                                    <TouchableItemFeedback
                                                        onPress={() =>
                                                            setOpenedItem(isSelected ? undefined : item.libelle)
                                                        }
                                                    >
                                                        <Icon
                                                            name={'information'}
                                                            size={Theme.constants.riskFamilyInformationIconSize}
                                                            color={
                                                                isSelected
                                                                    ? Theme.colors.riskFamilyItemInformationSelectedIcon
                                                                    : Theme.colors.riskFamilyItemInformationIcon
                                                            }
                                                        />
                                                    </TouchableItemFeedback>
                                                </LibelleContainer>

                                                {isSelected && (
                                                    <View>
                                                        <RowContainer>
                                                            <TitleContainer>
                                                                <InfoName>
                                                                    {i18n.t(`components.${itemsKey}List.infoName`)}
                                                                </InfoName>
                                                            </TitleContainer>
                                                            <TouchableItemFeedback
                                                                onPress={() => {
                                                                    setItemToEdit(item)
                                                                    setOpenedItem(undefined)
                                                                    setInputOpened(itemsKey)
                                                                    setErrors({})
                                                                }}
                                                            >
                                                                <Action>
                                                                    {i18n.t(`components.${itemsKey}List.edit`)}
                                                                </Action>
                                                            </TouchableItemFeedback>
                                                        </RowContainer>
                                                        {item.relatedItems.length === 0 ? (
                                                            <NoItemMessage>
                                                                {i18n.t(`components.${itemsKey}List.noRelatedItem`)}
                                                            </NoItemMessage>
                                                        ) : (
                                                            <RelatedItemList>
                                                                {item.relatedItems.map((subItem, idx) => (
                                                                    <RelatedItemLabel key={`item${index}subItem${idx}`}>
                                                                        {subItem}
                                                                    </RelatedItemLabel>
                                                                ))}
                                                            </RelatedItemList>
                                                        )}
                                                        <RowContainer>
                                                            <Flexbox />
                                                            <TouchableItemFeedback
                                                                onPress={() => onItemDelete(item.libelle)}
                                                            >
                                                                <Action>
                                                                    {i18n.t(`components.${itemsKey}List.delete`)}
                                                                </Action>
                                                            </TouchableItemFeedback>
                                                        </RowContainer>
                                                    </View>
                                                )}
                                            </ListItem>
                                        </TouchableItemFeedback>
                                    )
                                })
                            )}
                        </ListContainer>
                    </Container>
                )
            }}
        </Formik>
    )
}
const Container = styled(View)`
    flex: 1;
`
const LibelleContainer = styled(View)`
    min-height: ${props => props.theme.constants.homeListLineHeight}px;
    flex-direction: row;
    align-items: center;
    flex: 1;
`
const RowContainer = styled(View)`
    min-height: ${props => props.theme.constants.homeListLineHeight}px;
    flex-direction: row;
    align-items: center;
    flex: 1;
`
const ListHeader = styled(View)`
    flex-direction: row;
    align-items: center;
    padding-left: ${props => props.theme.constants.fieldPadding};
    padding-right: ${props => props.theme.constants.fieldPadding};
    padding-bottom: 8px;
`
const Action = styled(Text)`
    ${props => props.theme.fonts.homeActionLink}
    text-decoration: underline;
`
const TitleContainer = styled(View)`
    flex: 1;
`
const ListContainer = styled(View)`
    background-color: ${props => props.theme.colors.riskFamilyItemListBackground};
    padding: 15px 30px;
`
const Label = styled(Text)`
    ${props => props.theme.fonts.fieldLabel}
`
const LegendLine = styled(View)`
    margin-bottom: 8px;
`
const Legend = styled(Text)`
    ${props => props.theme.fonts.perimeterLegend}
    padding-left: 24px;
    text-transform: uppercase;
`
const NoItemMessage = styled(Text)`
    ${props => props.theme.fonts.perimeterName}
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
`
const ListItem = styled(View)<{ odd: boolean }>`
    background-color: ${props => !props.odd && props.theme.colors.homeEvenLines};
    padding-left: 24px;
    padding-right: 24px;
`
const Libelle = styled(Text)<{ boldItem?: boolean }>`
    flex: 1;
    ${props => props.theme.fonts.homeListLineContent}
    ${props => props.boldItem && 'font-family: Avenir-Heavy;'}
`
const InfoName = styled(Text)`
    flex: 1;
    ${props => props.theme.fonts.homeListLineContent}
`

const Flexbox = styled(View)`
    flex: 1;
`
const RelatedItemList = styled.ul`
    padding-left: 24px;
    margin: 0px;
`
const RelatedItemLabel = styled.li`
    margin-bottom: 10px;
    ${props => props.theme.fonts.homeListLineContent}
    font-family: Avenir-Heavy;
`
const EditInput = styled(View)`
    background-color: white;
    margin-bottom: 24px;
    padding: 15px 30px;
`
const EditListTitle = styled(View)`
    padding-left: 36px;
    padding-top: 24px;
    padding-bottom: 10px;
`
const CheckboxContainer = styled(View)`
    width: 36px;
`
const EditSubitemLine = styled(View)`
    flex-direction: row;
    align-items: center;
    flex: 1;
    margin-bottom: 5px;
`
const EditSubItemName = styled(View)`
    min-height: ${props => props.theme.constants.homeListLineHeight}px;
    flex: 1;
    flex-direction: row;
    align-items: center;
    background-color: ${props => props.theme.colors.homeEvenLines};
    padding-left: 24px;
    padding-right: 24px;
`
const ButtonContainer = styled(View)`
    align-items: center;
    margin-top: 10px;
`
