import React from 'react'

// Components
import { View, Image, Text } from 'react-native'
import { TouchableItemFeedback } from '@components/touchable/Touchable'
import Icon from '@components/icon/Icon'
import Dropzone from 'react-dropzone'
import { basicAlert } from '@components/alert/Alerts'
import FieldInformation from '@components/fieldInformation/FieldInformation'
import ImagesModal from '@components/imagesModal/ImagesModal'

// Utils
import { isPdf, openPdfInTab } from '@utils/pdfCommonUtils'

// Store
import useI18n from '@store/i18n/useI18n'

// Style
import styled from '@styles/index'
import useTheme from '@styles/useTheme'

const DEFAULT_MAX_PHOTOS = 3
const MAX_IMG_SIZE = 2621440 // 2,5 Mo

export default (props: ImageFormInputProps) => {
    const { onChange, values, error, maxPhotos, label, disabled } = props

    const [imageOpened, setImageOpened] = React.useState<number | undefined>(undefined)

    const i18n = useI18n()
    const [Theme] = useTheme()

    const onDropError = (oversized?: boolean) =>
        oversized
            ? basicAlert(i18n, 'components.imageDropInput.oversized', 'Drop', () => undefined, 'error', Theme)
            : basicAlert(i18n, 'components.imageDropInput', 'Drop', () => undefined, 'error', Theme)

    const onDropAccepted = (files: File[]) => {
        const reader = new FileReader()
        reader.readAsDataURL(files[0])
        reader.onloadend = () => {
            if (!!reader.result) {
                onChange([...values, reader.result.toString()])
            } else {
                onDropError()
            }
        }
        reader.onerror = err => onDropError()
    }

    const imagesOnly = values.filter(val => !isPdf(val))

    return (
        <Container>
            <ImagesModal
                visible={typeof imageOpened !== 'undefined'}
                onRequestClose={() => setImageOpened(undefined)}
                initImage={imageOpened}
                images={imagesOnly}
            />
            {!!label && <FieldInformation label={label} error={error} />}
            <RowContainer>
                {values.map((imageSrc, index) => (
                    <TouchableItemFeedback
                        key={`image${index}`}
                        onPress={() =>
                            isPdf(imageSrc) ? openPdfInTab(imageSrc) : setImageOpened(imagesOnly.indexOf(imageSrc))
                        }
                    >
                        <ImageContainer>
                            {isPdf(imageSrc) ? (
                                <PdfButton>
                                    <Icon name={'icpToApprove'} size={50} color={Theme.colors.uploadIconColor} />
                                </PdfButton>
                            ) : (
                                <ImageValue source={{ uri: imageSrc }} />
                            )}
                            {!disabled && (
                                <ClearButton>
                                    <TouchableItemFeedback
                                        onPress={() => onChange(values.filter((value, i) => i !== index))}
                                    >
                                        <Icon
                                            name={'cross'}
                                            size={Theme.constants.imageInputClearIconSize}
                                            color={Theme.colors.clearIcon}
                                        />
                                    </TouchableItemFeedback>
                                </ClearButton>
                            )}
                        </ImageContainer>
                    </TouchableItemFeedback>
                ))}

                {values.length < (maxPhotos || DEFAULT_MAX_PHOTOS) && !disabled && (
                    <Dropzone
                        maxSize={MAX_IMG_SIZE}
                        accept={'image/png,image/jpeg,image/svg+xml,.pdf'}
                        onDropAccepted={acceptedFiles => onDropAccepted(acceptedFiles)}
                        onDropRejected={(files, evt) => onDropError(files[0].size > MAX_IMG_SIZE)}
                        multiple={false}
                    >
                        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                            <View>
                                {!!error && <Error>{error}</Error>}
                                <DropZoneContent
                                    {...getRootProps()}
                                    isDragActive={isDragActive}
                                    isDragReject={isDragReject}
                                    error={!!error}
                                >
                                    <input {...getInputProps()} />
                                    <IconContainer>
                                        <Icon name={'cloud-upload'} size={50} color={Theme.colors.uploadIconColor} />
                                    </IconContainer>
                                </DropZoneContent>
                            </View>
                        )}
                    </Dropzone>
                )}
            </RowContainer>
        </Container>
    )
}

const Container = styled(View)``
const RowContainer = styled(View)`
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: -8px;
`
const DropZoneContent = styled.div<{
    isDragActive: boolean
    isDragReject: boolean
    error: boolean
}>`
    background-color: ${props => props.theme.colors.imageDropInputBackground};
    ${props => props.error && 'border: ' + props.theme.colors.textInputBorderError + ' solid 1px;'}
    ${props =>
        props.isDragActive &&
        `box-shadow: 0px 0px 0px 5px ${
            props.isDragReject ? props.theme.colors.imageDropInputReject : props.theme.colors.imageDropInputAccept
        } inset;`};
    cursor: pointer;
`
const ImageContainer = styled(View)`
    justify-content: center;
    align-items: center;
    height: ${props => props.theme.constants.formImageInputPictureSize};
    width: ${props => props.theme.constants.formImageInputPictureSize};
    margin-right: 24px;
    margin-bottom: 8px;
`
const IconContainer = styled(View)`
    justify-content: center;
    align-items: center;
    height: ${props => props.theme.constants.formImageInputPictureSize};
    width: ${props => props.theme.constants.formImageInputPictureSize};
`
const ImageValue = styled(Image)`
    height: ${props => props.theme.constants.formImageInputPictureSize};
    width: ${props => props.theme.constants.formImageInputPictureSize};
`
const ClearButton = styled(View)`
    position: absolute;
    top: 10px;
    right: 10px;
`
const Error = styled(Text)`
    ${props => props.theme.fonts.fieldError}
    padding-bottom: 8px;
    padding-right: ${props => props.theme.constants.fieldPadding};
`
const PdfButton = styled(View)`
    background-color: ${props => props.theme.colors.imageDropInputBackground};
    justify-content: center;
    align-items: center;
    height: ${props => props.theme.constants.formImageInputPictureSize};
    width: ${props => props.theme.constants.formImageInputPictureSize};
`
