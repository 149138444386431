import React from 'react'

// Components
import { View, Platform, TextInput as RNTextInput, KeyboardTypeOptions } from 'react-native'
import FieldInformation from '@components/fieldInformation/FieldInformation'

// Style
import styled from '@styles/index'
import useTheme from '@styles/useTheme'

interface Props {
    value?: string
    onTextChange: (text: string) => void

    required?: boolean
    label?: string
    placeholder?: string
    multiline?: boolean
    numberOfLines?: number
    disabled?: boolean
    error?: string
    errorWithoutText?: boolean
    keyboardType?: KeyboardTypeOptions
    customInputBackgroundColor?: string
    minHeight?: number
    maxLength?: number
    flexed?: boolean
    labelIcon?: IconName
}

export default (props: Props) => {
    const {
        value,
        onTextChange,
        label,
        error,
        required,
        disabled,
        multiline,
        numberOfLines,
        placeholder,
        keyboardType,
        customInputBackgroundColor,
        errorWithoutText,
        minHeight,
        flexed,
        maxLength,
        labelIcon,
    } = props
    const [Theme] = useTheme()

    return (
        <RowContainer flexed={flexed}>
            <Container>
                <FieldInformation
                    label={label}
                    required={required}
                    error={error}
                    minHeight={minHeight}
                    labelIcon={labelIcon}
                />
                <Input
                    value={value}
                    error={!!error || !!errorWithoutText}
                    onChangeText={onTextChange}
                    editable={!disabled}
                    multiline={multiline}
                    numberOfLines={numberOfLines}
                    placeholder={placeholder}
                    keyboardType={keyboardType || 'default'}
                    placeholderTextColor={Theme.colors.fieldPlaceholder}
                    customColor={customInputBackgroundColor}
                    minHeight={minHeight}
                    maxLength={maxLength}
                />
            </Container>
        </RowContainer>
    )
}
const RowContainer = styled(View)<{ flexed?: boolean }>`
    ${props => props.flexed && 'flex: 1;'}
    flex-direction: row;
`
const Container = styled(View)`
    flex: 1;
    flex-direction: column;
`
const Input = styled(RNTextInput)<{
    error: boolean
    editable: boolean
    numberOfLine?: number
    minHeight?: number
    customColor?: string
    keyboardType?: KeyboardTypeOptions
}>`
  ${props => props.theme.fonts.inputText};
  border-radius: ${props => props.theme.constants.inputBorderRadius};
  background-color: ${props =>
      props.editable
          ? props.customColor || props.theme.colors.textInputBackground
          : props.theme.colors.textInputBackgroundDisabled};
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: ${props => props.theme.constants.fieldPadding};
  padding-right: ${props => props.theme.constants.fieldPadding};
  min-height: ${props => props.minHeight || (props.numberOfLine || 1) * props.theme.constants.textInputHeight}px;
  ${props => props.error && 'border: ' + props.theme.colors.textInputBorderError + ' solid 1px;'}
  ${Platform.OS === 'web' && 'outline: none;'}
  font-size: ${props => props.minHeight && props.minHeight * 0.5}px;
  ${props => props.keyboardType === 'numeric' && `width: 100%;`}
`
