import { createEvent, createStore } from 'effector'

export const initialState: SynchroState = {
    icpsNonSynchro: [],
}

export const actions = {
    setIcpsNonSynchro: createEvent<Icp[]>('setIcpsNonSynchro'),
    setSynchronized: createEvent<boolean | undefined>('synchronized'),
    setLastSynchronizationState: createEvent<boolean | undefined>('synchronizationState'),
    setLastSynchronizationDate: createEvent<Date | undefined>('synchronizationDate'),
    setLastSynchronizationSuccededDate: createEvent<Date | undefined>('synchronizationSuccededDate'),
    reset: createEvent(),
}

export const store = createStore<SynchroState>(initialState)
    .on(actions.setIcpsNonSynchro, (state, newIcps) => ({ ...state, icpsNonSynchro: newIcps }))
    .on(actions.setSynchronized, (s, synchronized) => ({ ...s, synchronized }))
    .on(actions.setLastSynchronizationState, (s, lastSynchronizationState) => ({ ...s, lastSynchronizationState }))
    .on(actions.setLastSynchronizationDate, (s, lastSynchronizationDate) => ({ ...s, lastSynchronizationDate }))
    .on(actions.setLastSynchronizationSuccededDate, (s, lastSynchronizationSuccededDate) => ({
        ...s,
        lastSynchronizationSuccededDate,
    }))
    .on(actions.reset, () => initialState)
