import * as React from 'react'
import { Platform, View } from 'react-native'

import useParams from '@layout/useParams'
import useNavigation from '@layout/useNavigation'

import styled from '@styles/index'

import RootScreen from '@components/rootScreen/RootScreen'
import TextInput from '@components/textInput/TextInput'
import AnswerRubriqueSection from '@components/answerRubriqueSection/answerRubriqueSection'
import visitUtils from './visitFormUtils'
import Button from '@components/button/Button'
import { basicAlert } from '@components/alert/Alerts'

import useI18n from '@store/i18n/useI18n'
import api from '@api/api'
import Logger from '@utils/logger'
import { validateForm } from '@utils/azure/validationUtils'

import useReducer from '@store/useReducer'
import * as CustomInterfaceStore from '@store/customInterfaceStore'

import { Formik } from 'formik'
import { produce } from 'immer'
import config from '@app/config'
import useTheme from '@styles/useTheme'
import * as icpInfosStore from '@store/icpInfos'

const visiteModeId = config.visitModeId

const I18N_SCREEN_KEY = 'screens.ppeePdfScreen.visitForm'
interface Props {
    action?: 'add' | 'read' | 'edit'
}

const VisitForm = ({ action }: Props) => {
    const i18n = useI18n()
    const navigation = useNavigation()
    const { formId, ppee, formTitle } = useParams<{ formId?: string; ppee: Icp; formTitle: string }>()
    const ppeeId = React.useMemo(() => ppee.id, [ppee])
    const [Theme] = useTheme()

    const [visiteModele, setVisiteModele] = React.useState<AddedModele | undefined>(undefined)
    const [initialValues, setInitialValues] = React.useState<AddedFormulaire | undefined>(undefined)
    const [status, setStatus] = React.useState<Status>('fetched')

    const instanceName = useReducer(CustomInterfaceStore.store, s => s.name)

    const [redacteurs, setRedacteurs] = React.useState<UserRedacteur[]>([])
    const storeRedacteurs = useReducer(icpInfosStore.store, s => s.redacteurs)
    React.useEffect(() => {
        api.users
            .getRedacteurList()
            .then(redacs => setRedacteurs(redacs.data.sort((a, b) => a.lastName.localeCompare(b.lastName))))
            .catch(err => {
                Logger.error('Une erreur est survenue, on prend les valeurs stockées...', err)
                if (Platform.OS !== 'web') {
                    setRedacteurs(storeRedacteurs.sort((a, b) => a.lastName.localeCompare(b.lastName)))
                }
            })
    }, [storeRedacteurs])

    const setVisitModele = React.useCallback(
        (fromForm: boolean, formTitle: string, suivi?: CreateModifySuiviVisite) => {
            api.modele
                .getModele(visiteModeId)
                .then(visitModele => {
                    const adaptedVisiteModele = visitUtils.adaptModeleToAddedModele(visitModele, instanceName, i18n)
                    setVisiteModele(adaptedVisiteModele)
                    if (!fromForm) {
                        setInitialValues(
                            visitUtils.createEmptyFormFromModele(
                                adaptedVisiteModele,
                                ppee,
                                i18n,
                                visiteModeId,
                                formTitle,
                            ),
                        )
                    } else if (!!ppeeId && !!formId && !!suivi) {
                        setInitialValues(
                            visitUtils.createAdaptedFormFromModele(
                                suivi,
                                adaptedVisiteModele,
                                ppee,
                                i18n,
                                visiteModeId,
                                instanceName,
                                formTitle,
                            ),
                        )
                    }
                    setStatus('fetched')
                })
                .catch(err => {
                    Logger.error(err)
                    setStatus('error')
                })
        },
        [i18n, ppee, formId, ppeeId, instanceName],
    )

    React.useEffect(() => {
        if (!!ppeeId && !!formId) {
            api.ppee.getSuivi(ppeeId, formId).then(suivi => {
                setVisitModele(true, formTitle, suivi)
            })
        } else {
            setVisitModele(false, formTitle)
        }
    }, [formTitle, formId, setVisitModele, ppeeId])

    //Fonction permettant de mettre au format api CreateModifySuiviVisite les données du formulaire AddedFormulaire
    //le couple mainTitle/title sert d'identifiant pour le back
    //Piste d'amel: créer un id pour chaque question plutôt qu'utiliser le mainTitle/title comme identifiant

    const formulaireToApiFormat = (values: AddedFormulaire) => {
        return {
            titre: values.titre,
            operationName: values.json[0].questions[0].answer[0] as string,
            place: values.json[0].questions[1].answer[0] as string,
            date: new Date(values.json[0].questions[2].answer[0]).toISOString() as string,
            avenantReasons: values.json[1].questions[18].answer[0] as string,
            nonAppliedMeasures: values.json[1].questions[20].answer[0] as string,
            reasons: values.json[1].questions[16].answer[0] as string,
            controls: [
                {
                    mainTitle: 'L’entreprise extérieure est-elle celle qui a participé à l’ICP ?',
                    value: values.json[1].questions[0].answer[0] as ControlValue,
                },
                {
                    mainTitle:
                        'Les intervenants ont-ils eu connaissance des principales mesures de prévention pour l’opération sur laquelle ils interviennent et de son contenu (risques d’interférence, consignes, …) ?',
                    value: values.json[1].questions[1].answer[0] as ControlValue,
                },
                {
                    mainTitle:
                        'Les responsables des entreprises extérieures présentes sur le chantier ont-ils avec eux le plan de prévention signé ?',
                    value: values.json[1].questions[2].answer[0] as ControlValue,
                },
                {
                    mainTitle: `Les intervenants ont-ils eu connaissance des documents ${instanceName} suivants : `,
                    title: '"Règles et consignes de sécurité à tout déplacement sur le tracé" ?',
                    value: values.json[1].questions[3].answer[0] as ControlValue,
                },
                {
                    mainTitle: `Les intervenants ont-ils eu connaissance des documents ${instanceName} suivants : `,
                    title: '"Fiche accident du travail" ?',
                    value: values.json[1].questions[4].answer[0] as ControlValue,
                },
                {
                    mainTitle: `Les intervenants ont-ils eu connaissance des documents ${instanceName} suivants : `,
                    title: '"Plan des voies de circulation" ?',
                    value: values.json[1].questions[5].answer[0] as ControlValue,
                },
                {
                    mainTitle: `Les intervenants ont-ils eu connaissance des documents ${instanceName} suivants : `,
                    title: '"Plan des réseaux enterrés" ?',
                    value: values.json[1].questions[6].answer[0] as ControlValue,
                },
                {
                    mainTitle: `Les intervenants ont-ils eu connaissance des documents ${instanceName} suivants : `,
                    title: '"Consignes environnementales" ?',
                    value: values.json[1].questions[7].answer[0] as ControlValue,
                },
                {
                    mainTitle: `Les intervenants ont-ils eu connaissance des documents ${instanceName} suivants : `,
                    title: '"Diagnostic amiante" ?',
                    value: values.json[1].questions[8].answer[0] as ControlValue,
                },
                {
                    mainTitle: "Les consignes générales en cas d'accident sont-elles connues ?",
                    value: values.json[1].questions[9].answer[0] as ControlValue,
                },
                {
                    mainTitle: "Le lieu d'intervention est-il bien celui défini lors de l’ICP ?",
                    value: values.json[1].questions[10].answer[0] as ControlValue,
                },
                {
                    mainTitle: 'Des risques non listés dans le plan de prévention sont-ils présents ?',
                    value: values.json[1].questions[11].answer[0] as ControlValue,
                },
                {
                    mainTitle: 'Le matériel présent correspond-il à celui listé dans le plan de prévention ?',
                    value: values.json[1].questions[12].answer[0] as ControlValue,
                },
                {
                    mainTitle: 'Un permis de feu a-t-il été établi ?',
                    value: values.json[1].questions[13].answer[0] as ControlValue,
                },
                {
                    mainTitle: 'Une attestation de consignation/déconsignation a-t-elle été établie ?',
                    value: values.json[1].questions[14].answer[0] as ControlValue,
                },
                {
                    mainTitle: 'Les intervenants ont-ils les EPI adaptés à leurs tâches respectives ?',
                    value: values.json[1].questions[15].answer[0] as ControlValue,
                },
                {
                    mainTitle: 'Éventuels commentaires sur les questions précédentes',
                    comment: values.json[1].questions[16].answer[0] as string,
                },
                {
                    mainTitle: 'L’opération doit-elle faire l’objet d’un avenant au plan de prévention ?',
                    value: values.json[1].questions[17].answer[0] as ControlValue,
                },
                {
                    mainTitle:
                        'Si oui, préciser la raison (nouveau risque, modification du lieu, nature de l’opération, mode opératoire, …)',
                    value: values.json[1].questions[18].answer[0] as string,
                },
                {
                    mainTitle:
                        'Les mesures de prévention figurant au plan de prévention sont-elles respectées par les intervenants présents ?',
                    value: values.json[1].questions[19].answer[0] as ControlValue,
                },
                {
                    mainTitle: 'Préciser les mesures non appliquées et en indiquer la raison',
                    value: values.json[1].questions[20].answer[0] as string,
                },
                {
                    mainTitle: 'Ajouter des photos pour illustrer si besoin (3 max)',
                    value: values.json[1].questions[21].answer[0],
                },
            ],
            representatives: [
                ...values.json[2].questions[0].answer.map((rep: Representative) => {
                    return {
                        ...rep,
                        aprr: true,
                    }
                }),
                ...values.json[2].questions[1].answer.map((rep: Representative) => {
                    return {
                        ...rep,
                        aprr: false,
                    }
                }),
            ],
            pictures: values.json[1].questions[21].answer.map((image: string) => {
                return { url: image }
            }),
        }
    }

    const alertAfterSubmit = (
        actionKey: 'Edit' | 'Create',
        type: 'success' | 'error',
        setSubmitting: (isSubmitting: boolean) => void,
    ) =>
        basicAlert(
            i18n,
            I18N_SCREEN_KEY,
            actionKey,
            () => {
                setSubmitting(false)
                if (type === 'success') {
                    navigation.back()
                }
            },
            type,
            Theme,
        )

    const handleSubmit = (
        values: AddedFormulaire,
        setSubmitting: (isSubmitting: boolean) => void,
        ppeeIdToSubmit: string,
    ) => {
        const actionKey = !!formId ? 'Edit' : 'Create'
        const promiseCalled = !!formId
            ? api.ppee.editSuivi(ppeeIdToSubmit, formId, formulaireToApiFormat(values))
            : api.ppee.createSuivi(ppeeIdToSubmit, formulaireToApiFormat(values))

        promiseCalled
            .then(() => alertAfterSubmit(actionKey, 'success', setSubmitting))
            .catch(() => alertAfterSubmit(actionKey, 'error', setSubmitting))
    }

    const updatedRubriques = (
        values: RubriqueForm[],
        indexRubrique: number,
        indexQuestion: number,
        newAnswers: any[],
    ) =>
        produce(values, newValues => {
            newValues[indexRubrique].questions[indexQuestion].answer = newAnswers
        })

    return (
        <RootScreen status={status} scrollable>
            {!!visiteModele && !!initialValues && !!ppeeId && (
                <Formik
                    initialValues={initialValues as AddedFormulaire}
                    onSubmit={(values, { setSubmitting }) => handleSubmit(values, setSubmitting, ppeeId)}
                    validate={values => validateForm(values, visiteModele.json, i18n)}
                >
                    {({ errors, values, handleSubmit, isValid, setFieldValue, isSubmitting }) => {
                        return (
                            <>
                                <View>
                                    <TextInput
                                        label={i18n.t('screens.editForm.titles.titre')}
                                        placeholder={i18n.t('screens.editForm.placeholders.titre')}
                                        value={values.titre}
                                        onTextChange={text => setFieldValue('titre', text)}
                                        error={errors.titre}
                                        required={true}
                                        disabled={action === 'read'}
                                    />
                                    {visiteModele.json.map((rubrique, index) => (
                                        <AnswerRubriqueSection
                                            key={`rubrique${index}`}
                                            rubrique={rubrique}
                                            value={values.json[index]}
                                            errors={errors.json ? errors.json[index] : undefined}
                                            onRubriqueChange={(indexQuestion, newAnswers) =>
                                                setFieldValue(
                                                    'json',
                                                    updatedRubriques(values.json, index, indexQuestion, newAnswers),
                                                )
                                            }
                                            index={index}
                                            redacteurs={redacteurs}
                                            disabled={action === 'read'}
                                        />
                                    ))}

                                    {action !== 'read' && (
                                        <ButtonsContainer zIndex={visiteModele.json.length}>
                                            <Button
                                                onPress={handleSubmit}
                                                libelle={i18n.t(`screens.editForm.buttons.${formId ? 'edit' : 'add'}`)}
                                                width={Theme.constants.editModeleButtonWidth}
                                                status={isSubmitting ? 'loading' : isValid ? 'active' : 'disabled'}
                                            />
                                        </ButtonsContainer>
                                    )}
                                </View>
                            </>
                        )
                    }}
                </Formik>
            )}
        </RootScreen>
    )
}
export default VisitForm

const ButtonsContainer = styled(View)<{ zIndex?: number }>`
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    margin-top: 48px;
    ${props => props.zIndex && `z-index: ${props.zIndex};`}
`
