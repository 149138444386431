import * as React from 'react'
import { View, Text, ActivityIndicator } from 'react-native'
import styled from '@styles/index'
import { TouchableItemFeedback } from '@components/touchable/Touchable'
import useTheme from '@styles/useTheme'

interface OwnProps {
    libelle: string
    onPress: () => any
    status: ButtonStatus
    buttonColor?: string
    fontColor?: string
    fontSize?: number
    width?: number
    children?: JSX.Element
}

export default ({ libelle, onPress, status, buttonColor, fontColor, width, children, fontSize }: OwnProps): any => {
    const [Theme] = useTheme()

    if (status === 'loading') {
        return (
            <ButtonContainer buttonColor={buttonColor} specialWidth={width} fontSize={fontSize}>
                <ActivityIndicator color={fontColor || Theme.colors.defaultButtonFontColor} size={fontSize} />
            </ButtonContainer>
        )
    } else {
        return (
            <TouchableItemFeedback onPress={onPress} disabled={status === 'disabled'}>
                <ButtonContainer
                    disabled={status === 'disabled'}
                    buttonColor={buttonColor}
                    specialWidth={width}
                    fontSize={fontSize}
                >
                    {children || (
                        <ButtonText fColor={fontColor} numberOfLines={1} ellipsizeMode={'tail'} fontSize={fontSize}>
                            {libelle}
                        </ButtonText>
                    )}
                </ButtonContainer>
            </TouchableItemFeedback>
        )
    }
}

const ButtonContainer = styled(View)<{
    buttonColor?: string
    specialWidth?: number
    disabled?: boolean
    fontSize?: number
}>`
    &:hover {
        background-color: ${props =>
            props.disabled
                ? props.theme.colors.disabledButtonColorHovered
                : props.buttonColor || props.theme.colors.defaultButtonColorHovered};
    }

    border-radius: 5px;
    height: 30px;
    width: ${props => props.specialWidth || '160'}px;

    align-items: center;
    justify-content: center;

    background-color: ${props =>
        props.disabled
            ? props.theme.colors.disabledButtonColor
            : props.buttonColor || props.theme.colors.defaultButtonColor};
    ${props => !!props.fontSize && `height:  ${2 * props.fontSize}px;`}
`
const ButtonText = styled(Text)<{ fColor?: string; fontSize?: number }>`
    padding: 0 5px;
    ${props => props.theme.fonts.buttonLibelle}
    ${props => !!props.fontSize && `font-size: ${props.fontSize}px;`}
    ${props => !!props.fColor && `color: ${props.fColor};`}
`
