import axios from 'axios'
import { saveAs } from 'file-saver'

import Azure from '@utils/azure/Azure'

import configuration from '@app/config'

const PREFIX = configuration.SERVER_PREFIX + '/services/v1'

export const getDownload = (filename: string) =>
    Azure.assureToken('server').then(token =>
        axios
            .request<Blob>({
                url: `${PREFIX}/icp/unknownMeasures/export`,
                method: 'GET',
                headers: {
                    Accept: 'application/octet-stream',
                    Authorization: `Bearer ${token.accessToken}`,
                },
                responseType: 'arraybuffer',
            })
            .then(res =>
                saveAs(
                    new Blob([res.data], {
                        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    }),
                    filename,
                ),
            ),
    )
