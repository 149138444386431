import { useHistory } from 'react-router-dom'
import { onNavigate } from '@utils/navigationUtils'

import * as navigationStore from '@store/navigation'
import useReducer from '@store/useReducer'
import useTheme from '@styles/useTheme'

const useNavigation = (): Navigation => {
    const history = useHistory()
    const navigationState = useReducer(navigationStore.store, s => s)
    const [Theme] = useTheme()

    return {
        push: (name: string, params?: { [key: string]: any }, noAlert?: boolean) =>
            onNavigate(
                () => {
                    const allParams = params && params.pathParams
                    if (allParams) {
                        let url = name
                        Object.keys(allParams).forEach(key => (url = url.replace(':' + key, allParams[key])))
                        history.push({ pathname: url, state: params })
                    } else {
                        history.push({ pathname: name, state: params })
                    }
                },
                navigationState,
                Theme,
                noAlert,
            ),

        back: (noAlert?: boolean) => onNavigate(() => history.goBack(), navigationState, Theme, noAlert),
        replace: (name: string, params?: { [key: string]: any }, noAlert?: boolean) =>
            onNavigate(() => history.replace(name, params), navigationState, Theme, noAlert),
        reset: (noAlert?: boolean) => onNavigate(() => history.goBack(), navigationState, Theme, noAlert),
        pushFromRoot: (name: string, params?: { [key: string]: any }, noAlert?: boolean) =>
            onNavigate(
                () => {
                    const allParams = params && params.pathParams
                    if (allParams) {
                        let url = name
                        Object.keys(allParams).forEach(key => (url = url.replace(':' + key, allParams[key])))
                        history.push({ pathname: url, state: params })
                    } else {
                        history.push({ pathname: name, state: params })
                    }
                },
                navigationState,
                Theme,
                noAlert,
            ),

        getLocation: () => history.location,
    }
}

export default useNavigation
