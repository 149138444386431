import * as React from 'react'

// Components
import { View, Text } from 'react-native'
import { TouchableItemFeedback } from '@components/touchable/Touchable'
import TextInput from '@components/textInput/TextInput'
import Button from '@components/button/Button'
import FieldInformation from '@components/fieldInformation/FieldInformation'
import EditQuestionSection from '@components/editQuestionSection/editQuestionSection'
import OrderEditor from '@components/orderEditor/orderEditor'
import { confirmAlert } from '@components/alert/Alerts'

// Utils
import { produce } from 'immer'

// Store
import useI18n from '@store/i18n/useI18n'

// Style
import styled from '@styles/index'
import useTheme from '@styles/useTheme'

const emptyQuestion: Question = {
    title: '',
    description: '',
    required: false,
    type: 'textInputAlpha',
    options: ['', ''],
}

interface Props {
    index: number
    isLast: boolean
    rubrique: RubriqueModele
    errors?: any
    isTheOnlyRubrique?: boolean
    isArchived: boolean
    editEnabled: boolean
    questionsOpened: boolean[]
    onQuestionsOpenedChange: (newValues: boolean[]) => void
    setEditEnabled: (enabled: boolean) => void
    onRubriqueChange: (fieldName: RubriqueFieldName, newValue: any) => void
    changeOrder: (idx1: number, idx2: number) => void
    onDeleteRubrique: () => void
}

export default (props: Props) => {
    const {
        index,
        isLast,
        rubrique,
        errors,
        isTheOnlyRubrique,
        onRubriqueChange,
        changeOrder,
        onDeleteRubrique,
        isArchived,
        editEnabled,
        setEditEnabled,
        questionsOpened,
        onQuestionsOpenedChange,
    } = props

    const i18n = useI18n()
    const [Theme] = useTheme()

    const onDeleteQuestion = (indexQuestion: number) =>
        confirmAlert(
            i18n,
            'components.editRubriqueSection',
            'Delete',
            () => {
                onRubriqueChange(
                    'questions',
                    rubrique.questions.filter((question, i) => i !== indexQuestion),
                )
                onQuestionsOpenedChange(questionsOpened.filter((question, i) => i !== indexQuestion))
            },
            () => undefined,
            Theme,
        )

    const updateQuestions = (questionIdx: number, fieldName: QuestionFieldName, newValue: any) =>
        onRubriqueChange(
            'questions',
            produce(rubrique.questions, newQuestions => {
                // Erreur de type sans ce test : problème lié au type booléen de 'required' dans l'interface 'Question'
                if (fieldName === 'required') {
                    newQuestions[questionIdx].required = newValue
                } else {
                    newQuestions[questionIdx][fieldName] = newValue
                }
            }),
        )

    const switchTwoQuestionsOrder = (idx1: number, idx2: number) => {
        onRubriqueChange(
            'questions',
            produce(rubrique.questions, newQuestions => {
                newQuestions[idx1] = rubrique.questions[idx2]
                newQuestions[idx2] = rubrique.questions[idx1]
            }),
        )
        onQuestionsOpenedChange(
            produce(questionsOpened, newQuestionsOpened => {
                newQuestionsOpened[idx1] = questionsOpened[idx2]
                newQuestionsOpened[idx2] = questionsOpened[idx1]
            }),
        )
    }

    return (
        <RubriqueContainer key={index} zIndex={-index}>
            {!isArchived && (
                <OrderEditor
                    index={index}
                    changeOrder={changeOrder}
                    isLast={isLast}
                    editEnabled={editEnabled}
                    setEditEnabled={setEditEnabled}
                />
            )}

            <RubriqueField>
                {editEnabled ? (
                    <TextInput
                        label={i18n.t('components.editRubriqueSection.titles.nom')}
                        value={rubrique.nom}
                        customInputBackgroundColor={Theme.colors.riskFamilyItemInput}
                        onTextChange={(newValue: string) => onRubriqueChange('nom', newValue)}
                        placeholder={i18n.t('components.editRubriqueSection.placeholders.nom')}
                        error={errors ? errors.nom : undefined}
                        required={true}
                    />
                ) : (
                    <View>
                        <FieldInformation label={i18n.t('components.editRubriqueSection.titles.nom')} required={true} />
                        <FieldValue error={errors ? errors.nom : undefined}>
                            {rubrique.nom || i18n.t('components.editRubriqueSection.titles.noNom')}
                        </FieldValue>
                    </View>
                )}
            </RubriqueField>

            <RubriqueField>
                {editEnabled ? (
                    <TextInput
                        numberOfLines={3}
                        multiline={true}
                        label={i18n.t('components.editRubriqueSection.titles.description')}
                        value={rubrique.description}
                        customInputBackgroundColor={Theme.colors.riskFamilyItemInput}
                        onTextChange={(newValue: string) => onRubriqueChange('description', newValue)}
                        placeholder={i18n.t('components.editRubriqueSection.placeholders.description')}
                        error={errors ? errors.description : undefined}
                    />
                ) : (
                    <View>
                        <FieldInformation label={i18n.t('components.editRubriqueSection.titles.description')} />
                        <FieldValue error={errors ? errors.description : undefined}>
                            {rubrique.description || i18n.t('components.editRubriqueSection.titles.noDescription')}
                        </FieldValue>
                    </View>
                )}
            </RubriqueField>

            <FieldInformation
                label={i18n.t('components.editRubriqueSection.titles.questions')}
                color={Theme.colors.questionListTitleColor}
            />

            {rubrique.questions.map((question, j) => (
                <EditQuestionSection
                    key={`rubrique${index}question${j}`}
                    question={question}
                    index={j}
                    isLast={j === rubrique.questions.length - 1}
                    errors={errors && errors.questions ? errors.questions[j] : undefined}
                    onQuestionFieldChange={(fieldName: QuestionFieldName, newValue: any) =>
                        updateQuestions(j, fieldName, newValue)
                    }
                    changeOrder={switchTwoQuestionsOrder}
                    isTheOnlyQuestion={rubrique.questions.length === 1}
                    editEnabled={questionsOpened[j]}
                    setEditEnabled={enabled =>
                        onQuestionsOpenedChange(
                            produce(questionsOpened, newQuestionsOpened => {
                                newQuestionsOpened[j] = enabled
                            }),
                        )
                    }
                    isArchived={isArchived}
                    onDeleteQuestion={() => onDeleteQuestion(j)}
                />
            ))}

            {editEnabled && (
                <AddButton zIndex={-rubrique.questions.length}>
                    <Button
                        libelle={i18n.t(`components.editRubriqueSection.buttons.addQuestion`)}
                        onPress={() => {
                            onRubriqueChange('questions', [...rubrique.questions, emptyQuestion])
                            onQuestionsOpenedChange([...questionsOpened, true])
                        }}
                        status={'active'}
                        width={200}
                    />
                </AddButton>
            )}

            {editEnabled && (
                <ButtonsContainer zIndex={-rubrique.questions.length}>
                    <TouchableItemFeedback disabled={isTheOnlyRubrique} onPress={onDeleteRubrique}>
                        <Action disabled={isTheOnlyRubrique}>
                            {i18n.t('components.editRubriqueSection.buttons.delete')}
                        </Action>
                    </TouchableItemFeedback>

                    <ButtonContainer>
                        <Button
                            onPress={() => {
                                setEditEnabled(false)
                                onQuestionsOpenedChange(rubrique.questions.map(question => false))
                            }}
                            libelle={i18n.t('components.editRubriqueSection.buttons.validate')}
                            status={'active'}
                        />
                    </ButtonContainer>
                </ButtonsContainer>
            )}
        </RubriqueContainer>
    )
}

const RubriqueContainer = styled(View)<{ zIndex?: number }>`
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 24px;
    padding-bottom: 24px;
    margin-bottom: 12px;
    background-color: ${props => props.theme.colors.rubriqueBackground};
    ${props => props.zIndex && `z-index: ${props.zIndex};`}
`
const RubriqueField = styled(View)`
    margin-bottom: 24px;
`
const ButtonsContainer = styled(View)<{ zIndex?: number }>`
    padding-left: 20%;
    padding-right: 20%;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    ${props => props.zIndex && `z-index: ${props.zIndex};`}
`
const ButtonContainer = styled(View)`
    margin-left: 24px;
`
const Action = styled(Text)<{ disabled?: boolean }>`
    ${props => props.theme.fonts.homeActionLink}
    text-decoration: underline;
    ${props => props.disabled && `color :${props.theme.colors.disabledButtonColor}`}
`
const FieldValue = styled(Text)<{ error?: string }>`
    ${props => props.theme.fonts.inputText}
    padding-left: 24px;
    font-size: 14px;
    ${props => props.error && `color: ${props.theme.colors.fieldError};`}
`
const AddButton = styled(View)<{ zIndex?: number }>`
    margin-bottom: 24px;
    flex-direction: row;
    justify-content: flex-start;
    ${props => props.zIndex && `z-index: ${props.zIndex};`}
`
