import * as React from 'react'
import { Text, View } from 'react-native'
import styled from '@styles/index'
import useI18n from '@store/i18n/useI18n'
import { TouchableItemFeedback } from '@components/touchable/Touchable'

interface OwnProps {
    label: string
    value: number
    onPress: () => void
    isFirst?: boolean
}

export default ({ label, value, isFirst, onPress }: OwnProps): any => {
    const i18n = useI18n()

    return (
        <TouchableItemFeedback style={{ flex: 1, marginLeft: !isFirst ? '20px' : '0px' }} onPress={onPress}>
            <IndicatorContainer>
                <IndicatorInfo>
                    <IndicatorText numberOfLines={1} ellipsizeMode={'tail'}>
                        {i18n.t(`screens.home.indicator.entities.${label}`)}
                    </IndicatorText>
                    <IndicatorText numberOfLines={1} ellipsizeMode={'tail'}>
                        {i18n.t(`screens.home.indicator.states.${label}`)}
                    </IndicatorText>
                </IndicatorInfo>
                <IndicatorValue>{value}</IndicatorValue>
            </IndicatorContainer>
        </TouchableItemFeedback>
    )
}

const IndicatorContainer = styled(View)`
    &:hover {
        background-color: ${props => props.theme.colors.hoverBackground};
        & > div {
            color: ${props => props.theme.colors.hoverText};
            border-left-color: ${props => props.theme.colors.hoverText};
            & > div {
                color: ${props => props.theme.colors.hoverText};
            }
        }
    }
    box-shadow: 0px 0px 3px ${props => props.theme.colors.homeIndicatorBowShadowColor};
    background-color: ${props => props.theme.colors.homeIndicatorBackground};

    flex-direction: row;
    align-items: center;

    height: ${props => props.theme.constants.homeIndicatorHeight}px;
    border-radius: 5px;
`
const IndicatorInfo = styled(View)`
    padding-left: 10px;
    margin-left: 10px;
    flex: 1;
    border-left-color: ${props => props.theme.colors.homeIndicatorBorderColor};
    border-left-width: 3px;
`
const IndicatorText = styled(Text)`
    ${props => props.theme.fonts.homeIndicatorText}
    text-transform: uppercase;
`
const IndicatorValue = styled(Text)`
    ${props => props.theme.fonts.homeIndicatorValue}
    margin-right: 5px;
`
