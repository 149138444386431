import * as React from 'react'

// Components
import { View, ActivityIndicator, Text, ScrollView, Platform } from 'react-native'
import RootScreen from '@components/rootScreen/RootScreen'
import BarChart from '@components/barChart/BarChart'
import { TouchableItemFeedback } from '@components/touchable/Touchable'
import Picker from '@components/picker/Picker'
import DatePicker from '@components/datePicker/DatePicker'
import Button from '@components/button/Button'

// Utils
import Logger from '@utils/logger'
import { subDays } from 'date-fns'

// Store
import useI18n from '@store/i18n/useI18n'

// Api
import api from '@app/api/api'

// Style
import styled from '@styles/index'
import useTheme from '@styles/useTheme'

const icpStateFilters: IcpStateFilter[] = ['aPlanifier', 'aRealiser', 'aFinaliser', 'aApprouver']
const icpAmountTimes: IcpAmountTime[] = ['days', 'months', 'years']

const now = new Date()
const twoWeeksBeforeNow = subDays(now, 14)

export default () => {
    const i18n = useI18n()
    const [Theme] = useTheme()

    const [icpAmountStatus, setIcpAmountStatus] = React.useState<Status>('loading')
    const [icpAmountData, setIcpAmountData] = React.useState<BarGraphData>({ xAxisData: [], yAxisData: [] })
    const [icpAmountFilters, setIcpAmountFilters] = React.useState<IcpStateFilter[]>(icpStateFilters)
    const [icpAmountTime, setIcpAmountTime] = React.useState<IcpAmountTime>('days')
    const [icpAmountStartDate, setIcpAmountStartDate] = React.useState<Date>(twoWeeksBeforeNow)
    const [icpAmountEndDate, setIcpAmountEndDate] = React.useState<Date>(now)

    const fetchIcpAmounts = (params: IcpAmountParams) => {
        setIcpAmountStatus('loading')

        api.graphs
            .getIcpAmounts(params)
            .then(icpAmountResults => {
                setIcpAmountData(icpAmountResults)
                setIcpAmountStatus('fetched')
            })
            .catch(err => {
                setIcpAmountStatus('error')
                Logger.error(err)
            })
    }

    React.useEffect(() => {
        fetchIcpAmounts({
            startDate: icpAmountStartDate,
            endDate: icpAmountEndDate,
            timeUnit: icpAmountTime,
            filters: icpAmountFilters,
        })
    }, [icpAmountStartDate, icpAmountEndDate, icpAmountTime, icpAmountFilters])

    const renderIcpAmountFilters = () => (
        <View>
            {icpStateFilters.map(key => {
                const isSelected = icpAmountFilters.indexOf(key) > -1
                return (
                    <TouchableItemFeedback
                        onPress={() =>
                            setIcpAmountFilters(
                                isSelected
                                    ? icpAmountFilters.filter(filters => filters !== key)
                                    : [...icpAmountFilters, key],
                            )
                        }
                        key={key}
                    >
                        <FilterContainer selected={isSelected}>
                            <FilterLibelle selected={isSelected}>
                                {i18n.t(`screens.icpList.filterOption.ICP.${key}`)}
                            </FilterLibelle>
                        </FilterContainer>
                    </TouchableItemFeedback>
                )
            })}
        </View>
    )

    const renderIcpAmountDatePickers = () => (
        <RowLine zIndex={0}>
            <PickerContainer isLeft={true} zIndex={0}>
                <DatePicker
                    notClearable={true}
                    value={icpAmountStartDate}
                    onChange={newDate => newDate && setIcpAmountStartDate(newDate)}
                    label={i18n.t('screens.graphsScreen.labels.icpAmount.startDate')}
                    maxDate={icpAmountEndDate}
                />
            </PickerContainer>
            <PickerContainer isLeft={true} zIndex={-1}>
                <DatePicker
                    notClearable={true}
                    value={icpAmountEndDate}
                    onChange={newDate => newDate && setIcpAmountEndDate(newDate)}
                    label={i18n.t('screens.graphsScreen.labels.icpAmount.endDate')}
                    minDate={icpAmountStartDate}
                />
            </PickerContainer>
            <PickerContainer zIndex={-2}>
                <Picker
                    label={i18n.t('screens.graphsScreen.labels.icpAmount.timeUnit')}
                    data={icpAmountTimes.map(key => i18n.t(`screens.graphsScreen.values.icpAmountTimes.${key}`))}
                    value={i18n.t(`screens.graphsScreen.values.icpAmountTimes.${icpAmountTime}`)}
                    onChange={index => setIcpAmountTime(icpAmountTimes[index])}
                    notSorted={true}
                />
            </PickerContainer>
        </RowLine>
    )

    const renderIcpAmountGraph = () => (
        <View>
            <BarChart
                xAxisData={icpAmountData.xAxisData}
                yAxisData={icpAmountData.yAxisData}
                yLabel={i18n.t('screens.graphsScreen.yAxisLabels.icpAmount')}
            />
            {icpAmountStatus !== 'fetched' && (
                <LoadingBackground>
                    {icpAmountStatus === 'loading' ? (
                        <ActivityIndicator size={50} color={Theme.colors.graphLoading} />
                    ) : (
                        <CenteredView>
                            <GraphError>{i18n.t('screens.graphsScreen.error')}</GraphError>
                            <Button
                                libelle={i18n.t('screens.graphsScreen.buttons.tryAgain')}
                                onPress={() =>
                                    fetchIcpAmounts({
                                        startDate: icpAmountStartDate,
                                        endDate: icpAmountEndDate,
                                        timeUnit: icpAmountTime,
                                        filters: icpAmountFilters,
                                    })
                                }
                                status={'active'}
                            />
                        </CenteredView>
                    )}
                </LoadingBackground>
            )}
        </View>
    )

    const renderIcpAmount = () => (
        <RowContainer>
            {renderIcpAmountFilters()}
            <View>
                <GraphTitle>{i18n.t('screens.graphsScreen.graphTitles.icpAmount')}</GraphTitle>
                {renderIcpAmountGraph()}
                {renderIcpAmountDatePickers()}
            </View>
        </RowContainer>
    )

    return (
        <RootScreen status={'fetched'}>
            <ScrollView>{renderIcpAmount()}</ScrollView>
        </RootScreen>
    )
}
const RowContainer = styled(View)`
    flex-direction: row;
`
const LoadingBackground = styled(View)`
    height: 100%;
    width: 100%;
    background-color: ${props => props.theme.colors.graphLoadingBackground};
    align-items: center;
    justify-content: center;
    position: absolute;
`
const GraphTitle = styled(Text)`
    text-align: center;
    ${props => props.theme.fonts.graphTitle}
`

const FilterContainer = styled(View)<{ selected?: boolean }>`
    padding: 10px;
    margin: 5px 15px 5px 0px;
    border-radius: 5px;

    ${Platform.OS === 'web' && 'cursor: pointer;'}
    background-color: ${props =>
        props.selected ? props.theme.colors.filterBackgroundColorSelected : props.theme.colors.filterBackgroundColor};
    &:hover {
        background-color: ${props => props.theme.colors.filterBackgroundColorHovered};
        & > div {
            & > div {
                color: ${props => props.theme.colors.filterLibelleHovered};
            }
        }
    }
`
const FilterLibelle = styled(Text)<{ selected?: boolean }>`
    ${props => (props.selected ? props.theme.fonts.filterLibelleSelected : props.theme.fonts.filterLibelle)};
`
const PickerContainer = styled(View)<{ isLeft?: boolean; zIndex: number }>`
    flex: 1;
    ${props => props.isLeft && `margin-right: 40px`};
    z-index: ${props => props.zIndex};
`
const RowLine = styled(View)<{ zIndex: number }>`
    flex-direction: row;
    align-items: center;
    z-index: ${props => props.zIndex};
    margin-bottom: 24px;
`
const GraphError = styled(Text)`
    margin-bottom: 24px;
    text-align: center;
    ${props => props.theme.fonts.graphError}
`
const CenteredView = styled(View)`
    justify-content: center;
    align-items: center;
`
