import visiteModeleJSON from '@screens/visitForm/visitModel.json'

//Nécessaire car les questions renvoyées dans l'objet Suivi par l'api ne sont pas dans le même ordre que les questions du formulaire
//Sinon, par exemple, la réponse de la question 6 (order: 6) irait à la première question du formulaire.
//Piste d'amel: forcer le maintien de l'ordre côté back?

const ControlOrder = (instanceName: string) => [
    {
        mainTitle: `Les intervenants ont-ils eu connaissance des documents ${instanceName} suivants : `,
        title: '"Plan des voies de circulation" ?',
        order: 6,
    },
    {
        mainTitle: 'Un permis de feu a-t-il été établi ?',
        title: null,
        order: 14,
    },
    {
        mainTitle: "Le lieu d'intervention est-il bien celui défini lors de l’ICP ?",
        title: null,
        order: 11,
    },
    {
        mainTitle:
            'Si oui, préciser la raison (nouveau risque, modification du lieu, nature de l’opération, mode opératoire, …)',
        title: null,
        order: 19,
    },
    {
        mainTitle: 'Les intervenants ont-ils les EPI adaptés à leurs tâches respectives ?',
        title: null,
        order: 16,
    },
    {
        mainTitle: 'Préciser les mesures non appliquées et en indiquer la raison',
        title: null,
        order: 21,
    },
    {
        mainTitle: `Les intervenants ont-ils eu connaissance des documents ${instanceName} suivants : `,
        title: '"Diagnostic amiante" ?',
        order: 9,
    },
    {
        mainTitle: 'Ajouter des photos pour illustrer si besoin (3 max)',
        title: null,
        order: 22,
    },
    {
        mainTitle:
            'Les mesures de prévention figurant au plan de prévention sont-elles respectées par les intervenants présents ?',
        title: null,
        order: 20,
    },
    {
        mainTitle:
            'Les intervenants ont-ils eu connaissance des principales mesures de prévention pour l’opération sur laquelle ils interviennent et de son contenu (risques d’interférence, consignes, …) ?',
        title: null,
        order: 2,
    },
    {
        mainTitle: `Les intervenants ont-ils eu connaissance des documents ${instanceName} suivants : `,
        title: '"Règles et consignes de sécurité à tout déplacement sur le tracé" ?',
        order: 4,
    },
    {
        mainTitle: 'Une attestation de consignation/déconsignation a-t-elle été établie ?',
        title: null,
        order: 15,
    },
    {
        mainTitle: 'Des risques non listés dans le plan de prévention sont-ils présents ?',
        title: null,
        order: 12,
    },
    {
        mainTitle: 'L’opération doit-elle faire l’objet d’un avenant au plan de prévention ?',
        title: null,
        order: 18,
    },
    {
        mainTitle: `Les intervenants ont-ils eu connaissance des documents ${instanceName} suivants : `,
        title: '"Consignes environnementales" ?',
        order: 8,
    },
    {
        mainTitle: 'Éventuels commentaires sur les questions précédentes',
        title: null,
        order: 17,
    },
    {
        mainTitle:
            'Les responsables des entreprises extérieures présentes sur le chantier ont-ils avec eux le plan de prévention signé ?',
        title: null,
        order: 3,
    },
    {
        mainTitle: "Les consignes générales en cas d'accident sont-elles connues ?",
        title: null,
        order: 10,
    },
    {
        mainTitle: 'Le matériel présent correspond-il à celui listé dans le plan de prévention ?',
        title: null,
        order: 13,
    },
    {
        mainTitle: 'L’entreprise extérieure est-elle celle qui a participé à l’ICP ?',
        title: null,
        order: 1,
    },
    {
        mainTitle: `Les intervenants ont-ils eu connaissance des documents ${instanceName} suivants : `,
        title: '"Fiche accident du travail" ?',
        order: 5,
    },
    {
        mainTitle: `Les intervenants ont-ils eu connaissance des documents ${instanceName} suivants : `,
        title: '"Plan des réseaux enterrés" ?',
        order: 7,
    },
]

const adaptModeleToAddedModele = (modele: Modele, instanceName: string, i18n: I18nState): AddedModele => ({
    ...modele,
    json: visiteModeleJSON.map(rubriqueModele => ({
        nom: i18n.t(`screens.ppeePdfScreen.visitForm.rubriqueName.${rubriqueModele.nom}`),
        questions: rubriqueModele.questions.map(question => ({
            ...question,
            title: i18n.t(`screens.ppeePdfScreen.visitForm.questionTitles.${question.title}`, { name: instanceName }),
            options: question.options.map(option => {
                return i18n.t(`common.${option}`)
            }),
        })),
        description: i18n.t(`screens.ppeePdfScreen.visitForm.rubriqueDescription.${rubriqueModele.description}`),
    })) as RubriqueModele[],
})

const createAdaptedFormFromModele = (
    suivi: CreateModifySuiviVisite,
    modele: AddedModele,
    ppee: Icp,
    i18n: I18nState,
    visiteModeId: string,
    instanceName: string,
    formTitle?: string,
): AddedFormulaire => ({
    titre: formTitle || '',
    modeleId: visiteModeId,
    json: modele.json.map(rubrique => {
        switch (rubrique.nom) {
            case 'Informations':
                return {
                    rubriqueName: rubrique.nom,
                    questions: [
                        {
                            questionTitle: i18n.t(`screens.ppeePdfScreen.visitForm.questionTitles.q1`),
                            answer: [suivi.operationName],
                        },
                        {
                            questionTitle: i18n.t(`screens.ppeePdfScreen.visitForm.questionTitles.q2`),
                            answer: [suivi.place],
                        },
                        {
                            questionTitle: i18n.t(`screens.ppeePdfScreen.visitForm.questionTitles.q3`),
                            answer: [suivi.date],
                        },
                    ],
                }
            case 'Points de contrôle':
                return {
                    rubriqueName: rubrique.nom,
                    questions: suivi.controls
                        .map(control => {
                            return {
                                questionTitle: `${control.mainTitle} ${control.title}`,
                                answer: !!control.value ? [control.value] : [control.comment],
                                order: ControlOrder(instanceName).filter(order =>
                                    !!control.title && order.title
                                        ? control.title.indexOf(order.title) > -1
                                        : control.mainTitle.indexOf(order.mainTitle) > -1,
                                )[0].order,
                            }
                        })
                        .sort((a, b) => a.order - b.order)
                        .map(orderedControl => {
                            return {
                                questionTitle: orderedControl.questionTitle,
                                answer:
                                    orderedControl.order === 19
                                        ? [suivi.avenantReasons]
                                        : orderedControl.order === 21
                                        ? [suivi.nonAppliedMeasures]
                                        : orderedControl.order === 22
                                        ? suivi.pictures.map(picture => {
                                              return picture.url
                                          })
                                        : !!orderedControl.answer[0]
                                        ? orderedControl.answer
                                        : [],
                            }
                        }),
                }
            case 'Émargements':
                return {
                    rubriqueName: rubrique.nom,
                    questions: [
                        {
                            questionTitle: i18n.t(`screens.ppeePdfScreen.visitForm.questionTitles.q26`),
                            answer: suivi.representatives.filter(representative => !!representative.aprr),
                        },
                        {
                            questionTitle: i18n.t(`screens.ppeePdfScreen.visitForm.questionTitles.q27`),
                            answer: suivi.representatives.filter(representative => !representative.aprr),
                        },
                    ],
                }
            default:
                return null
        }
    }) as RubriqueForm[],
})

const createEmptyFormFromModele = (
    modele: AddedModele,
    ppee: Icp,
    i18n: I18nState,
    visiteModeId: string,
    formTitle?: string,
): AddedFormulaire => ({
    titre: formTitle || '',
    modeleId: visiteModeId,
    json: modele.json.map(rubrique => ({
        rubriqueName: rubrique.nom,
        questions: rubrique.questions.map(question => {
            //Solution temporaire en attendant la création d'un objet de suivi de visites indépendant des formulaires classiques
            switch (question.title) {
                case i18n.t(`screens.ppeePdfScreen.visitForm.questionTitles.q1`):
                    return {
                        questionTitle: question.title,
                        answer: [ppee.informations.description],
                    }
                case i18n.t(`screens.ppeePdfScreen.visitForm.questionTitles.q2`):
                    return {
                        questionTitle: question.title,
                        answer: [String(ppee.informations.places)],
                    }
                case i18n.t(`screens.ppeePdfScreen.visitForm.questionTitles.q3`):
                    return {
                        questionTitle: question.title,
                        answer: [new Date()],
                    }
                default:
                    return {
                        questionTitle: question.title,
                        answer: [],
                    }
            }
        }),
    })),
})

export default { createEmptyFormFromModele, createAdaptedFormFromModele, adaptModeleToAddedModele }
