import * as React from 'react'

// Components
import { View, Text } from 'react-native'
import RootScreen from '@components/rootScreen/RootScreen'
import { Formik, FormikErrors } from 'formik'
import Button from '@components/button/Button'
import TextInput from '@components/textInput/TextInput'
import FieldInformation from '@components/fieldInformation/FieldInformation'
import { basicAlert, confirmAlert } from '@components/alert/Alerts'
import EditRubriqueSection from '@components/editRubriqueSection/editRubriqueSection'

// Store
import useI18n from '@store/i18n/useI18n'

// Utils
import Logger from '@utils/logger'
import useNavigation from '@layout/useNavigation'
import { produce } from 'immer'

// Api
import api from '@api/api'

// Style
import styled from '@styles/index'
import useTheme from '@styles/useTheme'

// const MODELE_STATE_UNFINISHED = 0
// const MODELE_STATE_FINISHED = 1
const MODELE_STATE_ARCHIVED = 2

type fieldKeys = 'id' | 'titre' | 'json' | 'etat'

const i18nScreenKey = 'screens.editModeleAdminScreen'

const emptyRubrique: RubriqueModele = {
    nom: '',
    questions: [{ title: '', description: '', required: false, type: 'textInputAlpha', options: ['', ''] }],
    description: '',
}

const initModele: AddedModele = {
    titre: '',
    json: [emptyRubrique],
    etat: 0,
    defaut: false,
}

const adaptAdminModeleToAddedModele = (modele: AdminModele): AddedModele => ({
    ...modele,
    json: JSON.parse(modele.json),
})

const trimAllValues = (values: AddedModele): AddedModele =>
    produce(values, newValues => {
        newValues.titre = values.titre.trim()

        values.json.forEach((rubrique, i) => {
            newValues.json[i].nom = rubrique.nom.trim()
            if (rubrique.description) {
                newValues.json[i].description = rubrique.description.trim()
            }
            rubrique.questions.forEach((question, j) => {
                newValues.json[i].questions[j].title = question.title.trim()
                if (question.description) {
                    newValues.json[i].questions[j].description = question.description.trim()
                }
                if (question.options) {
                    newValues.json[i].questions[j].options = question.options.map(option => option.trim())
                }
            })
        })
    })

const filterEmptyFields = (values: AddedModele): AddedModele =>
    produce(values, newValues => {
        newValues.json = values.json.map(rub => ({
            ...rub,
            questions: rub.questions.map(question =>
                !!question.options
                    ? { ...question, options: question.options.filter(option => !!option) }
                    : { ...question },
            ),
        }))
    })

interface Props {
    modeleId?: string
}

export default (props: Props) => {
    const { modeleId } = props

    const i18n = useI18n()
    const navigation = useNavigation()
    const [Theme] = useTheme()

    const [status, setStatus] = React.useState<Status>('loading')
    const [modele, setModele] = React.useState<AddedModele>(initModele)
    const [rubriquesOpened, setRubriquesOpened] = React.useState<boolean[]>([])
    const [questionsOpened, setQuestionsOpened] = React.useState<boolean[][]>([])

    React.useEffect(() => {
        if (!modeleId) {
            setStatus('fetched')
        } else {
            api.admin
                .getAllModeles()
                .then(modeles => {
                    if (!!modeles) {
                        const modeleFound = modeles.find(modele => modele.id === modeleId)
                        if (!!modeleFound) {
                            setModele(adaptAdminModeleToAddedModele(modeleFound))
                            setStatus('fetched')
                        } else {
                            setStatus('error')
                            throw new Error('Modele not found')
                        }
                    } else {
                        setStatus('error')
                        throw new Error('No modele fetched')
                    }
                })
                .catch(err => {
                    Logger.error(err)
                    setStatus('error')
                })
        }
    }, [modeleId])

    React.useEffect(() => {
        setRubriquesOpened(modele.json.map(rubrique => modele.etat !== MODELE_STATE_ARCHIVED))
        setQuestionsOpened(
            modele.json.map(rubrique => rubrique.questions.map(question => modele.etat !== MODELE_STATE_ARCHIVED)),
        )
    }, [modele])

    const onArchiveConfirm = (modeleId: string) => {
        api.admin
            .archiveModele(modeleId)
            .then(() => basicAlert(i18n, i18nScreenKey, 'Archive', () => navigation.push('/trames'), 'success', Theme))
            .catch(err => {
                basicAlert(i18n, i18nScreenKey, 'Archive', () => undefined, 'error', Theme)
                Logger.error(err)
            })
    }

    const onArchivePress = (modeleId: string) =>
        confirmAlert(
            i18n,
            i18nScreenKey,
            'Archive',
            () => onArchiveConfirm(modeleId),
            () => undefined,
            Theme,
        )

    const validateForm = (values: AddedModele) => {
        const errors: FormikErrors<AddedModele> = {}
        const errorsJson: Array<FormikErrors<RubriqueModele>> = []
        const trimedValues = trimAllValues(values)

        if (!trimedValues.titre) {
            errors.titre = i18n.t('screens.editModeleAdminScreen.required')
        }
        trimedValues.json.forEach((rubrique, i) => {
            errorsJson.push({})
            const errorsQuestions: Array<FormikErrors<Question>> = []
            if (!rubrique.nom) {
                errorsJson[i].nom = i18n.t('screens.editModeleAdminScreen.required')
            }
            rubrique.questions.forEach((question, j) => {
                errorsQuestions.push({})
                if (!question.title) {
                    errorsQuestions[j].title = i18n.t('screens.editModeleAdminScreen.required')
                }
                if (!question.type) {
                    errorsQuestions[j].type = i18n.t('screens.editModeleAdminScreen.required')
                }
                if (question.type === 'checkboxes' || question.type === 'radio' || question.type === 'select') {
                    if (question.options && (!question.options[0] || !question.options[1])) {
                        errorsQuestions[j].options = i18n.t('screens.editModeleAdminScreen.requiredFields')
                    }
                }
            })
            if (errorsQuestions.find(errorRub => Object.keys(errorRub).length !== 0)) {
                errorsJson[i].questions = errorsQuestions
            }
        })
        if (errorsJson.find(errorRub => Object.keys(errorRub).length !== 0)) {
            errors.json = errorsJson
        }
        return errors
    }

    const handleSubmitModele = (
        values: AddedModele,
        setSubmitting: (isSubmitting: boolean) => void,
        draft?: boolean,
    ) => {
        const valuesToSubmit = draft ? trimAllValues(values) : filterEmptyFields(trimAllValues(values))
        const actionKey = valuesToSubmit.id ? 'Edit' : 'Add'

        confirmAlert(
            i18n,
            i18nScreenKey,
            `${actionKey}${!draft ? 'Finished' : 'Unfinished'}`,
            () =>
                (valuesToSubmit.id
                    ? api.admin.editModele(
                          {
                              id: valuesToSubmit.id,
                              titre: valuesToSubmit.titre,
                              json: JSON.stringify(valuesToSubmit.json),
                              etat: draft ? 0 : 1,
                              defaut: values.defaut,
                          },
                          valuesToSubmit.id,
                      )
                    : api.admin.addModele({
                          titre:
                              valuesToSubmit.titre ||
                              i18n.t('screens.editModeleAdminScreen.defaultTitle', { date: new Date() }),
                          json: JSON.stringify(valuesToSubmit.json),
                          etat: draft ? 0 : 1,
                      })
                )
                    .then(() =>
                        basicAlert(i18n, i18nScreenKey, actionKey, () => navigation.push('/trames'), 'success', Theme),
                    )
                    .catch(err => {
                        basicAlert(i18n, i18nScreenKey, actionKey, () => setSubmitting(false), 'error', Theme)
                        Logger.error(err)
                    }),
            () => setSubmitting(false),
            Theme,
        )
    }

    const updateRubrique = (
        values: AddedModele,
        newValue: any,
        rubriqueIdx: number,
        fieldName: RubriqueFieldName,
        setFieldValue: (field: fieldKeys, value: any) => void,
    ) =>
        setFieldValue(
            'json',
            produce(values.json, newJson => {
                newJson[rubriqueIdx][fieldName] = newValue
            }),
        )

    const onDeleteRubrique = (
        index: number,
        values: AddedModele,
        setFieldValue: (field: fieldKeys, value: any) => void,
    ) =>
        confirmAlert(
            i18n,
            i18nScreenKey,
            'DeleteRubrique',
            () => {
                setFieldValue(
                    'json',
                    values.json.filter((rubrique, i) => i !== index),
                )
                setRubriquesOpened(rubriquesOpened.filter((rubrique, i) => i !== index))
                setQuestionsOpened(questionsOpened.filter((rubrique, i) => i !== index))
            },
            () => undefined,
            Theme,
        )

    const switchTwoRubriquesOrder = (
        values: AddedModele,
        idx1: number,
        idx2: number,
        setFieldValue: (field: fieldKeys, value: any) => void,
    ) => {
        setFieldValue(
            'json',
            produce(values.json, newJson => {
                newJson[idx1] = values.json[idx2]
                newJson[idx2] = values.json[idx1]
            }),
        )
        setRubriquesOpened(
            produce(rubriquesOpened, newRubriquesOpened => {
                newRubriquesOpened[idx1] = rubriquesOpened[idx2]
                newRubriquesOpened[idx2] = rubriquesOpened[idx1]
            }),
        )
        setQuestionsOpened(
            produce(questionsOpened, newQuestionsOpened => {
                newQuestionsOpened[idx1] = questionsOpened[idx2]
                newQuestionsOpened[idx2] = questionsOpened[idx1]
            }),
        )
    }

    const handleQuestionsOpenedChange = (values: boolean[], indexRubrique: number) =>
        setQuestionsOpened(
            produce(questionsOpened, newQuestionsOpened => {
                newQuestionsOpened[indexRubrique] = values
            }),
        )

    return (
        <RootScreen status={status} scrollable>
            <Formik
                initialValues={modele as AddedModele}
                onSubmit={(values, { setSubmitting }) => handleSubmitModele(values, setSubmitting)}
                validate={validateForm}
                isInitialValid={!!modeleId}
            >
                {({ errors, values, handleSubmit, isValid, setFieldValue, isSubmitting, setSubmitting }) => {
                    return (
                        <View>
                            {values.etat !== MODELE_STATE_ARCHIVED ? (
                                <TextInput
                                    numberOfLines={1}
                                    value={values.titre}
                                    onTextChange={text => setFieldValue('titre', text)}
                                    error={errors.titre}
                                    label={i18n.t('screens.editModeleAdminScreen.titles.title')}
                                    placeholder={i18n.t('screens.editModeleAdminScreen.placeholders.title')}
                                />
                            ) : (
                                <View>
                                    <FieldInformation
                                        label={i18n.t('screens.editModeleAdminScreen.titles.title')}
                                        required={true}
                                    />
                                    <FieldValue error={errors.titre}>
                                        {values.titre || i18n.t('screens.editModeleAdminScreen.titles.noTitle')}
                                    </FieldValue>
                                </View>
                            )}
                            <QuestionsContainer>
                                <FieldInformation label={i18n.t('screens.editModeleAdminScreen.titles.questions')} />
                            </QuestionsContainer>

                            {values.json.map((rubrique, index) => (
                                <EditRubriqueSection
                                    key={`rubrique${index}`}
                                    index={index}
                                    rubrique={rubrique}
                                    isLast={index === values.json.length - 1}
                                    errors={errors.json ? errors.json[index] : undefined}
                                    onRubriqueChange={(fieldName, newValue) =>
                                        updateRubrique(values, newValue, index, fieldName, setFieldValue)
                                    }
                                    changeOrder={(idx1: number, idx2: number) =>
                                        switchTwoRubriquesOrder(values, idx1, idx2, setFieldValue)
                                    }
                                    onDeleteRubrique={() => onDeleteRubrique(index, values, setFieldValue)}
                                    isTheOnlyRubrique={values.json.length === 1}
                                    isArchived={values.etat === MODELE_STATE_ARCHIVED}
                                    editEnabled={rubriquesOpened[index]}
                                    setEditEnabled={enabled =>
                                        setRubriquesOpened(
                                            produce(rubriquesOpened, newRubriquesOpened => {
                                                newRubriquesOpened[index] = enabled
                                            }),
                                        )
                                    }
                                    questionsOpened={questionsOpened[index]}
                                    onQuestionsOpenedChange={newValue => handleQuestionsOpenedChange(newValue, index)}
                                />
                            ))}
                            {values.etat !== MODELE_STATE_ARCHIVED && (
                                <ButtonsContainer add={true} zIndex={-values.json.length}>
                                    <Button
                                        libelle={i18n.t(`screens.editModeleAdminScreen.buttons.addRubrique`)}
                                        onPress={() => {
                                            setFieldValue('json', [...values.json, emptyRubrique])
                                            setRubriquesOpened([...rubriquesOpened, true])
                                            setQuestionsOpened([...questionsOpened, [true]])
                                        }}
                                        status={'active'}
                                        width={250}
                                    />
                                </ButtonsContainer>
                            )}

                            {values.etat !== MODELE_STATE_ARCHIVED && (
                                <ButtonsContainer zIndex={-values.json.length}>
                                    {!!modeleId && !modele.defaut && (
                                        <ButtonContainer>
                                            <Button
                                                onPress={() => onArchivePress(modeleId)}
                                                buttonColor={Theme.colors.saveUnfinishedButtonBackground}
                                                libelle={i18n.t('screens.editModeleAdminScreen.buttons.archive')}
                                                width={Theme.constants.editModeleButtonWidth}
                                                status={isSubmitting ? 'loading' : 'active'}
                                            />
                                        </ButtonContainer>
                                    )}
                                    {!modele.defaut && (
                                        <ButtonContainer>
                                            <Button
                                                onPress={() => {
                                                    setSubmitting(true)
                                                    handleSubmitModele(values, setSubmitting, true)
                                                }}
                                                buttonColor={Theme.colors.saveUnfinishedButtonBackground}
                                                libelle={i18n.t('screens.editModeleAdminScreen.buttons.saveUnfinished')}
                                                width={Theme.constants.editModeleButtonWidth}
                                                status={isSubmitting ? 'loading' : 'active'}
                                            />
                                        </ButtonContainer>
                                    )}
                                    <ButtonContainer>
                                        <Button
                                            onPress={handleSubmit}
                                            libelle={i18n.t('screens.editModeleAdminScreen.buttons.saveFinished')}
                                            buttonColor={Theme.colors.saveFinishedButtonBackground}
                                            width={Theme.constants.editModeleButtonWidth}
                                            status={!isValid ? 'disabled' : isSubmitting ? 'loading' : 'active'}
                                        />
                                    </ButtonContainer>
                                </ButtonsContainer>
                            )}
                        </View>
                    )
                }}
            </Formik>
        </RootScreen>
    )
}

const ButtonsContainer = styled(View)<{ add?: boolean; zIndex?: number }>`
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    ${props => props.add && 'margin-bottom: 48px;'}
    ${props => props.zIndex && `z-index: ${props.zIndex};`}
`
const ButtonContainer = styled(View)`
    margin-top: 10px;
    margin-bottom: 10px;
`
const QuestionsContainer = styled(View)`
    padding-top: 24px;
`
const FieldValue = styled(Text)<{ error?: string }>`
    ${props => props.theme.fonts.inputText}
    padding-left: 24px;
    font-size: 14px;
    ${props => props.error && 'color: red;'}
`
