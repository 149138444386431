import * as React from 'react'

// Components
import { View, Platform, Text } from 'react-native'
import TextInput from '@components/textInput/TextInput'
import DatePicker from '@components/datePicker/DatePicker'
import { Formik, FormikErrors } from 'formik'
import Container from '@components/icpFormContainer/icpFormContainer'
import Checkbox from '@components/checkbox/Checkbox'
import Checkboxes from '@components/checkbox/Checkboxes'
import AddIcpPlaces from '@components/addIcpPlaces/AddIcpPlaces'
import Picker from '@components/picker/Picker'

// Utils
import { isBefore, isAfter } from 'date-fns'
import { produce } from 'immer'
import { addAprrYear } from '@utils/dateUtils'
import { alphabetical } from '@utils/string'

// Store
import useI18n from '@store/i18n/useI18n'

// Style
import styled from '@styles/index'

interface Props {
    reasons?: AmendmentReason[]
    initialValues: InformationsIcp
    onValidate?: (isValid: boolean) => void
    globalSetIcpFieldValue: (value: InformationsIcp) => void
    interventionObjects: string[]
    interventionTypes: string[]
    updateActors: (value: ActorsIcp) => void
    globalActors: ActorsIcp
    setIsDescriptionSet: (value: boolean) => void
}

const otherOperations = ['CSPS', 'PP', 'Chantier Interne']

export const trimInfosIcp = (values: InformationsIcp): InformationsIcp => ({
    ...values,
    description: !!values.description ? values.description.trim() : '',
    places: values.places.map(place => place.trim()),
})

export const validateInfosIcp = (
    values: InformationsIcp,
    i18n: I18nState,
    onValidate?: (isValid: boolean) => void,
): FormikErrors<InformationsIcp> => {
    const trimedValues = trimInfosIcp(values)
    const errors: FormikErrors<InformationsIcp> = {}
    if (!trimedValues.type) {
        errors.type = i18n.t('screens.addUserAdminScreen.required')
    }
    if (!trimedValues.description) {
        errors.description = i18n.t('screens.addUserAdminScreen.required')
    }
    if (!trimedValues.interventionObject) {
        errors.interventionObject = i18n.t('screens.addUserAdminScreen.required')
    }
    if (!trimedValues.interventionType) {
        errors.interventionType = i18n.t('screens.addUserAdminScreen.required')
    }
    if (!!trimedValues.startDate && !!trimedValues.endDate) {
        if (
            trimedValues.type === 'one-time' &&
            isAfter(new Date(trimedValues.endDate), addAprrYear(new Date(trimedValues.startDate), 1))
        ) {
            errors.endDate = i18n.t('components.infosIcp.errors.oneYear')
        }
        if (isBefore(new Date(trimedValues.endDate), new Date(trimedValues.startDate))) {
            errors.endDate = i18n.t('components.infosIcp.errors.isBefore')
        }
    }
    if (!trimedValues.startDate) {
        errors.startDate = i18n.t('screens.addUserAdminScreen.required')
    }
    if (!trimedValues.endDate) {
        errors.endDate = i18n.t('screens.addUserAdminScreen.required')
    }
    if (trimedValues.places.length === 0) {
        errors.places = [i18n.t('screens.addUserAdminScreen.required')]
    }

    if (!!onValidate) {
        onValidate(Object.keys(errors).length === 0)
    }

    return errors
}

export default (props: Props) => {
    const {
        initialValues,
        onValidate,
        globalSetIcpFieldValue,
        reasons,
        interventionObjects,
        interventionTypes,
        updateActors,
        globalActors,
        setIsDescriptionSet,
    } = props
    const i18n = useI18n()

    const [showOperations, setShowOperations] = React.useState<boolean>(initialValues.otherOperations.length > 0)

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={() => undefined} // On passe par le handleSubmit global sans validation
            validate={values => validateInfosIcp(values, i18n, onValidate)}
        >
            {({ values, errors, setFieldValue, isValid, setValues, validateForm }) => {
                const globalSetFieldValue = (key: keyof InformationsIcp, value: any) => {
                    globalSetIcpFieldValue(
                        produce(values, newValues => {
                            ;(newValues[key] as any) = value // as any pour corriger le cas des champs boolean qui posent problème
                        }),
                    )
                    if (key === 'endDate') {
                        updateActors(
                            produce(globalActors, newActors => {
                                newActors.agencies = globalActors.agencies.map(agency => ({
                                    ...agency,
                                    endDate: value && new Date(value),
                                }))
                            }),
                        )
                    }
                    setFieldValue(key, value)
                }

                React.useEffect(() => setIsDescriptionSet(values.description !== ''), [values.description])

                const globalSetValues = (newValues: InformationsIcp) => {
                    globalSetIcpFieldValue(newValues)
                    setValues(newValues)
                    updateActors(
                        produce(globalActors, newActors => {
                            newActors.agencies = globalActors.agencies.map(agency => ({
                                ...agency,
                                startDate: !!newValues.startDate ? new Date(newValues.startDate) : undefined,
                                endDate: !!newValues.endDate ? new Date(newValues.endDate) : undefined,
                            }))
                        }),
                    )
                }
                return (
                    <Container title={i18n.t('components.infosIcp.title')} hasErrors={!isValid}>
                        <View>
                            {reasons && reasons.length > 0 && (
                                <Section zIndex={1}>
                                    <SectionTitle>
                                        {i18n.t('components.infosIcp.sectionsTitles.amendmentReason', {
                                            count: reasons.length,
                                        })}
                                    </SectionTitle>
                                    {reasons.map((reason, index) => (
                                        <Reason key={index}>
                                            {i18n.t('components.infosIcp.listItem', {
                                                item: i18n.t(`screens.ppeePdfScreen.values.amendmentReasons.${reason}`),
                                            })}
                                        </Reason>
                                    ))}
                                </Section>
                            )}
                            <CheckboxesLine>
                                <Checkboxes
                                    label={i18n.t('components.infosIcp.labels.type')}
                                    required={true}
                                    labels={[
                                        i18n.t('components.infosIcp.values.one-time'),
                                        i18n.t('components.infosIcp.values.annual'),
                                    ]}
                                    values={[
                                        !!values.type
                                            ? values.type === 'one-time'
                                                ? i18n.t('components.infosIcp.values.one-time')
                                                : i18n.t('components.infosIcp.values.annual')
                                            : '',
                                    ]}
                                    onChange={value => {
                                        globalSetValues({
                                            ...values,
                                            type: !value[0]
                                                ? values.type
                                                : value[0] === i18n.t('components.infosIcp.values.one-time')
                                                ? 'one-time'
                                                : 'annual',

                                            endDate:
                                                !!value[0] && value[0] === i18n.t('components.infosIcp.values.annual')
                                                    ? values.startDate
                                                        ? addAprrYear(new Date(values.startDate), 1)
                                                        : undefined
                                                    : values.endDate,
                                        })
                                    }}
                                    error={!!errors && errors.type}
                                    radio={true}
                                />
                            </CheckboxesLine>
                            <CheckboxLine>
                                <Checkbox
                                    label={i18n.t('components.infosIcp.labels.socle')}
                                    value={values.socle}
                                    onCheck={() => globalSetFieldValue('socle', !values.socle)}
                                />
                            </CheckboxLine>
                            <Section zIndex={1}>
                                <SectionTitle>
                                    {i18n.t('components.infosIcp.sectionsTitles.categorisation')}
                                </SectionTitle>
                                <RowLine zIndex={-1}>
                                    <InputContainer isLeft={true} zIndex={0}>
                                        <Picker
                                            value={values.interventionObject}
                                            data={alphabetical(interventionObjects)}
                                            onChange={index =>
                                                globalSetFieldValue(
                                                    'interventionObject',
                                                    interventionObjects[index] || '',
                                                )
                                            }
                                            placeholder={i18n.t('components.infosIcp.interventionObjectPlaceholder')}
                                            label={i18n.t('components.infosIcp.labels.interventionObject')}
                                            error={!!errors && errors.interventionObject}
                                            required={true}
                                            isClearable={true}
                                        />
                                    </InputContainer>
                                    <InputContainer zIndex={-1}>
                                        <Picker
                                            value={values.interventionType}
                                            data={alphabetical(interventionTypes)}
                                            onChange={index =>
                                                globalSetFieldValue('interventionType', interventionTypes[index] || '')
                                            }
                                            placeholder={i18n.t('components.infosIcp.interventionTypePlaceholder')}
                                            label={i18n.t('components.infosIcp.labels.interventionType')}
                                            error={!!errors && errors.interventionType}
                                            required={true}
                                            isClearable={true}
                                        />
                                    </InputContainer>
                                </RowLine>
                            </Section>
                            <Section zIndex={0}>
                                <SectionTitle>{i18n.t('components.infosIcp.sectionsTitles.description')}</SectionTitle>
                                <Line zIndex={0}>
                                    <InputContainer>
                                        <TextInput
                                            value={values.description}
                                            multiline={true}
                                            numberOfLines={4}
                                            label={i18n.t('components.infosIcp.labels.description')}
                                            onTextChange={text => globalSetFieldValue('description', text)}
                                            placeholder={i18n.t('components.infosIcp.placeholder')}
                                            required={true}
                                            error={!!errors && errors.description}
                                        />
                                    </InputContainer>
                                </Line>
                                <RowLine zIndex={-1}>
                                    <InputContainer isLeft={true} zIndex={0}>
                                        <DatePicker
                                            label={i18n.t('components.infosIcp.labels.startDate')}
                                            value={values.startDate ? new Date(values.startDate) : undefined}
                                            onChange={date => {
                                                globalSetValues({
                                                    ...values,
                                                    startDate: date,
                                                    endDate:
                                                        values.type === 'annual'
                                                            ? !!date
                                                                ? addAprrYear(date, 1)
                                                                : undefined
                                                            : date && values.endDate && isAfter(date, values.endDate)
                                                            ? date
                                                            : values.endDate,
                                                })
                                            }}
                                            required={true}
                                            error={!!errors && errors.startDate}
                                            minDate={new Date()}
                                        />
                                    </InputContainer>
                                    <InputContainer zIndex={-1}>
                                        <DatePicker
                                            label={i18n.t('components.infosIcp.labels.endDate')}
                                            value={values.endDate ? new Date(values.endDate) : undefined}
                                            onChange={date => globalSetFieldValue('endDate', date)}
                                            disabled={values.type === 'annual'}
                                            dateFormat="date"
                                            required
                                            error={!!errors && errors.endDate}
                                            minDate={values.startDate && new Date(values.startDate)}
                                            maxDate={
                                                values.type === 'one-time'
                                                    ? values.startDate && addAprrYear(new Date(values.startDate), 1)
                                                    : undefined
                                            }
                                        />
                                    </InputContainer>
                                </RowLine>
                            </Section>
                            <Section zIndex={-1}>
                                <SectionTitle>{i18n.t('components.infosIcp.sectionsTitles.places')}</SectionTitle>
                                <InputContainer>
                                    <AddIcpPlaces
                                        values={values.places}
                                        required={true}
                                        onChange={places => globalSetFieldValue('places', places)}
                                        label={i18n.t('components.infosIcp.labels.places')}
                                        error={!!errors && errors.places}
                                    />
                                </InputContainer>
                                <Checkboxes
                                    label={i18n.t('components.infosIcp.labels.otherOperations')}
                                    labels={[
                                        i18n.t('components.infosIcp.values.yes'),
                                        i18n.t('components.infosIcp.values.no'),
                                    ]}
                                    values={[
                                        showOperations
                                            ? i18n.t('components.infosIcp.values.yes')
                                            : i18n.t('components.infosIcp.values.no'),
                                    ]}
                                    onChange={value => {
                                        if (!!value[0]) {
                                            setShowOperations(value[0] === i18n.t('components.infosIcp.values.yes'))
                                            globalSetFieldValue('otherOperations', [])
                                        }
                                    }}
                                    error={!!errors && errors.socle}
                                    radio={true}
                                />
                                {showOperations && (
                                    <Checkboxes
                                        labels={otherOperations}
                                        values={values.otherOperations}
                                        onChange={tab => globalSetFieldValue('otherOperations', tab)}
                                        radio={false}
                                    />
                                )}
                            </Section>
                        </View>
                    </Container>
                )
            }}
        </Formik>
    )
}
const SectionTitle = styled(Text)`
    ${props => props.theme.fonts.icpFormTitle}
    font-family: Avenir-Book;
    margin-bottom: 20px;
`
const RowLine = styled(View)<{ zIndex: number }>`
    flex-direction: ${Platform.OS === 'web' ? 'row' : 'column'};
    ${Platform.OS === 'web' && 'align-items: flex-end;'}
    z-index: ${props => props.zIndex};
`
const Line = styled(View)<{ zIndex: number }>`
    z-index: ${props => props.zIndex};
`
const InputContainer = styled(View)<{ isLeft?: boolean; zIndex?: number }>`
    ${Platform.OS === 'web' && 'flex: 1;'}
    ${props => props.isLeft && Platform.OS === 'web' && `margin-right: 40px`}
    margin-bottom: ${Platform.OS === 'web' ? '24px' : '15px'}
    ${props => props.zIndex && `z-index: ${props.zIndex};`}
`
const Section = styled(View)<{ zIndex: number }>`
    z-index: ${props => props.zIndex};
    margin-bottom: ${Platform.OS === 'web' ? '24px' : '15px'};
`

const CheckboxesLine = styled(View)`
    flex-direction: ${Platform.OS === 'web' ? 'row' : 'column'};
    margin-bottom: 20px;
    ${Platform.OS === 'web' &&
        `justify-content: space-between;
        align-items: flex-end;`}
`
const CheckboxLine = styled(View)`
    margin-bottom: 20px;
    background-color: ${props => props.theme.colors.rubriqueBackground};
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 24px;
    padding-right: 24px;
`
const Reason = styled(Text)`
    ${props => props.theme.fonts.amendmentReason}
    margin-left: ${props => props.theme.constants.fieldPadding};
`
