import * as React from 'react'

// Components
import { View, Text } from 'react-native'
import EditIcpScreen from '../editIcpScreen/editIcpScreen'
import LottieView from 'lottie-react-native'

// Utils
import useParams from '@layout/useParams'
import produce from 'immer'

// Api
import api from '@app/api/api'
import Logger from '@utils/logger'

// Store
import useI18n from '@store/i18n/useI18n'

// Styles
import styled from '@styles/index'

interface Params {
    ppeeId: string
    reasons: AmendmentReason[]
}

const filterFamilyRiskResponsables = (familyRisk: RisksAndMeasures): RisksAndMeasures =>
    produce(familyRisk, newFamilyRisk => {
        newFamilyRisk.risksAndMeasures.forEach((family, i) => {
            newFamilyRisk.risksAndMeasures[i].id = undefined
            family.risks.forEach((risk, j) => {
                risk.measures.forEach((measure, k) => {
                    newFamilyRisk.risksAndMeasures[i].risks[j].measures[k].responsables = []
                })
            })
        })
    })

export default () => {
    const { ppeeId, reasons } = useParams<Params>()
    const i18n = useI18n()

    const [initValues, setInitValues] = React.useState<Icp | undefined>(undefined)
    const [error, setError] = React.useState<string>('')
    const [status, setStatus] = React.useState<Status>('loading')

    const initAmendmentFromPpee = React.useMemo(
        () => (ppee: Icp): Icp =>
            produce(ppee, amendment => {
                // /!\ NE RENSEIGNER QUE LES CHANGEMENTS /!\
                // -------------- INFORMATIONS AVENANT - PPEE --------------
                amendment.id = undefined
                amendment.idParent = ppeeId
                amendment.raisonsAvenant = reasons
                amendment.reference = ''
                amendment.formulairesPpee = []
                amendment.documentsPpee = { documentsAprr: [], documentsExt: [], attachedDocuments: [] }
                amendment.avenantsPpee = []
                amendment.status = 'aPlanifier'

                // -------------- INFORMATIONS --------------
                // Dans le cas d'une modification de durée on enlève la date de fin d'opération
                if (reasons.indexOf('duration') > -1) {
                    amendment.informations.endDate = undefined
                }

                // -------------- PLANIFICATION --------------
                amendment.planification.contacts =
                    ppee.planification &&
                    ppee.planification.contacts.map(c => ({
                        ...c,
                        id: undefined,
                        state: 'notSent',
                    }))
                amendment.planification.declaration.cssctOpened = true
                amendment.planification.declaration.wiOpened = false
                const cssct = ppee.planification.declaration.cssct
                amendment.planification.declaration.cssct = {
                    id: undefined,
                    email: cssct ? cssct.email : '',
                    byEmail: cssct ? cssct.byEmail : true,
                    hasBeenSent: false,
                    hasBeenSentByEmail: false,
                }
                amendment.planification.date = new Date(new Date().setHours(8, 0))
                amendment.planification.place = ppee.planification.place || ''
                amendment.informations.description = ppee.informations.description || ''

                // -------------- ACTORS --------------
                amendment.actors.representatives = ppee.actors.representatives.map(r => ({
                    ...r,
                    id: undefined,
                    approbation: 'notSent',
                    signature: undefined,
                    signatureDate: undefined,
                    status: 'Présent',
                }))
                amendment.actors.agencies = ppee.actors.agencies.map(a => ({
                    ...a,
                    id: undefined,
                    endDate: reasons.indexOf('duration') > -1 ? undefined : a.endDate,
                }))

                // -------------- DOCUMENTS --------------
                amendment.documents.documentsExt = ppee.documents.documentsExt.map(d => ({
                    ...d,
                    id: undefined,
                }))
                amendment.documents.attachedDocuments = ppee.documents.attachedDocuments.map(d => ({
                    ...d,
                    id: undefined,
                }))
                const markingDocument = ppee.site.markingDocument

                // -------------- SITE --------------
                amendment.site.markingDocument = markingDocument
                    ? {
                          ...markingDocument,
                          id: undefined,
                      }
                    : undefined

                // -------------- FAMILYRISK --------------
                if (amendment.familyRisk) {
                    amendment.familyRisk = filterFamilyRiskResponsables(amendment.familyRisk)
                }
                setStatus('fetched')
            }),
        [ppeeId, reasons],
    )

    React.useEffect(() => {
        if (!!reasons && reasons.length > 0) {
            api.icp
                .getIcp(ppeeId)
                .then(ppee => {
                    setInitValues(initAmendmentFromPpee(ppee))
                })
                .catch(err => {
                    setStatus('error')
                    Logger.error(err)
                })
        } else {
            setStatus('error')
            setError(i18n.t('screens.editAmendmentScreen.errors.noReason'))
        }
    }, [i18n, initAmendmentFromPpee, reasons, ppeeId])

    return status === 'loading' ? (
        <ScreenContainer>
            <LottieView source={require('@drawable/loadingAnimation_red.json')} autoPlay loop />
        </ScreenContainer>
    ) : !!error || !initValues ? (
        <ScreenContainer>
            <LottieView source={require('@drawable/errorLoading.json')} autoPlay />
            <ErrorText>{error}</ErrorText>
        </ScreenContainer>
    ) : (
        <EditIcpScreen initialAmendment={initValues} />
    )
}

const ErrorText = styled(Text)`
    margin-top: 20px;
    ${props => props.theme.fonts.rootContainerError}
`
const ScreenContainer = styled(View)`
    flex: 1;
    align-items: center;
    justify-content: center;
`
