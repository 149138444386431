import * as React from 'react'

// Components
import { View, Text } from 'react-native'
import { TouchableItemFeedback } from '@components/touchable/Touchable'
import SelectPpeeModal from './selectPpeeModal'

// Store
import useI18n from '@store/i18n/useI18n'

// Style
import styled from '@styles/index'

interface Props {
    selectedPpeeId?: string
    ppeeList: SimpleIcp[]
    required?: boolean
    label?: string
    error?: any
    onChange: (ppeeId?: string) => void
}

export default (props: Props) => {
    const { selectedPpeeId, onChange, error, ppeeList } = props

    const i18n = useI18n()

    const [modalVisible, setModalVisible] = React.useState<boolean>(false)

    const selectedPpee = React.useMemo(() => ppeeList.find(ppee => ppee.id === selectedPpeeId), [
        ppeeList,
        selectedPpeeId,
    ])

    return (
        <View>
            {modalVisible && (
                <SelectPpeeModal
                    modalVisible={modalVisible}
                    onRequestClose={() => {
                        setModalVisible(false)
                    }}
                    onChange={onChange}
                    ppeeList={ppeeList}
                />
            )}

            <Container error={!!error}>
                <PpeeList>
                    {!selectedPpee ? (
                        <NoPpeeContainer>
                            <NoPpee>{i18n.t('components.selectPpee.noPpee')}</NoPpee>
                            <TouchableItemFeedback onPress={() => setModalVisible(true)}>
                                <Action centered={true}>{i18n.t('components.selectPpee.selectPpee')}</Action>
                            </TouchableItemFeedback>
                        </NoPpeeContainer>
                    ) : (
                        <PpeeContainer>
                            <RowContainer>
                                <Flexbox>
                                    <PpeeName ellipsizeMode={'tail'} numberOfLines={3}>
                                        {selectedPpee.companiesName.length === 0
                                            ? i18n.t(`screens.icpList.noCompany`)
                                            : selectedPpee.companiesName.sort((a, b) => a.localeCompare(b)).join(', ')}
                                    </PpeeName>
                                </Flexbox>
                                <ActionsContainer>
                                    <TouchableItemFeedback onPress={() => setModalVisible(true)}>
                                        <Action>{i18n.t('components.selectPpee.edit')}</Action>
                                    </TouchableItemFeedback>
                                    <TouchableItemFeedback onPress={() => onChange(undefined)}>
                                        <Action>{i18n.t('components.selectPpee.delete')}</Action>
                                    </TouchableItemFeedback>
                                </ActionsContainer>
                            </RowContainer>
                        </PpeeContainer>
                    )}
                </PpeeList>
            </Container>
        </View>
    )
}

const PpeeContainer = styled(View)<{ odd?: boolean }>`
    background-color: ${props => !props.odd && props.theme.colors.evenLines};
`
const Container = styled(View)<{ error?: boolean }>`
    background-color: ${props => props.theme.colors.addIcpContactsBackground};
    padding-right: 8px;
    padding-left: 8px;
    ${props => props.error && 'border: ' + props.theme.colors.textInputBorderError + ' solid 1px;'}
    margin-bottom: 24px;
`
const RowContainer = styled(View)`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: ${props => props.theme.constants.fieldPadding};
    padding-left: ${props => props.theme.constants.fieldPadding};
    min-height: 36px;
`
const ActionsContainer = styled(View)`
    width: 120px;
    flex-direction: row;
    justify-content: space-between;
`

const Action = styled(Text)<{ centered?: boolean }>`
    ${props => props.theme.fonts.homeActionLink}
    text-decoration: underline;
    text-decoration-color: ${props => props.theme.fonts.homeActionLink.color};
    ${props => props.centered && 'text-align: center;'}
`
const PpeeList = styled(View)`
    padding-bottom: 8px;
    padding-top: 8px;
`
const NoPpeeContainer = styled(View)`
    padding-top: 24px;
    padding-bottom: 24px;
`
const NoPpee = styled(Text)`
    ${props => props.theme.fonts.perimeterName}
    text-align: center;
    margin-bottom 12px;
`
const PpeeName = styled(Text)`
    ${props => props.theme.fonts.icpContactName}
`
const Flexbox = styled(View)`
    flex: 1;
`
