import * as React from 'react'

// Components
import { View, Text } from 'react-native'
import FieldInformation from '@components/fieldInformation/FieldInformation'
import { TouchableItemFeedback } from '@components/touchable/Touchable'
import TextInput from '@components/textInput/TextInput'
import Button from '@components/button/Button'

// Store
import useI18n from '@store/i18n/useI18n'

// Style
import styled from '@styles/index'
import useTheme from '@styles/useTheme'
import EditableList, { EditingText } from '@components/lists/EditableList'

interface Props {
    values: string[]
    required?: boolean
    label?: string
    error?: any
    onChange: (places: string[]) => void
}

export default (props: Props) => {
    const { values, required, label, onChange, error } = props

    const i18n = useI18n()
    const [Theme] = useTheme()

    const [inputVisible, setInputVisible] = React.useState<boolean>(false)
    const [inputValue, setinputValue] = React.useState<string>('')

    const [editingPlace, setEditingPlace] = React.useState<EditingText>()

    return (
        <View>
            <FieldInformation label={label} required={required} error={error} />
            <Container error={!!error}>
                <RowContainer odd={true}>
                    <Legend>{i18n.t('components.addIcpPlaces.place')}</Legend>
                    <TouchableItemFeedback
                        onPress={() => {
                            setInputVisible(!inputVisible)
                            setinputValue('')
                        }}
                    >
                        <Action>{i18n.t(`components.addIcpPlaces.${inputVisible ? 'cancel' : 'addPlace'}`)}</Action>
                    </TouchableItemFeedback>
                </RowContainer>
                {inputVisible && (
                    <View>
                        <TextInput
                            value={inputValue}
                            onTextChange={text => setinputValue(text)}
                            customInputBackgroundColor={Theme.colors.riskFamilyItemInput}
                            placeholder={i18n.t('components.addIcpPlaces.placeholder')}
                            maxLength={310}
                            // TODO : erreurs
                        />
                        <ButtonContainer>
                            <Button
                                status={'active'}
                                libelle={i18n.t('components.addIcpPlaces.add')}
                                onPress={() => {
                                    onChange([...values, inputValue.trim()])
                                    setInputVisible(false)
                                    setinputValue('')
                                }}
                            />
                        </ButtonContainer>
                    </View>
                )}
                <PlaceList>
                    {values.length === 0 ? (
                        <NoPlace>{i18n.t('components.addIcpPlaces.noPlace')}</NoPlace>
                    ) : (
                        values.map((place, index) => (
                            <EditableList
                                key={`${place} - ${index}`}
                                text={place}
                                index={index}
                                editing={!!editingPlace && editingPlace.index === index}
                                textList={values}
                                setTextList={onChange}
                                editingText={editingPlace}
                                setEditingText={setEditingPlace}
                            />
                        ))
                    )}
                </PlaceList>
            </Container>
        </View>
    )
}
const Container = styled(View)<{ error?: boolean }>`
    background-color: ${props => props.theme.colors.addIcpContactsBackground};
    padding-right: 8px;
    padding-left: 8px;
    ${props => props.error && 'border: ' + props.theme.colors.textInputBorderError + ' solid 1px;'}
`
const RowContainer = styled(View)<{ odd?: boolean }>`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: ${props => props.theme.constants.fieldPadding};
    padding-left: ${props => props.theme.constants.fieldPadding};
    min-height: 36px;

    background-color: ${props => !props.odd && props.theme.colors.evenLines};
`
const Legend = styled(Text)`
    ${props => props.theme.fonts.rightLegend}
    text-transform: uppercase;
`
const Action = styled(Text)`
    ${props => props.theme.fonts.homeActionLink}
    text-decoration: underline;
    text-decoration-color: ${props => props.theme.fonts.homeActionLink.color};
`
const PlaceList = styled(View)`
    padding-bottom: 8px;
`
const NoPlace = styled(Text)`
    ${props => props.theme.fonts.perimeterName}
    text-align: center;
    padding-top: 24px;
    padding-bottom: 24px;
`
const ButtonContainer = styled(View)`
    margin-top: 10px;
    margin-bottom: 10px;
    flex-direction: row;
    justify-content: flex-end;
`
