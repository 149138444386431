import * as React from 'react'

// Components
import { View, Text, ScrollView } from 'react-native'
import { TouchableItemFeedback } from '@components/touchable/Touchable'
import Button from '@components/button/Button'
import RootScreen from '@components/rootScreen/RootScreen'
import Icon from '@components/icon/Icon'
import SearchInput from '@components/searchInput/SearchInput'

// Store
import useI18n from '@store/i18n/useI18n'
import * as userStore from '@store/user'
import * as filtersStore from '@store/filters'
import useReducer from '@store/useReducer'

// Utils
import Logger from '@utils/logger'
import useNavigation from '@layout/useNavigation'
import { isAfter } from 'date-fns'
import * as JsSearch from 'js-search'
import accentSanitizer from '@utils/accentSanitizer'
import { canUserAddICP } from '@utils/userRight'

// Api
import api from '@api/api'

// Style
import styled from '@styles/index'
import SelectPerimeter from '@components/selectPerimeter/SelectPerimeter'
import { getFullPerimeterFromPerimeterID, getAllPerimeterAndSubLevels } from '@utils/perimeterUtils'
import useTheme from '@styles/useTheme'

//* INITIALISATION POUR RECHERCHE
const sanitizer = accentSanitizer()

export default ({ initialType, initialFilter, multiEntreprises }: IcpListScreenProps) => {
    const i18n = useI18n()
    const [Theme] = useTheme()

    const navigation = useNavigation()
    const userInfo = useReducer(userStore.store, s => s)
    const lastScreen = useReducer(filtersStore.store, s => s.lastScreen)

    const userRole = userInfo && userInfo.roleName
    const isRedacteur = userRole === 'Rédacteur'

    const selectedPerimetersIds = useReducer(filtersStore.store, s => s.selectedPerimetersIds)

    const [status, setStatus] = React.useState<Status>('loading')
    const [icpList, setIcpList] = React.useState<SimpleIcp[]>([])

    const [triAsc, setTriIAsc] = React.useState<TriType[]>([])
    const [triDesc, setTriDesc] = React.useState<TriType[]>([])
    const [filterOpen, setFilterOpen] = React.useState<boolean>(true)

    const [fullScreenOn, setFullScreenOn] = React.useState<boolean>(false)

    const selectedFilters = useReducer(filtersStore.store, s => s.selectedFilters)
    const { hasPerimeterFilterBeenSelected } = useReducer(filtersStore.store, s => s)
    const text = useReducer(filtersStore.store, s => s.searchText)

    const [perimeters, setPerimeters] = React.useState<Perimeter[]>()

    React.useEffect(() => {
        //Initialisation des filtres lorsque l'on vient de la home
        lastScreen === 'home' &&
            filtersStore.actions.setSelectedFilters(
                initialFilter ? (isRedacteur ? [initialFilter, 'mesICP'] : [initialFilter]) : [],
            )
    }, [lastScreen, initialFilter, isRedacteur])

    React.useEffect(() => {
        Promise.all([
            initialType === 'ICP' ? api.icp.getIcpList() : api.icp.getPpeeList(),
            api.perimeter.getPerimeterList(),
        ])
            .then(([icps, p]) => {
                setIcpList(multiEntreprises ? icps.filter(icp => icp.companiesName.length > 1) : icps)
                setPerimeters(p)
                if (userInfo && userInfo.perimeter) {
                    const initPerimeters = getFullPerimeterFromPerimeterID(userInfo.perimeter.id, p)
                    if (initPerimeters.length === 0) {
                        setStatus('error')
                    } else {
                        if (!hasPerimeterFilterBeenSelected) {
                            //On n'a pas encore choisi de périmètre comme filtre, on met donc par défaut ceux de l'utilisateur
                            initPerimeters.length > 0 && filtersStore.actions.setDirection(initPerimeters[0])
                            initPerimeters.length > 1 && filtersStore.actions.setStructure(initPerimeters[1])
                            initPerimeters.length > 2 && filtersStore.actions.setPerimeter(initPerimeters[2])
                            initPerimeters.length > 3 && filtersStore.actions.setSector(initPerimeters[3])
                            filtersStore.actions.setSelectedPerimetersIds(
                                getAllPerimeterAndSubLevels(initPerimeters.slice(-1)),
                            )
                        }
                    }
                }
                setStatus('fetched')
            })
            .catch(err => {
                Logger.error(err)
                setStatus('error')
            })
    }, [initialType, multiEntreprises, userInfo, hasPerimeterFilterBeenSelected])

    const icpFilterFunctions: { [key: string]: (list: SimpleIcp[]) => SimpleIcp[] } = {
        aPlanifier: (list: SimpleIcp[]) => list.filter(a => a.status === 'aPlanifier'),
        aRealiser: (list: SimpleIcp[]) => list.filter(a => a.status === 'aRealiser'),
        aFinaliser: (list: SimpleIcp[]) => list.filter(a => a.status === 'aFinaliser'),
        aApprouver: (list: SimpleIcp[]) => list.filter(a => a.status === 'aApprouver'),
        all: (list: SimpleIcp[]) => list,
    }

    const ppeeFilterFunctions: { [key: string]: (list: SimpleIcp[]) => SimpleIcp[] } = {
        actif: (list: SimpleIcp[]) => list.filter(a => a.status === 'actif' || a.status === 'aEcheance'),
        aEcheance: (list: SimpleIcp[]) => list.filter(a => a.status === 'aEcheance'),
        echu: (list: SimpleIcp[]) => list.filter(a => a.status === 'echu'),
        all: (list: SimpleIcp[]) => list,
    }

    const filterFunctions = React.useMemo((): { [key: string]: (list: SimpleIcp[]) => SimpleIcp[] } => {
        if (initialType === 'ICP') {
            return {
                mesICP: (list: SimpleIcp[]) => list.filter(a => a.redacteurId === (userInfo && userInfo.id)),
                ...icpFilterFunctions,
            }
        } else if (initialType === 'PPEE') {
            return {
                mesICP: (list: SimpleIcp[]) => list.filter(a => a.redacteurId === (userInfo && userInfo.id)),
                ...ppeeFilterFunctions,
            }
        } else {
            return {
                mesICP: (list: SimpleIcp[]) => list.filter(a => a.redacteurId === (userInfo && userInfo.id)),
                ...icpFilterFunctions,
                ...ppeeFilterFunctions,
            }
        }
    }, [initialType, userInfo, ppeeFilterFunctions, icpFilterFunctions])

    const renderICPState = (icpStatus: ICPStatus) => {
        return (
            <ICPStateTag icpStatus={icpStatus}>
                <ICPStateText numberOfLines={1} icpStatus={icpStatus}>
                    {i18n.t(`screens.icpList.ICPStates.${icpStatus}`)}
                </ICPStateText>
            </ICPStateTag>
        )
    }

    const renderIcpLine = (icp: SimpleIcp, index: number) => (
        <ICPLine odd={index % 2 === 1}>
            <IcpLineItem double leftAligned>
                <ICPTitle numberOfLines={1}>
                    {icp.description ? icp.description : i18n.t(`screens.icpList.noIcpDescription`)}
                </ICPTitle>
            </IcpLineItem>
            <IcpLineItem double>
                <ICPTitle numberOfLines={1}>
                    {icp.companiesName.length === 0
                        ? i18n.t(`screens.icpList.noCompany`)
                        : icp.companiesName.sort((a, b) => a.localeCompare(b)).join(', ')}
                </ICPTitle>
            </IcpLineItem>
            <IcpLineItem>{icp.isAvenant && <Icon name="checkmark" size={15} />}</IcpLineItem>
            <IcpLineItem>
                <IcpLineItemText numberOfLines={1}>
                    {icp.icpDate
                        ? i18n.t(`screens.icpList.icpDate`, { date: new Date(icp.icpDate) })
                        : i18n.t(`screens.icpList.noIcpDate`)}
                </IcpLineItemText>
            </IcpLineItem>
            <IcpLineItem>
                <IcpLineItemText numberOfLines={1}>
                    {icp.operationDate
                        ? i18n.t(`screens.icpList.operationDate`, { date: new Date(icp.operationDate) })
                        : i18n.t(`screens.icpList.noOperationDate`)}
                </IcpLineItemText>
            </IcpLineItem>
            <IcpLineItem>
                <IcpLineItemText numberOfLines={1}>
                    {icp.endDate
                        ? i18n.t(`screens.icpList.endDate`, { date: new Date(icp.endDate) })
                        : i18n.t(`screens.icpList.noIcpDate`)}
                </IcpLineItemText>
            </IcpLineItem>
            <IcpLineItem>
                <IcpLineItemText numberOfLines={1}>{icp.redacteur}</IcpLineItemText>
            </IcpLineItem>
            <IcpLineItem>{renderICPState(icp.status)}</IcpLineItem>
        </ICPLine>
    )

    const setTriToUse = (triType: TriType, triOrder: 'Asc' | 'Desc') => {
        if (triOrder === 'Asc') {
            if (triAsc.indexOf(triType) !== -1) {
                setTriIAsc(triAsc.filter(t => t !== triType))
            } else {
                setTriIAsc([...triAsc, triType])
                setTriDesc(triDesc.filter(t => t !== triType))
            }
        } else {
            if (triDesc.indexOf(triType) !== -1) {
                setTriDesc(triDesc.filter(t => t !== triType))
            } else {
                setTriDesc([...triDesc, triType])
                setTriIAsc(triAsc.filter(t => t !== triType))
            }
        }
    }

    const isTriSelected = (triType: TriType, triOrder: 'Asc' | 'Desc'): boolean =>
        triOrder === 'Asc' ? triAsc.indexOf(triType) !== -1 : triDesc.indexOf(triType) !== -1

    const renderTriComponent = (triType: TriType) => (
        <TriIconContainer>
            <TouchableItemFeedback onPress={() => setTriToUse(triType, 'Asc')}>
                <Icon
                    name="triAsc"
                    size={10}
                    color={isTriSelected(triType, 'Asc') ? Theme.colors.triSelected : Theme.colors.triUnselected}
                />
            </TouchableItemFeedback>
            <TouchableItemFeedback onPress={() => setTriToUse(triType, 'Desc')}>
                <IconRotated>
                    <Icon
                        name="triAsc"
                        size={10}
                        color={isTriSelected(triType, 'Desc') ? Theme.colors.triSelected : Theme.colors.triUnselected}
                    />
                </IconRotated>
            </TouchableItemFeedback>
        </TriIconContainer>
    )

    const isFilterSelected = (filterType: FilterType) => selectedFilters.indexOf(filterType) !== -1

    const setFilterToUse = (filterType: FilterType) => {
        if (isFilterSelected(filterType)) {
            filtersStore.actions.setSelectedFilters(selectedFilters.filter(t => t !== filterType))
        } else {
            filtersStore.actions.setSelectedFilters([...selectedFilters, filterType])
        }
    }

    const renderFilterView = () => {
        return (
            <FilterView zIndex={-3}>
                {Object.keys(filterFunctions).map(
                    (filter, i) =>
                        filter !== 'all' && (
                            <TouchableItemFeedback onPress={() => setFilterToUse(filter as FilterType)} key={i}>
                                <FilterContainer selected={isFilterSelected(filter as FilterType)}>
                                    <FilterLibelle selected={isFilterSelected(filter as FilterType)}>
                                        {i18n.t(`screens.icpList.filterOption.${initialType}.${filter}`)}
                                    </FilterLibelle>
                                </FilterContainer>
                            </TouchableItemFeedback>
                        ),
                )}
            </FilterView>
        )
    }

    //* FILTRE DES ELEMENTS SELON PERIMETRES
    const perimeterFilteredList = React.useMemo(
        (): SimpleIcp[] =>
            selectedPerimetersIds.length !== 0
                ? icpList.filter(icp => !!icp.perimeterId && selectedPerimetersIds.indexOf(icp.perimeterId) > -1)
                : icpList,
        [icpList, selectedPerimetersIds],
    )

    //* FILTRE DES ELEMENTS SELON CRITERE
    const filteredList = React.useMemo(
        (): SimpleIcp[] =>
            selectedFilters.reduce((acc, curr) => {
                if (!!filterFunctions[curr]) {
                    return filterFunctions[curr](acc)
                } else {
                    return filterFunctions['all'](acc)
                }
            }, perimeterFilteredList),
        [selectedFilters, perimeterFilteredList, filterFunctions],
    )

    //* ORDONNANCEMENT DES ELEMENTS
    const orderedIcpList: SimpleIcp[] = React.useMemo((): SimpleIcp[] => {
        const sortedList = [...filteredList]
        triAsc.forEach(t => {
            sortedList.sort((a, b) =>
                a[t] ? (b[t] ? (isAfter(new Date(a[t] as Date), new Date(b[t] as Date)) ? 1 : -1) : -1) : b[t] ? 1 : 0,
            )
        })
        triDesc.forEach(t => {
            sortedList.sort((a, b) =>
                a[t] ? (b[t] ? (isAfter(new Date(a[t] as Date), new Date(b[t] as Date)) ? -1 : 1) : 1) : b[t] ? -1 : 0,
            )
        })
        return sortedList
    }, [triAsc, triDesc, filteredList])

    //* RECHERCHE DES ELEMENTS PAR TEXTE
    const searchJs = React.useMemo(() => {
        const searchjs = new JsSearch.Search('id')

        searchjs.searchIndex = new JsSearch.UnorderedSearchIndex()
        searchjs.indexStrategy = new JsSearch.AllSubstringsIndexStrategy()
        searchjs.tokenizer = {
            tokenize: (text: string) => text.split(' '),
        }
        searchjs.sanitizer = sanitizer
        searchjs.addIndex('companiesName')
        searchjs.addIndex('redacteur')
        searchjs.addIndex('approbateur')
        searchjs.addIndex('description')
        searchjs.addDocuments(orderedIcpList)

        return searchjs
    }, [orderedIcpList])

    const searchedIcpList = React.useMemo((): SimpleIcp[] => {
        const searchedList = (text.trim()
            ? searchJs.search(sanitizer.sanitize(text.trim()))
            : orderedIcpList) as SimpleIcp[]
        return searchedList
    }, [orderedIcpList, text, searchJs])

    return (
        <RootScreen status={status} scrollable={false}>
            {!fullScreenOn && (
                <React.Fragment>
                    <SelectPerimeter
                        setSelectedPerimetersIds={filtersStore.actions.setSelectedPerimetersIds}
                        status={status}
                        perimeters={perimeters}
                    />
                    <SearchView zIndex={-2}>
                        <SearchContainer>
                            <SearchInput
                                value={text}
                                onTextChange={filtersStore.actions.setSearchText}
                                placeholder={i18n.t('screens.icpList.searchPlaceholder')}
                                resultsCount={searchedIcpList.length}
                            />
                        </SearchContainer>
                        <Button
                            libelle={i18n.t('screens.icpList.openFilterButton')}
                            status={'active'}
                            onPress={() => setFilterOpen(!filterOpen)}
                            width={175}
                        >
                            <React.Fragment>
                                <ButtonContent>
                                    <ButtonTitle numberOfLines={1} ellipsizeMode="tail">
                                        {i18n.t('screens.icpList.openFilterButton')}
                                    </ButtonTitle>
                                    <Icon
                                        name={filterOpen ? 'chevron-up' : 'chevron-down'}
                                        color={Theme.colors.buttonTitleClassic}
                                        size={10}
                                    />
                                </ButtonContent>
                                {selectedFilters.length !== 0 && <FilterActive />}
                            </React.Fragment>
                        </Button>
                    </SearchView>
                    {filterOpen && renderFilterView()}
                </React.Fragment>
            )}
            <Container zIndex={-4}>
                <ListTitleContainer>
                    <ListTitle numberOfLines={2}>{i18n.t(`screens.icpList.tabTitle.${initialType}`)}</ListTitle>
                    <ListActionsContainer>
                        {userInfo && canUserAddICP(userInfo) && (
                            <Button
                                libelle={i18n.t('screens.icpList.addIcp')}
                                onPress={() => navigation.push('/ajouterIcp')}
                                status="active"
                            />
                        )}
                        <FullScreenIconContainer>
                            <TouchableItemFeedback onPress={() => setFullScreenOn(!fullScreenOn)}>
                                <Icon
                                    name={fullScreenOn ? 'shrink' : 'expand'}
                                    color={Theme.colors.iconClassicColor}
                                    size={15}
                                />
                            </TouchableItemFeedback>
                        </FullScreenIconContainer>
                    </ListActionsContainer>
                </ListTitleContainer>

                <ICPsContainer>
                    <LegendLine>
                        <Legend double leftAligned>
                            <LegendText numberOfLines={1}>
                                {i18n.t('screens.icpList.legendLine.description')}
                            </LegendText>
                        </Legend>
                        <Legend double>
                            <LegendText numberOfLines={1}>{i18n.t('screens.icpList.legendLine.companies')}</LegendText>
                        </Legend>
                        <Legend>
                            <LegendText numberOfLines={1}>{i18n.t('screens.icpList.legendLine.isAvenant')}</LegendText>
                        </Legend>
                        <Legend>
                            <LegendText numberOfLines={1}>{i18n.t('screens.icpList.legendLine.dateICP')}</LegendText>
                            {renderTriComponent('icpDate')}
                        </Legend>
                        <Legend>
                            <LegendText numberOfLines={1}>
                                {i18n.t('screens.icpList.legendLine.dateOperation')}
                            </LegendText>
                            {renderTriComponent('operationDate')}
                        </Legend>
                        <Legend>
                            <LegendText numberOfLines={1}>{i18n.t('screens.icpList.legendLine.endDate')}</LegendText>
                            {renderTriComponent('endDate')}
                        </Legend>
                        <Legend>
                            <LegendText numberOfLines={1}>{i18n.t('screens.icpList.legendLine.redacteur')}</LegendText>
                        </Legend>
                        <Legend>
                            <LegendText numberOfLines={1}>{i18n.t('screens.icpList.legendLine.status')}</LegendText>
                        </Legend>
                    </LegendLine>

                    {searchedIcpList.length === 0 ? (
                        <NoIcpContainer>
                            <NoICPMessage>{i18n.t(`screens.icpList.no${initialType}`)}</NoICPMessage>
                        </NoIcpContainer>
                    ) : (
                        <ScrollView>
                            {searchedIcpList.map((icp, index) => {
                                return (
                                    <TouchableItemFeedback
                                        key={`icp${index}`}
                                        onPress={() => {
                                            filtersStore.actions.setLastScreen('icp')
                                            initialType === 'PPEE'
                                                ? navigation.push('/ppee/:ppeeId', {
                                                      ppeeId: icp.id,
                                                      pathParams: { ppeeId: icp.id },
                                                  })
                                                : navigation.push('/icp/:icpId', {
                                                      icpId: icp.id,
                                                      pathParams: { icpId: icp.id },
                                                  })
                                        }}
                                    >
                                        {renderIcpLine(icp, index)}
                                    </TouchableItemFeedback>
                                )
                            })}
                        </ScrollView>
                    )}
                </ICPsContainer>
            </Container>
        </RootScreen>
    )
}

const Container = styled(View)<{ zIndex: number }>`
    background-color: ${props => props.theme.colors.rolesBackground};
    padding: 5px 10px 10px 10px;
    flex: 1;
    margin-bottom: 10px;
    z-index: ${props => props.zIndex};
`
const ICPsContainer = styled(View)`
    flex: 1;
    padding: 5px;
`
const ICPLine = styled(View)<{ odd: boolean }>`
    flex-direction: row;
    align-items: center;
    padding: 0 5px;
    height: ${props => props.theme.constants.rightLineHeight}px;
    ${props => !props.odd && `background-color: ${props.theme.colors.evenLines};`}
    overflow: hidden;

    &:hover {
        height: ${props => props.theme.constants.rightLineHeight * 2}px;
        background-color: ${props => props.theme.colors.hoverBackground};
        & > div {
            max-height: ${props => props.theme.constants.rightLineHeight * 2}px;
            padding: 5px;
            color: ${props => props.theme.colors.hoverText};
            & > div {
                white-space: break-spaces !important;
                color: ${props => props.theme.colors.hoverText};
                & > div {
                    color: ${props => props.theme.colors.hoverText};
                }
            }
        }
    }
`
const IcpLineItem = styled(View)<{ double?: boolean; leftAligned?: boolean }>`
    flex: ${props => (props.double ? 2 : 1)};

    overflow: hidden;
    align-items: ${props => (props.leftAligned ? 'flex-start' : 'center')};
    justify-content: ${props => (props.leftAligned ? 'flex-start' : 'center')};
    padding: 0px 5px;
`
const ICPTitle = styled(Text)`
    flex: 1;
    line-height: ${props => (props.theme.constants.rightLineHeight * 2 - 10) * 0.2}px;
    text-overflow: ellipsis;
    ${props => props.theme.fonts.rightTitle}
    text-align: center;
`
const LegendLine = styled(View)`
    height: 34px;
    padding: 5px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`
const Legend = styled(View)<{ double?: boolean; leftAligned?: boolean }>`
    flex: ${props => (props.double ? 2 : 1)};
    flex-wrap: wrap;
    flex-direction: row;

    align-items: ${props => (props.leftAligned ? 'flex-start' : 'center')};
    justify-content: ${props => (props.leftAligned ? 'flex-start' : 'center')};
    padding: 0px 5px;
`
const LegendText = styled(Text)`
    ${props => props.theme.fonts.rightLegend}
    text-transform: uppercase;
`

const ListTitleContainer = styled(View)`
    flex-direction: row;
    height: 40px;
    justify-content: space-between;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;

    border-bottom-color: ${props => props.theme.colors.homeListBorderColor};
    border-bottom-width: 2px;
`
const ListTitle = styled(Text)`
    ${props => props.theme.fonts.homeListTitle}
    font-size: 15px;
    text-transform: uppercase;
`
const NoICPMessage = styled(Text)`
    ${props => props.theme.fonts.noIcpLibelle}
    text-align: center;
`
const NoIcpContainer = styled(View)`
    flex: 1;
    height: 48px;
    justify-content: center;
    align-items: center;
`
const ICPStateTag = styled(View)<{ icpStatus: ICPStatus }>`
    background-color: ${props =>
        props.icpStatus === 'aPlanifier'
            ? props.theme.colors.icpAPlanifier
            : props.icpStatus === 'aRealiser'
            ? props.theme.colors.icpARealiser
            : props.icpStatus === 'aFinaliser'
            ? props.theme.colors.icpAFinaliser
            : props.icpStatus === 'aApprouver'
            ? props.theme.colors.icpAApprouver
            : props.icpStatus === 'actif'
            ? props.theme.colors.icpActif
            : props.icpStatus === 'aEcheance'
            ? props.theme.colors.icpAEcheance
            : props.theme.colors.icpEchu};
    border-radius: 40px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
`
const ICPStateText = styled(Text)<{ icpStatus: ICPStatus }>`
    ${props =>
        props.icpStatus === 'aFinaliser' || props.icpStatus === 'aApprouver' || props.icpStatus === 'actif'
            ? props.theme.fonts.icpLightStateText
            : props.theme.fonts.icpDarkStateText};
`
const IcpLineItemText = styled(Text)`
    ${props => props.theme.fonts.icpLineItemText};
`
const IconRotated = styled(View)`
    transform: rotate(180deg);
    & > div {
        &:hover {
            color: ${props => props.theme.colors.triHovered};
        }
        & > div {
            &:hover {
                color: ${props => props.theme.colors.triHovered};
            }
        }
    }
`
const TriIconContainer = styled(View)`
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    margin-left: 10px;

    & > div {
        & > div {
            &:hover {
                color: ${props => props.theme.colors.triHovered};
            }
        }
    }
`
const SearchView = styled(View)<{ zIndex: number }>`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: 10px;
    z-index: ${props => props.zIndex};
`

const ButtonContent = styled(View)`
    flex-direction: row;
    align-items: space-between;
`
const ButtonTitle = styled(Text)`
    ${props => props.theme.fonts.buttonTitle}
    text-transform: uppercase;
    margin-right: 10px;
`
const SearchContainer = styled(View)`
    flex: 1;
    margin-right: 30px;
`
const FilterView = styled(View)<{ zIndex: number }>`
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 10px;
    z-index: ${props => props.zIndex};
`
const FilterContainer = styled(View)<{ selected?: boolean }>`
    padding: 10px;
    margin: 5px 15px 5px 0px;
    border-radius: 5px;

    cursor: pointer;
    background-color: ${props =>
        props.selected ? props.theme.colors.filterBackgroundColorSelected : props.theme.colors.filterBackgroundColor};
    &:hover {
        background-color: ${props => props.theme.colors.filterBackgroundColorHovered};
        & > div {
            & > div {
                color: ${props => props.theme.colors.filterLibelleHovered};
            }
        }
    }
`
const FilterLibelle = styled(Text)<{ selected?: boolean; disabled?: boolean }>`
    ${props => (props.selected ? props.theme.fonts.filterLibelleSelected : props.theme.fonts.filterLibelle)};
`

const FilterActive = styled(View)`
    background-color: ${props => props.theme.colors.filterActive};
    height: 13px;
    width: 13px;
    border-radius: 13px;

    position: absolute;
    top: -5px;
    right: -5px;
`
const ListActionsContainer = styled(View)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
`
const FullScreenIconContainer = styled(View)`
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${props => props.theme.colors.iconClassicColor};
    border-radius: 5px;
    padding: 5px;
    margin-left: 10px;
    box-shadow: 0px 0px 1px ${props => props.theme.colors.iconClassicColor};

    &:hover {
        border: 1px solid ${props => props.theme.colors.iconInversedColor};
        box-shadow: 0px 0px 1px ${props => props.theme.colors.iconInversedColor};
        background-color: ${props => props.theme.colors.iconClassicColor};

        & > div {
            & > div {
                color: ${props => props.theme.colors.iconInversedColor};
            }
        }
    }
`
