import * as React from 'react'
import { Platform, Text, View } from 'react-native'

import api from '@app/api/api'

import { TouchableItemFeedback } from '@components/touchable/Touchable'
import { basicAlert, confirmAlert } from '@components/alert/Alerts'

import styled from '@styles/index'
import useTheme from '@styles/useTheme'

import useI18n from '@store/i18n/useI18n'
import useNavigation from '@layout/useNavigation'

import { viewVisitformPdf } from '@utils/pdfUtils'
import logger from '@utils/logger'

const I18N_SCREEN_KEY = 'screens.ppeePdfScreen'

type ItemKey = 'visit' | 'formulaire'
interface Props {
    itemList: FormulairePpee[]
    onClose: () => void
    ppee: Icp
    handleFormDelete: (formId: string) => void
    itemKey: ItemKey
    refreshPpee: () => void
}

const PpeePdfMenuItem = ({ itemList, onClose, ppee, handleFormDelete, itemKey, refreshPpee }: Props) => {
    const i18n = useI18n()
    const navigation = useNavigation()
    const isVisit = itemKey === 'visit'
    const [Theme] = useTheme()

    const lastItemOnPress = (form: FormulairePpee) => {
        if (isVisit && form.etat === 'cloture' && Platform.OS !== 'web') {
            // Fiche de visite PDF sur téléphone
            navigation.push(`/ppee/:ppeeId/visitFormRead/:formId`, {
                ppee: ppee,
                formId: form.id,

                formTitle: form.titre,
                pathParams: { ppeeId: ppee.id, formId: form.id },
            })
        } else if (isVisit && !!ppee.id && Platform.OS === 'web') {
            // Fiche de visite PDF sur web
            viewVisitformPdf(ppee.id, form.id)
        } else if (!isVisit && form.etat !== 'cloture') {
            // Formulaire Supprimer sur téléphone et web
            handleFormDelete(form.id)
        } else if (!isVisit && !!ppee.id) {
            // Formulaire PDF sur téléphone et web
            navigation.push(`/ppee/:ppeeId/lireFormulaire/:formId`, {
                ppee: ppee,
                formId: form.id,
                ppeeId: ppee.id,
                formTitle: form.titre,
                pathParams: { ppeeId: ppee.id, formId: form.id },
            })
        }
    }
    const enclose = (formId: string) =>
        confirmAlert(
            i18n,
            I18N_SCREEN_KEY,
            'Enclose',
            () => encloseAction(formId),
            () => undefined,
            Theme,
        )

    // Clôture les fiches de visites ou les formulaires
    const encloseAction = async (formId: string) => {
        if (ppee.id && isVisit) {
            // suivi de visite
            const suivi = await api.ppee
                .getSuivi(ppee.id, formId)
                .then(suivi => {
                    return suivi
                })
                .catch(err => logger.error('Unable to get data of suivi de visite in order to enclose it', err))
            if (suivi) {
                api.ppee
                    .editSuivi(ppee.id, formId, {
                        ...suivi,
                        etat: 'cloture',
                    })
                    .then(() => {
                        basicAlert(
                            i18n,
                            I18N_SCREEN_KEY,
                            'Enclose',
                            () => {
                                onClose()
                                refreshPpee()
                            },
                            'success',
                            Theme,
                        )
                    })
                    .catch(err => logger.error('Unable to edit suivi de visit in order to enclose it', err))
            }
        } else if (ppee.id && !isVisit) {
            // formulaire
            const formulaire = await api.formulaire
                .getFormulaire(formId)
                .catch(err => logger.error('Unable to get data of form in order to enclose it', err))
            if (formulaire) {
                api.formulaire
                    .editForm(
                        {
                            ...formulaire,
                            etat: 'cloture',
                        },
                        formId,
                    )
                    .then(() =>
                        basicAlert(
                            i18n,
                            I18N_SCREEN_KEY,
                            'Enclose',
                            () => {
                                onClose()
                                refreshPpee()
                            },
                            'success',
                            Theme,
                        ),
                    )
                    .catch(err => logger.error('Unable to edit form in order to enclose it', err))
            }
        }
    }

    const renderFormulairesPpee = () => (
        <>
            {itemList.map((form, index) => (
                <RowContainer key={`ppeeFormulaire${index}`}>
                    <ItemTitle>{i18n.t('screens.ppeePdfScreen.listItem', { item: form.titre })}</ItemTitle>
                    {form.etat === 'cloture' && (
                        <ItemTitleEnclose>
                            {i18n.t(`screens.ppeePdfScreen.enclosed.${isVisit ? 'visit' : 'form'}`)}
                        </ItemTitleEnclose>
                    )}
                    <View>
                        {form.etat !== 'cloture' && (
                            <>
                                <TouchableItemFeedback
                                    onPress={() => {
                                        onClose()
                                        navigation.push(
                                            `/ppee/:ppeeId/${isVisit ? 'visitForm' : 'modifierFormulaire'}/:formId`,
                                            {
                                                ppee: ppee,
                                                formId: form.id,
                                                formTitle: form.titre,
                                                ppeeId: ppee.id,
                                                pathParams: { ppeeId: ppee.id, formId: form.id },
                                            },
                                        )
                                    }}
                                >
                                    <Action>{i18n.t('screens.ppeePdfScreen.edit')}</Action>
                                </TouchableItemFeedback>
                                <TouchableItemFeedback onPress={() => enclose(form.id)}>
                                    <Action>{i18n.t('screens.ppeePdfScreen.enclose')}</Action>
                                </TouchableItemFeedback>
                            </>
                        )}

                        {(form.etat === 'cloture' || !isVisit) && (
                            <TouchableItemFeedback onPress={() => lastItemOnPress(form)}>
                                <Action>
                                    {i18n.t(
                                        `screens.ppeePdfScreen.${
                                            form.etat === 'cloture'
                                                ? isVisit && Platform.OS === 'web'
                                                    ? 'pdf'
                                                    : 'view'
                                                : 'delete'
                                        }`,
                                    )}
                                </Action>
                            </TouchableItemFeedback>
                        )}
                    </View>
                </RowContainer>
            ))}
        </>
    )

    const renderNoItems = () => <NoItem>{i18n.t(`screens.ppeePdfScreen.noItem.${itemKey}`)}</NoItem>

    return itemList.length > 0 ? renderFormulairesPpee() : renderNoItems()
}
export default PpeePdfMenuItem

const RowContainer = styled(View)`
    flex-direction: row;
    margin-bottom: 10px;
`
const NoItem = styled(Text)`
    text-align: center;
    ${props => props.theme.fonts.ppeePdfItemLine}
`
const ItemTitle = styled(Text)`
    ${props => props.theme.fonts.ppeePdfItemLine}
    padding-left: 20px;
    padding-right: 20px;
    flex: 1;
`
const ItemTitleEnclose = styled(Text)`
    ${props => props.theme.fonts.ppeePdfItemLine}
    color:red;
    font-style: italic;
    padding-left: 20px;
    padding-right: 20px;
    flex: 1;
`
const Action = styled(Text)`
    ${props => props.theme.fonts.ppeeActionLink}
    text-align: right;
    text-decoration: underline;
    text-decoration-color: ${props => props.theme.colors.ppeeActionLink};
    :hover {
        ${props => props.theme.fonts.ppeeActionLinkHovered}
        text-decoration-color: ${props => props.theme.colors.ppeeActionLinkHovered};
    }
`
