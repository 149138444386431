import * as React from 'react'

// Components
import { Text, View, ScrollView } from 'react-native'
import Modal from '@components/modal/Modal'
import { TouchableItemFeedback } from '@components/touchable/Touchable'
import * as JsSearch from 'js-search'
import accentSanitizer from '@utils/accentSanitizer'
import SearchInput from '@components/searchInput/SearchInput'

// Store
import useI18n from '@store/i18n/useI18n'

// Style
import styled from '@styles/index'

const sanitizer = accentSanitizer()

interface Props {
    modalVisible: boolean
    onRequestClose: () => void
    onChange: (ppeeId?: string) => void
    ppeeList: SimpleIcp[]
}

export default (props: Props) => {
    const { modalVisible, onRequestClose, onChange, ppeeList } = props
    const i18n = useI18n()

    const [text, setText] = React.useState<string>('')

    const onPpeeSelected = (ppee: SimpleIcp) => {
        onChange(ppee.id)
        onRequestClose()
    }

    const renderICPState = (icpStatus: ICPStatus) => {
        return (
            <ICPStateTag icpStatus={icpStatus}>
                <ICPStateText numberOfLines={1} ellipsizeMode={'tail'} icpStatus={icpStatus}>
                    {i18n.t(`screens.icpList.ICPStates.${icpStatus}`)}
                </ICPStateText>
            </ICPStateTag>
        )
    }

    const renderIcpLine = (icp: SimpleIcp, index: number) => (
        <ICPLine odd={index % 2 === 1}>
            <IcpLineItem double>
                <ICPTitle numberOfLines={1} ellipsizeMode={'tail'}>
                    {icp.companiesName.length === 0
                        ? i18n.t(`screens.icpList.noCompany`)
                        : icp.companiesName.sort((a, b) => a.localeCompare(b)).join(', ')}
                </ICPTitle>
            </IcpLineItem>
            <IcpLineItem>
                <IcpLineItemText numberOfLines={1} ellipsizeMode={'tail'}>
                    {icp.icpDate
                        ? i18n.t(`screens.icpList.icpDate`, { date: new Date(icp.icpDate) })
                        : i18n.t(`screens.icpList.noIcpDate`)}
                </IcpLineItemText>
            </IcpLineItem>
            <IcpLineItem>
                <IcpLineItemText numberOfLines={1} ellipsizeMode={'tail'}>
                    {icp.operationDate
                        ? i18n.t(`screens.icpList.operationDate`, { date: new Date(icp.operationDate) })
                        : i18n.t(`screens.icpList.noOperationDate`)}
                </IcpLineItemText>
            </IcpLineItem>
            <IcpLineItem>
                <IcpLineItemText numberOfLines={1} ellipsizeMode={'tail'}>
                    {icp.redacteur}
                </IcpLineItemText>
            </IcpLineItem>
            <IcpLineItem double>
                <IcpLineItemText numberOfLines={2} ellipsizeMode={'tail'}>
                    {icp.description}
                </IcpLineItemText>
            </IcpLineItem>
            <IcpLineItem>{renderICPState(icp.status)}</IcpLineItem>
        </ICPLine>
    )

    // RECHERCHE DES ELEMENTS PAR TEXTE
    const searchJs = React.useMemo(() => {
        const searchjs = new JsSearch.Search('id')

        searchjs.searchIndex = new JsSearch.UnorderedSearchIndex()
        searchjs.indexStrategy = new JsSearch.AllSubstringsIndexStrategy()
        searchjs.tokenizer = {
            tokenize: (text: string) => text.split(' '),
        }
        searchjs.sanitizer = sanitizer
        searchjs.addIndex('companiesName')
        searchjs.addIndex('redacteur')
        searchjs.addIndex('approbateur')
        searchjs.addIndex('description')
        searchjs.addDocuments(ppeeList)

        return searchjs
    }, [ppeeList])

    const searchedIcpList = React.useMemo((): SimpleIcp[] => {
        const searchedList = (text.trim() ? searchJs.search(sanitizer.sanitize(text.trim())) : ppeeList) as SimpleIcp[]
        return searchedList
    }, [ppeeList, text, searchJs])

    return (
        <Modal visible={modalVisible} onRequestClose={onRequestClose}>
            <ModalContent>
                <SectionTitle>{i18n.t('screens.uploadPpee.selectPpee')}</SectionTitle>
                <SearchContainer>
                    <SearchInput
                        onTextChange={setText}
                        placeholder={i18n.t('screens.icpList.searchPlaceholder')}
                        resultsCount={searchedIcpList.length}
                    />
                </SearchContainer>
                <ScrollView>
                    <ListContainer>
                        {searchedIcpList.map((ppee, index) => {
                            return (
                                <TouchableItemFeedback key={`ppee${index}`} onPress={() => onPpeeSelected(ppee)}>
                                    {renderIcpLine(ppee, index)}
                                </TouchableItemFeedback>
                            )
                        })}
                    </ListContainer>
                </ScrollView>
            </ModalContent>
        </Modal>
    )
}

const ModalContent = styled(View)`
    background-color: ${props => props.theme.colors.documentsModalBackground};
    padding: 24px;
`
const ListContainer = styled(View)`
    background-color: white;
`
const ICPLine = styled(View)<{ odd: boolean; opened?: boolean }>`
    flex-direction: row;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
    ${props => !props.opened && `height: ${props.theme.constants.rightLineHeight}px;`}
    ${props => props.opened && `min-height: ${props.theme.constants.rightLineHeight}px;`}
    ${props => !props.odd && `background-color: ${props.theme.colors.evenLines};`}

    &:hover {
        background-color: ${props => props.theme.colors.hoverBackground};
        & > div {
            color: ${props => props.theme.colors.hoverText};
            & > div {
                color: ${props => props.theme.colors.hoverText};
                & > div {
                    color: ${props => props.theme.colors.hoverText};
                }
            }
        }
    }
`
const ICPTitle = styled(Text)`
    flex: 1;
    ${props => props.theme.fonts.rightTitle}
`
const IcpLineItem = styled(View)<{ double?: boolean }>`
    flex: ${props => (props.double ? 2 : 1)};
`
const IcpLineItemText = styled(Text)`
    ${props => props.theme.fonts.icpLineItemText};
`
const ICPStateTag = styled(View)<{ icpStatus: ICPStatus }>`
    background-color: ${props =>
        props.icpStatus === 'aPlanifier'
            ? props.theme.colors.icpAPlanifier
            : props.icpStatus === 'aRealiser'
            ? props.theme.colors.icpARealiser
            : props.icpStatus === 'aFinaliser'
            ? props.theme.colors.icpAFinaliser
            : props.icpStatus === 'aApprouver'
            ? props.theme.colors.icpAApprouver
            : props.icpStatus === 'actif'
            ? props.theme.colors.icpActif
            : props.icpStatus === 'aEcheance'
            ? props.theme.colors.icpAEcheance
            : props.theme.colors.icpEchu};
    border-radius: 40px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
`
const ICPStateText = styled(Text)<{ icpStatus: ICPStatus }>`
    ${props =>
        props.icpStatus === 'aFinaliser' || props.icpStatus === 'aApprouver' || props.icpStatus === 'actif'
            ? props.theme.fonts.icpLightStateText
            : props.theme.fonts.icpDarkStateText};
`
const SectionTitle = styled(Text)`
    ${props => props.theme.fonts.icpFormTitle}
    font-family: Avenir-Book;
    margin-bottom: 20px;
`
const SearchContainer = styled(View)`
    margin-bottom: 24px;
`
