import * as React from 'react'

import useTheme from './useTheme'
import { ThemeProvider } from './index'

interface Props {
    children: any
}

const Provider = ({ children }: Props) => {
    const [Theme] = useTheme()

    return <ThemeProvider theme={Theme}>{children}</ThemeProvider>
}
export default Provider
