import * as React from 'react'
import { Image, ScrollView, Text, View } from 'react-native'
import { ChromePicker } from 'react-color'

import styled from '@styles/index'

import RootScreen from '@components/rootScreen/RootScreen'
import Button from '@components/button/Button'
import ImageSelect from '@components/imageDropInput/ImageSelect.web'
import TextInput from '@components/textInput/TextInput'
import ImagesModal from '@components/imagesModal/ImagesModal'
import { TouchableItemFeedback } from '@components/touchable/Touchable'
import { basicAlert } from '@components/alert/Alerts'

import useI18n from '@store/i18n/useI18n'
import * as CustomInterfaceStore from '@store/customInterfaceStore'

import api from '@api/api'

import Logger from '@utils/logger'
import useTheme from '@styles/useTheme'

const I18N_SCREEN_KEY = 'screens.customInterface'

const initialConfValue: Configuration = {
    color: '#BF0000',
    logo: '',
    pdfLogo: '',
    name: '',
    prefix: '',
}

type Action = { type: string; payload: { key: string; value: any } }

type DispatchAction =
    | { type: 'globalSet'; payload: { key: ''; value: Configuration } }
    | { type: 'update'; payload: { key: string; value: string } }

const InterfaceWeb = () => {
    const i18n = useI18n()
    const [Theme] = useTheme()

    const [status, setStatus] = React.useState<Status>('loading')
    const [buttonStatus, setButtonStatus] = React.useState<ButtonStatus>('active')
    const [bigSizeImage, setBigSizeImage] = React.useState('')

    const reducer = (state: Configuration, action: DispatchAction): Configuration => {
        switch (action.type) {
            case 'globalSet':
                return { ...action.payload.value }
            case 'update':
                return { ...state, [action.payload.key]: action.payload.value }
            default:
                throw new Error(`Unknown action type`)
        }
    }

    const [currentConf, dispatchCurrentConf] = React.useReducer(reducer, initialConfValue)
    const [newConf, dispatchNewConf] = React.useReducer(reducer, initialConfValue)

    React.useEffect(() => {
        api.custom
            .getInterface()
            .then(res => {
                dispatchCurrentConf({ type: 'globalSet', payload: { key: '', value: res } })
                dispatchNewConf({ type: 'globalSet', payload: { key: '', value: res } })

                setStatus('fetched')
            })
            .catch(err => {
                Logger.error(err)
                setStatus('error')
            })
    }, [])

    const AlertAfterSubmit = (type: 'success' | 'error') =>
        basicAlert(i18n, I18N_SCREEN_KEY, `EditInterface`, () => setButtonStatus('active'), type, Theme)

    const saveConf = () => {
        api.custom
            .editInterface(newConf)
            .then(() => {
                AlertAfterSubmit('success')
                dispatchCurrentConf({ type: 'globalSet', payload: { key: '', value: newConf } })
                CustomInterfaceStore.actions.setMainColor(newConf.color)
                CustomInterfaceStore.actions.setMenuLogo(newConf.logo)
                CustomInterfaceStore.actions.setName(newConf.name)
            })
            .catch(() => AlertAfterSubmit('error'))
    }

    return (
        <RootScreen status={status}>
            <MainContainer>
                <ImagesModal
                    visible={bigSizeImage.length > 0}
                    onRequestClose={() => setBigSizeImage('')}
                    initImage={0}
                    images={[bigSizeImage]}
                />
                <ListTitleContainer>
                    <ListTitle numberOfLines={2}>{i18n.t('screens.customInterface.colorTitle')}</ListTitle>
                    <Button libelle={i18n.t('common.save')} onPress={saveConf} status={buttonStatus} />
                </ListTitleContainer>
                <ColorPickerView>
                    <ChromePicker
                        disableAlpha={true}
                        color={newConf.color}
                        onChange={color =>
                            dispatchNewConf({ type: 'update', payload: { key: 'color', value: color.hex } })
                        }
                    />
                    <SelectedColorView color={newConf.color} />
                </ColorPickerView>
                <ListTitleContainer>
                    <ListTitle numberOfLines={2}>{i18n.t('screens.customInterface.logoTitle')}</ListTitle>
                </ListTitleContainer>
                <LogoPickerContainer>
                    <LogoView>
                        <TouchableItemFeedback
                            onPress={() => {
                                setBigSizeImage(currentConf.logo)
                            }}
                        >
                            <LogoContainer>
                                <Logo resizeMode="contain" source={{ uri: currentConf.logo }} />
                            </LogoContainer>
                        </TouchableItemFeedback>
                    </LogoView>
                    <ImageSelectView>
                        <ImageSelect
                            value={newConf.logo}
                            onChange={image => {
                                if (image !== '') {
                                    dispatchNewConf({ type: 'update', payload: { key: 'logo', value: image } })
                                } else {
                                    // quand l'utilisateur met une image puis la supprime avant de sauvegarder, remet l'ancienne image
                                    dispatchNewConf({
                                        type: 'update',
                                        payload: { key: 'logo', value: currentConf.logo },
                                    })
                                }
                            }}
                            isPdf={false}
                            isNew={currentConf.logo !== newConf.logo}
                        />
                        <AdjustmentView size={10} />
                    </ImageSelectView>
                </LogoPickerContainer>
                <ListTitleContainer>
                    <ListTitle numberOfLines={2}>{i18n.t('screens.customInterface.pdfLogoTitle')}</ListTitle>
                </ListTitleContainer>
                <LogoPickerContainer>
                    <LogoView>
                        <TouchableItemFeedback
                            onPress={() => {
                                setBigSizeImage(currentConf.pdfLogo)
                            }}
                        >
                            <LogoContainer>
                                <Logo resizeMode="contain" source={{ uri: currentConf.pdfLogo }} />
                            </LogoContainer>
                        </TouchableItemFeedback>
                        <SuggestedSize>{i18n.t('screens.customInterface.logoSizeSuggestion')}</SuggestedSize>
                    </LogoView>
                    <ImageSelectView>
                        <ImageSelect
                            value={newConf.pdfLogo}
                            onChange={image => {
                                if (image !== '') {
                                    dispatchNewConf({ type: 'update', payload: { key: 'pdfLogo', value: image } })
                                } else {
                                    // quand l'utilisateur met une image puis la supprime avant de sauvegarder, remet l'ancienne image
                                    dispatchNewConf({
                                        type: 'update',
                                        payload: { key: 'pdfLogo', value: currentConf.pdfLogo },
                                    })
                                }
                            }}
                            isPdf={false}
                            isNew={currentConf.pdfLogo !== newConf.pdfLogo}
                        />

                        <AdjustmentView size={25} />
                    </ImageSelectView>
                </LogoPickerContainer>
                <ListTitleContainer>
                    <ListTitle numberOfLines={2}>{i18n.t('screens.customInterface.instanceTitle')}</ListTitle>
                </ListTitleContainer>
                <BorderView>
                    <TextInput
                        value={newConf.name}
                        onTextChange={text =>
                            dispatchNewConf({ type: 'update', payload: { key: 'name', value: text } })
                        }
                        placeholder={i18n.t('screens.customInterface.instancePlaceholder')}
                    />
                </BorderView>
                <ListTitleContainer>
                    <ListTitle numberOfLines={2}>{i18n.t('screens.customInterface.referenceTitle')}</ListTitle>
                </ListTitleContainer>
                <BorderView>
                    <TextInput
                        value={newConf.prefix}
                        onTextChange={text =>
                            dispatchNewConf({ type: 'update', payload: { key: 'prefix', value: text } })
                        }
                        placeholder={i18n.t('screens.customInterface.referencePlaceholder')}
                    />
                </BorderView>
            </MainContainer>
        </RootScreen>
    )
}
export default InterfaceWeb

const MainContainer = styled(ScrollView)`
    flex: 1;
    background-color: ${props => props.theme.colors.rolesBackground};
`
const ListTitleContainer = styled(View)`
    flex-direction: row;
    height: ${props => props.theme.constants.riskFamiliesListTitleContainerHeight}px;
    justify-content: space-between;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;

    border-bottom-color: ${props => props.theme.colors.homeListBorderColor};
    border-bottom-width: 2px;
`
const ListTitle = styled(Text)`
    ${props => props.theme.fonts.homeListTitle}
    text-transform: uppercase;
`
const ColorPickerView = styled(View)`
    flex-direction: row;
    align-items: center;
    margin: 30px;
`
const SelectedColorView = styled(View)<{ color: string }>`
    background-color: ${props => props.color};

    margin-left: 30px;
    width: 150px;
    height: 150px;
    border-radius: 75px;
    border: 1px solid black;
`
const LogoView = styled(View)`
    margin-top: ${props => props.theme.constants.webMenuLogoVerticalPadding}px;
    margin-bottom: ${props => props.theme.constants.webMenuLogoVerticalPadding}px;
    margin-left: ${props => props.theme.constants.webMenuLogoHorizontalPadding}px;
    margin-right: ${props => props.theme.constants.webMenuLogoHorizontalPadding}px;
`
const LogoContainer = styled(View)`
    background-color: ${props => props.theme.colors.logoBackground};
    height: ${props => props.theme.constants.formImageInputPictureSize};
    width: ${props => props.theme.constants.webMenuWidth - 2 * props.theme.constants.webMenuLogoHorizontalPadding}px;
    padding: 10px;
    margin-bottom: 5px;
    align-items: center;
    justify-content: center;
`
const Logo = styled(Image)`
    height: 100%;
    width: 100%;
    align-self: center;
`
const LogoPickerContainer = styled(View)`
    flex-direction: row;
    align-items: center;
`
const BorderView = styled(View)`
    background-color: ${props => props.theme.colors.menuBackground};
    margin: 10px 100px 10px 30px;
`
const SuggestedSize = styled(Text)`
    ${props => props.theme.fonts.sizeSuggestion};
`
const ImageSelectView = styled(View)``
const AdjustmentView = styled(View)<{ size: number }>`
    height: ${props => props.size}px;
`
