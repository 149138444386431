import React from 'react'

// Components
import { View, Image } from 'react-native'
import { TouchableItemFeedback } from '@components/touchable/Touchable'
import Icon from '@components/icon/Icon'
import SignatureCapture from 'react-signature-pad'
import Button from '@components/button/Button'
import Modal from '@components/modal/Modal'
import ImagesModal from '@components/imagesModal/ImagesModal'
import FieldInformation from '@components/fieldInformation/FieldInformation'

// Store
import useI18n from '@store/i18n/useI18n'

// Style
import styled from '@styles/index'
import 'react-signature-pad/style.css'
import useTheme from '@styles/useTheme'

export default (props: SignaturePadProps) => {
    const { onChange, value, error, customButton, withModal, label, disabled } = props
    const i18n = useI18n()
    const [Theme] = useTheme()

    const [padOpened, setPadOpened] = React.useState<boolean>(false)
    const [imagesOpened, setImagesOpened] = React.useState<string[]>([])

    let signature: any = null

    const _onSaveEvent = (result: { encoded: string }) => {
        onChange(result.encoded)
        setPadOpened(false)
    }

    const save = () => {
        if (signature) {
            _onSaveEvent({ encoded: signature.toDataURL() })
        }
    }

    const resetSign = () => {
        if (signature) {
            signature.clear()
        }
    }

    const renderSignaturePad = () => (
        <View>
            <SignatureContainer>
                <SignatureCapture ref={(r: any) => (signature = r)} onSaveEvent={_onSaveEvent} />
            </SignatureContainer>
            <OptionBar>
                <LeftButtonContainer>
                    <Button
                        libelle={i18n.t('components.signaturePad.buttons.cancel')}
                        onPress={() => setPadOpened(false)}
                        status={'active'}
                    />
                </LeftButtonContainer>
                <LeftButtonContainer>
                    <Button
                        libelle={i18n.t('components.signaturePad.buttons.clear')}
                        onPress={resetSign}
                        status={'active'}
                    />
                </LeftButtonContainer>
                <Button libelle={i18n.t('components.signaturePad.buttons.save')} onPress={save} status={'active'} />
            </OptionBar>
        </View>
    )

    return (
        <View>
            {withModal && (
                <Modal visible={padOpened} onRequestClose={() => setPadOpened(false)} noMinHeight>
                    <SignaturePadContainer>{renderSignaturePad()}</SignaturePadContainer>
                </Modal>
            )}

            <ImagesModal
                visible={imagesOpened.length > 0}
                onRequestClose={() => setImagesOpened([])}
                initImage={0}
                images={imagesOpened}
            />

            {(!!error || !!label) && <FieldInformation label={label} error={error} />}
            {!padOpened &&
                (!!customButton ? (
                    <TouchableItemFeedback onPress={() => setPadOpened(true)}>{customButton}</TouchableItemFeedback>
                ) : (
                    <RowContainer>
                        {!!value && (
                            <TouchableItemFeedback onPress={() => setImagesOpened([value])}>
                                <ImageContainer>
                                    <ImageValue source={{ uri: value }} />
                                    {!disabled && (
                                        <ClearButton>
                                            <TouchableItemFeedback onPress={() => onChange('')}>
                                                <Icon
                                                    name={'cross'}
                                                    size={Theme.constants.imageInputClearIconSize}
                                                    color={Theme.colors.clearIcon}
                                                />
                                            </TouchableItemFeedback>
                                        </ClearButton>
                                    )}
                                </ImageContainer>
                            </TouchableItemFeedback>
                        )}

                        <View>
                            {!disabled && (
                                <TouchableItemFeedback onPress={() => setPadOpened(true)}>
                                    <UploadButton error={!!error}>
                                        <Icon name={'edit'} size={40} color={Theme.colors.uploadIconColor} />
                                    </UploadButton>
                                </TouchableItemFeedback>
                            )}
                        </View>
                    </RowContainer>
                ))}

            {padOpened && !withModal && renderSignaturePad()}
        </View>
    )
}
const SignaturePadContainer = styled(View)`
    align-items: center;
`
const RowContainer = styled(View)`
    flex-direction: row;
`
const ImageContainer = styled(View)`
    justify-content: center;
    align-items: center;
    height: 120px;
    width: 240px;
    margin-right: 24px;
    background-color: ${props => props.theme.colors.signaturePadBackground};
`
const ImageValue = styled(Image)`
    height: 120px;
    width: 240px;
`
const ClearButton = styled(View)`
    position: absolute;
    top: 10px;
    right: 10px;
`
const SignatureContainer = styled(View)`
    width: 300px;
    height: 420px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: auto;
`
const OptionBar = styled(View)`
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
`
const UploadButton = styled(View)<{ error: boolean }>`
    background-color: ${props => props.theme.colors.imageDropInputBackground};
    ${props => props.error && 'border: ' + props.theme.colors.textInputBorderError + ' solid 1px;'}
    justify-content: center;
    align-items: center;
    height: ${props => props.theme.constants.formImageInputPictureSize};
    width: ${props => props.theme.constants.formImageInputPictureSize};
`
const LeftButtonContainer = styled(View)`
    margin-right: 24px;
`
