import * as React from 'react'

// Components
import { View, Text, NativeSyntheticEvent, NativeScrollEvent, ScrollView, Dimensions } from 'react-native'
import PlanICP from '@components/planIcp/PlanICPRead'
import InfosICP from '@components/infosIcp/ReadInfosICP'
import ActorsICP from '@components/actorsIcp/ReadActorsICP'
import DocumentsICP from '@components/documentsIcp/ReadDocumentsICP'
import ProceduresICP from '@components/proceduresIcp/ReadProceduresICP'
import SiteICP from '@components/siteIcp/ReadSiteICP'
import FamilyRiskIcpWeb from '@components/familyRiskIcp/ReadFamilyRiskIcp.web'
import Icon from '@components/icon/Icon'

// Store
import useI18n from '@store/i18n/useI18n'

// Style
import styled from '@styles/index'
import useTheme from '@styles/useTheme'

const STATE_BAR_TOP_BORDER = 5
const SECTION_MIN_WIDTH = 100
const SEPARATOR_MARGIN = 20

const KEY_SECTIONS: SectionName[] = [
    'infosIcp',
    'planIcp',
    'actorsIcp',
    'documentsIcp',
    'proceduresIcp',
    'siteIcp',
    'risksIcp',
]

export default (props: ReadIcpFormContainerProps) => {
    const { values, sectionsFilled, bubbleKeys, perimeters } = props
    const i18n = useI18n()
    const [Theme] = useTheme()

    const [windowWidth, setWindowWidth] = React.useState<number>(window.innerWidth)

    const [scrollPosition, setScrollPosition] = React.useState<number>(0)
    const [focusedSection, setFocusedSection] = React.useState<SectionName>('infosIcp')

    const ScrollRef = React.useRef<ScrollView | null>(null)

    React.useLayoutEffect(() => {
        const updateWidth = () => setWindowWidth(window.innerWidth)
        window.addEventListener('resize', updateWidth)
        return () => window.removeEventListener('resize', updateWidth)
    }, [setWindowWidth])

    const onScroll = (evt: NativeSyntheticEvent<NativeScrollEvent>) => {
        setScrollPosition(evt.nativeEvent.contentOffset.y)
        const focused = [...KEY_SECTIONS].reverse().find(section => {
            const element = document.getElementById(section)
            return (element && element.getBoundingClientRect().top <= 141) || false
        })
        if (focused) {
            setFocusedSection(focused)
        }
    }

    const scrollTo = (key: SectionName) => {
        if (ScrollRef.current) {
            const element = document.getElementById(key)
            const yPosition =
                (element &&
                    element.getBoundingClientRect().top + scrollPosition - Theme.constants.webHeaderHeight - 50) ||
                0
            ScrollRef.current.scrollTo({ x: 0, y: yPosition, animated: true })
        }
    }

    const renderSection = (key: SectionName, filled: boolean, isFirst: boolean, isLast: boolean) => (
        <FlexSection key={key}>
            {!isFirst && <ProgressLine isLeft={true} />}
            {!isLast && <ProgressLine isLeft={false} />}
            <ScrollLink onClick={() => scrollTo(key)}>
                <Section key={`sectionBubble${key}`}>
                    <Bubble filled={filled || focusedSection === key}>
                        {filled && <Icon name={'checkmark'} size={15} color={Theme.colors.icpStateBarCheckedIcon} />}
                    </Bubble>
                    <SectionName filled={filled} numberOfLines={2} ellipsizeMode={'tail'}>
                        {i18n.t(`components.${key}.title`)}
                    </SectionName>
                </Section>
            </ScrollLink>
        </FlexSection>
    )

    return (
        <View>
            <StateBar width={windowWidth}>
                <ProgressBar>
                    {bubbleKeys.map((key, index) =>
                        renderSection(
                            key,
                            sectionsFilled.indexOf(key) > -1,
                            index === 0,
                            index === bubbleKeys.length - 1,
                        ),
                    )}
                </ProgressBar>
            </StateBar>

            <FormContainer ref={ScrollRef} onScroll={onScroll}>
                <ScrollToDiv id={'planIcp'} />
                <PlanICP values={values.planification} perimeters={perimeters} />
                <Separator />
                <ScrollToDiv id={'infosIcp'} />
                <InfosICP values={values.informations} />
                <Separator />
                <ScrollToDiv id={'actorsIcp'} />
                <ActorsICP values={values.actors} />
                <Separator />
                <ScrollToDiv id={'documentsIcp'} />
                <DocumentsICP values={values.documents} />
                <Separator />
                <ScrollToDiv id={'proceduresIcp'} />
                <ProceduresICP values={values.procedures} />
                <Separator />
                <ScrollToDiv id={'siteIcp'} />
                <SiteICP values={values.site} />
                <Separator />
                <ScrollToDiv id={'risksIcp'} />
                <FamilyRiskIcpWeb
                    values={
                        values.familyRisk || {
                            risksAndMeasures: [],
                        }
                    }
                />
            </FormContainer>
        </View>
    )
}

const StateBar = styled(View)<{ width: number }>`
    position: fixed;
    flex-direction: row;
    align-items: center;

    height: 100px;
    width: ${props => props.width - props.theme.constants.webMenuWidth}px;
    top: ${props => props.theme.constants.webHeaderHeight}px;
    left: ${props => props.theme.constants.webMenuWidth}px;

    padding-top: 12px;
    padding-bottom: 12px;
    padding-right: 24px;
    padding-left: 24px;

    background-color: ${props => props.theme.colors.icpStateBarBackground};
    border-top: ${STATE_BAR_TOP_BORDER}px solid ${props => props.theme.colors.contentBackground};
    z-index: 1;
`
const Section = styled(View)`
    align-items: center;
`
const Bubble = styled(View)<{ filled: boolean }>`
    cursor: pointer;

    height: 25px;
    width: 25px;
    border-radius: 24px;
    background-color: ${props =>
        props.filled ? props.theme.colors.icpStateBarBubbleFilled : props.theme.colors.icpStateBarBubbleEmpty};
    ${props => !props.filled && 'border: 2px solid black;'}
    margin-bottom: 10px;
    justify-content: center;
    align-items: center;
`

const SectionName = styled(Text)<{ filled: boolean }>`
    text-align: center;
    ${props => props.theme.fonts.editIcpStateBar}
    ${props => props.filled && 'font-family: Avenir-Heavy;'}
    cursor: pointer;
`

const Separator = styled(View)`
    background-color: ${props => props.theme.colors.accountSeparator};
    border-radius: 50px;
    margin: ${SEPARATOR_MARGIN}px;
    height: 5px;
`
const FormContainer = styled(ScrollView)`
    height: ${props =>
        Dimensions.get('window').height -
        props.theme.constants.webHeaderHeight -
        (props.theme.constants.webHeaderHeight + STATE_BAR_TOP_BORDER + 50)}px;
    margin-top: ${props => props.theme.constants.webHeaderHeight + STATE_BAR_TOP_BORDER}px;
`
const ProgressBar = styled(View)`
    flex: 1;
    flex-direction: row;
    margin-right: 24px;
`
const FlexSection = styled(View)`
    flex: 1;
    min-width: ${SECTION_MIN_WIDTH}px;
`
const ProgressLine = styled(View)<{ isLeft: boolean }>`
    height: 5px;
    width: 50%;
    background-color: ${props => props.theme.colors.icpStateBarProgressLine};
    z-index: -100;
    position: absolute;
    top: 10px;
    ${props => (props.isLeft ? 'left' : 'right')}: 0px;
`
const ScrollToDiv = styled.div`
    position: relative;
    top: ${props => -props.theme.constants.webHeaderHeight - STATE_BAR_TOP_BORDER - SEPARATOR_MARGIN}px;
`
const ScrollLink = styled.a`
    text-decoration: none;
`
