import * as React from 'react'

// Components
import { View, Text } from 'react-native'

// Store
import useI18n from '@store/i18n/useI18n'

// Style
import styled from '@styles/index'

export default (props: IcpFormContainerProps) => {
    const { title, children, hasErrors } = props
    const i18n = useI18n()

    return (
        <Container>
            <LeftColumn>
                <SectionTitle>{title}</SectionTitle>
                {hasErrors && <Error>{i18n.t('components.icpFormContainer.error')}</Error>}
            </LeftColumn>
            <RightColumn>{children}</RightColumn>
        </Container>
    )
}

const Container = styled(View)`
    flex-direction: row;
`
const SectionTitle = styled(Text)`
    ${props => props.theme.fonts.icpFormTitle}
`
const LeftColumn = styled(View)`
    width: 250px;
    padding-right: 24px;
`
const RightColumn = styled(View)`
    flex: 1;
`
const Error = styled(Text)`
    ${props => props.theme.fonts.icpFormError}
`
