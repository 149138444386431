import React from 'react'

// Components
import Select, { InputActionMeta, Styles } from 'react-select'
import { View } from 'react-native'
import FieldInformation from '@components/fieldInformation/FieldInformation'

import useI18n from '@store/i18n/useI18n'

// Style
import styled from '@styles/index'
import Theme from '@styles/theme'
import useTheme from '@styles/useTheme'

interface PickerOption {
    label: string
    value: number
}

export default (props: PickerProps) => {
    const {
        disabled,
        value,
        data,
        isClearable,
        isSearchable = true,
        required,
        label,
        placeholder,
        error,
        onChange,
        customInputBackgroundColor,
        notSorted,
        debounce,
        onTextChange,
    } = props

    const i18n = useI18n()
    const [Theme] = useTheme()

    const [input, setInput] = React.useState('')

    const updateInput = (inputValue: string, action: InputActionMeta) =>
        action.action !== 'input-blur' && action.action !== 'menu-close' && setInput(inputValue)

    React.useEffect(() => {
        if (debounce && !!onTextChange) {
            const delayedTextChange = setTimeout(() => onTextChange(input), 1000)
            return () => clearTimeout(delayedTextChange)
        }
    }, [input, debounce, onTextChange])

    const options = data
        .reduce((acc, cur, idx) => {
            acc.push({ label: cur, value: idx })
            return acc
        }, [] as PickerOption[])
        .sort((a, b) => (notSorted ? 1 : a.label.localeCompare(b.label)))

    return (
        <Container>
            <FieldInformation label={label} required={required} error={error} />
            <Select
                styles={customStyles(Theme, error, customInputBackgroundColor)}
                key={value}
                placeholder={placeholder || i18n.t('components.picker.defaultPlaceholder')}
                isSearchable={isSearchable}
                isClearable={isClearable}
                value={options.find((option: any) => option.label === value)}
                onChange={(option: any) => {
                    option ? onChange(option.value) : onChange(-1)
                }} // any car sinon problème de type
                inputValue={input}
                onInputChange={updateInput}
                options={options}
                isDisabled={disabled}
                error={error}
                noOptionsMessage={() => i18n.t('components.picker.noOption')}
            />
        </Container>
    )
}

const customStyles: (Theme: Theme, error?: string, customColor?: string) => Partial<Styles> = (
    Theme: Theme,
    error,
    customColor,
) => ({
    control: (styles, state) => ({
        ...styles,
        backgroundColor: state.isDisabled
            ? Theme.colors.textInputBackgroundDisabled
            : customColor || Theme.colors.textInputBackground,

        borderWidth: !!error ? 1 : 0,
        borderStyle: !!error ? 'solid' : 'none',
        borderColor: !!error ? Theme.colors.fieldError : Theme.colors.defaultBorder,
        height: Theme.constants.textInputHeight,
        borderRadius: Theme.constants.inputBorderRadius,
        // paddingTop: 8,
        // paddingBottom: 8,
        paddingLeft: Theme.constants.fieldPadding,
        paddingRight: Theme.constants.fieldPadding,
        '&:hover': {
            borderWidth: !!error ? 1 : 0,
        },
        boxShadow: 'none',
    }),
    placeholder: styles => ({ ...styles, ...Theme.fonts.inputText, color: Theme.colors.fieldPlaceholder, size: 10 }),
    input: styles => ({ ...styles, ...Theme.fonts.inputText, size: 10 }),
    singleValue: styles => ({ ...styles, ...Theme.fonts.inputText, size: 10 }),
    valueContainer: styles => ({ ...styles, padding: 0, size: 10 }),
    option: (styles, state) => ({
        ...styles,
        ...Theme.fonts.inputText,
        backgroundColor: state.isFocused ? Theme.colors.fieldFocused : Theme.colors.fieldBackground,
        color: state.isSelected ? Theme.colors.fieldSelected : Theme.colors.fieldLabel,
        fontWeight: state.isSelected ? 'bold' : 'normal',
        paddingTop: 8,
        paddingBottom: 8,
        paddingLeft: Theme.constants.fieldPadding,
        paddingRight: Theme.constants.fieldPadding,
        ':active': {
            backgroundColor: Theme.colors.fieldActive,
        },
        size: 10,
    }),
    clearIndicator: styles => ({ ...styles, color: Theme.colors.clearIcon, size: 10 }),
    dropdownIndicator: styles => ({ ...styles, paddingRight: 0, size: 10 }),
    noOptionsMessage: styles => ({ ...styles, ...Theme.fonts.inputText, opacity: 0.7, size: 10 }),
})

const Container = styled(View)`
    flex-direction: column;
`
