import { MsalAuthProvider, LoginType } from 'react-aad-msal'

const createProvider = (config: AuthConfig, type?: 'popup' | 'redirect') => {
    const scopes = [config.serverAPI] // , ...config.additionalScopes] // Tester le assureToken de graph plus tard

    resources = {
        server: config.serverAPI,
        graph: '',
    }

    provider = new MsalAuthProvider(
        {
            auth: {
                authority: `https://login.microsoftonline.com/${config.tenant}`,
                clientId: config.clientId,
                redirectUri: config.redirectUri,
            },
            cache: {
                cacheLocation: 'localStorage',
                storeAuthStateInCookie: false,
            },
        },
        {
            scopes,
            forceRefresh: true,
        },
        type === 'popup' ? LoginType.Popup : LoginType.Redirect,
    )

    return provider
}

let provider: MsalAuthProvider
let logoutCallback: () => void
let resources: { [res: string]: string }

const MSAL = {
    login: (): Promise<Credentials> => {
        return provider.login().then(() => {
            return new Promise((res, rej) => {
                setTimeout(
                    () =>
                        MSAL.assureToken('server')
                            .then(res)
                            .catch(rej),
                    2000,
                )
            })
        })
    },
    assureToken: (resource: 'server' | 'graph'): Promise<Credentials> => {
        return provider
            .getAccessToken({ scopes: [resources[resource]] })
            .then(result => {
                return {
                    accessToken: result.accessToken,
                    scopes: result.scopes,
                    expireOn: result.expiresOn,
                    resource: resource,
                }
            })
            .catch(err => {
                if (err.errorCode === 'user_login_error') {
                    if (logoutCallback) {
                        logoutCallback()
                    }
                }
                throw err
            })
    },
    logout: (): void => {
        provider.logout()
        if (logoutCallback) {
            logoutCallback()
        }
    },
    setLogoutCallback: (cb: () => void) => {
        logoutCallback = cb
    },
}

export { createProvider }
export default MSAL
