import * as React from 'react'
import { Text, View } from 'react-native'
import styled from '@styles/index'
import Button from '@components/button/Button'
import useI18n from '@store/i18n/useI18n'
import useNavigation from '@layout/useNavigation'
import { TouchableItemFeedback } from '@components/touchable/Touchable'
import { canUserAddICP } from '@utils/userRight'

import useReducer from '@store/useReducer'
import * as userStore from '@store/user'

interface OwnProps {
    icpToCome: IcpHome[]
}

export default ({ icpToCome }: OwnProps) => {
    const i18n = useI18n()
    const navigation = useNavigation()
    const user = useReducer(userStore.store, s => s)

    return (
        <HomeListContainer>
            <ListTitleContainer>
                <ListTitle>{i18n.t('screens.home.myIcpToCome')}</ListTitle>
            </ListTitleContainer>
            <ListContent>
                <ListItem odd title>
                    <ListColumnTitle>{i18n.t('screens.home.nameTitle')}</ListColumnTitle>
                    <ListColumnTitle>{i18n.t('screens.home.dateTitle')}</ListColumnTitle>
                </ListItem>
                {icpToCome.length === 0 ? (
                    <NoIcpMessage>{i18n.t('screens.home.noIcp')}</NoIcpMessage>
                ) : (
                    icpToCome.map((icp, index) => (
                        <TouchableItemFeedback
                            onPress={() =>
                                navigation.push('/icp/:icpId', {
                                    icpId: icp.id,
                                    pathParams: { icpId: icp.id },
                                })
                            }
                            key={index}
                        >
                            <ListItem key={index} odd={index % 2 === 1}>
                                <Libelle numberOfLines={2} ellipsizeMode={'tail'}>
                                    {icp.libelle.trim() === '' ? i18n.t('screens.home.noCompany') : icp.libelle}
                                </Libelle>
                                <DateText>{i18n.t('screens.home.IcpDate', { date: new Date(icp.date) })}</DateText>
                                <Action>
                                    {user && canUserAddICP(user)
                                        ? i18n.t('screens.home.startIcp')
                                        : i18n.t('screens.home.readIcp')}
                                </Action>
                            </ListItem>
                        </TouchableItemFeedback>
                    ))
                )}
            </ListContent>
            {icpToCome.length > 0 && (
                <ButtonContainer>
                    <Button
                        libelle={i18n.t('screens.home.seeAll')}
                        onPress={() => navigation.push('/icpAVenir')}
                        status="active"
                    />
                </ButtonContainer>
            )}
        </HomeListContainer>
    )
}
const ButtonContainer = styled(View)`
    align-items: center;
    justify-content: center;
    height: 50px;
`
const Libelle = styled(Text)`
    flex: 6;
    ${props => props.theme.fonts.homeListLineContent}
    font-family: Avenir-Heavy;
`
const ListColumnTitle = styled(Text)`
    flex: 1;
    ${props => props.theme.fonts.homeListLineContent}
`
const DateText = styled(Text)`
    flex: 5;
    ${props => props.theme.fonts.homeListLineContent}
`
const Action = styled(Text)`
    ${props => props.theme.fonts.homeActionLink}
    flex: 1;
    text-decoration: underline;
`
const ListContent = styled(View)`
    padding: 15px 30px;
`
const ListItem = styled(View)<{ odd: boolean; title?: boolean }>`
    ${props =>
        !props.title &&
        `&:hover {
        background-color: ${props.theme.colors.hoverBackground};
        & > div {
            color: ${props.theme.colors.hoverText};
            & > div {
                color: ${props.theme.colors.hoverText};
                & > div {
                    color: ${props.theme.colors.hoverText};
                }
            }
        }
    }`}
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: ${props => props.theme.constants.homeListLineHeight}px;
    background-color: ${props => !props.odd && props.theme.colors.homeEvenLines};

    padding-left: 10px;
    padding-right: 10px;
`
const HomeListContainer = styled(View)`
    background-color: ${props => props.theme.colors.homeListBackground};
    border-radius: 10px;
    box-shadow: 0px 0px 3px ${props => props.theme.colors.homeIndicatorBowShadowColor};
`
const ListTitleContainer = styled(View)`
    flex-direction: row;
    height: ${props => props.theme.constants.homeListTitleContainerHeight}px;
    justify-content: space-between;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;

    border-bottom-color: ${props => props.theme.colors.homeListBorderColor};
    border-bottom-width: 2px;
`
const ListTitle = styled(Text)`
    ${props => props.theme.fonts.homeListTitle}
`
const NoIcpMessage = styled(Text)`
    ${props => props.theme.fonts.perimeterName}
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
`
