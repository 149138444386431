import * as React from 'react'
import { Text, View, ImageBackground, ScrollView } from 'react-native'
import styled from '@styles/index'
import Button from '@components/button/Button'
import useI18n from '@store/i18n/useI18n'
import Logger from '@utils/logger'
import { useLocation } from 'react-router'
import { basicAlert } from '@components/alert/Alerts'
import qs from 'qs'
import { apiPublic } from '@utils/approbationUtils'
import useTheme from '@styles/useTheme'

export default () => {
    const i18n = useI18n()
    const [Theme] = useTheme()

    const [windowHeight, setWindowHeight] = React.useState<number>(window.innerHeight)
    const [approbationIcp, setApprobationIcp] = React.useState<boolean>(false)
    const [acceptation, setAcceptation] = React.useState<boolean>(false)
    const [tokenValidity, setTokenValidity] = React.useState<boolean>(false)
    const [allInfos, setAllInfos] = React.useState<PublicIcp | undefined>(undefined)
    const [statusButton, setStatusButton] = React.useState<ButtonStatus>('active')
    const token = qs.parse(useLocation().search, { ignoreQueryPrefix: true })
    const i18nScreenKey = 'screens.approbationPublique'

    React.useLayoutEffect(() => {
        const updateHeight = () => setWindowHeight(window.innerHeight)
        window.addEventListener('resize', updateHeight)
        return () => window.removeEventListener('resize', updateHeight)
    }, [setWindowHeight])

    React.useEffect(() => {
        apiPublic.icp
            .getInfosApprobationIcp(token.token)
            .then(response => {
                if (response !== 'invalid_token') {
                    setTokenValidity(true)
                    setAllInfos(response)
                }
            })
            .catch(err => {
                basicAlert(
                    i18n,
                    i18nScreenKey,
                    'Loading',
                    () => {
                        setStatusButton('active')
                    },
                    'error',
                    Theme,
                )
                Logger.error('Erreur...', err)
            })
    }, [i18n, Theme, token.token])

    const response = (response: boolean) => {
        apiPublic.icp
            .responseIcp(response, token.token)
            .then(() => {
                basicAlert(
                    i18n,
                    i18nScreenKey,
                    !response ? 'Refuse' : 'Accept',
                    () => {
                        setStatusButton('active')
                    },
                    'success',
                    Theme,
                )
                !response ? setAcceptation(false) : setAcceptation(true)
                setApprobationIcp(true)
            })
            .catch(err => {
                basicAlert(
                    i18n,
                    i18nScreenKey,
                    'Accept',
                    () => {
                        setStatusButton('active')
                    },
                    'error',
                    Theme,
                )
                Logger.error('Erreur...', err)
            })
    }

    const valueDisplay = (status: string) => {
        switch (status) {
            case 'yes':
                return i18n.t('screens.approbationPublique.alreadyAccept')
            case 'no':
                return i18n.t('screens.approbationPublique.alreadyRefuse')
            case 'changed':
                return i18n.t('screens.approbationPublique.noApprobationNeeded')
            case 'waiting':
                return approbationIcp
                    ? acceptation
                        ? i18n.t('screens.approbationPublique.acceptICP')
                        : i18n.t('screens.approbationPublique.refusedICP')
                    : i18n.t('components.answerQuestionSection.unknownType')
            default:
                return i18n.t('components.answerQuestionSection.unknownType')
        }
    }

    return (
        <Container source={{ uri: require('@screens/login/HomePicture.png') }} windowHeight={windowHeight}>
            <Background windowHeight={windowHeight} />
            <LiseretContainer windowHeight={windowHeight}>
                {allInfos && tokenValidity && (
                    <InformationContainer>
                        <AppTextWelcomeContainer>
                            <ApplicationWelcome>
                                {`${i18n.t('screens.approbationPublique.welcomeText')}`}
                            </ApplicationWelcome>
                        </AppTextWelcomeContainer>
                        <TextContainer>
                            <DescriptionContainer windowHeight={windowHeight}>
                                <ScrollView>
                                    <Value>
                                        {allInfos.detail
                                            ? allInfos.detail
                                            : i18n.t('screens.approbationPublique.noDetail')}
                                    </Value>
                                </ScrollView>
                            </DescriptionContainer>
                            <SubInfoContainer>
                                <Label>{`${i18n.t('screens.icpList.modeSelected.icpDate')} : `}</Label>
                                <Value>
                                    {allInfos.icpDate
                                        ? i18n.t('components.datePicker.date', {
                                              date: new Date(allInfos.icpDate),
                                          })
                                        : i18n.t('components.datePicker.noDate')}
                                </Value>
                            </SubInfoContainer>
                            <SubInfoContainer>
                                <Label>{`${i18n.t('components.addIcpAgencies.fields.name')} : `}</Label>
                                <Value>
                                    {allInfos.companyName
                                        ? allInfos.companyName
                                        : i18n.t('screens.approbationPublique.noCompany')}
                                </Value>
                            </SubInfoContainer>
                            <SubInfoContainer>
                                <Label>{`${i18n.t('components.infosIcp.labels.startDate')} : `}</Label>
                                <Value>
                                    {allInfos.operationBeginDate
                                        ? i18n.t('components.datePicker.date', {
                                              date: new Date(allInfos.operationBeginDate),
                                          })
                                        : i18n.t('components.datePicker.noDate')}
                                </Value>
                            </SubInfoContainer>
                            <SubInfoContainer>
                                <Label>{`${i18n.t('components.infosIcp.labels.endDate')} : `}</Label>
                                <Value>
                                    {allInfos.operationEndDate
                                        ? i18n.t('components.datePicker.date', {
                                              date: new Date(allInfos.operationEndDate),
                                          })
                                        : i18n.t('components.datePicker.noDate')}
                                </Value>
                            </SubInfoContainer>
                        </TextContainer>

                        {allInfos.status === 'waiting' && !approbationIcp && (
                            <AppButtonContainer>
                                <ButtonContainer>
                                    <Button
                                        libelle={`${i18n.t('screens.approbationPublique.accept')}`}
                                        onPress={() => {
                                            setStatusButton('loading')
                                            response(true)
                                        }}
                                        status={statusButton}
                                        width={150}
                                        buttonColor={Theme.colors.defaultValidateButton}
                                    />
                                </ButtonContainer>
                                <ButtonContainer>
                                    <Button
                                        libelle={`${i18n.t('screens.approbationPublique.refuse')}`}
                                        onPress={() => {
                                            setStatusButton('loading')
                                            response(false)
                                        }}
                                        status={statusButton}
                                        width={150}
                                        buttonColor={Theme.colors.defaultCancelButton}
                                    />
                                </ButtonContainer>
                            </AppButtonContainer>
                        )}

                        {((allInfos.status === 'waiting' && approbationIcp) || allInfos.status !== 'waiting') && (
                            <AppButtonContainer>
                                <Value>{valueDisplay(allInfos.status)}</Value>
                            </AppButtonContainer>
                        )}
                    </InformationContainer>
                )}
                {!allInfos && !tokenValidity && (
                    <InformationContainer>
                        <Value>{i18n.t('screens.approbationPublique.tokenInvalid')}</Value>
                    </InformationContainer>
                )}
            </LiseretContainer>
        </Container>
    )
}
const Background = styled(View)<{ windowHeight: number }>`
    position: absolute;
    height: ${props => props.windowHeight}px;
    width: 100%;
    background-color: ${props => props.theme.colors.webLoginBackground};
`
const Container = styled(ImageBackground)<{ windowHeight: number }>`
    height: ${props => props.windowHeight}px;
    background-color: transparent;
`
const LiseretContainer = styled(View)<{ windowHeight: number }>`
    flex-direction: row;
    height: ${props => props.windowHeight * 0.6}px;
    min-height: 200px;
    background-color: ${props => props.theme.colors.webLoginLiseretBackground};
    margin-top: ${props => props.windowHeight * 0.2}px;
    elevation: 3px;
    box-shadow: 1px 1px 10px ${props => props.theme.colors.webBoxShadow};
`
const InformationContainer = styled(View)`
    flex: 1;
    justify-content: space-around;
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;
`
const AppTextWelcomeContainer = styled(View)`
    align-items: center;
    align-content: center;
    justify-content: center;
    height: ${props => props.theme.constants.webLoginAppTextHeight}px;
    flex-wrap: wrap;
    flex: 1;
`
const AppButtonContainer = styled(View)`
    align-items: center;
    align-content: center;
    justify-content: space-around;
    height: ${props => props.theme.constants.webLoginAppTextHeight}px;
    flex-wrap: wrap;
    flex-direction: row;
    flex: 1;
`
const ApplicationWelcome = styled(Text)`
    ${props => props.theme.fonts.webAppName}
`
const ButtonContainer = styled(View)`
    min-width: 300px;
    align-items: center;
    margin-bottom: 5px;
`
const Label = styled(Text)`
    ${props => props.theme.fonts.fieldLabel}
    color:  ${props => props.theme.colors.inputText};
    font-size: 15px;
`
const Value = styled(Text)<{ color?: string; minHeight?: number }>`
    ${props => props.theme.fonts.inputText}
    color:  ${props => props.theme.colors.inputText};
    margin-right: 10px; 
    text-align: center;
    font-size: 15px;
`
const SubInfoContainer = styled(View)`
    min-width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 1;
`
const TextContainer = styled(View)`
    flex: 3;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
`
const DescriptionContainer = styled(View)<{ windowHeight: number }>`
    min-width: 100%;
    flex: 1;
    justify-content: center;
    align-items: center;
    height: ${props => props.windowHeight * 0.1}px;
`
