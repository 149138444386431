import { createEvent, createStore } from 'effector'

type NavigationAlert = { title: string; content: string; confirm: string; cancel: string }

export type NavigationState = { alert?: NavigationAlert }

export const initialState: NavigationState = {}

export const actions = {
    setAlert: createEvent<NavigationAlert>('setAlert'),
    reset: createEvent(),
}

export const store = createStore<NavigationState>(initialState)
    .on(actions.setAlert, (state, alert) => ({ ...state, alert }))
    .on(actions.reset, () => initialState)
