import * as React from 'react'

// Components
import { View, Platform, Text } from 'react-native'
import Container from '@components/icpFormContainer/icpFormContainer'
import ReadIcpPlaces from '@components/addIcpPlaces/ReadIcpPlaces'

// Store
import useI18n from '@store/i18n/useI18n'

// Style
import styled from '@styles/index'

interface Props {
    values: InformationsIcp
}

export default (props: Props) => {
    const values = props.values
    const i18n = useI18n()

    return (
        <Container title={i18n.t('components.infosIcp.title')}>
            <View>
                <RowLine zIndex={0}>
                    <InputContainer isLeft={true}>
                        <LabelContainer>
                            <Label>{i18n.t('components.infosIcp.labels.type')}</Label>
                        </LabelContainer>
                        <ValueContainer>
                            <Value>
                                {!!values.type
                                    ? values.type === 'one-time'
                                        ? i18n.t('components.infosIcp.values.one-time')
                                        : i18n.t('components.infosIcp.values.annual')
                                    : ''}
                            </Value>
                        </ValueContainer>
                    </InputContainer>
                    <InputContainer>
                        <LabelContainer>
                            <Label>{i18n.t('components.infosIcp.labels.socle')}</Label>
                        </LabelContainer>
                        <ValueContainer>
                            <Value>
                                {values.socle === true
                                    ? i18n.t('components.infosIcp.values.yes')
                                    : i18n.t('components.infosIcp.values.no')}
                            </Value>
                        </ValueContainer>
                    </InputContainer>
                </RowLine>
                <Section zIndex={0}>
                    <SectionTitle>{i18n.t('components.infosIcp.sectionsTitles.description')}</SectionTitle>
                    <Line zIndex={0}>
                        <InputContainer>
                            <LabelContainer>
                                <Label>{i18n.t('components.infosIcp.labels.description')}</Label>
                            </LabelContainer>
                            <ValueContainer>
                                <Value>
                                    {values.description
                                        ? values.description
                                        : i18n.t('components.infosIcp.labels.noDescription')}
                                </Value>
                            </ValueContainer>
                        </InputContainer>
                    </Line>
                    <RowLine zIndex={-1}>
                        <InputContainer isLeft={true}>
                            <LabelContainer>
                                <Label>{i18n.t('components.infosIcp.labels.startDate')}</Label>
                            </LabelContainer>
                            <ValueContainer>
                                <Value>
                                    {values.startDate
                                        ? i18n.t('components.datePicker.dateTime', {
                                              date: new Date(values.startDate),
                                          })
                                        : i18n.t('components.datePicker.noDate')}
                                </Value>
                            </ValueContainer>
                        </InputContainer>
                        <InputContainer>
                            <LabelContainer>
                                <Label>{i18n.t('components.infosIcp.labels.endDate')}</Label>
                            </LabelContainer>
                            <ValueContainer>
                                <Value>
                                    {values.endDate
                                        ? i18n.t('components.datePicker.dateTime', {
                                              date: new Date(values.endDate),
                                          })
                                        : i18n.t('components.datePicker.noDate')}
                                </Value>
                            </ValueContainer>
                        </InputContainer>
                    </RowLine>
                </Section>
                <Section zIndex={-1}>
                    <SectionTitle>{i18n.t('components.infosIcp.sectionsTitles.places')}</SectionTitle>
                    <InputContainer>
                        <ReadIcpPlaces values={values.places} label={i18n.t('components.infosIcp.labels.places')} />
                    </InputContainer>
                    <LabelContainer>
                        <Label>{i18n.t('components.infosIcp.labels.otherOperations')}</Label>
                    </LabelContainer>
                    <ValueContainer>
                        <Value>
                            {values.otherOperations.length !== 0
                                ? i18n.t('components.infosIcp.values.yesItems', {
                                      items: values.otherOperations.join(' / '),
                                  })
                                : i18n.t('components.infosIcp.values.no')}
                        </Value>
                    </ValueContainer>
                </Section>
            </View>
        </Container>
    )
}
const SectionTitle = styled(Text)`
    ${props => props.theme.fonts.icpFormTitle}
    font-family: Avenir-Book;
    margin-bottom: 20px;
`
const RowLine = styled(View)<{ zIndex: number }>`
    flex-direction: ${Platform.OS === 'web' ? 'row' : 'column'};
    ${Platform.OS === 'web' && 'align-items: center;'}
    z-index: ${props => props.zIndex};
`
const Line = styled(View)<{ zIndex: number }>`
    z-index: ${props => props.zIndex};
`
const InputContainer = styled(View)<{ isLeft?: boolean }>`
    ${Platform.OS === 'web' && 'flex: 1;'}
    ${props => props.isLeft && Platform.OS === 'web' && `margin-right: 40px`};
    margin-bottom: ${Platform.OS === 'web' ? '24px' : '15px'};
`
const Section = styled(View)<{ zIndex: number }>`
    z-index: ${props => props.zIndex};
    margin-bottom: ${Platform.OS === 'web' ? '24px' : '15px'};
`

const LabelContainer = styled(View)`
    margin-bottom: 5px;
`

const ValueContainer = styled(View)`
    margin-bottom: 5px;
`

const Value = styled(Text)`
    ${props => props.theme.fonts.inputText}
    color:  ${props => props.theme.colors.inputText};
    
`

const Label = styled(Text)<{ color?: string; minHeight?: number }>`
    ${props => props.theme.fonts.fieldLabel}
    ${props => props.color && `color: ${props.color}`}
    font-size: ${props => props.minHeight && props.minHeight * 0.5}px;
`
