import * as React from 'react'

// Components
import { Text, View, Platform } from 'react-native'
import Modal from '@components/modal/Modal'
import { Formik, FormikErrors } from 'formik'
import TextInput from '@components/textInput/TextInput'
import Button from '@components/button/Button'
import { TouchableItemFeedback } from '@components/touchable/Touchable'
import DatePicker from '@components/datePicker/DatePicker'
import AgencySection from '@components/agencySection/AgencySection'

// Utils
import { produce } from 'immer'
import { isBefore, addYears, subYears } from 'date-fns'

// Store
import useI18n from '@store/i18n/useI18n'

// Style
import styled from '@styles/index'

const trimAllValues = (values: IcpAgency): IcpAgency => ({
    ...values,
    name: values.name.trim(),
    companyName: values.companyName.trim(),
    address: !!values.address ? values.address.trim() : '',
    telephone: !!values.telephone ? values.telephone.trim() : '',
    email: !!values.email ? values.email.trim() : '',
    siteManagerName: values.siteManagerName.trim(),
    siteManagerMobile: values.siteManagerMobile.trim(),
    worksNature: values.worksNature.trim(),
    tools: values.tools.trim(),
    comment: !!values.comment ? values.comment.trim() : undefined,
})

interface Props {
    modalVisible: boolean
    onRequestClose: () => void
    onChange: (agencies: IcpAgency[]) => void
    agencies: IcpAgency[]
    initialValues?: IcpAgency
    globalInfos?: InformationsIcp
}

export default (props: Props) => {
    const { modalVisible, onRequestClose, onChange, agencies, initialValues, globalInfos } = props
    const i18n = useI18n()

    const [mode, setMode] = React.useState<'search' | 'add' | 'edit'>('search')
    const [offlineMode, setOfflineMode] = React.useState<boolean>(false)

    React.useEffect(() => {
        if (!!initialValues) {
            setMode('edit')
        }
    }, [initialValues])

    const handleChange = (values: IcpAgency) => {
        const trimedValues = trimAllValues(values)
        const index = !!initialValues ? agencies.indexOf(initialValues) : -1
        const result: IcpAgency[] =
            !!initialValues && index > -1
                ? produce(agencies, newAgencies => {
                      newAgencies[index] = trimedValues
                  })
                : [...agencies, trimedValues]
        onChange(result)
        setMode('search')
        onRequestClose()
    }

    const validateForm = React.useMemo(
        () => (values: IcpAgency): FormikErrors<IcpAgency> => {
            const trimedValues = trimAllValues(values)
            const errors: FormikErrors<IcpAgency> = {}
            if (!trimedValues.name) {
                errors.name = i18n.t('screens.addUserAdminScreen.required')
            }
            if (!trimedValues.companyName) {
                errors.companyName = i18n.t('screens.addUserAdminScreen.required')
            }
            if (!trimedValues.address) {
                errors.address = i18n.t('screens.addUserAdminScreen.required')
            }

            if (!!trimedValues.email && !trimedValues.email.match(/^[a-z0-9](?:[.+-]?\w+)*@[a-z0-9]+(?:[.-]?\w+)*$/i)) {
                errors.email = i18n.t('screens.addUserAdminScreen.emailError')
            }
            if (!trimedValues.siteManagerName) {
                errors.siteManagerName = i18n.t('screens.addUserAdminScreen.required')
            }
            if (!trimedValues.maxStaff) {
                errors.maxStaff = i18n.t('screens.addUserAdminScreen.required')
            }

            if (!trimedValues.worksNature) {
                errors.worksNature = i18n.t('screens.addUserAdminScreen.required')
            }
            if (!!trimedValues.startDate && !!trimedValues.endDate) {
                if (isBefore(new Date(trimedValues.endDate), new Date(trimedValues.startDate))) {
                    errors.endDate = i18n.t('components.infosIcp.errors.isBefore')
                }
            }
            if (!trimedValues.startDate) {
                errors.startDate = i18n.t('screens.addUserAdminScreen.required')
            }
            if (!trimedValues.endDate) {
                errors.endDate = i18n.t('screens.addUserAdminScreen.required')
            }
            return errors
        },
        [i18n],
    )

    const renderTextInput = (
        fieldName: IcpAgencyStringFieldName,
        required: boolean,
        values: IcpAgency,
        setFieldValue: (key: IcpAgencyStringFieldName, value: any) => void,
        errors: FormikErrors<IcpAgency>,
        multiline?: boolean,
    ) => (
        <ModalRow key={fieldName}>
            <FlexContainer>
                <TextInput
                    value={values[fieldName]}
                    onTextChange={text => setFieldValue(fieldName, text)}
                    label={i18n.t(`components.addIcpAgencies.fields.${fieldName}`)}
                    required={required}
                    error={errors && errors[fieldName]}
                    multiline={multiline}
                    numberOfLines={multiline ? 4 : 1}
                    placeholder={i18n.t(`components.addIcpAgencies.placeholders.${fieldName}`)}
                    labelIcon={fieldName === 'tools' ? 'attention' : undefined}
                />
            </FlexContainer>
        </ModalRow>
    )

    const getInitialValues = (): IcpAgency =>
        produce(
            initialValues ||
                ({
                    name: '',
                    companyName: '',
                    address: '',
                    telephone: '',
                    email: '',
                    siteManagerName: '',
                    siteManagerMobile: '',
                    worksNature: '',
                    tools: '',
                } as IcpAgency),
            newValues => {
                newValues.startDate =
                    !!initialValues && !!initialValues.startDate
                        ? initialValues.startDate
                        : !!globalInfos && !!globalInfos.startDate
                        ? globalInfos.startDate
                        : undefined

                newValues.endDate =
                    !!initialValues && !!initialValues.endDate
                        ? initialValues.endDate
                        : !!globalInfos && !!globalInfos.endDate
                        ? globalInfos.endDate
                        : undefined

                newValues.worksNature =
                    !!initialValues && !!initialValues.worksNature
                        ? initialValues.worksNature
                        : !!globalInfos && !!globalInfos.description
                        ? globalInfos.description
                        : ''
            },
        )

    return (
        <Modal visible={modalVisible} onRequestClose={onRequestClose}>
            <ModalContent>
                {mode !== 'edit' && (
                    <ModeSelector>
                        {!offlineMode && (
                            <FlexContainer>
                                <TouchableItemFeedback onPress={() => setMode('search')}>
                                    <Mode selected={mode === 'search'}>
                                        <ModeName selected={mode === 'search'}>
                                            {i18n.t(`components.addIcpAgencies.modalTitles.search`)}
                                        </ModeName>
                                    </Mode>
                                </TouchableItemFeedback>
                            </FlexContainer>
                        )}
                        <FlexContainer>
                            <TouchableItemFeedback onPress={() => setMode('add')} disabled={offlineMode}>
                                <Mode selected={mode === 'add'}>
                                    <ModeName selected={mode === 'add'}>
                                        {i18n.t(`components.addIcpAgencies.modalTitles.add`)}
                                    </ModeName>
                                </Mode>
                            </TouchableItemFeedback>
                        </FlexContainer>
                    </ModeSelector>
                )}
                <Formik initialValues={getInitialValues()} onSubmit={handleChange} validate={validateForm}>
                    {({ errors, values, handleSubmit, isValid, setFieldValue, isSubmitting, setValues }) =>
                        mode === 'search' && !offlineMode ? (
                            <AgencySection
                                onAgencyPress={(agency, companyName) => {
                                    setValues({
                                        name: agency.libelle,
                                        companyName: companyName || '',
                                        address: agency.adresse,
                                        telephone: agency.telephone,
                                        email: agency.mail,
                                        siteManagerName: '',
                                        siteManagerMobile: '',
                                        worksNature: '',
                                        tools: '',
                                    })
                                    setMode('edit')
                                }}
                                filterFunction={res =>
                                    res.filter(agency => !agencies.find(a => a.name === agency.libelle))
                                }
                                setOfflineMode={() => {
                                    setMode('add')
                                    setOfflineMode(true)
                                }}
                            />
                        ) : (
                            <SectionContainer>
                                <Section zIndex={0}>
                                    {renderTextInput('name', true, values, setFieldValue, errors)}
                                </Section>
                                <Section zIndex={0}>
                                    {renderTextInput('companyName', true, values, setFieldValue, errors)}
                                </Section>
                                <Section zIndex={-1}>
                                    <SectionTitle>
                                        {i18n.t('components.addIcpAgencies.modalTitles.coordinates')}
                                    </SectionTitle>
                                    {renderTextInput('address', true, values, setFieldValue, errors)}
                                    {(['telephone', 'email'] as IcpAgencyStringFieldName[]).map(key =>
                                        renderTextInput(key, false, values, setFieldValue, errors),
                                    )}
                                    {renderTextInput('siteManagerName', true, values, setFieldValue, errors)}
                                    {renderTextInput('siteManagerMobile', false, values, setFieldValue, errors)}
                                </Section>
                                <Section zIndex={-2}>
                                    <SectionTitle>{i18n.t('components.addIcpAgencies.modalTitles.works')}</SectionTitle>
                                    {renderTextInput('worksNature', true, values, setFieldValue, errors)}
                                    {renderTextInput('tools', false, values, setFieldValue, errors)}

                                    <ModalRow key={'maxStaff'}>
                                        <FlexContainer>
                                            <TextInput
                                                required={true}
                                                keyboardType={'number-pad'}
                                                value={values.maxStaff?.toString()}
                                                onTextChange={text => setFieldValue('maxStaff', text)}
                                                label={i18n.t(`components.addIcpAgencies.fields.maxStaff`)}
                                                error={errors && errors.maxStaff}
                                                placeholder={i18n.t(`components.addIcpAgencies.placeholders.maxStaff`)}
                                            />
                                        </FlexContainer>
                                    </ModalRow>
                                </Section>
                                <Section zIndex={-3}>
                                    <SectionTitle>{i18n.t('components.addIcpAgencies.modalTitles.dates')}</SectionTitle>
                                    <ModalRow zIndex={0}>
                                        <FlexContainer>
                                            <DatePicker
                                                label={i18n.t('components.addIcpAgencies.fields.startDate')}
                                                value={values.startDate ? new Date(values.startDate) : undefined}
                                                onChange={date => setFieldValue('startDate', date)}
                                                required={true}
                                                error={!!errors && errors.startDate}
                                                minDate={
                                                    (globalInfos &&
                                                        globalInfos.startDate &&
                                                        new Date(globalInfos.startDate)) ||
                                                    (globalInfos &&
                                                        globalInfos.endDate &&
                                                        subYears(new Date(globalInfos.endDate), 1)) ||
                                                    (values.endDate && subYears(new Date(values.endDate), 1))
                                                }
                                                maxDate={
                                                    (values.endDate && new Date(values.endDate)) ||
                                                    (globalInfos &&
                                                        globalInfos.endDate &&
                                                        new Date(globalInfos.endDate)) ||
                                                    (globalInfos &&
                                                        globalInfos.startDate &&
                                                        addYears(new Date(globalInfos.startDate), 1))
                                                }
                                            />
                                        </FlexContainer>
                                    </ModalRow>
                                    <ModalRow zIndex={-1}>
                                        <FlexContainer>
                                            <DatePicker
                                                label={i18n.t('components.addIcpAgencies.fields.endDate')}
                                                value={values.endDate ? new Date(values.endDate) : undefined}
                                                onChange={date => setFieldValue('endDate', date)}
                                                required={true}
                                                error={!!errors && errors.endDate}
                                                minDate={
                                                    (values.startDate && new Date(values.startDate)) ||
                                                    (globalInfos &&
                                                        globalInfos.startDate &&
                                                        new Date(globalInfos.startDate)) ||
                                                    (globalInfos &&
                                                        globalInfos.endDate &&
                                                        subYears(new Date(globalInfos.endDate), 1))
                                                }
                                                maxDate={
                                                    (globalInfos &&
                                                        globalInfos.endDate &&
                                                        new Date(globalInfos.endDate)) ||
                                                    (globalInfos &&
                                                        globalInfos.startDate &&
                                                        addYears(new Date(globalInfos.startDate), 1)) ||
                                                    (values.startDate && addYears(new Date(values.startDate), 1))
                                                }
                                            />
                                        </FlexContainer>
                                    </ModalRow>
                                </Section>

                                <Section zIndex={-4}>
                                    {renderTextInput('comment', false, values, setFieldValue, errors, true)}
                                </Section>

                                <ButtonsContainer zIndex={-5}>
                                    <Button
                                        libelle={i18n.t('components.addIcpAgencies.add')}
                                        status={isSubmitting ? 'loading' : isValid ? 'active' : 'disabled'}
                                        onPress={handleSubmit}
                                    />
                                </ButtonsContainer>
                            </SectionContainer>
                        )
                    }
                </Formik>
            </ModalContent>
        </Modal>
    )
}

const ModalContent = styled(View)`
    background-color: ${props => props.theme.colors.documentsModalBackground};
    flex: 1;
`
const ModalRow = styled(View)<{ zIndex?: number }>`
    flex-direction: row;
    margin-bottom: 12px;
    ${props => `z-index: ${props.zIndex}`};
`
const ButtonsContainer = styled(View)<{ zIndex: number }>`
    flex-direction: row;
    justify-content: space-evenly;
    z-index: ${props => props.zIndex};
`
const FlexContainer = styled(View)`
    flex: 1;
`
const ModeSelector = styled(View)`
    flex-direction: row;
    border-radius: 20px;
    margin-bottom: 24px;
    overflow: hidden;
`
const Mode = styled(View)<{ selected: boolean }>`
    height: ${`${Platform.OS === 'web' ? 32 : 24}px`};
    background-color: ${props =>
        props.selected ? props.theme.colors.icpContactModeSelected : props.theme.colors.icpContactMode};
    align-items: center;
    justify-content: center;
`
const ModeName = styled(Text)<{ selected: boolean }>`
    ${props => props.theme.fonts.icpContactName}
    color: ${props =>
        !props.selected ? props.theme.colors.icpContactModeSelected : props.theme.colors.icpContactMode};
`
const Section = styled(View)<{ zIndex: number }>`
    margin-bottom: 24px;
    z-index: ${props => props.zIndex};
`
const SectionTitle = styled(Text)`
    margin-bottom: 10px;
    ${props => props.theme.fonts.icpContactName}
`
const SectionContainer = styled(View)`
    padding: 5px;
`
