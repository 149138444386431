import * as React from 'react'

// Components
import { View, Text } from 'react-native'
import { TouchableItemFeedback } from '@components/touchable/Touchable'
import Icon from '@components/icon/Icon'

// Store
import useI18n from '@store/i18n/useI18n'

// Style
import styled from '@styles/index'
import useTheme from '@styles/useTheme'

interface Props {
    values: IcpContact[]
}

export default (props: Props) => {
    const { values } = props

    const i18n = useI18n()
    const [Theme] = useTheme()

    const [itemOpened, setItemOpened] = React.useState<number | undefined>(undefined)

    const renderModeleState = (etat: PlanificationState) => {
        return (
            <ModeleStateContainer>
                <ModelStateTag etat={etat}>
                    <ModelStateText etat={etat}>
                        {i18n.t(`components.addIcpContacts.contactStates.${etat}`)}
                    </ModelStateText>
                </ModelStateTag>
            </ModeleStateContainer>
        )
    }

    return (
        <View>
            <Container>
                <RowContainer>
                    <Legend>{i18n.t('components.addIcpContacts.name')}</Legend>
                </RowContainer>
                <ContactList>
                    {values.length === 0 ? (
                        <NoContact>{i18n.t('components.addIcpContacts.noContact')}</NoContact>
                    ) : (
                        values.map((contact, index) => (
                            <TouchableItemFeedback
                                key={`contact${index}`}
                                onPress={() => setItemOpened(itemOpened === index ? undefined : index)}
                            >
                                <ContactContainer odd={index % 2 === 1}>
                                    <RowContainer>
                                        <ContactName>{`${contact.firstName} ${contact.lastName}`}</ContactName>
                                        <Line>
                                            {renderModeleState(contact.state)}
                                            <Icon
                                                name={'information'}
                                                size={Theme.constants.riskFamilyInformationIconSize}
                                                color={
                                                    itemOpened === index
                                                        ? Theme.colors.riskFamilyItemInformationSelectedIcon
                                                        : Theme.colors.riskFamilyItemInformationIcon
                                                }
                                            />
                                        </Line>
                                    </RowContainer>

                                    {itemOpened === index && (
                                        <View>
                                            <RowContainer>
                                                <ContactColumn double={true}>
                                                    <ContactInformation>{contact.email}</ContactInformation>
                                                    <ContactInformation>{contact.telephone1}</ContactInformation>
                                                    <ContactInformation>{contact.telephone2}</ContactInformation>
                                                </ContactColumn>
                                                <ContactColumn>
                                                    {!!contact.companyName && (
                                                        <ContactInformation main={true}>
                                                            {contact.companyName}
                                                        </ContactInformation>
                                                    )}
                                                    {!!contact.entityName && (
                                                        <ContactInformation>{contact.entityName}</ContactInformation>
                                                    )}
                                                </ContactColumn>
                                            </RowContainer>
                                        </View>
                                    )}
                                </ContactContainer>
                            </TouchableItemFeedback>
                        ))
                    )}
                </ContactList>
            </Container>
        </View>
    )
}
const Line = styled(View)`
    flex-direction: row;
`
const ContactContainer = styled(View)<{ odd?: boolean }>`
    background-color: ${props => !props.odd && props.theme.colors.evenLines};
`
const Container = styled(View)`
    background-color: ${props => props.theme.colors.addIcpContactsBackground};
    padding-right: 8px;
    padding-left: 8px;
`
const RowContainer = styled(View)`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: ${props => props.theme.constants.fieldPadding};
    padding-left: ${props => props.theme.constants.fieldPadding};
    min-height: 36px;
`
const Legend = styled(Text)`
    ${props => props.theme.fonts.rightLegend}
    text-transform: uppercase;
`
const ContactList = styled(View)`
    padding-bottom: 8px;
`
const NoContact = styled(Text)`
    ${props => props.theme.fonts.perimeterName}
    text-align: center;
    padding-top: 24px;
    padding-bottom: 24px;
`
const ContactName = styled(Text)`
    ${props => props.theme.fonts.icpContactName}
`
const ModeleStateContainer = styled(View)`
    width: 90px;
    margin-right: 24px;
    text-align: center;
`
const ModelStateTag = styled(View)<{ etat: PlanificationState }>`
    background-color: ${props =>
        props.etat === 'waiting'
            ? props.theme.colors.icpStateTagBackgroundEnAttente
            : props.etat === 'accepted'
            ? props.theme.colors.icpStateTagBackgroundAccepte
            : props.etat === 'refused'
            ? props.theme.colors.icpStateTagBackgroundRefuse
            : props.theme.colors.icpStateTagBackgroundPasEnvoye};
    border-radius: 40px;
    padding-left: 10px;
    padding-right: 10px;
`
const ModelStateText = styled(Text)<{ etat: PlanificationState }>`
    ${props => props.theme.fonts.homeActionLink}
    text-align: center;
    color: ${props =>
        props.etat === 'waiting'
            ? props.theme.colors.modeleStateTagTextBrouillon
            : props.theme.colors.modeleStateTagTextOther};
`
const ContactInformation = styled(Text)<{ main?: boolean }>`

    ${props => (props.main ? props.theme.fonts.contactMainInformation : props.theme.fonts.contactInformation)}
    margin-left: ${props => !props.main && '20px'};
`
const ContactColumn = styled(View)<{ double?: boolean }>`
    flex: ${props => (props.double ? '2' : '1')};
    flex-direction: column;
    margin-bottom: 20px;
`
