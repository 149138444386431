import * as React from 'react'

// Components
import { View, Platform, Text } from 'react-native'
import Picker from '@components/picker/Picker'
import Checkbox from '@components/checkbox/Checkbox'
import DeclarationInformation from './DeclarationInformation'
import Button from '@components/button/Button'
import { basicAlert, confirmAlert } from '@components/alert/Alerts'
import PdfInformation from '@components/planIcp/PdfInformation'
import RequiredIndicator from '@components/requiredIndicator/RequiredIndicator'

// Utils
import Logger from '@utils/logger'

// Store
import useI18n from '@store/i18n/useI18n'

// Style
import styled from '@styles/index'
import api from '@app/api/api'
import useTheme from '@styles/useTheme'

interface Props {
    onItemChange: (value: Declaration) => void
    values: Declaration
    error?: string
    cssctItems?: ConfigurationEmails
    icpId?: string
    canSendEmail: boolean
    handleSubmitWithoutAlert: () => Promise<void>
    alreadySending: boolean
    setAlreadySending: (sending: boolean) => void
    setEmailSent: () => void
    setIsSendingEmail: (isSendingEmail: boolean) => void
    isSendingEmail: boolean
}

const I18N_ITEM_KEY = 'components.planIcp'

export default (props: Props) => {
    const {
        values,
        onItemChange,
        error,
        cssctItems,
        icpId,
        canSendEmail,
        handleSubmitWithoutAlert,
        alreadySending,
        setAlreadySending,
        setEmailSent,
        setIsSendingEmail,
        isSendingEmail,
    } = props
    const i18n = useI18n()
    const [Theme] = useTheme()

    const [cssctSending, setCssctSending] = React.useState<boolean>(false)

    const [documentLink, emails] = React.useMemo(() => {
        if (!!cssctItems) {
            return [cssctItems.pdf || '', cssctItems.emails.map(adminMailItem => adminMailItem.email)]
        } else {
            return ['', []]
        }
    }, [cssctItems])

    const item = values.cssct

    const sendInvitationByMail = (id: string) => {
        setIsSendingEmail(true)
        setAlreadySending(true)
        setCssctSending(true)
        confirmAlert(
            i18n,
            I18N_ITEM_KEY,
            'sendPdfCssct',
            () => {
                handleSubmitWithoutAlert()
                    .then(() => {
                        const cssctMail = item?.email
                        setIsSendingEmail(false)
                        if (cssctMail) {
                            api.icp
                                .sendToCssct(id, { email: cssctMail })
                                .then(datas =>
                                    basicAlert(
                                        i18n,
                                        I18N_ITEM_KEY,
                                        'SendingCssct',
                                        () => {
                                            onItemChange({
                                                ...datas.planification.declaration,
                                            })
                                            setAlreadySending(false)
                                            setCssctSending(false)
                                            setEmailSent()
                                        },
                                        'success',
                                        Theme,
                                    ),
                                )
                                .catch(er =>
                                    basicAlert(
                                        i18n,
                                        I18N_ITEM_KEY,
                                        'SendingCssct',
                                        () => {
                                            setAlreadySending(false)
                                            setCssctSending(false)
                                            Logger.error('Error on sending email...', er)
                                        },
                                        'error',
                                        Theme,
                                    ),
                                )
                        }
                    })
                    .catch(() => {
                        setIsSendingEmail(false)
                        setAlreadySending(false)
                        setCssctSending(false)
                        Logger.error("Erreur à l'enregistrement...")
                    })
            },
            () => {
                setAlreadySending(false)
                setCssctSending(false)
                setIsSendingEmail(false)
            },
            Theme,
        )
    }

    const checkboxIsDisabled = React.useMemo((): boolean => {
        return !!item && (item.hasBeenSent || item.hasBeenSentByEmail)
    }, [item])

    return (
        <CheckboxLine>
            <RowContainer>
                <Flexbox>
                    <Checkbox
                        label={i18n.t(`components.planIcp.labels.cssct`)}
                        value={values.cssctOpened}
                        onCheck={() => {
                            onItemChange({
                                ...values,
                                cssctOpened: !values.cssctOpened,
                            })
                        }}
                        disabled={checkboxIsDisabled}
                    />
                    <RequiredIndicator />
                </Flexbox>
                {documentLink.length > 0 && <PdfInformation pdfLink={documentLink} />}
                <DeclarationInformation propsKey="cssct" />
            </RowContainer>

            {values.cssctOpened && !!item && (
                <View>
                    {item.byEmail
                        ? !item.hasBeenSentByEmail && (
                              <View>
                                  {canSendEmail && (
                                      <Line zIndex={0} isCssct>
                                          <Picker
                                              value={item.email}
                                              data={emails}
                                              label={i18n.t('components.planIcp.labels.workInspectionEmail')}
                                              onChange={index =>
                                                  onItemChange({
                                                      ...values,
                                                      cssct: {
                                                          ...item,
                                                          email: index > -1 && emails ? emails[index] : undefined,
                                                      },
                                                  })
                                              }
                                              required={true}
                                              isClearable={true}
                                              error={error}
                                              customInputBackgroundColor={Theme.colors.riskFamilyItemInput}
                                              placeholder={i18n.t('components.planIcp.placeholders.cssct')}
                                              disabled={checkboxIsDisabled}
                                          />
                                      </Line>
                                  )}
                                  <ButtonContainer isRight={canSendEmail}>
                                      {icpId && canSendEmail ? (
                                          <Button
                                              libelle={'Envoyer'}
                                              onPress={() => sendInvitationByMail(icpId)}
                                              status={
                                                  cssctSending
                                                      ? 'loading'
                                                      : isSendingEmail
                                                      ? 'disabled'
                                                      : item.hasBeenSentByEmail ||
                                                        !!error ||
                                                        error?.trim() !== '' ||
                                                        alreadySending
                                                      ? 'disabled'
                                                      : 'active'
                                              }
                                          />
                                      ) : (
                                          <MissingInformationsText>
                                              {i18n.t('components.planIcp.labels.cssctMissingInformations')}
                                          </MissingInformationsText>
                                      )}
                                  </ButtonContainer>
                              </View>
                          )
                        : Platform.OS === 'web' && (
                              <ButtonContainer>
                                  {
                                      <MissingInformationsText>
                                          {i18n.t('components.planIcp.labels.cssctMissingInformations')}
                                      </MissingInformationsText>
                                  }
                              </ButtonContainer>
                          )}
                    {canSendEmail && (
                        <CheckboxContainer style={{ zIndex: -1 }}>
                            {item.hasBeenSentByEmail && item.byEmail ? (
                                <SentToLabel>
                                    {i18n.t('components.planIcp.values.sentTo', { email: item?.email })}
                                </SentToLabel>
                            ) : (
                                <Checkbox
                                    label={i18n.t('components.planIcp.values.sent')}
                                    value={item.hasBeenSent || item.hasBeenSentByEmail}
                                    onCheck={() =>
                                        onItemChange({
                                            ...values,
                                            cssct: {
                                                ...item,
                                                hasBeenSent: !item.hasBeenSent,
                                            },
                                        })
                                    }
                                    disabled={item.byEmail || !canSendEmail}
                                />
                            )}
                        </CheckboxContainer>
                    )}
                </View>
            )}
        </CheckboxLine>
    )
}
const RowContainer = styled(View)`
    flex-direction: row;
    align-items: center;
`
const Flexbox = styled(View)`
    flex: 1;
    flex-direction: row;
`
const Line = styled(View)<{ zIndex: number; isCssct?: boolean }>`
    z-index: ${props => props.zIndex};
    margin-bottom: ${Platform.OS === 'web' ? '24px' : '15px'};
    ${props => props.isCssct && `margin-top: ${Platform.OS === 'web' ? '24px' : '15px'};`}
`
const CheckboxLine = styled(View)`
    margin-bottom: 20px;
    background-color: ${props => props.theme.colors.rubriqueBackground};
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 24px;
    padding-right: 24px;
`
const CheckboxContainer = styled(View)`
    z-index: -1;
`
const ButtonContainer = styled(View)<{ isRight?: boolean }>`
    flex-direction: row;
    justify-content: ${props => (props.isRight ? 'flex-end' : 'flex-start')};
    margin-bottom: 10px;
    z-index: -1;
`
const MissingInformationsText = styled(Text)`
    ${props => props.theme.fonts.missingInformationsText};
`
const SentToLabel = styled(Text)`
    margin-top: 10px;
    margin-left: 10px;
    ${props => props.theme.fonts.fieldLabel};
`
