import * as React from 'react'

// Components
import { View, Text, ScrollView } from 'react-native'
import { TouchableItemFeedback } from '@components/touchable/Touchable'
import Button from '@components/button/Button'
import RootScreen from '@components/rootScreen/RootScreen'

// Store
import useI18n from '@store/i18n/useI18n'

// Utils
import Logger from '@utils/logger'
import useNavigation from '@layout/useNavigation'

// Api
import api from '@api/api'

// Style
import styled from '@styles/index'

export default () => {
    const i18n = useI18n()
    const navigation = useNavigation()

    const [status, setStatus] = React.useState<Status>('loading')
    const [modeles, setModeles] = React.useState<AdminModeles | undefined>(undefined)

    React.useEffect(() => {
        api.admin
            .getAllModeles()
            .then(modeles => {
                if (!!modeles) {
                    setModeles(modeles.sort((m1, m2) => m1.etat - m2.etat))
                    setStatus('fetched')
                } else {
                    setStatus('error')
                }
            })
            .catch(err => {
                Logger.error(err)
                setStatus('error')
            })
    }, [])

    const renderModeleState = (etat: number) => {
        return (
            <ModeleStateContainer>
                <ModelStateTag etat={etat}>
                    <ModelStateText etat={etat}>
                        {i18n.t(`screens.modelesAdminScreen.modeleStates.${etat}`)}
                    </ModelStateText>
                </ModelStateTag>
            </ModeleStateContainer>
        )
    }

    const renderModeleList = (defaut: boolean, modelesToRender: AdminModele[]) => {
        return (
            <Container flexed={!defaut}>
                <ListTitleContainer>
                    <ListTitle numberOfLines={2} ellipsizeMode={'tail'}>
                        {defaut
                            ? i18n.t('screens.modelesAdminScreen.defaut')
                            : i18n.t('screens.modelesAdminScreen.modeles')}
                    </ListTitle>
                    {!defaut && (
                        <Button
                            libelle={i18n.t('screens.modelesAdminScreen.createModele')}
                            onPress={() => navigation.push('/ajouterTrame')}
                            status="active"
                        />
                    )}
                </ListTitleContainer>
                <ModelesContainer>
                    <LegendLine>
                        <Legend>{i18n.t('screens.modelesAdminScreen.modeleTitle')}</Legend>
                    </LegendLine>

                    {modelesToRender.length === 0 ? (
                        <NoModeleMessage>{i18n.t('screens.modelesAdminScreen.noModele')}</NoModeleMessage>
                    ) : (
                        <ScrollView>
                            {modelesToRender.map((modele, index) => {
                                return (
                                    <TouchableItemFeedback
                                        key={`modele${index}`}
                                        onPress={() =>
                                            navigation.push('/gestionDesTrames/:modeleId', {
                                                pathParams: { modeleId: modele.id },
                                            })
                                        }
                                    >
                                        <ModeleLine odd={index % 2 === 1}>
                                            <ModeleTitle numberOfLines={2} ellipsizeMode={'tail'}>
                                                {modele.titre}
                                            </ModeleTitle>
                                            {!defaut && renderModeleState(modele.etat)}

                                            <Action>
                                                {i18n.t(
                                                    `screens.modelesAdminScreen.${
                                                        modele.etat === 2 ? 'seeModele' : 'editModele'
                                                    }`,
                                                )}
                                            </Action>
                                        </ModeleLine>
                                    </TouchableItemFeedback>
                                )
                            })}
                        </ScrollView>
                    )}
                </ModelesContainer>
            </Container>
        )
    }

    return (
        <RootScreen status={status}>
            {!!modeles &&
                renderModeleList(
                    true,
                    modeles.filter(modele => modele.defaut),
                )}
            {!!modeles &&
                renderModeleList(
                    false,
                    modeles.filter(modele => !modele.defaut),
                )}
        </RootScreen>
    )
}

const Container = styled(View)<{ flexed?: boolean }>`
${props => props.flexed && 'flex: 1;'}
    background-color: ${props => props.theme.colors.rolesBackground};
`
const ModelesContainer = styled(View)`
    flex: 1;
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 14px;
    padding-right: 14px;
`
const ModeleLine = styled(View)<{ odd: boolean }>`
    flex-direction: row;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
    height: ${props => props.theme.constants.rightLineHeight}px;
    background-color: ${props => !props.odd && props.theme.colors.evenLines};

    &:hover {
        background-color: ${props => props.theme.colors.hoverBackground};
        & > div {
            color: ${props => props.theme.colors.hoverText};
            & > div {
                color: ${props => props.theme.colors.hoverText};
            }
        }
    }
`
const ModeleTitle = styled(Text)`
    flex: 1;
    ${props => props.theme.fonts.rightTitle}
`
const LegendLine = styled(View)`
    flex-direction: row;
    height: 34px;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
`
const Legend = styled(Text)`
    ${props => props.theme.fonts.rightLegend}
    text-transform: uppercase;
`
const ListTitleContainer = styled(View)`
    flex-direction: row;
    height: ${props => props.theme.constants.riskFamiliesListTitleContainerHeight}px;
    justify-content: space-between;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;

    border-bottom-color: ${props => props.theme.colors.homeListBorderColor};
    border-bottom-width: 2px;
`
const ListTitle = styled(Text)`
    ${props => props.theme.fonts.homeListTitle}
    text-transform: uppercase;
`
const Action = styled(Text)`
    ${props => props.theme.fonts.homeActionLink}
    text-decoration: underline;
    width: 100px;
`
const NoModeleMessage = styled(Text)`
    ${props => props.theme.fonts.perimeterName}
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
`
const ModeleStateContainer = styled(View)`
    width: 75px;
    margin-right: 24px;
    text-align: center;
`
const ModelStateTag = styled(View)<{ etat: number }>`
    background-color: ${props =>
        props.etat === 0
            ? props.theme.colors.modeleStateTagBackgroundBrouillon
            : props.etat === 1
            ? props.theme.colors.modeleStateTagBackgroundActif
            : props.theme.colors.modeleStateTagBackgroundArchive};
    border-radius: 40px;
    padding-left: 10px;
    padding-right: 10px;
`
const ModelStateText = styled(Text)<{ etat: number }>`
    ${props => props.theme.fonts.homeActionLink}
    color: ${props =>
        props.etat === 0 ? props.theme.colors.modeleStateTagTextBrouillon : props.theme.colors.modeleStateTagTextOther};
`
