import * as React from 'react'

// Components
import { View, Text } from 'react-native'
import { TouchableItemFeedback } from '@components/touchable/Touchable'
import Icon from '@components/icon/Icon'
import RootScreen from '@components/rootScreen/RootScreen'

// Store
import useI18n from '@store/i18n/useI18n'

// Utils
import Logger from '@utils/logger'
import useNavigation from '@layout/useNavigation'
import useParams from '@layout/useParams'

// Api
import api from '@api/api'

// Style
import styled from '@styles/index'

export default () => {
    const { ppeeId } = useParams<{ ppeeId: string }>()

    const i18n = useI18n()
    const navigation = useNavigation()

    const [status, setStatus] = React.useState<Status>('loading')
    const [modeles, setModeles] = React.useState<Modeles | undefined>(undefined)

    React.useEffect(() => {
        api.modele
            .getAllModeles()
            .then(modeles => {
                if (!!modeles) {
                    setModeles(modeles.filter(modele => modele.etat === 1))
                    setStatus('fetched')
                } else {
                    setStatus('error')
                }
            })
            .catch(err => {
                Logger.error(err)
                setStatus('error')
            })
    }, [])

    return (
        <RootScreen status={status}>
            {!!modeles && (
                <Container>
                    <ListTitleContainer>
                        <ListTitle numberOfLines={2} ellipsizeMode={'tail'}>
                            {i18n.t('screens.modelesAdminScreen.modeles')}
                        </ListTitle>
                    </ListTitleContainer>
                    <ModelesContainer>
                        <LegendLine>
                            <Legend>{i18n.t('screens.modelesAdminScreen.modeleTitle')}</Legend>
                        </LegendLine>

                        {modeles.length === 0 ? (
                            <NoModeleMessage>{i18n.t('screens.modelesAdminScreen.noModele')}</NoModeleMessage>
                        ) : (
                            modeles.map((modele, index) => {
                                return (
                                    <TouchableItemFeedback
                                        key={`modele${index}`}
                                        onPress={() =>
                                            navigation.push('/ppee/:ppeeId/ajouterFormulaire/:modeleId', {
                                                ppeeId: ppeeId,
                                                modeleId: modele.id,
                                                pathParams: { ppeeId: ppeeId, modeleId: modele.id },
                                            })
                                        }
                                    >
                                        <ModeleLine odd={index % 2 === 1}>
                                            <ModeleTitle numberOfLines={2} ellipsizeMode={'tail'}>
                                                {modele.titre}
                                            </ModeleTitle>
                                            <Icon name={'chevron-right'} size={15} />
                                        </ModeleLine>
                                    </TouchableItemFeedback>
                                )
                            })
                        )}
                    </ModelesContainer>
                </Container>
            )}
        </RootScreen>
    )
}

const Container = styled(View)`
    background-color: ${props => props.theme.colors.rolesBackground};
`
const ModelesContainer = styled(View)`
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 14px;
    padding-right: 14px;
`
const ModeleLine = styled(View)<{ odd: boolean }>`
    flex-direction: row;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
    height: ${props => props.theme.constants.trameLineHeight};
    background-color: ${props => (props.odd ? props.theme.colors.oddLine : props.theme.colors.evenLine)};
`
const ModeleTitle = styled(Text)`
    flex: 1;
    ${props => props.theme.fonts.rightTitle}
`
const LegendLine = styled(View)`
    flex-direction: row;
    height: 34px;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
`
const Legend = styled(Text)`
    ${props => props.theme.fonts.rightLegend}
    text-transform: uppercase;
`
const ListTitleContainer = styled(View)`
    flex-direction: row;
    height: ${props => props.theme.constants.modelesListTitleContainerHeight};
    justify-content: space-between;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;

    border-bottom-color: ${props => props.theme.colors.homeListBorderColor};
    border-bottom-width: 2px;
`
const ListTitle = styled(Text)`
    ${props => props.theme.fonts.homeListTitle}
    text-transform: uppercase;
`
const NoModeleMessage = styled(Text)`
    ${props => props.theme.fonts.perimeterName}
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
`
