import * as React from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { View } from 'react-native'
import styled from '@styles/index'
import Menu from '@components/menu/Menu'
import Header from '@components/header/Header.web'

import SplashScreen from '@utils/splashScreen'
import Azure from '@utils/azure/Azure'
import Logger from '@utils/logger'
import Loading from '@screens/loading/Loading'
import Login from '../screens/login/Login.web'

import * as userStore from '@store/user'
import useReducer from '@store/useReducer'

import api from '@app/api/api'
import { ConnectionError } from '../screens/login/Login'
import routes from './routes.web'
import routesPubliques from './routesPubliques.web'

export default () => {
    const user = useReducer(userStore.store, s => s)

    const [windowHeight, setWindowHeight] = React.useState<number>(window.innerHeight)
    const [loading, setLoading] = React.useState<boolean>(true)
    const [logged, setLogged] = React.useState<boolean>(false)
    const [connectionError, setConnectionError] = React.useState<ConnectionError>()

    React.useLayoutEffect(() => {
        const updateHeight = () => setWindowHeight(window.innerHeight)
        window.addEventListener('resize', updateHeight)
        return () => window.removeEventListener('resize', updateHeight)
    }, [setWindowHeight])

    React.useEffect(() => {
        if (loading) {
            Azure.assureToken('server')
                .then(() => {
                    setLogged(true)

                    api.login
                        .login()
                        .then(user => {
                            setConnectionError(undefined)
                            userStore.actions.setUser(user)
                            setLoading(false)
                        })
                        .catch(err => {
                            // Azure.logout()
                            setConnectionError('unknownUser')
                            Logger.info("Erreur de récupération de l'utilisateur", err)
                            setLoading(false)
                        })
                    SplashScreen.hide()
                })
                .catch(err => {
                    Logger.debug(err)

                    setLoading(false)
                    SplashScreen.hide()
                })
        }
    }, [loading])

    if (loading) {
        return <Loading />
    }

    const isPublic = (path: string): boolean => !!routesPubliques.find(r => r.path === path)

    return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        <Router>
            <Route
                render={({ location }) => {
                    return logged && user ? (
                        <GlobalContainer>
                            {!isPublic(location.pathname) && <Menu height={windowHeight} />}
                            <Switch>
                                {routes.map(r => (
                                    <Route
                                        exact
                                        key={r.name}
                                        path={r.path}
                                        render={screenProps => {
                                            const pathParams = screenProps.match.params || {}
                                            const params = screenProps.location.state || {}
                                            return (
                                                <Content>
                                                    <Header routeName={r.name} />
                                                    <WebLayout height={windowHeight}>
                                                        {/*//eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                                                        //@ts-ignore*/}
                                                        <r.component {...params} {...r.params} {...pathParams} />
                                                    </WebLayout>
                                                </Content>
                                            )
                                        }}
                                    />
                                ))}
                                {routesPubliques.map(r => (
                                    <Route
                                        exact
                                        key={r.name}
                                        path={r.path}
                                        render={screenProps => {
                                            const pathParams = screenProps.match.params || {}
                                            const queryParams = screenProps.location.search
                                            return (
                                                <Content>
                                                    {/*//eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                                                        //@ts-ignore*/}
                                                    <r.component {...pathParams} {...queryParams} {...r.params} />
                                                </Content>
                                            )
                                        }}
                                    />
                                ))}
                                <Route render={() => <Redirect to="/" />} />
                            </Switch>
                        </GlobalContainer>
                    ) : (
                        <PublicContainer>
                            <Switch>
                                {routesPubliques.map(r => (
                                    <Route
                                        exact
                                        key={r.name}
                                        path={r.path}
                                        render={screenProps => {
                                            const params = screenProps.match.params || {}
                                            const queryParams = screenProps.location.search
                                            return (
                                                <Content>
                                                    {/*//eslint-disable-next-line @typescript-eslint/ban-ts-ignore
                                                        //@ts-ignore*/}
                                                    <r.component {...params} {...queryParams} />
                                                </Content>
                                            )
                                        }}
                                    />
                                ))}
                                <Route render={() => <Login connectionError={connectionError} />} />
                            </Switch>
                        </PublicContainer>
                    )
                }}
            />
        </Router>
    )
}

const GlobalContainer = styled(View)`
    display: flex;
    flex-direction: row;
    flex: 1;
`

const PublicContainer = styled(View)`
    display: flex;
    flex: 1;
`

const Content = styled(View)`
    flex: 1;
    display: flex;
    flex-direction: column;
`
const WebLayout = styled(View)<{ height: number }>`
    overflow-y: hidden;
    overflow-x: hidden;
    height: ${props => props.height - props.theme.constants.webHeaderHeight}px;
    padding-left: ${props => props.theme.constants.pageHorizontalPadding}px;
    padding-right: ${props => props.theme.constants.pageHorizontalPadding}px;
    padding-top: ${props => props.theme.constants.pageVerticalPadding}px;
    padding-bottom: ${props => props.theme.constants.pageVerticalPadding}px;
    background-color: ${props => props.theme.colors.contentBackground};
`
