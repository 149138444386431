import * as React from 'react'

// Components
import { View, Keyboard, Platform } from 'react-native'
import Modal from '@components/modal/Modal'
import { Formik, FormikErrors } from 'formik'
import TextInput from '@components/textInput/TextInput'
import Button from '@components/button/Button'
import ImageDropInput from '@components/imageDropInput/ImageFormInput'
import PdfInput from '@components/pdfDropInput/documentsIcpPdfInput'

// Utils
import { produce } from 'immer'

// Store
import useI18n from '@store/i18n/useI18n'

// Style
import styled from '@styles/index'

const ICP_DOCUMENT_MAX_PHOTOS = 10

const trimAllValues = (values: DocumentIcp): DocumentIcp => ({
    ...values,
    name: values.name.trim(),
})

interface Props {
    modalVisible: boolean
    onRequestClose: () => void
    onChange: (agencies: DocumentIcp[]) => void
    documents: DocumentIcp[]
    initialValues?: DocumentIcp
}

export default (props: Props) => {
    const { modalVisible, onRequestClose, onChange, documents, initialValues } = props
    const i18n = useI18n()

    const [keyboardShown, setKeyboardShown] = React.useState<boolean>(false)

    const formInitialValues = (!!initialValues
        ? Platform.OS === 'web'
            ? initialValues
            : {
                  ...initialValues,
                  pages: initialValues.pages.filter(document => !document.image.includes('pdf')) as Page[],
                  pdf: initialValues.pages.filter(document => document.image.includes('pdf')) as PdfAnnexe[],
              }
        : Platform.OS === 'web'
        ? {
              name: '',
              number: 0,
              pages: [],
          }
        : {
              name: '',
              number: 0,
              pages: [],
              pdf: [],
          }) as DocumentIcpTemp

    React.useEffect(() => {
        const onKeyboardDidShow = () => setKeyboardShown(true)
        const onKeyboardDidHide = () => setKeyboardShown(false)
        const listenerShow = Keyboard.addListener('keyboardDidShow', onKeyboardDidShow)
        const listenerHide = Keyboard.addListener('keyboardDidHide', onKeyboardDidHide)
        return () => {
            listenerShow.remove()
            listenerHide.remove()
        }
    }, [setKeyboardShown])

    const handleChange = (values: DocumentIcpTemp) => {
        const consolidatedAnnexValues = !!values.pdf ? { ...values, pages: [...values.pages, ...values.pdf] } : values
        const trimedValues = trimAllValues(consolidatedAnnexValues)
        const index = !!initialValues ? documents.indexOf(initialValues) : -1
        const result: DocumentIcp[] =
            !!initialValues && index > -1
                ? produce(documents, newDocuments => {
                      newDocuments[index] = trimedValues
                  })
                : [...documents, trimedValues]
        onChange(result)
        onRequestClose()
    }

    const validateForm = React.useMemo(
        () => (values: DocumentIcp): FormikErrors<DocumentIcp> => {
            const trimedValues = trimAllValues(values)
            const errors: FormikErrors<DocumentIcp> = {}
            if (!trimedValues.name) {
                errors.name = i18n.t('screens.addUserAdminScreen.required')
            }
            return errors
        },
        [i18n],
    )

    return (
        <Modal visible={modalVisible} onRequestClose={onRequestClose}>
            <ModalContent keyboardShown={keyboardShown}>
                <Formik initialValues={formInitialValues} onSubmit={handleChange} validate={validateForm}>
                    {({ errors, values, handleSubmit, isValid, setFieldValue, isSubmitting, setValues }) => (
                        <View>
                            <Section zIndex={0}>
                                <ModalRow>
                                    <FlexContainer>
                                        <TextInput
                                            value={values.name}
                                            onTextChange={text => setFieldValue('name', text)}
                                            label={i18n.t(`components.addIcpDocuments.fields.name`)}
                                            required={true}
                                            error={errors && errors.name}
                                            placeholder={i18n.t(`components.addIcpDocuments.placeholders.name`)}
                                        />
                                    </FlexContainer>
                                </ModalRow>
                                <ImageDropInput
                                    label={i18n.t(`components.addIcpDocuments.fields.pages`, {
                                        max: ICP_DOCUMENT_MAX_PHOTOS,
                                    })}
                                    values={values.pages.map(page => page.image)}
                                    onChange={newImages =>
                                        setFieldValue(
                                            'pages',
                                            newImages.map(img => ({ image: img })),
                                        )
                                    }
                                    maxPhotos={ICP_DOCUMENT_MAX_PHOTOS}
                                />
                                <TopMarginView>
                                    <PdfInput
                                        values={!!values.pdf ? values.pdf : values.pages}
                                        onPdfChange={(newDocuments: PdfAnnexe[]) => setFieldValue('pdf', newDocuments)}
                                    />
                                </TopMarginView>
                            </Section>

                            <ButtonsContainer zIndex={-5}>
                                <Button
                                    libelle={i18n.t('components.addIcpAgencies.add')}
                                    status={isSubmitting ? 'loading' : isValid ? 'active' : 'disabled'}
                                    onPress={handleSubmit}
                                />
                            </ButtonsContainer>
                        </View>
                    )}
                </Formik>
            </ModalContent>
        </Modal>
    )
}

const ModalContent = styled(View)<{ keyboardShown: boolean }>`
    background-color: ${props => props.theme.colors.documentsModalBackground};
    padding: 5px;
    flex: 1;
`
const ModalRow = styled(View)<{ zIndex?: number }>`
    flex-direction: row;
    margin-bottom: 12px;
    ${props => `z-index: ${props.zIndex}`};
`
const ButtonsContainer = styled(View)<{ zIndex: number }>`
    flex-direction: row;
    justify-content: space-evenly;
    z-index: ${props => props.zIndex};
`
const FlexContainer = styled(View)`
    flex: 1;
`
const Section = styled(View)<{ zIndex: number }>`
    margin-bottom: 24px;
    z-index: ${props => props.zIndex};
`
const TopMarginView = styled(View)`
    margin-top: 10px;
`
