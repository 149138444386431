import configuration from '@app/config'

// const PREFIX = (Platform.OS === 'web' ? '' : configuration.SERVER_PREFIX) + '/services/v1'
const PREFIX = configuration.SERVER_PREFIX + '/services/v1'

export const apiPublic = {
    icp: {
        getInfosApprobationIcp: (token: string) =>
            fetch(`${PREFIX}/public/icp`, {
                method: 'GET',
                headers: {
                    'X-Api-Key': token,
                    Accept: 'application/json',
                },
            })
                .then(response => {
                    if (response.status === 200) {
                        return response.json()
                    } else return 'invalid_token'
                })
                .catch(error => {
                    return error
                }),
        responseIcp: (response: boolean, token: string) =>
            fetch(`${PREFIX}/public/icp/response?accept=${response}`, {
                method: 'POST',
                headers: {
                    'X-Api-Key': token,
                    Accept: 'application/json',
                },
            }),
    },
}
