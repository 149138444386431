import * as React from 'react'

// Components
import { View, Text } from 'react-native'
import Picker from '@components/picker/Picker'
import TextInput from '@components/textInput/TextInput'
import Icon from '@components/icon/Icon'
import Button from '@components/button/Button'
import Checkbox from '@components/checkbox/Checkbox'
import RootScreen from '@components/rootScreen/RootScreen'
import { Formik, FormikErrors } from 'formik'
import Alert from '@components/alert/Alert'

import useI18n from '@store/i18n/useI18n'

// Utils
import Logger from '@utils/logger'
import { findUserInUserPerimeters, getFullUserPerimeterFromPerimeterID } from '@utils/perimeterUtils'
import useNavigation from '@layout/useNavigation'

// Api
import api from '@api/api'

// Style
import styled from '@styles/index'
import useTheme from '@styles/useTheme'

const trimValues = (values: CreateModifyUser) => ({
    ...values,
    firstName: values.firstName.trim(),
    lastName: values.lastName.trim(),
    email: values.email.trim(),
})

type CreateModifyUserRequiredFields = 'firstName' | 'lastName' | 'email' | 'idRole'

const requiredFields: CreateModifyUserRequiredFields[] = ['lastName', 'firstName', 'email', 'idRole']

interface Props {
    userId?: string
}

export default (props: Props) => {
    const { userId } = props
    const i18n = useI18n()
    const navigation = useNavigation()
    const [Theme] = useTheme()

    const [status, setStatus] = React.useState<Status>('loading')
    const [perimeters, setPerimeters] = React.useState<PerimeterUser[] | undefined>(undefined)

    const [direction, setDirection] = React.useState<PerimeterUser | undefined>(undefined)
    const [structure, setStructure] = React.useState<PerimeterUser | undefined>(undefined)
    const [perimeter, setPerimeter] = React.useState<PerimeterUser | undefined>(undefined)
    const [sector, setSector] = React.useState<PerimeterUser | undefined>(undefined)
    const [roles, setRoles] = React.useState<RoleList | undefined>(undefined)
    const [initialValues, setInitialValues] = React.useState<CreateModifyUser | undefined>(undefined)
    const [foundUser, setFoundUser] = React.useState<User | undefined>(undefined)

    React.useEffect(() => {
        Promise.all([api.admin.getUserListByPerimeter(), api.admin.getRoleList()])
            .then(([perimeters, roles]) => {
                setPerimeters(perimeters[0].subPerimeterUser)
                setRoles(roles)
                // En mode modification d'utilisateur
                if (!!userId) {
                    const foundUser = findUserInUserPerimeters(userId, perimeters)
                    if (!!foundUser) {
                        setFoundUser(foundUser)
                        setInitialValues(foundUser)
                        if (foundUser.idPerimeter) {
                            const initPerimeters = getFullUserPerimeterFromPerimeterID(
                                foundUser.idPerimeter,
                                perimeters,
                            )
                            if (initPerimeters.length === 0) {
                                setStatus('error')
                            } else {
                                initPerimeters.length > 0 && setDirection(initPerimeters[1])
                                initPerimeters.length > 1 && setStructure(initPerimeters[2])
                                initPerimeters.length > 2 && setPerimeter(initPerimeters[3])
                                initPerimeters.length > 3 && setSector(initPerimeters[4])
                            }
                        }
                        setStatus('fetched')
                    } else {
                        setStatus('error')
                    }
                } else {
                    setStatus('fetched')
                }
            })
            .catch(err => {
                Logger.error(err)
                setStatus('error')
            })
    }, [userId])

    const changeUserActivation = (user: User) => {
        const key = !user.active ? 'Activation' : 'Deactivation'
        Alert({
            title: i18n.t(`screens.editUserAdminScreen.alerts.confirm${key}Title`),
            content: i18n.t(`screens.editUserAdminScreen.alerts.confirm${key}Content`),
            icon: 'question',
            buttons: [
                {
                    style: 'default',
                    text: i18n.t('screens.perimetersAdminScreen.alerts.confirmButton'),
                    onPress: () =>
                        api.admin
                            .modifyUserActivation(user.id, !user.active)
                            .then(() =>
                                Alert({
                                    title: i18n.t(`screens.editUserAdminScreen.alerts.success${key}Title`),
                                    content: i18n.t(`screens.editUserAdminScreen.alerts.success${key}Content`),
                                    icon: 'success',
                                    buttons: [
                                        {
                                            style: 'default',
                                            text: i18n.t('screens.perimetersAdminScreen.alerts.confirmButton'),
                                            onPress: () =>
                                                foundUser && setFoundUser({ ...foundUser, active: !foundUser.active }),
                                        },
                                    ],
                                    theme: Theme,
                                }),
                            )
                            .catch(err => {
                                Alert({
                                    title: i18n.t(`screens.editUserAdminScreen.alerts.error${key}Title`),
                                    content: i18n.t(`screens.editUserAdminScreen.alerts.error${key}Content`),
                                    icon: 'error',
                                    buttons: [
                                        {
                                            style: 'default',
                                            text: i18n.t('screens.perimetersAdminScreen.alerts.confirmButton'),
                                            onPress: () => undefined,
                                        },
                                    ],
                                    theme: Theme,
                                })
                                Logger.error(err)
                            }),
                },
                {
                    style: 'cancel',
                    text: i18n.t('screens.perimetersAdminScreen.alerts.cancelButton'),
                    onPress: () => undefined,
                },
            ],
            cancelable: true,
            theme: Theme,
        })
    }

    const handleSubmit = (values: CreateModifyUser, setSubmitting: (isSubmitting: boolean) => void) => {
        const trimedValues = trimValues(values)

        const i18nScreen = !userId ? 'addUserAdminScreen' : 'editUserAdminScreen'

        Alert({
            title: i18n.t(`screens.${i18nScreen}.alerts.confirmationTitle`),
            content: i18n.t(`screens.${i18nScreen}.alerts.confirmationContent`),
            icon: 'question',
            buttons: [
                {
                    style: 'default',
                    text: i18n.t('screens.perimetersAdminScreen.alerts.confirmButton'),
                    onPress: () => {
                        ;(!userId ? api.admin.addUser(trimedValues) : api.admin.editUser(trimedValues, userId))
                            .then(() => {
                                Alert({
                                    title: i18n.t(`screens.${i18nScreen}.alerts.successTitle`),
                                    content: i18n.t(`screens.${i18nScreen}.alerts.successContent`),
                                    icon: 'success',
                                    buttons: [
                                        {
                                            style: 'default',
                                            text: 'OK',
                                            onPress: () => (!!userId ? undefined : navigation.push('/utilisateurs')),
                                        },
                                    ],
                                    cancelable: true,
                                    theme: Theme,
                                })
                            })
                            .catch(err => {
                                Alert({
                                    title: i18n.t(`screens.${i18nScreen}.alerts.errorTitle`),
                                    content: i18n.t(
                                        `screens.${i18nScreen}.alerts.${
                                            err.message.startsWith('400') ? 'errorEmail' : 'errorContent'
                                        }`,
                                    ),
                                    icon: 'error',
                                    buttons: [
                                        {
                                            style: 'default',
                                            text: 'OK',
                                            onPress: () => undefined,
                                        },
                                    ],
                                    theme: Theme,
                                })
                                Logger.error(err)
                            })
                            .finally(() => setSubmitting(false))
                    },
                },
                {
                    style: 'cancel',
                    text: i18n.t('screens.perimetersAdminScreen.alerts.cancelButton'),
                    onPress: () => setSubmitting(false),
                },
            ],
            cancelable: true,
            theme: Theme,
        })
    }

    const validateForm = (values: CreateModifyUser): FormikErrors<CreateModifyUser> => {
        const trimedValues = trimValues(values)
        const errors: FormikErrors<CreateModifyUser> = {}

        requiredFields.map(
            fieldName =>
                !trimedValues[fieldName] && (errors[fieldName] = i18n.t('screens.addUserAdminScreen.required')),
        )

        if (!trimedValues.email.match(/^[a-z0-9](?:[.+-]?\w+)*@[a-z0-9]+(?:[.-]?\w+)*$/i)) {
            errors.email = i18n.t('screens.addUserAdminScreen.emailError')
        }

        return errors
    }

    return (
        <RootScreen status={status} scrollable>
            <Formik
                initialValues={
                    initialValues ||
                    ({
                        firstName: '',
                        lastName: '',
                        email: '',
                        idRole: '',
                        admin: false,
                    } as CreateModifyUser)
                }
                onSubmit={(values, { setSubmitting }) => handleSubmit(values, setSubmitting)}
                validate={validateForm}
            >
                {({ values, errors, setFieldValue, handleSubmit }) => {
                    const selectedRole = roles && roles.find(r => r.id === values.idRole)
                    return (
                        perimeters && (
                            <View>
                                <SectionContainer zIndex={0}>
                                    <InformationCategoryName>
                                        {i18n.t('screens.addUserAdminScreen.generalInformation')}
                                    </InformationCategoryName>
                                    <FieldLine>
                                        <FieldContainer left>
                                            <TextInput
                                                label={i18n.t('screens.addUserAdminScreen.fieldLabels.lastName')}
                                                value={values.lastName}
                                                onTextChange={text => setFieldValue('lastName', text)}
                                                required
                                                error={errors.lastName}
                                                placeholder={i18n.t('screens.addUserAdminScreen.placeHolder.lastName')}
                                            />
                                        </FieldContainer>
                                        <FieldContainer>
                                            <TextInput
                                                label={i18n.t('screens.addUserAdminScreen.fieldLabels.firstName')}
                                                value={values.firstName}
                                                onTextChange={text => setFieldValue('firstName', text)}
                                                required
                                                error={errors.firstName}
                                                placeholder={i18n.t('screens.addUserAdminScreen.placeHolder.firstName')}
                                            />
                                        </FieldContainer>
                                    </FieldLine>
                                    <FieldLine>
                                        <FieldContainer left>
                                            <TextInput
                                                label={i18n.t('screens.addUserAdminScreen.fieldLabels.email')}
                                                value={values.email}
                                                onTextChange={text => setFieldValue('email', text)}
                                                required
                                                error={errors.email}
                                                placeholder={i18n.t('screens.addUserAdminScreen.placeHolder.email')}
                                            />
                                        </FieldContainer>
                                        <FieldContainer>
                                            {!!foundUser && (
                                                <Checkbox
                                                    value={foundUser.active}
                                                    onCheck={() => changeUserActivation(foundUser)}
                                                    label={i18n.t('screens.editUserAdminScreen.active')}
                                                />
                                            )}
                                        </FieldContainer>
                                    </FieldLine>
                                </SectionContainer>
                                <SectionContainer zIndex={-1}>
                                    <InformationCategoryName>
                                        {i18n.t('screens.addUserAdminScreen.perimeter')}
                                    </InformationCategoryName>
                                    <FieldLine zIndex={0}>
                                        <FieldContainer left>
                                            <Picker
                                                data={perimeters.map(p => p.libelle)}
                                                value={!!direction ? direction.libelle : undefined}
                                                onChange={index => {
                                                    setDirection(perimeters[index])
                                                    setStructure(undefined)
                                                    setPerimeter(undefined)
                                                    setSector(undefined)
                                                    setFieldValue(
                                                        'idPerimeter',
                                                        index > -1 ? perimeters[index].idPerimeter : undefined,
                                                    )
                                                }}
                                                label={i18n.t('screens.perimetersAdminScreen.pickerLabels.direction')}
                                                isClearable
                                                error={errors.idPerimeter}
                                            />
                                        </FieldContainer>
                                        <FieldContainer>
                                            <Picker
                                                data={!!direction ? direction.subPerimeterUser.map(p => p.libelle) : []}
                                                value={!!structure ? structure.libelle : undefined}
                                                onChange={index => {
                                                    if (!!direction) {
                                                        setStructure(direction.subPerimeterUser[index])
                                                        setPerimeter(undefined)
                                                        setSector(undefined)

                                                        setFieldValue(
                                                            'idPerimeter',
                                                            index > -1
                                                                ? direction.subPerimeterUser[index].idPerimeter
                                                                : direction.idPerimeter,
                                                        )
                                                    }
                                                }}
                                                label={i18n.t('screens.perimetersAdminScreen.pickerLabels.structure')}
                                                disabled={!direction}
                                                isClearable
                                            />
                                        </FieldContainer>
                                    </FieldLine>
                                    <FieldLine zIndex={-1}>
                                        <FieldContainer left>
                                            <Picker
                                                data={!!structure ? structure.subPerimeterUser.map(p => p.libelle) : []}
                                                value={!!perimeter ? perimeter.libelle : undefined}
                                                onChange={index => {
                                                    if (!!structure) {
                                                        setPerimeter(structure.subPerimeterUser[index])
                                                        setSector(undefined)

                                                        setFieldValue(
                                                            'idPerimeter',
                                                            index > -1
                                                                ? structure.subPerimeterUser[index].idPerimeter
                                                                : structure.idPerimeter,
                                                        )
                                                    }
                                                }}
                                                label={i18n.t('screens.perimetersAdminScreen.pickerLabels.perimeter')}
                                                disabled={!structure}
                                                isClearable
                                            />
                                        </FieldContainer>
                                        <FieldContainer>
                                            <Picker
                                                data={!!perimeter ? perimeter.subPerimeterUser.map(p => p.libelle) : []}
                                                value={!!sector ? sector.libelle : undefined}
                                                onChange={index => {
                                                    if (!!perimeter) {
                                                        setSector(perimeter.subPerimeterUser[index])
                                                        setFieldValue(
                                                            'idPerimeter',
                                                            index > -1
                                                                ? perimeter.subPerimeterUser[index].idPerimeter
                                                                : perimeter.idPerimeter,
                                                        )
                                                    }
                                                }}
                                                label={i18n.t('screens.perimetersAdminScreen.pickerLabels.level')}
                                                disabled={!perimeter}
                                                isClearable
                                            />
                                        </FieldContainer>
                                    </FieldLine>
                                </SectionContainer>
                                <SectionContainer zIndex={-2}>
                                    <InformationCategoryName>
                                        {i18n.t('screens.addUserAdminScreen.role')}
                                    </InformationCategoryName>
                                    <FieldLine zIndex={0}>
                                        <FieldContainer left>
                                            <Picker
                                                data={roles ? roles.map(r => r.name) : []}
                                                value={!!selectedRole ? selectedRole.name : undefined}
                                                onChange={index =>
                                                    !!roles &&
                                                    setFieldValue('idRole', index !== -1 ? roles[index].id : undefined)
                                                }
                                                label={i18n.t('screens.usersAdminScreen.pickerLabels.roles')}
                                                required
                                                isClearable
                                                error={errors.idRole}
                                            />
                                        </FieldContainer>
                                        <FieldContainer>
                                            <Checkbox
                                                value={values.admin}
                                                onCheck={() => setFieldValue('admin', !values.admin)}
                                                label={i18n.t('screens.addUserAdminScreen.admin')}
                                            />
                                        </FieldContainer>
                                    </FieldLine>
                                </SectionContainer>
                                <ButtonContainer zIndex={-3}>
                                    <Button
                                        libelle={i18n.t(
                                            `screens.usersAdminScreen.${!!userId ? 'editUserButton' : 'addUserButton'}`,
                                        )}
                                        status={'active'}
                                        onPress={handleSubmit}
                                        width={250}
                                    >
                                        <ButtonContent>
                                            {!userId && (
                                                <Icon name={'plus'} color={Theme.colors.buttonTitleClassic} size={15} />
                                            )}
                                            <ButtonTitle>
                                                {i18n.t(
                                                    `screens.usersAdminScreen.${
                                                        !!userId ? 'editUserButton' : 'addUserButton'
                                                    }`,
                                                )}
                                            </ButtonTitle>
                                        </ButtonContent>
                                    </Button>
                                </ButtonContainer>
                            </View>
                        )
                    )
                }}
            </Formik>
        </RootScreen>
    )
}

const InformationCategoryName = styled(Text)`
    padding-left: 24px;
    margin-bottom: 10px;
    ${props => props.theme.fonts.roleRights}
    color: ${props => props.theme.colors.rightCategory};
    text-transform: uppercase;
`
const SectionContainer = styled(View)<{ zIndex: number }>`
    z-index: ${props => props.zIndex};
`
const FieldLine = styled(View)<{ zIndex?: number }>`
    flex-direction: row;
    margin-bottom: 20px;
    ${props => `z-index: ${props.zIndex};`}
    align-items: center;
`
const FieldContainer = styled(View)<{ left?: boolean }>`
    flex: 1;
    ${props => props.left && 'margin-right: 40px;'}
`
const ButtonContainer = styled(View)<{ zIndex?: number }>`
    flex-direction: row;
    justify-content: center;
    ${props => `z-index: ${props.zIndex};`}
`
const ButtonContent = styled(View)`
    flex-direction: row;
    align-items: center;
`
const ButtonTitle = styled(Text)`
    ${props => props.theme.fonts.buttonTitle}
    text-transform: uppercase;
    margin-left: 10px;
`
