import { Store, createEvent } from 'effector'
import * as user from './user'
import * as icp from './icp'
import * as icpInfos from './icpInfos'
import * as synchro from './synchro'
import * as appVersion from './version'
import Storage from './storage'

export const persist = (): Promise<void> => {
    const VERSION = '1'
    const VERSION_KEY = 'persist:version'
    return Storage.getItem(VERSION_KEY).then(version => {
        return (version === VERSION
            ? Promise.resolve(true)
            : Storage.setItem(VERSION_KEY, VERSION).then(() => false)
        ).then(reload => {
            // inspiré de https://github.com/MrFranke/effector-persist/blob/master/src/index.ts
            const rehydrate = createEvent('REHYDRATE')
            const persistStore = <State>(store: Store<State>): Promise<void> => {
                const name = store.shortName
                const persistKey = `persist:${name}`

                return (reload
                    ? Storage.getItem(persistKey).then(snapshot => {
                          if (snapshot) {
                              store.on(rehydrate, () => JSON.parse(snapshot))
                              rehydrate()
                          }
                      })
                    : Promise.resolve()
                ).then(() => {
                    store.watch(state => {
                        Storage.setItem(persistKey, JSON.stringify(state))
                    })
                })
            }

            return Promise.all([
                persistStore(user.store),
                persistStore(icp.store),
                persistStore(icpInfos.store),
                persistStore(synchro.store),
                persistStore(appVersion.store),
            ]).then()
        })
    })
}

export const reset = () => {
    user.actions.reset()
    icp.actions.reset()
    icpInfos.actions.reset()
    synchro.actions.reset()
}
