import * as React from 'react'
import useI18n from '@store/i18n/useI18n'

import { getDownload } from '@utils/downloadUtils.web'

import UnknownRiskMeasureComponent from '@components/unknownRisksMeasuresScreen/UnknownRiskMeasureComponent'

export default () => {
    const i18n = useI18n()
    const [isExporting, setIsExporting] = React.useState<ButtonStatus>('active')

    const handleExport = () => {
        const fileName = `Risks_Measures_${i18n.t('common.date', { date: new Date() })}`
        setIsExporting('loading')
        getDownload(fileName)
            .then(() => setIsExporting('active'))
            .catch(() => setIsExporting('active'))
    }

    return <UnknownRiskMeasureComponent handleExport={handleExport} isExporting={isExporting} />
}
