import * as React from 'react'

// Components
import { View, Image, Text } from 'react-native'
import { TouchableItemFeedback } from '@components/touchable/Touchable'
import Icon from '@components/icon/Icon'

// Style
import styled from '@styles/index'
import useI18n from '@store/i18n/useI18n'
import useNavigation from '@layout/useNavigation'

// Store
import useReducer from '@store/useReducer'
import * as userStore from '@store/user'
import * as CustomInterfaceStore from '@store/customInterfaceStore'

import menuSections from './menuSections'
import useTheme from '@styles/useTheme'

interface Props {
    height: number
}

export default (props: Props) => {
    const navigation = useNavigation()
    const i18n = useI18n()
    const user = useReducer(userStore.store, s => s)
    const [Theme] = useTheme()

    const locationPathname = navigation.getLocation().pathname
    const initialOpenedSection = menuSections.find(
        section =>
            section.type === 'subSections' &&
            section.subSections.find(sub => sub.type === 'pushTo' && locationPathname === sub.screenPath),
    )
    const initialOpenedSectionTitle = initialOpenedSection ? initialOpenedSection.title : undefined
    const [openedMenuSection, setOpenedMenuSection] = React.useState<string | undefined>(initialOpenedSectionTitle)
    const [menuOpened, setMenuOpened] = React.useState<boolean>(false)

    const logo = useReducer(CustomInterfaceStore.store, s => s.menuLogo)

    const initOpenedSection = () => {
        const locationPathname = navigation.getLocation().pathname
        const initialOpenedSection = menuSections.find(
            section =>
                section.type === 'subSections' &&
                section.subSections.find(sub => sub.type === 'pushTo' && locationPathname === sub.screenPath),
        )
        if (!!initialOpenedSection) {
            setOpenedMenuSection(initialOpenedSection.title)
        }
    }

    React.useEffect(initOpenedSection, [])

    const handleSectionClicked = (section: MenuSection) => {
        setOpenedMenuSection(openedMenuSection === section.title ? undefined : section.title)
        section.type === 'pushTo' && navigation.push(section.screenPath)
    }

    const handleSubSectionClicked = (screenPath: string) => navigation.push(screenPath)

    const sectionCanBeShown = (section: MenuSection): boolean =>
        // TODO : prévoir l'affichage de la section s'il est associé à un droit
        !section.rightRequired && ((user && user.admin) || !section.adminOnly)

    const currentLocation = navigation.getLocation().pathname

    if (menuOpened) {
        return (
            <Container height={props.height}>
                <TouchableItemFeedback
                    onPress={() => {
                        navigation.push('/')
                        setOpenedMenuSection(undefined)
                    }}
                >
                    <LogoContainer>
                        <Logo resizeMode="contain" source={{ uri: logo }} />
                    </LogoContainer>
                </TouchableItemFeedback>
                <TouchableItemFeedback onPress={() => setMenuOpened(false)}>
                    <LogoMenuContainer menuOpened>
                        <Icon
                            name={'cross'}
                            size={Theme.constants.webMenuIconSize}
                            color={Theme.colors.webMenuIconColor}
                        />
                    </LogoMenuContainer>
                </TouchableItemFeedback>
                {menuSections.map(
                    section =>
                        sectionCanBeShown(section) && (
                            <View key={section.title}>
                                <TouchableItemFeedback onPress={() => handleSectionClicked(section)}>
                                    <SectionContent
                                        screenSelected={
                                            section.type === 'pushTo' && section.screenPath === currentLocation
                                        }
                                    >
                                        <SectionIcon>
                                            <Icon
                                                name={section.iconName}
                                                size={Theme.constants.webMenuSectionIconSize}
                                            />
                                        </SectionIcon>
                                        <MenuSectionTitle>
                                            {i18n.t(`components.menu.menuTitles.${section.title}`)}
                                        </MenuSectionTitle>
                                    </SectionContent>
                                </TouchableItemFeedback>
                                {openedMenuSection === section.title &&
                                    section.type === 'subSections' &&
                                    section.subSections.map(subSection => (
                                        <TouchableItemFeedback
                                            key={subSection.title}
                                            onPress={() =>
                                                subSection.type === 'pushTo' &&
                                                handleSubSectionClicked(subSection.screenPath)
                                            }
                                        >
                                            <SubSectionContent
                                                selected={
                                                    subSection.type === 'pushTo' &&
                                                    currentLocation === subSection.screenPath
                                                }
                                            >
                                                <MenuSubSectionTitle>
                                                    {i18n.t(`components.menu.menuTitles.${subSection.title}`)}
                                                </MenuSubSectionTitle>
                                                <Icon
                                                    name={'chevron-right'}
                                                    size={Theme.constants.webMenuSubSectionIconSize}
                                                    color={Theme.fonts.menuTitles.color}
                                                />
                                            </SubSectionContent>
                                        </TouchableItemFeedback>
                                    ))}
                            </View>
                        ),
                )}
            </Container>
        )
    } else {
        return (
            <Container menuClosed height={props.height}>
                <TouchableItemFeedback onPress={() => setMenuOpened(true)}>
                    <LogoMenuContainer>
                        <Icon
                            name={'menu'}
                            size={Theme.constants.webMenuIconSize}
                            color={Theme.colors.webMenuIconColor}
                        />
                    </LogoMenuContainer>
                </TouchableItemFeedback>
            </Container>
        )
    }
}

const Container = styled(View)<{ height: number; menuClosed?: boolean }>`
    /* overflow-y: scroll; */
    /* overflow-x: hidden; */
    height: ${props => props.height}px;
    width: ${props => (props.menuClosed ? 23 : props.theme.constants.webMenuWidth)}px;
    flex-direction: column;
    background-color: ${props => props.theme.colors.menuBackground};
    z-index: 1;
`
const LogoContainer = styled(View)`
    height: ${props => props.theme.constants.menuLogoHeight}px;
    width: ${props => props.theme.constants.webMenuWidth - 2 * props.theme.constants.webMenuLogoHorizontalPadding}px;
    margin-top: ${props => props.theme.constants.webMenuLogoVerticalPadding}px;
    margin-bottom: ${props => props.theme.constants.webMenuLogoVerticalPadding}px;
    margin-left: ${props => props.theme.constants.webMenuLogoHorizontalPadding}px;
    margin-right: ${props => props.theme.constants.webMenuLogoHorizontalPadding}px;
    align-items: center;
    justify-content: center;
`
const Logo = styled(Image)`
    height: 100%;
    width: 100%;
    align-self: center;
`

const SectionContent = styled(View)<{ screenSelected: boolean }>`
    height: ${props => props.theme.constants.webMenuSectionHeight}px;
    flex-direction: row;
    align-items: center;
    padding-left: ${props => props.theme.constants.webMenuSectionHorizontalPadding}px;
    padding-right: ${props => props.theme.constants.webMenuSectionHorizontalPadding}px;
    &:hover {
        background-color: white;
        & > div {
            color: ${props => props.theme.colors.webMenuTitleHover};
        }
    }
    ${props =>
        props.screenSelected &&
        `background-color: white;
        & > div {
            color: ${props.theme.colors.webMenuTitleHover};
        }`}
`
const MenuSectionTitle = styled(Text)`
    ${props => props.theme.fonts.menuTitles}
`
const SectionIcon = styled(View)`
    ${props => props.theme.fonts.menuTitles}
    margin-right: ${props => props.theme.constants.webMenuSectionHorizontalPadding}px;
`
const SubSectionContent = styled(View)<{ selected?: boolean }>`
    min-height: ${props => props.theme.constants.webMenuSubSectionHeight}px;
    flex-direction: row;
    align-items: center;
    padding-left: ${props => props.theme.constants.webMenuSubItemLeftPadding}px;
    padding-right: ${props => props.theme.constants.webMenuSubItemRightPadding}px;
    &:hover {
        background-color: white;
        & > div {
            color: ${props => props.theme.colors.webMenuTitleHover};
        }
    }
    ${props =>
        props.selected &&
        `background-color: white;
        & > div {
            color: ${props.theme.colors.webMenuTitleHover};
        }`}
`
const MenuSubSectionTitle = styled(Text)`
    ${props => props.theme.fonts.menuSubTitles};
    flex: 1;
`
const LogoMenuContainer = styled(View)<{ menuOpened?: boolean }>`
    height: 35px;
    width: 35px;
    align-items: center;
    justify-content: center;

    border-radius: 50px;
    background-color: ${props => props.theme.colors.iconInversedColor};
    border: ${props => props.theme.colors.iconClassicColor} 2px solid;

    position: fixed;
    top: 12px;
    left: ${props => (props.menuOpened ? 285 : 5)}px;

    &:hover {
        background-color: ${props => props.theme.colors.iconClassicColor};
        border: ${props => props.theme.colors.iconInversedColor} 2px solid;

        & > div {
            color: ${props => props.theme.colors.iconInversedColor};
        }
    }
`
