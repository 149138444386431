import * as React from 'react'
import { ScrollView, View, Text, Platform, RefreshControl, TouchableOpacity } from 'react-native'
import Icon from '@components/icon/Icon'

import styled from '@styles/index'
import LottieView from 'lottie-react-native'
import useI18n from '@store/i18n/useI18n'
import useNavigation from '@layout/useNavigation'
import { NavigationActions } from '@app/ui/layout/NativeMenu'

// Gestion du réseau
import * as connection from '@utils/connection'

// Gestion des éléments non synchro
import useReducer from '@store/useReducer'
import * as synchroStore from '@store/synchro'
import SynchronizationFeature from './SynchronizationFeature'
import useTheme from '@styles/useTheme'

interface OwnProps {
    children: React.ReactNode
    status: Status
    launchScrollToTop?: boolean
    scrollable?: boolean
    customHeader?: JSX.Element
    pullToRefresh?: () => void
    refreshing?: boolean
    fixed?: boolean
    offlineDatas?: boolean
    synchronizationFeature?: boolean
}

export default ({
    children,
    status,
    scrollable,
    customHeader,
    launchScrollToTop,
    pullToRefresh,
    refreshing,
    fixed,
    offlineDatas,
    synchronizationFeature,
}: OwnProps) => {
    const i18n = useI18n()
    const [Theme] = useTheme()

    const navigation = useNavigation()
    const [activeNetwork, setActiveNetwork] = React.useState<boolean>(true)
    const [modalVisible, setModalVisible] = React.useState<boolean>(false)
    const ScrollRef = React.useRef<ScrollView | null>(null)

    const storeNonSyncIcpList = useReducer(synchroStore.store, s => s.icpsNonSynchro)

    NavigationActions.setNavigation(navigation)

    const onConnectionChange = ({ connected }: connection.TypeConnectionState) => {
        setActiveNetwork(connected)
    }

    React.useEffect(() => {
        connection.default.subscribe(onConnectionChange)
    }, [])

    React.useEffect(() => {
        if (ScrollRef.current && launchScrollToTop) {
            ScrollRef.current.scrollTo({ x: 0, y: 0, animated: true })
        }
    }, [launchScrollToTop])

    return (
        <ScreenContainer centeredContent={status !== 'fetched'} noNetwork={!activeNetwork || !!offlineDatas}>
            {(!activeNetwork || !!offlineDatas) && (
                <NoNetworkContainer>
                    <Icon name={'connection'} size={Theme.constants.noNetworkSize} color={Theme.colors.noNetworkIcon} />
                    <NoNetwork>
                        {!!offlineDatas
                            ? i18n.t('screens.connection.offlineDatas')
                            : i18n.t('screens.connection.noNetwork')}
                    </NoNetwork>
                </NoNetworkContainer>
            )}
            {!!synchronizationFeature && status !== 'loading' && storeNonSyncIcpList.length !== 0 && (
                <SynchronizationContainer onPress={() => setModalVisible(true)}>
                    <SynchronizationLibelle>
                        {i18n.t('screens.synchronizationFeature.buttonTitle', {
                            count: storeNonSyncIcpList.length,
                        })}
                    </SynchronizationLibelle>
                </SynchronizationContainer>
            )}

            {!!synchronizationFeature && status !== 'loading' && (
                <SynchronizationFeature
                    i18n={i18n}
                    modalVisible={modalVisible}
                    closeModal={() => setModalVisible(false)}
                    storeNonSyncIcpList={storeNonSyncIcpList}
                />
            )}
            {status === 'loading' ? (
                <LottieView source={require('@drawable/loadingAnimation_red.json')} autoPlay loop />
            ) : status === 'error' ? (
                Platform.OS === 'web' ? (
                    <WebNoNetworkContainer>
                        <LottieView source={require('@drawable/errorLoading.json')} autoPlay loop={false} />
                        <ErrorText>{i18n.t('screens.fetchingError')}</ErrorText>
                    </WebNoNetworkContainer>
                ) : !fixed && pullToRefresh ? (
                    <ScrollableNoNetworkContainer
                        ref={ScrollRef}
                        refreshControl={
                            <RefreshControl
                                refreshing={!!refreshing}
                                onRefresh={pullToRefresh}
                                colors={[Theme.colors.refreshLoadingColor]}
                            />
                        }
                        scrollIndicatorInsets={{ right: 1 }}
                    >
                        <LottieContainer>
                            <LottieView source={require('@drawable/errorLoading.json')} autoPlay loop={false} />
                        </LottieContainer>
                        <ErrorText>{i18n.t('screens.fetchingError')}</ErrorText>
                    </ScrollableNoNetworkContainer>
                ) : (
                    <FetchingErrorContainer>
                        <LottieContainer>
                            <LottieView source={require('@drawable/errorLoading.json')} autoPlay loop={false} />
                        </LottieContainer>
                        <ErrorText>{i18n.t('screens.fetchingError')}</ErrorText>
                    </FetchingErrorContainer>
                )
            ) : (
                <React.Fragment>
                    {customHeader}
                    {!fixed && (Platform.OS !== 'web' || scrollable) ? (
                        pullToRefresh ? (
                            <ScrollableScreenContent
                                ref={ScrollRef}
                                refreshControl={
                                    <RefreshControl
                                        refreshing={!!refreshing}
                                        onRefresh={pullToRefresh}
                                        colors={[Theme.colors.refreshLoadingColor]}
                                    />
                                }
                                scrollIndicatorInsets={{ right: 1 }}
                            >
                                {children}
                                {Platform.OS !== 'web' && <MobilePadding />}
                            </ScrollableScreenContent>
                        ) : (
                            <ScrollableScreenContent ref={ScrollRef} scrollIndicatorInsets={{ right: 1 }}>
                                {children}
                                {Platform.OS !== 'web' && <MobilePadding />}
                            </ScrollableScreenContent>
                        )
                    ) : (
                        <ScreenContent>{children}</ScreenContent>
                    )}
                </React.Fragment>
            )}
        </ScreenContainer>
    )
}
const NoNetwork = styled(Text)`
    color: ${props => props.theme.colors.noNetworkIcon};
    margin-left: 5px;
`
const NoNetworkContainer = styled(View)`
    width: 100%;
    height: ${props => `${props.theme.constants.noNetworkContainerHeight}px;`};
    background-color: ${props => props.theme.colors.noNetworkBackground};
    align-items: center;
    justify-content: center;
    flex-direction: row;
    position: absolute;
    top: 0;
    z-index: 3000;
`

const ErrorText = styled(Text)`
    ${props => props.theme.fonts.rootContainerError}
`
const ScreenContainer = styled(View)<{ centeredContent: boolean; noNetwork?: boolean }>`
    flex: 1;
    align-items: ${props => props.centeredContent && 'center'};
    justify-content: ${props => props.centeredContent && 'center'};
    ${props => props.noNetwork && `padding-top: ${props.theme.constants.noNetworkContainerHeight - 1}px;`}
`
const ScreenContent = styled(View)`
    flex: 1;
    padding: 10px;
`
const ScrollableScreenContent = styled(ScrollView)`
    padding: 10px;
`
const MobilePadding = styled(View)`
    height: 65px;
`
const FetchingErrorContainer = styled(View)`
    height: 100px;
`
const LottieContainer = styled(View)`
    height: 50px;
`
const ScrollableNoNetworkContainer = styled(ScrollView)`
    padding-top: 200px;
`
const WebNoNetworkContainer = styled(View)``
const SynchronizationContainer = styled(TouchableOpacity)`
    height: 50px;
    margin: 10px;
    border-radius: 15px;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.theme.colors.mobileHeaderBackground};
`
const SynchronizationLibelle = styled(Text)`
    ${props => props.theme.fonts.synchronizationLibelle}
`
