import * as React from 'react'

// Components
import { View, Platform } from 'react-native'
import Picker from '@components/picker/Picker'
import DatePicker from '@components/datePicker/DatePicker'

// Utils
import { FormikErrors } from 'formik'

// Store
import useI18n from '@store/i18n/useI18n'

// Style
import styled from '@styles/index'

const emptyUser: UserIcp = { id: '', firstName: '', lastName: '' }

interface PlanPpeeProps {
    values: UploadedPpee
    errors: FormikErrors<UploadedPpee>
    setFieldValue: (field: keyof UploadedPpee, value: any) => void
    redacteurs: UserRedacteur[]
    approbateurs: UserApprobateur[]
}

export default (props: PlanPpeeProps) => {
    const { values, errors, setFieldValue, redacteurs, approbateurs } = props
    const i18n = useI18n()

    return (
        <View>
            <Line zIndex={0}>
                <Picker
                    value={
                        values.redacteur.id ? `${values.redacteur.firstName} ${values.redacteur.lastName}` : undefined
                    }
                    data={redacteurs.map(r => `${r.firstName} ${r.lastName}`)}
                    onChange={index => setFieldValue('redacteur', index > -1 ? redacteurs[index] : emptyUser)}
                    placeholder={i18n.t('components.planIcp.placeholders.redacteur')}
                    label={i18n.t('components.planIcp.labels.redacteur')}
                    required={true}
                    error={errors && errors.redacteur && errors.redacteur.id}
                    isClearable={true}
                />
            </Line>
            <Line zIndex={-1}>
                <Picker
                    value={
                        values.approbateur && values.approbateur.id
                            ? `${values.approbateur.firstName} ${values.approbateur.lastName}`
                            : undefined
                    }
                    data={approbateurs.map(r => `${r.firstName} ${r.lastName}`)}
                    onChange={index => setFieldValue('approbateur', index > -1 ? approbateurs[index] : emptyUser)}
                    placeholder={i18n.t('components.planIcp.placeholders.approbateur')}
                    label={i18n.t('components.planIcp.labels.approbateur')}
                    required={true}
                    error={errors && errors.approbateur}
                    isClearable={true}
                />
            </Line>
            <Line zIndex={-2}>
                <DatePicker
                    label={i18n.t('components.planIcp.labels.datePpee')}
                    value={values.date ? new Date(values.date) : undefined}
                    onChange={date => setFieldValue('date', date)}
                    time={true}
                    required={true}
                    error={!!errors && errors.date}
                />
            </Line>
        </View>
    )
}

const Line = styled(View)<{ zIndex: number }>`
    z-index: ${props => props.zIndex};
    margin-bottom: ${Platform.OS === 'web' ? '24px' : '15px'};
`
