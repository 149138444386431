import * as React from 'react'

// Components
import { View, Text, Platform, ActivityIndicator } from 'react-native'
import Picker from '@components/picker/Picker'
import SearchInput from '@components/searchInput/SearchInput'
import { TouchableItemFeedback } from '@components/touchable/Touchable'
import Pagination from '@components/pagination/Pagination'

import useI18n from '@store/i18n/useI18n'

// Api
import api from '@api/api'

// Utils
import * as JsSearch from 'js-search'
import accentSanitizer from '@utils/accentSanitizer'

// Style
import styled from '@styles/index'
import Logger from '@utils/logger'
import useTheme from '@styles/useTheme'

//* INITIALISATION POUR RECHERCHE
const sanitizer = accentSanitizer()
let searchjs: JsSearch.Search

const initSearchJs = (documents: any[]) => {
    searchjs = new JsSearch.Search('id')
    searchjs.searchIndex = new JsSearch.UnorderedSearchIndex()
    searchjs.indexStrategy = new JsSearch.AllSubstringsIndexStrategy()
    searchjs.tokenizer = {
        tokenize: (text: string) => text.split(' '),
    }
    searchjs.sanitizer = sanitizer
    searchjs.addIndex('libelle')
    searchjs.addIndex('mail')
    searchjs.addIndex('adresse')
    searchjs.addDocuments(documents)
}

interface Props {
    renderAgency?: (a: Agence, i: number) => JSX.Element
    onAgencyPress?: (agency: Agence, companyName?: string) => void
    agencyByPage?: number
    filterFunction?: (agencies: Agence[]) => Agence[]
    setOfflineMode?: () => void
}

export default (props: Props) => {
    const { renderAgency, onAgencyPress, agencyByPage, filterFunction, setOfflineMode = () => null } = props
    const i18n = useI18n()
    const [Theme] = useTheme()

    const [entityLoading, setEntityLoading] = React.useState<boolean>(true)
    const [text, setText] = React.useState<string>('')

    const [allCompanies, setAllCompanies] = React.useState<Entreprises>([])
    const [companySelected, setCompanySelected] = React.useState<Entreprise | undefined>(undefined)

    const [allAgencies, setAllAgencies] = React.useState<Agences>([])
    const [agenciesToRender, setAgenciesToRender] = React.useState<Agences>([])

    // GESTION DES DONNÉES
    React.useEffect(() => {
        api.company
            .getAllCompanies()
            .then(companies => {
                setAllCompanies(companies.sort((a, b) => a.libelle.localeCompare(b.libelle)))
                setEntityLoading(false)
            })
            .catch(err => {
                Logger.error('Une erreur est survenue...', err)
                setOfflineMode()
                setEntityLoading(false)
            })
    }, [setOfflineMode])

    // AFFICHAGE
    const defaultRenderAgency = (a: Agence, i: number) => {
        return (
            <TouchableItemFeedback
                key={`agency${i}`}
                onPress={() =>
                    onAgencyPress ? onAgencyPress(a, companySelected ? companySelected.libelle : undefined) : undefined
                }
            >
                <AgencyContainer key={i} odd={i % 2 !== 0}>
                    <AgencyColumn>
                        <AgencyInformation main>{a.libelle}</AgencyInformation>
                    </AgencyColumn>
                    <AgencyColumn double>
                        <AgencyInformation>{a.mail}</AgencyInformation>
                        <AgencyInformation>{a.telephone}</AgencyInformation>
                        <AgencyInformation>{a.adresse}</AgencyInformation>
                    </AgencyColumn>
                </AgencyContainer>
            </TouchableItemFeedback>
        )
    }

    const fetchAgencies = (): Promise<Agences> => {
        if (companySelected) {
            return api.company.getAllAgenciesByCompany(companySelected.id)
        } else {
            return new Promise<Agences>((r, _) => r([]))
        }
    }

    const fetchCompanies = (): Promise<Entreprises> => api.company.getAllCompanies()

    const fetchDatas = () => {
        setEntityLoading(true)
        Promise.all([fetchCompanies(), fetchAgencies()])
            .then(([a, b]) => {
                setAllCompanies(a.sort((a, b) => a.libelle.localeCompare(b.libelle)))
                setAllAgencies(b.sort((a, b) => a.libelle.localeCompare(b.libelle)) as Agences)

                setEntityLoading(false)
            })
            .catch(err => {
                Logger.error('Une erreur est survenue lors de la récupération des données...', err)

                setEntityLoading(false)
            })
    }

    React.useEffect(fetchDatas, [companySelected])

    const changeCompany = (i: number) => {
        setCompanySelected(allCompanies[i])
    }

    //* RECHERCHE DES ELEMENTS PAR TEXTE
    const searchedAgencies = React.useMemo((): Agences => {
        const searchedList = (text.trim() ? searchjs.search(sanitizer.sanitize(text.trim())) : allAgencies) as Agences
        return filterFunction ? filterFunction(searchedList) : searchedList
    }, [allAgencies, text, filterFunction])

    React.useMemo(() => initSearchJs(allAgencies), [allAgencies])

    return (
        <View>
            <SectionContainer zIndex={0}>
                <PickerLine zIndex={0}>
                    <PickerContainer>
                        <Picker
                            value={companySelected ? companySelected.libelle : ''}
                            data={allCompanies.map(c => c.libelle)}
                            onChange={changeCompany}
                            isClearable
                            isSearchable
                            label={i18n.t('screens.contactScreen.title.companies', { count: allCompanies.length })}
                        />
                    </PickerContainer>
                </PickerLine>
                {!!companySelected && (
                    <SearchResultsContainer zIndex={-1}>
                        <SearchInput
                            onTextChange={setText}
                            placeholder={i18n.t('components.addIcpAgencies.searchPlaceholder')}
                            resultsCount={searchedAgencies.length}
                        />
                    </SearchResultsContainer>
                )}
            </SectionContainer>
            {!!companySelected && (
                <SectionContainer zIndex={-1}>
                    <Pagination
                        itemsByPage={agencyByPage || 5}
                        allItems={searchedAgencies}
                        setItemsToRender={setAgenciesToRender}
                    />
                    <AgencyList>
                        {entityLoading ? (
                            <ActivityIndicatorContainer>
                                <ActivityIndicator
                                    size={Theme.constants.activityIndicatorScreenSize}
                                    color={Theme.colors.activityIndicator}
                                />
                            </ActivityIndicatorContainer>
                        ) : agenciesToRender.length === 0 ? (
                            <NoAgencyFound>{i18n.t('components.addIcpAgencies.noAgency')}</NoAgencyFound>
                        ) : (
                            agenciesToRender.map((a, i) => {
                                return !!renderAgency ? renderAgency(a, i) : defaultRenderAgency(a, i)
                            })
                        )}
                    </AgencyList>
                </SectionContainer>
            )}
        </View>
    )
}

const SectionContainer = styled(View)<{ zIndex: number }>`
    z-index: ${props => props.zIndex};
`

// PICKERS
const PickerLine = styled(View)<{ zIndex: number }>`
    flex-direction: ${Platform.OS === 'web' ? 'row' : 'column'};
    margin-bottom: ${Platform.OS === 'web' ? '40px' : '20px'};
    ${Platform.OS === 'web' && 'align-items: center;'}
    z-index: ${props => props.zIndex};
`
const PickerContainer = styled(View)`
    ${Platform.OS === 'web' && 'flex: 1;'}
    ${Platform.OS !== 'web' && 'margin-bottom: 15px;'}
`

//SEARCH
const SearchResultsContainer = styled(View)<{ zIndex: number }>`
    z-index: ${props => props.zIndex};
    margin-bottom: ${Platform.OS === 'web' ? '60px' : '30px'};
`

// CONTACT LISTE
const NoAgencyFound = styled(Text)`
    text-align: center;
    ${props => props.theme.fonts.noItemLabel}
`
const AgencyList = styled(View)`
    background-color: ${props => props.theme.colors.contactListBackground};
    padding: ${Platform.OS === 'web' ? '30px' : '10px'};
    z-index: -1;
`

const AgencyContainer = styled(View)<{ odd: boolean }>`
    background-color: ${props => (props.odd ? props.theme.colors.oddLine : props.theme.colors.evenLine)};
    padding: 10px;

    flex-direction: row;
    ${props =>
        Platform.OS === 'web' &&
        `&:hover {
        background-color: ${props.theme.colors.contactLineHover};
    }`}
`
const AgencyInformation = styled(Text)<{ main?: boolean }>`
    ${props => (props.main ? props.theme.fonts.contactMainInformation : props.theme.fonts.contactInformation)}
    margin-left: ${props => !props.main && '20px'};
`
const AgencyColumn = styled(View)<{ double?: boolean }>`
    flex: ${props => (props.double ? '2' : '1')};
    flex-direction: column;
`
const ActivityIndicatorContainer = styled(View)`
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
`
