import * as React from 'react'
import { Platform, Text, View } from 'react-native'

import { TouchableItemFeedback } from '@components/touchable/Touchable'
import Icon from '@components/icon/Icon'
import TextInput from '@components/textInput/TextInput'

import styled from '@styles/index'
import useTheme from '@styles/useTheme'

import useI18n from '@store/i18n/useI18n'

interface Props {
    text: string
    index: number
    editing: boolean
    errorText?: (text: string) => string | undefined
    textList: string[]
    setTextList: (textList: string[]) => void
    editingText?: EditingText
    setEditingText: (editingText?: EditingText) => void
}

export type EditingText = {
    index: number
    libelle: string
}

const EditableList = ({
    text,
    index,
    editing,
    errorText,
    textList,
    setTextList,
    editingText,
    setEditingText,
}: Props) => {
    const [Theme] = useTheme()
    const i18n = useI18n()

    const isError = !!errorText && !!editingText && !!errorText(editingText.libelle)
    const isWeb = React.useMemo(() => Platform.OS === 'web', [])

    return (
        <TextContainer
            key={`${text} - ${index}`}
            editing={editing}
            even={index % 2 === 0}
            errorEditing={isError}
            isWeb={isWeb}
        >
            {!editing ? (
                <>
                    <TextContentContainer>
                        <TextContent>{text}</TextContent>
                    </TextContentContainer>
                    <TouchableItemFeedback
                        onPress={() =>
                            setEditingText({
                                index: index,
                                libelle: text,
                            })
                        }
                    >
                        <EditButtonContainer isWeb={isWeb}>
                            <View>
                                <Icon name="edit" size={15} color={Theme.colors.editButtonColor} />
                            </View>
                        </EditButtonContainer>
                    </TouchableItemFeedback>
                    <TouchableItemFeedback onPress={() => setTextList(textList.filter(listItem => listItem !== text))}>
                        <EditButtonContainer isWeb={isWeb} margined>
                            <View>
                                <Icon name="delete" size={15} color={Theme.colors.editButtonColor} />
                            </View>
                        </EditButtonContainer>
                    </TouchableItemFeedback>
                </>
            ) : (
                <EditingTextContainer>
                    {!!editingText && (
                        <>
                            <TextInput
                                onTextChange={(text: string) => setEditingText({ ...editingText, libelle: text })}
                                value={editingText.libelle}
                                placeholder={i18n.t('screens.customInterface.emailPlaceholder')}
                                flexed
                                error={!!errorText ? errorText(editingText.libelle) : undefined}
                            />
                            <TouchableItemFeedback
                                onPress={() => {
                                    if (!!editingText) {
                                        setTextList(
                                            textList.map((emailItem, i) =>
                                                i === index ? editingText?.libelle : emailItem,
                                            ),
                                        )
                                        setEditingText(undefined)
                                    }
                                }}
                                disabled={isError}
                            >
                                <EditButtonContainer isWeb={isWeb} error={isError} margined>
                                    <View>
                                        <Icon name="save" size={15} color={Theme.colors.editButtonColor} />
                                    </View>
                                </EditButtonContainer>
                            </TouchableItemFeedback>
                            <TouchableItemFeedback onPress={() => setEditingText(undefined)}>
                                <EditButtonContainer isWeb={isWeb} margined>
                                    <View>
                                        <Icon name="cross" size={15} color={Theme.colors.editButtonColor} />
                                    </View>
                                </EditButtonContainer>
                            </TouchableItemFeedback>
                        </>
                    )}
                </EditingTextContainer>
            )}
        </TextContainer>
    )
}
export default EditableList

const TextContainer = styled(View)<{ even: boolean; isWeb: boolean; editing?: boolean; errorEditing?: boolean }>`
    background-color: ${props =>
        props.editing
            ? props.theme.colors.editContainerBackground
            : props.even
            ? props.theme.colors.evenLine
            : props.theme.colors.oddLine};
    ${props => props.theme.fonts.riskList}
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: ${props => (props.errorEditing ? '70px' : '50px')};
    padding: 10px 30px;

    &:hover {
        background-color: ${props => props.isWeb && props.theme.colors.riskLineHover};
        & > div {
            & > div {
                color: ${props => props.isWeb && props.theme.colors.riskTextHover};
            }
        }
    }
`
const EditButtonContainer = styled(View)<{ isWeb: boolean; error?: boolean; margined?: boolean }>`
    width: 35px;
    height: 35px;
    border-radius: 50px;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.theme.colors.editButtonBackgroundColor};
    ${props => `border: 2px solid ${props.theme.colors.editButtonBorderColor};`}
    ${props => props.margined && 'margin-left: 20px;'}

     &:hover {
        border: 2px solid ${props => !props.error && props.isWeb && props.theme.colors.editButtonBorderColorHovered};
        background-color: ${props =>
            !props.error && props.isWeb && props.theme.colors.editButtonBackgroundColorHovered};
        & > div {
            & > div {
                color: ${props => !props.error && props.isWeb && props.theme.colors.editButtonColorHovered};
            }
        }
    }
`
const TextContentContainer = styled(View)`
    flex: 1;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    margin-left: 25px;
`

const TextContent = styled(Text)`
    ${props => props.theme.fonts.infoLibelle}
`
const EditingTextContainer = styled(View)`
    flex: 1;
    align-items: center;
    flex-direction: row;
`
