import React from 'react'

// Components
import { Text, View, Platform, TextInput as RNTextInput } from 'react-native'
import Icon from '@components/icon/Icon'

// Style
import styled from '@styles/index'
import i18next from 'i18next'
import useTheme from '@styles/useTheme'

interface Props {
    value?: string
    onTextChange: (text: string) => void

    resultsCount?: number
    placeholder?: string
    disabled?: boolean
}

export default (props: Props) => {
    const { value, onTextChange, disabled, placeholder, resultsCount } = props
    const [Theme] = useTheme()

    return (
        <AlignedRowContainer>
            <Container editable={!disabled}>
                <SearchIcon>
                    <Icon name={'loupe'} size={20} color={Theme.colors.fieldPlaceholder} />
                </SearchIcon>

                <Input
                    value={value}
                    onChangeText={onTextChange}
                    placeholder={placeholder}
                    placeholderTextColor={Theme.colors.fieldPlaceholder}
                />
            </Container>
            {resultsCount !== undefined && (
                <ResultsCountContainer>
                    <ResultsCount>{resultsCount}</ResultsCount>
                    <ResultMessage>
                        {i18next.t('components.searchInput.resultsCountMessage', { count: resultsCount }).toString()}
                    </ResultMessage>
                </ResultsCountContainer>
            )}
        </AlignedRowContainer>
    )
}
const AlignedRowContainer = styled(View)`
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: -8px;
`
const Container = styled(View)<{ editable: boolean }>`
    height: 20px;
    flex: 1;
    align-items: center;
    flex-direction: row;
    border-radius: ${props => props.theme.constants.inputBorderRadius};
    background-color: ${props =>
        props.editable ? props.theme.colors.textInputBackground : props.theme.colors.textInputBackgroundDisabled};
    padding-left: ${props => props.theme.constants.fieldPadding};
    padding-right: ${props => props.theme.constants.fieldPadding};
    min-height: ${props => props.theme.constants.textInputHeight + 'px'};
    min-width: 250px;
    margin-bottom: 8px;
`

const Input = styled(RNTextInput)`
    ${props => props.theme.fonts.inputText}
    flex: 1;
    ${Platform.OS === 'web' && 'outline: none;'}
`
const SearchIcon = styled(View)`
    margin-right: 12px;
`
const ResultsCountContainer = styled(View)`
    flex-direction: row;
    margin-left: 16px;
    align-items: center;
    margin-bottom: 8px;
`
const ResultsCount = styled(Text)`
    ${props => props.theme.fonts.searchInputResultsCount}
    font-family: Avenir-Heavy;
    color: ${props => props.theme.colors.searchInputResultsCount};
`

const ResultMessage = styled(Text)`
    ${props => props.theme.fonts.searchInputResultsCount}
`
