import * as React from 'react'

// Components
import { View, Text, Image } from 'react-native'
import { TouchableItemFeedback } from '@components/touchable/Touchable'
import Icon from '@components/icon/Icon'
import ImagesModal from '@components/imagesModal/ImagesModal'

// Utils
import { isPdf, openPdfInTab } from '@utils/pdfCommonUtils'

// Store
import useI18n from '@store/i18n/useI18n'

// Style
import styled from '@styles/index'
import useTheme from '@styles/useTheme'

interface Props {
    values: DocumentIcp[]
}

export default (props: Props) => {
    const { values } = props

    const i18n = useI18n()
    const [Theme] = useTheme()

    const [itemOpened, setItemOpened] = React.useState<number | undefined>(undefined)
    const [imagesToDisplay, setImagesToDisplay] = React.useState<string[]>([])
    const [initImage, setInitImage] = React.useState<number>(0)

    return (
        <Container>
            <ImagesModal
                visible={imagesToDisplay.length > 0}
                onRequestClose={() => {
                    setImagesToDisplay([])
                    setInitImage(0)
                }}
                initImage={initImage}
                images={imagesToDisplay}
            />
            <RowContainer>
                <Legend>{i18n.t('components.addIcpDocuments.name')}</Legend>
            </RowContainer>
            <DocumentList>
                {values.length === 0 ? (
                    <NoDocument>{i18n.t('components.addIcpDocuments.noDocument')}</NoDocument>
                ) : (
                    values.map((document, index) => {
                        const imagesOnly = document.pages.filter(page => !isPdf(page.image))

                        return (
                            <TouchableItemFeedback
                                key={`document${index}`}
                                onPress={() => setItemOpened(itemOpened === index ? undefined : index)}
                            >
                                <DocumentContainer odd={index % 2 === 1}>
                                    <RowContainer>
                                        <DocumentName ellipsizeMode={'tail'} numberOfLines={2}>
                                            {document.name}
                                        </DocumentName>

                                        <Icon
                                            name={'information'}
                                            size={Theme.constants.riskFamilyInformationIconSize}
                                            color={
                                                itemOpened === index
                                                    ? Theme.colors.riskFamilyItemInformationSelectedIcon
                                                    : Theme.colors.riskFamilyItemInformationIcon
                                            }
                                        />
                                    </RowContainer>
                                    {itemOpened === index && (
                                        <DocumentInformations>
                                            <Preview>
                                                {document.pages.length === 0 ? (
                                                    <NoDocument>
                                                        {i18n.t('components.addIcpDocuments.noPreview')}
                                                    </NoDocument>
                                                ) : (
                                                    <RowWrapContainer>
                                                        {document.pages.map((page, j) => (
                                                            <TouchableItemFeedback
                                                                key={`doc${index}image${j}`}
                                                                onPress={() => {
                                                                    if (isPdf(page.image)) {
                                                                        openPdfInTab(page.image)
                                                                    } else {
                                                                        setInitImage(imagesOnly.indexOf(page))
                                                                        setImagesToDisplay(
                                                                            imagesOnly
                                                                                .filter(im => !isPdf(im.image))
                                                                                .map(pag => pag.image),
                                                                        )
                                                                    }
                                                                }}
                                                            >
                                                                {isPdf(page.image) ? (
                                                                    <PdfButton>
                                                                        <Icon
                                                                            name={'icpToApprove'}
                                                                            size={50}
                                                                            color={Theme.colors.uploadIconColor}
                                                                        />
                                                                    </PdfButton>
                                                                ) : (
                                                                    <ImageContainer>
                                                                        <ImageValue source={{ uri: page.image }} />
                                                                    </ImageContainer>
                                                                )}
                                                            </TouchableItemFeedback>
                                                        ))}
                                                    </RowWrapContainer>
                                                )}
                                            </Preview>
                                        </DocumentInformations>
                                    )}
                                </DocumentContainer>
                            </TouchableItemFeedback>
                        )
                    })
                )}
            </DocumentList>
        </Container>
    )
}

const DocumentContainer = styled(View)<{ odd?: boolean }>`
    background-color: ${props => !props.odd && props.theme.colors.evenLines};
`
const Container = styled(View)`
    background-color: ${props => props.theme.colors.addIcpContactsBackground};
    padding-right: 8px;
    padding-left: 8px;
`
const RowContainer = styled(View)`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: ${props => props.theme.constants.fieldPadding};
    padding-left: ${props => props.theme.constants.fieldPadding};
    min-height: 36px;
`
const Legend = styled(Text)`
    ${props => props.theme.fonts.rightLegend}
    text-transform: uppercase;
`
const DocumentList = styled(View)`
    padding-bottom: 8px;
`
const NoDocument = styled(Text)`
    ${props => props.theme.fonts.perimeterName}
    text-align: center;
    padding-top: 24px;
    padding-bottom: 24px;
`
const DocumentName = styled(Text)`
    ${props => props.theme.fonts.icpContactName}
`
const DocumentInformations = styled(View)`
    padding: 24px;
`
const ImageContainer = styled(View)`
    justify-content: center;
    align-items: center;
    height: ${props => props.theme.constants.formImageInputPictureSize};
    width: ${props => props.theme.constants.formImageInputPictureSize};
    margin-right: 24px;
    margin-bottom: 8px;
`
const ImageValue = styled(Image)`
    height: ${props => props.theme.constants.formImageInputPictureSize};
    width: ${props => props.theme.constants.formImageInputPictureSize};
`
const RowWrapContainer = styled(View)`
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: -8px;
`
const Preview = styled(View)`
    margin-bottom: 24px;
`
const PdfButton = styled(View)`
    background-color: ${props => props.theme.colors.imageDropInputBackground};
    justify-content: center;
    align-items: center;
    height: ${props => props.theme.constants.formImageInputPictureSize};
    width: ${props => props.theme.constants.formImageInputPictureSize};
    margin-right: 24px;
    margin-bottom: 8px;
`
