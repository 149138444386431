import * as React from 'react'

// Components
import { View } from 'react-native'
import { TouchableItemFeedback } from '@components/touchable/Touchable'
import Icon from '@components/icon/Icon'

// Style
import styled from '@styles/index'
import useTheme from '@styles/useTheme'

interface Props {
    index: number
    isLast: boolean
    editEnabled: boolean
    setEditEnabled: (enabled: boolean) => void
    changeOrder: (idx1: number, idx2: number) => void
}

export default (props: Props) => {
    const { index, isLast, changeOrder, editEnabled, setEditEnabled } = props
    const [Theme] = useTheme()

    return (
        <Container>
            <LeftButton>
                <TouchableItemFeedback disabled={index === 0} onPress={() => changeOrder(index, index - 1)}>
                    <Icon
                        color={index === 0 ? Theme.colors.orderArrowsDisabledColor : Theme.colors.orderArrowsColor}
                        name={'chevron-up'}
                        size={15}
                    />
                </TouchableItemFeedback>
            </LeftButton>

            <TouchableItemFeedback disabled={isLast} onPress={() => changeOrder(index, index + 1)}>
                <Icon
                    color={isLast ? Theme.colors.orderArrowsDisabledColor : Theme.colors.orderArrowsColor}
                    name={'chevron-down'}
                    size={15}
                />
            </TouchableItemFeedback>
            {!editEnabled && (
                <RightButton>
                    <TouchableItemFeedback onPress={() => setEditEnabled(true)}>
                        <Icon color={Theme.colors.editRubriqueColor} name={'pencil'} size={15} />
                    </TouchableItemFeedback>
                </RightButton>
            )}
        </Container>
    )
}

const Container = styled(View)`
    position: absolute;
    padding-top: 12px;
    padding-right: 12px;
    top: 0;
    right: 0;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    z-index: 10;
`
const LeftButton = styled(View)`
    margin-right: 5px;
`
const RightButton = styled(View)`
    margin-left: 15px;
`
