import * as React from 'react'
import { View, Text, Image } from 'react-native'

import styled from '@styles/index'
import LottieView from 'lottie-react-native'

import useReducer from '@store/useReducer'
import * as CustomInterfaceStore from '@store/customInterfaceStore'

export default () => {
    const [windowHeight, setWindowHeight] = React.useState<number>(window.innerHeight)

    const logo = useReducer(CustomInterfaceStore.store, s => s.menuLogo)
    const color = useReducer(CustomInterfaceStore.store, s => s.mainColor)

    React.useLayoutEffect(() => {
        const updateHeight = () => setWindowHeight(window.innerHeight)
        window.addEventListener('resize', updateHeight)
        return () => window.removeEventListener('resize', updateHeight)
    }, [setWindowHeight])

    return (
        <ScreenContainer windowHeight={windowHeight} color={color}>
            <Logo resizeMode="contain" source={{ uri: logo }} />
            <AppName>NUMA PPEE</AppName>
            <LottieView source={require('@drawable/loadingAnimation_white.json')} autoPlay loop />
        </ScreenContainer>
    )
}

const ScreenContainer = styled(View)<{ windowHeight: number; color: string }>`
    height: ${props => props.windowHeight}px;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    background-color: ${props => props.color};
`
const Logo = styled(Image)`
    height: 150px;
    width: 50%;
`
const AppName = styled(Text)`
    font-size: 30px;
    font-weight: bold;
    color: white;
    width: 100%;
    text-align: center;
`
