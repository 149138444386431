import * as React from 'react'

// Components
import { Text, View, Platform } from 'react-native'
import Modal from '@components/modal/Modal'
import { TouchableItemFeedback } from '@components/touchable/Touchable'
import Icon from '@components/icon/Icon'
import Picker from '@components/picker/Picker'
import { Formik } from 'formik'
import Button from '@components/button/Button'
import Checkbox from '@components/checkbox/Checkbox'
import { confirmAlert, basicAlert } from '@components/alert/Alerts'
import PpeePdfMenu from './ppeePdfMenu'
import AddIcpDocuments from '@components/addIcpDocuments/AddIcpDocuments'

// Utils
import produce from 'immer'
import Logger from '@utils/logger'
import useNavigation from '@layout/useNavigation'

// Api
import api from '@api/api'

// Store
import useI18n from '@store/i18n/useI18n'

// Style
import styled from '@styles/index'
import useTheme from '@styles/useTheme'

const AMENDMENT_REASONS: AmendmentReason[] = ['duration', 'nature', 'newActor', 'newCompanyMeans', 'newRisk']

const renderDocumentCheckboxLine = (
    values: DocumentAprr[],
    doc: DocumentAprr,
    index: number,
    onChange: (docs: DocumentAprr[]) => void,
    isLast: boolean,
) => {
    const docIsChecked = values.map(d => d.id).indexOf(doc.id) > -1
    const onCheck = () => onChange(docIsChecked ? values.filter(d => d.id !== doc.id) : [...values, doc])
    return (
        <CheckboxLineContainer key={`document${index}`} isLast={isLast}>
            <TouchableItemFeedback onPress={onCheck}>
                <EditSubitemLine>
                    <CheckboxContainer>
                        <Checkbox value={docIsChecked} onCheck={onCheck} smallCheckbox />
                    </CheckboxContainer>
                    <EditSubItemName>
                        <Libelle boldItem={docIsChecked}>{doc.name}</Libelle>
                    </EditSubItemName>
                </EditSubitemLine>
            </TouchableItemFeedback>
        </CheckboxLineContainer>
    )
}

const renderAmendmentReasonCheckbox = (
    values: AmendmentReason[],
    reason: AmendmentReason,
    index: number,
    onChange: (reasons: AmendmentReason[]) => void,
    isLast: boolean,
    i18n: I18nState,
) => {
    const reasonIsChecked = values.indexOf(reason) > -1
    const onCheck = () => onChange(reasonIsChecked ? values.filter(r => r !== reason) : [...values, reason])
    return (
        <CheckboxLineContainer key={`document${index}`} isLast={isLast}>
            <TouchableItemFeedback onPress={onCheck}>
                <EditSubitemLine>
                    <CheckboxContainer>
                        <Checkbox value={reasonIsChecked} onCheck={onCheck} smallCheckbox />
                    </CheckboxContainer>
                    <EditSubItemName>
                        <Libelle boldItem={reasonIsChecked}>
                            {i18n.t(`screens.ppeePdfScreen.values.amendmentReasons.${reason}`)}
                        </Libelle>
                    </EditSubItemName>
                </EditSubitemLine>
            </TouchableItemFeedback>
        </CheckboxLineContainer>
    )
}

const renderModalButtons = (
    i18n: I18nState,
    Theme: Theme,
    onValidate: () => void,
    onClose: () => void,
    validateStatus: ButtonStatus,
) => (
    <BottomButtonsContainer>
        <ButtonContainer>
            <Button
                libelle={i18n.t('screens.ppeePdfScreen.buttons.close')}
                onPress={onClose}
                status={'active'}
                buttonColor={Theme.colors.closeButton}
            />
        </ButtonContainer>
        <ButtonContainer>
            <Button
                libelle={i18n.t('screens.ppeePdfScreen.buttons.validate')}
                onPress={onValidate}
                status={validateStatus}
            />
        </ButtonContainer>
    </BottomButtonsContainer>
)

interface Props {
    buttons: ButtonsPPEE
    modalMode?: PpeeModalMode
    onRequestClose: () => void
    ppee: Icp
    documents: DocumentAprr[]
    redacteurs: UserRedacteur[]
    refreshPpee: () => void
    updatePpee: (newPpee: Icp) => void
    setStatus: (status: Status) => void
}

export default (props: Props) => {
    const {
        buttons,
        modalMode,
        onRequestClose,
        ppee,
        redacteurs,
        documents,
        refreshPpee,
        updatePpee,
        setStatus,
    } = props
    const i18n = useI18n()
    const navigation = useNavigation()
    const [Theme] = useTheme()

    const [isFormDirty, setIsFormDirty] = React.useState<boolean>(false)
    const [selectedReasons, setSelectedReasons] = React.useState<AmendmentReason[]>([])

    const onClose = () => {
        if (!isFormDirty) {
            onRequestClose()
        } else {
            confirmAlert(
                i18n,
                'screens.ppeePdfScreen',
                'CloseUnsaved',
                () => {
                    setIsFormDirty(false)
                    onRequestClose()
                },
                () => undefined,
                Theme,
            )
        }
    }
    const handlePpeeSubmit = (values: Icp, setSubmitting: (isSubmitting: boolean) => void) => {
        if (ppee.id) {
            const EditPromise =
                modalMode === 'editInfo'
                    ? api.ppee.editRedacteur(ppee.id, { id: values.planification.redacteur.id })
                    : api.ppee.editAnnexes(ppee.id, values.documentsPpee)
            EditPromise.then(updatedPpee => {
                updatePpee(updatedPpee)
                setSubmitting(false)
                setStatus('fetched')
                onRequestClose()
            }).catch(err => {
                Logger.error(err)
                basicAlert(
                    i18n,
                    'screens.ppeePdfScreen',
                    'Edit',
                    () => {
                        setSubmitting(false)
                        setStatus('fetched')
                    },
                    'error',
                    Theme,
                )
            })
        }
    }

    return (
        <Modal
            visible={!!modalMode}
            onRequestClose={onClose}
            customBackgroundColor={Platform.OS !== 'web' ? Theme.colors.ppeePdfLeftMenuBackground : undefined}
            noMinHeight={modalMode === 'editInfo' || modalMode === 'amendmentReasons'}
            noBackground={modalMode === 'menu'}
            fixedContent={modalMode === 'menu'}
        >
            {modalMode === 'menu' ? (
                <Menu>
                    <PpeePdfMenu buttons={buttons} onClose={onClose} ppee={ppee} refreshPpee={refreshPpee} />

                    <CloseButton onPress={onClose}>
                        <Bubble alternativeColors={true}>
                            <Icon name={'cross'} color={Theme.colors.defaultButtonColor} size={20} />
                        </Bubble>
                    </CloseButton>
                </Menu>
            ) : (
                <InfoContainer>
                    {modalMode === 'amendmentReasons' ? (
                        <View>
                            <SectionTitle>{i18n.t(`screens.ppeePdfScreen.titles.${modalMode}`)}</SectionTitle>
                            {selectedReasons.length === 0 && (
                                <Error>{i18n.t('screens.ppeePdfScreen.error.missingSelectedReason')}</Error>
                            )}
                            <ItemsContainer>
                                {AMENDMENT_REASONS.map((reason, index) =>
                                    renderAmendmentReasonCheckbox(
                                        selectedReasons,
                                        reason,
                                        index,
                                        newReasons => setSelectedReasons(newReasons),
                                        index === AMENDMENT_REASONS.length - 1,
                                        i18n,
                                    ),
                                )}
                            </ItemsContainer>
                            {renderModalButtons(
                                i18n,
                                Theme,
                                () => {
                                    onClose()
                                    navigation.push('/ppee/:ppeeId/ajouterAvenant', {
                                        ppeeId: ppee.id,
                                        reasons: selectedReasons,
                                        pathParams: { ppeeId: ppee.id },
                                    })
                                },
                                () => {
                                    setSelectedReasons([])
                                    onClose()
                                },
                                selectedReasons.length === 0 ? 'disabled' : 'active',
                            )}
                        </View>
                    ) : (
                        <Formik
                            initialValues={ppee}
                            onSubmit={(values, { setSubmitting }) => handlePpeeSubmit(values, setSubmitting)}
                        >
                            {({ values, setFieldValue, handleSubmit, isSubmitting, dirty }) => {
                                React.useEffect(() => {
                                    if (dirty !== isFormDirty) {
                                        setIsFormDirty(dirty)
                                    }
                                }, [dirty])

                                return (
                                    <View>
                                        <SectionTitle>
                                            {i18n.t(`screens.ppeePdfScreen.titles.${modalMode}`)}
                                        </SectionTitle>
                                        {modalMode === 'editInfo' ? (
                                            <PickerContainer zIndex={0}>
                                                <Picker
                                                    value={
                                                        values.planification.redacteur.id
                                                            ? `${values.planification.redacteur.firstName} ${values.planification.redacteur.lastName}`
                                                            : undefined
                                                    }
                                                    data={redacteurs.map(r => `${r.firstName} ${r.lastName}`)}
                                                    onChange={index =>
                                                        setFieldValue(
                                                            'planification',
                                                            index > -1
                                                                ? produce(values.planification, newPlanification => {
                                                                      newPlanification.redacteur = redacteurs[index]
                                                                  })
                                                                : values.planification,
                                                        )
                                                    }
                                                    placeholder={i18n.t('components.planIcp.placeholders.redacteur')}
                                                    label={i18n.t('components.planIcp.labels.redacteur')}
                                                    required={true}
                                                />
                                            </PickerContainer>
                                        ) : (
                                            <View>
                                                <ItemsContainer>
                                                    {documents.length === 0 ? (
                                                        <NoDocument>
                                                            {i18n.t('components.documentsIcp.noDocument')}
                                                        </NoDocument>
                                                    ) : (
                                                        documents.map((doc, index) =>
                                                            renderDocumentCheckboxLine(
                                                                values.documentsPpee.documentsAprr,
                                                                doc,
                                                                index,
                                                                value => {
                                                                    setFieldValue('documentsPpee', {
                                                                        ...values.documentsPpee,
                                                                        documentsAprr: value,
                                                                    })
                                                                },
                                                                index === documents.length - 1,
                                                            ),
                                                        )
                                                    )}
                                                </ItemsContainer>
                                                <SectionTitle paddingTop>
                                                    {i18n.t('components.documentsIcp.sectionsTitles.documentsExt')}
                                                </SectionTitle>
                                                <ItemsContainer>
                                                    <AddIcpDocuments
                                                        values={values.documentsPpee.documentsExt}
                                                        onChange={docs =>
                                                            setFieldValue('documentsPpee', {
                                                                ...values.documentsPpee,
                                                                documentsExt: docs,
                                                            })
                                                        }
                                                    />
                                                </ItemsContainer>

                                                <SectionTitle paddingTop>
                                                    {i18n.t('components.documentsIcp.sectionsTitles.attachedDocuments')}
                                                </SectionTitle>
                                                <ItemsContainer>
                                                    <AddIcpDocuments
                                                        values={values.documentsPpee.attachedDocuments}
                                                        onChange={docs =>
                                                            setFieldValue('documentsPpee', {
                                                                ...values.documentsPpee,
                                                                attachedDocuments: docs,
                                                            })
                                                        }
                                                    />
                                                </ItemsContainer>
                                            </View>
                                        )}

                                        {renderModalButtons(
                                            i18n,
                                            Theme,
                                            handleSubmit,
                                            onClose,
                                            isSubmitting ? 'loading' : 'active',
                                        )}
                                    </View>
                                )
                            }}
                        </Formik>
                    )}
                </InfoContainer>
            )}
        </Modal>
    )
}

const Menu = styled(View)`
    justify-content: flex-end;
`
const InfoContainer = styled(View)`
    background-color: ${props => props.theme.colors.documentsModalBackground};
    padding: 24px;
`
const CloseButton = styled(TouchableItemFeedback)`
    position: absolute;
    bottom: 0px;
    right: 0px;
    z-index: 1;
`
const Bubble = styled(View)<{ alternativeColors?: boolean }>`
    background-color: ${props =>
        props.alternativeColors ? props.theme.colors.defaultButtonFontColor : props.theme.colors.defaultButtonColor};

    justify-content: center;
    align-items: center;

    height: 50px;
    width: 50px;
    border-radius: 50px;

    elevation: 50px;
    box-shadow: 1px 1px 5px ${props => props.theme.colors.homeIndicatorBowShadowColor};
`
const BottomButtonsContainer = styled(View)`
    margin-top: 40px;
    margin-bottom: -20px;
    flex-direction: ${Platform.OS === 'web' ? 'row' : 'column'};
    align-items: center;
    justify-content: ${Platform.OS === 'web' ? 'space-evenly' : 'center'};
    z-index: -1;
`
const SectionTitle = styled(Text)<{ paddingTop?: boolean }>`
    ${props => props.theme.fonts.icpFormTitle}
    ${props => props.paddingTop && 'padding-top: 20px;'}
    margin-bottom: 24px;
    padding-left: ${props => props.theme.constants.fieldPadding};
`
const PickerContainer = styled(View)<{ zIndex: number }>`
    z-index: ${props => props.zIndex};
`
const ItemsContainer = styled(View)`
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 12px;
    padding-bottom: 12px;
`
const NoDocument = styled(Text)`
    text-align: center;
    ${props => props.theme.fonts.noItemLabel}
`
const CheckboxLineContainer = styled(View)<{ isLast: boolean }>`
    ${props => !props.isLast && 'margin-bottom: 5px;'}
    background-color: white;
    padding-left: ${props => props.theme.constants.fieldPadding};
`

const EditSubitemLine = styled(View)`
    flex-direction: row;
    align-items: center;
`
const EditSubItemName = styled(View)`
    flex: 1;
    min-height: ${props => `${props.theme.constants.homeListLineHeight}px`};
    flex-direction: row;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
`
const CheckboxContainer = styled(View)`
    width: 36px;
`
const Libelle = styled(Text)<{ boldItem?: boolean }>`
    ${props => props.theme.fonts.homeListLineContent}
    ${props => props.boldItem && 'font-family: Avenir-Heavy;'}
`
const ButtonContainer = styled(View)`
    margin-bottom: 20px;
`
const Error = styled(Text)`
    ${props => props.theme.fonts.fieldError}
    padding-right: ${props => props.theme.constants.fieldPadding};
    padding-left: ${props => props.theme.constants.fieldPadding};
    margin-bottom: 24px;
`
