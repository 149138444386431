// Api
import api from '@api/api'

// Store
import * as synchroStore from '@store/synchro'
import { createStoreObject, Subscription } from 'effector'

// Utils
import * as connection from '@utils/connection'
import Logger from '@utils/logger'
import { getDay, getMonth, getYear, getTime } from 'date-fns'

const idPrefix = 'ns'
let storage: any // Devrait être de type StorageState mais dans le start, le data du watch ne correspond pas bien...
let storeSubscriber: Subscription | undefined
let subscriber: TypeSubscription | undefined

const generateId = (timestamp: Date) =>
    idPrefix + getDay(timestamp) + getMonth(timestamp) + getYear(timestamp) + getTime(timestamp)

const IcpSyncService = {
    // Á la connexion
    start: () => {
        Logger.info('Démarrage du module de synchronisation...')
        if (!storeSubscriber) {
            storeSubscriber = createStoreObject(synchroStore.store).watch(data => (storage = data))
        }
        if (!subscriber) {
            subscriber = connection.default.subscribe(IcpSyncService.onConnectionStateChange)
        }
    },

    stop: () => {
        Logger.info('Arrêt du module de synchronisation...')
        if (storeSubscriber) {
            storeSubscriber.unsubscribe()
            storeSubscriber = undefined
        }
        if (subscriber) {
            subscriber.unsubscribe()
            subscriber = undefined
        }
    },

    onConnectionStateChange: ({ connected }: { connected: boolean }) => {
        if (connected) {
            Logger.info('Connexion retrouvée, tentative de synchronisation des ICP')
            IcpSyncService.syncIcps()
        }
    },

    getSyncState: () => storage[0].icpsNonSynchro && storage[0].icpsNonSynchro.length,

    sendIcp: (icp: Icp): Promise<string> => {
        const icpsNonSynchro: Icp[] = storage[0].icpsNonSynchro
        const generatedId = generateId(new Date())
        const newIcpsNonSync = [
            ...icpsNonSynchro.filter(icpNs => icpNs.id !== icp.id),
            { ...icp, id: icp.id || generatedId },
        ]
        synchroStore.actions.setIcpsNonSynchro(newIcpsNonSync)
        IcpSyncService.syncIcps()
        return Promise.resolve(icp.id || generatedId)
    },

    syncIcps: () => {
        const icpsNonSynchro: Icp[] = storage[0].icpsNonSynchro
        !!icpsNonSynchro &&
            icpsNonSynchro.forEach(icp => {
                if (!!icp.id) {
                    ;(icp.id.indexOf(idPrefix) > -1
                        ? !!icp.idParent
                            ? api.ppee.addAvenant(icp.idParent, { ...icp, id: undefined })
                            : api.icp.addIcp({ ...icp, id: undefined })
                        : api.icp.editIcp(icp, icp.id)
                    )
                        .then(() => {
                            synchroStore.actions.setIcpsNonSynchro(icpsNonSynchro.filter(i => icp.id !== i.id))
                        })
                        .catch(err => Logger.info('Echec de la synchro : ', icp.id, err))
                }
            })
    },

    syncIcp: (icpId: string): Promise<void> =>
        new Promise((resolve, reject) => {
            Logger.info("Synchronization de l'icp : " + icpId)
            const icpsNonSynchro: Icp[] = storage[0].icpsNonSynchro
            const icp = !!icpsNonSynchro && icpsNonSynchro.find(i => i.id === icpId)
            if (!!icp && !!icp.id) {
                ;(icp.id.indexOf(idPrefix) > -1
                    ? !!icp.idParent
                        ? api.ppee.addAvenant(icp.idParent, { ...icp, id: undefined })
                        : api.icp.addIcp({ ...icp, id: undefined })
                    : api.icp.editIcp(icp, icp.id)
                )
                    .then(() => {
                        synchroStore.actions.setIcpsNonSynchro(icpsNonSynchro.filter(i => icp.id !== i.id))
                        return resolve()
                    })
                    .catch(err => {
                        Logger.info('Echec de la synchro : ', icp.id, err)
                        return reject()
                    })
            }
            return reject()
        }),
}

const getAllIcps = (icpsNonSynchros: Icp[], fetchedIcps: Icp[]): Icp[] => {
    const filteredFetchedIcps = !!fetchedIcps
        ? fetchedIcps.filter(fetchedIcp => !icpsNonSynchros.find(icpNonSync => icpNonSync.id === fetchedIcp.id))
        : []
    return [...icpsNonSynchros, ...filteredFetchedIcps]
}

export { IcpSyncService, getAllIcps }
