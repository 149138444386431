import * as React from 'react'
// Components
import { View, Text, Platform, FlatList } from 'react-native'
import SearchInput from '@components/searchInput/SearchInput'
import { TouchableItemFeedback } from '@components/touchable/Touchable'
import RootScreen from '@components/rootScreen/RootScreen'
import Checkbox from '@components/checkbox/Checkbox'

import useI18n from '@store/i18n/useI18n'

// Api
import api from '@api/api'

// Utils
import * as JsSearch from 'js-search'
import accentSanitizer from '@utils/accentSanitizer'
import useNavigation from '@layout/useNavigation'

// Style
import styled from '@styles/index'
import Logger from '@utils/logger'
import Button from '@components/button/Button'

const sanitizer = accentSanitizer()

interface Props {
    handleExport?: () => void
    isExporting?: ButtonStatus
}

const SharedComponent = ({ handleExport, isExporting }: Props) => {
    const i18n = useI18n()
    const navigation = useNavigation()
    const [status, setStatus] = React.useState<Status>('loading')
    const [measures, setMeasures] = React.useState<UnknownMeasure[]>([])

    const [text, setText] = React.useState<string>('')

    const [measuresToDelete, setMeasuresToDelete] = React.useState<string[]>([])
    const [isDeleting, setIsDeleting] = React.useState<boolean>(false)

    React.useEffect(() => {
        api.icp
            .getUnknownMeasures()
            .then(unkownMeasures => {
                setMeasures(unkownMeasures)
                setStatus('fetched')
            })
            .catch(err => {
                Logger.error(err)
                setStatus('error')
            })
    }, [])

    const searchJs = React.useMemo(() => {
        const searchjs = new JsSearch.Search('label')

        searchjs.searchIndex = new JsSearch.UnorderedSearchIndex()
        searchjs.indexStrategy = new JsSearch.AllSubstringsIndexStrategy()
        searchjs.tokenizer = {
            tokenize: (text: string) => text.split(' '),
        }
        searchjs.sanitizer = sanitizer
        searchjs.addIndex('label')
        searchjs.addIndex('riskLabel')
        searchjs.addDocuments(measures)

        return searchjs
    }, [measures])

    const searchedMeasures = React.useMemo((): UnknownMeasure[] => {
        const searchedList = (text ? searchJs.search(sanitizer.sanitize(text)) : measures) as UnknownMeasure[]
        return searchedList
    }, [measures, text, searchJs])

    const handleDelete = () => {
        setIsDeleting(true)
        const deleteIdsToString = measuresToDelete.reduce((wsParam, current, index) => {
            return index === 0 ? current : `${wsParam}%2C${current}`
        }, '')
        api.icp
            .deleteUnknownMeasures(deleteIdsToString)
            .then(res => {
                setMeasuresToDelete([])
                setMeasures(res)
                setIsDeleting(false)
            })
            .catch(() => {
                setIsDeleting(false)
            })
    }

    const renderLegend = () => (
        <LegendLine>
            <NameLegend>
                <Legend>{i18n.t('screens.unknownRisksMeasuresScreen.resultsLegend.measure')}</Legend>
            </NameLegend>
            <NameLegend>
                <Legend>{i18n.t('screens.unknownRisksMeasuresScreen.resultsLegend.risk')}</Legend>
            </NameLegend>
            <NameLegend>
                <Legend>{i18n.t('screens.unknownRisksMeasuresScreen.resultsLegend.date')}</Legend>
            </NameLegend>
            <NameLegend>
                <Legend>{i18n.t('screens.unknownRisksMeasuresScreen.resultsLegend.reporter')}</Legend>
            </NameLegend>
            <NameLegend>
                <Legend>{i18n.t('screens.unknownRisksMeasuresScreen.resultsLegend.description')}</Legend>
            </NameLegend>
            <ActionContainer />
        </LegendLine>
    )

    const deleteSelect = (id: string) =>
        measuresToDelete.indexOf(id) > -1
            ? setMeasuresToDelete(measuresToDelete.filter(measureId => measureId !== id))
            : setMeasuresToDelete([...measuresToDelete, id])

    const renderMeasure = (measure: { item: UnknownMeasure; index: number }) => (
        <ColorView odd={measure.index % 2 === 1}>
            <FlexView>
                <TouchableItemFeedback
                    key={`measure${measure.item.id}`}
                    onPress={() =>
                        navigation.push('/icp/:icpId', {
                            icpId: measure.item.icpInformation.icpId,
                            pathParams: { icpId: measure.item.icpInformation.icpId },
                        })
                    }
                >
                    <MeasureLine>
                        <MeasureName numberOfLines={2}>{measure.item.label}</MeasureName>
                        <MeasureName numberOfLines={2}>{measure.item.riskLabel}</MeasureName>
                        <MeasureName numberOfLines={2}>
                            {i18n.t('screens.unknownRisksMeasuresScreen.dateRisk', {
                                dateRisk: new Date(measure.item.icpInformation.date),
                            })}
                        </MeasureName>
                        <MeasureName numberOfLines={2}>{measure.item.icpInformation.redacteur}</MeasureName>
                        <MeasureName numberOfLines={2}>{measure.item.icpInformation.description}</MeasureName>
                        <ActionContainer>
                            <Action>{i18n.t(`screens.unknownAgenciesScreen.seeIcp`)}</Action>
                        </ActionContainer>
                    </MeasureLine>
                </TouchableItemFeedback>
            </FlexView>
            <CenterView>
                <Checkbox
                    key={`measure${measure.item.id}`}
                    value={measuresToDelete.indexOf(measure.item.id) > -1}
                    onCheck={() => deleteSelect(measure.item.id)}
                />
            </CenterView>
        </ColorView>
    )

    const renderMeasures = () =>
        searchedMeasures.length === 0 ? (
            <NoMeasureMessage>{i18n.t('screens.unknownRisksMeasuresScreen.noMeasure')}</NoMeasureMessage>
        ) : (
            <FlatList data={searchedMeasures} renderItem={renderMeasure} extraData={measuresToDelete} />
        )

    return (
        <RootScreen status={status}>
            <React.Fragment>
                <SearchResultsContainer zIndex={-3}>
                    <SearchInput
                        onTextChange={setText}
                        placeholder={i18n.t('screens.usersAdminScreen.searchPlaceholder')}
                        resultsCount={searchedMeasures.length}
                    />
                </SearchResultsContainer>

                <MarginBottomButtons>
                    {!!handleExport && !!isExporting && (
                        <MarginRightButton>
                            <Button
                                libelle={i18n.t('screens.unknownRisksMeasuresScreen.export')}
                                onPress={handleExport}
                                status={isExporting}
                            />
                        </MarginRightButton>
                    )}
                    {measuresToDelete.length > 0 && (
                        <Button
                            libelle={i18n.t('screens.unknownRisksMeasuresScreen.delete')}
                            onPress={handleDelete}
                            status={isDeleting ? 'loading' : 'active'}
                        />
                    )}
                </MarginBottomButtons>

                <ResultsContainer zIndex={-3}>
                    {renderLegend()}
                    {renderMeasures()}
                </ResultsContainer>
            </React.Fragment>
        </RootScreen>
    )
}
export default SharedComponent

const SearchResultsContainer = styled(View)<{ zIndex: number }>`
    z-index: ${props => props.zIndex};
    margin-bottom: 60px;
`
const ResultsContainer = styled(View)<{ zIndex: number }>`
    flex: 1;
    background-color: white;
    padding: 12px 24px;
    z-index: ${props => props.zIndex};
`
const LegendLine = styled(View)`
    flex-direction: row;
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom: 8px;
`
const NameLegend = styled(View)`
    flex: 1;
`
const Legend = styled(Text)`
    ${props => props.theme.fonts.perimeterLegend}
    text-transform: uppercase;
    text-align: center;
`
const MeasureLine = styled(View)`
    flex-direction: row;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
    height: ${props => `${props.theme.constants.rightLineHeight}px`};
`
const MeasureName = styled(Text)`
    flex: 1;
    ${props => props.theme.fonts.perimeterName}
    font-family: Avenir-Heavy;
    text-align: center;
`
const NoMeasureMessage = styled(Text)`
    ${props => props.theme.fonts.perimeterName}
    text-align: center;
    margin-top: 15px;
`
const ActionContainer = styled(View)`
    width: 100px;
`
const Action = styled(Text)`
    ${props => props.theme.fonts.homeActionLink}
    align-self: center;
    text-decoration: underline;
    text-decoration-color: ${props => props.theme.fonts.homeActionLink.color};
`
const FlexView = styled(View)`
    flex: 1;
`
const ColorView = styled(View)<{ odd: boolean }>`
    flex-direction: row;
    background-color: ${props => !props.odd && props.theme.colors.evenLines};
    ${props =>
        Platform.OS === 'web' &&
        `&:hover {
        background-color: ${props.theme.colors.hoverBackground};
        & > div {
            color: ${props.theme.colors.hoverText};
            text-decoration-color: ${props.theme.colors.hoverText};
            & > div {
                color: ${props.theme.colors.hoverText};
                text-decoration-color: ${props.theme.colors.hoverText};
            }
        }
    }`}
`
const CenterView = styled(View)`
    justify-content: center;
    padding: 0px 10px 0px 5px;
`
const MarginBottomButtons = styled(View)`
    flex-direction: row;
    margin-bottom: 10px;
`
const MarginRightButton = styled(View)`
    margin-right: 10px;
`
