import * as React from 'react'

// Components
import { View, Text, ScrollView, Platform } from 'react-native'
import SearchInput from '@components/searchInput/SearchInput'
import { TouchableItemFeedback } from '@components/touchable/Touchable'
import RootScreen from '@components/rootScreen/RootScreen'

import useI18n from '@store/i18n/useI18n'

// Api
import api from '@api/api'

// Utils
import * as JsSearch from 'js-search'
import accentSanitizer from '@utils/accentSanitizer'
import useNavigation from '@layout/useNavigation'

// Style
import styled from '@styles/index'
import Logger from '@utils/logger'

const sanitizer = accentSanitizer()

export default () => {
    const i18n = useI18n()
    const navigation = useNavigation()
    const [status, setStatus] = React.useState<Status>('loading')
    const [agencies, setAgencies] = React.useState<UnknownEntity[]>([])

    const [text, setText] = React.useState<string>('')

    React.useEffect(() => {
        api.icp
            .getUnknownEntities()
            .then(unkownEnitites => {
                setAgencies(unkownEnitites)
                setStatus('fetched')
            })
            .catch(err => {
                Logger.error(err)
                setStatus('error')
            })
    }, [])

    const searchJs = React.useMemo(() => {
        const searchjs = new JsSearch.Search('id')

        searchjs.searchIndex = new JsSearch.UnorderedSearchIndex()
        searchjs.indexStrategy = new JsSearch.AllSubstringsIndexStrategy()
        searchjs.tokenizer = {
            tokenize: (text: string) => text.split(' '),
        }
        searchjs.sanitizer = sanitizer
        searchjs.addIndex('name')
        searchjs.addDocuments(agencies)

        return searchjs
    }, [agencies])

    const searchedAgencies = React.useMemo((): UnknownEntity[] => {
        const searchedList = (text ? searchJs.search(sanitizer.sanitize(text)) : agencies) as UnknownEntity[]
        return searchedList
    }, [agencies, text, searchJs])

    const renderLegend = () => (
        <LegendLine>
            <NameLegend>
                <Legend>{i18n.t('screens.usersAdminScreen.resultsLegend.name')}</Legend>
            </NameLegend>
        </LegendLine>
    )

    const renderAgency = (agency: UnknownEntity, index: number) => (
        <TouchableItemFeedback
            key={`agency${index}`}
            onPress={() =>
                navigation.push('/icp/:icpId', {
                    icpIc: agency.icpId,
                    pathParams: { icpId: agency.icpId },
                })
            }
        >
            <AgencyLine odd={index % 2 === 1}>
                <AgencyName numberOfLines={2} ellipsizeMode={'tail'}>
                    {agency.name}
                </AgencyName>
                <Action>{i18n.t(`screens.unknownAgenciesScreen.seeIcp`)}</Action>
            </AgencyLine>
        </TouchableItemFeedback>
    )

    const renderAgencies = () =>
        searchedAgencies.length === 0 ? (
            <NoAgencyMessage>{i18n.t('screens.unknownAgenciesScreen.noAgency')}</NoAgencyMessage>
        ) : (
            <ScrollView>{searchedAgencies.map(renderAgency)}</ScrollView>
        )

    return (
        <RootScreen status={status}>
            <React.Fragment>
                <SearchResultsContainer zIndex={-3}>
                    <SearchInput
                        onTextChange={setText}
                        placeholder={i18n.t('screens.usersAdminScreen.searchPlaceholder')}
                        resultsCount={searchedAgencies.length}
                    />
                </SearchResultsContainer>

                <ResultsContainer zIndex={-3}>
                    {renderLegend()}
                    {renderAgencies()}
                </ResultsContainer>
            </React.Fragment>
        </RootScreen>
    )
}

const SearchResultsContainer = styled(View)<{ zIndex: number }>`
    z-index: ${props => props.zIndex};
    margin-bottom: 60px;
`
const ResultsContainer = styled(View)<{ zIndex: number }>`
    flex: 1;
    background-color: white;
    padding: 12px 24px;
    z-index: ${props => props.zIndex};
`
const LegendLine = styled(View)`
    flex-direction: row;
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom: 8px;
`
const NameLegend = styled(View)`
    flex: 1;
`
const Legend = styled(Text)`
    ${props => props.theme.fonts.perimeterLegend}
    text-transform: uppercase;
`
const AgencyLine = styled(View)<{ odd: boolean }>`
    flex-direction: row;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
    height: ${props => `${props.theme.constants.rightLineHeight}px`};
    background-color: ${props => !props.odd && props.theme.colors.evenLines};

    ${props =>
        Platform.OS === 'web' &&
        `&:hover {
        background-color: ${props.theme.colors.hoverBackground};
        & > div {
            color: ${props.theme.colors.hoverText};
            text-decoration-color: ${props.theme.colors.hoverText};
            & > div {
                color: ${props.theme.colors.hoverText};
                text-decoration-color: ${props.theme.colors.hoverText};
            }
        }
    }`}
`
const AgencyName = styled(Text)`
    flex: 1;
    ${props => props.theme.fonts.perimeterName}
    font-family: Avenir-Heavy;
`
const NoAgencyMessage = styled(Text)`
    ${props => props.theme.fonts.perimeterName}
    text-align: center;
    margin-top: 15px;
`
const Action = styled(Text)`
    ${props => props.theme.fonts.homeActionLink}
    text-decoration: underline;
    text-decoration-color: ${props => props.theme.fonts.homeActionLink.color};
`
