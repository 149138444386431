import * as React from 'react'

// Components
import { View, Text } from 'react-native'
import FieldInformation from '@components/fieldInformation/FieldInformation'
import { TouchableItemFeedback } from '@components/touchable/Touchable'
import Icon from '@components/icon/Icon'
import ContactModal from './SelectIcpContactModal'
import Button from '@components/button/Button'
import DeclarationInformation from '../planIcp/DeclarationInformation'
// Store
import useI18n from '@store/i18n/useI18n'

// Style
import styled from '@styles/index'
import api from '@app/api/api'
import { confirmAlert, basicAlert } from '@components/alert/Alerts'
import { produce } from 'immer'
import useTheme from '@styles/useTheme'

interface Props {
    values: IcpContact[]
    required?: boolean
    label?: string
    error?: any
    onChange: (contacts: IcpContact[]) => void
    icpId?: string
    canEditContacts?: boolean
    handleSubmitWithoutAlert: () => Promise<void>
    globalActors: ActorsIcp
    globalInfos: InformationsIcp
    updateActors: (values: ActorsIcp) => void
    readyToSendInvitation?: boolean
    setEmailSent: () => void
    setIsSendingEmail: (isSendingEmail: boolean) => void
    isSendingEmail: boolean
}

const I18N_ITEM_KEY = 'components.addIcpContacts'

type ButtonAction = 'noAction' | 'relaunchAndSend' | 'onlySending' | 'onlyRelaunch'

export default (props: Props) => {
    const {
        values,
        required,
        label,
        onChange,
        error,
        icpId,
        canEditContacts,
        handleSubmitWithoutAlert,
        globalActors,
        globalInfos,
        updateActors,
        readyToSendInvitation,
        setEmailSent,
        setIsSendingEmail,
        isSendingEmail,
    } = props

    const i18n = useI18n()
    const [Theme] = useTheme()

    const [modalVisible, setModalVisible] = React.useState<boolean>(false)
    const [itemToEdit, setItemToEdit] = React.useState<IcpContact | undefined>(undefined)
    const [itemOpened, setItemOpened] = React.useState<number | undefined>(undefined)

    const [invitationsSending, setInvitationsSending] = React.useState<boolean>(false)

    const renderModeleState = (etat: PlanificationState) => {
        return (
            <ModeleStateContainer>
                <ModelStateTag etat={etat}>
                    <ModelStateText etat={etat}>
                        {i18n.t(`components.addIcpContacts.contactStates.${etat}`)}
                    </ModelStateText>
                </ModelStateTag>
            </ModeleStateContainer>
        )
    }

    const sendInvitations = (id: string, action: ButtonAction) => {
        setIsSendingEmail(true)
        setInvitationsSending(true)
        confirmAlert(
            i18n,
            I18N_ITEM_KEY,
            action,
            () =>
                handleSubmitWithoutAlert()
                    .then(() =>
                        api.icp
                            .sendInvitations(id)
                            .then(icp => {
                                setIsSendingEmail(false)
                                basicAlert(
                                    i18n,
                                    I18N_ITEM_KEY,
                                    'sendingInvitations',
                                    () => {
                                        setInvitationsSending(false)
                                        onChange(icp.planification.contacts)
                                        setEmailSent()
                                    },
                                    'success',
                                    Theme,
                                )
                            })
                            .catch(err => {
                                setIsSendingEmail(false)
                                basicAlert(
                                    i18n,
                                    I18N_ITEM_KEY,
                                    'sendingInvitations',
                                    () => setInvitationsSending(false),
                                    'error',
                                    Theme,
                                )
                            }),
                    )
                    .catch(() => setInvitationsSending(false)),
            () => {
                setIsSendingEmail(false)
                setInvitationsSending(false)
            },
            Theme,
        )
        //setInvitationsSending(false)
    }

    const buttonAction = React.useMemo(
        (): ButtonAction =>
            !(canEditContacts && values.length !== 0)
                ? 'noAction'
                : values.find(c => c.state === 'notSent')
                ? values.find(c => c.state === 'waiting')
                    ? 'relaunchAndSend'
                    : 'onlySending'
                : 'onlyRelaunch',
        [values, canEditContacts],
    )

    return (
        <View>
            <ContactModal
                modalVisible={modalVisible}
                onChange={onChange}
                onRequestClose={() => {
                    setModalVisible(false)
                    setItemToEdit(undefined)
                }}
                contacts={values}
                initialValues={itemToEdit}
                globalActors={globalActors}
                globalInfos={globalInfos}
                updateActors={updateActors}
            />
            <FieldInformation label={label} required={required} error={error} />
            <Container error={!!error}>
                <RowContainer>
                    <Legend>{i18n.t('components.addIcpContacts.name')}</Legend>
                    <EditContactContainer>
                        {canEditContacts ? (
                            <TouchableItemFeedback onPress={() => setModalVisible(true)}>
                                <Action>{i18n.t('components.addIcpContacts.addContact')}</Action>
                            </TouchableItemFeedback>
                        ) : (
                            <View />
                        )}
                        <DeclarationInformation propsKey={'contacts'} />
                    </EditContactContainer>
                </RowContainer>
                <ContactList>
                    {values.length === 0 ? (
                        <NoContact>{i18n.t('components.addIcpContacts.noContact')}</NoContact>
                    ) : (
                        values.map((contact, index) => (
                            <TouchableItemFeedback
                                key={`contact${index}`}
                                onPress={() => setItemOpened(itemOpened === index ? undefined : index)}
                            >
                                <ContactContainer odd={index % 2 === 1}>
                                    <RowContainer>
                                        <ContactName
                                            ellipsizeMode={'tail'}
                                            numberOfLines={itemOpened === index ? 2 : 1}
                                        >{`${contact.firstName} ${contact.lastName}`}</ContactName>
                                        <Line>
                                            {renderModeleState(contact.state)}
                                            <Icon
                                                name={'information'}
                                                size={Theme.constants.riskFamilyInformationIconSize}
                                                color={
                                                    itemOpened === index
                                                        ? Theme.colors.riskFamilyItemInformationSelectedIcon
                                                        : Theme.colors.riskFamilyItemInformationIcon
                                                }
                                            />
                                        </Line>
                                    </RowContainer>

                                    {itemOpened === index && (
                                        <View>
                                            <RowContainer>
                                                <ContactColumn double={true}>
                                                    <ContactInformation>{contact.email}</ContactInformation>
                                                    <ContactInformation>{contact.telephone1}</ContactInformation>
                                                    <ContactInformation>{contact.telephone2}</ContactInformation>
                                                </ContactColumn>
                                                <ContactColumn>
                                                    {!!contact.companyName && (
                                                        <ContactInformation main={true}>
                                                            {contact.companyName}
                                                        </ContactInformation>
                                                    )}
                                                    {!!contact.entityName && (
                                                        <ContactInformation>{contact.entityName}</ContactInformation>
                                                    )}
                                                </ContactColumn>
                                            </RowContainer>
                                            <RowContainer>
                                                <TouchableItemFeedback
                                                    onPress={() => {
                                                        setItemToEdit(contact)
                                                        setModalVisible(true)
                                                    }}
                                                >
                                                    <Action>{i18n.t('components.addIcpContacts.edit')}</Action>
                                                </TouchableItemFeedback>
                                                <TouchableItemFeedback
                                                    onPress={() => {
                                                        onChange(values.filter((c, i) => i !== index))
                                                        const newRepresentatives = globalActors.representatives.filter(
                                                            r =>
                                                                !(
                                                                    r.email === contact.email &&
                                                                    r.lastName === contact.lastName &&
                                                                    r.firstName === contact.firstName
                                                                ),
                                                        )
                                                        updateActors(
                                                            produce(globalActors, draft => {
                                                                draft.representatives = newRepresentatives
                                                            }),
                                                        )
                                                        setItemOpened(undefined)
                                                    }}
                                                >
                                                    <Action>{i18n.t('components.addIcpContacts.delete')}</Action>
                                                </TouchableItemFeedback>
                                            </RowContainer>
                                        </View>
                                    )}
                                </ContactContainer>
                            </TouchableItemFeedback>
                        ))
                    )}
                </ContactList>
            </Container>
            {icpId && buttonAction !== 'noAction' && readyToSendInvitation && (
                <ButtonContainer>
                    <Button
                        libelle={i18n.t(`components.addIcpContacts.send.${buttonAction}`)}
                        status={invitationsSending ? 'loading' : isSendingEmail ? 'disabled' : 'active'}
                        onPress={() => sendInvitations(icpId, buttonAction)}
                    />
                </ButtonContainer>
            )}
        </View>
    )
}
const Line = styled(View)`
    flex-direction: row;
`
const ContactContainer = styled(View)<{ odd?: boolean }>`
    background-color: ${props => !props.odd && props.theme.colors.evenLines};
`
const Container = styled(View)<{ error?: boolean }>`
    background-color: ${props => props.theme.colors.addIcpContactsBackground};
    padding-right: 8px;
    padding-left: 8px;
    ${props => props.error && 'border: ' + props.theme.colors.textInputBorderError + ' solid 1px;'}
`
const RowContainer = styled(View)`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: ${props => props.theme.constants.fieldPadding};
    padding-left: ${props => props.theme.constants.fieldPadding};
    min-height: 36px;
`
const Legend = styled(Text)`
    ${props => props.theme.fonts.rightLegend}
    text-transform: uppercase;
`
const Action = styled(Text)`
    ${props => props.theme.fonts.homeActionLink}
    text-decoration: underline;
    text-decoration-color: ${props => props.theme.fonts.homeActionLink.color};
`
const ContactList = styled(View)`
    padding-bottom: 8px;
`
const NoContact = styled(Text)`
    ${props => props.theme.fonts.perimeterName}
    text-align: center;
    padding-top: 24px;
    padding-bottom: 24px;
`
const ContactName = styled(Text)`
    flex: 1;
    ${props => props.theme.fonts.icpContactName}
`
const ModeleStateContainer = styled(View)`
    width: 90px;
    margin-right: 24px;
    text-align: center;
`
const ModelStateTag = styled(View)<{ etat: PlanificationState }>`
    background-color: ${props =>
        props.etat === 'waiting'
            ? props.theme.colors.icpStateTagBackgroundEnAttente
            : props.etat === 'accepted'
            ? props.theme.colors.icpStateTagBackgroundAccepte
            : props.etat === 'refused'
            ? props.theme.colors.icpStateTagBackgroundRefuse
            : props.theme.colors.icpStateTagBackgroundPasEnvoye};
    border-radius: 40px;
    padding-left: 10px;
    padding-right: 10px;
`
const ModelStateText = styled(Text)<{ etat: PlanificationState }>`
    ${props => props.theme.fonts.homeActionLink}
    text-align: center;
    color: ${props =>
        props.etat === 'waiting'
            ? props.theme.colors.modeleStateTagTextBrouillon
            : props.theme.colors.modeleStateTagTextOther};
`
const ButtonContainer = styled(View)`
    margin-top: 10px;
    flex-direction: row;
    justify-content: flex-end;
`
const ContactInformation = styled(Text)<{ main?: boolean }>`

    ${props => (props.main ? props.theme.fonts.contactMainInformation : props.theme.fonts.contactInformation)}
    margin-left: ${props => !props.main && '20px'};
`
const ContactColumn = styled(View)<{ double?: boolean }>`
    flex: ${props => (props.double ? '2' : '1')};
    flex-direction: column;
`
const EditContactContainer = styled(View)`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 150px;
`
