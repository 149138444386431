import { createEvent, createStore } from 'effector'

export const initialState: IcpFilters = {
    direction: undefined,
    structure: undefined,
    perimeter: undefined,
    sector: undefined,
    hasPerimeterFilterBeenSelected: false,
    selectedPerimetersIds: [],
    searchText: '',
    selectedFilters: ['all'],
    lastScreen: 'home',
}

export const actions = {
    setDirection: createEvent<Perimeter | undefined>('setDirection'),
    setStructure: createEvent<Perimeter | undefined>('setStructure'),
    setPerimeter: createEvent<Perimeter | undefined>('setPerimeter'),
    setSector: createEvent<Perimeter | undefined>('setSector'),
    setHasPerimeterFilterBeenSelected: createEvent<boolean>('setHasPerimeterFilterBeenSelected'),
    setSelectedPerimetersIds: createEvent<string[]>('setSelectedPerimetersIds'),
    setSearchText: createEvent<string>('setSearchText'),
    setSelectedFilters: createEvent<FilterType[]>('setSelectedFilters'),
    setLastScreen: createEvent<'home' | 'icp'>('setLastScreen'),
    reset: createEvent(),
}

export const store = createStore<IcpFilters>(initialState, { name: "Filtres liste d'ICP/PPEE" })
    .on(actions.setDirection, (state, direction) => ({ ...state, direction }))
    .on(actions.setStructure, (state, structure) => ({ ...state, structure }))
    .on(actions.setPerimeter, (state, perimeter) => ({ ...state, perimeter }))
    .on(actions.setSector, (state, sector) => ({ ...state, sector }))
    .on(actions.setHasPerimeterFilterBeenSelected, (state, hasPerimeterFilterBeenSelected) => ({
        ...state,
        hasPerimeterFilterBeenSelected,
    }))
    .on(actions.setSelectedPerimetersIds, (state, selectedPerimetersIds) => ({ ...state, selectedPerimetersIds }))
    .on(actions.setSearchText, (state, searchText) => ({ ...state, searchText }))
    .on(actions.setSelectedFilters, (state, selectedFilters) => ({ ...state, selectedFilters }))
    .on(actions.setLastScreen, (state, lastScreen) => ({ ...state, lastScreen }))
    .on(actions.reset, () => initialState)
