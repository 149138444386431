import * as React from 'react'
import Picker from '@components/picker/Picker'
import useI18n from '@store/i18n/useI18n'
import { displayUserName } from '@utils/string'

interface Props {
    key?: number
    onChange: (index: number) => void
    collaborateursToDisplay: UserRedacteur[]
}

export const InternRepresentativePicker = ({ key, onChange, collaborateursToDisplay }: Props) => {
    const i18n = useI18n()

    return (
        <Picker
            key={key}
            data={collaborateursToDisplay.map(displayUserName)}
            onChange={onChange}
            placeholder={i18n.t('components.planIcp.placeholders.collaborateur')}
            label={i18n.t('components.planIcp.labels.collaborateur')}
            required={false}
            isClearable={false}
            disabled={collaborateursToDisplay.length === 0}
        />
    )
}
