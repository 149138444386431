import i18next from 'i18next'
import dateFormat from 'date-fns/format'
import { createEvent, createStore } from 'effector'
import InitialLang from './i18n/deviceLang'
import { fr, enUS as en } from 'date-fns/locale'

const supportedLang = ['fr', 'en']
const fallbackLang = 'en'

const resources = {
    fr: {
        translation: {
            ...require('./i18n/locales/fr.json'),
        },
    },
    en: {
        translation: {
            ...require('./i18n/locales/en.json'),
        },
    },
}
const locales: { [key: string]: Locale } = {
    fr,
    en,
}

i18next.init({
    // debug: true,
    supportedLngs: supportedLang,
    fallbackLng: fallbackLang,
    interpolation: {
        escapeValue: false,
        format: (value, f, lng) => {
            let format = f

            const formatKey = 'formats.' + format
            const i18nforma = i18next.t(formatKey)
            if (i18nforma !== formatKey) {
                format = i18nforma
            }

            // You can extends functionality here by providing custom format function based on value and format
            if (format && value instanceof Date) {
                const locale = locales[lng || fallbackLang]
                const s = dateFormat(value, format, { locale })
                return s
            }
            return value
        },
    },
    resources,
})

const i18n = (lng: string) => {
    const lang = supportedLang.find(l => l === lng) || supportedLang.find(l => lng.indexOf(l) > -1) || fallbackLang
    i18next.changeLanguage(lang)
    return {
        // eslint-disable-next-line prefer-spread
        t: (...args: any) => i18next.t.apply(i18next, args),
        lang,
    } as I18nState
}

export const actions = {
    setLang: createEvent<string>('setLang'),
}

export const store = createStore<I18nState>(i18n(InitialLang)).on(actions.setLang, (_, lang) => i18n(lang))
