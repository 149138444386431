import * as React from 'react'

// Components
import { Text, View } from 'react-native'
import Picker from '@components/picker/Picker'
import Icon from '@components/icon/Icon'

// Store
import useI18n from '@store/i18n/useI18n'

// Style
import styled from '@styles/index'
import useTheme from '@styles/useTheme'

interface Props {
    type: TypeQuestion
    options?: string[]
}

export default (props: Props) => {
    const { type, options } = props

    const i18n = useI18n()
    const [Theme] = useTheme()

    switch (type) {
        case 'textInputAlpha':
        case 'textInputNum':
        case 'date':
            return (
                <Input>
                    <InputText>{i18n.t(`components.modeleDemoComponent.placeholders.${type}`)}</InputText>
                </Input>
            )

        case 'radio':
        case 'checkboxes':
            return (
                <CheckboxLineContainer>
                    {options?.map((option, index) => (
                        <CheckboxItem key={`option${index}`}>
                            {type === 'radio' ? (
                                <Radio />
                            ) : (
                                <Icon name={'checkbox-unchecked'} size={14} color={Theme.colors.checkbox} />
                            )}

                            <Label>
                                {option || `${i18n.t(`components.modeleDemoComponent.unknownOption`)} ${index + 1}`}
                            </Label>
                        </CheckboxItem>
                    ))}
                </CheckboxLineContainer>
            )

        case 'select':
            return (
                <Picker
                    data={
                        options?.map(
                            (option, index) =>
                                option || `${i18n.t(`components.modeleDemoComponent.unknownOption`)} ${index + 1}`,
                        ) || []
                    }
                    onChange={() => undefined}
                    isClearable
                />
            )

        case 'drawing':
        case 'pictures':
            return (
                <DropZoneContainer>
                    <Icon
                        name={type === 'drawing' ? 'edit' : 'cloud-upload'}
                        size={50}
                        color={Theme.colors.uploadIconColor}
                    />
                </DropZoneContainer>
            )

        default:
            return <InputText>{i18n.t('components.modeleDemoComponent.unknownType')}</InputText>
    }
}

const Input = styled(View)`
  ${props => props.theme.fonts.inputText}
  border-radius: ${props => props.theme.constants.inputBorderRadius};
  background-color: ${props => props.theme.colors.textInputBackground};
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: ${props => props.theme.constants.fieldPadding};
  padding-right: ${props => props.theme.constants.fieldPadding};
  min-height: ${props => props.theme.constants.textInputHeight}px;
  flex-direction: row;
  align-items: center;
`
const InputText = styled(Text)`
    ${props => props.theme.fonts.inputText}
    color:  ${props => props.theme.colors.fieldPlaceholder}
`
const CheckboxLineContainer = styled(View)`
    background-color: ${props => props.theme.colors.rubriqueBackground};
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 24px;
    padding-right: 24px;
    flex-direction: row;
    flex-wrap: wrap;
`
const CheckboxItem = styled(View)`
    flex-direction: row;
    align-items: center;
    margin-right: 48px;
`
const Label = styled(Text)`
    ${props => props.theme.fonts.checkboxLabel}
    font-size: 14px;
    margin-left: ${props => props.theme.constants.checkboxLabelPadding};
    text-align: justify;
`
const DropZoneContainer = styled(View)`
    justify-content: center;
    align-items: center;
    height: ${props => props.theme.constants.formImageInputPictureSize};
    width: ${props => props.theme.constants.formImageInputPictureSize};
    background-color: ${props => props.theme.colors.imageDropInputBackground};
`
const Radio = styled(View)`
    height: 14px;
    width: 14px;
    border-radius: 20px;
    border-width: 1px;
    border-color: ${props => props.theme.colors.checkbox};
`
