import * as React from 'react'

// Components
import { View, Text } from 'react-native'
import { TouchableItemFeedback } from '@components/touchable/Touchable'
import Button from '@components/button/Button'
import RootScreen from '@components/rootScreen/RootScreen'
import { Formik, FormikErrors } from 'formik'
import ImageDropInput from '@components/imageDropInput/ImageDropInput'
import TextInput from '@components/textInput/TextInput'
import Alert from '@components/alert/Alert'
import RiskFamilyItemsList from '@components/riskFamilyItemsList/RiskFamilyItemsList'

// Utils
import useNavigation from '@layout/useNavigation'
import Logger from '@utils/logger'

// Store
import useI18n from '@store/i18n/useI18n'

// Api
import api from '@api/api'

// Style
import styled from '@styles/index'
import Checkbox from '@components/checkbox/Checkbox'
import useTheme from '@styles/useTheme'

interface Props {
    riskFamilyId: string
}

const trimValues = (values: CreateFamily): CreateFamily => ({
    ...values,
    libelle: values.libelle.trim(),
})

export default (props: Props) => {
    const { riskFamilyId } = props
    const i18n = useI18n()
    const navigation = useNavigation()
    const [Theme] = useTheme()

    const [status, setStatus] = React.useState<Status>('loading')
    const [initialValues, setInitialValues] = React.useState<CreateFamily | undefined>(undefined)
    const [openedEditor, setOpenedEditor] = React.useState<ListTypes | undefined>(undefined)

    React.useEffect(() => {
        if (!!riskFamilyId) {
            api.admin
                .getRiskFamily(riskFamilyId)
                .then(family => {
                    setInitialValues({
                        libelle: family.libelle,
                        picto: family.picto,
                        mandatory: family.mandatory,
                        risks: family.risk ? [...family.risk] : [],
                        measures: family.measure ? [...family.measure] : [],
                    })
                    setStatus('fetched')
                })
                .catch(err => {
                    Logger.error(err)
                    setStatus('error')
                })
        } else {
            setStatus('fetched')
        }
    }, [riskFamilyId])

    const validateForm = (values: CreateFamily): FormikErrors<CreateFamily> => {
        const trimedValues = trimValues(values)
        const errors: FormikErrors<CreateFamily> = {}

        if (!trimedValues.libelle) {
            errors.libelle = i18n.t('screens.addUserAdminScreen.required')
        }
        if (!trimedValues.picto) {
            errors.picto = i18n.t('screens.addUserAdminScreen.required')
        }
        return errors
    }

    const onDeleteConfirm = () =>
        api.admin
            .deleteRiskFamily(riskFamilyId)
            .then(() =>
                Alert({
                    title: i18n.t(`screens.editRiskFamilyAdminScreen.alerts.successDeleteTitle`),
                    content: i18n.t(`screens.editRiskFamilyAdminScreen.alerts.successDeleteContent`),
                    icon: 'success',
                    buttons: [
                        {
                            style: 'default',
                            text: i18n.t('screens.perimetersAdminScreen.alerts.confirmButton'),
                            onPress: () => navigation.push('/famillesDeRisque'),
                        },
                    ],
                    theme: Theme,
                }),
            )
            .catch(err => {
                Alert({
                    title: i18n.t(`screens.editRiskFamilyAdminScreen.alerts.errorDeleteTitle`),
                    content: i18n.t(`screens.editRiskFamilyAdminScreen.alerts.errorDeleteContent`),
                    icon: 'error',
                    buttons: [
                        {
                            style: 'default',
                            text: i18n.t('screens.perimetersAdminScreen.alerts.confirmButton'),
                            onPress: () => undefined,
                        },
                    ],
                    theme: Theme,
                })
                Logger.error(err)
            })

    const handleDelete = () =>
        Alert({
            title: i18n.t(`screens.editRiskFamilyAdminScreen.alerts.confirmDeleteTitle`),
            content: i18n.t(`screens.editRiskFamilyAdminScreen.alerts.confirmDeleteContent`),
            icon: 'warning',
            buttons: [
                {
                    style: 'default',
                    text: i18n.t('screens.perimetersAdminScreen.alerts.confirmButton'),
                    onPress: onDeleteConfirm,
                },
                {
                    style: 'cancel',
                    text: i18n.t('screens.perimetersAdminScreen.alerts.cancelButton'),
                    onPress: () => undefined,
                },
            ],
            cancelable: true,
            theme: Theme,
        })

    const onSubmitConfirmed = (
        values: CreateFamily,
        setSubmitting: (isSubmitting: boolean) => void,
        action: 'Edit' | 'Add',
    ) => {
        const trimedValues = trimValues(values)
        ;(action === 'Add'
            ? api.admin.addRiskFamily(trimedValues)
            : api.admin.editRiskFamily(
                  {
                      picto: trimedValues.picto,
                      mandatory: trimedValues.mandatory,
                      libelle: trimedValues.libelle,
                      risk: trimedValues.risks,
                      measure: trimedValues.measures,
                      id: riskFamilyId,
                  } as AdminFamily,
                  riskFamilyId,
              )
        )
            .then(() =>
                Alert({
                    title: i18n.t(`screens.editRiskFamilyAdminScreen.alerts.success${action}Title`),
                    content: i18n.t(`screens.editRiskFamilyAdminScreen.alerts.success${action}Content`),
                    icon: 'success',
                    buttons: [
                        {
                            style: 'default',
                            text: i18n.t('screens.perimetersAdminScreen.alerts.confirmButton'),
                            onPress: () => {
                                setSubmitting(false)
                                navigation.push('/famillesDeRisque')
                            },
                        },
                    ],
                    theme: Theme,
                }),
            )
            .catch(err => {
                Alert({
                    title: i18n.t(`screens.editRiskFamilyAdminScreen.alerts.error${action}Title`),
                    content: i18n.t(`screens.editRiskFamilyAdminScreen.alerts.error${action}Content`),
                    icon: 'error',
                    buttons: [
                        {
                            style: 'default',
                            text: i18n.t('screens.perimetersAdminScreen.alerts.confirmButton'),
                            onPress: () => setSubmitting(false),
                        },
                    ],
                    theme: Theme,
                })
                Logger.error(err)
            })
    }

    const handleSubmit = (values: CreateFamily, setSubmitting: (isSubmitting: boolean) => void) => {
        const action = !!riskFamilyId ? 'Edit' : 'Add'
        Alert({
            title: i18n.t(`screens.editRiskFamilyAdminScreen.alerts.confirm${action}Title`),
            content: i18n.t(`screens.editRiskFamilyAdminScreen.alerts.confirm${action}Content`),
            icon: 'warning',
            buttons: [
                {
                    style: 'default',
                    text: i18n.t('screens.perimetersAdminScreen.alerts.confirmButton'),
                    onPress: () => onSubmitConfirmed(values, setSubmitting, action),
                },
                {
                    style: 'cancel',
                    text: i18n.t('screens.perimetersAdminScreen.alerts.cancelButton'),
                    onPress: () => setSubmitting(false),
                },
            ],
            cancelable: true,
            theme: Theme,
        })
    }

    return (
        <RootScreen status={status} scrollable>
            <Formik
                initialValues={
                    initialValues ||
                    ({
                        libelle: '',
                        picto: '',
                        mandatory: false,
                        risks: [],
                        measures: [],
                    } as CreateFamily)
                }
                onSubmit={(values, { setSubmitting }) => handleSubmit(values, setSubmitting)}
                validate={validateForm}
            >
                {({ handleSubmit, values, setFieldValue, isSubmitting, errors }) => {
                    const valuesRisks = values.risks.map(
                        risk =>
                            ({
                                libelle: risk.libelle,
                                relatedItems: risk.measures,
                            } as RiskFamilyItem),
                    )
                    const valuesMeasures = values.measures.map(
                        measure => ({ libelle: measure.libelle, relatedItems: measure.risks } as RiskFamilyItem),
                    )
                    return (
                        <View>
                            <FieldsContainer>
                                <ImageField>
                                    <ImageDropInput
                                        onChange={imageSrc => setFieldValue('picto', imageSrc)}
                                        value={values.picto}
                                        label={i18n.t('screens.editRiskFamilyAdminScreen.fields.image')}
                                        isClearable
                                        error={errors.picto}
                                        required
                                    />
                                </ImageField>
                                <TextInputContainer>
                                    <TextInput
                                        onTextChange={text => setFieldValue('libelle', text)}
                                        label={i18n.t('screens.editRiskFamilyAdminScreen.fields.name')}
                                        value={values.libelle}
                                        error={errors.libelle}
                                        required
                                    />
                                    <Checkbox
                                        onCheck={() => setFieldValue('mandatory', !values.mandatory)}
                                        label={i18n.t('screens.editRiskFamilyAdminScreen.fields.mandatory')}
                                        value={values.mandatory}
                                    />
                                </TextInputContainer>
                            </FieldsContainer>
                            <FieldsContainer>
                                <RisksContainer>
                                    <RiskFamilyItemsList
                                        itemsKey={'risks'}
                                        items={valuesRisks}
                                        subItems={valuesMeasures}
                                        onItemsChange={newItems =>
                                            setFieldValue(
                                                'risks',
                                                newItems.map(
                                                    risk =>
                                                        ({
                                                            libelle: risk.libelle,
                                                            measures: risk.relatedItems,
                                                        } as AdminRisk),
                                                ),
                                            )
                                        }
                                        updateRelatedItems={newItems =>
                                            setFieldValue(
                                                'measures',
                                                newItems.map(
                                                    measure =>
                                                        ({
                                                            libelle: measure.libelle,
                                                            risks: measure.relatedItems,
                                                        } as AdminMeasure),
                                                ),
                                            )
                                        }
                                        inputOpened={openedEditor === 'risks'}
                                        setInputOpened={(value?: ListTypes) => setOpenedEditor(value)}
                                    />
                                </RisksContainer>
                                <RiskFamilyItemsList
                                    itemsKey={'measures'}
                                    items={valuesMeasures}
                                    subItems={valuesRisks}
                                    onItemsChange={newItems =>
                                        setFieldValue(
                                            'measures',
                                            newItems.map(
                                                measure =>
                                                    ({
                                                        libelle: measure.libelle,
                                                        risks: measure.relatedItems,
                                                    } as AdminMeasure),
                                            ),
                                        )
                                    }
                                    updateRelatedItems={newItems =>
                                        setFieldValue(
                                            'risks',
                                            newItems.map(
                                                risk =>
                                                    ({
                                                        libelle: risk.libelle,
                                                        measures: risk.relatedItems,
                                                    } as AdminRisk),
                                            ),
                                        )
                                    }
                                    inputOpened={openedEditor === 'measures'}
                                    setInputOpened={(value?: ListTypes) => setOpenedEditor(value)}
                                />
                            </FieldsContainer>
                            <ButtonsContainer>
                                {!!riskFamilyId && (
                                    <ActionContainer>
                                        <TouchableItemFeedback onPress={handleDelete}>
                                            <Action>
                                                {i18n.t('screens.editRiskFamilyAdminScreen.deleteRiskFamily')}
                                            </Action>
                                        </TouchableItemFeedback>
                                    </ActionContainer>
                                )}

                                <Button
                                    status={isSubmitting ? 'loading' : 'active'}
                                    onPress={handleSubmit}
                                    libelle={i18n.t(
                                        `screens.editRiskFamilyAdminScreen.${!!riskFamilyId ? 'save' : 'add'}`,
                                    )}
                                    width={Theme.constants.editRiskButtonWidth}
                                />
                            </ButtonsContainer>
                        </View>
                    )
                }}
            </Formik>
        </RootScreen>
    )
}

const FieldsContainer = styled(View)`
    flex-direction: row;
    margin-bottom: 40px;
`
const ImageField = styled(View)`
    width: ${props => props.theme.constants.editRiskFamilyImageFieldWidth}px;
    margin-right: 40px;
`
const ButtonsContainer = styled(View)`
    flex-direction: row;
    align-items: center;
    justify-content: center;
`
const ActionContainer = styled(View)`
    margin-right: 40px;
`
const TextInputContainer = styled(View)`
    flex: 1;
    justify-content: space-around;
`
const Action = styled(Text)`
    ${props => props.theme.fonts.homeActionLink}
    flex: 1;
    text-decoration: underline;
`
const RisksContainer = styled(View)`
    flex: 1 ;
    margin-right 40px;
`
