import * as React from 'react'

// Components
import { View, Text, Image, TouchableOpacity, Platform } from 'react-native'
import { TouchableItemFeedback } from '@components/touchable/Touchable'
import Icon from '@components/icon/Icon'
import AddIcpDocumentsModal from './AddIcpDocumentsModal'
import ImagesModal from '@components/imagesModal/ImagesModal'

// Utils
import { isPdf, openPdfInTab } from '@utils/pdfCommonUtils'

// Store
import useI18n from '@store/i18n/useI18n'

// Style
import styled from '@styles/index'
import DocumentVisualization from './DocumentVisualization'
import useTheme from '@styles/useTheme'

interface Props {
    values: DocumentIcp[]
    error?: any
    onChange: (docs: DocumentIcp[]) => void
    maxDocuments?: number
    canOrderDocuments?: boolean
    documentGoUp?: (idx: number) => void
    documentGoDown?: (idx: number) => void
}

export default (props: Props) => {
    const {
        values,
        onChange,
        error,
        maxDocuments,
        canOrderDocuments = false,
        documentGoDown = (idx: number) => null,
        documentGoUp = (idx: number) => null,
    } = props

    const i18n = useI18n()
    const [Theme] = useTheme()

    const [modalVisible, setModalVisible] = React.useState<boolean>(false)
    const [itemOpened, setItemOpened] = React.useState<number | undefined>(undefined)
    const [itemToEdit, setItemToEdit] = React.useState<DocumentIcp | undefined>(undefined)
    const [imagesToDisplay, setImagesToDisplay] = React.useState<string[]>([])
    const [initImage, setInitImage] = React.useState<number>(0)
    const [mobilePdfVisible, setMobilePdfVisible] = React.useState<string | undefined>(undefined)

    return (
        <View>
            {modalVisible && (
                <AddIcpDocumentsModal
                    initialValues={itemToEdit}
                    modalVisible={modalVisible}
                    onRequestClose={() => {
                        setModalVisible(false)
                        setItemToEdit(undefined)
                    }}
                    onChange={onChange}
                    documents={values}
                />
            )}

            <ImagesModal
                visible={imagesToDisplay.length > 0}
                onRequestClose={() => {
                    setImagesToDisplay([])
                    setInitImage(0)
                }}
                initImage={initImage}
                images={imagesToDisplay.filter(val => !isPdf(val))}
            />

            <Container error={!!error}>
                <RowContainer>
                    <Legend>{i18n.t('components.addIcpDocuments.name')}</Legend>
                    {(maxDocuments === undefined || values.length < maxDocuments) && (
                        <TouchableItemFeedback onPress={() => setModalVisible(true)}>
                            <Action>{i18n.t('components.addIcpDocuments.addDocument')}</Action>
                        </TouchableItemFeedback>
                    )}
                </RowContainer>
                <DocumentList>
                    {values.length === 0 ? (
                        <NoDocument>{i18n.t('components.addIcpDocuments.noDocument')}</NoDocument>
                    ) : (
                        values.map((document, index) => {
                            const imagesOnly = document.pages.filter(page => !isPdf(page.image))
                            return (
                                <TouchableItemFeedback
                                    key={`document${index}`}
                                    onPress={() => setItemOpened(itemOpened === index ? undefined : index)}
                                >
                                    <DocumentContainer odd={index % 2 === 1}>
                                        <RowContainer
                                            needLeftSpace={canOrderDocuments && index === 0}
                                            needRightSpace={canOrderDocuments && index === values.length - 1}
                                        >
                                            {canOrderDocuments && index !== 0 && (
                                                <ArrowContainer
                                                    onPress={() => {
                                                        !!itemOpened && setItemOpened(undefined)
                                                        documentGoUp(index)
                                                    }}
                                                    isLeft
                                                >
                                                    <Icon
                                                        name="chevron-up"
                                                        size={15}
                                                        color={Theme.colors.documentChevron}
                                                    />
                                                </ArrowContainer>
                                            )}
                                            <DocumentName ellipsizeMode={'tail'} numberOfLines={2}>
                                                {document.name}
                                            </DocumentName>
                                            <Icon
                                                name={'information'}
                                                size={Theme.constants.riskFamilyInformationIconSize}
                                                color={
                                                    itemOpened === index
                                                        ? Theme.colors.riskFamilyItemInformationSelectedIcon
                                                        : Theme.colors.riskFamilyItemInformationIcon
                                                }
                                            />
                                            {canOrderDocuments && index !== values.length - 1 && (
                                                <ArrowContainer
                                                    isRight
                                                    onPress={() => {
                                                        !!itemOpened && setItemOpened(undefined)
                                                        documentGoDown(index)
                                                    }}
                                                >
                                                    <Icon
                                                        name="chevron-down"
                                                        size={15}
                                                        color={Theme.colors.documentChevron}
                                                    />
                                                </ArrowContainer>
                                            )}
                                        </RowContainer>
                                        {itemOpened === index && (
                                            <DocumentInformations>
                                                <Preview>
                                                    {document.pages.length === 0 ? (
                                                        <NoDocument>
                                                            {i18n.t('components.addIcpDocuments.noPreview')}
                                                        </NoDocument>
                                                    ) : (
                                                        <RowWrapContainer>
                                                            {document.pages.map((page, j) => (
                                                                <TouchableItemFeedback
                                                                    key={`doc${index}image${j}`}
                                                                    onPress={() => {
                                                                        if (isPdf(page.image)) {
                                                                            if (Platform.OS === 'web') {
                                                                                openPdfInTab(page.image)
                                                                            } else {
                                                                                setMobilePdfVisible(page.image)
                                                                            }
                                                                        } else {
                                                                            setInitImage(imagesOnly.indexOf(page))
                                                                            setImagesToDisplay(
                                                                                document.pages.map(pag => pag.image),
                                                                            )
                                                                        }
                                                                    }}
                                                                >
                                                                    {isPdf(page.image) ? (
                                                                        <PdfButton>
                                                                            <Icon
                                                                                name={'icpToApprove'}
                                                                                size={50}
                                                                                color={Theme.colors.uploadIconColor}
                                                                            />
                                                                        </PdfButton>
                                                                    ) : (
                                                                        <ImageContainer>
                                                                            <ImageValue source={{ uri: page.image }} />
                                                                        </ImageContainer>
                                                                    )}
                                                                </TouchableItemFeedback>
                                                            ))}
                                                        </RowWrapContainer>
                                                    )}
                                                </Preview>

                                                <RowContainer>
                                                    <TouchableItemFeedback
                                                        onPress={() => {
                                                            setItemToEdit(document)
                                                            setModalVisible(true)
                                                        }}
                                                    >
                                                        <Action>{i18n.t('components.addIcpContacts.edit')}</Action>
                                                    </TouchableItemFeedback>

                                                    <TouchableItemFeedback
                                                        onPress={() => {
                                                            onChange(values.filter((c, i) => i !== index))
                                                            setItemOpened(undefined)
                                                        }}
                                                    >
                                                        <Action>{i18n.t('components.addIcpContacts.delete')}</Action>
                                                    </TouchableItemFeedback>
                                                </RowContainer>
                                            </DocumentInformations>
                                        )}
                                    </DocumentContainer>
                                </TouchableItemFeedback>
                            )
                        })
                    )}
                </DocumentList>
                <DocumentVisualization
                    displayingDocument={mobilePdfVisible}
                    closeModal={() => setMobilePdfVisible(undefined)}
                />
            </Container>
        </View>
    )
}

const DocumentContainer = styled(View)<{ odd?: boolean }>`
    background-color: ${props => !props.odd && props.theme.colors.evenLines};
`
const Container = styled(View)<{ error?: boolean }>`
    background-color: ${props => props.theme.colors.addIcpContactsBackground};
    padding-right: 8px;
    padding-left: 8px;
    ${props => props.error && 'border: ' + props.theme.colors.textInputBorderError + ' solid 1px;'}
`
const RowContainer = styled(View)<{ needLeftSpace?: boolean; needRightSpace?: boolean }>`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: ${props => props.theme.constants.fieldPadding};
    padding-left: ${props => props.theme.constants.fieldPadding};
    min-height: 36px;
    ${props => props.needLeftSpace && 'margin-left: 45px;'}
    ${props => props.needRightSpace && 'margin-right: 45px;'}
`
const Legend = styled(Text)`
    ${props => props.theme.fonts.rightLegend}
    text-transform: uppercase;
`
const Action = styled(Text)`
    ${props => props.theme.fonts.homeActionLink}
    text-decoration: underline;
    text-decoration-color: ${props => props.theme.fonts.homeActionLink.color};
`
const DocumentList = styled(View)`
    padding-bottom: 8px;
`
const NoDocument = styled(Text)`
    ${props => props.theme.fonts.perimeterName}
    text-align: center;
    padding-top: 24px;
    padding-bottom: 24px;
`
const DocumentName = styled(Text)`
    flex: 1;
    ${props => props.theme.fonts.icpContactName}
`
const DocumentInformations = styled(View)`
    padding: 24px;
`
const ImageContainer = styled(View)`
    justify-content: center;
    align-items: center;
    height: ${props => props.theme.constants.formImageInputPictureSize};
    width: ${props => props.theme.constants.formImageInputPictureSize};
    margin-right: 24px;
    margin-bottom: 8px;
`
const ImageValue = styled(Image)`
    height: ${props => props.theme.constants.formImageInputPictureSize};
    width: ${props => props.theme.constants.formImageInputPictureSize};
`
const RowWrapContainer = styled(View)`
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: -8px;
`
const Preview = styled(View)`
    margin-bottom: 24px;
`
const PdfButton = styled(View)`
    background-color: ${props => props.theme.colors.imageDropInputBackground};
    justify-content: center;
    align-items: center;
    height: ${props => props.theme.constants.formImageInputPictureSize};
    width: ${props => props.theme.constants.formImageInputPictureSize};
    margin-right: 24px;
    margin-bottom: 8px;
`
const ArrowContainer = styled(TouchableOpacity)<{ isRight?: boolean; isLeft?: boolean }>`
    width: 25px;
    height: 25px;
    border-radius: 50px;
    ${props => `border: 1px solid ${props.theme.colors.documentChevronBorder};`}
    background-color: ${props => props.theme.colors.documentChevronBackground};
    align-items: center;
    justify-content: center;
    ${props => props.isLeft && 'margin-right:20px;'}
    ${props => props.isRight && 'margin-left:20px;'}
    ${props =>
        Platform.OS === 'web' &&
        `&:hover {
        ${`border: 1px solid ${props.theme.colors.documentChevronBorderHovered};`}
        background-color: ${props.theme.colors.documentChevronBackgroundHovered};
        & > div {
            color: ${props.theme.colors.documentChevronHovered};
        }
    }`}
`
