import * as React from 'react'

// Components
import { View, Text, Platform } from 'react-native'
import Picker from '@components/picker/Picker'
import Icon from '@components/icon/Icon'
import Button from '@components/button/Button'
import RootScreen from '@components/rootScreen/RootScreen'
import Alert from '@components/alert/Alert'
import FloatButton from '@components/floatButton/FloatButton'

import useI18n from '@store/i18n/useI18n'

// Api
import api from '@api/api'

// Utils
import useNavigation from '@layout/useNavigation'
import useParams from '@layout/useParams'

// Style
import styled from '@styles/index'
import Logger from '@utils/logger'

// Formik
import { Formik } from 'formik'
import TextInput from '@components/textInput/TextInput'
import * as yup from 'yup'
import useTheme from '@styles/useTheme'

const formikContact: Contact = {
    id: '',
    idAgence: '',
    idEntreprise: '',
    libelleAgence: '',
    nom: '',
    prenom: '',
    mail: '',
    address: '',
    telephone1: '',
    telephone2: '',
}

const unknownAgency: Agence = {
    id: '',
    libelle: '',
    idEntreprise: '',
    telephone: '',
    mail: '',
    adresse: '',
}

const contactYupSchema = (i18n: I18nState) =>
    yup.object().shape(
        {
            idAgence: yup.string().when('libelleAgence', {
                is: libelleAgence => !libelleAgence || libelleAgence.length === 0,
                then: yup.string().required(i18n.t('screens.contactScreen.errors.agenceRequired')),
                otherwise: yup.string(),
            }),
            libelleAgence: yup.string().when('idAgence', {
                is: idAgence => !idAgence || idAgence.length === 0,
                then: yup.string().required(i18n.t('screens.contactScreen.errors.agenceRequired')),
                otherwise: yup.string(),
            }),
            nom: yup.string().required(i18n.t('screens.contactScreen.errors.nameRequired')),
            prenom: yup.string().required(i18n.t('screens.contactScreen.errors.firstNameRequired')),
            mail: yup
                .string()
                .required(i18n.t('screens.contactScreen.errors.mailRequired'))
                .email(i18n.t('screens.contactScreen.errors.mailLibelle')),
        },
        [['idAgence', 'libelleAgence']],
    )

export { contactYupSchema }

export default () => {
    const { contactId } = useParams<{ contactId?: string }>()

    const i18n = useI18n()
    const navigation = useNavigation()
    const [Theme] = useTheme()

    const [status, setStatus] = React.useState<Status>('loading')

    const [initialValues, setInitialValues] = React.useState<Contact>(formikContact)

    const [allCompanies, setAllCompanies] = React.useState<Entreprises>([])
    const [companySelected, setCompanySelected] = React.useState<Entreprise | undefined>(undefined)

    const [allEntities, setAllEntities] = React.useState<Agence[]>([])
    const [entitySelected, setEntitySelected] = React.useState<Agence | undefined>(undefined)

    const [unknownEntity, setUnknownEntity] = React.useState('')

    React.useEffect(() => {
        if (unknownEntity.length > 0) {
            setEntitySelected({ ...unknownAgency, libelle: unknownEntity })
        }
    }, [unknownEntity])

    React.useEffect(() => {
        api.company
            .getAllCompanies()
            .then(c => {
                setAllCompanies(c.sort((a, b) => a.libelle.localeCompare(b.libelle)))
                // - - - -
                if (contactId) {
                    api.contact
                        .getContact(contactId)
                        .then(v => {
                            const company = c.find(ic => ic.id === v.idEntreprise)
                            if (company) {
                                api.company
                                    .getAllAgenciesByCompany(company.id)
                                    .then(e => {
                                        setAllEntities(e.sort((a, b) => a.libelle.localeCompare(b.libelle)))
                                        // - - - -
                                        const entity = e.find(ie => ie.id === v.idAgence)
                                        if (entity) {
                                            setCompanySelected(company)
                                            setEntitySelected(entity)
                                            setInitialValues(v)
                                            setStatus('fetched')
                                        } else {
                                            setStatus('error')
                                            Logger.error("Une erreur est survenue, l'entité est inconnue...")
                                        }
                                    })
                                    .catch(err => {
                                        Logger.error('Une erreur est survenue...', err)
                                        setStatus('error')
                                    })
                            } else {
                                setStatus('error')
                                Logger.error("Une erreur est survenue, l'entreprise est inconnue...")
                            }
                        })
                        .catch(err => {
                            Logger.error('Une erreur est survenue...', err)
                            setStatus('error')
                        })
                } else {
                    setStatus('fetched')
                }
            })
            .catch(err => {
                Logger.error('Une erreur est survenue...', err)
                setStatus('error')
            })
    }, [contactId])

    const onCompanyChange = (i: number) => {
        const c = allCompanies[i]
        setCompanySelected(c)
        setEntitySelected(undefined)

        if (c) {
            api.company
                .getAllAgenciesByCompany(c.id)
                .then(setAllEntities)
                .catch(err => Logger.error(err))
        } else {
            setAllEntities([])
        }
    }

    const alertUser = (
        type: 'edit' | 'create',
        ended: 'success' | 'failure',
        setSubmitting: (isSubmitting: boolean) => void,
    ) => {
        Alert({
            title: i18n.t(`screens.contactScreen.alerts.${type}.${ended}.title`),
            content: i18n.t(`screens.contactScreen.alerts.${type}.${ended}.content`),
            icon: ended === 'failure' ? 'error' : 'success',
            buttons: [
                {
                    style: 'default',
                    text: i18n.t(`screens.contactScreen.alerts.${type}.${ended}.confirm`),
                    onPress: () => {
                        if (ended === 'success') {
                            Platform.OS === 'web' ? navigation.push('/contacts') : navigation.pushFromRoot('contacts')
                        }
                        setSubmitting(false)
                    },
                },
            ],
            cancelable: true,
            theme: Theme,
        })
    }

    const handleEditContact = (values: Contact, setSubmitting: (isSubmitting: boolean) => void) => {
        const { id, idAgence, idEntreprise, libelleAgence, nom, prenom, address, mail, telephone1, telephone2 } = values
        if (!!id) {
            api.contact
                .editContact(values)
                .then(() => alertUser('edit', 'success', setSubmitting))
                .catch(() => alertUser('edit', 'failure', setSubmitting))
        } else {
            api.contact
                .addContact(
                    libelleAgence.length === 0
                        ? ({
                              idAgence,
                              nom,
                              prenom,
                              address,
                              mail,
                              telephone1,
                              telephone2,
                          } as CreateContact)
                        : ({
                              idEntreprise,
                              libelleAgence,
                              nom,
                              prenom,
                              address,
                              mail,
                              telephone1,
                              telephone2,
                          } as CreateContact),
                )
                .then(() => alertUser('create', 'success', setSubmitting))
                .catch(() => alertUser('create', 'failure', setSubmitting))
        }
    }

    const renderEntityInformation = React.useMemo(() => {
        if (companySelected && entitySelected) {
            return (
                <InformationContainer zIndex={-1}>
                    <InformationLine main>{companySelected.libelle}</InformationLine>
                    <InformationLine main>{entitySelected.libelle}</InformationLine>
                    <>
                        <InformationLine>{entitySelected.adresse}</InformationLine>
                        <InformationLine>{entitySelected.mail}</InformationLine>
                        <InformationLine>{entitySelected.telephone}</InformationLine>
                    </>
                </InformationContainer>
            )
        }
        return null
    }, [companySelected, entitySelected])

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => handleEditContact(values, setSubmitting)}
            validationSchema={contactYupSchema(i18n)}
        >
            {({ values, errors, setFieldValue, handleSubmit, isSubmitting, dirty, setValues }) => {
                React.useEffect(() => {
                    setValues(initialValues)
                    // eslint-disable-next-line
                }, [initialValues, setValues])

                return (
                    <React.Fragment>
                        <RootScreen status={status} scrollable>
                            <PickerLine zIndex={0}>
                                <PickerContainer isLeft={Platform.OS === 'web'}>
                                    <Picker
                                        label={i18n.t('screens.contactScreen.title.companies', {
                                            count: allCompanies.length,
                                        })}
                                        value={companySelected && companySelected.libelle}
                                        data={allCompanies.map(c => c.libelle)}
                                        onChange={c => {
                                            onCompanyChange(c)
                                            setFieldValue('idEntreprise', allCompanies[c].id)
                                            setFieldValue('idAgence', '')
                                        }}
                                        error={errors.idEntreprise}
                                        isClearable
                                        isSearchable
                                        required
                                    />
                                </PickerContainer>
                                <PickerContainer>
                                    <Picker
                                        label={i18n.t('screens.contactScreen.title.entities', {
                                            count: allEntities.length,
                                        })}
                                        value={(entitySelected && entitySelected.libelle) || ''}
                                        data={allEntities.map(e => e.libelle)}
                                        onChange={e => {
                                            setEntitySelected(allEntities[e])
                                            setFieldValue('idAgence', e === -1 ? '' : allEntities[e].id)
                                        }}
                                        onTextChange={value => {
                                            setUnknownEntity(value)
                                            setFieldValue('libelleAgence', value)
                                        }}
                                        error={errors.idAgence}
                                        disabled={!companySelected}
                                        isClearable
                                        isSearchable
                                        required
                                        debounce
                                    />
                                </PickerContainer>
                            </PickerLine>
                            {renderEntityInformation}
                            <PickerLine zIndex={-2}>
                                <PickerContainer isLeft={Platform.OS === 'web'}>
                                    <TextInput
                                        label={i18n.t('screens.contactScreen.label.nom')}
                                        value={values.nom}
                                        onTextChange={text => setFieldValue('nom', text)}
                                        required
                                        error={errors.nom}
                                        placeholder={i18n.t('screens.contactScreen.placeHolder.nom')}
                                    />
                                </PickerContainer>
                                <PickerContainer>
                                    <TextInput
                                        label={i18n.t('screens.contactScreen.label.prenom')}
                                        value={values.prenom}
                                        onTextChange={text => setFieldValue('prenom', text)}
                                        required
                                        error={errors.prenom}
                                        placeholder={i18n.t('screens.contactScreen.placeHolder.prenom')}
                                    />
                                </PickerContainer>
                            </PickerLine>
                            <PickerLine zIndex={-3}>
                                <PickerContainer>
                                    <TextInput
                                        label={i18n.t('screens.contactScreen.label.address')}
                                        value={values.address}
                                        onTextChange={text => setFieldValue('address', text)}
                                        placeholder={i18n.t('screens.contactScreen.placeHolder.address')}
                                    />
                                </PickerContainer>
                            </PickerLine>
                            <PickerLine zIndex={-3}>
                                <PickerContainer>
                                    <TextInput
                                        label={i18n.t('screens.contactScreen.label.mail')}
                                        value={values.mail}
                                        onTextChange={text => setFieldValue('mail', text)}
                                        required
                                        error={errors.mail}
                                        placeholder={i18n.t('screens.contactScreen.placeHolder.mail')}
                                    />
                                </PickerContainer>
                            </PickerLine>
                            <PickerLine zIndex={-4}>
                                <PickerContainer isLeft={Platform.OS === 'web'}>
                                    <TextInput
                                        label={i18n.t('screens.contactScreen.label.telephone1')}
                                        value={values.telephone1}
                                        onTextChange={text => setFieldValue('telephone1', text)}
                                        error={errors.telephone1}
                                        placeholder={i18n.t('screens.contactScreen.placeHolder.telephone1')}
                                    />
                                </PickerContainer>
                                <PickerContainer>
                                    <TextInput
                                        label={i18n.t('screens.contactScreen.label.telephone2')}
                                        value={values.telephone2}
                                        onTextChange={text => setFieldValue('telephone2', text)}
                                        error={errors.telephone2}
                                        placeholder={i18n.t('screens.contactScreen.placeHolder.telephone2')}
                                    />
                                </PickerContainer>
                            </PickerLine>
                            {Platform.OS === 'web' && (
                                <ButtonContainer>
                                    <Button
                                        libelle={i18n.t('screens.contactScreen.addContactButton')}
                                        status={
                                            isSubmitting
                                                ? 'loading'
                                                : !dirty || Object.keys(errors).length !== 0
                                                ? 'disabled'
                                                : 'active'
                                        }
                                        onPress={handleSubmit}
                                        width={250}
                                    >
                                        <ButtonContent>
                                            <Icon name={'plus'} color={Theme.colors.buttonTitleClassic} size={15} />
                                            <ButtonTitle>
                                                {i18n.t(
                                                    `screens.contactScreen.${
                                                        contactId ? 'editContactButton' : 'addContactButton'
                                                    }`,
                                                )}
                                            </ButtonTitle>
                                        </ButtonContent>
                                    </Button>
                                </ButtonContainer>
                            )}
                        </RootScreen>
                        {Platform.OS !== 'web' && !isSubmitting && dirty && Object.keys(errors).length === 0 && (
                            <FloatButton iconName="save" onPress={handleSubmit} />
                        )}
                    </React.Fragment>
                )
            }}
        </Formik>
    )
}

// PICKERS
const PickerLine = styled(View)<{ zIndex: number }>`
    flex-direction: ${Platform.OS === 'web' ? 'row' : 'column'};
    margin-bottom: ${Platform.OS === 'web' ? '40px' : '20px'};
    ${Platform.OS === 'web' && 'align-items: flex-end;'}
    z-index: ${props => props.zIndex};
`
const PickerContainer = styled(View)<{ isLeft?: boolean }>`
    ${Platform.OS === 'web' && 'flex: 1;'}
    ${props => props.isLeft && `margin-right: ${Platform.OS === 'web' ? '40px' : '20px'};`}
    ${Platform.OS !== 'web' && 'margin-bottom: 15px;'}
`

// BUTTON
const ButtonContainer = styled(View)`
    margin-top: 30px;
    flex-direction: row;
    justify-content: center;
`
const ButtonContent = styled(View)`
    flex-direction: row;
    align-items: center;
    padding-left: 8px;
    padding-right: 8px;
`
const ButtonTitle = styled(Text)`
    ${props => props.theme.fonts.buttonTitle}
    text-transform: uppercase;
    margin-left: 10px;
`

// EntityInformation
const InformationLine = styled(Text)<{ main?: boolean }>`
    ${props => (props.main ? props.theme.fonts.companyMainInformation : props.theme.fonts.companyInformation)}
`
const InformationContainer = styled(View)<{ zIndex: number }>`
    z-index: ${props => props.zIndex};
    background-color: ${props => props.theme.colors.companyInformationBackground};
    padding: ${Platform.OS === 'web' ? '30px' : '15px'};
    margin-bottom: ${Platform.OS === 'web' ? '40px' : '20px'};
`
