import { createEvent, createStore } from 'effector'

export const initialState: IcpState = {
    icps: [],
    ppees: [],
    reducedIcps: [],
    reducedPpees: [],
    homeIndicators: {
        indicators: {
            icpToPlan: 0,
            icpToDo: 0,
            icpToEnd: 0,
            icpToApprove: 0,
            ppeeInProgess: 0,
        },
        nextIcp: [],
    },
}

export const actions = {
    setReducedIcps: createEvent<SimpleIcp[]>('setReducedIcps'),
    setIcps: createEvent<Icp[]>('setIcps'),
    setReducedPpees: createEvent<SimpleIcp[]>('setReducedPpees'),
    setPpees: createEvent<Icp[]>('setPpees'),
    setHomeIndicators: createEvent<Home>('setHomeIndicators'),
    reset: createEvent(),
}

export const store = createStore<IcpState>(initialState)
    .on(actions.setIcps, (state, icps) => ({ ...state, icps }))
    .on(actions.setReducedIcps, (state, reducedIcps) => ({ ...state, reducedIcps }))
    .on(actions.setPpees, (state, ppees) => ({ ...state, ppees }))
    .on(actions.setReducedPpees, (state, reducedPpees) => ({ ...state, reducedPpees }))
    .on(actions.setHomeIndicators, (state, homeIndicators) => ({ ...state, homeIndicators }))
    .on(actions.reset, () => initialState)
