import { Platform } from 'react-native'

const functionColors = (MAIN_COLOR: string) => ({
    //----------WEB----------
    contentBackground: '#F6F5F5',
    // Menu
    menuBackground: MAIN_COLOR,
    webMenuTitle: '#FFFFFF',
    webMenuTitleHover: MAIN_COLOR,
    webMenuIconBorder: MAIN_COLOR,
    webMenuIconColor: MAIN_COLOR,
    // Header
    webHeaderBackground: '#FFFFFF',
    pageName: MAIN_COLOR,
    userName: '#464F58',
    logoutIcon: '#464F58',
    // Touchable
    backgroundTouchable: '#8E8E93',
    // Checkbox
    checkbox: '#464F58',
    checkboxDisabled: '#9DA1A5',
    checkboxLabel: '#464F58',
    checkboxLabelDisabled: '#9DA1A5',
    // Fields
    requiredIndicator: MAIN_COLOR,
    fieldLabel: '#464F58',
    fieldDefaultBorder: '#FFFFFF',
    fieldError: '#FF0000',
    fieldSelected: MAIN_COLOR,
    fieldFocused: '#FCEDEE',
    fieldBackground: '#FFFFFF',
    fieldActive: '#FFD8DB',
    fieldPlaceholder: '#7D838A',
    // TextInput
    textInputBackground: '#FFFFFF',
    textInputBackgroundDisabled: '#FAFAFA',
    textInputBorderError: '#FF0000',
    // Roles/Rights
    rolesBackground: '#FFFFFF',
    roleName: '#464F58',
    paginationLibelle: '#464F58',
    nextColor: '#464F58',
    nextDisabledColor: '#D3D3D3',
    roleNameSelected: MAIN_COLOR,
    roleRights: '#464F58',
    rightCategory: MAIN_COLOR,
    rightTitle: '#464F58',
    evenLines: '#F6F5F5',
    // Button
    iconClassicColor: MAIN_COLOR,
    iconInversedColor: '#FFFFFF',
    buttonBackgroundClassic: MAIN_COLOR,
    buttonBackgroundValidate: '#34B233',
    buttonBackgroundDisabled: '#FCBFC4',
    buttonTitleClassic: '#FFFFFF',
    buttonApprobationColor: '#008000',
    buttonDisapprobationColor: '#BF0000',
    // Tooltip
    tooltipContent: '#FFFFFF',
    infoIcon: '#464F58',
    // Activity Indicator
    activityIndicator: MAIN_COLOR,
    // Error Message
    errorMessage: '#FFFFFF',
    mailToError: '#0088CE',
    // Picker
    mobilePickerIconDisabled: '#CDCFD1',
    mobilePickerSelectedBackground: MAIN_COLOR,
    clearIcon: '#FF0000',
    // Modal
    modalBackground: '#2229',
    modalContentBackground: '#FFFFFF',
    modalButton: '#464F58',
    modalPickerSelected: '#FFFFFF',
    modalPickerItem: '#464F58',
    // SearchInput
    searchInputResultsCountMessage: '#464F58',
    searchInputResultsCount: MAIN_COLOR,
    // Perimeters
    perimeterName: '#464F58',
    editPerimeterLink: MAIN_COLOR,
    activeToggle: '#34B233',
    inactiveToggle: '#464F58',
    // Users
    hoverBackground: MAIN_COLOR,
    hoverText: '#FFFFFF',
    // Alert
    defaultCancelButton: '#FF0000',
    defaultValidateButton: '#34B233',
    // Login
    webLoginBackground: 'rgba(0, 0, 0, 0.38)',
    webLoginLiseretBackground: 'rgba(255, 255, 255, 0.94)',
    webBoxShadow: '#BBBBBB',
    // Risk Family Item List
    riskFamilyItemListBackground: '#FFFFFF',
    riskFamilyItemInformationIcon: '#464F58',
    riskFamilyItemInformationSelectedIcon: MAIN_COLOR,
    riskFamilyItemInput: '#F6F5F5',
    riskFamilyItemMandatory: '#158f36',
    editButtonColor: '#F6F5F5',
    editButtonBorderColor: '#F6F5F5',
    editButtonBackgroundColor: MAIN_COLOR,
    editButtonColorHovered: MAIN_COLOR,
    editButtonBorderColorHovered: MAIN_COLOR,
    editButtonBackgroundColorHovered: '#F6F5F5',
    editContainerBackground: MAIN_COLOR,
    // ImageDropInput
    imageDropInputBackground: '#FFFFFF',
    imageDropInputAccept: '#8BCA8A',
    imageDropInputReject: '#F17171',
    // ModeleStateTag
    modeleStateTagBackgroundBrouillon: '#FED100',
    modeleStateTagBackgroundActif: '#34B233',
    modeleStateTagBackgroundArchive: '#464F58',
    modeleStateTagTextBrouillon: '#464F58',
    modeleStateTagTextOther: '#FFFFFF',
    // EditModeleAdminScreen
    saveUnfinishedButtonBackground: '#0088CE',
    saveFinishedButtonBackground: '#34B233',
    // EditRubriqueSection
    rubriqueBackground: '#FFFFFF',
    orderArrowsColor: '#464F58',
    orderArrowsDisabledColor: '#F6F5F5',
    editRubriqueColor: MAIN_COLOR,
    questionListTitleColor: MAIN_COLOR,
    // EditQuestionSection
    questionListBackground: '#F6F5F5',
    questionRequired: MAIN_COLOR,
    // ImageFormInput
    uploadIconColor: '#464F58',
    // SignaturePad
    signaturePadBackground: '#FFFFFF',
    // DocumentAdminScreen
    documentsModalBackground: '#F6F5F5',
    documentsModalTitle: MAIN_COLOR,
    // editDocumentModal
    visualizeIcon: MAIN_COLOR,
    visualizeIconDisabled: '#545454',
    //----------MOBILE----------
    // Header
    headerIcon: '#FFFFFF',
    // Component
    componentsShadow: '#000000',
    defaultButtonColor: MAIN_COLOR,
    defaultButtonColorHovered: MAIN_COLOR + 'AA',
    disabledButtonColor: '#545454',
    disabledButtonColorHovered: '#545454',
    defaultButtonFontColor: '#FFFFFF',
    // Rootview
    mobileHeaderBackground: MAIN_COLOR,
    mobilePageName: '#FFFFFF',
    mobileLoading: '#8E8E93',
    noNetworkIcon: '#FFFFFF',
    noNetworkBackground: '#707070',
    backgroundColor: '#2D2A2666',
    popupBackgroundColor: '#F8F6F5',
    // Loading
    loadingBackground: MAIN_COLOR,
    initialLoadingText: '#FFFFFF',
    refreshButtonColor: '#FFFFFF',
    refreshButtonTextColor: MAIN_COLOR,
    // Home
    homeIndicatorBackground: '#FFFFFF',
    homeIndicatorBorderColor: MAIN_COLOR,
    homeIndicatorBowShadowColor: Platform.OS === 'web' ? '#707070' : '#464F58',
    homeIndicatorText: '#464F58',
    disabledHomeActionLink: '#8E8E93',
    homeActionLink: MAIN_COLOR,
    homeListTitle: '#464F58',
    homeListBorderColor: MAIN_COLOR,
    homeListBackground: '#FFFFFF',
    homeEvenLines: '#F6F5F5',
    homeListLineContent: '#464F58',
    // Account
    accountTextContainerBackground: '#FFFFFF',
    accountSeparator: MAIN_COLOR,
    accountCheckboxIcon: '#464F58',
    mobileHeaderShadowBox: '#000000',
    rootContainerError: '#464F58',
    // Login
    loginError: '#FFFFFF',
    mobileAppTitle: '#FFFFFF',
    mobileWelcome: '#FFFFFF',
    mobileLoginBackground: MAIN_COLOR,
    webWelcome: '#595959',
    // Bottom Drawer
    bottomDrawerRootIcon: MAIN_COLOR,
    bottomDrawerSecondaryIcon: '#FFFFFF',
    bottomDrawerShadowBackground: '#000000',
    bottomDrawerMarginRootBackground: MAIN_COLOR,
    bottomDrawerMarginSecondaryBackground: '#FFFFFF',
    // Admin contact
    noItemLabel: '#464F58',
    entityInformation: '#464F58',
    mainTitle: '#464F58',
    tabTitle: '#464F58',
    smallTabTitle: '#464F58',
    oddLine: '#FFFFFF',
    evenLine: '#F6F5F5',
    infoIconClosed: '#464F58',
    infoIconOpened: MAIN_COLOR,
    documentChevron: MAIN_COLOR,
    documentChevronBorder: MAIN_COLOR,
    documentChevronBackground: '#F6F5F5',
    documentChevronHovered: '#F6F5F5',
    documentChevronBorderHovered: MAIN_COLOR,
    documentChevronBackgroundHovered: MAIN_COLOR,
    iconHover: MAIN_COLOR,
    iconDisabled: '#F6F5F5',
    iconActif: '#464F58',
    deleteIconHover: '#F6F5F5',
    companyName: '#464F58',
    entityTitle: '#464F58',
    entityName: '#464F58',
    contactInformation: '#464F58',
    companyInformation: '#464F58',
    companyInformationBackground: '#FFFFFF',
    contactListBackground: '#FFFFFF',
    contactLineHover: '#e5e5e5',
    // icpForm
    icpFormTitle: MAIN_COLOR,
    icpFormError: '#464F58',
    // Collaborateurs liste
    collaborateurSelectedBorder: MAIN_COLOR,
    collaborateurSentBorder: '#34B233',
    collaborateurSelectedColor: '#464F58',
    collaborateurBackgroundColor: '#FFFFFF',
    deleteCollaborateurColor: MAIN_COLOR,
    // addIcpContacts
    addIcpContactsBackground: '#FFFFFF',
    deleteIcpContactBackground: '#464F58',
    deleteIcpContactIcon: '#FFFFFF',
    icpContactName: '#464F58',
    icpContactModeSelected: MAIN_COLOR,
    icpContactMode: '#FFFFFF',
    // IcpContactState
    icpStateTagBackgroundEnAttente: '#FED100',
    icpStateTagBackgroundAccepte: '#34B233',
    icpStateTagBackgroundRefuse: '#FF0000',
    icpStateTagBackgroundPasEnvoye: '#464F58',
    icpStateTagBackgroundRefuseRed: '#FF0000',

    //icpListItems
    icpBorderColor: '#AEAEAEAE',
    icpAPlanifier: '#C22525',
    icpARealiser: '#F3602A',
    icpAFinaliser: '#FCAF17',
    icpAApprouver: '#FCE609',
    icpActif: '#82C345',
    icpAEcheance: '#7957A5',
    icpEchu: '#000000',
    lightTagText: '#000000',
    darkTagText: '#FFFFFF',
    icpLineItemText: '#464F58',
    triSelected: MAIN_COLOR,
    triHovered: MAIN_COLOR + 'AA',
    triUnselected: '#000000',

    filterLibelle: '#464F58',
    filterLibelleDisabled: '#464F58',
    filterBackgroundColor: '#FFFFFF',
    filterBackgroundColorDisabled: '#AEAEAEAE',

    filterBackgroundColorHovered: MAIN_COLOR + 'AA',
    filterLibelleHovered: '#FFFFFF',

    filterBackgroundColorSelected: MAIN_COLOR,
    filterBackgroundColorSelectedDisabled: '#AEAEAEAE',
    filterLibelleSelected: '#FFFFFF',
    filterLibelleSelectedDisabled: MAIN_COLOR,
    filterActive: '#82C345',

    filterBorderActive: MAIN_COLOR,

    buttonFilterColor: MAIN_COLOR,
    buttonFilterIcon: '#FFFFFF',
    buttonFilterIconSelected: '#82C345',

    buttonSelectPerimeter: '#FFFFFF',
    classicalText: '#464F58',
    classicalLink: MAIN_COLOR,

    pictoFamilyBackground: MAIN_COLOR,
    pictoFamilyBackgroundHover: MAIN_COLOR + 'AA',

    riskLine: '#FFFFFF',
    riskLineHover: MAIN_COLOR,
    riskTextHover: '#FFFFFF',
    borderListColor: MAIN_COLOR,

    // Add adn Delete Icon
    addIcon: '#34B233',
    deleteIcon: '#FF0000',
    iconColor: '#FFFFFF',
    addIconDisabled: '#464F58',

    noCompany: '#FF0000',
    onError: '#FF0000',

    // Edit ICP Screen
    icpStateBarBackground: '#FFFFFF',
    icpStateBarBorder: '#FFFFFF',
    icpStateBarCheckedIcon: '#FFFFFF',
    icpStateBarBubbleFilled: MAIN_COLOR,
    icpStateBarBubbleEmpty: '#FFFFFF',
    icpStateBarProgressLine: MAIN_COLOR,
    editIcpMobileStateBar: '#FFFFFF',
    editIcpSectionBubble: '#FFFFFF',
    declarationModalBackground: '#FFFFFF',
    noMandatoryFamilies: '#FF0000',

    //Loading on refresh
    refreshLoadingColor: MAIN_COLOR,
    ppeePdfAction: '#FFFFFF',
    closeButton: '#FF0000',
    closeMenuButton: '#FFFFFF',
    ppeePdfLeftMenuBackground: '#222E',

    // Graphs
    graphTitle: MAIN_COLOR,
    graphLoading: MAIN_COLOR,
    graphLoadingBackground: '#F6F5F5DD',
    missingInformationsText: '#FF0000',
    agencyWarning: '#FF0000',
    imageModalBackground: '#FFFFFF',
    imageModalArrowsColor: '#464F58',
    imageModalArrowsDisabledColor: '#F6F5F5',

    // PPEE
    ppeeActionLink: '#FFFFFF',
    ppeeActionLinkHovered: MAIN_COLOR,

    // FloatButton
    floatButtonBackgroundColor: '#646464CC',
    floatButtonText: '#FFFFFF',

    // Logo Container
    logoBackground: '#646464',
    white: '#FFFFFF',
    inputText: '#464F58',
    defaultBorder: '#FFFFFF',
})

const functionFonts = (colors: Colors) => ({
    headerPageName: {
        fontFamily: 'Avenir-Heavy',
        fontSize: 20,
        fontStyle: 'normal',
        color: colors.pageName,
    },
    menuTitles: {
        fontFamily: 'Avenir-Light',
        fontSize: 18,
        fontStyle: 'normal',
        color: colors.webMenuTitle,
    },
    menuSubTitles: {
        fontFamily: 'Avenir-Heavy',
        fontSize: 14,
        fontStyle: 'normal',
        color: colors.webMenuTitle,
    },
    headerMobilePageName: {
        fontFamily: 'Avenir-Heavy',
        fontSize: 20,
        fontStyle: 'normal',
        color: colors.mobilePageName,
    },
    headerMobilePageNameSmall: {
        fontFamily: 'Avenir-Heavy',
        fontSize: 16,
        fontStyle: 'normal',
        color: colors.mobilePageName,
    },
    headerMobilePreviousPage: {
        fontFamily: 'Avenir-Heavy',
        fontSize: 12,
        fontStyle: 'normal',
        color: colors.mobilePageName,
    },
    loadingMobileText: {
        fontFamily: 'Avenir-Heavy',
        fontSize: 20,
        fontStyle: 'normal',
        color: colors.mobileLoading,
    },
    accountInformationLibelle: {
        fontSize: 18,
        fontFamily: 'Avenir-Book',
        color: '#464F58',
    },
    accountInformationValue: {
        fontSize: 16,
        fontFamily: 'Avenir-Book',
        color: '#464F58',
    },
    buttonLibelle: {
        color: '#fff',
        fontSize: 14,
        fontFamily: 'Avenir-Heavy',
    },
    versionName: {
        color: '#464F58',
        fontSize: 12,
        fontFamily: 'Avenir-Book',
    },
    requiredIndicator: {
        fontFamily: 'Avenir-Heavy',
        fontSize: Platform.OS === 'web' ? 18 : 10,
        fontStyle: 'normal',
        color: colors.fieldError,
    },
    fieldLabel: {
        fontFamily: 'Avenir-Heavy',
        fontSize: Platform.OS === 'web' ? 18 : 10,
        fontStyle: 'normal',
        color: colors.fieldLabel,
    },
    sentToLabel: {
        fontFamily: 'Avenir-Heavy',
        fontSize: Platform.OS === 'web' ? 15 : 10,
        fontStyle: 'normal',
        color: colors.fieldLabel,
    },
    sentToBoldLabel: {
        fontFamily: 'Avenir-Book',
        fontSize: Platform.OS === 'web' ? 15 : 10,
        fontStyle: 'normal',
        color: colors.fieldLabel,
    },
    fieldSubLabel: {
        fontFamily: 'Avenir-Book',
        fontSize: 10,
        fontStyle: 'normal',
        color: colors.fieldLabel,
    },
    fieldError: {
        fontFamily: 'Avenir-Book',
        fontSize: Platform.OS === 'web' ? 12 : 8,
        fontStyle: 'normal',
        color: colors.fieldError,
    },
    inputText: {
        fontFamily: 'Avenir-Book',
        fontSize: Platform.OS === 'web' ? 12 : 12,
        fontStyle: 'normal',
        color: colors.fieldLabel,
    },
    headerUserName: {
        fontFamily: 'Avenir-Book',
        fontSize: 15,
        fontStyle: 'normal',
        color: colors.userName,
    },
    checkboxLabel: {
        fontFamily: 'Avenir-Heavy',
        fontSize: Platform.OS === 'web' ? 18 : 10,
        fontStyle: 'normal',
        color: colors.checkboxLabel,
    },
    checkboxLabelDisabled: {
        fontFamily: 'Avenir-Heavy',
        fontSize: Platform.OS === 'web' ? 18 : 10,
        fontStyle: 'normal',
        color: colors.checkboxLabelDisabled,
    },
    roleName: {
        fontFamily: 'Avenir-Light',
        fontSize: 18,
        fontStyle: 'normal',
        color: colors.roleName,
    },
    roleRights: {
        fontFamily: 'Avenir-Heavy',
        fontSize: 18,
        fontStyle: 'normal',
        color: colors.roleRights,
    },
    rightTitle: {
        fontFamily: 'Avenir-Heavy',
        fontSize: 12,
        fontStyle: 'normal',
        color: colors.rightTitle,
    },
    rightLegend: {
        fontFamily: 'Avenir-Light',
        fontSize: 10,
        fontStyle: 'normal',
        color: colors.rightTitle,
    },
    rightLegendMobile: {
        fontFamily: 'Avenir-Light',
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 'bold',
        color: colors.rightTitle,
    },
    buttonTitle: {
        fontFamily: 'Avenir-Heavy',
        fontSize: 14,
        fontStyle: 'normal',
        color: colors.buttonTitleClassic,
    },
    tooltipContent: {
        fontFamily: 'Avenir-Light',
        fontSize: 12,
        fontStyle: 'normal',
        color: colors.tooltipContent,
    },
    errorMessage: {
        fontFamily: 'Avenir-Heavy',
        fontSize: 25,
        fontStyle: 'normal',
        color: colors.errorMessage,
    },
    modalButton: {
        fontFamily: 'Avenir-Heavy',
        fontSize: 12,
        fontStyle: 'normal',
        color: colors.modalButton,
    },
    modalPickerItem: {
        fontFamily: 'Avenir-Book',
        fontSize: 15,
        fontStyle: 'normal',
        color: colors.modalPickerItem,
    },
    searchInputResultsCount: {
        fontFamily: 'Avenir-Light',
        fontSize: 14,
        fontStyle: 'normal',
        color: colors.searchInputResultsCountMessage,
    },
    perimeterLegend: {
        fontFamily: 'Avenir-Light',
        fontSize: 10,
        fontStyle: 'normal',
        color: colors.rightTitle,
    },
    perimeterName: {
        fontFamily: 'Avenir-Book',
        fontSize: 14,
        fontStyle: 'normal',
        color: colors.perimeterName,
    },
    editPerimeterLink: {
        fontFamily: 'Avenir-Book',
        fontSize: 14,
        fontStyle: 'normal',
        color: colors.editPerimeterLink,
    },
    homeIndicatorText: {
        fontFamily: 'Avenir-Heavy',
        fontSize: Platform.OS === 'web' ? 15 : 12,
        fontStyle: 'normal',
        color: colors.homeIndicatorText,
    },
    homeIndicatorValue: {
        fontFamily: 'Avenir-Heavy',
        fontSize: 30,
        fontStyle: 'normal',
        color: colors.homeIndicatorText,
    },
    homeActionLink: {
        fontFamily: 'Avenir-Book',
        fontSize: 12,
        fontStyle: 'normal',
        color: colors.homeActionLink,
    },
    disabledHomeActionLink: {
        fontFamily: 'Avenir-Book',
        fontSize: 12,
        fontStyle: 'normal',
        color: colors.disabledHomeActionLink,
    },
    homeListTitle: {
        fontFamily: 'Avenir-Heavy',
        fontSize: Platform.OS === 'web' ? 20 : 18,
        fontStyle: 'normal',
        color: colors.homeListTitle,
    },
    homeListLineContent: {
        fontFamily: 'Avenir-Book',
        fontSize: 12,
        fontStyle: 'normal',
        color: colors.homeListLineContent,
    },
    rootContainerError: {
        fontFamily: 'Avenir-Heavy',
        fontSize: 15,
        fontStyle: 'normal',
        color: colors.rootContainerError,
    },
    mobileAppName: {
        fontFamily: 'Avenir-Heavy',
        fontSize: 42,
        fontStyle: 'normal',
        color: colors.mobileAppTitle,
    },
    mobileWelcome: {
        fontFamily: 'Avenir-Heavy',
        fontSize: 34,
        fontStyle: 'normal',
        color: colors.mobileWelcome,
    },
    webAppName: {
        fontFamily: 'Avenir-Heavy',
        fontSize: 25,
        fontStyle: 'normal',
        color: colors.webWelcome,
    },
    webWelcome: {
        fontFamily: 'Avenir-Book',
        fontSize: 20,
        fontStyle: 'normal',
        color: colors.webWelcome,
    },
    noItemLabel: {
        fontFamily: 'Avenir-Heavy',
        fontSize: 14,
        fontStyle: 'normal',
        color: colors.noItemLabel,
    },
    mainTitle: {
        fontFamily: 'Avenir-Heavy',
        fontSize: 18,
        fontStyle: 'normal',
        color: colors.mainTitle,
    },
    tabTitle: {
        fontFamily: 'Avenir-Book',
        fontSize: 12,
        fontStyle: 'normal',
        color: colors.tabTitle,
    },
    companyName: {
        fontFamily: 'Avenir-Heavy',
        fontSize: 14,
        fontStyle: 'normal',
        color: colors.companyName,
    },
    entityTitle: {
        fontFamily: 'Avenir-Heavy',
        fontSize: 12,
        fontStyle: 'normal',
        color: colors.entityTitle,
    },
    entityName: {
        fontFamily: 'Avenir-Heavy',
        fontSize: 12,
        fontStyle: 'normal',
        color: colors.entityName,
    },
    smallTabTitle: {
        fontFamily: 'Avenir-Book',
        fontSize: 10,
        fontStyle: 'normal',
        color: colors.smallTabTitle,
    },
    entityInformation: {
        fontFamily: 'Avenir-Book',
        fontSize: 12,
        fontStyle: 'normal',
        color: colors.entityInformation,
    },

    // Composant pagination
    paginationLibelle: {
        fontFamily: 'Avenir-Heavy',
        fontSize: 20,
        fontStyle: 'normal',
        color: colors.paginationLibelle,
        fontWeight: 'bold',
    },

    // Affichage liste contacts
    noContactLabel: {
        fontFamily: 'Avenir-Book',
        fontSize: 18,
        fontStyle: 'normal',
        color: colors.noItemLabel,
    },
    contactInformation: {
        fontFamily: 'Avenir-Book',
        fontSize: 12,
        fontStyle: 'normal',
        color: colors.contactInformation,
    },
    contactMainInformation: {
        fontFamily: 'Avenir-Heavy',
        fontSize: 12,
        fontStyle: 'normal',
        color: colors.contactInformation,
    },
    companyInformation: {
        fontFamily: 'Avenir-Book',
        fontSize: Platform.OS === 'web' ? 18 : 14,
        fontStyle: 'normal',
        color: colors.companyInformation,
    },
    companyMainInformation: {
        fontFamily: 'Avenir-Heavy',
        fontSize: Platform.OS === 'web' ? 20 : 16,
        fontStyle: 'normal',
        color: colors.companyInformation,
    },
    icpFormTitle: {
        fontFamily: Platform.OS === 'web' ? 'Avenir-Heavy' : 'Avenir-Book',
        fontSize: Platform.OS === 'web' ? 18 : 14,
        fontStyle: 'normal',
        color: colors.icpFormTitle,
    },
    icpFormError: {
        fontFamily: 'Avenir-Book',
        fontSize: Platform.OS === 'web' ? 14 : 12,
        fontStyle: 'normal',
        color: colors.icpFormError,
    },
    icpContactName: {
        fontFamily: 'Avenir-Heavy',
        fontSize: Platform.OS === 'web' ? 14 : 12,
        fontStyle: 'normal',
        color: colors.icpContactName,
    },
    icpAgencyWorksNature: {
        fontFamily: 'Avenir-Book',
        fontSize: Platform.OS === 'web' ? 14 : 12,
        fontStyle: 'normal',
        color: colors.icpContactName,
    },
    icpAgencyLabel: {
        fontFamily: 'Avenir-Book',
        fontSize: Platform.OS === 'web' ? 14 : 12,
        fontStyle: 'normal',
        color: colors.icpContactName,
    },
    icpAgencyValue: {
        fontFamily: 'Avenir-Heavy',
        fontSize: Platform.OS === 'web' ? 14 : 12,
        fontStyle: 'normal',
        color: colors.icpContactName,
    },
    // ICP Liste
    icpLightStateText: {
        fontFamily: 'Avenir-Book',
        fontSize: 12,
        fontStyle: 'normal',
        color: colors.lightTagText,
    },
    icpDarkStateText: {
        fontFamily: 'Avenir-Book',
        fontSize: 12,
        fontStyle: 'normal',
        color: colors.darkTagText,
    },
    icpLineItemText: {
        fontFamily: 'Avenir-Book',
        fontSize: 10,
        fontStyle: 'normal',
        color: colors.icpLineItemText,
    },
    filterLibelle: {
        fontFamily: 'Avenir-Book',
        fontSize: 10,
        fontStyle: 'normal',
        color: colors.filterLibelle,
    },
    filterLibelleSelected: {
        fontFamily: 'Avenir-Book',
        fontSize: 10,
        fontStyle: 'normal',
        fontWeight: 'bold',
        color: colors.filterLibelleSelected,
    },
    filterLibelleDisabled: {
        fontFamily: 'Avenir-Book',
        fontSize: 10,
        fontStyle: 'normal',
        color: colors.filterLibelleDisabled,
    },
    filterLibelleSelectedDisabled: {
        fontFamily: 'Avenir-Book',
        fontSize: 10,
        fontStyle: 'normal',
        fontWeight: 'bold',
        color: colors.filterLibelleSelectedDisabled,
    },
    noIcpLibelle: {
        fontFamily: 'Avenir-Light',
        fontSize: Platform.OS === 'web' ? 18 : 14,
        fontStyle: 'normal',
        color: colors.perimeterName,
    },
    buttonSelectPerimeter: {
        fontFamily: 'Avenir-Light',
        fontSize: 14,
        fontStyle: 'normal',
        color: colors.buttonSelectPerimeter,
    },
    // FAMILY ICP LIST
    noFamilyList: {
        fontFamily: 'Avenir-Light',
        fontSize: 14,
        fontStyle: 'normal',
        color: colors.classicalText,
    },
    riskList: {
        fontFamily: 'Avenir-Light',
        fontSize: 16,
        fontStyle: 'normal',
        color: colors.classicalText,
    },
    infoLibelle: {
        fontFamily: 'Avenir-Light',
        fontSize: 14,
        fontStyle: 'normal',
        color: colors.classicalText,
    },
    companiesChoiceLibelle: {
        fontFamily: 'Avenir-Light',
        fontSize: 16,
        fontStyle: 'normal',
        fontWeight: 'bold',
        color: colors.classicalText,
    },
    companiesLibelle: {
        fontFamily: 'Avenir-Light',
        fontSize: 12,
        fontStyle: 'normal',
        color: colors.classicalLink,
    },
    noCompaniesLibelle: {
        fontFamily: 'Avenir-Light',
        fontSize: 12,
        fontStyle: 'normal',
        color: colors.noCompany,
    },
    icpRubriqueTitle: {
        fontFamily: 'Avenir-Heavy',
        fontSize: Platform.OS === 'web' ? 20 : 16,
        fontStyle: 'normal',
        color: colors.homeListTitle,
    },
    familyInfoLibelle: {
        fontFamily: 'Avenir-Book',
        fontSize: 18,
        fontStyle: 'normal',
        fontWeight: 'bold',
        color: colors.classicalText,
    },
    riskInfoLibelle: {
        fontFamily: 'Avenir-Heavy',
        fontSize: 16,
        fontStyle: 'normal',
        color: colors.classicalText,
    },
    measureInfoLibelle: {
        fontFamily: 'Avenir-Light',
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 'bold',
        color: colors.classicalText,
    },
    icpDescription: {
        fontFamily: 'Avenir-Heavy',
        fontSize: 14,
        fontStyle: 'normal',
        color: colors.rightTitle,
    },
    icpCompanies: {
        fontFamily: 'Avenir-Book',
        fontSize: 12,
        fontStyle: 'normal',
        color: colors.rightTitle,
    },
    icpDatas: {
        fontFamily: 'Avenir-Light',
        fontSize: 13,
        fontStyle: 'normal',
        color: colors.rightTitle,
    },
    icpInstructions: {
        fontFamily: 'Avenir-Book',
        fontSize: 12,
        fontStyle: 'normal',
        color: colors.icpContactName,
    },
    editIcpStateBar: {
        fontFamily: 'Avenir-Book',
        fontSize: 16,
        fontStyle: 'normal',
        color: colors.icpContactName,
    },
    editIcpMobileStateBar: {
        fontFamily: 'Avenir-Heavy',
        fontSize: 12,
        fontStyle: 'normal',
        color: colors.editIcpMobileStateBar,
    },
    declarationModalInformation: {
        fontFamily: 'Avenir-Book',
        fontSize: 18,
        fontStyle: 'normal',
        color: colors.icpContactName,
    },
    ppeePdfAction: {
        fontFamily: 'Avenir-Heavy',
        fontSize: 18,
        fontStyle: 'normal',
        color: colors.ppeePdfAction,
    },
    ppeePdfSectionTitle: {
        fontFamily: 'Avenir-Heavy',
        fontSize: 20,
        fontStyle: 'normal',
        color: colors.ppeePdfAction,
    },
    ppeePdfItemLine: {
        fontFamily: 'Avenir-Book',
        fontSize: 15,
        fontStyle: 'normal',
        color: colors.ppeePdfAction,
    },
    amendmentReason: {
        fontFamily: 'Avenir-Book',
        fontSize: Platform.OS === 'web' ? 18 : 14,
        fontStyle: 'normal',
        color: colors.icpContactName,
    },
    graphTitle: {
        fontFamily: 'Avenir-Heavy',
        fontSize: 14,
        fontStyle: 'normal',
        color: colors.graphTitle,
    },
    graphError: {
        fontFamily: 'Avenir-Book',
        fontSize: 18,
        fontStyle: 'normal',
        color: colors.icpContactName,
    },
    pdfFile: {
        fontFamily: 'Avenir-Heavy',
        fontSize: 12,
        fontStyle: 'normal',
        color: colors.icpContactName,
    },
    missingInformationsText: {
        fontFamily: 'Avenir-Light',
        fontSize: 14,
        fontStyle: 'normal',
        color: colors.missingInformationsText,
    },
    approbationTitle: {
        fontFamily: 'Avenir-Heavy',
        fontSize: 20,
        fontStyle: 'normal',
        color: colors.classicalText,
    },
    approbationDescription: {
        fontFamily: 'Avenir-Light',
        fontSize: 14,
        fontStyle: 'normal',
        color: colors.classicalText,
    },
    noMandatoryFamilies: {
        fontFamily: 'Avenir-Light',
        fontSize: 12,
        fontStyle: 'normal',
        color: colors.noMandatoryFamilies,
    },
    ppeeActionLink: {
        fontFamily: 'Avenir-Book',
        fontSize: 15,
        fontStyle: 'normal',
        color: colors.ppeeActionLink,
    },
    ppeeActionLinkHovered: {
        fontFamily: 'Avenir-Book',
        fontSize: 15,
        fontStyle: 'normal',
        color: colors.ppeeActionLinkHovered,
    },
    synchronizationLibelle: {
        fontFamily: 'Avenir-Book',
        fontSize: 18,
        fontStyle: 'normal',
        color: colors.mobilePageName,
    },
    noSynchronizedIcp: {
        fontFamily: 'Avenir-Book',
        fontSize: 15,
        fontStyle: 'normal',
        color: colors.componentsShadow,
    },
    loadingText: {
        fontFamily: 'Avenir-Book',
        fontSize: 30,
        fontStyle: 'normal',
        color: colors.initialLoadingText,
    },
    errorText: {
        fontFamily: 'Avenir-Book',
        fontSize: 20,
        fontStyle: 'normal',
        color: colors.initialLoadingText,
    },
    progressionText: {
        fontFamily: 'Avenir-Book',
        fontSize: 40,
        fontStyle: 'normal',
        color: colors.initialLoadingText,
    },
    floatButtonText: {
        fontFamily: 'Avenir-Book',
        fontSize: 20,
        fontStyle: 'normal',
        color: colors.floatButtonText,
    },
    collaborateurSelected: {
        fontFamily: 'Avenir-Light',
        fontSize: 10,
        fontStyle: 'normal',
        color: colors.collaborateurSelectedColor,
    },
    sizeSuggestion: {
        fontFamily: 'Avenir-Light',
        fontSize: 12,
        fontStyle: 'italic',
        color: colors.homeListTitle,
    },
})

const constants = {
    // Height
    webHeaderHeight: 60,
    menuLogoHeight: 91,
    webMenuSectionHeight: 45,
    webMenuSectionIconSize: 20,
    webMenuSubSectionHeight: 24,
    webMenuSubSectionIconSize: 12,
    webMenuIconSize: 15,
    textInputHeight: Platform.OS === 'web' ? 40 : 40,
    checkboxSize: Platform.OS === 'web' ? 20 : 14,
    checkboxSmallSize: Platform.OS === 'web' ? 14 : 10,
    logoutIconSize: 26,
    rightLineHeight: 48,
    activityIndicatorScreenSize: 45,
    mobilePickerSelectHeight: '44px',
    pickerIconSize: Platform.OS === 'web' ? 26 : 15,
    mobileModalContent: '300px',
    homeIndicatorHeight: Platform.OS === 'web' ? 100 : 40,
    homeListTitleContainerHeight: 50,
    homeListLineHeight: 40,
    accountTextContainer: Platform.OS === 'web' ? 52 : 44,
    accountIconSize: 30,
    backIconSize: 20,
    mobileHeaderHeight: 60,
    noNetworkContainerHeight: 20,
    noNetworkSize: 15,
    loginErrorIconSize: 20,
    mobileLogoHeight: 150,
    webLoginAppTextHeight: 100,
    webLoginErrorHeight: 20,
    bottomDrawerIconSize: 50,
    bottomDrawerBackIconSize: 35,
    bottomDrawerLineHeight: 100,
    bottomDrawerIconContainerSize: 75,
    riskFamiliesListTitleContainerHeight: 60,
    riskFamiliesListImageSize: 36,
    imageInputClearIconSize: 15,
    riskFamilyInformationIconSize: 15,
    imageDropInputZoneHeight: 115,
    imageDropInputPictureSize: 90,
    trameLineHeight: '48px',
    modelesListTitleContainerHeight: '60px',
    formImageInputPictureSize: Platform.OS === 'web' ? '120px' : '100px',

    // Width
    webMenuWidth: 304,
    editRiskFamilyImageFieldWidth: 350,
    editRiskButtonWidth: 315,
    editModeleButtonWidth: 250,

    // Paddings
    webMenuLogoVerticalPadding: 40,
    webMenuLogoHorizontalPadding: 32,
    webMenuSectionHorizontalPadding: 24,
    webMenuSubItemLeftPadding: 78,
    webMenuSubItemRightPadding: 24,
    pageHorizontalPadding: 82,
    pageVerticalPadding: 10,
    headerRightPadding: 48,
    checkboxLabelPadding: Platform.OS === 'web' ? '24px' : '12px',
    fieldPadding: Platform.OS === 'web' ? '24px' : '12px',

    // Border Radius
    inputBorderRadius: '6px',

    // Position
    floatApprobationButtonBottomPosition: 140,
    floatButtonBottomPosition: 60,
    floatButtonRightPosition: 30,
}

const themeUtils: ThemeUtils = {
    functionColors,
    functionFonts,
    constants,
}

export default themeUtils
