import * as React from 'react'

// Components
import { View, Platform, Text, ScrollView } from 'react-native'
import { Formik, FormikErrors } from 'formik'
import RootScreen from '@components/rootScreen/RootScreen'
import AddIcpAgencies from '@components/addIcpAgencies/AddIcpAgencies'
import PdfDropInput from '@components/pdfDropInput/pdfDropInput'
import { confirmAlert, basicAlert } from '@components/alert/Alerts'
import Button from '@components/button/Button'
import InfosPpee from '@components/infosPpee/InfosPpee'
import PlanPpee from '@components/planPpee/PlanPpee'

// Utils
import Logger from '@utils/logger'
import { isBefore, isAfter } from 'date-fns'
import { addAprrYear } from '@utils/dateUtils'

// Store
import useNavigation from '@layout/useNavigation'

import useI18n from '@store/i18n/useI18n'

// Api
import api from '@app/api/api'

// Style
import styled from '@styles/index'
import useTheme from '@styles/useTheme'

const I18N_SCREEN_KEY = 'screens.uploadPpee'

const emptyUser: UserIcp = { id: '', firstName: '', lastName: '' }

const emptyPpee: UploadedPpee = {
    type: 'one-time',
    description: '',
    socle: false,
    startDate: new Date(),
    endDate: new Date(),
    redacteur: emptyUser,
    agencies: [],
    pdf: '',
}

const trimValues = (ppee: UploadedPpee): UploadedPpee => ({ ...ppee, description: ppee.description.trim() })

export default () => {
    const i18n = useI18n()
    const navigation = useNavigation()
    const [Theme] = useTheme()

    const [status, setStatus] = React.useState<Status>('loading')
    const [redacteurs, setRedacteurs] = React.useState<UserRedacteur[]>([])
    const [approbateurs, setApprobateurs] = React.useState<UserApprobateur[]>([])
    const [ppeeList, setPpeeList] = React.useState<SimpleIcp[]>([])
    const [isAvenant, setIsAvenant] = React.useState<boolean>(false)

    React.useEffect(() => {
        Promise.all([api.users.getRedacteurList(), api.users.getApprobateurList(), api.icp.getPpeeList()])
            .then(([redacs, approbs, ppeeFetchedList]) => {
                setRedacteurs(redacs.data)
                setApprobateurs(approbs.data)
                setPpeeList(ppeeFetchedList)
                setStatus('fetched')
            })
            .catch(err => {
                Logger.error('Une erreur est survenue...', err)
                setStatus('fetched') // todo
            })
    }, [])

    const validatePpee = (values: UploadedPpee, i18n: I18nState): FormikErrors<UploadedPpee> => {
        const trimedValues = trimValues(values)
        const errors: FormikErrors<UploadedPpee> = {}
        if (!trimedValues.type) {
            errors.type = i18n.t('screens.addUserAdminScreen.required')
        }
        if (!trimedValues.description) {
            errors.description = i18n.t('screens.addUserAdminScreen.required')
        }
        if (!!trimedValues.startDate && !!trimedValues.endDate) {
            if (
                trimedValues.type === 'one-time' &&
                isAfter(new Date(trimedValues.endDate), addAprrYear(new Date(trimedValues.startDate), 1))
            ) {
                errors.endDate = i18n.t('components.infosIcp.errors.oneYear')
            }
            if (isBefore(new Date(trimedValues.endDate), new Date(trimedValues.startDate))) {
                errors.endDate = i18n.t('components.infosIcp.errors.isBefore')
            }
        }
        if (!trimedValues.startDate) {
            errors.startDate = i18n.t('screens.addUserAdminScreen.required')
        }
        if (!trimedValues.endDate) {
            errors.endDate = i18n.t('screens.addUserAdminScreen.required')
        }
        if (isAvenant && !trimedValues.ppeeParentId) {
            errors.ppeeParentId = i18n.t('screens.addUserAdminScreen.required')
        }
        if (!trimedValues.redacteur.id) {
            errors.redacteur = { id: i18n.t('screens.addUserAdminScreen.required') }
        }
        if (!trimedValues.approbateur || !trimedValues.approbateur.id) {
            errors.approbateur = i18n.t('screens.addUserAdminScreen.required')
        }
        if (!trimedValues.date) {
            errors.date = i18n.t('screens.addUserAdminScreen.required')
        }
        if (values.agencies.length === 0) {
            errors.agencies = i18n.t('screens.addUserAdminScreen.required')
        }
        if (!trimedValues.pdf) {
            errors.pdf = i18n.t('screens.addUserAdminScreen.required')
        }
        return errors
    }

    const handleGlobalSubmit = (values: UploadedPpee, setSubmitting: (isSubmitting: boolean) => void) => {
        const trimedValues = trimValues(values)
        const actionKey = 'Add'

        setSubmitting(true)
        confirmAlert(
            i18n,
            I18N_SCREEN_KEY,
            actionKey,
            () =>
                api.icp
                    .addPpee(trimedValues)
                    .then(() => {
                        basicAlert(
                            i18n,
                            I18N_SCREEN_KEY,
                            actionKey,
                            () => {
                                navigation.push('/actifEnCours')
                                setSubmitting(false)
                            },
                            'success',
                            Theme,
                        )
                    })
                    .catch(err => {
                        basicAlert(i18n, I18N_SCREEN_KEY, actionKey, () => setSubmitting(false), 'error', Theme)
                        Logger.error(err)
                    }),

            () => setSubmitting(false),
            Theme,
        )
    }

    return (
        <RootScreen status={status}>
            <ScrollView>
                <Formik
                    initialValues={emptyPpee}
                    onSubmit={(values, { setSubmitting }) => handleGlobalSubmit(values, setSubmitting)}
                    validate={values => validatePpee(values, i18n)}
                >
                    {({ values, setFieldValue, handleSubmit, isSubmitting, errors, setValues, isValid }) => {
                        React.useEffect(() => {
                            setFieldValue('ppeeParentId', '')
                            // eslint-disable-next-line
                        }, [isAvenant])

                        return (
                            <View>
                                <Section zIndex={0}>
                                    <SectionTitle>
                                        {i18n.t('screens.uploadPpee.sectionTitles.informations')}
                                    </SectionTitle>
                                    <InfosPpee
                                        ppeeList={ppeeList}
                                        values={values}
                                        setFieldValue={setFieldValue}
                                        setValues={setValues}
                                        errors={errors}
                                        isAvenant={isAvenant}
                                        setIsAvenant={setIsAvenant}
                                    />
                                </Section>
                                <Section zIndex={-1}>
                                    <SectionTitle>
                                        {i18n.t('screens.uploadPpee.sectionTitles.planification')}
                                    </SectionTitle>
                                    <PlanPpee
                                        values={values}
                                        setFieldValue={setFieldValue}
                                        errors={errors}
                                        redacteurs={redacteurs}
                                        approbateurs={approbateurs}
                                    />
                                </Section>
                                <Section zIndex={-2}>
                                    <SectionTitle>
                                        {i18n.t('components.actorsIcp.sectionsTitles.agenciesList')}
                                    </SectionTitle>
                                    <AddIcpAgencies
                                        values={values.agencies}
                                        onChange={agencies => setFieldValue('agencies', agencies)}
                                        label={i18n.t('components.actorsIcp.agencies')}
                                        required={true}
                                        error={!!errors && errors.agencies}
                                    />
                                </Section>
                                <Section zIndex={-3}>
                                    <SectionTitle>{i18n.t('screens.uploadPpee.sectionTitles.pdf')}</SectionTitle>
                                    <PdfDropInput
                                        values={!!values.pdf ? [values.pdf] : []}
                                        onChange={newPdf => setFieldValue('pdf', newPdf.length > 0 ? newPdf[0] : '')}
                                        error={!!errors && errors.pdf}
                                    />
                                </Section>
                                <ButtonContainer>
                                    <Button
                                        status={isSubmitting ? 'loading' : isValid ? 'active' : 'disabled'}
                                        onPress={handleSubmit}
                                        libelle={i18n.t('screens.uploadPpee.buttons.addPpee')}
                                    />
                                </ButtonContainer>
                            </View>
                        )
                    }}
                </Formik>
            </ScrollView>
        </RootScreen>
    )
}

const Section = styled(View)<{ zIndex: number }>`
    z-index: ${props => props.zIndex};
    margin-bottom: ${Platform.OS === 'web' ? '24px' : '15px'};
`
const SectionTitle = styled(Text)`
    ${props => props.theme.fonts.icpFormTitle}
    font-family: Avenir-Book;
    margin-bottom: 20px;
`
const ButtonContainer = styled(View)`
    justify-content: center;
    align-items: center;
`
