import React from 'react'

// Components
import { Platform, Text, View } from 'react-native'
import RequiredIndicator from '@components/requiredIndicator/RequiredIndicator'
import Icon from '@components/icon/Icon'

// Style
import styled from '@styles/index'
import useTheme from '@styles/useTheme'

interface Props {
    required?: boolean
    label?: string
    subLabel?: string
    error?: string
    color?: string
    minHeight?: number
    labelIcon?: IconName
    doubleLined?: boolean
}

export default (props: Props) => {
    const { label, error, required, color, minHeight, subLabel, labelIcon, doubleLined } = props
    const [Theme] = useTheme()

    if (!label && !error) {
        return null
    }

    return (
        <Container>
            <TopContainer>
                {!!label && (
                    <LabelContainer>
                        <Label color={color} minHeight={minHeight}>
                            {label}
                        </Label>
                        {required && <RequiredIndicator />}
                        {!!labelIcon && (
                            <IconContainer isPlatformWeb={Platform.OS === 'web'}>
                                <Icon name={labelIcon} size={15} color={Theme.colors.fieldError} />
                            </IconContainer>
                        )}
                    </LabelContainer>
                )}

                {!!error && !doubleLined && <Error minHeight={minHeight}>{error}</Error>}
            </TopContainer>
            {!!subLabel && <SubLabel>{subLabel}</SubLabel>}
            {!!error && !!doubleLined && <Error minHeight={minHeight}>{error}</Error>}
        </Container>
    )
}
const Container = styled(View)`
    padding-left: ${props => props.theme.constants.fieldPadding};
    padding-right: ${props => props.theme.constants.fieldPadding};
`
const TopContainer = styled(View)`
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
`
const LabelContainer = styled(View)`
    flex: 1;
    flex-direction: row;
`
const IconContainer = styled(View)<{ isPlatformWeb: boolean }>`
    ${props => !props.isPlatformWeb && 'margin-left: 10px;'}
`
const Label = styled(Text)<{ color?: string; minHeight?: number }>`
    ${props => props.theme.fonts.fieldLabel}
    ${props => props.color && `color: ${props.color}`}
    font-size: ${props => (props.minHeight ? props.minHeight * 0.5 : 12)}px;
`
const Error = styled(Text)<{ minHeight?: number }>`
    ${props => props.theme.fonts.fieldError}
    font-size: ${props => props.minHeight && props.minHeight * 0.5}px;
`
const SubLabel = styled(Text)`
    ${props => props.theme.fonts.fieldSubLabel}
`
