import { FormikErrors } from 'formik'

export const validateForm = (values: AddedFormulaire, modeleRub: RubriqueModele[], i18n: I18nState) => {
    const errors: FormikErrors<AddedFormulaire> = {}
    const errorsJson: Array<FormikErrors<RubriqueForm>> = []

    if (!values.titre) {
        errors.titre = i18n.t('screens.editModeleAdminScreen.required')
    }
    values.json.forEach((rubrique, idxRub) => {
        errorsJson.push({})
        const errorsQuestions: Array<FormikErrors<QuestionForm>> = []
        rubrique.questions.forEach((question, idxQuest) => {
            errorsQuestions.push({})
            const errorsAnswer: Array<FormikErrors<string>> = []
            if (
                modeleRub[idxRub].questions[idxQuest].required &&
                question.answer.filter(ans => ans !== '').length === 0
            ) {
                errorsAnswer.push(i18n.t('screens.editModeleAdminScreen.required'))
            } else {
                errorsAnswer.push('')
            }

            // Test de valeur numérique sur les champs input "textInputNum"
            if (modeleRub[idxRub].questions[idxQuest].type === 'textInputNum' && isNaN(question.answer[0])) {
                errorsAnswer.push(i18n.t('screens.editForm.errors.invalidInput'))
            }

            if (errorsAnswer[0] !== '') {
                errorsQuestions[idxQuest].answer = errorsAnswer
            }
        })
        if (errorsQuestions.find(errorQ => Object.keys(errorQ).length !== 0)) {
            errorsJson[idxRub].questions = errorsQuestions
        }
    })
    if (errorsJson.find(errorJson => Object.keys(errorJson).length !== 0)) {
        errors.json = errorsJson
    }

    return errors
}
