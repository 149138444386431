import React from 'react'

// Components
import { View, Image, Text } from 'react-native'
import { TouchableItemFeedback } from '@components/touchable/Touchable'
import Icon from '@components/icon/Icon'
import Dropzone from 'react-dropzone'
import { basicAlert } from '@components/alert/Alerts'
import FieldInformation from '@components/fieldInformation/FieldInformation'
import ImagesModal from '@components/imagesModal/ImagesModal'

// Utils

// Store
import useI18n from '@store/i18n/useI18n'

// Style
import styled from '@styles/index'
import Button from '@components/button/Button'
import { openPdfInTab } from '@utils/pdfCommonUtils'
import useTheme from '@styles/useTheme'

const MAX_IMG_SIZE = 2621440 // 2,5 Mo

export default (props: ImageSelectProps) => {
    const { onChange, buttonText, value, error, label, isPdf, isNew } = props

    const [isBigsizeSelected, setIsBigsizeSelected] = React.useState(false)
    const [itemName, setItemName] = React.useState('')

    const i18n = useI18n()
    const [Theme] = useTheme()

    const onDropError = (oversized?: boolean) =>
        oversized
            ? basicAlert(i18n, 'components.imageDropInput.oversized', 'Drop', () => undefined, 'error', Theme)
            : basicAlert(i18n, 'components.imageDropInput', 'Drop', () => undefined, 'error', Theme)

    const onDropAccepted = (file: File) => {
        if (!!file.name) {
            setItemName(file.name)
        }
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onloadend = () => {
            if (!!reader.result) {
                onChange(reader.result.toString())
            } else {
                onDropError()
            }
        }
        reader.onerror = err => onDropError()
    }

    return (
        <Container>
            <ImagesModal
                visible={isBigsizeSelected}
                onRequestClose={() => setIsBigsizeSelected(false)}
                initImage={0}
                images={[value]}
            />
            {!!label && <FieldInformation label={label} error={error} />}
            <RowContainer>
                <Dropzone
                    maxSize={MAX_IMG_SIZE}
                    accept={isPdf ? '.pdf' : 'image/png,image/jpeg,image/svg+xml'}
                    onDropAccepted={acceptedFiles => onDropAccepted(acceptedFiles[0])}
                    onDropRejected={(files, evt) => onDropError(files[0].size > MAX_IMG_SIZE)}
                    multiple={false}
                >
                    {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                        <CenterView>
                            {!!error && <Error>{error}</Error>}
                            <DropZoneContent
                                {...getRootProps()}
                                isDragActive={isDragActive}
                                isDragReject={isDragReject}
                                error={!!error}
                            >
                                <input {...getInputProps()} />
                                {!!buttonText ? (
                                    <CenterView>
                                        <Button
                                            libelle={buttonText}
                                            onPress={() => {
                                                /*TODO*/
                                            }}
                                            status="active"
                                            width={240}
                                        />
                                    </CenterView>
                                ) : (
                                    <IconContainer>
                                        <Icon name="cloud-upload" size={40} color={Theme.colors.uploadIconColor} />
                                        <ButtonText>
                                            {i18n.t(`screens.customInterface.${isPdf ? 'newDocument' : 'newLogo'}`)}
                                        </ButtonText>
                                    </IconContainer>
                                )}
                            </DropZoneContent>
                        </CenterView>
                    )}
                </Dropzone>
                {value.length > 0 && isNew && (
                    <MarginLeft>
                        <TouchableItemFeedback
                            onPress={() => (isPdf ? openPdfInTab(value) : setIsBigsizeSelected(true))}
                        >
                            <ImageContainer>
                                {isPdf ? (
                                    <PdfButton>
                                        <Icon name="icpToApprove" size={50} color={Theme.colors.uploadIconColor} />
                                        <ButtonText numberOfLines={2}>{itemName}</ButtonText>
                                    </PdfButton>
                                ) : (
                                    <ImageValue source={{ uri: value }} />
                                )}
                                <ClearButton isPdf={isPdf}>
                                    <TouchableItemFeedback onPress={() => onChange('')}>
                                        <Icon
                                            name="cross"
                                            size={Theme.constants.imageInputClearIconSize}
                                            color={Theme.colors.clearIcon}
                                        />
                                    </TouchableItemFeedback>
                                </ClearButton>
                            </ImageContainer>
                        </TouchableItemFeedback>
                    </MarginLeft>
                )}
            </RowContainer>
        </Container>
    )
}

const Container = styled(View)`
    flex-direction: row;
`
const RowContainer = styled(View)`
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: -8px;
`
const DropZoneContent = styled.div<{
    isDragActive: boolean
    isDragReject: boolean
    error: boolean
}>`
    background-color: ${props => props.theme.colors.imageDropInputBackground};
    ${props => props.error && 'border: ' + props.theme.colors.textInputBorderError + ' solid 1px;'}
    ${props =>
        props.isDragActive &&
        `box-shadow: 0px 0px 0px 5px ${
            props.isDragReject ? props.theme.colors.imageDropInputReject : props.theme.colors.imageDropInputAccept
        } inset;`};
    cursor: pointer;
`
const ImageContainer = styled(View)`
    justify-content: center;
    align-items: center;
    height: ${props => props.theme.constants.formImageInputPictureSize};
    width: ${props => props.theme.constants.formImageInputPictureSize};
    margin-right: 24px;
    margin-bottom: 8px;
`
const IconContainer = styled(View)`
    justify-content: center;
    align-items: center;
    height: ${props => props.theme.constants.formImageInputPictureSize};
    width: ${props => props.theme.constants.formImageInputPictureSize};

    border-radius: 5px;
    border: 1px solid ${props => props.theme.colors.menuBackground};
`
const ImageValue = styled(Image)`
    height: ${props => props.theme.constants.formImageInputPictureSize};
    width: ${props => props.theme.constants.formImageInputPictureSize};
`
const Error = styled(Text)`
    ${props => props.theme.fonts.fieldError}
    padding-bottom: 8px;
    padding-right: ${props => props.theme.constants.fieldPadding}px;
`
const MarginLeft = styled(View)`
    margin-left: 20px;
`
const CenterView = styled(View)`
    align-items: center;
`
const ButtonText = styled(Text)`
    padding: 0 5px;
    ${props => props.theme.fonts.buttonLibelle};
    color: ${props => props.theme.colors.menuBackground};
    text-align: center;
`
const ClearButton = styled(View)<{ isPdf: boolean }>`
    position: absolute;
    top: 10px;
    right: ${props => (props.isPdf ? 10 : -10)}px;
`
const PdfButton = styled(View)`
    background-color: ${props => props.theme.colors.imageDropInputBackground};
    justify-content: center;
    align-items: center;
    height: ${props => props.theme.constants.formImageInputPictureSize};
    width: ${props => props.theme.constants.formImageInputPictureSize};

    border-radius: 5px;
    border: 1px solid ${props => props.theme.colors.menuBackground};
`
