import * as React from 'react'

// Components
import { Text, View } from 'react-native'
import Checkbox from '@components/checkbox/Checkbox'
import Picker from '@components/picker/Picker'
import TextInput from '@components/textInput/TextInput'
import FieldInformation from '@components/fieldInformation/FieldInformation'
import { TouchableItemFeedback } from '@components/touchable/Touchable'
import Button from '@components/button/Button'
import OrderEditor from '@components/orderEditor/orderEditor'
import ModeleDemoComponent from '@components/modeleDemoComponent/modeleDemoComponent'

// Store
import useI18n from '@store/i18n/useI18n'

// Style
import styled from '@styles/index'

const questionTypes: TypeQuestion[] = [
    'textInputAlpha',
    'textInputNum',
    'checkboxes',
    'radio',
    'select',
    'drawing',
    'pictures',
    'date',
]

interface Props {
    index: number
    isLast: boolean
    isArchived: boolean
    question: Question
    errors?: any
    isTheOnlyQuestion?: boolean
    editEnabled: boolean
    onQuestionFieldChange: (fieldName: QuestionFieldName, value: any) => void
    changeOrder: (idx1: number, idx2: number) => void
    setEditEnabled: (enabled: boolean) => void
    onDeleteQuestion: () => void
}

export default (props: Props) => {
    const {
        question,
        errors,
        onQuestionFieldChange,
        changeOrder,
        index,
        isLast,
        isTheOnlyQuestion,
        editEnabled,
        setEditEnabled,
        isArchived,
        onDeleteQuestion,
    } = props

    const i18n = useI18n()

    const questionTypesToString = questionTypes.map(type =>
        i18n.t(`components.editQuestionSection.questionTypes.${type}`),
    )

    return (
        <QuestionContainer zIndex={-index}>
            {!isArchived && (
                <OrderEditor
                    index={index}
                    changeOrder={changeOrder}
                    isLast={isLast}
                    editEnabled={editEnabled}
                    setEditEnabled={setEditEnabled}
                />
            )}
            <QuestionField>
                <RowContainer>
                    <RowItemContainer left>
                        {editEnabled ? (
                            <TextInput
                                label={i18n.t('components.editQuestionSection.titles.title')}
                                required={true}
                                value={question.title}
                                onTextChange={(newTitle: string) => onQuestionFieldChange('title', newTitle)}
                                placeholder={i18n.t('components.editQuestionSection.placeholders.title')}
                                error={errors ? errors.title : undefined}
                            />
                        ) : (
                            <FieldValue title={true} error={errors ? errors.title : undefined}>
                                {question.title || i18n.t('components.editQuestionSection.titles.noTitle')}
                            </FieldValue>
                        )}
                    </RowItemContainer>
                    <RowItemContainer>
                        {editEnabled ? (
                            <Checkbox
                                label={i18n.t('components.editQuestionSection.titles.required')}
                                value={question.required}
                                onCheck={() => onQuestionFieldChange('required', !question.required)}
                            />
                        ) : (
                            <FieldValue required={question.required}>
                                {i18n.t(
                                    `components.editQuestionSection.titles.${
                                        question.required ? 'required' : 'optional'
                                    }`,
                                )}
                            </FieldValue>
                        )}
                    </RowItemContainer>
                </RowContainer>
            </QuestionField>
            <QuestionField>
                {editEnabled ? (
                    <TextInput
                        numberOfLines={3}
                        multiline={true}
                        label={i18n.t('components.editQuestionSection.titles.description')}
                        value={question.description}
                        onTextChange={(newDescription: string) => onQuestionFieldChange('description', newDescription)}
                        placeholder={i18n.t('components.editQuestionSection.placeholders.description')}
                        error={errors ? errors.description : undefined}
                    />
                ) : (
                    <FieldValue error={errors ? errors.description : undefined}>
                        {question.description || i18n.t('components.editQuestionSection.titles.noDescription')}
                    </FieldValue>
                )}
            </QuestionField>
            {editEnabled ? (
                <QuestionField zIndex={0}>
                    <Picker
                        label={i18n.t('components.editQuestionSection.titles.questionType')}
                        required={true}
                        data={questionTypesToString}
                        value={questionTypesToString[questionTypes.findIndex(item => item === question.type)]}
                        onChange={(value: number) => onQuestionFieldChange('type', questionTypes[value])}
                    />
                </QuestionField>
            ) : (
                <ModeleDemoComponent type={question.type} options={question.options} />
            )}
            {editEnabled &&
                (question.type === 'checkboxes' || question.type === 'radio' || question.type === 'select') && (
                    <QuestionField zIndex={-1}>
                        <FieldInformation
                            label={i18n.t('components.editQuestionSection.titles.options')}
                            required={true}
                        />
                        {!!question.options &&
                            question.options.map(
                                (option, j) =>
                                    question.options && (
                                        <ColumnFlexContainer isOption key={`question${index}option${j}`}>
                                            <RowContainer>
                                                <RowItemContainer left={question.options.length > 2}>
                                                    <TextInput
                                                        value={option}
                                                        onTextChange={(newOption: string) => {
                                                            if (question.options) {
                                                                const newOptions = [...question.options]
                                                                newOptions[j] = newOption
                                                                onQuestionFieldChange('options', newOptions)
                                                            }
                                                        }}
                                                        placeholder={i18n.t(
                                                            'components.editQuestionSection.placeholders.option',
                                                        )}
                                                        error={errors && j === 0 ? errors.options : undefined}
                                                        errorWithoutText={errors && errors.options && j === 1}
                                                    />
                                                </RowItemContainer>

                                                {question.options.length > 2 && (
                                                    <TouchableItemFeedback
                                                        onPress={() =>
                                                            question.options &&
                                                            onQuestionFieldChange(
                                                                'options',
                                                                question.options.filter((option, i) => i !== j),
                                                            )
                                                        }
                                                    >
                                                        <Action>
                                                            {i18n.t('components.editRubriqueSection.buttons.delete')}
                                                        </Action>
                                                    </TouchableItemFeedback>
                                                )}
                                            </RowContainer>
                                        </ColumnFlexContainer>
                                    ),
                            )}
                        <AddButton>
                            <Button
                                libelle={i18n.t(`components.editQuestionSection.buttons.addOption`)}
                                onPress={() =>
                                    question.options && onQuestionFieldChange('options', [...question.options, ''])
                                }
                                status={'active'}
                            />
                        </AddButton>
                    </QuestionField>
                )}
            {editEnabled && (
                <ButtonsContainer>
                    <TouchableItemFeedback disabled={isTheOnlyQuestion} onPress={onDeleteQuestion}>
                        <Action disabled={isTheOnlyQuestion}>
                            {i18n.t('components.editRubriqueSection.buttons.delete')}
                        </Action>
                    </TouchableItemFeedback>

                    <ButtonContainer>
                        <Button
                            onPress={() => setEditEnabled(false)}
                            libelle={i18n.t('components.editRubriqueSection.buttons.validate')}
                            status={'active'}
                        />
                    </ButtonContainer>
                </ButtonsContainer>
            )}
        </QuestionContainer>
    )
}

const RowItemContainer = styled(View)<{ left?: boolean }>`
    flex: 1;
    ${props => props.left && 'margin-right: 40px;'}
`
const RowContainer = styled(View)`
    flex-direction: row;
    align-items: center;
`
const ColumnFlexContainer = styled(View)<{ isOption?: boolean }>`
    flex-direction: column;
    flex: 1;
    ${props => props.isOption && 'margin-bottom: 3px;'}
`
const QuestionContainer = styled(View)<{ zIndex?: number }>`
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 24px;
    padding-bottom: 24px;
    background-color: ${props => props.theme.colors.questionListBackground};
    margin-bottom: 24px;
    ${props => props.zIndex && `z-index: ${props.zIndex};`}
`
const QuestionField = styled(View)<{ isCheckBox?: boolean; zIndex?: number }>`
    margin-bottom: 24px;
    ${props => props.zIndex && `z-index: ${props.zIndex};`}
`
const ButtonsContainer = styled(View)`
    padding-left: 20%;
    padding-right: 20%;
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    z-index: -1;
`
const ButtonContainer = styled(View)`
    margin-left: 24px;
`
const Action = styled(Text)<{ disabled?: boolean }>`
    ${props => props.theme.fonts.homeActionLink}
    text-decoration: underline;
    ${props => props.disabled && `color :${props.theme.colors.disabledButtonColor}`}
`
const AddButton = styled(View)`
    margin-top: 24px;
    flex-direction: row;
    justify-content: space-around;
    z-index: -1;
`
const FieldValue = styled(Text)<{ title?: boolean; error?: string; required?: boolean }>`
    ${props => props.theme.fonts.inputText}
    font-size: 14px;
    ${props => props.error && `color: ${props.theme.colors.fieldError};`}
    ${props => props.required && `color: ${props.theme.colors.questionRequired};`}
    ${props => props.title && `font-family: Avenir-Heavy;`}
`
