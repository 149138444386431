import * as React from 'react'

// Components
import { View, Platform } from 'react-native'
import Checkbox from '@components/checkbox/Checkbox'
import Checkboxes from '@components/checkbox/Checkboxes'
import TextInput from '@components/textInput/TextInput'
import DatePicker from '@components/datePicker/DatePicker'
import SelectPpee from '@components/selectPpee/selectPpee'

// Utils
import { isAfter } from 'date-fns'
import { FormikErrors } from 'formik'
import { addAprrYear } from '@utils/dateUtils'
// Store
import useI18n from '@store/i18n/useI18n'

// Style
import styled from '@styles/index'

interface InfosPpeeProps {
    ppeeList: SimpleIcp[]
    values: UploadedPpee
    errors: FormikErrors<UploadedPpee>
    setValues: (values: UploadedPpee) => void
    setFieldValue: (field: keyof UploadedPpee, value: any) => void
    isAvenant: boolean
    setIsAvenant: (value: boolean) => void
}

export default (props: InfosPpeeProps) => {
    const { ppeeList, values, errors, setValues, setFieldValue, isAvenant, setIsAvenant } = props
    const i18n = useI18n()

    return (
        <View>
            <CheckboxesLine>
                <Checkboxes
                    label={i18n.t('components.infosIcp.labels.type')}
                    required={true}
                    labels={[
                        i18n.t('components.infosIcp.values.one-time'),
                        i18n.t('components.infosIcp.values.annual'),
                    ]}
                    values={[
                        !!values.type
                            ? values.type === 'one-time'
                                ? i18n.t('components.infosIcp.values.one-time')
                                : i18n.t('components.infosIcp.values.annual')
                            : '',
                    ]}
                    onChange={value => {
                        setValues({
                            ...values,
                            type: !value[0]
                                ? values.type
                                : value[0] === i18n.t('components.infosIcp.values.one-time')
                                ? 'one-time'
                                : 'annual',

                            endDate:
                                !!value[0] && value[0] === i18n.t('components.infosIcp.values.annual')
                                    ? values.startDate
                                        ? addAprrYear(new Date(values.startDate), 1)
                                        : undefined
                                    : values.endDate,
                        })
                    }}
                    error={!!errors && errors.type}
                    radio={true}
                />
            </CheckboxesLine>
            <CheckboxLine>
                <Checkbox
                    label={i18n.t('components.infosIcp.labels.socle')}
                    value={values.socle}
                    onCheck={() => setFieldValue('socle', !values.socle)}
                />
            </CheckboxLine>
            <CheckboxLine>
                <Checkbox
                    label={i18n.t('screens.uploadPpee.isAvenant')}
                    value={isAvenant}
                    onCheck={() => setIsAvenant(!isAvenant)}
                />
            </CheckboxLine>
            {isAvenant && (
                <SelectPpee
                    ppeeList={ppeeList}
                    onChange={newPpeeId => setFieldValue('ppeeParentId', newPpeeId)}
                    selectedPpeeId={values.ppeeParentId}
                    error={!!errors && errors.ppeeParentId}
                />
            )}
            <InputContainer>
                <TextInput
                    value={values.description}
                    multiline={true}
                    numberOfLines={4}
                    label={i18n.t('components.infosIcp.labels.description')}
                    onTextChange={text => setFieldValue('description', text)}
                    placeholder={i18n.t('components.infosIcp.placeholder')}
                    required={true}
                    error={!!errors && errors.description}
                />
            </InputContainer>
            <RowLine zIndex={-1}>
                <InputContainer isLeft={true}>
                    <DatePicker
                        label={i18n.t('components.infosIcp.labels.startDate')}
                        value={values.startDate ? new Date(values.startDate) : undefined}
                        onChange={date => {
                            setValues({
                                ...values,
                                startDate: date,
                                endDate:
                                    values.type === 'annual'
                                        ? !!date
                                            ? addAprrYear(date, 1)
                                            : undefined
                                        : date && values.endDate && isAfter(date, values.endDate)
                                        ? date
                                        : values.endDate,
                            })
                        }}
                        required={true}
                        error={!!errors && errors.startDate}
                        minDate={
                            values.type === 'one-time'
                                ? values.endDate && addAprrYear(new Date(values.endDate), -1)
                                : undefined
                        }
                    />
                </InputContainer>
                <InputContainer>
                    <DatePicker
                        label={i18n.t('components.infosIcp.labels.endDate')}
                        value={values.endDate ? new Date(values.endDate) : undefined}
                        onChange={date => setFieldValue('endDate', date)}
                        disabled={values.type === 'annual'}
                        required={true}
                        error={!!errors && errors.endDate}
                        minDate={values.startDate && new Date(values.startDate)}
                        maxDate={
                            values.type === 'one-time'
                                ? values.startDate && addAprrYear(new Date(values.startDate), 1)
                                : undefined
                        }
                    />
                </InputContainer>
            </RowLine>
        </View>
    )
}

const CheckboxesLine = styled(View)`
    flex-direction: ${Platform.OS === 'web' ? 'row' : 'column'};
    margin-bottom: 20px;
    ${Platform.OS === 'web' &&
        `justify-content: space-between;
        align-items: flex-end;`}
`
const CheckboxLine = styled(View)`
    margin-bottom: 20px;
    background-color: ${props => props.theme.colors.rubriqueBackground};
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 24px;
    padding-right: 24px;
`
const InputContainer = styled(View)<{ isLeft?: boolean }>`
    ${Platform.OS === 'web' && 'flex: 1;'}
    ${props => props.isLeft && Platform.OS === 'web' && `margin-right: 40px`};
    margin-bottom: ${Platform.OS === 'web' ? '24px' : '15px'};
`
const RowLine = styled(View)<{ zIndex: number }>`
    flex-direction: ${Platform.OS === 'web' ? 'row' : 'column'};
    ${Platform.OS === 'web' && 'align-items: center;'}
    z-index: ${props => props.zIndex};
`
