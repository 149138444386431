import * as React from 'react'

// Components
import { View, Platform, Text, TouchableOpacity, ScrollView } from 'react-native'
import { Formik, FormikErrors } from 'formik'
import Container from '@components/icpFormContainer/icpFormContainer'
import Checkbox from '@components/checkbox/Checkbox'
import AddIcpDocument from '@components/addIcpDocuments/AddIcpDocuments'
import Icon from '@components/icon/Icon'
import Modal from '@components/modal/Modal'

// Utils
import { produce } from 'immer'
import { openPdfInTab } from '@utils/pdfCommonUtils'

// Store
import useI18n from '@store/i18n/useI18n'
import * as CustomInterfaceStore from '@store/customInterfaceStore'
import useReducer from '@store/useReducer'

// Style
import styled from '@styles/index'

import useNavigation from '@layout/useNavigation'
import useTheme from '@styles/useTheme'

interface Props {
    initialValues: DocumentsIcp
    onValidate?: (isValid: boolean) => void
    globalSetIcpFieldValue: (value: DocumentsIcp) => void
    documents: DocumentAprr[]
}

export const validateDocumentsIcp = (
    values: DocumentsIcp,
    i18n: I18nState,
    onValidate?: (isValid: boolean) => void,
): FormikErrors<DocumentsIcp> => {
    const errors: FormikErrors<DocumentsIcp> = {}

    if (
        values.documentsAprr.length === 0 &&
        values.documentsExt.length === 0 &&
        values.attachedDocuments.length === 0
    ) {
        errors.documentsAprr = i18n.t('screens.addUserAdminScreen.required')
    }

    if (!!onValidate) {
        onValidate(Object.keys(errors).length === 0)
    }

    return errors
}

export default ({ initialValues, documents, globalSetIcpFieldValue, onValidate }: Props) => {
    const i18n = useI18n()
    const navigation = useNavigation()
    const [Theme] = useTheme()

    const [documentFocused, setDocumentFocused] = React.useState<string | undefined>(undefined)
    const instanceName = useReducer(CustomInterfaceStore.store, s => s.name)

    const openDocumentAnnexe = (link: string) => {
        if (Platform.OS === 'web') {
            openPdfInTab(link)
        } else {
            navigation.push('/pdfDisplay', {
                status: 'fetched',
                link: link,
            })
        }
    }

    const renderCheckboxLine = (
        values: DocumentsIcp,
        doc: DocumentAprr,
        index: number,
        setFieldValue: (value: any) => void,
        isLast: boolean,
        isLastSelected: boolean,
        documentGoUp: () => void,
        documentGoDown: () => void,
    ) => {
        const docIsChecked = values.documentsAprr.map(d => d.id).indexOf(doc.id) > -1
        const onCheck = () =>
            setFieldValue(
                docIsChecked
                    ? values.documentsAprr.filter(d => d.id !== doc.id).map((d, idx) => ({ ...d, number: idx }))
                    : [...values.documentsAprr, { ...doc, number: values.documentsAprr.length }],
            )
        return (
            <CheckboxLineContainer isLast={isLast} key={index}>
                <EditSubitemLine key={`document${index}`} onPress={onCheck}>
                    {docIsChecked && index !== 0 && (
                        <ArrowContainer onPress={documentGoUp}>
                            <Icon name="chevron-up" size={15} color={Theme.colors.documentChevron} />
                        </ArrowContainer>
                    )}
                    <CheckboxContainer isFirstLineItem={!docIsChecked || index === 0}>
                        <Checkbox value={docIsChecked} onCheck={onCheck} smallCheckbox />
                    </CheckboxContainer>
                    <DocumentInfoContainer>
                        <EditSubItemName>
                            <Libelle boldItem={docIsChecked} ellipsizeMode="tail" numberOfLines={2}>
                                {Platform.OS === 'web' && documentFocused === doc.id
                                    ? doc.name + ' - ' + doc.description
                                    : doc.name}
                            </Libelle>
                        </EditSubItemName>
                    </DocumentInfoContainer>
                    <InfoIconContainer onPress={() => openDocumentAnnexe(doc.link)} isLastLineItem={false}>
                        <Icon name="eye" size={20} color={Theme.colors.infoIconClosed} />
                    </InfoIconContainer>
                    <InfoIconContainer
                        onPress={() =>
                            doc.id !== documentFocused ? setDocumentFocused(doc.id) : setDocumentFocused(undefined)
                        }
                        isLastLineItem={!docIsChecked || isLastSelected}
                    >
                        <Icon
                            name="information"
                            size={20}
                            color={
                                doc.id === documentFocused ? Theme.colors.infoIconOpened : Theme.colors.infoIconClosed
                            }
                        />
                    </InfoIconContainer>
                    {docIsChecked && !isLastSelected && (
                        <ArrowContainer onPress={documentGoDown}>
                            <Icon name="chevron-down" size={15} color={Theme.colors.documentChevron} />
                        </ArrowContainer>
                    )}
                </EditSubitemLine>
                {Platform.OS !== 'web' && (
                    <Modal visible={doc.id === documentFocused} onRequestClose={() => setDocumentFocused(undefined)}>
                        <ModalContent>
                            <Libelle boldItem={true}>{doc.name}</Libelle>
                            <Libelle>{doc.description}</Libelle>
                        </ModalContent>
                    </Modal>
                )}
            </CheckboxLineContainer>
        )
    }

    const orderAprrDocuments = (doc: DocumentAprr[]): DocumentAprr[] =>
        doc.sort((a, b) =>
            a.number !== undefined ? (b.number !== undefined ? (a.number - b.number > 0 ? 1 : -1) : -1) : 1,
        )

    const orderIcpDocuments = (doc: DocumentIcp[]): DocumentIcp[] =>
        doc.sort((a, b) =>
            a.number !== undefined ? (b.number !== undefined ? (a.number - b.number > 0 ? 1 : -1) : -1) : 1,
        )

    return (
        <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={() => undefined} // On passe par le handleSubmit global sans validation
            validate={values => validateDocumentsIcp(values, i18n, onValidate)}
        >
            {({ values, errors, setFieldValue, isValid, setValues }) => {
                const globalSetFieldValue = (key: keyof DocumentsIcp, value: any) => {
                    globalSetIcpFieldValue(
                        produce(values, newValues => {
                            ;(newValues[key] as any) = value
                        }),
                    )
                    setFieldValue(key, value)
                }

                const sortedDocuments = React.useMemo(
                    () => [
                        ...values.documentsAprr,
                        ...documents.reduce(
                            (acc, cur) => (!!values.documentsAprr.find(doc => doc.id === cur.id) ? acc : [...acc, cur]),
                            [] as DocumentAprr[],
                        ),
                    ],
                    [values],
                )

                const documentGoUp = (key: keyof DocumentsIcp, index: number) => {
                    const newValues = produce(values, draft => {
                        draft[key][index].number = index - 1
                        draft[key][index - 1].number = index
                        if (key === 'documentsAprr') {
                            draft[key] = orderAprrDocuments(draft[key])
                        } else {
                            draft[key] = orderIcpDocuments(draft[key])
                        }
                    })
                    globalSetIcpFieldValue(newValues)
                    setValues(newValues)
                }

                const documentGoDown = (key: keyof DocumentsIcp, index: number) => {
                    const newValues = produce(values, draft => {
                        draft[key][index].number = index + 1
                        draft[key][index + 1].number = index
                        if (key === 'documentsAprr') {
                            draft[key] = orderAprrDocuments(draft[key])
                        } else {
                            draft[key] = orderIcpDocuments(draft[key])
                        }
                    })
                    globalSetIcpFieldValue(newValues)
                    setValues(newValues)
                }

                return (
                    <Container title={i18n.t('components.documentsIcp.title')} hasErrors={!isValid}>
                        <View>
                            <Section zIndex={0}>
                                <SectionTitle>
                                    {i18n.t('components.documentsIcp.sectionsTitles.documentsAPRR', {
                                        name: instanceName,
                                    })}
                                </SectionTitle>
                                <DocumentsContainer error={!!errors && !!errors.documentsAprr}>
                                    {documents.length === 0 ? (
                                        <NoDocument>{i18n.t('components.documentsIcp.noDocument')}</NoDocument>
                                    ) : (
                                        sortedDocuments.map((doc, index) =>
                                            renderCheckboxLine(
                                                values,
                                                doc,
                                                index,
                                                value => globalSetFieldValue('documentsAprr', value),
                                                index === documents.length - 1,
                                                index === values.documentsAprr.length - 1,
                                                () => documentGoUp('documentsAprr', index),
                                                () => documentGoDown('documentsAprr', index),
                                            ),
                                        )
                                    )}
                                </DocumentsContainer>
                            </Section>
                            <Section zIndex={-1}>
                                <SectionTitle>
                                    {i18n.t('components.documentsIcp.sectionsTitles.documentsExt')}
                                </SectionTitle>
                                <AddIcpDocument
                                    error={!!errors && !!errors.documentsExt}
                                    values={values.documentsExt}
                                    onChange={docs => globalSetFieldValue('documentsExt', docs)}
                                    canOrderDocuments
                                    documentGoUp={(idx: number) => documentGoUp('documentsExt', idx)}
                                    documentGoDown={(idx: number) => documentGoDown('documentsExt', idx)}
                                />
                            </Section>
                            <Section zIndex={-2}>
                                <SectionTitle>
                                    {i18n.t('components.documentsIcp.sectionsTitles.attachedDocuments')}
                                </SectionTitle>
                                <AddIcpDocument
                                    error={!!errors && !!errors.attachedDocuments}
                                    values={values.attachedDocuments}
                                    onChange={docs => globalSetFieldValue('attachedDocuments', docs)}
                                    canOrderDocuments
                                    documentGoUp={(idx: number) => documentGoUp('attachedDocuments', idx)}
                                    documentGoDown={(idx: number) => documentGoDown('attachedDocuments', idx)}
                                />
                            </Section>
                        </View>
                    </Container>
                )
            }}
        </Formik>
    )
}

const Section = styled(View)<{ zIndex: number }>`
    z-index: ${props => props.zIndex};
    margin-bottom: ${Platform.OS === 'web' ? '24px' : '15px'};
`
const SectionTitle = styled(Text)`
    ${props => props.theme.fonts.icpFormTitle}
    font-family: Avenir-Book;
    margin-bottom: 20px;
`
const EditSubitemLine = styled(TouchableOpacity)`
    flex-direction: row;
    align-items: center;
`
const EditSubItemName = styled(View)`
    flex: 1;
    min-height: ${props => `${props.theme.constants.homeListLineHeight}px`};
    flex-direction: row;
    align-items: center;
    background-color: ${props => props.theme.colors.homeEvenLines};
    padding-left: 24px;
    padding-right: 24px;
`
const CheckboxContainer = styled(View)<{ isFirstLineItem: boolean }>`
    width: 36px;
    ${props => props.isFirstLineItem && 'margin-left: 45px'}
`
const ArrowContainer = styled(TouchableOpacity)`
    width: 25px;
    height: 25px;
    border-radius: 50px;
    ${props => `border: 1px solid ${props.theme.colors.documentChevronBorder};`}
    background-color: ${props => props.theme.colors.documentChevronBackground};
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    margin-right: 10px;
    ${props =>
        Platform.OS === 'web' &&
        `&:hover {
        ${`border: 1px solid ${props.theme.colors.documentChevronBorderHovered};`}
        background-color: ${props.theme.colors.documentChevronBackgroundHovered};
        & > div {
            color: ${props.theme.colors.documentChevronHovered};
        }
    }`}
`
const Libelle = styled(Text)<{ boldItem?: boolean }>`
    ${props => props.theme.fonts.homeListLineContent}
    ${props => props.boldItem && 'font-family: Avenir-Heavy;'}
`
const DocumentsContainer = styled(View)<{ error: boolean }>`
    ${props => props.error && 'border: ' + props.theme.colors.textInputBorderError + ' solid 1px;'}
    background: white;
    ${Platform.OS === 'web'
        ? `padding-left: 24px;
    padding-right: 24px;
    padding-top: 12px;
    padding-bottom: 12px;`
        : `padding: 5px;`}
`
const NoDocument = styled(Text)`
    text-align: center;
    ${props => props.theme.fonts.noItemLabel}
`
const CheckboxLineContainer = styled(View)<{ isLast: boolean }>`
    ${props => !props.isLast && 'margin-bottom: 5px;'}
`
const DocumentInfoContainer = styled(View)`
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
    background-color: ${props => props.theme.colors.homeEvenLines};
    min-height: ${props => `${props.theme.constants.homeListLineHeight}px`};
`
const ModalContent = styled(ScrollView)`
    background-color: ${props => props.theme.colors.documentsModalBackground};
    padding: 24px;
`
const InfoIconContainer = styled(TouchableOpacity)<{ isLastLineItem: boolean }>`
    margin-left: 10px;
    ${props => props.isLastLineItem && 'margin-right: 45px;'}
`
