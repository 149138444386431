import * as React from 'react'

// Components
import { View, Text, ActivityIndicator } from 'react-native'
import Icon from '@components/icon/Icon'
import { TouchableItemFeedback } from '@components/touchable/Touchable'

// Style
import styled from '@styles/index'
import useI18n from '@store/i18n/useI18n'
import useNavigation from '@layout/useNavigation'

import Azure from '@utils/azure/Azure'

import * as store from '@store/store'
import * as userStore from '@store/user'
import useReducer from '@store/useReducer'
import useTheme from '@styles/useTheme'

interface Props {
    routeName: string
}

export default ({ routeName }: Props) => {
    const user = useReducer(userStore.store, s => s)

    const i18n = useI18n()
    const navigation = useNavigation()
    const [disconnecting, setDisconnecting] = React.useState<boolean>(false)
    const [Theme] = useTheme()

    const logout = () => {
        setDisconnecting(true)
        setTimeout(() => {
            store.reset()
            Azure.logout()
        }, 1000)
    }

    if (!user) {
        return null
    }

    return (
        <Container>
            <PageTitleContainer>
                <PageTitle ellipsizeMode={'tail'} numberOfLines={1}>
                    {i18n.t(`components.header.headerTitles.${routeName}`)}
                </PageTitle>
            </PageTitleContainer>
            <TouchableItemFeedback onPress={() => navigation.push('/account')}>
                <UserName>
                    {user.firstName} {user.lastName}
                </UserName>
            </TouchableItemFeedback>
            {disconnecting ? (
                <ActivityIndicator size={Theme.constants.logoutIconSize} color={Theme.colors.logoutIcon} />
            ) : (
                <TouchableItemFeedback onPress={logout}>
                    <Icon name="exit" size={Theme.constants.logoutIconSize} color={Theme.colors.logoutIcon} />
                </TouchableItemFeedback>
            )}
        </Container>
    )
}

const Container = styled(View)`
    height: ${props => props.theme.constants.webHeaderHeight}px;
    flex-direction: row;
    align-items: center;
    background-color: ${props => props.theme.colors.webHeaderBackground};
    padding-left: ${props => props.theme.constants.pageHorizontalPadding}px;
    padding-right: ${props => props.theme.constants.headerRightPadding}px;
`
const PageTitleContainer = styled(View)`
    flex: 1;
`
const PageTitle = styled(Text)`
    ${props => props.theme.fonts.headerPageName};
`
const UserName = styled(Text)`
    ${props => props.theme.fonts.headerUserName};
    margin-right: 24px;
`
