import React from 'react'

// Components
import Viewer from 'react-viewer'
import styled from '@styles/index'
export default (props: ImageModalProps) => {
    const { visible, onRequestClose, images, initImage } = props

    return (
        <CustomViewer
            visible={visible}
            onClose={onRequestClose}
            images={images.map(img => ({ src: img, downloadUrl: `${img}`, download: `${img}` }))}
            activeIndex={initImage}
            drag={false}
            scalable={false}
            noNavbar={true}
            noImgDetails={true}
            onMaskClick={onRequestClose}
            downloadable={false}
            noClose={true}
            showTotal={false}
            changeable={false}
        />
    )
}

const CustomViewer = styled(Viewer)`
    .react-viewer-image {
        background-color: ${props => props.theme.colors.imageModalBackground};
    }
`
