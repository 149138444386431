import React from 'react'

// Components
import { Text } from 'react-native'

// Style
import styled from '@styles/index'

interface Props {
    fontSize?: string
}

export default (props: Props) => {
    return <Required fontSize={props.fontSize}>{' *'}</Required>
}

const Required = styled(Text)<{ fontSize?: string }>`
    ${props => props.theme.fonts.requiredIndicator};
    ${props => props.fontSize && `font-size: ${props.fontSize};`}
`
