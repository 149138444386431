// Components
import Swal from 'sweetalert2'

// Style
import './styleAlertWeb.css'

export default (props: AlertProps) => {
    const cancelButton = props.buttons.find(b => b.style === 'cancel')
    const validateButton = props.buttons.find(b => b.style === 'default')
    Swal.fire({
        title: props.title,
        text: props.content,
        icon: props.icon,
        showCancelButton: !!cancelButton,
        cancelButtonColor: props.theme.colors.defaultCancelButton,
        cancelButtonText: cancelButton && cancelButton.text,
        confirmButtonColor: props.theme.colors.defaultValidateButton,
        confirmButtonText: validateButton && validateButton.text,
        allowEscapeKey: !!props.cancelable,
        allowOutsideClick: !!props.cancelable,
    }).then(res => {
        if (res.value) {
            return validateButton && validateButton.onPress && validateButton.onPress()
        } else {
            return cancelButton && cancelButton.onPress && cancelButton.onPress()
        }
    })
}
