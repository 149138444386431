import { saveAs } from 'file-saver'
import { fetchPdf } from './pdfCommonUtils'
import axios from 'axios'

import Azure from '@utils/azure/Azure'
import Logger from '@utils/logger'
import configuration from '@app/config'

const PREFIX = configuration.SERVER_PREFIX + '/services/v1'

const downloadPdf = (url: string, fileName: string) =>
    fetchPdf(url).then(buffer => {
        saveAs(new Blob([buffer], { type: 'application/pdf' }), fileName)
    })

export const createPreviewPdf = (icpData: Icp, setStatus: (status: ButtonStatus) => void) => {
    setStatus('loading')
    Azure.assureToken('server').then(token =>
        axios
            .request({
                url: `${PREFIX}/icp/pdf/previsualize`,
                method: 'POST',
                headers: {
                    Accept: 'application/pdf',
                    Authorization: `Bearer ${token.accessToken}`,
                },
                data: icpData,
                responseType: 'arraybuffer',
            })
            .then(res => {
                setStatus('active')
                saveAs(
                    new Blob([(res.data as unknown) as ArrayBuffer], { type: 'application/pdf' }),
                    `ICP_${icpData.id}_BROUILLON`,
                )
            })
            .catch(err => {
                setStatus('active')
                Logger.error('Erreur : ', err)
            }),
    )
}

export const viewVisitformPdf = (ppeeId: string, id: string) => {
    Azure.assureToken('server').then(token =>
        axios
            .request<Blob>({
                url: `${PREFIX}/ppee/${ppeeId}/suiviVisites/${id}`,
                method: 'GET',
                headers: {
                    Accept: 'application/pdf',
                    Authorization: `Bearer ${token.accessToken}`,
                },
                responseType: 'arraybuffer',
            })
            .then(res => {
                saveAs(new Blob([res.data], { type: 'application/pdf' }), `SUIVI_${id}`)
            })
            .catch(Logger.error),
    )
}

export const downloadWorkInspection = (icpId: string) =>
    downloadPdf(`${PREFIX}/icp/${icpId}/download/workInspection`, 'icpInspectionDuTravail.pdf')
