import * as React from 'react'

// Components
import { View } from 'react-native'
import Picker from '@components/picker/Picker'
import LottieView from 'lottie-react-native'

// Style
import styled from '@styles/index'

// Store
import useI18n from '@store/i18n/useI18n'
import { getAllPerimeterAndSubLevels } from '@utils/perimeterUtils'
import useReducer from '@store/useReducer'
import * as filtersStore from '@store/filters'

export default ({ setSelectedPerimetersIds, status, perimeters, selectedPerimeterOnly }: SelectePerimeterProps) => {
    const i18n = useI18n()

    const { direction, structure, perimeter, sector } = useReducer(filtersStore.store, s => s)

    if (status === 'loading') {
        return (
            <LoadingContainer>
                <LottieView source={require('@drawable/loadingAnimation_red.json')} autoPlay loop />
            </LoadingContainer>
        )
    }

    if (perimeters) {
        const setPerimeterValue = (index: number, level: 'direction' | 'structure' | 'perimeter' | 'sector') => {
            switch (level) {
                case 'direction':
                    filtersStore.actions.setDirection(index > -1 && !!perimeters[index] ? perimeters[index] : undefined)
                    filtersStore.actions.setStructure(undefined)
                    filtersStore.actions.setPerimeter(undefined)
                    filtersStore.actions.setSector(undefined)
                    //Permet de spécifier que l'on a choisi de filtrer sur un périmètre, qu'on ne veut donc pas ceux donnés par défaut
                    filtersStore.actions.setHasPerimeterFilterBeenSelected(true)
                    setSelectedPerimetersIds(
                        !selectedPerimeterOnly
                            ? index > -1 && !!perimeters[index]
                                ? getAllPerimeterAndSubLevels([perimeters[index]])
                                : []
                            : index > -1 && !!perimeters[index]
                            ? [perimeters[index].id]
                            : [],
                    )
                    break
                case 'structure':
                    if (!!direction) {
                        filtersStore.actions.setStructure(
                            index > -1 && !!direction.subPerimeter[index] ? direction.subPerimeter[index] : undefined,
                        )
                        filtersStore.actions.setPerimeter(undefined)
                        filtersStore.actions.setSector(undefined)
                        setSelectedPerimetersIds(
                            !selectedPerimeterOnly
                                ? getAllPerimeterAndSubLevels(
                                      index > -1 && !!direction.subPerimeter[index]
                                          ? [direction.subPerimeter[index]]
                                          : [direction],
                                  )
                                : index > -1 && !!direction.subPerimeter[index]
                                ? [direction.subPerimeter[index].id]
                                : [direction.id],
                        )
                    }
                    break
                case 'perimeter':
                    if (!!structure) {
                        filtersStore.actions.setPerimeter(
                            index > -1 && !!structure.subPerimeter[index] ? structure.subPerimeter[index] : undefined,
                        )
                        filtersStore.actions.setSector(undefined)
                        setSelectedPerimetersIds(
                            !selectedPerimeterOnly
                                ? getAllPerimeterAndSubLevels(
                                      index > -1 && !!structure.subPerimeter[index]
                                          ? [structure.subPerimeter[index]]
                                          : [structure],
                                  )
                                : index > -1 && !!structure.subPerimeter[index]
                                ? [structure.subPerimeter[index].id]
                                : [structure.id],
                        )
                    }
                    break
                default:
                    if (!!perimeter) {
                        filtersStore.actions.setSector(
                            index > -1 && !!perimeter.subPerimeter[index] ? perimeter.subPerimeter[index] : undefined,
                        )
                        setSelectedPerimetersIds(
                            !selectedPerimeterOnly
                                ? getAllPerimeterAndSubLevels(
                                      index > -1 && !!perimeter.subPerimeter[index]
                                          ? [perimeter.subPerimeter[index]]
                                          : [perimeter],
                                  )
                                : index > -1 && !!perimeter.subPerimeter[index]
                                ? [perimeter.subPerimeter[index].id]
                                : [perimeter.id],
                        )
                    }
            }
        }

        return (
            <React.Fragment>
                <PickerLine zIndex={0}>
                    <PickerContainer left>
                        <Picker
                            data={perimeters.map(p => p.libelle)}
                            value={!!direction ? direction.libelle : undefined}
                            onChange={index => setPerimeterValue(index, 'direction')}
                            label={i18n.t('screens.perimetersAdminScreen.pickerLabels.direction')}
                            isClearable
                        />
                    </PickerContainer>
                    <PickerContainer>
                        <Picker
                            data={!!direction ? direction.subPerimeter.map(p => p.libelle) : []}
                            value={!!structure ? structure.libelle : undefined}
                            onChange={index => setPerimeterValue(index, 'structure')}
                            label={i18n.t('screens.perimetersAdminScreen.pickerLabels.structure')}
                            disabled={!direction}
                            isClearable
                        />
                    </PickerContainer>
                </PickerLine>
                <PickerLine zIndex={-1}>
                    <PickerContainer left>
                        <Picker
                            data={!!structure ? structure.subPerimeter.map(p => p.libelle) : []}
                            value={!!perimeter ? perimeter.libelle : undefined}
                            onChange={index => setPerimeterValue(index, 'perimeter')}
                            label={i18n.t('screens.perimetersAdminScreen.pickerLabels.perimeter')}
                            disabled={!structure}
                            isClearable
                        />
                    </PickerContainer>
                    <PickerContainer>
                        <Picker
                            data={!!perimeter ? perimeter.subPerimeter.map(p => p.libelle) : []}
                            value={!!sector ? sector.libelle : undefined}
                            onChange={index => setPerimeterValue(index, 'sector')}
                            label={i18n.t('screens.usersAdminScreen.pickerLabels.sector')}
                            disabled={!perimeter}
                            isClearable
                        />
                    </PickerContainer>
                </PickerLine>
            </React.Fragment>
        )
    }
    return null
}

const PickerLine = styled(View)<{ zIndex: number }>`
    flex-direction: row;
    margin-bottom: 10px;
    align-items: center;
    z-index: ${props => props.zIndex};
`
const PickerContainer = styled(View)<{ left?: boolean }>`
    flex: 1;
    ${props => props.left && 'margin-right: 40px;'}
`
const LoadingContainer = styled(View)`
    height: 400px;
    justify-content: center;
    align-items: center;
`
