import * as React from 'react'

// Components
import { View, Platform, Text } from 'react-native'
import Container from '@components/icpFormContainer/icpFormContainer'

// Store
import useI18n from '@store/i18n/useI18n'

// Style
import styled from '@styles/index'

interface Props {
    values: Procedures
}

export default (props: Props) => {
    const { values } = props
    const i18n = useI18n()

    return (
        <Container title={i18n.t('components.proceduresIcp.title')}>
            <View>
                <Section zIndex={0}>
                    <SectionTitle>{i18n.t('components.proceduresIcp.sectionsTitles.instructions')}</SectionTitle>
                    <Instructions>{i18n.t('components.proceduresIcp.instructions')}</Instructions>
                    <LabelContainer>
                        <Label>{i18n.t('components.proceduresIcp.fields.comment')}</Label>
                    </LabelContainer>
                    <ValueContainer>
                        <Value>{values.comment ? values.comment : i18n.t('components.planIcp.labels.noComment')}</Value>
                    </ValueContainer>
                </Section>
                <Section zIndex={0}>
                    <SectionTitle>{i18n.t('components.proceduresIcp.sectionsTitles.procedures')}</SectionTitle>
                    <Instructions>{i18n.t('components.proceduresIcp.procedures')}</Instructions>

                    {!values.firstAidePhoneNumber &&
                        !values.centralPhoneNumber &&
                        !values.zonePau &&
                        !values.otherPhoneNumber && (
                            <PhoneContainer>
                                <LabelContainer>
                                    <Label>{i18n.t('components.proceduresIcp.fields.noPhoneNumber')}</Label>
                                </LabelContainer>
                            </PhoneContainer>
                        )}

                    {values.firstAidePhoneNumber && (
                        <PhoneContainer>
                            <LabelContainer>
                                <Label>{i18n.t('components.proceduresIcp.fields.firstAidePhoneNumber')}</Label>
                            </LabelContainer>
                            <ValueContainer>
                                <Value>{values.firstAidePhoneNumber}</Value>
                            </ValueContainer>
                        </PhoneContainer>
                    )}

                    {values.centralPhoneNumber && (
                        <PhoneContainer>
                            <LabelContainer>
                                <Label>{i18n.t('components.proceduresIcp.fields.centralPhoneNumber')}</Label>
                            </LabelContainer>
                            <ValueContainer>
                                <Value>{values.centralPhoneNumber}</Value>
                            </ValueContainer>
                        </PhoneContainer>
                    )}

                    {values.zonePau && (
                        <PhoneContainer>
                            <LabelContainer>
                                <Label>{i18n.t('components.proceduresIcp.fields.zonePau')}</Label>
                            </LabelContainer>
                            <ValueContainer>
                                <Value>{values.zonePau}</Value>
                            </ValueContainer>
                        </PhoneContainer>
                    )}

                    {values.otherPhoneNumber && (
                        <PhoneContainer>
                            <LabelContainer>
                                <Label>{i18n.t('components.proceduresIcp.fields.otherPhoneNumber')}</Label>
                            </LabelContainer>
                            <ValueContainer>
                                <Value>{values.otherPhoneNumber}</Value>
                            </ValueContainer>
                        </PhoneContainer>
                    )}

                    <Instructions>
                        <BoldText>{i18n.t('components.proceduresIcp.reminder.reminder')}</BoldText>
                        {i18n.t('components.proceduresIcp.reminder.beginning')}
                        <UnderlinedText>{i18n.t('components.proceduresIcp.reminder.middle')}</UnderlinedText>
                        {i18n.t('components.proceduresIcp.reminder.end')}
                    </Instructions>
                </Section>
            </View>
        </Container>
    )
}

const Section = styled(View)<{ zIndex: number }>`
    z-index: ${props => props.zIndex};
    margin-bottom: ${Platform.OS === 'web' ? '24px' : '15px'};
`
const SectionTitle = styled(Text)`
    ${props => props.theme.fonts.icpFormTitle}
    font-family: Avenir-Book;
    margin-bottom: 20px;
`
const Instructions = styled(Text)`
    padding-right: ${props => props.theme.constants.fieldPadding};
    padding-left: ${props => props.theme.constants.fieldPadding};
    margin-bottom: 24px;
    ${props => props.theme.fonts.icpInstructions}
`
const BoldText = styled(Text)`
    font-family: Avenir-Heavy;
`
const UnderlinedText = styled(Text)`
    text-decoration: underline;
    text-decoration-color: ${props => props.theme.fonts.icpInstructions.color};
`

const Value = styled(Text)`
    ${props => props.theme.fonts.inputText}
    color:  ${props => props.theme.colors.inputText};
    
`

const Label = styled(Text)<{ color?: string; minHeight?: number }>`
    ${props => props.theme.fonts.fieldLabel}
    ${props => props.color && `color: ${props.color}`}
    font-size: ${props => props.minHeight && props.minHeight * 0.5}px;
`

const LabelContainer = styled(View)`
    margin-bottom: 5px;
    padding-right: ${props => props.theme.constants.fieldPadding};
    padding-left: ${props => props.theme.constants.fieldPadding};
`

const ValueContainer = styled(View)`
    margin-bottom: 5px;
    padding-right: ${props => props.theme.constants.fieldPadding};
    padding-left: ${props => props.theme.constants.fieldPadding};
`

const PhoneContainer = styled(View)`
    margin-bottom: 10px;
`
