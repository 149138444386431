import * as React from 'react'

// Components
import { View, Platform, Text } from 'react-native'
import Container from '@components/icpFormContainer/icpFormContainer'
import IcpContactsRead from '@components/addIcpContacts/IcpContactsRead'

// Store
import useI18n from '@store/i18n/useI18n'

// Utils
import { getAllInitialPerimetersFromPerimeterID } from '@utils/perimeterUtils'

// Style
import styled from '@styles/index'

interface Props {
    values: Planification
    perimeters: Perimeter[]
}

export default (props: Props) => {
    const { values, perimeters } = props
    const i18n = useI18n()

    const [direction, setDirection] = React.useState<Perimeter | undefined>(undefined)
    const [structure, setStructure] = React.useState<Perimeter | undefined>(undefined)
    const [perimeter, setPerimeter] = React.useState<Perimeter | undefined>(undefined)
    const [sector, setSector] = React.useState<Perimeter | undefined>(undefined)

    const initialFunctions = [setDirection, setStructure, setPerimeter, setSector]

    React.useEffect(() => {
        if (values.perimeterId) {
            const initialPerimeters =
                values.perimeterId && getAllInitialPerimetersFromPerimeterID(values.perimeterId, perimeters)
            if (initialPerimeters) {
                initialPerimeters.forEach(
                    (initPerimeter, index) => initialFunctions[index] && initialFunctions[index](initPerimeter),
                )
            }
        }
    }, [values, perimeters, initialFunctions])

    return (
        <Container title={i18n.t('components.planIcp.title')}>
            <View>
                <RowLine zIndex={0}>
                    <InputContainer isLeft={true}>
                        <LabelContainer>
                            <Label>{i18n.t('components.planIcp.labels.redacteur')}</Label>
                        </LabelContainer>
                        <ValueContainer>
                            <Value>
                                {values.redacteur.id
                                    ? `${values.redacteur.firstName} ${values.redacteur.lastName}`
                                    : i18n.t('components.planIcp.labels.noRedacteur')}
                            </Value>
                        </ValueContainer>
                    </InputContainer>
                    <InputContainer>
                        <LabelContainer>
                            <Label>{i18n.t('components.planIcp.labels.approbateur')}</Label>
                        </LabelContainer>
                        <ValueContainer>
                            <Value>
                                {values.approbateur
                                    ? `${values.approbateur.firstName} ${values.approbateur.lastName}`
                                    : i18n.t('components.planIcp.labels.noApprobateur')}
                            </Value>
                        </ValueContainer>
                    </InputContainer>
                </RowLine>

                <Section zIndex={-2}>
                    <SectionTitle>{i18n.t('components.planIcp.sectionsTitles.perimeter')}</SectionTitle>

                    {!direction && (
                        <InputContainer>
                            <Value>{i18n.t('components.planIcp.sectionsTitles.noPerimeter')}</Value>
                        </InputContainer>
                    )}

                    {direction && (
                        <RowLine zIndex={0}>
                            <InputContainer isLeft={true}>
                                <LabelContainer>
                                    <Label>{i18n.t('screens.perimetersAdminScreen.pickerLabels.direction')}</Label>
                                </LabelContainer>
                                <ValueContainer>
                                    <Value>{direction.libelle}</Value>
                                </ValueContainer>
                            </InputContainer>
                            {structure && (
                                <InputContainer>
                                    <LabelContainer>
                                        <Label>{i18n.t('screens.perimetersAdminScreen.pickerLabels.structure')}</Label>
                                    </LabelContainer>
                                    <ValueContainer>
                                        <Value>{structure.libelle}</Value>
                                    </ValueContainer>
                                </InputContainer>
                            )}
                        </RowLine>
                    )}

                    {direction && structure && perimeter && (
                        <RowLine zIndex={-1}>
                            <InputContainer isLeft={true}>
                                <LabelContainer>
                                    <Label>{i18n.t('screens.perimetersAdminScreen.pickerLabels.perimeter')}</Label>
                                </LabelContainer>
                                <ValueContainer>
                                    <Value>{perimeter.libelle}</Value>
                                </ValueContainer>
                            </InputContainer>
                            {sector && (
                                <InputContainer>
                                    <LabelContainer>
                                        <Label>{i18n.t('screens.perimetersAdminScreen.pickerLabels.level')}</Label>
                                    </LabelContainer>
                                    <ValueContainer>
                                        <Value>{sector.libelle}</Value>
                                    </ValueContainer>
                                </InputContainer>
                            )}
                        </RowLine>
                    )}
                </Section>

                <Section zIndex={-3}>
                    <SectionTitle>{i18n.t('components.planIcp.sectionsTitles.planMeeting')}</SectionTitle>
                    <RowLine zIndex={0}>
                        <InputContainer isLeft={true}>
                            <LabelContainer>
                                <Label>{i18n.t('components.planIcp.labels.date')}</Label>
                            </LabelContainer>
                            <ValueContainer>
                                <Value>
                                    {values.date
                                        ? i18n.t('components.datePicker.date', {
                                              date: new Date(values.date),
                                          })
                                        : i18n.t('components.datePicker.noDate')}
                                </Value>
                            </ValueContainer>
                        </InputContainer>
                        <InputContainer>
                            <LabelContainer>
                                <Label>{i18n.t('components.planIcp.labels.place')}</Label>
                            </LabelContainer>
                            <ValueContainer>
                                <Value>{values.place ? values.place : i18n.t('components.addIcpPlaces.noPlace')}</Value>
                            </ValueContainer>
                        </InputContainer>
                    </RowLine>
                    <Line zIndex={-1}>
                        <LabelContainer>
                            <Label>{i18n.t('components.planIcp.labels.contacts')}</Label>
                        </LabelContainer>
                        <IcpContactsRead values={values.contacts} />
                    </Line>
                </Section>

                <Section zIndex={-4}>
                    <SectionTitle>{i18n.t('components.planIcp.sectionsTitles.declaration')}</SectionTitle>
                    <SectionContainer>
                        <LabelContainer>
                            <Label>{i18n.t('components.planIcp.labels.workInspection')}</Label>
                        </LabelContainer>
                        {values.declaration.workInspection &&
                            values.declaration.workInspection.map((wi, index) => (
                                <ValueContainer key={index}>
                                    <Value>
                                        {wi.byEmail
                                            ? `${i18n.t('components.planIcp.labels.workInspectionByEmail')} ${wi.email}`
                                            : i18n.t('components.planIcp.labels.workInspectionNotByEmail')}
                                    </Value>
                                    <Value>
                                        {wi.hasBeenSent
                                            ? i18n.t('components.planIcp.labels.workInspectionHasBeenSent')
                                            : i18n.t('components.planIcp.labels.workInspectionHasNotBeenSent')}
                                    </Value>
                                </ValueContainer>
                            ))}
                        {!values.declaration.workInspection && (
                            <ValueContainer>
                                <Value>{i18n.t('components.planIcp.labels.notWorkInspection')}</Value>
                            </ValueContainer>
                        )}
                        <LabelContainer>
                            <Label>{i18n.t('components.planIcp.labels.cssct')}</Label>
                        </LabelContainer>

                        {values.declaration.cssct && (
                            <ValueContainer>
                                <Value>
                                    {values.declaration.cssct.byEmail
                                        ? `${i18n.t('components.planIcp.labels.cssctByEmail')} ${
                                              values.declaration.cssct.email
                                          }`
                                        : i18n.t('components.planIcp.labels.cssctNotByEmail')}
                                </Value>
                                <Value>
                                    {values.declaration.cssct?.hasBeenSent
                                        ? i18n.t('components.planIcp.labels.cssctHasBeenSent')
                                        : i18n.t('components.planIcp.labels.cssctHasNotBeenSent')}
                                </Value>
                            </ValueContainer>
                        )}

                        {!values.declaration.cssct && (
                            <ValueContainer>
                                <Value>{i18n.t('components.planIcp.labels.notCssct')}</Value>
                            </ValueContainer>
                        )}
                    </SectionContainer>
                </Section>

                <Section zIndex={-5}>
                    <SectionTitle>{i18n.t('components.planIcp.sectionsTitles.extraInfo')}</SectionTitle>
                    <LabelContainer>
                        <Label>{i18n.t('components.planIcp.labels.comment')}</Label>
                    </LabelContainer>
                    <ValueContainer>
                        <Value>{values.comment ? values.comment : i18n.t('components.planIcp.labels.noComment')}</Value>
                    </ValueContainer>
                </Section>
            </View>
        </Container>
    )
}
const SectionTitle = styled(Text)`
    ${props => props.theme.fonts.icpFormTitle}
    font-family: Avenir-Book;
    margin-bottom: 20px;
`
const RowLine = styled(View)<{ zIndex: number; perimeter?: boolean }>`
    flex-direction: ${props => (Platform.OS === 'web' && !props.perimeter ? 'row' : 'column')};
    ${props => Platform.OS === 'web' && !props.perimeter && 'align-items: center;'}
    z-index: ${props => props.zIndex};
`
const Line = styled(View)<{ zIndex: number }>`
    z-index: ${props => props.zIndex};
    margin-bottom: ${Platform.OS === 'web' ? '24px' : '15px'};
`
const InputContainer = styled(View)<{ isLeft?: boolean }>`
    ${Platform.OS === 'web' && 'flex: 1;'}
    ${props => props.isLeft && Platform.OS === 'web' && `margin-right: 40px`};
    margin-bottom: ${Platform.OS === 'web' ? '24px' : '15px'};
`
const Section = styled(View)<{ zIndex: number }>`
    z-index: ${props => props.zIndex};
`

const Value = styled(Text)`
    ${props => props.theme.fonts.inputText}
    color:  ${props => props.theme.colors.inputText};
    
`

const Label = styled(Text)<{ color?: string; minHeight?: number }>`
    ${props => props.theme.fonts.fieldLabel}
    ${props => props.color && `color: ${props.color}`}
    font-size: ${props => props.minHeight && props.minHeight * 0.5}px;
`

const LabelContainer = styled(View)`
    margin-bottom: 5px;
`

const ValueContainer = styled(View)`
    margin-bottom: 5px;
`

const SectionContainer = styled(View)`
    margin-bottom: 20px;
`
