import * as React from 'react'

// Components
import { View, Text } from 'react-native'
import FieldInformation from '@components/fieldInformation/FieldInformation'
import { TouchableItemFeedback } from '@components/touchable/Touchable'
import Icon from '@components/icon/Icon'
import RepresentativeModal from './SelectIcpRepresentativeModal'
import SignaturePad from '@components/signaturePad/SignaturePad'

// Utils
import { produce } from 'immer'

// Store
import useI18n from '@store/i18n/useI18n'

// Style
import styled from '@styles/index'
import useTheme from '@styles/useTheme'

interface Props {
    values: Representative[]
    required?: boolean
    label?: string
    error?: any
    onChange: (rep: Representative[]) => void
    redacteurs?: UserRedacteur[]
}

export default (props: Props) => {
    const { values, required, label, onChange, error, redacteurs } = props

    const i18n = useI18n()
    const [Theme] = useTheme()

    const [modalVisible, setModalVisible] = React.useState<boolean>(false)
    const [itemToEdit, setItemToEdit] = React.useState<Representative | undefined>(undefined)
    const [itemOpened, setItemOpened] = React.useState<number | undefined>(undefined)

    const handleSignature = (signature: string, index: number) => {
        onChange(
            produce(values, newValues => {
                newValues[index] = { ...values[index], signature: signature, signatureDate: new Date() }
            }),
        )
    }

    const renderModeleState = (etat: ApprobationState) => {
        return (
            <ModeleStateContainer>
                <ModelStateTag etat={etat}>
                    <ModelStateText etat={etat}>
                        {i18n.t(`components.addIcpRepresentatives.approbationStates.${etat}`)}
                    </ModelStateText>
                </ModelStateTag>
            </ModeleStateContainer>
        )
    }

    return (
        <View>
            <RepresentativeModal
                modalVisible={modalVisible}
                onChange={onChange}
                onRequestClose={() => {
                    setModalVisible(false)
                    setItemToEdit(undefined)
                }}
                contacts={values}
                initialValues={itemToEdit}
                redacteurs={redacteurs}
            />

            <TopContainer>
                <Flexbox>
                    <FieldInformation label={label} required={required} error={error} doubleLined />
                </Flexbox>
                <TouchableItemFeedback onPress={() => setModalVisible(true)}>
                    <Action>{i18n.t('components.addIcpRepresentatives.addRepresentative')}</Action>
                </TouchableItemFeedback>
            </TopContainer>

            <Container error={!!error}>
                <RowContainer>
                    <Legend>{i18n.t('components.addIcpRepresentatives.legends.name')}</Legend>
                    <Legend>
                        {!values.find(approbateur => approbateur.approbation !== 'notSent')
                            ? i18n.t('components.addIcpRepresentatives.legends.status')
                            : i18n.t('components.addIcpRepresentatives.legends.approbation')}
                    </Legend>
                    <Legend>{i18n.t('components.addIcpRepresentatives.legends.signature')}</Legend>
                </RowContainer>
                <ContactList>
                    {values.length === 0 ? (
                        <NoContact>{i18n.t('components.addIcpRepresentatives.noRepresentative')}</NoContact>
                    ) : (
                        values.map((contact, index) => (
                            <TouchableItemFeedback
                                key={`contact${index}`}
                                onPress={() => setItemOpened(itemOpened === index ? undefined : index)}
                            >
                                <ContactContainer odd={index % 2 === 1}>
                                    <RowContainer>
                                        <Flexbox>
                                            <ContactName>{`${contact.firstName} ${contact.lastName}`}</ContactName>
                                        </Flexbox>
                                        <Flexbox>
                                            {contact.approbation === 'notSent' ? (
                                                <ContactName>{contact.status}</ContactName>
                                            ) : (
                                                renderModeleState(contact.approbation)
                                            )}
                                        </Flexbox>
                                        <Flexbox>
                                            <SimpleRow>
                                                {!!contact.signatureDate ? (
                                                    <ContactName>
                                                        {i18n.t('components.addIcpRepresentatives.signatureDate', {
                                                            date: new Date(contact.signatureDate),
                                                        })}
                                                    </ContactName>
                                                ) : (
                                                    <SignaturePad
                                                        value={contact.signature}
                                                        onChange={signature => handleSignature(signature, index)}
                                                        customButton={
                                                            <Action>
                                                                {i18n.t('components.addIcpRepresentatives.toBeSigned')}
                                                            </Action>
                                                        }
                                                        withModal={true}
                                                    />
                                                )}
                                                <Icon
                                                    name={'information'}
                                                    size={Theme.constants.riskFamilyInformationIconSize}
                                                    color={
                                                        itemOpened === index
                                                            ? Theme.colors.riskFamilyItemInformationSelectedIcon
                                                            : Theme.colors.riskFamilyItemInformationIcon
                                                    }
                                                />
                                            </SimpleRow>
                                        </Flexbox>
                                    </RowContainer>

                                    {itemOpened === index && (
                                        <View>
                                            <RowContainer>
                                                <ContactColumn double={true}>
                                                    {contact.approbation !== 'notSent' && (
                                                        <ContactInformation>{contact.status}</ContactInformation>
                                                    )}
                                                    <ContactInformation>{contact.email}</ContactInformation>
                                                    <ContactInformation>{contact.telephone1}</ContactInformation>
                                                    <ContactInformation>{contact.telephone2}</ContactInformation>
                                                </ContactColumn>
                                                <ContactColumn>
                                                    {!!contact.agencyName && (
                                                        <ContactInformation>{contact.agencyName}</ContactInformation>
                                                    )}
                                                </ContactColumn>
                                            </RowContainer>
                                            <RowContainer>
                                                <TouchableItemFeedback
                                                    onPress={() => {
                                                        setItemToEdit(contact)
                                                        setModalVisible(true)
                                                    }}
                                                >
                                                    <Action>{i18n.t('components.addIcpContacts.edit')}</Action>
                                                </TouchableItemFeedback>

                                                <TouchableItemFeedback
                                                    onPress={() => {
                                                        onChange(values.filter((c, i) => i !== index))
                                                        setItemOpened(undefined)
                                                    }}
                                                >
                                                    <Action>{i18n.t('components.addIcpContacts.delete')}</Action>
                                                </TouchableItemFeedback>
                                            </RowContainer>
                                        </View>
                                    )}
                                </ContactContainer>
                            </TouchableItemFeedback>
                        ))
                    )}
                </ContactList>
            </Container>
        </View>
    )
}
const SimpleRow = styled(View)`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`
const ContactContainer = styled(View)<{ odd?: boolean }>`
    background-color: ${props => !props.odd && props.theme.colors.evenLines};
`
const Container = styled(View)<{ error?: boolean }>`
    background-color: ${props => props.theme.colors.addIcpContactsBackground};
    padding-right: 8px;
    padding-left: 8px;
    ${props => props.error && 'border: ' + props.theme.colors.textInputBorderError + ' solid 1px;'}
`
const RowContainer = styled(View)`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: ${props => props.theme.constants.fieldPadding};
    padding-left: ${props => props.theme.constants.fieldPadding};
    min-height: 36px;
`
const Legend = styled(Text)`
    ${props => props.theme.fonts.rightLegend}
    flex:1;
    text-transform: uppercase;
`
const Action = styled(Text)`
    ${props => props.theme.fonts.homeActionLink}
    text-decoration: underline;
    text-decoration-color: ${props => props.theme.fonts.homeActionLink.color};
`
const ContactList = styled(View)`
    padding-bottom: 8px;
`
const NoContact = styled(Text)`
    ${props => props.theme.fonts.perimeterName}
    text-align: center;
    padding-top: 24px;
    padding-bottom: 24px;
`
const ContactName = styled(Text)`
    ${props => props.theme.fonts.icpContactName}
`
const ContactInformation = styled(Text)<{ main?: boolean }>`
    ${props => (props.main ? props.theme.fonts.contactMainInformation : props.theme.fonts.contactInformation)}
    margin-left: ${props => !props.main && '20px'};
`
const ContactColumn = styled(View)<{ double?: boolean }>`
    flex: ${props => (props.double ? '2' : '1')};
    flex-direction: column;
`
const Flexbox = styled(View)`
    flex: 1;
`
const TopContainer = styled(View)`
    flex-direction: row;
    padding-right: ${props => props.theme.constants.fieldPadding};
`
const ModeleStateContainer = styled(View)`
    width: 90px;
    margin-right: 24px;
    text-align: center;
`
const ModelStateTag = styled(View)<{ etat: ApprobationState }>`
    background-color: ${props =>
        props.etat === 'waiting'
            ? props.theme.colors.icpStateTagBackgroundEnAttente
            : props.etat === 'yes'
            ? props.theme.colors.icpStateTagBackgroundAccepte
            : props.etat === 'no'
            ? props.theme.colors.icpStateTagBackgroundRefuseRed
            : props.theme.colors.icpStateTagBackgroundPasEnvoye};
    border-radius: 40px;
    padding-left: 10px;
    padding-right: 10px;
`
const ModelStateText = styled(Text)<{ etat: ApprobationState }>`
    ${props => props.theme.fonts.homeActionLink}
    text-align: center;
    color: ${props =>
        props.etat === 'waiting'
            ? props.theme.colors.modeleStateTagTextBrouillon
            : props.theme.colors.modeleStateTagTextOther};
`
