import * as React from 'react'

// Components
import { View, Text, Platform } from 'react-native'
import Icon from '@components/icon/Icon'
import Button from '@components/button/Button'
import RootScreen from '@components/rootScreen/RootScreen'
import ContactSection from '@screens/contacts/ContactSection'
import FloatButton from '@components/floatButton/FloatButton'

import useI18n from '@store/i18n/useI18n'

// Utils
import useNavigation from '@layout/useNavigation'

// Style
import styled from '@styles/index'
import useTheme from '@styles/useTheme'

export default () => {
    const i18n = useI18n()
    const navigation = useNavigation()
    const [Theme] = useTheme()

    return (
        <React.Fragment>
            <RootScreen status={'fetched'}>
                <Contacts>
                    <ContactSection
                        onContactPress={(contact: Contact) =>
                            navigation.push('/modifierContact/:contactId', {
                                contactId: contact.id,
                                pathParams: { contactId: contact.id },
                            })
                        }
                    />
                </Contacts>
                {Platform.OS === 'web' && (
                    <ButtonContainer>
                        <Button
                            libelle={i18n.t('screens.contactScreen.addContactButton')}
                            status={'active'}
                            onPress={() => navigation.push('/ajouterContact')}
                            width={250}
                        >
                            <ButtonContent>
                                <Icon name={'plus'} color={Theme.colors.buttonTitleClassic} size={15} />
                                <ButtonTitle>{i18n.t('screens.contactScreen.addContactButton')}</ButtonTitle>
                            </ButtonContent>
                        </Button>
                    </ButtonContainer>
                )}
            </RootScreen>
            {Platform.OS !== 'web' && (
                <FloatButton iconName="plus" onPress={() => navigation.push('/ajouterContact')} />
            )}
        </React.Fragment>
    )
}
const Contacts = styled(View)`
    flex: 1;
    z-index: 0;
`

// BUTTON
const ButtonContainer = styled(View)`
    margin-top: 15px;
    margin-bottom: 15px;
    flex-direction: row;
    justify-content: center;
    z-index: -1;
`
const ButtonContent = styled(View)`
    flex-direction: row;
    align-items: center;
    padding-left: 8px;
    padding-right: 8px;
`
const ButtonTitle = styled(Text)`
    ${props => props.theme.fonts.buttonTitle}
    text-transform: uppercase;
    margin-left: 10px;
`
