import React from 'react'

// Components
import { View } from 'react-native'
import { TouchableItemFeedback } from '@components/touchable/Touchable'
import Icon from '@components/icon/Icon'
import FieldInformation from '@components/fieldInformation/FieldInformation'

import useI18n from '@store/i18n/useI18n'

// Utils
import { isSameDay, isBefore, isAfter, getMinutes, getHours } from 'date-fns'

// Style
import styled from '@styles/index'
import Flatpickr from 'react-flatpickr'
import 'flatpickr/dist/themes/material_green.css'
import { French } from 'flatpickr/dist/l10n/fr.js'
import './Styles.css'
import useTheme from '@styles/useTheme'

export default (props: DatePickerProps) => {
    const { value, onChange, error, minDate, maxDate, time, disabled, label, required, notClearable } = props

    const i18n = useI18n()
    const [Theme] = useTheme()

    const min = minDate || new Date()
    const max = maxDate || new Date()

    return (
        <View>
            <FieldInformation label={label} required={required} error={error} />
            <DatePickerContainer error={!!error} editable={!disabled}>
                <FlatPicker
                    disabled={disabled}
                    onChange={(res: Date[]) => onChange(res[0])}
                    placeholder={
                        !!value && ((minDate && isBefore(value, minDate)) || (maxDate && isAfter(value, maxDate)))
                            ? getMinutes(value) === 0 && getHours(value) === 0
                                ? i18n.t('components.datePicker.date', { date: value })
                                : i18n.t('components.datePicker.dateTime', { date: value })
                            : i18n.t('components.datePicker.noDate')
                    }
                    value={value}
                    options={{
                        enableTime: time,
                        locale: French,
                        dateFormat: time ? 'd/m/Y H:i' : 'd/m/Y',
                        minDate: minDate ? new Date(min.getFullYear(), min.getMonth(), min.getDate()) : undefined,
                        maxDate: maxDate ? max : undefined,
                        minuteIncrement: 1,
                        defaultHour: min.getHours(),
                        defaultMinute: min.getMinutes(),
                        static: true,
                        minTime: minDate && !!value && isSameDay(value, minDate) ? minDate.getTime() : undefined,
                        maxTime: maxDate && !!value && isSameDay(value, maxDate) ? maxDate.getTime() : undefined,
                    }}
                />
                {!!value && !disabled && !notClearable && (
                    <CrossContainer onPress={() => onChange(undefined)}>
                        <Icon
                            name={'cross'}
                            size={Theme.constants.imageInputClearIconSize}
                            color={Theme.colors.clearIcon}
                        />
                    </CrossContainer>
                )}
            </DatePickerContainer>
        </View>
    )
}

const DatePickerContainer = styled(View)<{
    error: boolean
    editable: boolean
    minHeight?: number
    customColor?: string
}>`
    ${props => props.theme.fonts.inputText};
    border-radius: ${props => props.theme.constants.inputBorderRadius};
    background-color: ${props =>
        props.editable
            ? props.customColor || props.theme.colors.textInputBackground
            : props.theme.colors.textInputBackgroundDisabled};

    padding-right: ${props => props.theme.constants.fieldPadding};
    min-height: ${props => props.theme.constants.textInputHeight}px;
    ${props => props.error && 'border: ' + props.theme.colors.textInputBorderError + ' solid 1px;'}
    outline: none;
    flex-direction: row;
    align-items: center;
    padding-left: 15px;
`

const FlatPicker = styled(Flatpickr)`
    width: 100%;
    padding-right: ${props => props.theme.constants.fieldPadding};
    border: 0px;
`

const CrossContainer = styled(TouchableItemFeedback)`
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100%;
`
