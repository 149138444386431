import { createEvent, createStore } from 'effector'

export const initialState: IcpInfosState = {
    perimeters: [],
    documents: [],
    redacteurs: [],
    approbateurs: [],
    families: [],
    cssctItems: undefined,
    wiItems: undefined,
    interventionObjects: [],
    interventionTypes: [],
}

export const actions = {
    setPerimeters: createEvent<Perimeter[]>('setPerimeters'),
    setDocuments: createEvent<Document[]>('setDocuments'),
    setRedacteurs: createEvent<UserRedacteur[]>('setRedacteurs'),
    setApprobateurs: createEvent<UserApprobateur[]>('setApprobateurs'),
    setFamilies: createEvent<Family[]>('setFamilies'),
    setCssctItems: createEvent<ConfigurationEmails>('setCssctItems'),
    setWiItems: createEvent<ConfigurationEmails>('setWiItems'),
    setInterventionObjects: createEvent<string[]>('setInterventionObjects'),
    setInterventionTypes: createEvent<string[]>('setInterventionTypes'),
    reset: createEvent(),
}

export const store = createStore<IcpInfosState>(initialState)
    .on(actions.setPerimeters, (state, perimeters) => ({ ...state, perimeters }))
    .on(actions.setDocuments, (state, documents) => ({ ...state, documents }))
    .on(actions.setRedacteurs, (state, redacteurs) => ({ ...state, redacteurs }))
    .on(actions.setApprobateurs, (state, approbateurs) => ({ ...state, approbateurs }))
    .on(actions.setFamilies, (state, families) => ({ ...state, families }))
    .on(actions.setCssctItems, (state, cssctItems) => ({ ...state, cssctItems }))
    .on(actions.setWiItems, (state, wiItems) => ({ ...state, wiItems }))
    .on(actions.setInterventionObjects, (state, interventionObjects) => ({ ...state, interventionObjects }))
    .on(actions.setInterventionTypes, (state, interventionTypes) => ({ ...state, interventionTypes }))
    .on(actions.reset, () => initialState)
