import * as React from 'react'

// Components
import { View, Platform, Text } from 'react-native'
import { Formik, FormikErrors } from 'formik'
import Container from '@components/icpFormContainer/icpFormContainer'
import TextInput from '@components/textInput/TextInput'
import Checkbox from '@components/checkbox/Checkbox'

// Utils
import { produce } from 'immer'

// Store
import useI18n from '@store/i18n/useI18n'

// Style
import styled from '@styles/index'

const DEFAULT_FIRST_AID = '112'
const DEFAULT_APPEL_PAU = (i18n: I18nState) => i18n.t('components.siteIcp.defaultPAU')

interface Props {
    initialValues: Procedures
    onValidate?: (isValid: boolean) => void
    globalSetIcpFieldValue: (value: Procedures) => void
}

type ProcedureIcpKey = 'firstAidePhoneNumber' | 'centralPhoneNumber' | 'zonePau' | 'otherPhoneNumber'

export const validateProceduresIcp = (
    values: Procedures,
    i18n: I18nState,
    onValidate?: (isValid: boolean) => void,
    firstAid?: boolean,
    central?: boolean,
    zone?: boolean,
    other?: boolean,
): FormikErrors<Procedures> => {
    const errors: FormikErrors<Procedures> = {}
    if (firstAid && !values.firstAidePhoneNumber) {
        errors.firstAidePhoneNumber = i18n.t('screens.addUserAdminScreen.required')
    }
    if (central && !values.centralPhoneNumber) {
        errors.centralPhoneNumber = i18n.t('screens.addUserAdminScreen.required')
    }
    if (zone && !values.zonePau) {
        errors.zonePau = i18n.t('screens.addUserAdminScreen.required')
    }
    if (other && !values.otherPhoneNumber) {
        errors.otherPhoneNumber = i18n.t('screens.addUserAdminScreen.required')
    }

    if (!!onValidate) {
        onValidate(
            Object.keys(errors).length === 0 &&
                (!!values.firstAidePhoneNumber ||
                    !!values.centralPhoneNumber ||
                    !!values.zonePau ||
                    !!values.otherPhoneNumber),
        )
    }

    return errors
}

export default (props: Props) => {
    const { initialValues, onValidate, globalSetIcpFieldValue } = props
    const i18n = useI18n()

    const [firstAid, setFirstAid] = React.useState<boolean>(!!initialValues.firstAidePhoneNumber)
    const [central, setCentral] = React.useState<boolean>(!!initialValues.centralPhoneNumber)
    const [zone, setZone] = React.useState<boolean>(!!initialValues.zonePau)
    const [other, setOther] = React.useState<boolean>(!!initialValues.otherPhoneNumber)

    const renderCheckboxWithInput = (
        key: ProcedureIcpKey,
        isChecked: boolean,
        onCheck: () => void,
        setFieldValue: (field: ProcedureIcpKey, value: any) => void,
        value?: string,
        error?: string,
    ) => (
        <CheckboxInputContainer>
            <Checkbox
                label={i18n.t(`components.proceduresIcp.fields.${key}`)}
                value={isChecked}
                onCheck={onCheck}
                extendedTouchZone={true}
            />
            {isChecked && (
                <TextInput
                    value={value}
                    onTextChange={text => setFieldValue(key, text)}
                    error={error}
                    placeholder={i18n.t('components.proceduresIcp.placeholder')}
                />
            )}
        </CheckboxInputContainer>
    )

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={() => undefined} // On passe par le handleSubmit global sans validation
        >
            {({ values, setFieldValue, isValid }) => {
                const errors = validateProceduresIcp(values, i18n, onValidate, firstAid, central, zone, other)
                const globalSetFieldValue = (key: keyof Procedures, value: any) => {
                    globalSetIcpFieldValue(
                        produce(values, newValues => {
                            newValues[key] = value
                        }),
                    )
                    setFieldValue(key, value)
                }
                validateProceduresIcp(values, i18n, onValidate, firstAid, central, zone, other)

                return (
                    <Container title={i18n.t('components.proceduresIcp.title')} hasErrors={!isValid}>
                        <View>
                            <Section zIndex={0}>
                                <SectionTitle>
                                    {i18n.t('components.proceduresIcp.sectionsTitles.instructions')}
                                </SectionTitle>
                                <Instructions>{i18n.t('components.proceduresIcp.instructions')}</Instructions>
                                <TextInput
                                    label={i18n.t('components.proceduresIcp.fields.comment')}
                                    placeholder={i18n.t('components.proceduresIcp.placeholder')}
                                    value={values.comment}
                                    onTextChange={text => globalSetFieldValue('comment', text)}
                                    multiline={true}
                                    numberOfLines={3}
                                />
                            </Section>
                            <Section zIndex={0}>
                                <SectionTitle>
                                    {i18n.t('components.proceduresIcp.sectionsTitles.procedures')}
                                </SectionTitle>
                                <Instructions>{i18n.t('components.proceduresIcp.procedures')}</Instructions>
                                {!values.firstAidePhoneNumber &&
                                    !values.centralPhoneNumber &&
                                    !values.zonePau &&
                                    !values.otherPhoneNumber && (
                                        <Error>{i18n.t('screens.addUserAdminScreen.required')}</Error>
                                    )}

                                {renderCheckboxWithInput(
                                    'firstAidePhoneNumber',
                                    firstAid,
                                    () => {
                                        globalSetFieldValue(
                                            'firstAidePhoneNumber',
                                            !firstAid ? DEFAULT_FIRST_AID : undefined,
                                        )
                                        setFirstAid(!firstAid)
                                    },
                                    globalSetFieldValue,
                                    values.firstAidePhoneNumber,
                                    errors && errors.firstAidePhoneNumber,
                                )}
                                {renderCheckboxWithInput(
                                    'centralPhoneNumber',
                                    central,
                                    () => {
                                        if (central) {
                                            globalSetFieldValue('centralPhoneNumber', undefined)
                                        }
                                        setCentral(!central)
                                    },
                                    globalSetFieldValue,
                                    values.centralPhoneNumber,
                                    errors && errors.centralPhoneNumber,
                                )}
                                {renderCheckboxWithInput(
                                    'zonePau',
                                    zone,
                                    () => {
                                        globalSetFieldValue('zonePau', !zone ? DEFAULT_APPEL_PAU(i18n) : undefined)
                                        setZone(!zone)
                                    },
                                    globalSetFieldValue,
                                    values.zonePau,
                                    errors && errors.zonePau,
                                )}
                                {renderCheckboxWithInput(
                                    'otherPhoneNumber',
                                    other,
                                    () => {
                                        if (other) {
                                            globalSetFieldValue('otherPhoneNumber', undefined)
                                        }
                                        setOther(!other)
                                    },
                                    globalSetFieldValue,
                                    values.otherPhoneNumber,
                                    errors && errors.otherPhoneNumber,
                                )}
                                <Instructions>
                                    <BoldText>{i18n.t('components.proceduresIcp.reminder.reminder')}</BoldText>
                                    {i18n.t('components.proceduresIcp.reminder.beginning')}
                                    <UnderlinedText>
                                        {i18n.t('components.proceduresIcp.reminder.middle')}
                                    </UnderlinedText>
                                    {i18n.t('components.proceduresIcp.reminder.end')}
                                </Instructions>
                            </Section>
                        </View>
                    </Container>
                )
            }}
        </Formik>
    )
}

const Section = styled(View)<{ zIndex: number }>`
    z-index: ${props => props.zIndex};
    margin-bottom: ${Platform.OS === 'web' ? '24px' : '15px'};
`
const SectionTitle = styled(Text)`
    ${props => props.theme.fonts.icpFormTitle}
    font-family: Avenir-Book;
    margin-bottom: 20px;
`
const Instructions = styled(Text)`
    padding-right: ${props => props.theme.constants.fieldPadding};
    padding-left: ${props => props.theme.constants.fieldPadding};
    margin-bottom: 24px;
    ${props => props.theme.fonts.icpInstructions}
`
const BoldText = styled(Text)`
    font-family: Avenir-Heavy;
`
const UnderlinedText = styled(Text)`
    text-decoration: underline;
    text-decoration-color: ${props => props.theme.fonts.icpInstructions.color};
`
const CheckboxInputContainer = styled(View)`
    padding-left: ${props => props.theme.constants.fieldPadding};
    padding-right: ${props => props.theme.constants.fieldPadding};
    margin-bottom: 12px;
`
const Error = styled(Text)`
    ${props => props.theme.fonts.fieldError}
    padding-right: ${props => props.theme.constants.fieldPadding};
    padding-left: ${props => props.theme.constants.fieldPadding};
    margin-bottom: 24px;
`
