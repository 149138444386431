import * as React from 'react'

// Components
import { View, Text, ScrollView, NativeSyntheticEvent, NativeScrollEvent, Dimensions } from 'react-native'
import PlanICP from '@components/planIcp/PlanICP'
import InfosICP from '@components/infosIcp/InfosICP'
import ActorsICP from '@components/actorsIcp/ActorsICP'
import DocumentsICP from '@components/documentsIcp/DocumentsICP'
import ProceduresICP from '@components/proceduresIcp/ProceduresICP'
import SiteICP from '@components/siteIcp/SiteICP'
import Button from '@components/button/Button'
import Icon from '@components/icon/Icon'
import Modal from '@components/modal/Modal'

// Store
import useI18n from '@store/i18n/useI18n'

// Style
import styled from '@styles/index'
import FamilyRiskIcpWeb from '@components/familyRiskIcp/FamilyRiskIcp.web'
import { createPreviewPdf } from '@utils/pdfUtils.web'
import useTheme from '@styles/useTheme'

const STATE_BAR_TOP_BORDER = 5
const SECTION_MIN_WIDTH = 100
const SEPARATOR_MARGIN = 20

const CLASSICAL_SUP_MARGIN = 10
const THREE_BUTTONS_SUPP_MARGIN = 35
const FOUR_BUTTONS_SUPP_MARGIN = 72

const KEY_SECTIONS: SectionName[] = [
    'infosIcp',
    'planIcp',
    'actorsIcp',
    'documentsIcp',
    'proceduresIcp',
    'siteIcp',
    'risksIcp',
]

const getSectionToScrollTo = (reason: AmendmentReason): SectionName => {
    switch (reason) {
        case 'newRisk':
            return 'risksIcp'
        case 'newActor':
            return 'planIcp'
        case 'newCompanyMeans':
            return 'actorsIcp'
        case 'nature':
        case 'duration':
        default:
            return 'infosIcp'
    }
}

export default ({
    initialValues,
    globalValues,
    sectionsFilled,
    setSectionFilled,
    bubbleKeys,
    handleSubmit,
    isSubmitting,
    globalSetIcpFieldValue,
    documents,
    redacteurs,
    approbateurs,
    cssctItems,
    wiItems,
    perimeters,
    icpId,
    canSendEmail,
    handleSubmitWithoutAlert,
    companies,
    user,
    handleApprobation,
    approbationInProgress,
    interventionObjects,
    interventionTypes,
    deleteIcp,
    isDeleting,
}: EditIcpFormContainerProps) => {
    const i18n = useI18n()
    const [Theme] = useTheme()

    const [windowWidth, setWindowWidth] = React.useState<number>(window.innerWidth)

    const [scrollPosition, setScrollPosition] = React.useState<number>(0)
    const [focusedSection, setFocusedSection] = React.useState<SectionName>('infosIcp')
    const [approbationModalOpened, setApprobationModalOpened] = React.useState<boolean>(false)
    const [previewStatus, setPreviewStatus] = React.useState<ButtonStatus>('active')

    const ScrollRef = React.useRef<ScrollView | null>(null)

    const allFieldsRequired: SectionName[] = [
        'siteIcp',
        'infosIcp',
        'planIcp',
        'actorsIcp',
        'proceduresIcp',
        'risksIcp',
    ]
    const verificationFieldsRequired = allFieldsRequired.every(
        (element: SectionName) => sectionsFilled.indexOf(element) > -1,
    )

    const [isDescriptionSet, setIsDescriptionSet] = React.useState(false)

    const hasUserApprobationButton = React.useMemo(
        () =>
            initialValues.status === 'aApprouver' &&
            user &&
            user.roleName === 'Approbateur' &&
            !initialValues.actors.representatives.find(
                actor => actor.approbation !== 'yes' && !actor.signature && actor.status === 'Présent',
            ),
        [initialValues, user],
    )

    const hasDeleteButton = React.useMemo(() => !!initialValues.id, [initialValues])

    const hasSendApprobationButton = React.useMemo(
        () => verificationFieldsRequired && initialValues.status === 'aFinaliser',
        [initialValues, verificationFieldsRequired],
    )

    const buttonAmount = React.useMemo(() => {
        if (hasDeleteButton) {
            if (hasUserApprobationButton || hasSendApprobationButton) {
                return 4
            }
            return 3
        }
        return 1
    }, [hasDeleteButton, hasSendApprobationButton, hasUserApprobationButton])

    React.useLayoutEffect(() => {
        const updateWidth = () => setWindowWidth(window.innerWidth)
        window.addEventListener('resize', updateWidth)
        return () => window.removeEventListener('resize', updateWidth)
    }, [setWindowWidth])

    const scrollTo = (key: SectionName) => {
        if (ScrollRef.current) {
            const element = document.getElementById(key)
            const yPosition =
                (element &&
                    element.getBoundingClientRect().top +
                        scrollPosition -
                        Theme.constants.webHeaderHeight -
                        50 -
                        (buttonAmount === 4
                            ? FOUR_BUTTONS_SUPP_MARGIN
                            : buttonAmount === 3
                            ? THREE_BUTTONS_SUPP_MARGIN
                            : CLASSICAL_SUP_MARGIN)) ||
                0
            ScrollRef.current.scrollTo({ x: 0, y: yPosition, animated: true })
        }
    }

    React.useEffect(() => {
        if (initialValues.raisonsAvenant && initialValues.raisonsAvenant.length > 0) {
            scrollTo(getSectionToScrollTo(initialValues.raisonsAvenant[0]))
        }
        // eslint-disable-next-line
    }, [initialValues])

    const onScroll = (evt: NativeSyntheticEvent<NativeScrollEvent>) => {
        setScrollPosition(evt.nativeEvent.contentOffset.y)
        const focused = [...KEY_SECTIONS].reverse().find(section => {
            const element = document.getElementById(section)
            return (
                (element &&
                    element.getBoundingClientRect().top <=
                        141 +
                            (buttonAmount === 4
                                ? FOUR_BUTTONS_SUPP_MARGIN
                                : buttonAmount === 3
                                ? THREE_BUTTONS_SUPP_MARGIN
                                : CLASSICAL_SUP_MARGIN)) ||
                false
            )
        })
        if (focused) {
            setFocusedSection(focused)
        }
    }

    const renderSection = (key: SectionName, filled: boolean, isFirst: boolean, isLast: boolean) => (
        <FlexSection key={key}>
            {!isFirst && <ProgressLine isLeft={true} />}
            {!isLast && <ProgressLine isLeft={false} />}
            <ScrollLink onClick={() => scrollTo(key)}>
                <Section key={`sectionBubble${key}`}>
                    <Bubble bubbleFilled={filled || focusedSection === key}>
                        {filled && <Icon name={'checkmark'} size={15} color={Theme.colors.icpStateBarCheckedIcon} />}
                    </Bubble>
                    <SectionName bubbleFilled={filled} numberOfLines={2} ellipsizeMode={'tail'}>
                        {i18n.t(`components.${key}.title`)}
                    </SectionName>
                </Section>
            </ScrollLink>
        </FlexSection>
    )

    return (
        <React.Fragment>
            <StateBar width={windowWidth}>
                <ProgressBar>
                    {bubbleKeys.map((key, index) =>
                        renderSection(
                            key,
                            sectionsFilled.indexOf(key) > -1,
                            index === 0,
                            index === bubbleKeys.length - 1,
                        ),
                    )}
                </ProgressBar>
                <ButtonContainer>
                    {hasSendApprobationButton && (
                        <MarginButton>
                            <Button
                                libelle={i18n.t('screens.editIcpScreen.approbation.sendApprobation')}
                                onPress={() => {
                                    handleSubmit(true)
                                }}
                                status={isSubmitting ? 'loading' : isDeleting ? 'disabled' : 'active'}
                            />
                        </MarginButton>
                    )}
                    {hasUserApprobationButton && (
                        <MarginButton>
                            <Button
                                libelle={i18n.t('screens.editIcpScreen.approbation.userApprobation')}
                                onPress={() => setApprobationModalOpened(true)}
                                status={isSubmitting ? 'loading' : isDeleting ? 'disabled' : 'active'}
                            />
                            <Modal
                                visible={approbationModalOpened}
                                onRequestClose={() => setApprobationModalOpened(false)}
                                noMinHeight
                            >
                                <ModalContent>
                                    <ApprobationTitle>
                                        {i18n.t('screens.editIcpScreen.approbation.title')}
                                    </ApprobationTitle>
                                    <ApprobationDescription>
                                        {i18n.t('screens.editIcpScreen.approbation.description')}
                                    </ApprobationDescription>
                                    <ApprobationButtons>
                                        <Button
                                            libelle={i18n.t('screens.editIcpScreen.approbation.approve')}
                                            onPress={() =>
                                                initialValues.id && handleApprobation('approved', initialValues.id)
                                            }
                                            status={approbationInProgress ? 'loading' : 'active'}
                                            buttonColor={Theme.colors.buttonApprobationColor}
                                        />
                                        <Button
                                            libelle={i18n.t('screens.editIcpScreen.approbation.refuse')}
                                            onPress={() =>
                                                initialValues.id && handleApprobation('refused', initialValues.id)
                                            }
                                            status={approbationInProgress ? 'loading' : 'active'}
                                            buttonColor={Theme.colors.buttonDisapprobationColor}
                                        />
                                    </ApprobationButtons>
                                </ModalContent>
                            </Modal>
                        </MarginButton>
                    )}
                    {hasDeleteButton && (
                        <>
                            <MarginBottomButton>
                                <Button
                                    libelle={i18n.t('screens.editIcpScreen.delete')}
                                    onPress={() => initialValues.id && deleteIcp(initialValues.id)}
                                    status={isDeleting ? 'loading' : isSubmitting ? 'disabled' : 'active'}
                                />
                            </MarginBottomButton>
                            <MarginBottomButton>
                                <Button
                                    libelle={i18n.t('screens.editIcpScreen.preview')}
                                    onPress={() => createPreviewPdf(globalValues, setPreviewStatus)}
                                    status={previewStatus}
                                />
                            </MarginBottomButton>
                        </>
                    )}
                    <Button
                        libelle={i18n.t('screens.editIcpScreen.save')}
                        onPress={() => {
                            handleSubmit(false)
                        }}
                        status={isSubmitting ? 'loading' : isDeleting ? 'disabled' : 'active'}
                    />
                </ButtonContainer>
            </StateBar>
            <View>
                {globalValues.actors.representatives.find(a => !a.signature) && focusedSection !== 'actorsIcp' && (
                    <ScrollToValidationContainer buttonAmount={buttonAmount}>
                        <Button
                            libelle={i18n.t('screens.editIcpScreen.companyValidation')}
                            onPress={() => scrollTo('actorsIcp')}
                            status="active"
                        />
                    </ScrollToValidationContainer>
                )}
                <FormContainer ref={ScrollRef} onScroll={onScroll} buttonAmount={buttonAmount}>
                    <ScrollToDiv id={'infosIcp'} />
                    <InfosICP
                        initialValues={initialValues.informations}
                        onValidate={isValid => setSectionFilled('infosIcp', isValid)}
                        globalSetIcpFieldValue={value => globalSetIcpFieldValue('informations', value)}
                        reasons={initialValues.raisonsAvenant}
                        interventionObjects={interventionObjects}
                        interventionTypes={interventionTypes}
                        updateActors={value => globalSetIcpFieldValue('actors', value)}
                        globalActors={globalValues.actors}
                        setIsDescriptionSet={setIsDescriptionSet}
                    />
                    <Separator />
                    <ScrollToDiv id={'planIcp'} />
                    <PlanICP
                        initialValues={initialValues.planification}
                        onValidate={isValid => setSectionFilled('planIcp', isValid)}
                        globalSetIcpFieldValue={value => globalSetIcpFieldValue('planification', value)}
                        updateActors={value => globalSetIcpFieldValue('actors', value)}
                        globalActors={globalValues.actors}
                        globalInfos={globalValues.informations}
                        redacteurs={redacteurs}
                        approbateurs={approbateurs}
                        cssctItems={cssctItems}
                        wiItems={wiItems}
                        perimeters={perimeters}
                        icpId={icpId}
                        canSendEmail={canSendEmail}
                        handleSubmitWithoutAlert={handleSubmitWithoutAlert}
                        canEditContacts={
                            initialValues.status === 'aPlanifier' ||
                            initialValues.status === 'aRealiser' ||
                            initialValues.status === 'aFinaliser' ||
                            !initialValues.status
                        }
                        isDescriptionSet={isDescriptionSet}
                    />
                    <Separator style={{ zIndex: -1 }} />
                    <ScrollToDiv id={'actorsIcp'} />
                    <View style={{ zIndex: -1 }}>
                        <ActorsICP
                            initialValues={initialValues.actors}
                            onValidate={isValid => setSectionFilled('actorsIcp', isValid)}
                            globalSetIcpFieldValue={value => globalSetIcpFieldValue('actors', value)}
                            globalInfos={globalValues.informations}
                            globalActors={globalValues.actors}
                        />
                    </View>
                    <Separator />
                    <ScrollToDiv id={'documentsIcp'} />
                    <DocumentsICP
                        initialValues={initialValues.documents}
                        onValidate={isValid => setSectionFilled('documentsIcp', isValid)}
                        globalSetIcpFieldValue={value => globalSetIcpFieldValue('documents', value)}
                        documents={documents}
                    />
                    <Separator />
                    <ScrollToDiv id={'proceduresIcp'} />
                    <ProceduresICP
                        initialValues={initialValues.procedures}
                        onValidate={isValid => setSectionFilled('proceduresIcp', isValid)}
                        globalSetIcpFieldValue={value => globalSetIcpFieldValue('procedures', value)}
                    />
                    <Separator />
                    <ScrollToDiv id={'siteIcp'} />
                    <SiteICP
                        initialValues={initialValues.site}
                        onValidate={isValid => setSectionFilled('siteIcp', isValid)}
                        globalSetIcpFieldValue={value => globalSetIcpFieldValue('site', value)}
                    />
                    <Separator />
                    <ScrollToDiv id={'risksIcp'} />
                    <FamilyRiskIcpWeb
                        initialValues={
                            initialValues.familyRisk || {
                                risksAndMeasures: [],
                            }
                        }
                        onValidate={isValid => setSectionFilled('risksIcp', isValid)}
                        companies={companies}
                        globalSetIcpFieldValue={value => globalSetIcpFieldValue('familyRisk', value)}
                    />
                </FormContainer>
            </View>
        </React.Fragment>
    )
}

const StateBar = styled(View)<{ width: number }>`
    position: absolute;
    flex-direction: row;
    align-items: center;

    min-height: 100px;
    /* width: ${props => props.width - props.theme.constants.webMenuWidth}px; */
    top: -10px;
    left: ${props => (props.theme.constants.pageHorizontalPadding - 5) * -1}px;
    right: ${props => (props.theme.constants.pageHorizontalPadding - 5) * -1}px;

    padding-top: 12px;
    padding-bottom: 12px;
    padding-right: 24px;
    padding-left: 24px;

    background-color: ${props => props.theme.colors.icpStateBarBackground};
    border-top: ${STATE_BAR_TOP_BORDER}px solid ${props => props.theme.colors.contentBackground};
    z-index: 1;

    /* width: 100%; */
    /* top: -10px;
    left: -77px;
    right: -77px; */
    
`
const Section = styled(View)`
    align-items: center;
`
const Bubble = styled(View)<{ bubbleFilled: boolean }>`
    cursor: pointer;
    height: 25px;
    width: 25px;
    border-radius: 24px;
    background-color: ${props =>
        props.bubbleFilled ? props.theme.colors.icpStateBarBubbleFilled : props.theme.colors.icpStateBarBubbleEmpty};
    ${props => !props.bubbleFilled && 'border: 2px solid black;'}
    margin-bottom: 10px;
    justify-content: center;
    align-items: center;
`

const SectionName = styled(Text)<{ bubbleFilled: boolean }>`
    text-align: center;
    ${props => props.theme.fonts.editIcpStateBar}
    ${props => props.bubbleFilled && 'font-family: Avenir-Heavy;'}
    cursor: pointer;
`

const Separator = styled(View)`
    background-color: ${props => props.theme.colors.accountSeparator};
    border-radius: 50px;
    margin: ${SEPARATOR_MARGIN}px;
    height: 5px;
`
const FormContainer = styled(ScrollView)<{ buttonAmount: number }>`
    height: ${props =>
        Dimensions.get('window').height -
        props.theme.constants.webHeaderHeight -
        (props.theme.constants.webHeaderHeight + STATE_BAR_TOP_BORDER + 50)}px;
    margin-top: ${props =>
        props.theme.constants.webHeaderHeight +
        5 * STATE_BAR_TOP_BORDER +
        (props.buttonAmount === 4
            ? FOUR_BUTTONS_SUPP_MARGIN
            : props.buttonAmount === 3
            ? THREE_BUTTONS_SUPP_MARGIN
            : CLASSICAL_SUP_MARGIN)}px;
`
const ProgressBar = styled(View)`
    flex: 1;
    flex-direction: row;
    margin-right: 24px;
`
const FlexSection = styled(View)`
    flex: 1;
    min-width: ${SECTION_MIN_WIDTH}px;
`
const ProgressLine = styled(View)<{ isLeft: boolean }>`
    height: 5px;
    width: 50%;
    background-color: ${props => props.theme.colors.icpStateBarProgressLine};
    z-index: -100;
    position: absolute;
    top: 10px;
    ${props => (props.isLeft ? 'left' : 'right')}: 0px;
`
const ScrollToDiv = styled.div`
    position: relative;
    top: ${props => -props.theme.constants.webHeaderHeight - STATE_BAR_TOP_BORDER - SEPARATOR_MARGIN}px;
`
const ScrollLink = styled.a`
    text-decoration: none;
`
const ButtonContainer = styled(View)`
    flex-wrap: wrap;
`
const MarginButton = styled(View)`
    flex-direction: row;
    margin-bottom: 10px;
`
const MarginBottomButton = styled(View)`
    margin-bottom: 5px;
`
// MODALE D'APPROBATION
const ModalContent = styled(View)`
    background-color: ${props => props.theme.colors.documentsModalBackground};
    padding: 24px;
`
const ApprobationTitle = styled(Text)`
    ${props => props.theme.fonts.approbationTitle};
    margin-top: 25px;
    text-align: center;
`
const ApprobationDescription = styled(Text)`
    ${props => props.theme.fonts.approbationDescription};
    margin-top: 50px;
    text-align: center;
`
const ApprobationButtons = styled(View)`
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-top: 50px;
    margin-bottom: 50px;
}
`
const ScrollToValidationContainer = styled(View)<{
    buttonAmount: number
}>`
    position: absolute;
    right: -82px;
    top: ${props =>
        100 +
        (props.buttonAmount === 4
            ? FOUR_BUTTONS_SUPP_MARGIN
            : props.buttonAmount === 3
            ? THREE_BUTTONS_SUPP_MARGIN
            : CLASSICAL_SUP_MARGIN)}px;
    z-index: 1;
`
