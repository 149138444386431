import * as React from 'react'

// Components
import { View, Text, ScrollView } from 'react-native'
import TextInput from '@components/textInput/TextInput'
import Button from '@components/button/Button'
import { Formik } from 'formik'
import RootScreen from '@components/rootScreen/RootScreen'
import CheckBox from '@components/checkbox/Checkbox'
import Alert from '@components/alert/Alert'

import useI18n from '@store/i18n/useI18n'

// Api
import api from '@api/api'

// Utils
import {
    findUserPerimeterFromPerimeterId,
    canPerimeterBeDeactivated,
    canPerimeterBeActivated,
} from '@utils/perimeterUtils'
import Logger from '@utils/logger'

// Style
import styled from '@styles/index'
import useTheme from '@styles/useTheme'

type ActivationActionKey = 'Deactivation' | 'Activation'

interface Props {
    perimeterId: string
}

export default (props: Props) => {
    const { perimeterId } = props
    const i18n = useI18n()
    const [Theme] = useTheme()

    const [allPerimeters, setAllPerimeters] = React.useState<PerimeterUser[] | undefined>(undefined)
    const [status, setStatus] = React.useState<Status>('loading')
    const [perimeter, setPerimeter] = React.useState<PerimeterUser | undefined>(undefined)
    const [roles, setRoles] = React.useState<RoleList | undefined>(undefined)

    React.useEffect(() => {
        Promise.all([api.admin.getUserListByPerimeter(), api.admin.getRoleList()])
            .then(([perimeters, roles]) => {
                if (!!perimeters && !!roles) {
                    const foundPerimeter = findUserPerimeterFromPerimeterId(perimeterId, perimeters)
                    if (!!foundPerimeter) {
                        setAllPerimeters(perimeters)
                        setPerimeter(foundPerimeter)
                        setRoles(roles)
                        setStatus('fetched')
                    } else {
                        setStatus('error')
                    }
                } else {
                    setStatus('error')
                }
            })
            .catch(err => {
                Logger.error(err)
                setStatus('error')
            })
    }, [perimeterId])

    const handleSubmit = (
        values: Libelle,
        setFieldError: (libelle: 'libelle', message: string) => void,
        setSubmitting: (isSubmitting: boolean) => void,
    ) => {
        const trimedLibelle = values.libelle ? values.libelle.trim() : undefined

        if (!!trimedLibelle && !!perimeter) {
            Alert({
                title: i18n.t('screens.editPerimeterAdminScreen.alerts.editPerimeterTitle'),
                content: i18n.t('screens.editPerimeterAdminScreen.alerts.editPerimeterContent'),
                icon: 'question',
                buttons: [
                    {
                        style: 'default',
                        text: i18n.t('screens.perimetersAdminScreen.alerts.confirmButton'),
                        onPress: () => {
                            api.admin
                                .editPerimeter(perimeter.idPerimeter, { libelle: trimedLibelle })
                                .then(() => {
                                    Alert({
                                        title: i18n.t('screens.editPerimeterAdminScreen.alerts.successEditTitle'),
                                        content: i18n.t('screens.editPerimeterAdminScreen.alerts.successEditContent'),
                                        icon: 'success',
                                        buttons: [
                                            {
                                                style: 'default',
                                                text: 'OK',
                                                onPress: () => undefined,
                                            },
                                        ],
                                        cancelable: true,
                                        theme: Theme,
                                    })
                                })
                                .catch(err => {
                                    Alert({
                                        title: i18n.t('screens.editPerimeterAdminScreen.alerts.errorEditTitle'),
                                        content: i18n.t('screens.editPerimeterAdminScreen.alerts.errorEditContent'),
                                        icon: 'error',
                                        buttons: [
                                            {
                                                style: 'default',
                                                text: 'OK',
                                                onPress: () => undefined,
                                            },
                                        ],
                                        theme: Theme,
                                    })
                                    Logger.error(err)
                                })
                                .finally(() => setSubmitting(false))
                        },
                    },
                    {
                        style: 'cancel',
                        text: i18n.t('screens.perimetersAdminScreen.alerts.cancelButton'),
                        onPress: () => setSubmitting(false),
                    },
                ],
                cancelable: true,
                theme: Theme,
            })
        } else {
            setFieldError('libelle', i18n.t('screens.perimetersAdminScreen.required'))
            setSubmitting(false)
        }
    }

    const showImpossibleActionAlert = (key: ActivationActionKey) =>
        Alert({
            title: i18n.t(`screens.editPerimeterAdminScreen.alerts.impossible${key}Title`),
            content: i18n.t(`screens.editPerimeterAdminScreen.alerts.impossible${key}Content`),
            icon: 'error',
            buttons: [
                {
                    style: 'default',
                    text: i18n.t('screens.perimetersAdminScreen.alerts.confirmButton'),
                    onPress: () => undefined,
                },
            ],
            cancelable: true,
            theme: Theme,
        })

    const onActiveCheckConfirmed = (id: string, active: boolean, key: string) =>
        api.admin
            .modifyPerimeterActivation(id, active)
            .then(() => {
                Alert({
                    title: i18n.t(`screens.perimetersAdminScreen.alerts.success${key}Title`),
                    content: i18n.t(`screens.perimetersAdminScreen.alerts.success${key}Content`),
                    icon: 'success',
                    buttons: [
                        {
                            style: 'default',
                            text: i18n.t('screens.perimetersAdminScreen.alerts.confirmButton'),
                            onPress: () => perimeter && setPerimeter({ ...perimeter, active: !perimeter.active }),
                        },
                    ],
                    theme: Theme,
                })
            })
            .catch(err => {
                Alert({
                    title: i18n.t(`screens.perimetersAdminScreen.alerts.error${key}Title`),
                    content: i18n.t(`screens.perimetersAdminScreen.alerts.error${key}Content`),
                    icon: 'error',
                    buttons: [
                        {
                            style: 'default',
                            text: i18n.t('screens.perimetersAdminScreen.alerts.confirmButton'),
                            onPress: () => undefined,
                        },
                    ],
                    theme: Theme,
                })
                Logger.error(err)
            })

    const handleActiveCheck = (id: string, active: boolean) => {
        const key = active ? 'Activation' : 'Deactivation'
        Alert({
            title: i18n.t(`screens.perimetersAdminScreen.alerts.confirm${key}Title`),
            content: i18n.t(`screens.perimetersAdminScreen.alerts.confirm${key}Content`),
            icon: 'question',
            buttons: [
                {
                    style: 'default',
                    text: i18n.t('screens.perimetersAdminScreen.alerts.confirmButton'),
                    onPress: () => onActiveCheckConfirmed(id, active, key),
                },
                {
                    style: 'cancel',
                    text: i18n.t('screens.perimetersAdminScreen.alerts.cancelButton'),
                    onPress: () => undefined,
                },
            ],
            cancelable: true,
            theme: Theme,
        })
    }

    const changePerimeterActivation = (perimeter: PerimeterUser) => {
        if (perimeter.active) {
            if (canPerimeterBeDeactivated(perimeter)) {
                handleActiveCheck(perimeter.idPerimeter, !perimeter.active)
            } else {
                showImpossibleActionAlert('Deactivation')
            }
        } else {
            if (!!allPerimeters && canPerimeterBeActivated(allPerimeters, perimeter.idPerimeter)) {
                handleActiveCheck(perimeter.idPerimeter, !perimeter.active)
            } else {
                showImpossibleActionAlert('Activation')
            }
        }
    }

    return (
        <RootScreen status={status}>
            {perimeter && (
                <Formik
                    initialValues={{ libelle: perimeter.libelle } as Libelle}
                    onSubmit={(values, { setFieldError, setSubmitting }) =>
                        handleSubmit(values, setFieldError, setSubmitting)
                    }
                >
                    {({ handleSubmit, values, setFieldValue, isSubmitting, errors }) => (
                        <React.Fragment>
                            <InputContainer>
                                <TextInputContainer>
                                    <TextInput
                                        label={i18n.t('screens.editPerimeterAdminScreen.name')}
                                        value={values.libelle}
                                        onTextChange={text => setFieldValue('libelle', text)}
                                        placeholder={i18n.t('screens.editPerimeterAdminScreen.inputPlaceholder')}
                                        required
                                        error={errors.libelle}
                                        disabled={isSubmitting}
                                    />
                                </TextInputContainer>
                                <Button
                                    libelle={i18n.t('screens.editPerimeterAdminScreen.validate')}
                                    onPress={handleSubmit}
                                    status={isSubmitting ? 'loading' : 'active'}
                                    buttonColor={Theme.colors.buttonBackgroundValidate}
                                />
                            </InputContainer>

                            <CheckboxContainer>
                                <CheckBox
                                    value={perimeter.active}
                                    onCheck={() => changePerimeterActivation(perimeter)}
                                    label={i18n.t('screens.editPerimeterAdminScreen.active')}
                                />
                            </CheckboxContainer>
                            <InformationCategoryName>
                                {i18n.t('screens.editPerimeterAdminScreen.users')}
                            </InformationCategoryName>
                            <ResultsContainer>
                                <LegendLine>
                                    <NameLegend>
                                        <Legend>{i18n.t('screens.usersAdminScreen.resultsLegend.name')}</Legend>
                                    </NameLegend>
                                    <RoleContainer>
                                        <Legend>{i18n.t('screens.usersAdminScreen.resultsLegend.role')}</Legend>
                                    </RoleContainer>
                                    <ActiveContainer>
                                        <Legend>{i18n.t('screens.usersAdminScreen.resultsLegend.active')}</Legend>
                                    </ActiveContainer>
                                </LegendLine>
                                {perimeter.users.length === 0 ? (
                                    <NoUserMessage>{i18n.t('screens.editPerimeterAdminScreen.noUser')}</NoUserMessage>
                                ) : (
                                    <ScrollView>
                                        {perimeter.users.map((u, index) => {
                                            const userRole = !!roles ? roles.find(r => r.id === u.idRole) : undefined
                                            return (
                                                <UserLine key={u.id} odd={index % 2 === 1}>
                                                    <UserName numberOfLines={2} ellipsizeMode={'tail'}>
                                                        {`${u.lastName.toUpperCase()} ${u.firstName}`}
                                                    </UserName>

                                                    <RoleContainer>
                                                        <UserInformation>
                                                            {userRole
                                                                ? userRole.name
                                                                : i18n.t(
                                                                      'screens.editPerimeterAdminScreen.unknownRole',
                                                                  )}
                                                        </UserInformation>
                                                    </RoleContainer>
                                                    <ActiveContainer>
                                                        <UserInformation>
                                                            {i18n.t(
                                                                `screens.usersAdminScreen.${
                                                                    u.active ? 'active' : 'inactive'
                                                                }`,
                                                            )}
                                                        </UserInformation>
                                                    </ActiveContainer>
                                                </UserLine>
                                            )
                                        })}
                                    </ScrollView>
                                )}
                            </ResultsContainer>
                        </React.Fragment>
                    )}
                </Formik>
            )}
        </RootScreen>
    )
}

const ResultsContainer = styled(View)`
    flex: 1;
    background-color: white;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 24px;
    padding-bottom: 24px;
    margin-bottom: 36px;
`
const LegendLine = styled(View)`
    flex-direction: row;
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom: 8px;
`
const NameLegend = styled(View)`
    flex: 1;
`
const RoleContainer = styled(View)`
    width: 150px;
`
const ActiveContainer = styled(View)`
    width: 35px;
`
const Legend = styled(Text)`
    ${props => props.theme.fonts.perimeterLegend}
    text-transform: uppercase;
`
const UserLine = styled(View)<{ odd: boolean }>`
    flex-direction: row;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
    height: ${props => props.theme.constants.rightLineHeight}px;
    ${props => !props.odd && `background-color: ${props.theme.colors.evenLines}`}
`
const UserName = styled(Text)`
    flex: 1;
    ${props => props.theme.fonts.perimeterName}
    font-family: Avenir-Heavy;
`
const NoUserMessage = styled(Text)`
    ${props => props.theme.fonts.perimeterName}
    text-align: center;
    margin-top: 15px;
`
const UserInformation = styled(Text)`
    ${props => props.theme.fonts.perimeterName}
    font-family: Avenir-Heavy;
`
const TextInputContainer = styled(View)`
    flex: 1;
    margin-right: 24px;
`
const InformationCategoryName = styled(Text)`
    padding-left: 24px;
    margin-bottom: 10px;
    margin-top: 60px;
    ${props => props.theme.fonts.roleRights}
    color: ${props => props.theme.colors.rightCategory};
    text-transform: uppercase;
`
const CheckboxContainer = styled(View)`
    margin-top: 10px;
    padding-left: 24px;
`
const InputContainer = styled(View)`
    margin-bottom: 15px;
    flex-direction: row;
    align-items: flex-end;
`
