import * as React from 'react'

// Components
import { View, Platform, Text } from 'react-native'
import Container from '@components/icpFormContainer/icpFormContainer'
import ReadIcpAgencies from '@components/addIcpAgencies/ReadIcpAgencies'
import ReadIcpRepresentative from '@components/addIcpRepresentatives/ReadIcpRepresentatives'

// Store
import useI18n from '@store/i18n/useI18n'

// Style
import styled from '@styles/index'

interface Props {
    values: ActorsIcp
}

export default (props: Props) => {
    const { values } = props
    const i18n = useI18n()

    return (
        <Container title={i18n.t('components.actorsIcp.title')}>
            <View>
                <Section zIndex={-1}>
                    <SectionTitle>{i18n.t('components.actorsIcp.sectionsTitles.agenciesList')}</SectionTitle>
                    <ReadIcpAgencies values={values.agencies} label={i18n.t('components.actorsIcp.agencies')} />
                </Section>
                <Section zIndex={-1}>
                    <SectionTitle>{i18n.t('components.actorsIcp.sectionsTitles.representatives')}</SectionTitle>
                    <ReadIcpRepresentative
                        label={i18n.t('components.actorsIcp.labels.representatives')}
                        values={values.representatives}
                    />
                </Section>
            </View>
        </Container>
    )
}

const Section = styled(View)<{ zIndex: number }>`
    z-index: ${props => props.zIndex};
    margin-bottom: ${Platform.OS === 'web' ? '24px' : '15px'};
`
const SectionTitle = styled(Text)`
    ${props => props.theme.fonts.icpFormTitle}
    font-family: Avenir-Book;
    margin-bottom: 20px;
`
