import * as React from 'react'

// Components
import { View, Text, Platform, ActivityIndicator, ScrollView } from 'react-native'
import Picker from '@components/picker/Picker'
import SearchInput from '@components/searchInput/SearchInput'
import { TouchableItemFeedback } from '@components/touchable/Touchable'
import Pagination from '@components/pagination/Pagination'

import useI18n from '@store/i18n/useI18n'

// Api
import api from '@api/api'

// Utils
import * as JsSearch from 'js-search'
import accentSanitizer from '@utils/accentSanitizer'

// Style
import styled from '@styles/index'
import Logger from '@utils/logger'
import useTheme from '@styles/useTheme'

//* INITIALISATION POUR RECHERCHE
const sanitizer = accentSanitizer()
let searchjs: JsSearch.Search

const initSearchJs = (documents: any[]) => {
    searchjs = new JsSearch.Search('id')
    searchjs.searchIndex = new JsSearch.UnorderedSearchIndex()
    searchjs.indexStrategy = new JsSearch.AllSubstringsIndexStrategy()
    searchjs.tokenizer = {
        tokenize: (text: string) => text.split(' '),
    }
    searchjs.sanitizer = sanitizer
    searchjs.addIndex('nom')
    searchjs.addIndex('prenom')
    searchjs.addIndex('mail')
    searchjs.addIndex('address')
    searchjs.addIndex('telephone1')
    searchjs.addIndex('telephone2')
    searchjs.addDocuments(documents)
}

interface Props {
    renderContact?: (c: Contact, i: number, entity?: Agence, company?: Entreprise) => JSX.Element
    onContactPress?: (contact: Contact, company?: Entreprise, entity?: Agence) => void
    contactByPage?: number
    filterFunction?: (contacts: Contact[]) => Contact[]
    setOfflineMode?: () => void
}

export default (props: Props) => {
    const {
        renderContact,
        onContactPress = () => null,
        contactByPage,
        filterFunction,
        setOfflineMode = () => null,
    } = props
    const i18n = useI18n()
    const [Theme] = useTheme()

    const [contactLoading, setContactLoading] = React.useState<boolean>(true)
    const [text, setText] = React.useState<string>('')

    const [allCompanies, setAllCompanies] = React.useState<Entreprises>([])
    const [companySelected, setCompanySelected] = React.useState<Entreprise | undefined>(undefined)

    const [allEntities, setAllEntities] = React.useState<Agences>([])
    const [entitySelected, setEntitySelected] = React.useState<Agence | undefined>(undefined)

    const [allContacts, setAllContacts] = React.useState<Contact[]>([])
    const [contactsToRender, setContactsToRender] = React.useState<Contact[]>([])

    const [contactPressedLoading, setContactPressedLoading] = React.useState<number | undefined>()

    // GESTION DES DONNÉES
    React.useEffect(() => {
        Promise.all([api.company.getAllCompanies(), api.contact.getAllContacts()])
            .then(([companies, contacts]) => {
                setAllCompanies(companies.sort((a, b) => a.libelle.localeCompare(b.libelle)))
                setAllContacts(contacts.sort((a, b) => a.nom.localeCompare(b.nom)))
                setContactLoading(false)
            })
            .catch(err => {
                Logger.error('Une erreur est survenue...', err)
                setOfflineMode()
                setContactLoading(false)
            })
        // eslint-disable-next-line
    }, [])

    // AFFICHAGE
    const defaultRenderContact = (c: Contact, i: number, entity?: Agence, company?: Entreprise) => {
        return (
            <TouchableItemFeedback
                key={`contact${i}`}
                onPress={() => {
                    setContactPressedLoading(i)
                    if (!entity && company) {
                        api.company
                            .getAllAgenciesByCompany(company.id)
                            .then(entities => {
                                const contactEntity = entities.find(e => e.id === c.idAgence)
                                onContactPress(c, company, contactEntity)
                                setContactPressedLoading(undefined)
                            })
                            .catch(err => {
                                Logger.error('Une erreur est survenue lors de la récuperation des agences...')
                                onContactPress(c, company, entity)
                                setContactPressedLoading(undefined)
                            })
                    } else {
                        onContactPress(c, company, entity)
                        setContactPressedLoading(undefined)
                    }
                }}
            >
                {contactPressedLoading === i ? (
                    <ContactContainerLoading key={i} odd={i % 2 !== 0}>
                        <ActivityIndicator
                            size={Theme.constants.activityIndicatorScreenSize * 0.5}
                            color={Theme.colors.activityIndicator}
                        />
                    </ContactContainerLoading>
                ) : (
                    <ContactContainer key={i} odd={i % 2 !== 0}>
                        <ContactColumn>
                            <ContactInformation main>{c.nom}</ContactInformation>
                            <ContactInformation main>{c.prenom}</ContactInformation>
                        </ContactColumn>
                        <ContactColumn double>
                            <ContactInformation>{c.mail}</ContactInformation>
                            <ContactInformation>{c.telephone1}</ContactInformation>
                            <ContactInformation>{c.telephone2}</ContactInformation>
                        </ContactColumn>
                        <ContactColumn>
                            {company && <ContactInformation main>{company.libelle}</ContactInformation>}
                            {entity && <ContactInformation>{entity.libelle}</ContactInformation>}
                        </ContactColumn>
                    </ContactContainer>
                )}
            </TouchableItemFeedback>
        )
    }

    const fetchContacts = (): Promise<Contact[]> => {
        if (entitySelected) {
            return api.entity.getAllContactsByEntity(entitySelected.id)
        } else if (companySelected) {
            return api.company.getAllContactsByCompany(companySelected.id)
        } else {
            return api.contact.getAllContacts()
        }
    }

    const fetchEntities = (): Promise<Agences> => {
        if (companySelected) {
            return api.company.getAllAgenciesByCompany(companySelected.id)
        } else {
            return new Promise<Agences>((r, _) => r([]))
        }
    }

    const fetchCompanies = (): Promise<Entreprises> => api.company.getAllCompanies()

    const fetchDatas = () => {
        setContactLoading(true)
        Promise.all([fetchCompanies(), fetchEntities(), fetchContacts()])
            .then(([a, b, c]) => {
                setAllCompanies(a.sort((a, b) => a.libelle.localeCompare(b.libelle)))
                setAllEntities(b.sort((a, b) => a.libelle.localeCompare(b.libelle)) as Agences)
                setAllContacts(c.sort((a, b) => a.nom.localeCompare(b.nom)))

                setContactLoading(false)
            })
            .catch(err => {
                Logger.error('Une erreur est survenue lors de la récupération des données...', err)

                setContactLoading(false)
            })
    }

    React.useEffect(fetchDatas, [companySelected, entitySelected])

    const changeCompany = (i: number) => {
        setCompanySelected(allCompanies[i])
        setEntitySelected(undefined)
    }

    const changeEntity = (i: number) => setEntitySelected(allEntities[i])

    const optionChoices = () => (
        <SectionContainer zIndex={0} noFlex>
            <PickerLine zIndex={0}>
                <PickerContainer isLeft={Platform.OS === 'web'}>
                    <Picker
                        value={companySelected ? companySelected.libelle : ''}
                        data={allCompanies.map(c => c.libelle)}
                        onChange={changeCompany}
                        isClearable
                        isSearchable
                        label={i18n.t('screens.contactScreen.title.companies', { count: allCompanies.length })}
                    />
                </PickerContainer>
                <PickerContainer>
                    <Picker
                        value={entitySelected ? entitySelected.libelle : ''}
                        data={allEntities.map(e => e.libelle)}
                        onChange={changeEntity}
                        disabled={!companySelected}
                        isClearable
                        isSearchable
                        label={i18n.t('screens.contactScreen.title.entities', { count: allEntities.length })}
                    />
                </PickerContainer>
            </PickerLine>
            <SearchResultsContainer zIndex={-1}>
                <SearchInput
                    onTextChange={setText}
                    placeholder={i18n.t('screens.contactScreen.searchPlaceholder')}
                    resultsCount={searchedContacts.length}
                />
            </SearchResultsContainer>
        </SectionContainer>
    )

    //* RECHERCHE DES ELEMENTS PAR TEXTE
    const searchedContacts = React.useMemo((): Contact[] => {
        const searchedList = (text.trim() ? searchjs.search(sanitizer.sanitize(text.trim())) : allContacts) as Contact[]
        return filterFunction ? filterFunction(searchedList) : searchedList
    }, [allContacts, text, filterFunction])

    React.useMemo(() => initSearchJs(allContacts), [allContacts])

    return (
        <ContactSectionContainer>
            {optionChoices()}
            <SectionContainer zIndex={-1}>
                {Platform.OS !== 'web' && (
                    <Pagination
                        itemsByPage={contactByPage || 5}
                        allItems={searchedContacts}
                        setItemsToRender={setContactsToRender}
                    />
                )}
                <ContactList>
                    {contactLoading ? (
                        <ActivityIndicatorContainer>
                            <ActivityIndicator
                                size={Theme.constants.activityIndicatorScreenSize}
                                color={Theme.colors.activityIndicator}
                            />
                        </ActivityIndicatorContainer>
                    ) : (Platform.OS === 'web' ? searchedContacts : contactsToRender).length === 0 ? (
                        <NoContactFound>{i18n.t('screens.contactScreen.noContactFound')}</NoContactFound>
                    ) : (
                        <ScrollView>
                            {(Platform.OS === 'web' ? searchedContacts : contactsToRender).map((c, i) => {
                                const entity = allEntities.find(e => e.id === c.idAgence)
                                const company = allCompanies.find(a => a.id === c.idEntreprise)
                                return !!renderContact
                                    ? renderContact(c, i, entity, company)
                                    : defaultRenderContact(c, i, entity, company)
                            })}
                        </ScrollView>
                    )}
                </ContactList>
            </SectionContainer>
        </ContactSectionContainer>
    )
}
const ContactSectionContainer = styled(View)`
    flex: 1;
`
const SectionContainer = styled(View)<{ zIndex: number; noFlex?: boolean }>`
    ${props => !props.noFlex && 'flex: 1;'}
    z-index: ${props => props.zIndex};
`

// PICKERS
const PickerLine = styled(View)<{ zIndex: number }>`
    flex-direction: ${Platform.OS === 'web' ? 'row' : 'column'};
    margin-bottom: ${Platform.OS === 'web' ? '40px' : '20px'};
    ${Platform.OS === 'web' && 'align-items: center;'}
    z-index: ${props => props.zIndex};
`
const PickerContainer = styled(View)<{ isLeft?: boolean }>`
    ${Platform.OS === 'web' && 'flex: 1;'}
    ${props => props.isLeft && `margin-right: ${Platform.OS === 'web' ? '40px' : '20px'};`}
    ${Platform.OS !== 'web' && 'margin-bottom: 15px;'}
`

//SEARCH
const SearchResultsContainer = styled(View)<{ zIndex: number }>`
    z-index: ${props => props.zIndex};
    margin-bottom: ${Platform.OS === 'web' ? '60px' : '30px'};
`

// CONTACT LISTE
const NoContactFound = styled(Text)`
    text-align: center;
    ${props => props.theme.fonts.noItemLabel}
`
const ContactList = styled(View)`
    flex: 1;
    background-color: ${props => props.theme.colors.contactListBackground};
    padding: ${Platform.OS === 'web' ? '30px' : '10px'};
    z-index: -1;
`

const ContactContainer = styled(View)<{ odd: boolean }>`
    background-color: ${props => (props.odd ? props.theme.colors.oddLine : props.theme.colors.evenLine)};
    padding: 10px;

    flex-direction: row;
    ${props =>
        Platform.OS === 'web' &&
        `&:hover {
        background-color: ${props.theme.colors.contactLineHover};
    }`}
`
const ContactContainerLoading = styled(View)<{ odd: boolean }>`
    background-color: ${props => (props.odd ? props.theme.colors.oddLine : props.theme.colors.evenLine)};
    padding: 10px;

    flex-direction: row;
    ${props =>
        Platform.OS === 'web' &&
        `&:hover {
    background-color: ${props.theme.colors.contactLineHover};
}`}
    justify-content: center;
    align-items: center;
`
const ContactInformation = styled(Text)<{ main?: boolean }>`

    ${props => (props.main ? props.theme.fonts.contactMainInformation : props.theme.fonts.contactInformation)}
    margin-left: ${props => !props.main && '20px'};
`
const ContactColumn = styled(View)<{ double?: boolean }>`
    flex: ${props => (props.double ? '2' : '1')};
    flex-direction: column;
`
const ActivityIndicatorContainer = styled(View)`
    justify-content: center;
    padding-top: 10px;
    padding-bottom: 10px;
`
