import * as React from 'react'

// Components
import { View, Text, Platform } from 'react-native'
import { TouchableItemFeedback } from '@components/touchable/Touchable'
import Icon from '@components/icon/Icon'
import FieldInformation from '@components/fieldInformation/FieldInformation'

// Style
import styled from '@styles/index'
import useTheme from '@styles/useTheme'

interface Props {
    label?: string
    subLabel?: string
    required?: boolean
    labels: string[]
    values: string[]
    error?: string
    radio: boolean
    onChange: (values: string[]) => void
    disabled?: boolean
}

export default (props: Props) => {
    const { labels, values, onChange, error, radio, label, required, subLabel, disabled } = props
    const [Theme] = useTheme()

    return (
        <View>
            <FieldInformation label={label} required={required} error={error} subLabel={subLabel} />
            <CheckboxLineContainer error={!!error}>
                {labels.map((label, index) => {
                    const foundIndex = values.indexOf(label)
                    const isChecked = foundIndex > -1
                    return (
                        <TouchableItemFeedback
                            key={`option${index}`}
                            onPress={() =>
                                onChange(
                                    radio
                                        ? isChecked
                                            ? []
                                            : [label]
                                        : isChecked
                                        ? values.filter((value, i) => i !== foundIndex)
                                        : [...values, label],
                                )
                            }
                            disabled={disabled}
                        >
                            <CheckboxItem key={`option${index}`}>
                                {radio ? (
                                    <Radio selected={isChecked} disabled={disabled} />
                                ) : (
                                    <Icon
                                        name={isChecked ? 'checkbox-checked' : 'checkbox-unchecked'}
                                        size={14}
                                        color={disabled ? Theme.colors.checkboxDisabled : Theme.colors.checkbox}
                                    />
                                )}
                                <Label disabled={disabled}>{label}</Label>
                            </CheckboxItem>
                        </TouchableItemFeedback>
                    )
                })}
            </CheckboxLineContainer>
        </View>
    )
}

const CheckboxLineContainer = styled(View)<{ error?: boolean }>`
    background-color: ${props => props.theme.colors.rubriqueBackground};
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 24px;
    padding-right: 24px;
    flex-direction: row;
    flex-wrap: wrap;
    ${props => props.error && 'border: ' + props.theme.colors.textInputBorderError + ' solid 1px;'}
`
const CheckboxItem = styled(View)`
    flex-direction: row;
    align-items: center;
    margin-right: 48px;
    ${Platform.OS === 'web' && 'cursor: pointer;'}
`
const Label = styled(Text)<{ disabled?: boolean }>`
    ${props => (props.disabled ? props.theme.fonts.checkboxLabelDisabled : props.theme.fonts.checkboxLabel)}
    font-size: 14px;
    margin-left: ${props => props.theme.constants.checkboxLabelPadding};
    text-align: justify;
`
const Radio = styled(View)<{ selected: boolean; disabled?: boolean }>`
    height: 14px;
    width: 14px;
    border-radius: 20px;
    border-width: ${props => (props.selected ? '5px' : '1px')};
    border-color: ${props => (props.disabled ? props.theme.colors.checkboxDisabled : props.theme.colors.checkbox)};
`
