import * as React from 'react'
import { Text, View, Platform } from 'react-native'
import styled from '@styles/index'
import RootScreen from '@components/rootScreen/RootScreen'
import Button from '@components/button/Button'
import Icon from '@components/icon/Icon'
import config from '@app/config'

import Azure from '@utils/azure/Azure'
import useI18n from '@store/i18n/useI18n'

import * as store from '@store/store'
import * as synchroStore from '@store/synchro'
import * as userStore from '@store/user'
import useReducer from '@store/useReducer'

import Picker from '@components/picker/Picker'
import api from '@app/api/api'
import Logger from '@utils/logger'
import { unsubscribeLogOut } from '@utils/push'
import useTheme from '@styles/useTheme'

export default (): any => {
    const i18n = useI18n()
    const [Theme] = useTheme()

    const user = useReducer(userStore.store, s => s)
    const synchro = useReducer(synchroStore.store, s => s)

    const [offlineData, setOfflineData] = React.useState(false)
    const [status, setStatus] = React.useState<Status>('loading')
    const [disconnectionStatus, setDisconnectionStatus] = React.useState<ButtonStatus>('active')
    const [synchronizationStatus, setSynchronizationStatus] = React.useState<ButtonStatus>('active')
    const [approbateurList, setApprobateurList] = React.useState<UserApprobateur[]>([])

    const defaultApprobateur = React.useMemo(() => {
        if (!!user) {
            return approbateurList.find(a => a.id === user.idApprobateur)
        }
    }, [approbateurList, user])

    const stringifyUserName = (user: UserApprobateur) => `${user.lastName.toUpperCase()} ${user.firstName}`

    React.useEffect(() => {
        api.login
            .login()
            .then(user => {
                setStatus('fetched')
                userStore.actions.setUser(user)
            })
            .catch(err => {
                setStatus('fetched')
                Logger.info("Erreur de récupération de l'utilisateur", err)
                setOfflineData(true)
            })
    }, [])

    React.useEffect(() => {
        api.users
            .getApprobateurList()
            .then(a =>
                setApprobateurList(
                    a.data.sort((userA, userB) => stringifyUserName(userA).localeCompare(stringifyUserName(userB))),
                ),
            )
            .catch(err => Logger.error('Une erreur est survenue dans la récuperation des approbateur...', err))
    }, [])

    const synchronize = () => {
        setSynchronizationStatus('loading')
        setTimeout(() => {
            synchroStore.actions.setLastSynchronizationDate(undefined)
            setSynchronizationStatus('active')
        }, 500)
    }

    const logout = () => {
        if (Platform.OS !== 'web') {
            if (user) {
                unsubscribeLogOut(user.id)
            }
        }
        setDisconnectionStatus('loading')
        setTimeout(() => {
            Azure.logout()
            store.reset()

            setDisconnectionStatus('disabled')
        }, 1000)
    }

    const setDefaultApprobateur = (i: number) => {
        if (user) {
            const newApprobateur = approbateurList[i]
            if (!!newApprobateur) {
                api.users.setDefaultApprobateur(newApprobateur && newApprobateur.id).then(() => {
                    userStore.actions.setUser({ ...user, idApprobateur: newApprobateur.id })
                })
            }
        }
    }

    if (!user) {
        return null
    }

    return (
        <RootScreen status={status} offlineDatas={offlineData} scrollable>
            {Platform.OS !== 'web' && (
                <React.Fragment>
                    <SynchroInfo>
                        <SynchroInfoText titleInfo boldInfo ellipsizeMode="tail" numberOfLines={1}>
                            {i18n.t('screens.account.synchronize.title')}
                        </SynchroInfoText>
                        <Separator />
                        <SynchroLine>
                            <SynchroInfoText boldInfo ellipsizeMode="tail" numberOfLines={1}>
                                {i18n.t('screens.account.synchronize.hasData')}
                            </SynchroInfoText>
                            <SynchroIcon>
                                <Icon
                                    name={synchro.synchronized ? 'checkmark' : 'cross'}
                                    size={15}
                                    color={synchro.synchronized ? 'green' : 'red'}
                                />
                            </SynchroIcon>
                        </SynchroLine>
                        <SynchroLine>
                            <SynchroInfoText boldInfo ellipsizeMode="tail" numberOfLines={1}>
                                {i18n.t('screens.account.synchronize.lastSynchronisationSucceded')}
                            </SynchroInfoText>
                            {synchro.lastSynchronizationSuccededDate ? (
                                <SynchroInfoText ellipsizeMode="tail" numberOfLines={1}>
                                    {i18n.t('screens.account.synchronize.lastSynchronisationDate', {
                                        date: new Date(synchro.lastSynchronizationSuccededDate),
                                    })}
                                </SynchroInfoText>
                            ) : (
                                <Icon name="cross" size={15} color="red" />
                            )}
                        </SynchroLine>
                        <SynchroLine>
                            <SynchroInfoText boldInfo ellipsizeMode="tail" numberOfLines={1}>
                                {i18n.t('screens.account.synchronize.lastSynchronisation')}
                            </SynchroInfoText>
                            {synchro.lastSynchronizationDate ? (
                                <SynchroInfoText ellipsizeMode="tail" numberOfLines={1}>
                                    {i18n.t('screens.account.synchronize.lastSynchronisationDate', {
                                        date: new Date(synchro.lastSynchronizationDate),
                                    })}
                                </SynchroInfoText>
                            ) : (
                                <Icon name="cross" size={15} color="red" />
                            )}
                        </SynchroLine>
                        <SynchroLine>
                            <SynchroInfoText boldInfo ellipsizeMode="tail" numberOfLines={1}>
                                {i18n.t('screens.account.synchronize.lastSynchronisationState')}
                            </SynchroInfoText>
                            <SynchroIcon>
                                <Icon
                                    name={synchro.lastSynchronizationState ? 'checkmark' : 'cross'}
                                    size={15}
                                    color={synchro.lastSynchronizationState ? 'green' : 'red'}
                                />
                            </SynchroIcon>
                        </SynchroLine>
                    </SynchroInfo>
                    <ButtonContainer>
                        <Button
                            libelle={i18n.t('screens.account.synchronize.button')}
                            onPress={synchronize}
                            status={synchronizationStatus}
                        />
                    </ButtonContainer>
                </React.Fragment>
            )}
            <Separator />
            <LineContainer>
                <InfoContainer>
                    <InfoLibelle>{i18n.t('screens.account.firstName')}</InfoLibelle>
                    <TextContainer>
                        <InfoValue>{user.firstName}</InfoValue>
                    </TextContainer>
                </InfoContainer>
                <InfoContainer>
                    <InfoLibelle>{i18n.t('screens.account.name')}</InfoLibelle>
                    <TextContainer>
                        <InfoValue>{user.lastName}</InfoValue>
                    </TextContainer>
                </InfoContainer>
            </LineContainer>
            <LineContainer>
                <InfoContainer>
                    <InfoLibelle>{i18n.t('screens.account.mail')}</InfoLibelle>
                    <TextContainer>
                        <InfoValue>{user.email}</InfoValue>
                    </TextContainer>
                </InfoContainer>
            </LineContainer>
            <LineContainer>
                <InfoContainer>
                    <InfoLibelle>{i18n.t('screens.account.perimeterId')}</InfoLibelle>
                    <TextContainer>
                        <InfoValue>
                            {user.perimeter ? user.perimeter.libelle : i18n.t('screens.account.noPerimeter')}
                        </InfoValue>
                    </TextContainer>
                </InfoContainer>
            </LineContainer>
            <LineContainer>
                <InfoContainer>
                    <InfoLibelle>{i18n.t('screens.account.role')}</InfoLibelle>
                    <TextContainer>
                        <InfoValue>{user.roleName}</InfoValue>
                    </TextContainer>
                </InfoContainer>
            </LineContainer>
            <LineContainer>
                <AdministrationContainer>
                    <Icon
                        name={user.admin ? 'checkbox-checked' : 'checkbox-unchecked'}
                        size={15}
                        color={Theme.colors.accountCheckboxIcon}
                    />
                    <AdministrationLibelle>{i18n.t('screens.account.isAdmin')}</AdministrationLibelle>
                </AdministrationContainer>
            </LineContainer>
            <Separator />
            {approbateurList.length !== 0 && (
                <LineContainer>
                    <InfoContainer>
                        <InfoLibelle>{i18n.t('screens.account.defaultApprobateur')}</InfoLibelle>
                        <PickerContainer>
                            <Picker
                                data={approbateurList.map(a => a.lastName.toUpperCase() + ' ' + a.firstName)}
                                value={!!defaultApprobateur ? stringifyUserName(defaultApprobateur) : undefined}
                                placeholder={i18n.t('screens.account.defaultApprobateurPlaceholder')}
                                onChange={setDefaultApprobateur}
                                isClearable
                            />
                        </PickerContainer>
                    </InfoContainer>
                </LineContainer>
            )}
            <Separator />
            {Platform.OS !== 'web' && (
                <ButtonContainer>
                    <Button
                        libelle={i18n.t('screens.account.disconnect')}
                        onPress={logout}
                        status={disconnectionStatus}
                    />
                </ButtonContainer>
            )}
            <VersionNumberContainer>
                <VersionNumber>{'Version ' + config.VERSION_NAME}</VersionNumber>
            </VersionNumberContainer>
        </RootScreen>
    )
}

// APPRO DEFAUT
const PickerContainer = styled(View)`
    padding-left: 10px;
    padding-right: 10px;
`
const Separator = styled(View)`
    background-color: ${props => props.theme.colors.accountSeparator};
    border-radius: 50px;
    margin: 20px 20px 10px 20px;
    height: 5px;
    z-index: -1;
`

// AUTRE
const VersionNumber = styled(Text)`
    ${props => props.theme.fonts.versionName};
`
const VersionNumberContainer = styled(View)`
    margin-top: 20px;
    margin-bottom: 50px;
    justify-content: center;
    align-items: center;
    z-index: -1;
`
const ButtonContainer = styled(View)`
    flex: 1;
    margin-top: 20px;
    justify-content: center;
    align-items: center;
`
const AdministrationLibelle = styled(Text)`
    margin-left: 10px;
    ${props => props.theme.fonts.accountInformationLibelle};
`
const AdministrationContainer = styled(View)`
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    margin-left: 10px;
    margin-top: 10px;
    flex: 1;
`
const LineContainer = styled(View)`
    flex-direction: row;
    flex: 1;
`
const InfoLibelle = styled(Text)`
    margin-left: 10px;
    margin-bottom: 10px;
    ${props => props.theme.fonts.accountInformationLibelle};
`
const InfoValue = styled(Text)`
    ${props => props.theme.fonts.accountInformationValue};
`
const InfoContainer = styled(View)`
    flex: 1;
    margin-top: 10px;
    margin-bottom: 10px;
`
const TextContainer = styled(View)`
    elevation: 2px;
    box-shadow: 1px 1px 1px ${props => props.theme.colors.homeIndicatorBowShadowColor};

    height: ${props => `${props.theme.constants.accountTextContainer}px;`};
    background-color: ${props => props.theme.colors.accountTextContainerBackground};
    border-radius: 3px;
    justify-content: center;

    padding-left: 10px;
    padding-right: 10px;
    margin-left: 10px;
    margin-right: 10px;
`
const SynchroInfo = styled(View)`
    flex: 1;
    background-color: ${props => props.theme.colors.accountTextContainerBackground};

    border-radius: 3px;
    elevation: 2px;
    box-shadow: 1px 1px 1px ${props => props.theme.colors.homeIndicatorBowShadowColor};

    padding: 10px 8px;
    margin: 5px;
    margin-bottom: 0;
`
const SynchroLine = styled(View)`
    flex: 1;
    flex-direction: row;
    height: 18px;
    align-items: center;
    justify-content: space-between;
`
const SynchroIcon = styled(View)``
const SynchroInfoText = styled(Text)<{ boldInfo?: boolean; titleInfo?: boolean }>`
    ${props => props.theme.fonts.accountInformationValue};
    ${props => (props.titleInfo ? 'font-size: 15px;text-align: center;' : 'font-size: 13px;')}
    ${props => props.boldInfo && 'font-weight: bold;'}
`
