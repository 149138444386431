import * as React from 'react'

// Components
import RootScreen from '@components/rootScreen/RootScreen'
import PpeePdfDisplay from './ppeePdfDisplay'
import PpeeModal from './ppeePdfModal'

// Utils
import useParams from '@layout/useParams'
import Logger from '@utils/logger'

// Store
import useI18n from '@store/i18n/useI18n'

// Api
import api from '@app/api/api'
import useNavigation from '@app/ui/layout/useNavigation'

export default () => {
    const { ppeeId } = useParams<{ ppeeId: string }>()
    const i18n = useI18n()

    const [status, setStatus] = React.useState<Status>('loading')
    const [ppee, setPpee] = React.useState<Icp | undefined>(undefined)
    const [modalMode, setModalMode] = React.useState<PpeeModalMode | undefined>(undefined)
    const [documents, setDocuments] = React.useState<DocumentAprr[]>([])
    const [redacteurs, setRedacteurs] = React.useState<UserRedacteur[]>([])
    const [buttons, setButtons] = React.useState<ButtonsPPEE>({
        documents: [],
        info: [],
        visit: [],
        forms: [],
        amendments: [],
    })

    const refreshPpee = () => {
        setStatus('loading')
        api.icp
            .getIcp(ppeeId)
            .then(updatePpee)
            .catch(err => {
                Logger.error(err)
                setStatus('error')
            })
    }

    const updatePpee = React.useMemo(
        () => (updatedPpee: Icp) => {
            setStatus('loading')
            setPpee(updatedPpee)
            setStatus('fetched')
        },
        [],
    )

    React.useEffect(() => {
        Promise.all([
            api.modele.getAllModeles(),
            api.icp.getIcp(ppeeId),
            api.document.getAllDocuments(),
            api.users.getRedacteurList(),
        ])
            .then(([modeles, fetchedPpee, docs, redacs]) => {
                if (!fetchedPpee.pdfUrl) {
                    useNavigation().push('/icp/:icpId', {
                        icpId: fetchedPpee.id,
                        pathParams: { icpId: fetchedPpee.id },
                    })
                } else {
                    setButtons({
                        documents: [
                            {
                                label: i18n.t('screens.ppeePdfScreen.buttons.addDocument'),
                                onPress: () => setModalMode('addDocument'),
                                icon: 'icpToApprove',
                            },
                        ],
                        info: [
                            {
                                label: i18n.t('screens.ppeePdfScreen.buttons.editInfo'),
                                onPress: () => setModalMode('editInfo'),
                                icon: 'edit',
                            },
                        ],
                        visit: [
                            {
                                label: i18n.t('screens.ppeePdfScreen.buttons.visitForm'),
                                onPress: (nav: Navigation) =>
                                    nav.push('/ppee/:ppeeId/visitForm', {
                                        ppee: fetchedPpee,
                                        formTitle: i18n.t('screens.ppeePdfScreen.formTitle', {
                                            reference: fetchedPpee.reference,
                                            modeleTitle: i18n.t('screens.formsAdminScreen.visitTitle'),
                                            date: new Date(),
                                        }),
                                        pathParams: { ppeeId: fetchedPpee.id },
                                    }),
                                icon: 'forms',
                            },
                        ],
                        forms: [
                            //Formulaire par défaut à garder en commentaire car peut être utile plus tard
                            /*
                            ...modeles
                                .filter(modele => modele.defaut)
                                .map(
                                    mod =>
                                        ({
                                            label: mod.titre,
                                            onPress: (nav: Navigation) => {
                                                nav.push('/ppee/:ppeeId/ajouterFormulaire/:modeleId', {
                                                    ppeeId: fetchedPpee.id,
                                                    modeleId: mod.id,
                                                    formTitle: i18n.t('screens.ppeePdfScreen.formTitle', {
                                                        reference: fetchedPpee.reference,
                                                        modeleTitle: mod.titre,
                                                        date: new Date(),
                                                    }),
                                                    pathParams: {
                                                        ppeeId: fetchedPpee.id,
                                                        modeleId: mod.id,
                                                    },
                                                })
                                            },
                                            icon: 'forms',
                                        } as ButtonPPEE),
                                ),
*/
                            {
                                label: i18n.t('screens.ppeePdfScreen.buttons.addForm'),
                                onPress: nav =>
                                    nav.push('/ppee/:ppeeId/ajouterFormulaire', {
                                        ppeeId: fetchedPpee.id,
                                        pathParams: { ppeeId: fetchedPpee.id },
                                    }),
                                icon: 'forms',
                            },
                        ],
                        amendments: [
                            {
                                label: i18n.t('screens.ppeePdfScreen.buttons.addAmendment'),
                                onPress: () => setModalMode('amendmentReasons'),

                                icon: 'ppeeActifs',
                            },
                        ],
                    })
                    updatePpee(fetchedPpee)

                    setDocuments(docs.sort((a, b) => a.index - b.index))

                    setRedacteurs(redacs.data)

                    setStatus('fetched')
                }
            })
            .catch(err => {
                Logger.error(err)
                setStatus('error')
            })
    }, [i18n, ppeeId, updatePpee])

    return (
        <React.Fragment>
            {ppee && (
                <PpeeModal
                    buttons={buttons}
                    modalMode={modalMode}
                    onRequestClose={() => setModalMode(undefined)}
                    ppee={ppee}
                    documents={documents}
                    redacteurs={redacteurs}
                    refreshPpee={refreshPpee}
                    updatePpee={updatePpee}
                    setStatus={setStatus}
                />
            )}
            <RootScreen status={status} scrollable={false}>
                {!!ppee && <PpeePdfDisplay buttons={buttons} ppee={ppee} refreshPpee={refreshPpee} />}
            </RootScreen>
        </React.Fragment>
    )
}
