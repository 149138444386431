import * as React from 'react'

// Components
import { View } from 'react-native'
import Icon from '@components/icon/Icon'
import { TouchableItemFeedback } from '@components/touchable/Touchable'

// Style
import styled from '@styles/index'

interface Props {
    pdfLink: string
}

export default ({ pdfLink }: Props) => {
    return (
        <TouchableItemFeedback onPress={() => window.open(pdfLink, '_blank')}>
            <InfoIcon>
                <Icon name="eye" size={20} />
            </InfoIcon>
        </TouchableItemFeedback>
    )
}

const InfoIcon = styled(View)`
    color: ${props => props.theme.colors.infoIcon};
    margin-right: 25px;
    cursor: pointer;
`
