import * as React from 'react'

// Components
import { View, Platform } from 'react-native'
import RootScreen from '@components/rootScreen/RootScreen'
import { Formik } from 'formik'
import TextInput from '@components/textInput/TextInput'
import Button from '@components/button/Button'
import { basicAlert, confirmAlert } from '@components/alert/Alerts'
import AnswerRubriqueSection from '@components/answerRubriqueSection/answerRubriqueSection'
import useParams from '@layout/useParams'
import FloatButton from '@components/floatButton/FloatButton'
// Store
import useI18n from '@store/i18n/useI18n'

// Utils
import Logger from '@utils/logger'
import { produce } from 'immer'
import useNavigation from '@layout/useNavigation'
import { validateForm } from '@utils/azure/validationUtils'

// Api
import api from '@api/api'

// Style
import styled from '@styles/index'
import useTheme from '@styles/useTheme'

const i18nScreenKey = 'screens.editForm'

const adaptModeleToAddedModele = (modele: Modele): AddedModele => ({
    ...modele,
    json: JSON.parse(modele.json),
})

const adaptFormulaireToAddedFormulaire = (form: Formulaire): AddedFormulaire => ({
    titre: form.titre,
    json: JSON.parse(form.json),
    modeleId: form.modeleId,
})

const createEmptyFormFromModele = (modele: AddedModele, modeleId: string, formTitle?: string): AddedFormulaire => ({
    titre: formTitle || '',
    modeleId: modeleId,
    json: modele.json.map(rubrique => ({
        rubriqueName: rubrique.nom,
        questions: rubrique.questions.map(question => ({ questionTitle: question.title, answer: [] })),
    })),
})

const trimAllValues = (values: AddedFormulaire): AddedFormulaire =>
    produce(values, newValues => {
        newValues.titre = values.titre.trim()
        values.json.forEach((rubrique, i) => {
            rubrique.questions.forEach((question, j) => {
                newValues.json[i].questions[j].questionTitle = question.questionTitle.trim()
                question.answer.forEach((answer, k) => (newValues.json[i].questions[j].answer[k] = answer.trim()))
            })
        })
    })

interface Props {
    formTitle: string
    action?: 'add' | 'read' | 'edit'
}

export default ({ action = 'read', formTitle }: Props) => {
    const { formId, modeleId, ppeeId } = useParams<{
        formId?: string
        modeleId?: string
        ppeeId?: string
    }>()

    const i18n = useI18n()
    const navigation = useNavigation()

    const [Theme] = useTheme()

    const [modele, setModele] = React.useState<AddedModele | undefined>(undefined)
    const [initialValues, setInitialValues] = React.useState<AddedFormulaire | undefined>(undefined)
    const [status, setStatus] = React.useState<Status>('loading')

    const fetchAndSetModele = (modId: string, fromForm: boolean, formTitle: string) =>
        api.modele
            .getModele(modId)
            .then(modele => {
                const adaptedModele = adaptModeleToAddedModele(modele)
                setModele(adaptedModele)
                if (!fromForm) {
                    setInitialValues(createEmptyFormFromModele(adaptedModele, modId, formTitle))
                }
                setStatus('fetched')
            })
            .catch(err => {
                Logger.error(err)
                setStatus('error')
            })

    React.useEffect(() => {
        // TODO : vérifier que ppeeId correspond bien à un PPEE ?
        if (!!formId) {
            api.formulaire
                .getFormulaire(formId)
                .then(formulaire => {
                    setInitialValues(adaptFormulaireToAddedFormulaire(formulaire))
                    fetchAndSetModele(formulaire.modeleId, true, formTitle)
                })
                .catch(err => {
                    setStatus('error')
                    Logger.error(err)
                })
        } else {
            if (!modeleId) {
                setStatus('error')
                throw new Error('No modele id')
            } else {
                fetchAndSetModele(modeleId, false, formTitle)
            }
        }
    }, [modeleId, formId, formTitle])

    const handleSubmit = (
        values: AddedFormulaire,
        setSubmitting: (isSubmitting: boolean) => void,
        ppeeIdToSubmit: string,
    ) => {
        const valuesToSubmit = trimAllValues(values)
        const actionKey = !!formId ? 'Edit' : 'Add'
        confirmAlert(
            i18n,
            i18nScreenKey,
            actionKey,
            () =>
                (formId
                    ? api.formulaire.editForm(
                          {
                              ...valuesToSubmit,
                              json: JSON.stringify(valuesToSubmit.json),
                          },
                          formId,
                      )
                    : api.ppee.addFormulaire(ppeeIdToSubmit, {
                          ...valuesToSubmit,
                          json: JSON.stringify(valuesToSubmit.json),
                      })
                )
                    .then(() =>
                        basicAlert(
                            i18n,
                            i18nScreenKey,
                            actionKey,
                            () =>
                                Platform.OS === 'web'
                                    ? navigation.push('/ppee/:ppeeId', { pathParams: { ppeeId: ppeeId } })
                                    : navigation.pushFromRoot('/ppee/:ppeeId', { ppeeId: ppeeId }),
                            'success',
                            Theme,
                        ),
                    )
                    .catch(err => {
                        basicAlert(i18n, i18nScreenKey, actionKey, () => setSubmitting(false), 'error', Theme)
                        Logger.error(err)
                    }),
            () => setSubmitting(false),
            Theme,
        )
    }

    const updatedRubriques = (
        values: RubriqueForm[],
        indexRubrique: number,
        indexQuestion: number,
        newAnswers: any[],
    ) =>
        produce(values, newValues => {
            newValues[indexRubrique].questions[indexQuestion].answer = newAnswers
        })

    return (
        <RootScreen status={status} scrollable>
            {!!modele && !!ppeeId && (
                <Formik
                    initialValues={initialValues as AddedFormulaire}
                    onSubmit={(values, { setSubmitting }) => handleSubmit(values, setSubmitting, ppeeId)}
                    validate={values => validateForm(values, modele.json, i18n)}
                    isInitialValid={!!formId}
                >
                    {({ errors, values, handleSubmit, isValid, setFieldValue, isSubmitting }) => {
                        return (
                            <React.Fragment>
                                <View>
                                    <TextInput
                                        label={i18n.t('screens.editForm.titles.titre')}
                                        placeholder={i18n.t('screens.editForm.placeholders.titre')}
                                        value={values.titre}
                                        onTextChange={text => setFieldValue('titre', text)}
                                        error={errors.titre}
                                        required={true}
                                        disabled={action === 'read'}
                                    />
                                    {modele.json.map((rubrique, index) => (
                                        <AnswerRubriqueSection
                                            key={`rubrique${index}`}
                                            rubrique={rubrique}
                                            value={values.json[index]}
                                            errors={errors.json ? errors.json[index] : undefined}
                                            onRubriqueChange={(indexQuestion, newAnswers) =>
                                                setFieldValue(
                                                    'json',
                                                    updatedRubriques(values.json, index, indexQuestion, newAnswers),
                                                )
                                            }
                                            index={index}
                                            disabled={action === 'read'}
                                        />
                                    ))}
                                    {Platform.OS === 'web' && action !== 'read' && (
                                        <ButtonsContainer zIndex={-modele.json.length}>
                                            <Button
                                                onPress={handleSubmit}
                                                libelle={i18n.t(`screens.editForm.buttons.${formId ? 'edit' : 'add'}`)}
                                                width={Theme.constants.editModeleButtonWidth}
                                                status={!isValid ? 'disabled' : isSubmitting ? 'loading' : 'active'}
                                            />
                                        </ButtonsContainer>
                                    )}
                                </View>
                                {Platform.OS !== 'web' && action !== 'read' && status === 'fetched' && (
                                    <FloatButton iconName="save" onPress={handleSubmit} />
                                )}
                            </React.Fragment>
                        )
                    }}
                </Formik>
            )}
        </RootScreen>
    )
}

const ButtonsContainer = styled(View)<{ zIndex?: number }>`
    justify-content: space-around;
    align-items: center;
    flex-direction: row;
    margin-top: 48px;
    ${props => props.zIndex && `z-index: ${props.zIndex};`}
`
