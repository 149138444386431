import * as React from 'react'

// Components
import { View, Text } from 'react-native'
import FieldInformation from '@components/fieldInformation/FieldInformation'
import { TouchableItemFeedback } from '@components/touchable/Touchable'
import Icon from '@components/icon/Icon'
import AgencyModal from './SelectIcpAgencyModal'
import Button from '@components/button/Button'

// Utils
import { isAgencyIncomplete } from '@utils/icpUtils'

// Store
import useI18n from '@store/i18n/useI18n'

// Style
import styled from '@styles/index'
import useTheme from '@styles/useTheme'

interface Props {
    values: IcpAgency[]
    required?: boolean
    label?: string
    error?: any
    onChange: (contacts: IcpAgency[]) => void
    globalInfos?: InformationsIcp
}

export default (props: Props) => {
    const { values, required, onChange, label, error, globalInfos } = props
    const i18n = useI18n()
    const [Theme] = useTheme()

    const [modalVisible, setModalVisible] = React.useState<boolean>(false)
    const [itemOpened, setItemOpened] = React.useState<number | undefined>(undefined)
    const [itemToEdit, setItemToEdit] = React.useState<IcpAgency | undefined>(undefined)

    const renderField = (itemKey: string, value?: string) => (
        <SimpleRow key={itemKey}>
            <Label>{`${i18n.t(`components.addIcpAgencies.fields.${itemKey}`)} :`}</Label>
            <Value>{value || i18n.t('components.addIcpAgencies.noValue')}</Value>
        </SimpleRow>
    )

    return (
        <View>
            {modalVisible && (
                <AgencyModal
                    initialValues={itemToEdit}
                    modalVisible={modalVisible}
                    onRequestClose={() => {
                        setModalVisible(false)
                        setItemToEdit(undefined)
                    }}
                    onChange={onChange}
                    agencies={values}
                    globalInfos={globalInfos}
                />
            )}
            <TopContainer>
                <Flexbox>
                    <FieldInformation label={label} required={required} error={error} doubleLined />
                </Flexbox>
                <TouchableItemFeedback onPress={() => setModalVisible(true)}>
                    <Action>{i18n.t('components.addIcpAgencies.addAgency')}</Action>
                </TouchableItemFeedback>
            </TopContainer>

            <Container error={!!error}>
                <RowContainer>
                    <Flexbox>
                        <Legend>{i18n.t('components.addIcpAgencies.name')}</Legend>
                    </Flexbox>
                    <Flexbox>
                        <Legend>{i18n.t('components.addIcpAgencies.worksNature')}</Legend>
                    </Flexbox>
                </RowContainer>
                <AgencyList>
                    {values.length === 0 ? (
                        <NoAgency>{i18n.t('components.addIcpAgencies.noAgency')}</NoAgency>
                    ) : (
                        values.map((agency, index) => (
                            <TouchableItemFeedback
                                key={`agency${index}`}
                                onPress={() => setItemOpened(itemOpened === index ? undefined : index)}
                            >
                                <AgencyContainer odd={index % 2 === 1}>
                                    <RowContainer height={50}>
                                        <Flexbox>
                                            <AgencyName ellipsizeMode={'tail'} numberOfLines={2}>
                                                {isAgencyIncomplete(agency) && (
                                                    <AgencyNameWarning>
                                                        {i18n.t('components.addIcpAgencies.incomplete')}
                                                    </AgencyNameWarning>
                                                )}
                                                {agency.companyName !== agency.name
                                                    ? `${agency.companyName} - ${agency.name}`
                                                    : agency.name}
                                            </AgencyName>
                                        </Flexbox>
                                        <Flexbox>
                                            <AlignedRow>
                                                <Flexbox>
                                                    <AgencyWorksNature ellipsizeMode={'tail'} numberOfLines={2}>
                                                        {agency.worksNature}
                                                    </AgencyWorksNature>
                                                </Flexbox>
                                                <Icon
                                                    name={'information'}
                                                    size={Theme.constants.riskFamilyInformationIconSize}
                                                    color={
                                                        itemOpened === index
                                                            ? Theme.colors.riskFamilyItemInformationSelectedIcon
                                                            : Theme.colors.riskFamilyItemInformationIcon
                                                    }
                                                />
                                            </AlignedRow>
                                        </Flexbox>
                                    </RowContainer>
                                    {itemOpened === index && (
                                        <AgencyInformations>
                                            <TitleRowContainer>
                                                <Button
                                                    libelle={i18n.t('components.addIcpContacts.edit')}
                                                    onPress={() => {
                                                        setItemToEdit(agency)
                                                        setModalVisible(true)
                                                    }}
                                                    status="active"
                                                />
                                            </TitleRowContainer>
                                            <SectionTitle>
                                                {i18n.t('components.addIcpAgencies.modalTitles.coordinates')}
                                            </SectionTitle>
                                            <Section>
                                                {([
                                                    'address',
                                                    'telephone',
                                                    'email',
                                                    'siteManagerName',
                                                    'siteManagerMobile',
                                                ] as IcpAgencyStringFieldName[]).map(key =>
                                                    renderField(key, agency[key]),
                                                )}
                                            </Section>
                                            <SectionTitle>
                                                {i18n.t('components.addIcpAgencies.modalTitles.works')}
                                            </SectionTitle>
                                            <Section>
                                                {(['worksNature', 'tools'] as IcpAgencyStringFieldName[]).map(key =>
                                                    renderField(key, agency[key]),
                                                )}
                                                {renderField(
                                                    'maxStaff',
                                                    agency.maxStaff ? agency.maxStaff.toString() : undefined,
                                                )}
                                            </Section>
                                            <SectionTitle>
                                                {i18n.t('components.addIcpAgencies.modalTitles.dates')}
                                            </SectionTitle>
                                            <Section>
                                                {renderField(
                                                    'startDate',
                                                    !!agency.startDate
                                                        ? i18n.t('components.datePicker.date', {
                                                              date: new Date(agency.startDate),
                                                          })
                                                        : i18n.t('components.datePicker.noDate'),
                                                )}
                                                {renderField(
                                                    'endDate',
                                                    !!agency.endDate
                                                        ? i18n.t('components.datePicker.date', {
                                                              date: new Date(agency.endDate),
                                                          })
                                                        : i18n.t('components.datePicker.noDate'),
                                                )}
                                            </Section>
                                            <SectionTitle>
                                                {i18n.t('components.addIcpAgencies.fields.comment')}
                                            </SectionTitle>
                                            <Section>
                                                <Value>
                                                    {agency.comment || i18n.t('components.addIcpAgencies.noValue')}
                                                </Value>
                                            </Section>
                                            <RowContainer>
                                                <TouchableItemFeedback
                                                    onPress={() => {
                                                        onChange(values.filter((c, i) => i !== index))
                                                        setItemOpened(undefined)
                                                    }}
                                                >
                                                    <Action>{i18n.t('components.addIcpContacts.delete')}</Action>
                                                </TouchableItemFeedback>
                                            </RowContainer>
                                        </AgencyInformations>
                                    )}
                                </AgencyContainer>
                            </TouchableItemFeedback>
                        ))
                    )}
                </AgencyList>
            </Container>
        </View>
    )
}

const AgencyContainer = styled(View)<{ odd?: boolean }>`
    background-color: ${props => !props.odd && props.theme.colors.evenLines};
`
const Container = styled(View)<{ error?: boolean }>`
    background-color: ${props => props.theme.colors.addIcpContactsBackground};
    padding-right: 8px;
    padding-left: 8px;
    ${props => props.error && 'border: ' + props.theme.colors.textInputBorderError + ' solid 1px;'}
`
const TitleRowContainer = styled(View)<{ height?: number }>`
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding-right: ${props => props.theme.constants.fieldPadding};
    padding-left: ${props => props.theme.constants.fieldPadding};
    min-height: ${props => props.height || 36}px;
`
const RowContainer = styled(View)<{ height?: number }>`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: ${props => props.theme.constants.fieldPadding};
    padding-left: ${props => props.theme.constants.fieldPadding};
    min-height: ${props => props.height || 36}px;
`
const Legend = styled(Text)`
    ${props => props.theme.fonts.rightLegend}
    text-transform: uppercase;
`
const Action = styled(Text)`
    ${props => props.theme.fonts.homeActionLink}
    text-decoration: underline;
    text-decoration-color: ${props => props.theme.fonts.homeActionLink.color};
`
const AgencyList = styled(View)`
    padding-bottom: 8px;
`
const NoAgency = styled(Text)`
    ${props => props.theme.fonts.perimeterName}
    text-align: center;
    padding-top: 24px;
    padding-bottom: 24px;
`
const AgencyName = styled(Text)`
    ${props => props.theme.fonts.icpContactName}
`
const AgencyNameWarning = styled(Text)`
    color: ${props => props.theme.colors.agencyWarning};
    font-style: italic;
`
const AgencyWorksNature = styled(Text)`
    ${props => props.theme.fonts.icpAgencyWorksNature}
`
const Flexbox = styled(View)`
    flex: 1;
`
const TopContainer = styled(View)`
    flex-direction: row;
    padding-right: ${props => props.theme.constants.fieldPadding};
`
const AlignedRow = styled(View)`
    flex-direction: row;
    align-items: center;
`
const AgencyInformations = styled(View)`
    padding: 24px;
`
const SectionTitle = styled(Text)`
    margin-bottom: 10px;
    ${props => props.theme.fonts.icpContactName}
`
const Section = styled(View)`
    padding-right: 12px;
    padding-left: 12px;
    margin-bottom: 12px;
`
const SimpleRow = styled(View)`
    flex-direction: row;
`
const Label = styled(Text)`
    flex: 1;
    ${props => props.theme.fonts.icpAgencyLabel}
    margin-bottom: 5px;
    margin-right: 5px;
`
const Value = styled(Text)`
    flex: 2;
    ${props => props.theme.fonts.icpAgencyValue}
    margin-bottom: 5px;
`
