import * as React from 'react'

// Components
import { View, Text } from 'react-native'
import FieldInformation from '@components/fieldInformation/FieldInformation'

// Store
import useI18n from '@store/i18n/useI18n'

// Style
import styled from '@styles/index'

interface Props {
    values: string[]
    label?: string
}

export default (props: Props) => {
    const { values, label } = props

    const i18n = useI18n()

    return (
        <View>
            <FieldInformation label={label} />
            <Container>
                <RowContainer odd={true}>
                    <Legend>{i18n.t('components.addIcpPlaces.place')}</Legend>
                </RowContainer>
                <PlaceList>
                    {values.length === 0 ? (
                        <NoPlace>{i18n.t('components.addIcpPlaces.noPlace')}</NoPlace>
                    ) : (
                        values.map((contact, index) => (
                            <RowContainer key={`contact${index}`} odd={index % 2 === 1}>
                                <PlaceName>{contact}</PlaceName>
                            </RowContainer>
                        ))
                    )}
                </PlaceList>
            </Container>
        </View>
    )
}
const Container = styled(View)`
    background-color: ${props => props.theme.colors.addIcpContactsBackground};
    padding-right: 8px;
    padding-left: 8px;
`
const RowContainer = styled(View)<{ odd?: boolean }>`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-right: ${props => props.theme.constants.fieldPadding};
    padding-left: ${props => props.theme.constants.fieldPadding};
    min-height: 36px;

    background-color: ${props => !props.odd && props.theme.colors.evenLines};
`
const Legend = styled(Text)`
    ${props => props.theme.fonts.rightLegend}
    text-transform: uppercase;
`

const PlaceList = styled(View)`
    padding-bottom: 8px;
`
const NoPlace = styled(Text)`
    ${props => props.theme.fonts.perimeterName}
    text-align: center;
    padding-top: 24px;
    padding-bottom: 24px;
`

const PlaceName = styled(Text)`
    ${props => props.theme.fonts.icpContactName}
`
