interface Subscriber {
    id: string
    cb: MonoCallback<any>
    unsubscribe: () => void
}
const Emitter = (
    name: string,
    initialContent: any,
    accept?: (params: any) => boolean,
    transform?: (params: any) => any,
): TypeEmitter => {
    let subscribers: Subscriber[] = []
    let id = 0
    let lastContent = initialContent
    const subscribe = (cb: MonoCallback<any>) => {
        const eid = 'emitter' + id++
        const subscriber: Subscriber = {
            id: eid,
            cb,
            unsubscribe: () => {
                const newSubscribers = subscribers.filter(s => s && s.id !== eid)
                subscribers = newSubscribers
            },
        }
        subscribers.push(subscriber)
        if (lastContent) {
            subscriber.cb(lastContent)
        }
        return subscriber
    }
    return {
        name,
        subscribe,
        subscribeOnce: (cb: MonoCallback<any>) => {
            if (lastContent) {
                cb(lastContent)
            } else {
                const subscription = subscribe(content => {
                    cb(content)
                    subscription.unsubscribe()
                })
            }
        },
        send: (content: any) => {
            if (accept && !accept(content)) {
                return
            }
            if (transform) {
                content = transform(content)
            }
            lastContent = content
            const sendTo = [...subscribers]
            sendTo.filter(Boolean).forEach(s => s.cb(content))
        },
        last: () => lastContent,
    }
}
export default Emitter
