import * as React from 'react'

// Components
import { View, Platform, Text, TouchableOpacity } from 'react-native'
import TextInput from '@components/textInput/TextInput'
import Picker from '@components/picker/Picker'
import DatePicker from '@components/datePicker/DatePicker'
import { Formik, FormikErrors } from 'formik'
import Container from '@components/icpFormContainer/icpFormContainer'
import AddIcpContacts from '@components/addIcpContacts/AddIcpContacts'
import PlanICPDeclarationWi, { idPrefix } from './PlanICPDeclarationWi'
import PlanICPDeclarationCssct from './PlanICPDeclarationCssct'
import Icon from '@components/icon/Icon'
import { basicAlert } from '@components/alert/Alerts'

// Utils
import { produce } from 'immer'
import { getAllInitialPerimetersFromPerimeterID } from '@utils/perimeterUtils'
import { displayUserName } from '@utils/string'

// Store
import useI18n from '@store/i18n/useI18n'

// Style
import styled from '@styles/index'
import SelectPerimeter from '@components/selectPerimeter/SelectPerimeter'
import useTheme from '@styles/useTheme'
import { InternRepresentativePicker } from '@components/internRepresentativePicker/internRepresentativePicker'
import * as filtersStore from '@store/filters'

interface Props {
    initialValues: Planification
    onValidate?: (isValid: boolean) => void
    globalSetIcpFieldValue: (value: Planification) => void
    updateActors: (value: ActorsIcp) => void
    globalActors: ActorsIcp
    globalInfos: InformationsIcp
    redacteurs: UserRedacteur[]
    approbateurs: UserApprobateur[]
    cssctItems?: ConfigurationEmails
    wiItems?: ConfigurationEmails
    perimeters: Perimeter[]
    icpId?: string
    canSendEmail: boolean
    handleSubmitWithoutAlert: () => Promise<void>
    canEditContacts?: boolean
    isDescriptionSet: boolean
    isSendingEmail?: boolean
}

const emptyUser: UserIcp = { id: '', firstName: '', lastName: '' }
const I18N_ITEM_KEY = 'components.planIcp'

export const trimPlanIcp = (values: Planification): Planification => {
    return {
        ...values,
        place: !!values.place ? values.place.trim() : '',
        comment: !!values.comment ? values.comment.trim() : undefined,
        declaration: {
            ...values.declaration,
            workInspection:
                values.declaration.workInspection &&
                values.declaration.workInspection.map(wi => ({
                    ...wi,
                    id: wi.id && (wi.id.indexOf(idPrefix) > -1 ? undefined : wi.id),
                })),
        },
    }
}

export const validatePlanIcp = (
    values: Planification,
    i18n: I18nState,
    onValidate?: (isValid: boolean) => void,
): FormikErrors<Planification> => {
    const trimedValues = trimPlanIcp(values)
    const errors: FormikErrors<Planification> = {}

    if (!trimedValues.redacteur.id) {
        errors.redacteur = { id: i18n.t('screens.addUserAdminScreen.required') }
    }
    if (!trimedValues.approbateur || !trimedValues.approbateur.id) {
        errors.approbateur = i18n.t('screens.addUserAdminScreen.required')
    }
    if (!trimedValues.date) {
        errors.date = i18n.t('screens.addUserAdminScreen.required')
    }
    if (!trimedValues.place) {
        errors.place = i18n.t('screens.addUserAdminScreen.required')
    }
    if (!!trimedValues.emailsSent && !trimedValues.emailsSent?.toCssct) {
        errors.emailsSent = i18n.t('components.planIcp.errors.oneEmailCssct')
    }
    if (!!trimedValues.emailsSent && !trimedValues.emailsSent.toOnecontact) {
        errors.contacts = i18n.t('components.planIcp.errors.oneEmailContact')
    }
    if (trimedValues.contacts.length === 0) {
        errors.contacts = i18n.t('screens.addUserAdminScreen.required')
    }
    if (!!trimedValues.declaration.cssct && trimedValues.declaration.cssct.byEmail) {
        if (!trimedValues.declaration.cssct.email) {
            errors.declaration = {
                ...errors.declaration,
                cssct: i18n.t('screens.addUserAdminScreen.required'),
            }
        }
    }

    if (!!onValidate) {
        onValidate(Object.keys(errors).length === 0)
    }

    return errors
}

export default (props: Props) => {
    const {
        initialValues,
        onValidate,
        globalSetIcpFieldValue,
        redacteurs,
        approbateurs,
        cssctItems,
        wiItems,
        perimeters,
        icpId,
        canSendEmail,
        handleSubmitWithoutAlert,
        canEditContacts,
        globalActors,
        globalInfos,
        updateActors,
        isDescriptionSet,
    } = props
    const i18n = useI18n()
    const [Theme] = useTheme()

    const [alreadySending, setAlreadySending] = React.useState<boolean>(false)

    const [isSendingEmail, setIsSendingEmail] = React.useState<boolean>(false)

    const initialFunctions = React.useMemo(
        () => [
            filtersStore.actions.setDirection,
            filtersStore.actions.setStructure,
            filtersStore.actions.setPerimeter,
            filtersStore.actions.setSector,
        ],
        [],
    )

    React.useEffect(() => {
        if (initialValues.perimeterId) {
            const initialPerimeters =
                initialValues.perimeterId &&
                getAllInitialPerimetersFromPerimeterID(initialValues.perimeterId, perimeters)
            if (initialPerimeters) {
                initialPerimeters.forEach(
                    (initPerimeter, index) => initialFunctions[index] && initialFunctions[index](initPerimeter),
                )
            }
        }
    }, [initialValues, perimeters, initialFunctions])

    //Met à jour le statut du contact après avoir envoyé les invitations, avant une sauvegarde
    const updateLocalStatus = (contact: IcpContact) => {
        if (contact.state === 'notSent') {
            return {
                ...contact,
                state: 'waiting',
            }
        }
        return contact
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={() => undefined} // On passe par le handleSubmit global sans validation
        >
            {({ values, setFieldValue, isValid }) => {
                const errors = validatePlanIcp(values, i18n, onValidate)
                const globalSetFieldValue = (key: keyof Planification, value: any) => {
                    globalSetIcpFieldValue(
                        produce(values, newValues => {
                            newValues[key] = value
                        }),
                    )
                    setFieldValue(key, value)
                }

                const globalSetFieldValueOnCondition = (key: keyof Planification, value: any) => {
                    if (isDescriptionSet && !!values.approbateur && values.place.length !== 0) {
                        globalSetFieldValue(key, value)
                    } else {
                        if (!values.declaration.wiOpened) {
                            basicAlert(i18n, I18N_ITEM_KEY, 'MissingInformationWi', () => null, 'error', Theme)
                        } else {
                            globalSetFieldValue(key, {
                                ...value,
                                declaration: { ...value.declaration, wiOpened: false },
                            })
                        }
                    }
                }

                const displayingCollaborateurs = React.useMemo(
                    () => redacteurs.filter(r => values.contributeurs.indexOf(r) === -1),
                    [values],
                )

                return (
                    <Container title={i18n.t('components.planIcp.title')} hasErrors={!isValid}>
                        <View>
                            <Line zIndex={0}>
                                <Picker
                                    value={values.redacteur.id ? displayUserName(values.redacteur) : undefined}
                                    data={redacteurs.map(displayUserName)}
                                    onChange={index =>
                                        globalSetFieldValue('redacteur', index > -1 ? redacteurs[index] : emptyUser)
                                    }
                                    placeholder={i18n.t('components.planIcp.placeholders.redacteur')}
                                    label={i18n.t('components.planIcp.labels.redacteur')}
                                    required={true}
                                    error={errors && errors.redacteur && errors.redacteur.id}
                                    isClearable={true}
                                />
                            </Line>
                            <Line zIndex={-1} noMargin={values.contributeurs.length !== 0}>
                                <InternRepresentativePicker
                                    key={values.contributeurs.length}
                                    collaborateursToDisplay={displayingCollaborateurs}
                                    onChange={index => {
                                        const contribs = [
                                            ...values.contributeurs,
                                            displayingCollaborateurs[index],
                                        ].filter(Boolean)

                                        globalSetFieldValue('contributeurs', contribs)
                                    }}
                                />
                            </Line>
                            {values.contributeurs.length !== 0 && (
                                <CollaborateurListContainer>
                                    {values.contributeurs.map((c, i) => (
                                        <CollaborateurContainer key={i}>
                                            <CollabotateurLibelle>{displayUserName(c)}</CollabotateurLibelle>
                                            <TouchableOpacity
                                                onPress={() =>
                                                    globalSetFieldValue(
                                                        'contributeurs',
                                                        values.contributeurs.filter(
                                                            contributeur => c.id !== contributeur.id,
                                                        ),
                                                    )
                                                }
                                            >
                                                <Icon name="cross" size={10} color={Theme.colors.documentChevron} />
                                            </TouchableOpacity>
                                        </CollaborateurContainer>
                                    ))}
                                </CollaborateurListContainer>
                            )}
                            <Line zIndex={-2}>
                                <Picker
                                    value={
                                        values.approbateur && values.approbateur.id
                                            ? displayUserName(values.approbateur)
                                            : undefined
                                    }
                                    data={approbateurs.map(displayUserName)}
                                    onChange={index =>
                                        globalSetFieldValue('approbateur', index > -1 ? approbateurs[index] : undefined)
                                    }
                                    required={true}
                                    placeholder={i18n.t('components.planIcp.placeholders.approbateur')}
                                    label={i18n.t('components.planIcp.labels.approbateur')}
                                    isClearable={true}
                                    error={errors && errors.approbateur}
                                />
                            </Line>

                            <Section zIndex={-3}>
                                <SectionTitle>{i18n.t('components.planIcp.sectionsTitles.perimeter')}</SectionTitle>
                                <RowLine zIndex={0} perimeter>
                                    <SelectPerimeter
                                        setSelectedPerimetersIds={ids =>
                                            globalSetFieldValue('perimeterId', ids[0] || undefined)
                                        }
                                        status={!!perimeters ? 'fetched' : 'error'}
                                        perimeters={perimeters}
                                        selectedPerimeterOnly
                                    />
                                </RowLine>
                            </Section>

                            <Section zIndex={-4}>
                                <SectionTitle>{i18n.t('components.planIcp.sectionsTitles.planMeeting')}</SectionTitle>
                                <RowLine zIndex={0}>
                                    <InputContainer isLeft={true}>
                                        <DatePicker
                                            label={i18n.t('components.planIcp.labels.date')}
                                            value={values.date ? new Date(values.date) : undefined}
                                            onChange={date => globalSetFieldValue('date', date)}
                                            time={true}
                                            required={true}
                                            error={!!errors && errors.date}
                                            minDate={new Date()}
                                        />
                                    </InputContainer>
                                    <InputContainer>
                                        <TextInput
                                            label={i18n.t('components.planIcp.labels.place')}
                                            value={values.place}
                                            onTextChange={text => globalSetFieldValue('place', text)}
                                            placeholder={i18n.t('components.planIcp.placeholders.place')}
                                            required={true}
                                            error={!!errors && errors.place}
                                        />
                                    </InputContainer>
                                </RowLine>
                                <Section zIndex={-5}>
                                    <SectionTitle>{i18n.t('components.planIcp.sectionsTitles.extraInfo')}</SectionTitle>
                                    <Line zIndex={0}>
                                        <TextInput
                                            value={values.comment}
                                            label={i18n.t('components.planIcp.labels.comment')}
                                            onTextChange={text => globalSetFieldValue('comment', text)}
                                            multiline={true}
                                            numberOfLines={4}
                                            placeholder={i18n.t('components.planIcp.placeholders.comment')}
                                        />
                                    </Line>
                                </Section>
                                <Line zIndex={-6}>
                                    <AddIcpContacts
                                        label={i18n.t('components.planIcp.labels.contacts')}
                                        required={true}
                                        values={values.contacts}
                                        onChange={contacts => {
                                            globalSetFieldValue('contacts', contacts)
                                        }}
                                        error={!!errors && errors.contacts}
                                        icpId={icpId}
                                        canEditContacts={canEditContacts}
                                        handleSubmitWithoutAlert={handleSubmitWithoutAlert}
                                        globalActors={globalActors}
                                        globalInfos={globalInfos}
                                        updateActors={updateActors}
                                        readyToSendInvitation={!!values.date && !!values.place && !!values.place.trim()}
                                        setEmailSent={() => {
                                            globalSetFieldValue('emailsSent', {
                                                ...values.emailsSent,
                                                toOnecontact: true,
                                            })
                                            globalSetFieldValue('contacts', values.contacts.map(updateLocalStatus))
                                        }}
                                        setIsSendingEmail={setIsSendingEmail}
                                        isSendingEmail={isSendingEmail}
                                    />
                                </Line>
                                <Line zIndex={-7}>
                                    <PlanICPDeclarationCssct
                                        values={values.declaration}
                                        onItemChange={value => globalSetFieldValue('declaration', value)}
                                        error={(!!errors && !!errors.declaration && errors.declaration.cssct) || ''}
                                        cssctItems={cssctItems}
                                        icpId={icpId}
                                        canSendEmail={canSendEmail}
                                        handleSubmitWithoutAlert={handleSubmitWithoutAlert}
                                        alreadySending={alreadySending}
                                        setAlreadySending={setAlreadySending}
                                        setEmailSent={() => {
                                            globalSetFieldValue('emailsSent', { ...values.emailsSent, toCssct: true })
                                            globalSetFieldValue('declaration', {
                                                ...values.declaration,
                                                cssct: {
                                                    ...values.declaration.cssct,
                                                    hasBeenSent: true,
                                                    hasBeenSentByEmail: true,
                                                },
                                            })
                                        }}
                                        setIsSendingEmail={setIsSendingEmail}
                                        isSendingEmail={isSendingEmail}
                                    />
                                </Line>
                            </Section>

                            <Section zIndex={-8}>
                                <SectionTitle>{i18n.t('components.planIcp.sectionsTitles.declaration')}</SectionTitle>
                                <PlanICPDeclarationWi
                                    values={values.declaration}
                                    onItemChange={value => globalSetFieldValueOnCondition('declaration', value)}
                                    wiItems={wiItems}
                                    icpId={icpId}
                                    canSendEmail={canSendEmail}
                                    handleSubmitWithoutAlert={handleSubmitWithoutAlert}
                                    alreadySending={alreadySending}
                                    setAlreadySending={setAlreadySending}
                                />
                            </Section>
                        </View>
                    </Container>
                )
            }}
        </Formik>
    )
}
const SectionTitle = styled(Text)`
    ${props => props.theme.fonts.icpFormTitle}
    margin-bottom: 20px;
`
const RowLine = styled(View)<{ zIndex: number; perimeter?: boolean }>`
    flex-direction: ${props => (Platform.OS === 'web' && !props.perimeter ? 'row' : 'column')};
    ${props => Platform.OS === 'web' && !props.perimeter && 'align-items: center;'}
    z-index: ${props => props.zIndex};
    margin-bottom: ${Platform.OS === 'web' ? '24px' : '15px'};
`
const Line = styled(View)<{ zIndex: number; noMargin?: boolean }>`
    z-index: ${props => props.zIndex};
    ${props => (props.noMargin ? `margin-bottom: 10px;` : `margin-bottom: ${Platform.OS === 'web' ? '24px' : '15px'};`)}
`
const InputContainer = styled(View)<{ isLeft?: boolean }>`
    ${Platform.OS === 'web' && 'flex: 1;'}
    ${props => props.isLeft && Platform.OS === 'web' && `margin-right: 40px`};
    margin-bottom: ${Platform.OS === 'web' ? '24px' : '15px'};
`
const Section = styled(View)<{ zIndex: number }>`
    z-index: ${props => props.zIndex};
`
const CollaborateurListContainer = styled(View)`
    z-index: -2;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: ${Platform.OS === 'web' ? '24px' : '15px'};
`
const CollaborateurContainer = styled(View)`
    border: 1px solid ${props => props.theme.colors.collaborateurSelectedBorder};
    background-color: ${props => props.theme.colors.collaborateurBackgroundColor};
    margin: 2px;
    padding: 3px 15px;
    border-radius: 25px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`
const CollabotateurLibelle = styled(Text)`
    ${props => props.theme.fonts.collaborateurSelected}
    margin-right: 5px;
`
