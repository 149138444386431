import * as React from 'react'
import { TouchableOpacity, TouchableWithoutFeedback, TouchableHighlight } from 'react-native'
import useTheme from '@styles/useTheme'

interface PropsWithoutFeedback {
    children?: React.ReactNode
    onPress: () => void
    style?: any
    disabled?: boolean
}
interface Props extends PropsWithoutFeedback {
    feedback?: 'none' | 'item' | 'list' | 'menu'
}

const Touchable = ({ feedback = 'item', disabled = false, onPress, style, children }: Props) => {
    const [Theme] = useTheme()

    if (feedback === 'none') {
        return (
            <TouchableWithoutFeedback disabled={disabled} style={style} onPress={onPress}>
                <React.Fragment>{children}</React.Fragment>
            </TouchableWithoutFeedback>
        )
    }
    if (feedback === 'item') {
        return (
            <TouchableOpacity disabled={disabled} style={style} activeOpacity={0.7} onPress={onPress}>
                <React.Fragment>{children}</React.Fragment>
            </TouchableOpacity>
        )
    }
    if (feedback === 'menu') {
        return (
            <TouchableHighlight disabled={disabled} underlayColor={'#0009'} style={style} onPress={onPress}>
                <React.Fragment>{children}</React.Fragment>
            </TouchableHighlight>
        )
    }

    return (
        <TouchableHighlight
            disabled={disabled}
            underlayColor={Theme.colors.backgroundTouchable}
            style={style}
            onPress={onPress}
        >
            <React.Fragment>{children}</React.Fragment>
        </TouchableHighlight>
    )
}
export { Touchable }
export const TouchableNoFeedback = (props: Props) => <Touchable feedback="none" {...props} />
export const TouchableItemFeedback = (props: Props) => <Touchable feedback="item" {...props} />
export const TouchableListFeedback = (props: Props) => <Touchable feedback="list" {...props} />
