import * as React from 'react'

// Components
import { View } from 'react-native'
import SVG from 'react-inlinesvg'

// Style
import styled from '@styles/index'

/*  
    Si une nouvelle icône ajoutée (notamment depuis IcoMoon) n'apparaît pas aux bonnes dimensions, vérifier dans le 
    fichier .svg que height et width ne sont pas fixes. Si c'est le cas, remplacer ces valeurs par "100%".
    
    Si la couleur de l'icône ne change pas, vérifier dans le fichier .svg que l'attribut "fill" est défini et
    qu'il ne vaut pas une couleur fixe (typiquement #000). Sinon, le rajouter sur la balise svg : fill="currentColor".
*/
const icons: { [key in IconName]: string } = {
    'checkbox-checked': require('./svgs/checkbox-checked.svg'),
    'checkbox-unchecked': require('./svgs/checkbox-unchecked.svg'),
    checkmark: require('./svgs/checkmark.svg'),
    'chevron-down': require('./svgs/chevron-down.svg'),
    'chevron-right': require('./svgs/chevron-right.svg'),
    'chevron-up': require('./svgs/chevron-up.svg'),
    connection: require('./svgs/connection.svg'),
    'cloud-upload': require('./svgs/cloud-upload.svg'),
    cross: require('./svgs/cross.svg'),
    exit: require('./svgs/exit.svg'),
    eye: require('./svgs/eye.svg'),
    information: require('./svgs/information.svg'),
    loupe: require('./svgs/loupe.svg'),
    plus: require('./svgs/plus.svg'),
    'toggle-off': require('./svgs/toggle-off.svg'),
    'toggle-on': require('./svgs/toggle-on.svg'),
    pencil: require('./svgs/pencil.svg'),
    poubelle: require('./svgs/poubelle.svg'),
    icp: require('./svgs/icp.svg'),
    agent: require('./svgs/agent.svg'),
    attention: require('./svgs/attention.svg'),
    depanneuse: require('./svgs/depanneuse.svg'),
    securite: require('./svgs/securite.svg'),
    travaux: require('./svgs/travaux.svg'),
    back: require('./svgs/back.svg'),
    agenda: require('./svgs/agenda.svg'),
    user: require('./svgs/user.svg'),
    edit: require('./svgs/edit.svg'),
    menu: require('./svgs/menu.svg'),
    addIcp: require('./svgs/addIcp.svg'),
    triAsc: require('./svgs/triAsc.svg'),
    next: require('./svgs/next.svg'),
    doubleNext: require('./svgs/doubleNext.svg'),
    filter: require('./svgs/filter.svg'),
    'sliced-left': require('./svgs/sliced-left.svg'),
    'sliced-right': require('./svgs/sliced-right.svg'),
    expand: require('./svgs/expand.svg'),
    shrink: require('./svgs/shrink.svg'),
    // MENU MOBILE
    // Icone pour les icp
    myIcp: require('./svgs/drawerMenu/myIcp.svg'),
    allIcp: require('./svgs/drawerMenu/allIcp.svg'),
    icpToPlan: require('./svgs/drawerMenu/icpToPlan.svg'),
    icpToDo: require('./svgs/drawerMenu/icpToDo.svg'),
    icpToEnd: require('./svgs/drawerMenu/icpToEnd.svg'),
    icpToApprove: require('./svgs/drawerMenu/icpToApprove.svg'),
    // Icone pour les ppee
    allPpee: require('./svgs/drawerMenu/allPpee.svg'),
    myPpee: require('./svgs/drawerMenu/myPpee.svg'),
    ppeeActifs: require('./svgs/drawerMenu/ppeeActifs.svg'),
    forms: require('./svgs/drawerMenu/forms.svg'),
    // Autre icones
    home: require('./svgs/drawerMenu/home.svg'),
    contact: require('./svgs/drawerMenu/contact.svg'),
    dashboard: require('./svgs/drawerMenu/dashboard.svg'),
    // Back du menu mobile
    backMenu: require('./svgs/drawerMenu/backMenu.svg'),
    // Menu web spé
    settings: require('./svgs/drawerMenu/settings.svg'),
    graphic: require('./svgs/drawerMenu/graphic.svg'),

    // SAVE BUTTON
    save: require('./svgs/save.svg'),

    // Send Icp to approve
    sendToApprove: require('./svgs/sendIcp.svg'),
    signing: require('./svgs/signing.svg'),
    trash: require('./svgs/trash.svg'),

    delete: require('./svgs/delete.svg'),
}

export default (props: CustomIconProps) => {
    const { name, size, color } = props

    const iconSrc = icons[name || 'plus']

    return (
        <SVGContainer size={size} color={color}>
            <SVG src={iconSrc} />
        </SVGContainer>
    )
}

const SVGContainer = styled(View)<{ size?: number; color?: string }>`
    ${props => props.size && `height: ${props.size}px; width: ${props.size}px;`}
    ${props => props.color && `color: ${props.color};`}
`
