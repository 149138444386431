import * as React from 'react'

// Components
import { Text, View, Platform, FlatList } from 'react-native'
import Modal from '@components/modal/Modal'
import { Formik, FormikErrors } from 'formik'
import TextInput from '@components/textInput/TextInput'
import Button from '@components/button/Button'
import { TouchableItemFeedback } from '@components/touchable/Touchable'
import ContactSection from '@screens/contacts/ContactSection'

// Utils
import { produce } from 'immer'

// Store
import useI18n from '@store/i18n/useI18n'

// Style
import styled from '@styles/index'
import Picker from '@components/picker/Picker'
import { displayUserName } from '@utils/string'

const trimAllValues = (values: Representative): Representative => ({
    ...values,
    firstName: values.firstName.trim(),
    lastName: values.lastName.trim(),
    email: values.email.trim(),
    telephone1: values.telephone1 ? values.telephone1.trim() : '',
    telephone2: values.telephone2 ? values.telephone2.trim() : '',
    agencyName: values.agencyName ? values.agencyName.trim() : '',
})

const STATUS_VALUES = ['Présent', 'Absent', 'Excusé']

interface Props {
    modalVisible: boolean
    onRequestClose: () => void
    onChange: (reps: Representative[]) => void
    contacts: Representative[]
    initialValues?: Representative
    redacteurs?: UserRedacteur[]
}

export default (props: Props) => {
    const { modalVisible, onRequestClose, onChange, contacts, initialValues, redacteurs } = props
    const i18n = useI18n()

    const isInternal = !!redacteurs
    const [mode, setMode] = React.useState<'search' | 'internalSearch' | 'add' | 'edit'>(
        isInternal ? 'internalSearch' : 'search',
    )
    const isSearchMode = mode === 'search' || mode === 'internalSearch'

    const [offlineMode, setOfflineMode] = React.useState<boolean>(false)
    const [mailInformation, setMailInformation] = React.useState<string | undefined>(undefined)

    React.useEffect(() => {
        if (!!initialValues) {
            setMode('edit')
        }
    }, [initialValues])

    const onClose = () => {
        setMode(isInternal ? 'internalSearch' : 'search')
        onRequestClose()
    }

    const handleChange = (values: Representative) => {
        const trimedValues = trimAllValues(values)
        const index = !!initialValues ? contacts.indexOf(initialValues) : -1
        const result: Representative[] =
            !!initialValues && index > -1
                ? produce(contacts, newContacts => {
                      newContacts[index] = trimedValues
                  })
                : [...contacts, trimedValues]
        onChange(result)
        onClose()
    }

    const validateForm = React.useMemo(
        () => (values: Representative): FormikErrors<Representative> => {
            const trimedValues = trimAllValues(values)
            const errors: FormikErrors<Representative> = {}

            if (!trimedValues.firstName) {
                errors.firstName = i18n.t('screens.addUserAdminScreen.required')
            }
            if (!trimedValues.lastName) {
                errors.lastName = i18n.t('screens.addUserAdminScreen.required')
            }
            if (!trimedValues.status) {
                errors.status = i18n.t('screens.addUserAdminScreen.required')
            }
            if (
                contacts.find(c => c.email === trimedValues.email) &&
                (!initialValues || trimedValues.email !== initialValues.email)
            ) {
                setMailInformation(i18n.t('components.addIcpContacts.alreadyAdded'))
            } else {
                setMailInformation(undefined)
            }
            if (!trimedValues.email.match(/^[a-z0-9](?:[.+-]?\w+)*@[a-z0-9]+(?:[.-]?\w+)*$/i)) {
                errors.email = i18n.t('screens.addUserAdminScreen.emailError')
            }
            if (!trimedValues.email) {
                errors.email = i18n.t('screens.addUserAdminScreen.required')
            }

            return errors
        },
        [i18n, contacts, initialValues],
    )

    return (
        <Modal visible={modalVisible} onRequestClose={onClose}>
            <ModalContent>
                {mode !== 'edit' && (
                    <ModeSelector>
                        {!offlineMode && (
                            <FlexContainer>
                                <TouchableItemFeedback
                                    onPress={() => setMode(isInternal ? 'internalSearch' : 'search')}
                                >
                                    <Mode selected={isSearchMode}>
                                        <ModeName selected={isSearchMode}>
                                            {i18n.t(`components.addIcpContacts.modalTitles.search`)}
                                        </ModeName>
                                    </Mode>
                                </TouchableItemFeedback>
                            </FlexContainer>
                        )}
                        <FlexContainer>
                            <TouchableItemFeedback onPress={() => setMode('add')} disabled={offlineMode}>
                                <Mode selected={mode === 'add'}>
                                    <ModeName selected={mode === 'add'}>
                                        {i18n.t(`components.addIcpContacts.modalTitles.add`)}
                                    </ModeName>
                                </Mode>
                            </TouchableItemFeedback>
                        </FlexContainer>
                    </ModeSelector>
                )}
                <Formik
                    initialValues={
                        initialValues ||
                        ({
                            firstName: '',
                            lastName: '',
                            email: '',
                            telephone1: '',
                            telephone2: '',
                            agencyName: '',
                            approbation: 'notSent',
                        } as Representative)
                    }
                    onSubmit={handleChange}
                    validate={validateForm}
                >
                    {({ errors, values, handleSubmit, isValid, setFieldValue, isSubmitting, setValues }) => {
                        const inputRepresentativeInformation = (
                            firstName: string,
                            lastName: string,
                            email?: string,
                            telephone1?: string,
                            telephone2?: string,
                            entity?: Entreprise,
                        ) => {
                            setValues({
                                firstName: firstName,
                                lastName: lastName,
                                email: email || '',
                                telephone1: telephone1 || '',
                                telephone2: telephone2 || '',
                                agencyName: !!entity ? entity.libelle : '',
                                status: 'Présent',
                                approbation: 'notSent',
                            })
                            setMode('edit')
                        }
                        const renderRedacteur = (redacteur: { item: UserRedacteur; index: number }) => (
                            <TouchableItemFeedback
                                key={`redacteur${redacteur.index}`}
                                onPress={() => {
                                    if (!!redacteurs) {
                                        inputRepresentativeInformation(
                                            redacteurs[redacteur.index].firstName,
                                            redacteurs[redacteur.index].lastName,
                                        )
                                    }
                                }}
                            >
                                <RedacteurContainer key={redacteur.index} odd={redacteur.index % 2 === 1}>
                                    <RedacteurName>{displayUserName(redacteur.item)}</RedacteurName>
                                </RedacteurContainer>
                            </TouchableItemFeedback>
                        )

                        return (
                            <View>
                                {mode === 'search' && !offlineMode ? (
                                    <ContactSection
                                        onContactPress={(contact, entity) => {
                                            inputRepresentativeInformation(
                                                contact.prenom,
                                                contact.nom,
                                                contact.mail,
                                                contact.telephone1,
                                                contact.telephone2,
                                                entity,
                                            )
                                        }}
                                        contactByPage={3}
                                        setOfflineMode={() => {
                                            setMode('add')
                                            setOfflineMode(true)
                                        }}
                                    />
                                ) : mode === 'internalSearch' && !!redacteurs && !offlineMode ? (
                                    <RedacteursList>
                                        <SmallMarginBottomView>
                                            <InternalRepresentatives>
                                                {i18n.t('components.addIcpContacts.internalRepresentatives')}
                                            </InternalRepresentatives>
                                        </SmallMarginBottomView>
                                        <FlatList data={redacteurs} renderItem={renderRedacteur} />
                                    </RedacteursList>
                                ) : (
                                    <View>
                                        <ModalRow zIndex={-1}>
                                            <FlexContainer>
                                                <TextInput
                                                    value={values.firstName}
                                                    onTextChange={text => setFieldValue('firstName', text)}
                                                    label={i18n.t('components.addIcpContacts.fields.firstName')}
                                                    required
                                                    error={errors ? errors.firstName : ''}
                                                    placeholder={i18n.t(
                                                        'components.addIcpContacts.placeholders.firstName',
                                                    )}
                                                />
                                            </FlexContainer>
                                        </ModalRow>
                                        <ModalRow zIndex={-2}>
                                            <FlexContainer>
                                                <TextInput
                                                    value={values.lastName}
                                                    onTextChange={text => setFieldValue('lastName', text)}
                                                    label={i18n.t('components.addIcpContacts.fields.lastName')}
                                                    required
                                                    error={errors ? errors.lastName : ''}
                                                    placeholder={i18n.t(
                                                        'components.addIcpContacts.placeholders.lastName',
                                                    )}
                                                />
                                            </FlexContainer>
                                        </ModalRow>
                                        <ModalRow zIndex={-3}>
                                            <FlexContainer>
                                                <Picker
                                                    isClearable
                                                    label={i18n.t('components.addIcpRepresentatives.fields.status')}
                                                    data={STATUS_VALUES}
                                                    onChange={index => setFieldValue('status', STATUS_VALUES[index])}
                                                    value={values.status}
                                                    placeholder={i18n.t(
                                                        'components.addIcpRepresentatives.placeholders.status',
                                                    )}
                                                    error={errors ? errors.status : ''}
                                                    required
                                                />
                                            </FlexContainer>
                                        </ModalRow>
                                        <ModalRow zIndex={-4}>
                                            <FlexContainer>
                                                <TextInput
                                                    value={values.email}
                                                    onTextChange={text => setFieldValue('email', text)}
                                                    label={i18n.t('components.addIcpContacts.fields.email')}
                                                    required
                                                    error={mailInformation}
                                                    placeholder={i18n.t('components.addIcpContacts.placeholders.email')}
                                                />
                                            </FlexContainer>
                                        </ModalRow>
                                        <ModalRow zIndex={-5}>
                                            <FlexContainer>
                                                <TextInput
                                                    value={values.agencyName}
                                                    onTextChange={text => setFieldValue('agencyName', text)}
                                                    label={i18n.t('components.addIcpContacts.fields.entityName')}
                                                    placeholder={i18n.t(
                                                        'components.addIcpContacts.placeholders.entityName',
                                                    )}
                                                />
                                            </FlexContainer>
                                        </ModalRow>
                                        <ModalRow zIndex={-6}>
                                            <FlexContainer>
                                                <TextInput
                                                    value={values.telephone1}
                                                    onTextChange={text => setFieldValue('telephone1', text)}
                                                    label={i18n.t('components.addIcpContacts.fields.telephone1')}
                                                    placeholder={i18n.t(
                                                        'components.addIcpContacts.placeholders.telephone1',
                                                    )}
                                                />
                                            </FlexContainer>
                                        </ModalRow>
                                        <ModalRow zIndex={-7}>
                                            <FlexContainer>
                                                <TextInput
                                                    value={values.telephone2}
                                                    onTextChange={text => setFieldValue('telephone2', text)}
                                                    label={i18n.t('components.addIcpContacts.fields.telephone2')}
                                                    placeholder={i18n.t(
                                                        'components.addIcpContacts.placeholders.telephone2',
                                                    )}
                                                />
                                            </FlexContainer>
                                        </ModalRow>

                                        <ButtonsContainer>
                                            <Button
                                                libelle={i18n.t(
                                                    `components.addIcpContacts.${!!initialValues ? 'edit' : 'add'}`,
                                                )}
                                                status={isSubmitting ? 'loading' : isValid ? 'active' : 'disabled'}
                                                onPress={handleSubmit}
                                            />
                                        </ButtonsContainer>
                                    </View>
                                )}
                            </View>
                        )
                    }}
                </Formik>
            </ModalContent>
        </Modal>
    )
}

const ModalContent = styled(View)`
    background-color: ${props => props.theme.colors.documentsModalBackground};
    padding: 24px;
`
const ModalRow = styled(View)<{ zIndex?: number }>`
    flex-direction: row;
    margin-bottom: 24px;
    ${props => props.zIndex && `z-index: ${props.zIndex};`}
`
const ButtonsContainer = styled(View)`
    flex-direction: row;
    justify-content: space-evenly;
`
const FlexContainer = styled(View)`
    flex: 1;
`
const ModeSelector = styled(View)`
    flex-direction: row;
    border-radius: 20px;
    margin-bottom: 24px;
    overflow: hidden;
`
const Mode = styled(View)<{ selected: boolean }>`
    height: ${`${Platform.OS === 'web' ? 32 : 24}px`};
    background-color: ${props =>
        props.selected ? props.theme.colors.icpContactModeSelected : props.theme.colors.icpContactMode};
    align-items: center;
    justify-content: center;
`
const ModeName = styled(Text)<{ selected: boolean }>`
    ${props => props.theme.fonts.icpContactName}
    color: ${props =>
        !props.selected ? props.theme.colors.icpContactModeSelected : props.theme.colors.icpContactMode};
`
const RedacteurContainer = styled(View)<{ odd: boolean }>`
    background-color: ${props => (props.odd ? props.theme.colors.oddLine : props.theme.colors.evenLine)};
    padding: 10px;

    flex-direction: row;
    ${props =>
        Platform.OS === 'web' &&
        `&:hover {
        background-color: ${props.theme.colors.contactLineHover};
    }`}
`
const RedacteurName = styled(Text)`
    ${props => props.theme.fonts.contactMainInformation};
    margin-left: 20px;
`
const SmallMarginBottomView = styled(View)`
    margin-bottom: 10px;
`
const InternalRepresentatives = styled(Text)`
    ${props => props.theme.fonts.companyMainInformation};
`
const RedacteursList = styled(View)`
    flex: 1;
    background-color: ${props => props.theme.colors.contactListBackground};
    padding: ${Platform.OS === 'web' ? '30px' : '10px'};
    z-index: -1;
`
