import * as React from 'react'

// Components
import { View, Platform, Text } from 'react-native'
import { Formik, FormikErrors } from 'formik'
import Container from '@components/icpFormContainer/icpFormContainer'
import AddIcpAgencies from '@components/addIcpAgencies/AddIcpAgencies'
import AddIcpRepresentative from '@components/addIcpRepresentatives/AddIcpRepresentatives'

// Utils
import { produce } from 'immer'
import { isAgencyIncomplete } from '@utils/icpUtils'

// Store
import useI18n from '@store/i18n/useI18n'

// Style
import styled from '@styles/index'

interface Props {
    initialValues: ActorsIcp
    onValidate?: (isValid: boolean) => void
    globalSetIcpFieldValue: (value: ActorsIcp) => void
    globalInfos: InformationsIcp
    globalActors: ActorsIcp
}

export const validateActorsIcp = (
    values: ActorsIcp,
    i18n: I18nState,
    onValidate?: (isValid: boolean) => void,
): FormikErrors<ActorsIcp> => {
    const errors: FormikErrors<ActorsIcp> = {}

    if (values.agencies.length === 0) {
        errors.agencies = i18n.t('screens.addUserAdminScreen.required')
    }
    if (!!values.agencies.find(agency => isAgencyIncomplete(agency))) {
        errors.agencies = i18n.t('components.actorsIcp.errors.agencyIncomplete')
    }
    if (values.representatives.length === 0) {
        errors.representatives = i18n.t('screens.addUserAdminScreen.required')
    }

    if (!!onValidate) {
        onValidate(Object.keys(errors).length === 0)
    }

    return errors
}

export default (props: Props) => {
    const { initialValues, onValidate, globalSetIcpFieldValue, globalActors, globalInfos } = props
    const i18n = useI18n()

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={() => undefined} // On passe par le handleSubmit global sans validation
            validate={values => validateActorsIcp(values, i18n, onValidate)}
        >
            {({ values, errors, setFieldValue, isValid, setValues }) => {
                const globalSetFieldValue = (key: keyof ActorsIcp, value: any) => {
                    globalSetIcpFieldValue(
                        produce(values, newValues => {
                            newValues[key] = value
                        }),
                    )
                    setFieldValue(key, value)
                }

                React.useEffect(() => {
                    // Mise à jour des représentants/entreprises affichés lorsqu'un contact a été ajouté dans la partie planification
                    setValues({ representatives: globalActors.representatives, agencies: globalActors.agencies })
                    // eslint-disable-next-line
                }, [globalActors])

                return (
                    <Container title={i18n.t('components.actorsIcp.title')} hasErrors={!isValid}>
                        <View>
                            <Section zIndex={-1}>
                                <SectionTitle>
                                    {i18n.t('components.actorsIcp.sectionsTitles.agenciesList')}
                                </SectionTitle>
                                <AddIcpAgencies
                                    values={values.agencies}
                                    onChange={agencies => globalSetFieldValue('agencies', agencies)}
                                    label={i18n.t('components.actorsIcp.agencies')}
                                    required={true}
                                    error={!!errors && errors.agencies}
                                    globalInfos={globalInfos}
                                />
                            </Section>
                            <Section zIndex={-2}>
                                <SectionTitle>
                                    {i18n.t('components.actorsIcp.sectionsTitles.representatives')}
                                </SectionTitle>
                                <AddIcpRepresentative
                                    label={i18n.t('components.actorsIcp.labels.representatives')}
                                    values={values.representatives}
                                    onChange={rep => globalSetFieldValue('representatives', rep)}
                                    required={true}
                                    error={!!errors && errors.representatives}
                                />
                            </Section>
                        </View>
                    </Container>
                )
            }}
        </Formik>
    )
}

const Section = styled(View)<{ zIndex: number }>`
    z-index: ${props => props.zIndex};
    margin-bottom: ${Platform.OS === 'web' ? '24px' : '15px'};
`
const SectionTitle = styled(Text)`
    ${props => props.theme.fonts.icpFormTitle}
    font-family: Avenir-Book;
    margin-bottom: 20px;
`
