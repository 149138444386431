import { createEvent, createStore } from 'effector'
export type VersionState = {
    showNewVersionModal: boolean
}

export const actions = {
    setShowNewVersionModal: createEvent<boolean>('setShowNewVersionModal'),
}

export const initialState: VersionState = {
    showNewVersionModal: false,
}

export const store = createStore<VersionState>(initialState).on(actions.setShowNewVersionModal, (s, v) => ({
    ...s,
    showNewVersionModal: v,
}))
