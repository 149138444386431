import { Platform } from 'react-native'
import Logger from '@utils/logger'
import Azure from '@utils/azure/Azure'

export const isPdf = (value: string) => value.indexOf('data:application/pdf') > -1 || value.indexOf('.pdf') > -1

export const openPdfInTab = (data: string) => {
    if (Platform.OS === 'web') {
        const x = window.open()
        if (x) {
            const pdf = `<embed width='100%' height='100%' src='${data}'/>`
            x.document.open()
            x.document.write(pdf)
            x.document.close()
        }
    }
}

export const arrayBufferToBase64 = (buffer: ArrayBuffer) => {
    let binary = ''
    const bytes = new Uint8Array(buffer)
    const len = bytes.byteLength
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i])
    }
    return window.btoa(binary)
}

export const fetchPdf = (url: string) =>
    Azure.assureToken('server')
        .then(token =>
            fetch(url, {
                headers: {
                    Authorization: 'Bearer ' + token.accessToken,
                    Accept: 'application/pdf',
                },
            }),
        )
        .then(res => {
            return res.arrayBuffer()
        })
        .catch(error => {
            Logger.error(error)
            throw error
        })

export const getPpeePdfToBase64 = (url: string) =>
    fetchPdf(url)
        .then(res => arrayBufferToBase64(res))
        .catch(error => {
            Logger.error(error)
            throw error
        })
