import axios from 'axios'

import * as CustomInterfaceStore from '@store/customInterfaceStore'

import configuration from '@app/config'

import Logger from '@utils/logger'

// const PREFIX = (Platform.OS === 'web' ? '' : configuration.SERVER_PREFIX) + '/services/v1'
const PREFIX = configuration.SERVER_PREFIX + '/services/v1'

export const getThemeFromApi = () => {
    axios
        .request<Configuration>({
            url: `${PREFIX}/admin/configuration`,
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
        })
        .then(res => {
            CustomInterfaceStore.actions.setMainColor(res.data.color)
            CustomInterfaceStore.actions.setMenuLogo(res.data.logo)
            CustomInterfaceStore.actions.setName(res.data.name)
        })
        .catch(Logger.error)
}
