import Home from '../screens/home/Home'
import Account from '../screens/account/Account'
import RolesRightsAdminScreen from '../screens/rolesRightsAdminScreen/rolesRightsAdminScreen'
import PerimetersAdminScreen from '../screens/perimetersAdminScreen/perimetersAdminScreen'
import UsersAdminScreen from '../screens/usersAdminScreen/usersAdminScreen'
import EditUserScreen from '../screens/editUserScreen/editUserScreen'
import EditPerimeterAdminScreen from '../screens/editPerimeterAdminScreen/editPerimeterAdminScreen'
import TodoScreen from '../screens/todo/Todo'
import RiskFamiliesAdminScreen from '../screens/riskFamiliesAdminScreen/riskFamiliesAdminScreen'
import EditRiskFamilyAdminScreen from '../screens/editRiskFamilyAdminScreen/editRiskFamilyAdminScreen'
import ModelesAdminScreen from '../screens/modelesAdminScreen/modelesAdminScreen'
import EditModeleAdminScreen from '../screens/editModeleAdminScreen/editModeleAdminScreen'
import SelectModeleScreen from '../screens/selectModeleScreen/selectModeleScreen'
import EditForm from '../screens/editForm/editForm'
import CompanyAdminScreen from '../screens/companyAdminScreen/CompanyAdminScreen'
import ContactScreen from '../screens/contacts/ContactScreen'
import EditContact from '../screens/contacts/EditContact'
import DocumentsAdminScreen from '../screens/documentsAdminScreen/documentsAdminScreen'
import EditIcp from '../screens/editIcpScreen/editIcpScreen'
import IcpListScreen from '../screens/icpListScreen/IcpListScreen'
import PpeePdfScreen from '../screens/ppeePdfScreen/ppeePdfScreen'
import EditAmendmentScreen from '../screens/editAmendmentScreen/editAmendmentScreen'
import UnknownAgencies from '../screens/unknownAgenciesScreen/unknownAgenciesScreen'
import UnknownRisksMeasures from '../screens/unknownRisksMeasuresScreen/unknownRisksMeasuresScreen'
import GraphsScreen from '../screens/graphsScreen/graphsScreen'
import UploadPpee from '../screens/uploadPpee/uploadPpee'
import VisitForm from '../screens/visitForm/visitForm'
import CustomInterfaceScreen from '@screens/custom/Interface.web'
import CustomCssctScreen from '@screens/custom/Cssct'
import CustomWorkInspectionScreen from '@screens/custom/WorkInspection'

const routes = [
    {
        name: 'homeScreen',
        path: '/',
        component: Home,
    },
    {
        name: 'myAccount',
        path: '/account',
        component: Account,
    },
    {
        name: 'rolesRightsAdminScreen',
        path: '/roles',
        component: RolesRightsAdminScreen,
    },
    {
        name: 'perimetersAdminScreen',
        path: '/perimetres',
        component: PerimetersAdminScreen,
    },
    {
        name: 'usersAdminScreen',
        path: '/utilisateurs',
        component: UsersAdminScreen,
    },
    {
        name: 'addUserAdminScreen',
        path: '/ajouterUtilisateur',
        component: EditUserScreen,
    },
    {
        name: 'editUserAdminScreen',
        path: '/modifierUtilisateur/:userId',
        component: EditUserScreen,
    },
    {
        name: 'editPerimeterAdminScreen',
        path: '/modifierPerimetre/:perimeterId',
        component: EditPerimeterAdminScreen,
    },
    {
        name: 'icpToCome',
        path: '/icpAVenir',
        component: IcpListScreen,
        params: { initialType: 'ICP', initialFilter: 'mesICP' },
    },
    {
        name: 'icpToPlan',
        path: '/icpAPlanifier',
        component: IcpListScreen,
        params: { initialType: 'ICP', initialFilter: 'aPlanifier' },
    },
    {
        name: 'icpToDo',
        path: '/icpARealiser',
        component: IcpListScreen,
        params: { initialType: 'ICP', initialFilter: 'aRealiser' },
    },
    {
        name: 'icpToEnd',
        path: '/icpAFinaliser',
        component: IcpListScreen,
        params: { initialType: 'ICP', initialFilter: 'aFinaliser' },
    },
    {
        name: 'icpToApprove',
        path: '/icpAApprouver',
        component: IcpListScreen,
        params: { initialType: 'ICP', initialFilter: 'aApprouver' },
    },
    {
        name: 'allIcps',
        path: '/toutesLesIcps',
        component: IcpListScreen,
        params: { initialType: 'ICP', initialFilter: 'all' },
    },
    {
        name: 'ppeeInProgress',
        path: '/actifEnCours',
        component: IcpListScreen,
        params: { initialType: 'PPEE', initialFilter: 'actif' },
    },
    {
        name: 'preventionPlan',
        path: '/mesPlansDePrevention',
        component: IcpListScreen,
        params: { initialType: 'PPEE', initialFilter: 'mesICP' },
    },
    {
        name: 'allPpees',
        path: '/tousLesPpees',
        component: IcpListScreen,
        params: { initialType: 'PPEE', initialFilter: 'all' },
    },
    {
        name: 'preventionPlan',
        path: '/mesPlansDePrevention',
        component: IcpListScreen,
        params: { initialType: 'PPEE', initialFilter: 'mesICP' },
    },
    {
        name: 'contact',
        path: '/contacts',
        component: ContactScreen,
    },
    {
        name: 'agenda',
        path: '/agenda',
        component: TodoScreen,
    },
    {
        name: 'follow',
        path: '/mesSuivis',
        component: TodoScreen,
    },
    {
        name: 'addIcp',
        path: '/ajouterICP',
        component: EditIcp,
    },
    {
        name: 'ajouterICP',
        path: '/ajouterICP',
        component: EditIcp,
    },
    {
        name: 'startIcp',
        path: '/demarrerICP',
        component: EditIcp,
    },
    {
        name: 'riskFamilies',
        path: '/famillesDeRisque',
        component: RiskFamiliesAdminScreen,
    },
    {
        name: 'addRiskFamily',
        path: '/ajouterFamilleDeRisque',
        component: EditRiskFamilyAdminScreen,
    },
    {
        name: 'handleRisks',
        path: '/gestionDesRisques/:riskFamilyId',
        component: EditRiskFamilyAdminScreen,
    },
    {
        name: 'modeles',
        path: '/trames',
        component: ModelesAdminScreen,
    },
    {
        name: 'addModele',
        path: '/ajouterTrame',
        component: EditModeleAdminScreen,
    },
    {
        name: 'handleModele',
        path: '/gestionDesTrames/:modeleId',
        component: EditModeleAdminScreen,
    },
    {
        name: 'adminCompany',
        path: '/administrationEntreprises',
        component: CompanyAdminScreen,
    },
    {
        name: 'addContact',
        path: '/ajouterContact',
        component: EditContact,
    },
    {
        name: 'editContact',
        path: '/modifierContact/:contactId',
        component: EditContact,
    },
    {
        name: 'documents',
        path: '/documentsAnnexes',
        component: DocumentsAdminScreen,
    },
    {
        name: 'modifierICP',
        path: '/icp/:icpId',
        component: EditIcp,
    },
    {
        name: 'ppee',
        path: '/ppee/:ppeeId',
        component: PpeePdfScreen,
    },
    {
        name: 'avenantPPEE',
        path: '/avenantPPEE/:ppeeId',
        component: PpeePdfScreen,
    },
    {
        name: 'selectModele',
        path: '/ppee/:ppeeId/ajouterFormulaire',
        component: SelectModeleScreen,
    },
    {
        name: 'addForm',
        path: '/ppee/:ppeeId/ajouterFormulaire/:modeleId',
        component: EditForm,
        params: { action: 'add' },
    },
    {
        name: 'editForm',
        path: '/ppee/:ppeeId/modifierFormulaire/:formId',
        component: EditForm,
        params: { action: 'edit' },
    },
    {
        name: 'addAmendment',
        path: '/ppee/:ppeeId/ajouterAvenant',
        component: EditAmendmentScreen,
    },
    {
        name: 'readForm',
        path: '/ppee/:ppeeId/lireFormulaire/:formId',
        component: EditForm,
        params: { action: 'read' },
    },
    {
        name: 'editAmendment',
        path: '/ppee/:ppeeId/modifierAvenant/:icpId',
        component: EditIcp,
    },
    {
        name: 'icpMultiEntreprises',
        path: '/icpMultiEntreprises',
        component: IcpListScreen,
        params: { initialType: 'ICP', multiEntreprises: true },
    },
    {
        name: 'ppeeMultiEntreprises',
        path: '/ppeeMultiEntreprises',
        component: IcpListScreen,
        params: { initialType: 'PPEE', multiEntreprises: true },
    },
    {
        name: 'unknownAgencies',
        path: '/entitesNonReferencees',
        component: UnknownAgencies,
    },
    {
        name: 'unknownRisksMeasures',
        path: '/risquesEtMesuresNonReferencees',
        component: UnknownRisksMeasures,
    },
    {
        name: 'graphs',
        path: '/graphes',
        component: GraphsScreen,
    },
    {
        name: 'uploadPpee',
        path: '/televersementPpee',
        component: UploadPpee,
    },
    {
        name: 'visitForm',
        path: '/ppee/:ppeeId/visitForm',
        component: VisitForm,
    },
    {
        name: 'editVisitForm',
        path: '/ppee/:ppeeId/visitForm/:formId',
        component: VisitForm,
    },
    {
        name: 'editVisitForm',
        path: '/ppee/:ppeeId/visitFormRead/:formId',
        component: VisitForm,
        params: { action: 'read' },
    },
    {
        name: 'customInterface',
        path: '/customInterface',
        component: CustomInterfaceScreen,
    },
    {
        name: 'customCssct',
        path: '/customCssct',
        component: CustomCssctScreen,
    },
    {
        name: 'customWorkInspection',
        path: '/customWorkInspection',
        component: CustomWorkInspectionScreen,
    },
]

export default routes
