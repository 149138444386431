import * as React from 'react'

// Components
import { View, Text, ActivityIndicator, ScrollView } from 'react-native'
import Picker from '@components/picker/Picker'
import SearchInput from '@components/searchInput/SearchInput'
import Icon from '@components/icon/Icon'
import Button from '@components/button/Button'
import Checkbox from '@components/checkbox/Checkbox'
import { TouchableItemFeedback } from '@components/touchable/Touchable'
import RootScreen from '@components/rootScreen/RootScreen'

import useI18n from '@store/i18n/useI18n'

// Api
import api from '@api/api'

// Utils
import { getFullUserPerimeterName, getAllUserPerimeterSubLevelsUsers } from '@utils/perimeterUtils'
import * as JsSearch from 'js-search'
import accentSanitizer from '@utils/accentSanitizer'
import useNavigation from '@layout/useNavigation'

// Style
import styled from '@styles/index'
import Logger from '@utils/logger'
import useTheme from '@styles/useTheme'

const sanitizer = accentSanitizer()
let searchjs: JsSearch.Search

const initSearchJs = (documents: any[]) => {
    searchjs = new JsSearch.Search('id')
    searchjs.searchIndex = new JsSearch.UnorderedSearchIndex()
    searchjs.indexStrategy = new JsSearch.AllSubstringsIndexStrategy()
    searchjs.tokenizer = {
        tokenize: (text: string) => text.split(' '),
    }
    searchjs.sanitizer = sanitizer
    searchjs.addIndex('firstName')
    searchjs.addIndex('lastName')
    searchjs.addIndex('email')
    searchjs.addDocuments(documents)
}

const sortAndFilterUsers = (users: User[], seeInactiveUsers: boolean, role?: Role) => {
    const filteredByRole = !!role ? users.filter(u => u.idRole === role.id) : users
    const filteredByActivity = seeInactiveUsers ? filteredByRole : filteredByRole.filter(u => u.active)
    return [...filteredByActivity].sort((user1, user2) => user1.lastName.localeCompare(user2.lastName))
}

export default () => {
    const i18n = useI18n()
    const navigation = useNavigation()
    const [Theme] = useTheme()

    const [status, setStatus] = React.useState<Status>('loading')
    const [allPerimeters, setAllPerimeters] = React.useState<PerimeterUser[]>([])
    const [perimeters, setPerimeters] = React.useState<PerimeterUser[]>([])

    const [direction, setDirection] = React.useState<PerimeterUser | undefined>(undefined)
    const [structure, setStructure] = React.useState<PerimeterUser | undefined>(undefined)
    const [perimeter, setPerimeter] = React.useState<PerimeterUser | undefined>(undefined)
    const [sector, setSector] = React.useState<PerimeterUser | undefined>(undefined)

    const [roles, setRoles] = React.useState<RoleList | undefined>(undefined)
    const [selectedRole, setSelectedRole] = React.useState<Role | undefined>(undefined)
    const [seeInactiveUsers, setSeeInactiveUsers] = React.useState<boolean>(true)
    const [text, setText] = React.useState<string>('')

    const [items, setItems] = React.useState<User[]>([])

    const [loadingResults, setLoadingResultsStatus] = React.useState<Status>('fetched')

    const searchUsers = (users: User[], inputText: string): User[] => {
        const searchedList = (inputText ? searchjs.search(sanitizer.sanitize(inputText)) : users) as User[]
        return searchedList
    }

    const updateLastLevel = (perimeterID: string) => {
        setLoadingResultsStatus('loading')
        api.admin
            .getUserListByPerimeter(perimeterID)
            .then(perimeterList => {
                setPerimeters(perimeterList)
                setLoadingResultsStatus('fetched')
            })
            .catch(err => {
                Logger.error(err)
                setLoadingResultsStatus('error')
            })
    }

    React.useEffect(() => {
        Promise.all([api.admin.getUserListByPerimeter(), api.admin.getRoleList()])
            .then(([p, roles]) => {
                setAllPerimeters(p)
                setPerimeters(p)
                setRoles(roles)
                setStatus('fetched')
            })
            .catch(err => {
                Logger.error(err)
                setStatus('error')
            })
    }, [])

    React.useEffect(() => {
        const users =
            perimeters &&
            sortAndFilterUsers(getAllUserPerimeterSubLevelsUsers(perimeters), seeInactiveUsers, selectedRole)

        initSearchJs(users || [])
        setItems(searchUsers(users || [], text.trim()))
    }, [perimeters, seeInactiveUsers, selectedRole, text])

    const displayPerimeters = (allPerimeters[0] && allPerimeters[0].subPerimeterUser) || []

    return (
        <RootScreen status={status}>
            {perimeters && (
                <React.Fragment>
                    <PickerLine zIndex={0}>
                        <PickerContainer left>
                            <Picker
                                data={displayPerimeters.map(p => p.libelle)}
                                value={!!direction ? direction.libelle : undefined}
                                onChange={index => {
                                    updateLastLevel(index > -1 ? displayPerimeters[index].idPerimeter : '')
                                    setDirection(displayPerimeters[index])
                                    setStructure(undefined)
                                    setPerimeter(undefined)
                                    setSector(undefined)
                                }}
                                label={i18n.t('screens.perimetersAdminScreen.pickerLabels.direction')}
                                isClearable
                            />
                        </PickerContainer>
                        <PickerContainer>
                            <Picker
                                data={!!direction ? direction.subPerimeterUser.map(p => p.libelle) : []}
                                value={!!structure ? structure.libelle : undefined}
                                onChange={index => {
                                    if (!!direction) {
                                        updateLastLevel(
                                            index > -1
                                                ? direction.subPerimeterUser[index].idPerimeter
                                                : direction.idPerimeter,
                                        )
                                        setStructure(direction.subPerimeterUser[index])
                                        setPerimeter(undefined)
                                        setSector(undefined)
                                    }
                                }}
                                label={i18n.t('screens.perimetersAdminScreen.pickerLabels.structure')}
                                disabled={!direction}
                                isClearable
                            />
                        </PickerContainer>
                    </PickerLine>
                    <PickerLine zIndex={-1}>
                        <PickerContainer left>
                            <Picker
                                data={!!structure ? structure.subPerimeterUser.map(p => p.libelle) : []}
                                value={!!perimeter ? perimeter.libelle : undefined}
                                onChange={index => {
                                    if (!!structure) {
                                        updateLastLevel(
                                            index > -1
                                                ? structure.subPerimeterUser[index].idPerimeter
                                                : structure.idPerimeter,
                                        )
                                        setPerimeter(structure.subPerimeterUser[index])
                                        setSector(undefined)
                                    }
                                }}
                                label={i18n.t('screens.perimetersAdminScreen.pickerLabels.perimeter')}
                                disabled={!structure}
                                isClearable
                            />
                        </PickerContainer>
                        <PickerContainer>
                            <Picker
                                data={!!perimeter ? perimeter.subPerimeterUser.map(p => p.libelle) : []}
                                value={!!sector ? sector.libelle : undefined}
                                onChange={index => {
                                    if (!!perimeter) {
                                        updateLastLevel(
                                            index > -1
                                                ? perimeter.subPerimeterUser[index].idPerimeter
                                                : perimeter.idPerimeter,
                                        )
                                        setSector(perimeter.subPerimeterUser[index])
                                    }
                                }}
                                label={i18n.t('screens.usersAdminScreen.pickerLabels.sector')}
                                disabled={!perimeter}
                                isClearable
                            />
                        </PickerContainer>
                    </PickerLine>
                    <PickerLine zIndex={-2}>
                        <PickerContainer left>
                            <Picker
                                data={roles ? roles.map(r => r.name) : []}
                                value={!!selectedRole ? selectedRole.name : undefined}
                                onChange={index => roles && setSelectedRole(roles[index])}
                                label={i18n.t('screens.usersAdminScreen.pickerLabels.roles')}
                                isClearable
                            />
                        </PickerContainer>
                        <PickerContainer>
                            <Checkbox
                                value={seeInactiveUsers}
                                onCheck={() => setSeeInactiveUsers(!seeInactiveUsers)}
                                label={i18n.t('screens.usersAdminScreen.activeOnly')}
                            />
                        </PickerContainer>
                    </PickerLine>

                    <SearchResultsContainer zIndex={-3}>
                        <SearchInput
                            onTextChange={setText}
                            placeholder={i18n.t('screens.usersAdminScreen.searchPlaceholder')}
                            resultsCount={items.length}
                        ></SearchInput>
                    </SearchResultsContainer>

                    <ResultsContainer zIndex={-3}>
                        <LegendLine>
                            <NameLegend>
                                <Legend>{i18n.t('screens.usersAdminScreen.resultsLegend.name')}</Legend>
                            </NameLegend>
                            <PerimeterContainer>
                                <Legend>{i18n.t('screens.usersAdminScreen.resultsLegend.perimeter')}</Legend>
                            </PerimeterContainer>
                            <RoleContainer>
                                <Legend>{i18n.t('screens.usersAdminScreen.resultsLegend.role')}</Legend>
                            </RoleContainer>
                            <ActiveContainer>
                                <Legend>{i18n.t('screens.usersAdminScreen.resultsLegend.active')}</Legend>
                            </ActiveContainer>
                        </LegendLine>
                        {loadingResults === 'loading' ? (
                            <ActivityIndicator
                                size={Theme.constants.activityIndicatorScreenSize}
                                color={Theme.colors.activityIndicator}
                            />
                        ) : loadingResults === 'error' ? (
                            <NoUserMessage>{i18n.t('screens.usersAdminScreen.errorMessage')}</NoUserMessage>
                        ) : items.length === 0 ? (
                            <NoUserMessage>{i18n.t('screens.usersAdminScreen.noUser')}</NoUserMessage>
                        ) : (
                            <ScrollView>
                                {items.map((u, index) => {
                                    const userRole = !!roles ? roles.find(r => r.id === u.idRole) : undefined
                                    return (
                                        <TouchableItemFeedback
                                            key={`user${index}`}
                                            onPress={() =>
                                                navigation.push('/modifierUtilisateur/:userId', {
                                                    pathParams: { userId: u.id },
                                                })
                                            }
                                        >
                                            <UserLine odd={index % 2 === 1}>
                                                <UserName numberOfLines={2} ellipsizeMode={'tail'}>
                                                    {`${u.lastName.toUpperCase()} ${u.firstName}`}
                                                </UserName>
                                                <PerimeterContainer>
                                                    <UserInformation numberOfLines={2} ellipsizeMode={'tail'}>
                                                        {allPerimeters && u.idPerimeter
                                                            ? getFullUserPerimeterName(u.idPerimeter, allPerimeters)
                                                            : i18n.t('screens.usersAdminScreen.noPerimeter')}
                                                    </UserInformation>
                                                </PerimeterContainer>
                                                <RoleContainer>
                                                    <UserInformation>
                                                        {userRole ? userRole.name : 'Role inconnu'}
                                                    </UserInformation>
                                                </RoleContainer>
                                                <ActiveContainer>
                                                    <UserInformation>
                                                        {i18n.t(
                                                            `screens.usersAdminScreen.${
                                                                u.active ? 'active' : 'inactive'
                                                            }`,
                                                        )}
                                                    </UserInformation>
                                                </ActiveContainer>
                                            </UserLine>
                                        </TouchableItemFeedback>
                                    )
                                })}
                            </ScrollView>
                        )}
                    </ResultsContainer>

                    <ButtonContainer>
                        <Button
                            libelle={i18n.t('screens.usersAdminScreen.addUserButton')}
                            status={'active'}
                            onPress={() => navigation.push('/ajouterUtilisateur')}
                            width={250}
                        >
                            <ButtonContent>
                                <Icon name={'plus'} color={Theme.colors.buttonTitleClassic} size={15} />
                                <ButtonTitle>{i18n.t('screens.usersAdminScreen.addUserButton')}</ButtonTitle>
                            </ButtonContent>
                        </Button>
                    </ButtonContainer>
                </React.Fragment>
            )}
        </RootScreen>
    )
}

const PickerLine = styled(View)<{ zIndex: number }>`
    flex-direction: row;
    margin-bottom: 10px;
    align-items: center;
    z-index: ${props => props.zIndex};
`
const PickerContainer = styled(View)<{ left?: boolean }>`
    flex: 1;
    ${props => props.left && 'margin-right: 40px;'}
`
const SearchResultsContainer = styled(View)<{ zIndex: number }>`
    z-index: ${props => props.zIndex};
    margin-bottom: 20px;
    margin-top: 10px;
`
const ResultsContainer = styled(View)<{ zIndex: number }>`
    flex: 1;
    background-color: white;
    padding: 12px 24px;
    z-index: ${props => props.zIndex};
`
const LegendLine = styled(View)`
    flex-direction: row;
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom: 8px;
`
const NameLegend = styled(View)`
    flex: 1;
`
const RoleContainer = styled(View)`
    width: 120px;
`
const ActiveContainer = styled(View)`
    width: 35px;
`
const Legend = styled(Text)`
    ${props => props.theme.fonts.perimeterLegend}
    text-transform: uppercase;
`
const UserLine = styled(View)<{ odd: boolean }>`
    flex-direction: row;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
    height: ${props => props.theme.constants.rightLineHeight}px;
    background-color: ${props => !props.odd && props.theme.colors.evenLines};

    &:hover {
        background-color: ${props => props.theme.colors.hoverBackground};
        & > div {
            color: ${props => props.theme.colors.hoverText};
            & > div {
                color: ${props => props.theme.colors.hoverText};
            }
        }
    }
`
const UserName = styled(Text)`
    flex: 1;
    ${props => props.theme.fonts.perimeterName}
    font-family: Avenir-Heavy;
`
const ButtonContainer = styled(View)`
    margin-top: 15px;
    margin-bottom: 15px;
    flex-direction: row;
    justify-content: center;
`
const ButtonContent = styled(View)`
    flex-direction: row;
    align-items: center;
`
const ButtonTitle = styled(Text)`
    ${props => props.theme.fonts.buttonTitle}
    text-transform: uppercase;
    margin-left: 10px;
`
const NoUserMessage = styled(Text)`
    ${props => props.theme.fonts.perimeterName}
    text-align: center;
    margin-top: 15px;
`
const UserInformation = styled(Text)`
    ${props => props.theme.fonts.perimeterName}
    font-family: Avenir-Heavy;
`
const PerimeterContainer = styled(View)`
    flex: 1;
`
