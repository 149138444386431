import * as React from 'react'
import { ScrollView, Text, View } from 'react-native'

import styled from '@styles/index'

import RootScreen from '@components/rootScreen/RootScreen'
import Button from '@components/button/Button'
import ImageSelect from '@components/imageDropInput/ImageSelect.web'
import Icon from '@components/icon/Icon'
import { TouchableItemFeedback } from '@components/touchable/Touchable'
import TextInput from '@components/textInput/TextInput'
import { basicAlert } from '@components/alert/Alerts'
import EditableList from '@components/lists/EditableList'

import useI18n from '@store/i18n/useI18n'
import api from '@api/api'

import { openPdfInTab } from '@utils/pdfCommonUtils'
import Logger from '@utils/logger'
import useTheme from '@styles/useTheme'

const I18N_SCREEN_KEY = 'screens.customInterface'

interface Props {
    domain: 'Cssct' | 'WorkInspection'
}

const EditDocAndEmail = ({ domain }: Props) => {
    const i18n = useI18n()
    const [Theme] = useTheme()

    const [status, setStatus] = React.useState<Status>('loading')
    const [buttonStatus, setButtonStatus] = React.useState<ButtonStatus>('active')
    const [currentPdf, setCurrentPdf] = React.useState('')
    const [emailsList, setEmailsList] = React.useState<string[]>([])

    const [newDocument, setNewDocument] = React.useState('')
    const [editingEmail, setEditingMail] = React.useState<EditingMail>()
    const [newEmail, setNewEmail] = React.useState('')

    React.useEffect(() => {
        const getPromise = domain === 'Cssct' ? api.custom.getCssct() : api.custom.getWi()

        getPromise
            .then(res => {
                setCurrentPdf(res.pdf || '')
                setNewDocument(res.pdf || '')
                setEmailsList(res.emails.map(adminEmailItem => adminEmailItem.email))
                setStatus('fetched')
            })
            .catch(err => {
                Logger.error(err)
                setStatus('error')
            })
    }, [domain])

    const errorMail = (email: string) => {
        return !email.match(/^[a-z0-9_]+([.-]?[a-z0-9_]+)*@\w+([.-]?[a-z0-9_]+)*(\.[a-z0-9_]{2,3})+$/)
            ? i18n.t('common.error.mail')
            : undefined
    }

    const AlertAfterSubmit = (type: 'success' | 'error') =>
        basicAlert(i18n, I18N_SCREEN_KEY, `Edit${domain}`, () => setButtonStatus('active'), type, Theme)

    const saveNewConf = () => {
        setButtonStatus('loading')
        const newConf = {
            pdf: newDocument,
            emails: emailsList.map(mailListItem => {
                return {
                    email: mailListItem,
                }
            }),
        }
        const editPromise = domain === 'Cssct' ? api.custom.editCssct(newConf) : api.custom.editWi(newConf)

        editPromise
            .then(() => {
                AlertAfterSubmit('success')
                setCurrentPdf(newDocument)
            })
            .catch(() => AlertAfterSubmit('error'))
    }

    return (
        <RootScreen status={status}>
            <MainContainer>
                <ListTitleContainer>
                    <ListTitle numberOfLines={2}>{i18n.t(`screens.customInterface.document${domain}`)}</ListTitle>
                    <Button libelle={i18n.t('common.save')} onPress={saveNewConf} status={buttonStatus} />
                </ListTitleContainer>
                <DocumentPickerContainer>
                    <PdfButton>
                        <Icon name="icpToApprove" size={50} color={Theme.colors.uploadIconColor} />
                        <MarginTop>
                            <Button
                                libelle={i18n.t('common.preview')}
                                onPress={() => openPdfInTab(currentPdf)}
                                status="active"
                                width={90}
                            />
                        </MarginTop>
                    </PdfButton>
                    <ImageSelect
                        value={newDocument}
                        onChange={setNewDocument}
                        isNew={currentPdf !== newDocument}
                        isPdf
                    />
                </DocumentPickerContainer>
                <ListTitleContainer>
                    <ListTitle numberOfLines={2}>{i18n.t(`screens.customInterface.emails${domain}`)}</ListTitle>
                </ListTitleContainer>
                <EmailListContainer>
                    {emailsList.map((email, index) => (
                        <EditableList
                            key={`${email} - ${index}`}
                            text={email}
                            index={index}
                            editing={!!editingEmail && editingEmail.index === index}
                            errorText={errorMail}
                            textList={emailsList}
                            setTextList={setEmailsList}
                            editingText={editingEmail}
                            setEditingText={setEditingMail}
                        />
                    ))}
                    <ContentRight>
                        <NewEmailContainer>
                            <TextInput
                                value={newEmail}
                                onTextChange={setNewEmail}
                                placeholder={i18n.t('screens.customInterface.newEmailPlaceholder')}
                                flexed
                                error={newEmail.length > 0 ? errorMail(newEmail) : undefined}
                            />
                            <TouchableItemFeedback
                                onPress={() => {
                                    setEmailsList([...emailsList, newEmail])
                                    setNewEmail('')
                                }}
                                disabled={!newEmail || !!errorMail(newEmail)}
                            >
                                <AddEmailContainer disabled={!newEmail || !!errorMail(newEmail)}>
                                    <Icon name="plus" size={15} color={Theme.colors.iconColor} />
                                </AddEmailContainer>
                            </TouchableItemFeedback>
                        </NewEmailContainer>
                    </ContentRight>
                </EmailListContainer>
            </MainContainer>
        </RootScreen>
    )
}
export default EditDocAndEmail

const MainContainer = styled(ScrollView)`
    flex: 1;
    background-color: ${props => props.theme.colors.rolesBackground};
`
const ListTitleContainer = styled(View)`
    flex-direction: row;
    height: ${props => props.theme.constants.riskFamiliesListTitleContainerHeight}px;
    justify-content: space-between;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;

    border-bottom-color: ${props => props.theme.colors.homeListBorderColor};
    border-bottom-width: 2px;
`
const ListTitle = styled(Text)`
    ${props => props.theme.fonts.homeListTitle}
    text-transform: uppercase;
`
const DocumentPickerContainer = styled(View)`
    flex-direction: row;
    align-items: center;
`
const PdfButton = styled(View)`
    background-color: ${props => props.theme.colors.imageDropInputBackground};
    justify-content: center;
    align-items: center;
    height: ${props => props.theme.constants.formImageInputPictureSize};
    width: ${props => props.theme.constants.formImageInputPictureSize};

    margin-top: ${props => props.theme.constants.webMenuLogoVerticalPadding + 7}px;
    margin-bottom: ${props => props.theme.constants.webMenuLogoVerticalPadding}px;
    margin-left: ${props => props.theme.constants.webMenuLogoHorizontalPadding}px;
    margin-right: ${props => props.theme.constants.webMenuLogoHorizontalPadding}px;

    border-radius: 5px;
    border: 1px solid ${props => props.theme.colors.menuBackground};
`
const MarginTop = styled(View)`
    margin-top: 5px;
`
const EmailListContainer = styled(View)`
    padding: 20px 50px;
`
const ContentRight = styled(View)`
    align-items: flex-end;
`
const NewEmailContainer = styled(View)`
    flex-direction: row;
    align-items: center;
    padding: 20px 10px;
    margin: 10px 0px;
    background-color: ${props => props.theme.colors.evenLine};
    min-width: 400px;
    justify-content: flex-end;
`
const AddEmailContainer = styled(View)<{ disabled?: boolean }>`
    background-color: ${props => (props.disabled ? props.theme.colors.addIconDisabled : props.theme.colors.addIcon)};
    height: 50px;
    width: 50px;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
`
