import * as React from 'react'

// Components
import { View, Platform, Text, FlatList } from 'react-native'
import Container from '@components/icpFormContainer/icpFormContainer'
import AddIcpDocument from '@components/addIcpDocuments/ReadIcpDocuments'

// Store
import useI18n from '@store/i18n/useI18n'

// Style
import styled from '@styles/index'

interface Props {
    values: DocumentsIcp
}

export default (props: Props) => {
    const { values } = props
    const i18n = useI18n()

    return (
        <Container title={i18n.t('components.documentsIcp.title')}>
            <View>
                <Section zIndex={0}>
                    <SectionTitle>{i18n.t('components.documentsIcp.sectionsTitles.documentsAPRR')}</SectionTitle>
                    {values.documentsAprr.length !== 0 ? (
                        <FlatList
                            data={values.documentsAprr}
                            renderItem={({ item }) => (
                                <ValueContainer>
                                    <Value>{item.name}</Value>
                                </ValueContainer>
                            )}
                            keyExtractor={item => item.id}
                        />
                    ) : (
                        <ValueContainer>
                            <Value>{i18n.t('components.documentsIcp.sectionsTitles.noDocumentsAPRR')}</Value>
                        </ValueContainer>
                    )}
                </Section>
                <Section zIndex={-1}>
                    <SectionTitle>{i18n.t('components.documentsIcp.sectionsTitles.documentsExt')}</SectionTitle>
                    <AddIcpDocument values={values.documentsExt} />
                </Section>
                <Section zIndex={-2}>
                    <SectionTitle>{i18n.t('components.documentsIcp.sectionsTitles.attachedDocuments')}</SectionTitle>
                    <AddIcpDocument values={values.attachedDocuments} />
                </Section>
            </View>
        </Container>
    )
}

const Section = styled(View)<{ zIndex: number }>`
    z-index: ${props => props.zIndex};
    margin-bottom: ${Platform.OS === 'web' ? '24px' : '15px'};
`
const SectionTitle = styled(Text)`
    ${props => props.theme.fonts.icpFormTitle}
    font-family: Avenir-Book;
    margin-bottom: 20px;
`

const Value = styled(Text)`
    ${props => props.theme.fonts.inputText}
    color:  ${props => props.theme.colors.inputText};
    
`

const ValueContainer = styled(View)`
    margin-bottom: 5px;
`
