import * as React from 'react'

// Components
import { View, Text, ActivityIndicator, Platform } from 'react-native'
import { TouchableItemFeedback } from '@components/touchable/Touchable'
import Icon from '@components/icon/Icon'

// Style
import styled from '@styles/index'
import useTheme from '@styles/useTheme'

interface Props {
    label?: string
    onCheck: () => void
    value: boolean
    checkedColor?: string
    isLoading?: boolean
    disabled?: boolean
    smallCheckbox?: boolean
    toggle?: boolean
    extendedTouchZone?: boolean
}

export default (props: Props) => {
    const { label, value, onCheck, checkedColor, isLoading, disabled, smallCheckbox, toggle, extendedTouchZone } = props
    const [Theme] = useTheme()

    return isLoading ? (
        <RowCenteredContainer>
            <Loader size={20} />
        </RowCenteredContainer>
    ) : (
        <TouchableItemFeedback disabled={disabled} onPress={onCheck}>
            <RowCenteredContainer extended={extendedTouchZone}>
                {value ? (
                    <Icon
                        name={toggle ? 'toggle-on' : 'checkbox-checked'}
                        size={smallCheckbox ? Theme.constants.checkboxSmallSize : Theme.constants.checkboxSize}
                        color={disabled ? Theme.colors.checkboxDisabled : checkedColor || Theme.colors.checkbox}
                    />
                ) : (
                    <Icon
                        name={toggle ? 'toggle-off' : 'checkbox-unchecked'}
                        size={smallCheckbox ? Theme.constants.checkboxSmallSize : Theme.constants.checkboxSize}
                        color={props.disabled ? Theme.colors.checkboxDisabled : Theme.colors.checkbox}
                    />
                )}
                {label && (
                    <Label smallCheckbox={smallCheckbox} disabled={disabled}>
                        {label}
                    </Label>
                )}
            </RowCenteredContainer>
        </TouchableItemFeedback>
    )
}

const RowCenteredContainer = styled(View)<{ extended?: boolean }>`
    flex-direction: row;
    align-items: center;
    ${props => props.extended && `padding: ${props.theme.constants.fieldPadding};`}
`
const Label = styled(Text)<{ disabled?: boolean; smallCheckbox?: boolean }>`
    ${props => (props.disabled ? props.theme.fonts.checkboxLabelDisabled : props.theme.fonts.checkboxLabel)}
    margin-left: ${props => props.theme.constants.checkboxLabelPadding};
    text-align: justify;
    ${props => props.smallCheckbox && `font-size: ${Platform.OS === 'web' ? '12px' : '8px'};`}
`
const Loader = styled(ActivityIndicator)`
    color: ${props => props.theme.colors.white};
`
