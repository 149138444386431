import * as React from 'react'

// Components
import { View, Text, Platform } from 'react-native'
import Icon from '@components/icon/Icon'
import Modal from '@components/modal/Modal'
import { TouchableItemFeedback } from '@components/touchable/Touchable'
import Button from '@components/button/Button'

// Store
import useI18n from '@store/i18n/useI18n'

// Style
import styled from '@styles/index'

interface Props {
    propsKey: declarationKey | 'contacts'
}

export default (props: Props) => {
    const { propsKey } = props

    const [infoVisible, setInfoVisible] = React.useState<boolean>(false)

    const i18n = useI18n()
    return (
        <React.Fragment>
            <Modal visible={infoVisible} onRequestClose={() => setInfoVisible(false)} noMinHeight>
                <ModalContent>
                    <ModalText>{i18n.t(`components.planIcp.infos.${propsKey}`)}</ModalText>
                    <ButtonContainer>
                        <Button
                            libelle={i18n.t('components.planIcp.close')}
                            onPress={() => setInfoVisible(false)}
                            status={'active'}
                        />
                    </ButtonContainer>
                </ModalContent>
            </Modal>
            <TouchableItemFeedback onPress={() => setInfoVisible(true)}>
                <InfoIcon>
                    <Icon name="information" size={20} />
                </InfoIcon>
            </TouchableItemFeedback>
        </React.Fragment>
    )
}

const InfoIcon = styled(View)`
    color: ${props => props.theme.colors.infoIcon};
    ${Platform.OS === 'web' && 'cursor: pointer;'}
`
const ModalContent = styled(View)`
    background-color: ${props => props.theme.colors.declarationModalBackground};
    padding: 20px;
    flex-direction: column;
    justify-content: space-between;
`
const ButtonContainer = styled(View)`
    align-items: center;
    margin-top: 25px;
`
const ModalText = styled(Text)`
    ${props => props.theme.fonts.declarationModalInformation}
`
