import * as React from 'react'
import { View, Text } from 'react-native'

import styled from '@styles/index'
import LottieView from 'lottie-react-native'

import RootScreen from '@components/rootScreen/RootScreen'
import useI18n from '@store/i18n/useI18n'

export default () => {
    const i18n = useI18n()

    return (
        <RootScreen status={'fetched'}>
            <TextContainer>
                <TodoText>{i18n.t('screens.todo')}</TodoText>
            </TextContainer>
            <TodoContainer>
                <LottieView source={require('@drawable/todo.json')} autoPlay loop />
            </TodoContainer>
        </RootScreen>
    )
}
const TodoText = styled(Text)`
    font-family: Avenir-Heavy;
    font-size: 20px;
    text-align: center;
`
const TextContainer = styled(View)`
    height: 100px;
    justify-content: center;
    align-items: center;
`
const TodoContainer = styled(View)`
    flex: 1;
    height: 500px;
    align-items: center;
    justify-content: center;
`
