import * as React from 'react'
import { View, Text } from 'react-native'
import styled from '@styles/index'
import Button from '@components/button/Button'
// Store
import Icon from '@components/icon/Icon'
import useTheme from '@styles/useTheme'

export default ({ onPress, iconName, libelle, size }: FloatButtonProps) => {
    const [Theme] = useTheme()

    return (
        <ButtonContainer>
            <Button libelle={libelle || ''} onPress={onPress} status="active" width={250}>
                <ButtonContent>
                    <Icon name={iconName} color={Theme.colors.buttonTitleClassic} size={size || 15} />
                    <ButtonTitle>{libelle}</ButtonTitle>
                </ButtonContent>
            </Button>
        </ButtonContainer>
    )
}

// BUTTON
const ButtonContainer = styled(View)`
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 50px;
    margin-top: 50px;
`
const ButtonContent = styled(View)`
    flex-direction: row;
    align-items: flex-start;
`
const ButtonTitle = styled(Text)`
    ${props => props.theme.fonts.buttonTitle}
    text-transform: uppercase;
    margin-left: 10px;
`
