import * as React from 'react'

// Components
import { Text, View, Platform } from 'react-native'
import Modal from '@components/modal/Modal'
import { Formik, FormikErrors } from 'formik'
import TextInput from '@components/textInput/TextInput'
import Button from '@components/button/Button'
import { TouchableItemFeedback } from '@components/touchable/Touchable'
import ContactSection from '@screens/contacts/ContactSection'

// Utils
import { produce } from 'immer'

// Store
import useI18n from '@store/i18n/useI18n'

// Style
import styled from '@styles/index'

const trimAllValues = (values: IcpContact): IcpContact => ({
    ...values,
    firstName: values.firstName.trim(),
    lastName: values.lastName.trim(),
    email: values.email.trim(),
    telephone1: values.telephone1 ? values.telephone1.trim() : '',
    telephone2: values.telephone2 ? values.telephone2.trim() : '',
    companyName: values.companyName ? values.companyName.trim() : '',
    entityName: values.entityName ? values.entityName.trim() : '',
})

interface Props {
    modalVisible: boolean
    onRequestClose: () => void
    onChange: (contacts: IcpContact[]) => void
    contacts: IcpContact[]
    initialValues?: IcpContact
    globalActors: ActorsIcp
    globalInfos: InformationsIcp
    updateActors: (values: ActorsIcp) => void
}

export default (props: Props) => {
    const {
        modalVisible,
        onRequestClose,
        onChange,
        contacts,
        initialValues,
        globalActors,
        updateActors,
        globalInfos,
    } = props
    const i18n = useI18n()

    const [mode, setMode] = React.useState<'search' | 'add'>('search')
    const [mailInformation, setMailInformation] = React.useState<string | undefined>(undefined)
    const [offlineMode, setOfflineMode] = React.useState<boolean>(false)

    React.useEffect(() => {
        if (!!initialValues) {
            setMode('add')
        }
    }, [initialValues])

    const handleChange = (values: IcpContact, agencyValue?: Agence) => {
        const trimedValues = trimAllValues(values)

        // Ajout du contact dans les représentants de l'ICP
        if (!initialValues) {
            const newRepresentative: Representative | undefined = {
                firstName: trimedValues.firstName,
                lastName: trimedValues.lastName,
                email: trimedValues.email,
                telephone1: trimedValues.telephone1 || '',
                telephone2: trimedValues.telephone2 || '',
                agencyName: trimedValues.companyName
                    ? trimedValues.entityName
                        ? trimedValues.companyName + ' - ' + trimedValues.entityName
                        : trimedValues.companyName
                    : '',
                status: 'Présent',
                approbation: 'notSent',
            }
            const newAgency: IcpAgency | undefined =
                trimedValues.companyName &&
                !globalActors.agencies.find(
                    agency =>
                        agency.name === trimedValues.entityName ||
                        (!trimedValues.entityName && agency.name === trimedValues.companyName),
                )
                    ? {
                          name: trimedValues.entityName || trimedValues.companyName,
                          address: agencyValue ? agencyValue.adresse : '',
                          telephone: agencyValue ? agencyValue.telephone : '',
                          email: agencyValue ? agencyValue.mail : '',
                          siteManagerName: `${trimedValues.firstName} ${trimedValues.lastName}`,
                          siteManagerMobile: trimedValues.telephone1 || '',
                          worksNature: globalInfos.description || '',
                          tools: '',
                          startDate: globalInfos.startDate,
                          endDate: globalInfos.endDate,
                          companyName: trimedValues.companyName,
                      }
                    : undefined

            if (!!newRepresentative || !!newAgency) {
                updateActors(
                    produce(globalActors, newActors => {
                        if (!!newRepresentative) {
                            newActors.representatives = [...globalActors.representatives, newRepresentative]
                        }
                        if (!!newAgency) {
                            newActors.agencies = [...globalActors.agencies, newAgency]
                        }
                    }),
                )
            }
        }

        const index = !!initialValues ? contacts.indexOf(initialValues) : -1
        const result: IcpContact[] =
            !!initialValues && index > -1
                ? produce(contacts, newContacts => {
                      newContacts[index] = trimedValues
                  })
                : [...contacts, trimedValues]
        onChange(result)
        setMode('search')
        onRequestClose()
    }

    const validateForm = React.useMemo(
        () => (values: IcpContact): FormikErrors<IcpContact> => {
            const trimedValues = trimAllValues(values)
            const errors: FormikErrors<IcpContact> = {}

            if (!trimedValues.firstName) {
                errors.firstName = i18n.t('screens.addUserAdminScreen.required')
            }
            if (!trimedValues.lastName) {
                errors.lastName = i18n.t('screens.addUserAdminScreen.required')
            }
            if (
                contacts.find(c => c.email === trimedValues.email) &&
                (!initialValues || trimedValues.email !== initialValues.email)
            ) {
                setMailInformation(i18n.t('components.addIcpContacts.alreadyAdded'))
            } else {
                setMailInformation(undefined)
            }
            if (!trimedValues.email.match(/^[a-z0-9](?:[.+-]?\w+)*@[a-z0-9]+(?:[.-]?\w+)*$/i)) {
                setMailInformation(i18n.t('screens.addUserAdminScreen.emailError'))
            }
            if (!trimedValues.email) {
                errors.email = i18n.t('screens.addUserAdminScreen.required')
            }

            return errors
        },
        [i18n, contacts, initialValues],
    )

    return (
        <Modal visible={modalVisible} onRequestClose={onRequestClose}>
            <ModalContent>
                {!initialValues && (
                    <ModeSelector>
                        {!offlineMode && (
                            <FlexContainer>
                                <TouchableItemFeedback onPress={() => setMode('search')}>
                                    <Mode selected={mode === 'search'}>
                                        <ModeName selected={mode === 'search'}>
                                            {i18n.t(`components.addIcpContacts.modalTitles.search`)}
                                        </ModeName>
                                    </Mode>
                                </TouchableItemFeedback>
                            </FlexContainer>
                        )}
                        <FlexContainer>
                            <TouchableItemFeedback onPress={() => setMode('add')} disabled={offlineMode}>
                                <Mode selected={mode === 'add'}>
                                    <ModeName selected={mode === 'add'}>
                                        {i18n.t(`components.addIcpContacts.modalTitles.add`)}
                                    </ModeName>
                                </Mode>
                            </TouchableItemFeedback>
                        </FlexContainer>
                    </ModeSelector>
                )}
                <Formik
                    initialValues={
                        initialValues ||
                        ({
                            firstName: '',
                            lastName: '',
                            email: '',
                            telephone1: '',
                            telephone2: '',
                            companyName: '',
                            entityName: '',
                            state: 'notSent',
                        } as IcpContact)
                    }
                    onSubmit={v => handleChange(v)}
                    validate={validateForm}
                >
                    {({ errors, values, handleSubmit, isValid, setFieldValue, isSubmitting }) =>
                        mode === 'search' && !offlineMode ? (
                            <ContactSection
                                onContactPress={(contact, company, entity) => {
                                    handleChange(
                                        {
                                            firstName: contact.prenom,
                                            lastName: contact.nom,
                                            email: contact.mail,
                                            telephone1: contact.telephone1,
                                            telephone2: contact.telephone2,
                                            companyName: company ? company.libelle : undefined,
                                            entityName: entity ? entity.libelle : undefined,
                                            state: 'notSent',
                                        },
                                        entity,
                                    )
                                }}
                                contactByPage={3}
                                setOfflineMode={() => {
                                    setMode('add')
                                    setOfflineMode(true)
                                }}
                            />
                        ) : (
                            <NewContactContainer>
                                <ModalRow>
                                    <FlexContainer>
                                        <TextInput
                                            value={values.firstName}
                                            onTextChange={text => setFieldValue('firstName', text)}
                                            label={i18n.t('components.addIcpContacts.fields.firstName')}
                                            required={true}
                                            error={errors ? errors.firstName : ''}
                                            placeholder={i18n.t('components.addIcpContacts.placeholders.firstName')}
                                        />
                                    </FlexContainer>
                                </ModalRow>
                                <ModalRow>
                                    <FlexContainer>
                                        <TextInput
                                            value={values.lastName}
                                            onTextChange={text => setFieldValue('lastName', text)}
                                            label={i18n.t('components.addIcpContacts.fields.lastName')}
                                            required={true}
                                            error={errors ? errors.lastName : ''}
                                            placeholder={i18n.t('components.addIcpContacts.placeholders.lastName')}
                                        />
                                    </FlexContainer>
                                </ModalRow>
                                <ModalRow>
                                    <FlexContainer>
                                        <TextInput
                                            value={values.email}
                                            onTextChange={text => setFieldValue('email', text)}
                                            label={i18n.t('components.addIcpContacts.fields.email')}
                                            required={true}
                                            error={mailInformation}
                                            placeholder={i18n.t('components.addIcpContacts.placeholders.email')}
                                        />
                                    </FlexContainer>
                                </ModalRow>
                                <ModalRow>
                                    <FlexContainer>
                                        <TextInput
                                            value={values.companyName}
                                            onTextChange={text => setFieldValue('companyName', text)}
                                            label={i18n.t('components.addIcpContacts.fields.companyName')}
                                            placeholder={i18n.t('components.addIcpContacts.placeholders.companyName')}
                                        />
                                    </FlexContainer>
                                </ModalRow>
                                <ModalRow>
                                    <FlexContainer>
                                        <TextInput
                                            value={values.entityName}
                                            onTextChange={text => setFieldValue('entityName', text)}
                                            label={i18n.t('components.addIcpContacts.fields.entityName')}
                                            placeholder={i18n.t('components.addIcpContacts.placeholders.entityName')}
                                        />
                                    </FlexContainer>
                                </ModalRow>
                                <ModalRow>
                                    <FlexContainer>
                                        <TextInput
                                            value={values.telephone1}
                                            onTextChange={text => setFieldValue('telephone1', text)}
                                            label={i18n.t('components.addIcpContacts.fields.telephone1')}
                                            placeholder={i18n.t('components.addIcpContacts.placeholders.telephone1')}
                                        />
                                    </FlexContainer>
                                </ModalRow>
                                <ModalRow>
                                    <FlexContainer>
                                        <TextInput
                                            value={values.telephone2}
                                            onTextChange={text => setFieldValue('telephone2', text)}
                                            label={i18n.t('components.addIcpContacts.fields.telephone2')}
                                            placeholder={i18n.t('components.addIcpContacts.placeholders.telephone2')}
                                        />
                                    </FlexContainer>
                                </ModalRow>

                                <ButtonsContainer>
                                    <Button
                                        libelle={i18n.t(
                                            `components.addIcpContacts.${!!initialValues ? 'edit' : 'add'}`,
                                        )}
                                        status={
                                            isSubmitting
                                                ? 'loading'
                                                : isValid && !mailInformation
                                                ? 'active'
                                                : 'disabled'
                                        }
                                        onPress={handleSubmit}
                                    />
                                </ButtonsContainer>
                            </NewContactContainer>
                        )
                    }
                </Formik>
            </ModalContent>
        </Modal>
    )
}

const ModalContent = styled(View)`
    background-color: ${props => props.theme.colors.documentsModalBackground};
    flex: 1;
`
const ModalRow = styled(View)`
    flex-direction: row;
    margin-bottom: 24px;
`
const ButtonsContainer = styled(View)`
    flex-direction: row;
    justify-content: space-evenly;
`
const FlexContainer = styled(View)`
    flex: 1;
`
const ModeSelector = styled(View)`
    flex-direction: row;
    border-radius: 20px;
    margin-bottom: 24px;
    overflow: hidden;
`
const Mode = styled(View)<{ selected: boolean }>`
    height: ${`${Platform.OS === 'web' ? 32 : 24}px`};
    background-color: ${props =>
        props.selected ? props.theme.colors.icpContactModeSelected : props.theme.colors.icpContactMode};
    align-items: center;
    justify-content: center;
`
const ModeName = styled(Text)<{ selected: boolean }>`
    ${props => props.theme.fonts.icpContactName}
    color: ${props =>
        !props.selected ? props.theme.colors.icpContactModeSelected : props.theme.colors.icpContactMode};
`
const NewContactContainer = styled(View)`
    padding: 5px;
`
