import * as React from 'react'

// Components
import { Text, View } from 'react-native'
import Modal from '@components/modal/Modal'
import { Formik } from 'formik'
import TextInput from '@components/textInput/TextInput'
import Button from '@components/button/Button'
import { confirmAlert, basicAlert } from '@components/alert/Alerts'
import PdfDropInput from '@components/pdfDropInput/pdfDropInput'

// Api
import api from '@app/api/api'

// Utils
import * as yup from 'yup'
import Logger from '@utils/logger'

// Store
import useI18n from '@store/i18n/useI18n'

// Style
import styled from '@styles/index'
import useTheme from '@styles/useTheme'

const documentYupSchema = (i18n: I18nState) =>
    yup.object().shape({
        name: yup.string().required(i18n.t('screens.editModeleAdminScreen.required')),
        link: yup.string().required(i18n.t('screens.editModeleAdminScreen.required')),
    })

const trimAllValues = (values: AddedDocument): AddedDocument => ({
    index: values.index,
    name: values.name.trim(),
    description: !!values.description ? values.description.trim() : undefined,
    link: values.link,
})

const i18nScreenKey = 'components.editDocumentModal'

interface Props {
    modalState: 'add' | 'edit' | 'closed'
    initialValues: AddedDocument
    document?: AdminDocument
    onRequestClose: () => void
    onDocumentEdited: () => void
}

export default (props: Props) => {
    const { modalState, onRequestClose, initialValues, document, onDocumentEdited } = props

    const i18n = useI18n()
    const [Theme] = useTheme()

    const handleSubmit = (values: AddedDocument, setSubmitting: (isSubmitting: boolean) => void) => {
        const actionKey = modalState === 'add' ? 'Add' : 'Edit'
        confirmAlert(
            i18n,
            i18nScreenKey,
            actionKey,
            () =>
                (modalState === 'add'
                    ? api.admin.addDocument(trimAllValues(values))
                    : !!document
                    ? api.admin.editDocument({ ...trimAllValues(values), id: document.id }, document.id)
                    : Promise.reject()
                )
                    .then(() =>
                        basicAlert(
                            i18n,
                            i18nScreenKey,
                            actionKey,
                            () => {
                                setSubmitting(false)
                                onRequestClose()
                                onDocumentEdited()
                            },
                            'success',
                            Theme,
                        ),
                    )
                    .catch(err => {
                        basicAlert(i18n, i18nScreenKey, actionKey, () => setSubmitting(false), 'error', Theme)
                        Logger.error(err)
                    }),

            () => setSubmitting(false),
            Theme,
        )
    }

    const onDelete = () =>
        confirmAlert(
            i18n,
            i18nScreenKey,
            'Delete',
            () =>
                (!!document ? api.admin.deleteDocument(document.id) : Promise.reject())
                    .then(() =>
                        basicAlert(
                            i18n,
                            i18nScreenKey,
                            'Delete',
                            () => {
                                onRequestClose()
                                onDocumentEdited()
                            },
                            'success',
                            Theme,
                        ),
                    )
                    .catch(err => {
                        basicAlert(i18n, i18nScreenKey, 'Delete', () => undefined, 'error', Theme)
                        Logger.error(err)
                    }),

            () => undefined,
            Theme,
        )

    return (
        <Modal visible={modalState !== 'closed'} onRequestClose={onRequestClose}>
            <ModalContent>
                <Formik
                    initialValues={initialValues}
                    onSubmit={(values, { setSubmitting }) => handleSubmit(values, setSubmitting)}
                    validationSchema={documentYupSchema(i18n)}
                >
                    {({ errors, values, handleSubmit, isValid, setFieldValue, isSubmitting }) => (
                        <View>
                            <ModalTitle>{i18n.t(`screens.documentsAdminScreen.${modalState}Title`)}</ModalTitle>
                            <ModalRow>
                                <FlexContainer>
                                    <TextInput
                                        value={values.name}
                                        onTextChange={text => setFieldValue('name', text)}
                                        label={i18n.t('screens.documentsAdminScreen.name')}
                                        required={true}
                                        error={errors ? errors.name : ''}
                                    />
                                </FlexContainer>
                            </ModalRow>
                            <ModalRow>
                                <FlexContainer>
                                    <TextInput
                                        numberOfLines={2}
                                        multiline={true}
                                        value={values.description}
                                        onTextChange={text => setFieldValue('description', text)}
                                        label={i18n.t('screens.documentsAdminScreen.description')}
                                    />
                                </FlexContainer>
                            </ModalRow>
                            <ModalRow>
                                <FlexContainer>
                                    <PdfDropInput
                                        values={!!values.link ? [values.link] : []}
                                        onChange={newPdf => setFieldValue('link', newPdf.length > 0 ? newPdf[0] : '')}
                                        label={i18n.t('screens.documentsAdminScreen.uploadedDocument')}
                                        error={errors ? errors.link : ''}
                                        required={true}
                                    />
                                </FlexContainer>
                            </ModalRow>
                            <ButtonsContainer>
                                {modalState === 'edit' && (
                                    <Button
                                        libelle={i18n.t('screens.documentsAdminScreen.delete')}
                                        status={'active'}
                                        onPress={onDelete}
                                    />
                                )}
                                <Button
                                    libelle={i18n.t('screens.documentsAdminScreen.end')}
                                    status={isSubmitting ? 'loading' : isValid ? 'active' : 'disabled'}
                                    onPress={handleSubmit}
                                />
                            </ButtonsContainer>
                        </View>
                    )}
                </Formik>
            </ModalContent>
        </Modal>
    )
}

const ModalContent = styled(View)`
    width: 800px;
    background-color: ${props => props.theme.colors.documentsModalBackground};
    padding: 24px;
`
const ModalTitle = styled(Text)`
    ${props => props.theme.fonts.homeListTitle}
    text-transform: uppercase;
    color: ${props => props.theme.colors.documentsModalTitle};
    margin-bottom: 24px;
`
const ModalRow = styled(View)`
    flex-direction: row;
    margin-bottom: 24px;
`
const ButtonsContainer = styled(View)`
    flex-direction: row;
    justify-content: space-evenly;
`
const FlexContainer = styled(View)`
    flex: 1;
`
