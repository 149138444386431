import * as React from 'react'
import { Text, View, ImageBackground, ScrollView } from 'react-native'
import styled from '@styles/index'
import Button from '@components/button/Button'
import useI18n from '@store/i18n/useI18n'
import useNavigation from '@layout/useNavigation'
import useTheme from '@styles/useTheme'

interface Props {
    servicesAccountFailed?: boolean
}

export default ({ servicesAccountFailed }: Props) => {
    const i18n = useI18n()
    const navigation = useNavigation()
    const [Theme] = useTheme()

    const [windowHeight, setWindowHeight] = React.useState<number>(window.innerHeight)

    React.useLayoutEffect(() => {
        const updateHeight = () => setWindowHeight(window.innerHeight)
        window.addEventListener('resize', updateHeight)
        return () => window.removeEventListener('resize', updateHeight)
    }, [setWindowHeight])

    return (
        <Container source={{ uri: require('@screens/login/HomePicture.png') }} windowHeight={windowHeight}>
            <Background windowHeight={windowHeight} />
            <LiseretContainer windowHeight={windowHeight}>
                <InformationContainer>
                    <AppTextWelcomeContainer>
                        <ApplicationWelcome>
                            {servicesAccountFailed
                                ? i18n.t('screens.servicesAccountConnection.title_failure')
                                : i18n.t('screens.servicesAccountConnection.title_success')}
                        </ApplicationWelcome>
                    </AppTextWelcomeContainer>
                    <TextContainer>
                        <DescriptionContainer windowHeight={windowHeight}>
                            <ScrollView>
                                <Value>
                                    {servicesAccountFailed
                                        ? i18n.t('screens.servicesAccountConnection.description_failure')
                                        : i18n.t('screens.servicesAccountConnection.description_success')}
                                </Value>
                            </ScrollView>
                        </DescriptionContainer>
                    </TextContainer>
                    <AppButtonContainer>
                        <ButtonContainer>
                            <Button
                                libelle={`${i18n.t('screens.servicesAccountConnection.backButton')}`}
                                onPress={() => navigation.push('/')}
                                width={250}
                                status="active"
                                buttonColor={
                                    servicesAccountFailed
                                        ? Theme.colors.defaultCancelButton
                                        : Theme.colors.defaultValidateButton
                                }
                            />
                        </ButtonContainer>
                        {servicesAccountFailed && (
                            <ButtonContainer>
                                <Button
                                    libelle={`${i18n.t('screens.servicesAccountConnection.tryAgain')}`}
                                    onPress={() => navigation.push('/token/o365')}
                                    width={250}
                                    status="active"
                                    buttonColor={Theme.colors.defaultValidateButton}
                                />
                            </ButtonContainer>
                        )}
                    </AppButtonContainer>
                </InformationContainer>
            </LiseretContainer>
        </Container>
    )
}
const Background = styled(View)<{ windowHeight: number }>`
    position: absolute;
    height: ${props => props.windowHeight}px;
    width: 100%;
    background-color: ${props => props.theme.colors.webLoginBackground};
`
const Container = styled(ImageBackground)<{ windowHeight: number }>`
    height: ${props => props.windowHeight}px;
    background-color: transparent;
`
const LiseretContainer = styled(View)<{ windowHeight: number }>`
    flex-direction: row;
    height: ${props => props.windowHeight * 0.2}px;
    min-height: 200px;
    background-color: ${props => props.theme.colors.webLoginLiseretBackground};
    margin-top: ${props => props.windowHeight * 0.4}px;
    elevation: 3px;
    box-shadow: 1px 1px 10px ${props => props.theme.colors.webBoxShadow};
    padding-top: 40px;
    padding-bottom: 40px;
`
const InformationContainer = styled(View)`
    flex: 1;
    justify-content: space-around;
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;
`
const AppTextWelcomeContainer = styled(View)`
    align-items: center;
    align-content: center;
    justify-content: center;
    height: ${props => props.theme.constants.webLoginAppTextHeight}px;
    flex-wrap: wrap;
    flex: 1;
`
const AppButtonContainer = styled(View)`
    align-items: center;
    align-content: center;
    justify-content: space-around;
    height: ${props => props.theme.constants.webLoginAppTextHeight}px;
    flex-wrap: wrap;
    flex-direction: row;
    flex: 1;
`
const ApplicationWelcome = styled(Text)`
    ${props => props.theme.fonts.webAppName}
`
const ButtonContainer = styled(View)`
    min-width: 300px;
    align-items: center;
    margin-bottom: 5px;
`
const Value = styled(Text)<{ color?: string; minHeight?: number }>`
    ${props => props.theme.fonts.inputText}
    color:  ${props => props.theme.colors.inputText};
    margin-right: 10px; 
    text-align: center;
    font-size: 15px;
`
const TextContainer = styled(View)`
    flex: 3;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
`
const DescriptionContainer = styled(View)<{ windowHeight: number }>`
    min-width: 100%;
    flex: 1;
    justify-content: center;
    align-items: center;
    height: ${props => props.windowHeight * 0.1}px;
`
