import * as React from 'react'

// Components
import { View, Text } from 'react-native'
import FieldInformation from '@components/fieldInformation/FieldInformation'
import { TouchableItemFeedback } from '@components/touchable/Touchable'
import Icon from '@components/icon/Icon'

// Store
import useI18n from '@store/i18n/useI18n'

// Style
import styled from '@styles/index'
import useTheme from '@styles/useTheme'

interface Props {
    values: IcpAgency[]
    label?: string
}

export default (props: Props) => {
    const { values, label } = props

    const i18n = useI18n()
    const [Theme] = useTheme()
    const [itemOpened, setItemOpened] = React.useState<number | undefined>(undefined)

    const renderField = (itemKey: string, value?: string) => (
        <SimpleRow key={itemKey}>
            <Label>{`${i18n.t(`components.addIcpAgencies.fields.${itemKey}`)} :`}</Label>
            <Value>{value || i18n.t('components.addIcpAgencies.noValue')}</Value>
        </SimpleRow>
    )

    return (
        <View>
            <TopContainer>
                <Flexbox>
                    <FieldInformation label={label} />
                </Flexbox>
            </TopContainer>

            <Container>
                <RowContainer>
                    <Flexbox>
                        <Legend>{i18n.t('components.addIcpAgencies.name')}</Legend>
                    </Flexbox>
                    <Flexbox>
                        <Legend>{i18n.t('components.addIcpAgencies.worksNature')}</Legend>
                    </Flexbox>
                </RowContainer>
                <AgencyList>
                    {values.length === 0 ? (
                        <NoAgency>{i18n.t('components.addIcpAgencies.noAgency')}</NoAgency>
                    ) : (
                        values.map((agency, index) => (
                            <TouchableItemFeedback
                                key={`agency${index}`}
                                onPress={() => setItemOpened(itemOpened === index ? undefined : index)}
                            >
                                <AgencyContainer odd={index % 2 === 1}>
                                    <RowContainer>
                                        <Flexbox>
                                            <AgencyName ellipsizeMode={'tail'} numberOfLines={2}>
                                                {agency.name}
                                            </AgencyName>
                                        </Flexbox>
                                        <Flexbox>
                                            <AlignedRow>
                                                <Flexbox>
                                                    <AgencyWorksNature ellipsizeMode={'tail'} numberOfLines={2}>
                                                        {agency.worksNature}
                                                    </AgencyWorksNature>
                                                </Flexbox>
                                                <Icon
                                                    name={'information'}
                                                    size={Theme.constants.riskFamilyInformationIconSize}
                                                    color={
                                                        itemOpened === index
                                                            ? Theme.colors.riskFamilyItemInformationSelectedIcon
                                                            : Theme.colors.riskFamilyItemInformationIcon
                                                    }
                                                />
                                            </AlignedRow>
                                        </Flexbox>
                                    </RowContainer>
                                    {itemOpened === index && (
                                        <AgencyInformations>
                                            <SectionTitle>
                                                {i18n.t('components.addIcpAgencies.modalTitles.coordinates')}
                                            </SectionTitle>
                                            <Section>
                                                {([
                                                    'address',
                                                    'telephone',
                                                    'email',
                                                    'siteManagerName',
                                                    'siteManagerMobile',
                                                ] as IcpAgencyStringFieldName[]).map(key =>
                                                    renderField(key, agency[key]),
                                                )}
                                            </Section>
                                            <SectionTitle>
                                                {i18n.t('components.addIcpAgencies.modalTitles.works')}
                                            </SectionTitle>
                                            <Section>
                                                {(['worksNature', 'tools'] as IcpAgencyStringFieldName[]).map(key =>
                                                    renderField(key, agency[key]),
                                                )}
                                                {renderField(
                                                    'maxStaff',
                                                    agency.maxStaff ? agency.maxStaff.toString() : undefined,
                                                )}
                                            </Section>
                                            <SectionTitle>
                                                {i18n.t('components.addIcpAgencies.modalTitles.dates')}
                                            </SectionTitle>
                                            <Section>
                                                {agency.startDate
                                                    ? renderField(
                                                          'startDate',
                                                          i18n.t('components.datePicker.dateTime', {
                                                              date: new Date(agency.startDate),
                                                          }),
                                                      )
                                                    : i18n.t('components.datePicker.noDate')}
                                                {agency.endDate
                                                    ? renderField(
                                                          'endDate',
                                                          i18n.t('components.datePicker.dateTime', {
                                                              date: new Date(agency.endDate),
                                                          }),
                                                      )
                                                    : i18n.t('components.datePicker.noDate')}
                                            </Section>
                                            <SectionTitle>
                                                {i18n.t('components.addIcpAgencies.fields.comment')}
                                            </SectionTitle>
                                            <Section>
                                                <Value>
                                                    {agency.comment || i18n.t('components.addIcpAgencies.noValue')}
                                                </Value>
                                            </Section>
                                        </AgencyInformations>
                                    )}
                                </AgencyContainer>
                            </TouchableItemFeedback>
                        ))
                    )}
                </AgencyList>
            </Container>
        </View>
    )
}

const AgencyContainer = styled(View)<{ odd?: boolean }>`
    background-color: ${props => !props.odd && props.theme.colors.evenLines};
`
const Container = styled(View)`
    background-color: ${props => props.theme.colors.addIcpContactsBackground};
    padding-right: 8px;
    padding-left: 8px;
`
const RowContainer = styled(View)`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: ${props => props.theme.constants.fieldPadding};
    padding-left: ${props => props.theme.constants.fieldPadding};
    min-height: 36px;
`
const Legend = styled(Text)`
    ${props => props.theme.fonts.rightLegend}
    text-transform: uppercase;
`
const AgencyList = styled(View)`
    padding-bottom: 8px;
`
const NoAgency = styled(Text)`
    ${props => props.theme.fonts.perimeterName}
    text-align: center;
    padding-top: 24px;
    padding-bottom: 24px;
`
const AgencyName = styled(Text)`
    ${props => props.theme.fonts.icpContactName}
`
const AgencyWorksNature = styled(Text)`
    ${props => props.theme.fonts.icpAgencyWorksNature}
`
const Flexbox = styled(View)`
    flex: 1;
`
const TopContainer = styled(View)`
    flex-direction: row;
    padding-right: ${props => props.theme.constants.fieldPadding};
`
const AlignedRow = styled(View)`
    flex-direction: row;
    align-items: center;
`
const AgencyInformations = styled(View)`
    padding: 24px;
`
const SectionTitle = styled(Text)`
    margin-bottom: 10px;
    ${props => props.theme.fonts.icpContactName}
`
const Section = styled(View)`
    padding-right: 12px;
    padding-left: 12px;
    margin-bottom: 12px;
`
const SimpleRow = styled(View)`
    flex-direction: row;
`
const Label = styled(Text)`
    flex: 1;
    ${props => props.theme.fonts.icpAgencyLabel}
    margin-bottom: 5px;
    margin-right: 5px;
`
const Value = styled(Text)`
    flex: 2;
    ${props => props.theme.fonts.icpAgencyValue}
    margin-bottom: 5px;
`
