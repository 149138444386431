import ApprobationScreen from '../screens/approbation/ApprobationScreen.web'
import Connexion from '../screens/login/Login'
import ServicesAccountConnection from '../screens/servicesAccountConnection/ServicesAccountConnection'

const routesPubliques = [
    {
        name: 'approbation',
        path: '/approbation',
        component: ApprobationScreen,
    },
    {
        name: 'authentification',
        path: '/authentification',
        component: Connexion,
    },
    {
        name: 'success_token',
        path: '/success_token',
        component: ServicesAccountConnection,
        params: { servicesAccountFailed: false },
    },
    {
        name: 'failure_token',
        path: '/failure_token',
        component: ServicesAccountConnection,
        params: { servicesAccountFailed: true },
    },
]

export default routesPubliques
