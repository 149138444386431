import React from 'react'

// Components
import { View, Text } from 'react-native'
import { TouchableItemFeedback } from '@components/touchable/Touchable'
import Icon from '@components/icon/Icon'
import Dropzone from 'react-dropzone'
import { basicAlert } from '@components/alert/Alerts'
import FieldInformation from '@components/fieldInformation/FieldInformation'

// Utils
import { openPdfInTab } from '@utils/pdfCommonUtils'

// Store
import useI18n from '@store/i18n/useI18n'

// Style
import styled from '@styles/index'
import useTheme from '@styles/useTheme'

const DEFAULT_MAX_FILES = 1
const MAX_PDF_SIZE = 10485760 // 10 Mo

export default (props: PdfDropInputProps) => {
    const { onChange, values, error, maxFiles, label, required } = props
    const i18n = useI18n()
    const [Theme] = useTheme()

    const onDropError = (oversized?: boolean) =>
        oversized
            ? basicAlert(i18n, 'components.imageDropInput.oversized', 'Drop', () => undefined, 'error', Theme)
            : basicAlert(i18n, 'components.imageDropInput', 'Drop', () => undefined, 'error', Theme)

    const onDropAccepted = (files: File[]) => {
        const reader = new FileReader()
        reader.readAsDataURL(files[0])
        reader.onloadend = () => {
            if (!!reader.result) {
                const datas64 = reader.result.toString().split('data:application/pdf;base64,')
                onChange([...values, datas64[1]])
            } else {
                onDropError()
            }
        }
        reader.onerror = err => onDropError()
    }

    return (
        <Container>
            {!!label && <FieldInformation label={label} error={error} required={required} />}
            {values.length > 0 && (
                <FileContainer>
                    {values.map((pdfSrc, index) => (
                        <Line key={`pdf${index}`}>
                            <TouchableItemFeedback onPress={() => openPdfInTab(pdfSrc)}>
                                <PdfFile>{i18n.t('components.pdfDropInput.pdfFile', { count: index + 1 })}</PdfFile>
                            </TouchableItemFeedback>
                            <TouchableItemFeedback onPress={() => onChange(values.filter((value, i) => i !== index))}>
                                <Action>{i18n.t('components.pdfDropInput.delete')}</Action>
                            </TouchableItemFeedback>
                        </Line>
                    ))}
                </FileContainer>
            )}

            {values.length < (maxFiles || DEFAULT_MAX_FILES) && (
                <RowContainer>
                    <Dropzone
                        maxSize={MAX_PDF_SIZE}
                        accept={'.pdf'}
                        onDropAccepted={acceptedFiles => onDropAccepted(acceptedFiles)}
                        onDropRejected={(files, evt) => onDropError(files[0].size > MAX_PDF_SIZE)}
                        multiple={false}
                    >
                        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
                            <View>
                                {!!error && !label && <Error>{error}</Error>}
                                <DropZoneContent
                                    {...getRootProps()}
                                    isDragActive={isDragActive}
                                    isDragReject={isDragReject}
                                    error={!!error}
                                >
                                    <input {...getInputProps()} />
                                    <IconContainer>
                                        <Icon name={'cloud-upload'} size={50} color={Theme.colors.uploadIconColor} />
                                    </IconContainer>
                                </DropZoneContent>
                            </View>
                        )}
                    </Dropzone>
                </RowContainer>
            )}
        </Container>
    )
}

const Container = styled(View)``
const Line = styled(View)`
    flex-direction: row;
    align-items: center;
`

const DropZoneContent = styled.div<{
    isDragActive: boolean
    isDragReject: boolean
    error: boolean
}>`
    background-color: ${props => props.theme.colors.imageDropInputBackground};
    ${props => props.error && 'border: ' + props.theme.colors.textInputBorderError + ' solid 1px;'}
    ${props =>
        props.isDragActive &&
        `box-shadow: 0px 0px 0px 5px ${
            props.isDragReject ? props.theme.colors.imageDropInputReject : props.theme.colors.imageDropInputAccept
        } inset;`};
    cursor: pointer;
`
const IconContainer = styled(View)`
    justify-content: center;
    align-items: center;
    height: ${props => props.theme.constants.formImageInputPictureSize};
    width: ${props => props.theme.constants.formImageInputPictureSize};
`
const Error = styled(Text)`
    ${props => props.theme.fonts.fieldError}
    padding-bottom: 8px;
    padding-right: ${props => props.theme.constants.fieldPadding};
`
const PdfFile = styled(Text)`
    margin-right: 50px;
    ${props => props.theme.fonts.pdfFile}
    &:hover {
        cursor: pointer;
        text-decoration: underline;
        text-decoration-color: ${props => props.theme.fonts.homeActionLink.color};
        color: ${props => props.theme.fonts.homeActionLink.color};
    }
`
const RowContainer = styled(View)`
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: -8px;
`
const FileContainer = styled(View)`
    padding-left: ${props => props.theme.constants.fieldPadding};
    padding-right: ${props => props.theme.constants.fieldPadding};
    margin-bottom: 12px;
`
const Action = styled(Text)`
    ${props => props.theme.fonts.homeActionLink}
    text-align: right;
    text-decoration: underline;
    text-decoration-color: ${props => props.theme.fonts.homeActionLink.color};
`
