import * as React from 'react'

const useNavigationFocus = (navigation: Navigation) => {
    const [focused, setFocused] = React.useState(false)

    const willMount = React.useRef(true)
    if (willMount.current) {
        willMount.current = false
        !!navigation.addListener &&
            navigation.addListener('focus', () => {
                setFocused(true)
            })
    }

    React.useEffect(() => {
        if (!!navigation.addListener) {
            const removeDidFocusSubscription = navigation.addListener('focus', () => {
                setFocused(true)
            })
            const removeWillBlurSubscription = navigation.addListener('blur', () => {
                setFocused(false)
            })

            return () => {
                if (removeDidFocusSubscription) {
                    removeDidFocusSubscription()
                }
                if (removeWillBlurSubscription) {
                    removeWillBlurSubscription()
                }
            }
        }
    }, [focused, navigation])
    return focused
}

const useVisible = (navigation: Navigation) => {
    const navigationFocus = useNavigationFocus(navigation)

    return navigationFocus
}

export default useVisible
