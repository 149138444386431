import * as React from 'react'

// Components
import { Text, View, Platform } from 'react-native'
import TextInput from '@components/textInput/TextInput'
import Picker from '@components/picker/Picker'
import Checkboxes from '@components/checkbox/Checkboxes'
import ImagesInput from '@components/imageDropInput/ImageFormInput'
import SignaturePad from '@components/signaturePad/SignaturePad'
import DatePicker from '@components/datePicker/DatePicker'
import AddIcpRepresentatives from '@components/addIcpRepresentatives/AddIcpRepresentatives'

// Store
import useI18n from '@store/i18n/useI18n'

// Style
import styled from '@styles/index'

interface Props {
    index: number
    isLast: boolean
    question: Question
    values: any
    errors?: any
    onAnswerChange: (value: any) => void
    redacteurs?: UserRedacteur[]
    disabled?: boolean
}

export default (props: Props) => {
    const { question, errors, index, isLast, values, onAnswerChange, redacteurs, disabled } = props

    const i18n = useI18n()

    const renderField = (questionType: TypeQuestion, questionError: string | undefined, questionOptions: string[]) => {
        switch (questionType) {
            case 'textInputAlpha':
                return (
                    <TextInput
                        multiline
                        numberOfLines={5}
                        value={values[0]}
                        onTextChange={text => onAnswerChange([text])}
                        placeholder={i18n.t('components.answerQuestionSection.placeholders.textInputAlpha')}
                        error={questionError}
                        disabled={disabled}
                    />
                )

            case 'textInputNum':
                return (
                    <TextInput
                        keyboardType="numeric"
                        numberOfLines={1}
                        value={values[0]}
                        onTextChange={text => onAnswerChange([text])}
                        placeholder={i18n.t('components.answerQuestionSection.placeholders.textInputNum')}
                        error={questionError}
                        disabled={disabled}
                    />
                )
            case 'select':
                return (
                    <Picker
                        data={questionOptions}
                        value={values[0]}
                        onChange={optionIndex =>
                            onAnswerChange([optionIndex === -1 ? '' : questionOptions[optionIndex]])
                        }
                        isClearable={true}
                        error={questionError}
                        disabled={disabled}
                    />
                )

            case 'checkboxes':
            case 'radio':
                return (
                    <Checkboxes
                        labels={questionOptions}
                        values={values}
                        onChange={newValues => onAnswerChange(newValues)}
                        error={questionError}
                        radio={questionType === 'radio'}
                        disabled={disabled}
                    />
                )

            case 'pictures':
                return (
                    <ImagesInput
                        values={values}
                        onChange={newImages => onAnswerChange(newImages)}
                        error={questionError}
                        disabled={disabled}
                    />
                )
            case 'drawing':
                return (
                    <SignaturePad
                        value={values[0]}
                        onChange={newValue => onAnswerChange([newValue])}
                        error={questionError}
                        disabled={disabled}
                    />
                )
            case 'date':
                return (
                    <DatePicker
                        value={!!values[0] ? new Date(values[0]) : undefined}
                        onChange={newValue => onAnswerChange([!!newValue ? newValue.toString() : ''])}
                        error={questionError}
                        time
                        disabled={disabled}
                    />
                )
            case 'internal_representative':
                return (
                    <AddIcpRepresentatives
                        label={i18n.t('components.actorsIcp.labels.representatives')}
                        values={values as Representative[]}
                        onChange={newValue => onAnswerChange(newValue)}
                        redacteurs={redacteurs}
                    />
                )
            case 'extern_representative':
                return (
                    <AddIcpRepresentatives
                        label={i18n.t('components.actorsIcp.labels.representatives')}
                        values={values as Representative[]}
                        onChange={newValue => onAnswerChange(newValue)}
                    />
                )
            default:
                return <FieldValue>{i18n.t('components.answerQuestionSection.unknownType')}</FieldValue>
        }
    }

    return (
        <QuestionContainer zIndex={-index} isLast={isLast}>
            <QuestionField>
                <FieldValue title={true}>{question.title}</FieldValue>

                <RequiredFieldValue required={question.required}>
                    {i18n.t(`components.editQuestionSection.titles.${question.required ? 'required' : 'optional'}`)}
                </RequiredFieldValue>
            </QuestionField>

            {!!question.description && (
                <QuestionField>
                    <FieldValue>{question.description}</FieldValue>
                </QuestionField>
            )}

            {renderField(question.type, !!errors && errors.length > 0 ? errors[0] : undefined, question.options || [])}
        </QuestionContainer>
    )
}

const QuestionContainer = styled(View)<{ zIndex?: number; isLast: boolean }>`
    padding-left: ${Platform.OS === 'web' ? '24' : '10'}px;
    padding-right: ${Platform.OS === 'web' ? '24' : '10'}px;
    padding-top: ${Platform.OS === 'web' ? '24' : '10'}px;
    padding-bottom: ${Platform.OS === 'web' ? '24' : '10'}px;
    background-color: ${props => props.theme.colors.questionListBackground};
    ${props => !props.isLast && `margin-bottom: ${Platform.OS === 'web' ? '24' : '10'}px;`}
    ${props => props.zIndex && `z-index: ${props.zIndex};`}
`
const QuestionField = styled(View)<{ isCheckBox?: boolean; zIndex?: number }>`
    margin-bottom: ${Platform.OS === 'web' ? '24' : '10'}px;
    ${props => props.zIndex && `z-index: ${props.zIndex};`}
`
const FieldValue = styled(Text)<{ title?: boolean }>`
    ${props => props.theme.fonts.inputText}
    font-size: 14px;
    ${props => props.title && `font-weight: bold;`}
`
const RequiredFieldValue = styled(Text)<{ required?: boolean }>`
    ${props => props.theme.fonts.inputText}
    font-size: 12px;
    ${props => props.required && `color: ${props.theme.colors.questionRequired};`}
`
