import * as React from 'react'

// Components
import { View, Text, Image } from 'react-native'
import { TouchableItemFeedback } from '@components/touchable/Touchable'
import Button from '@components/button/Button'
import RootScreen from '@components/rootScreen/RootScreen'

// Store
import useI18n from '@store/i18n/useI18n'

// Utils
import Logger from '@utils/logger'
import useNavigation from '@layout/useNavigation'

// Api
import api from '@api/api'

// Style
import styled from '@styles/index'

export default () => {
    const i18n = useI18n()
    const navigation = useNavigation()

    const [status, setStatus] = React.useState<Status>('loading')
    const [riskFamilies, setRiskFamilies] = React.useState<Families | undefined>(undefined)

    React.useEffect(() => {
        api.admin
            .getAllRiskFamilies()
            .then(families => {
                if (!!families) {
                    setRiskFamilies(families)
                    setStatus('fetched')
                } else {
                    setStatus('error')
                }
            })
            .catch(err => {
                Logger.error(err)
                setStatus('error')
            })
    }, [])

    return (
        <RootScreen status={status} scrollable>
            {!!riskFamilies && (
                <Container>
                    <ListTitleContainer>
                        <ListTitle numberOfLines={2} ellipsizeMode={'tail'}>
                            {i18n.t('screens.riskFamiliesAdminScreen.riskFamilies')}
                        </ListTitle>
                        <Button
                            libelle={i18n.t('screens.riskFamiliesAdminScreen.createFamily')}
                            onPress={() => navigation.push('/ajouterFamilleDeRisque')}
                            status="active"
                        />
                    </ListTitleContainer>
                    <FamiliesContainer>
                        <LegendLine>
                            <ImageContainer>
                                <Legend>{i18n.t('screens.riskFamiliesAdminScreen.image')}</Legend>
                            </ImageContainer>
                            <Legend>{i18n.t('screens.riskFamiliesAdminScreen.familyName')}</Legend>
                        </LegendLine>

                        {riskFamilies.length === 0 ? (
                            <NoFamilyMessage>{i18n.t('screens.riskFamiliesAdminScreen.noFamily')}</NoFamilyMessage>
                        ) : (
                            riskFamilies.map((family, index) => {
                                return (
                                    <TouchableItemFeedback
                                        key={`riskFamily${index}`}
                                        onPress={() =>
                                            navigation.push('/gestionDesRisques/:riskFamilyId', {
                                                pathParams: { riskFamilyId: family.id },
                                            })
                                        }
                                    >
                                        <FamilyLine odd={index % 2 === 1}>
                                            <ImageContainer>
                                                <FamilyImage
                                                    mandatory={family.mandatory}
                                                    source={{
                                                        uri: family.picto,
                                                    }}
                                                />
                                            </ImageContainer>

                                            <FamilyName numberOfLines={2} ellipsizeMode={'tail'}>
                                                {family.libelle}
                                            </FamilyName>
                                            <Action>{i18n.t('screens.riskFamiliesAdminScreen.handleFamily')}</Action>
                                        </FamilyLine>
                                    </TouchableItemFeedback>
                                )
                            })
                        )}
                    </FamiliesContainer>
                </Container>
            )}
        </RootScreen>
    )
}

const Container = styled(View)`
    background-color: ${props => props.theme.colors.rolesBackground};
`
const FamiliesContainer = styled(View)`
    padding-top: 24px;
    padding-bottom: 24px;
    padding-left: 14px;
    padding-right: 14px;
`
const FamilyLine = styled(View)<{ odd: boolean }>`
    flex-direction: row;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
    height: ${props => props.theme.constants.rightLineHeight}px;
    background-color: ${props => !props.odd && props.theme.colors.evenLines};

    &:hover {
        background-color: ${props => props.theme.colors.hoverBackground};
        & > div {
            color: ${props => props.theme.colors.hoverText};
            & > div {
                color: ${props => props.theme.colors.hoverText};
            }
        }
    }
`
const FamilyName = styled(Text)`
    flex: 1;
    ${props => props.theme.fonts.rightTitle}
`
const LegendLine = styled(View)`
    flex-direction: row;
    height: 34px;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
`
const Legend = styled(Text)`
    ${props => props.theme.fonts.rightLegend}
    text-transform: uppercase;
`
const ListTitleContainer = styled(View)`
    flex-direction: row;
    height: ${props => props.theme.constants.riskFamiliesListTitleContainerHeight}px;
    justify-content: space-between;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;

    border-bottom-color: ${props => props.theme.colors.homeListBorderColor};
    border-bottom-width: 2px;
`
const ListTitle = styled(Text)`
    ${props => props.theme.fonts.homeListTitle}
    text-transform: uppercase;
`
const ImageContainer = styled(View)`
    width: 75px;
`
const Action = styled(Text)`
    ${props => props.theme.fonts.homeActionLink}
    text-decoration: underline;
`
const NoFamilyMessage = styled(Text)`
    ${props => props.theme.fonts.perimeterName}
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
`
const FamilyImage = styled(Image)<{ mandatory?: boolean }>`
    height: ${props => props.theme.constants.riskFamiliesListImageSize}px;
    width: ${props => props.theme.constants.riskFamiliesListImageSize}px;
    border: ${props => props.mandatory && `0px solid ${props.theme.colors.riskFamilyItemMandatory}`};
    border-left-width: ${props => props.mandatory && '5px'};
    border-radius: ${props => props.mandatory && '5px'};
`
