import * as React from 'react'
import useReducer from '@store/useReducer'
import * as CustomInterfaceStore from '@store/customInterfaceStore'

import theme from './theme'

const useTheme = () => {
    const mainColor = useReducer(CustomInterfaceStore.store, s => s.mainColor)
    const colors = React.useMemo(() => theme.functionColors(mainColor), [mainColor])
    const Theme: Theme = React.useMemo(() => {
        return {
            colors,
            fonts: theme.functionFonts(colors),
            constants: theme.constants,
        }
    }, [colors])

    return [Theme] as const
}
export default useTheme
