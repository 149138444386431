export type TypeLogLevel = 'debug' | 'info' | 'warn' | 'error'

let logLevel: TypeLogLevel = 'info'
let disabled = false

const iflevel = (level: TypeLogLevel) => {
    if (logLevel === 'debug' || (level === 'warn' && logLevel !== 'error')) {
        return true
    }
    if (logLevel === level) {
        return true
    }
    return false
}

const log = (method: ((p: any[]) => void) | undefined, args: any[]) => {
    if (disabled) {
        return
    }
    if (!method) {
        // eslint-disable-next-line no-console
        method = console.log
    }
    method.apply(console, [[new Date().toLocaleTimeString(), ' : ', ...args]])
}

const Logger = {
    debug: (...args: any[]) => {
        if (iflevel('debug')) {
            // eslint-disable-next-line no-console
            log(console.log, args)
        }
    },
    info: (...args: any[]) => {
        if (iflevel('info')) {
            // eslint-disable-next-line no-console
            log(console.info, args)
        }
    },
    warn: (...args: any[]) => {
        if (iflevel('warn')) {
            // eslint-disable-next-line no-console
            log(console.warn, args)
        }
    },
    error: (...args: any[]) => {
        // eslint-disable-next-line no-console
        log(console.error, args)
    },
    setLogLevel: (level: TypeLogLevel) => (logLevel = level),
    disableAll: () => {
        disabled = true // Pour les tests
    },
}

export { logLevel, disabled, iflevel, log }
export default Logger
