import * as React from 'react'
import { Platform } from 'react-native'
import Layout from './ui/layout/MainLayout'
import { persist } from './store/store'

import { createProvider } from '@utils/azure/Azure'
import AzureConfig from '@utils/azure/config'
import Loading from './ui/screens/loading/Loading'

import { IcpSyncService } from '@utils/icpSyncService'
import { checkNewVersion } from '@utils/versionUtils'

import VersionModal from '@components/version/VersionModal'

import useReducer from '@store/useReducer'
import * as appVersionStore from '@store/version'

import Provider from '@styles/Provider'
import { getThemeFromApi } from '@utils/themeUtils'
import Logger from '@utils/logger'
import * as config from './config'

export default () => {
    const [loading, setLoading] = React.useState(true)
    const showNewVersionModal = useReducer(appVersionStore.store, state => state.showNewVersionModal)

    Logger.warn('------------------------------')
    Logger.warn('global env : ' + config.default.ENV)
    Logger.warn('serveur prefix : ' + config.default.SERVER_PREFIX)
    Logger.warn('------------------------------')

    React.useEffect(() => {
        if (loading) {
            persist().then(() => {
                IcpSyncService.start()
                createProvider(AzureConfig)
                setLoading(false)
            })
        }
    }, [loading])

    React.useEffect(() => checkNewVersion(), [])

    React.useEffect(() => {
        if (Platform.OS !== 'web') {
            if (showNewVersionModal) {
                VersionModal.open({
                    onBack: () => null,
                })
                return () => VersionModal.close()
            }
        }
    }, [showNewVersionModal])

    React.useEffect(() => {
        getThemeFromApi()
    }, [])

    if (loading) {
        return <Loading />
    }

    return (
        <Provider>
            <Layout />
        </Provider>
    )
}
