import './Bootstrap'
import { AppRegistry, Platform } from 'react-native'
import App from './App'
import { name as appName } from './app.json'

import config from './config'
import logger from './utils/logger'

// IcoMoon icons
const iconFontStyles = `
@font-face {
  src: url(${require('./NumaPPEE.ttf')});
  font-family: NumaPPEE;
}
@font-face {
    src: url(${require('./fonts/Avenir-Black.otf')});
    font-family: Avenir-Black;
  }
  
@font-face {
  src: url(${require('./fonts/Avenir-Book.otf')});
  font-family: Avenir-Book;
}
  
@font-face {
  src: url(${require('./fonts/Avenir-Heavy.otf')});
  font-family: Avenir-Heavy;
}
  
@font-face {
  src: url(${require('./fonts/Avenir-Light.otf')});
  font-family: Avenir-Light;
}
  
@font-face {
  src: url(${require('./fonts/Avenir-Medium.otf')});
  font-family: Avenir-Medium;
}  
`
if (Platform.OS === 'web') {
    // Create stylesheet
    const style = document.createElement('style')
    style.type = 'text/css'
    style.appendChild(document.createTextNode(iconFontStyles))

    // Inject stylesheet
    document.head.appendChild(style)
}
logger.setLogLevel(config.ENV === 'debug' ? 'debug' : 'info')

AppRegistry.registerComponent(appName, () => App)

if (Platform.OS === 'web') {
    AppRegistry.runApplication(appName, {
        rootTag: document.getElementById('root'),
    })
}
