import * as React from 'react'
import { ScrollView, View, Text, Platform, TouchableOpacity, ActivityIndicator } from 'react-native'
import Modal from '@components/modal/Modal'
import styled from '@styles/index'
import { icpToSimpleIcp } from '@utils/icpUtils'
import { IcpSyncService } from '@utils/icpSyncService'

interface OwnProps {
    storeNonSyncIcpList: Icp[]
    modalVisible: boolean
    closeModal: () => void
}

interface Props {
    i18n: I18nState
    storeNonSyncIcpList: Icp[]
    modalVisible: boolean
    closeModal: () => void
}
interface State {
    icpSynchronizing: string[]
}

export default class SynchronizationFeature extends React.PureComponent<Props, State> {
    state: State = {
        icpSynchronizing: [],
    }

    synchronizeIcp = (icpId: string) => {
        this.setState({ icpSynchronizing: [...this.state.icpSynchronizing, icpId] })
        IcpSyncService.syncIcp(icpId)
            .then(() =>
                this.setState({
                    icpSynchronizing: this.state.icpSynchronizing.filter(i => i !== icpId),
                }),
            )
            .catch(() =>
                this.setState({
                    icpSynchronizing: this.state.icpSynchronizing.filter(i => i !== icpId),
                }),
            )
    }

    renderIcpLine = (i18n: I18nState, icp: SimpleIcp, index: number) => (
        <ICPLine
            key={index}
            icpStatus={icp.status}
            onPress={() => this.synchronizeIcp(icp.id)}
            disabled={this.state.icpSynchronizing.indexOf(icp.id) > -1}
        >
            {this.state.icpSynchronizing.indexOf(icp.id) > -1 && (
                <LoadingContainer>
                    <ActivityIndicator color="white" size={50} />
                </LoadingContainer>
            )}
            <CompaniesContainer>
                <ICPTitle numberOfLines={2} ellipsizeMode={'tail'}>
                    {icp.description ? icp.description : i18n.t(`screens.icpList.noIcpDescription`)}
                </ICPTitle>
                <ICPSubTitle numberOfLines={1} ellipsizeMode={'tail'}>
                    {icp.companiesName.length === 0
                        ? i18n.t(`screens.icpList.noCompany`)
                        : icp.companiesName.sort((a, b) => a.localeCompare(b)).join(', ')}
                </ICPSubTitle>
            </CompaniesContainer>
            <RowContainer>
                <RedacteurContainer>
                    <IcpLineItemText numberOfLines={2} ellipsizeMode={'tail'}>
                        {icp.redacteur}
                    </IcpLineItemText>
                </RedacteurContainer>
                <IcpLineDateContainer>
                    <IcpLineItemTextContainer>
                        {icp.icpDate && (
                            <IcpLineItemText numberOfLines={1} ellipsizeMode={'tail'}>
                                {icp.icpDate
                                    ? i18n.t(`screens.icpList.icpDateText`, { date: new Date(icp.icpDate) })
                                    : i18n.t(`screens.icpList.noIcpDate`)}
                            </IcpLineItemText>
                        )}
                    </IcpLineItemTextContainer>
                    <IcpLineItemTextContainer>
                        {icp.operationDate && (
                            <IcpLineItemText numberOfLines={1} ellipsizeMode={'tail'}>
                                {icp.operationDate
                                    ? i18n.t(`screens.icpList.operationDateText`, { date: new Date(icp.operationDate) })
                                    : i18n.t(`screens.icpList.noOperationDate`)}
                            </IcpLineItemText>
                        )}
                    </IcpLineItemTextContainer>
                    <IcpLineItemTextContainer>
                        {icp.operationDate && (
                            <IcpLineItemText numberOfLines={1} ellipsizeMode={'tail'}>
                                {icp.endDate
                                    ? i18n.t(`screens.icpList.endDateText`, { date: new Date(icp.endDate) })
                                    : i18n.t(`screens.icpList.noOperationDate`)}
                            </IcpLineItemText>
                        )}
                    </IcpLineItemTextContainer>
                </IcpLineDateContainer>
            </RowContainer>
        </ICPLine>
    )

    render() {
        const { modalVisible, closeModal, storeNonSyncIcpList, i18n } = this.props

        return (
            <Modal visible={modalVisible} onRequestClose={closeModal}>
                <ModalContent>
                    {storeNonSyncIcpList.length !== 0 ? (
                        <IcpSynchronisationList>
                            {storeNonSyncIcpList.map((icp, index) =>
                                this.renderIcpLine(i18n, icpToSimpleIcp(icp), index),
                            )}
                        </IcpSynchronisationList>
                    ) : (
                        <NoIcpSynchronizationContainer>
                            <NoIcpSynchronizationLibelle>
                                {i18n.t('screens.synchronizationFeature.noIcpToSynchronize')}
                            </NoIcpSynchronizationLibelle>
                        </NoIcpSynchronizationContainer>
                    )}
                    <Mode>
                        <ModeName>
                            {i18n.t(`screens.synchronizationFeature.modalTitle`, {
                                count: storeNonSyncIcpList.length,
                            })}
                        </ModeName>
                    </Mode>
                </ModalContent>
            </Modal>
        )
    }
}

const ModalContent = styled(View)`
    background-color: ${props => props.theme.colors.documentsModalBackground};
`
const Mode = styled(View)`
    position: absolute;
    top: 15px;
    right: 25px;
    left: 25px;
    border-radius: 50px;
    margin-bottom: 10px;
    align-items: center;
    justify-content: center;
    height: ${`${Platform.OS === 'web' ? 32 : 24}px`};
    background-color: ${props => props.theme.colors.icpContactModeSelected};
`
const ModeName = styled(Text)`
    ${props => props.theme.fonts.icpContactName}
    color: ${props => props.theme.colors.icpContactMode};
`
const IcpSynchronisationList = styled(ScrollView)`
    flex: 1;
    padding-top: 50px;
`

//ICP ITEM LINE
const ICPLine = styled(TouchableOpacity)<{ icpStatus: ICPStatus }>`
    elevation: 5px;
    box-shadow: 1px 1px 2px ${props => props.theme.colors.homeIndicatorBowShadowColor};
    margin: 5px;
    height: 125px;
    flex-direction: column;
    background-color: ${props => props.theme.colors.evenLines};

    border-radius: 10px;
    border-left-width: 10px;
    border-left-color: ${props =>
        props.icpStatus === 'aPlanifier'
            ? props.theme.colors.icpAPlanifier
            : props.icpStatus === 'aRealiser'
            ? props.theme.colors.icpARealiser
            : props.icpStatus === 'aFinaliser'
            ? props.theme.colors.icpAFinaliser
            : props.icpStatus === 'aApprouver'
            ? props.theme.colors.icpAApprouver
            : props.icpStatus === 'actif'
            ? props.theme.colors.icpActif
            : props.icpStatus === 'aEcheance'
            ? props.theme.colors.icpAEcheance
            : props.theme.colors.icpEchu};
`
const ICPTitle = styled(Text)`
    ${props => props.theme.fonts.icpDescription}
`
const ICPSubTitle = styled(Text)`
    ${props => props.theme.fonts.icpCompanies}
`
const IcpLineItemText = styled(Text)`
    ${props => props.theme.fonts.icpDatas};
`
const IcpLineItemTextContainer = styled(View)`
    flex: 1;
    align-items: flex-end;
    justify-content: center;
    padding-right: 5;
    margin-bottom: 3px;
`
const IcpLineDateContainer = styled(View)`
    flex: 1;
    flex-direction: column;
`
const CompaniesContainer = styled(View)`
    flex: 1;
    padding: 10px;
`
const RowContainer = styled(View)`
    flex: 1;
    height: 75px;
    flex-direction: row;
`
const RedacteurContainer = styled(View)`
    flex: 1;
    align-items: center;
    justify-content: center;
`
const NoIcpSynchronizationContainer = styled(View)`
    flex: 1;
    align-items: center;
    justify-content: center;
`
const NoIcpSynchronizationLibelle = styled(Text)`
    text-align: center;
    ${props => props.theme.fonts.noSynchronizedIcp};
`
const LoadingContainer = styled(View)`
    background-color: rgba(120, 120, 120, 0.5);
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0px;
    right: 0px;
    left: 0px;
    top: 0px;
`
