const findUserPerimeterParents = (perimeter: PerimeterUser, perimeterID: string): PerimeterUser[] => {
    if (perimeter.idPerimeter === perimeterID) {
        return [perimeter]
    } else if (perimeter.subPerimeterUser && perimeter.subPerimeterUser.length !== 0) {
        const found = getFullUserPerimeterFromPerimeterID(perimeterID, perimeter.subPerimeterUser)
        if (found.length > 0) {
            return [perimeter, ...found]
        }
    }
    return []
}

const getFullUserPerimeterFromPerimeterID = (perimeterID: string, perimeters: PerimeterUser[]) =>
    perimeters.reduce(
        (acc, cur) => (acc.length === 0 ? findUserPerimeterParents(cur, perimeterID) : acc),
        [] as PerimeterUser[],
    )

const findPerimeterParents = (perimeter: Perimeter, perimeterID: string): Perimeter[] => {
    if (perimeter.id === perimeterID) {
        return [perimeter]
    } else if (perimeter.subPerimeter && perimeter.subPerimeter.length !== 0) {
        const found = getFullPerimeterFromPerimeterID(perimeterID, perimeter.subPerimeter)
        if (found.length > 0) {
            return [perimeter, ...found]
        }
    }
    return []
}

const getFullPerimeterFromPerimeterID = (perimeterID: string, perimeters: Perimeter[]) =>
    perimeters.reduce(
        (acc, cur) => (acc.length === 0 ? findPerimeterParents(cur, perimeterID) : acc),
        [] as Perimeter[],
    )

const getFullUserPerimeterName = (perimeterID: string, perimeters: PerimeterUser[]) =>
    getFullUserPerimeterFromPerimeterID(perimeterID, perimeters).reduce(
        (acc, cur) => (cur.libelle ? (acc = acc + (!acc || acc.length === 0 ? '' : ' - ') + cur.libelle) : acc),
        '',
    )

const getAllUserPerimeterSubLevels = (level: PerimeterUser[]) =>
    level.reduce((acc, cur) => {
        acc.push(cur, ...getAllUserPerimeterSubLevels(cur.subPerimeterUser))
        return acc
    }, [] as PerimeterUser[])

const findUserInUserPerimeter = (perimeter: PerimeterUser, userID: string): User | undefined => {
    const foundUser = perimeter.users.find(u => u.id === userID)
    if (!!foundUser) {
        return foundUser
    } else if (perimeter.subPerimeterUser && perimeter.subPerimeterUser.length !== 0) {
        return findUserInUserPerimeters(userID, perimeter.subPerimeterUser)
    }
}

const findUserInUserPerimeters = (userID: string, perimeters: PerimeterUser[]): User | undefined =>
    perimeters.reduce((acc, cur) => {
        return !acc ? findUserInUserPerimeter(cur, userID) : acc
    }, undefined as User | undefined)

const findPerimeterInUserPerimeter = (perimeter: PerimeterUser, perimeterId: string): PerimeterUser | undefined => {
    if (perimeter.idPerimeter === perimeterId) {
        return perimeter
    } else if (perimeter.subPerimeterUser && perimeter.subPerimeterUser.length !== 0) {
        return findUserPerimeterFromPerimeterId(perimeterId, perimeter.subPerimeterUser)
    }
    return undefined
}

const findUserPerimeterFromPerimeterId = (
    perimeterId: string,
    perimeters: PerimeterUser[],
): PerimeterUser | undefined => {
    return perimeters.reduce((acc, cur) => {
        return !acc ? findPerimeterInUserPerimeter(cur, perimeterId) : acc
    }, undefined as PerimeterUser | undefined)
}

const hasSubPerimeterUser = (perimeter: PerimeterUser): boolean => {
    // Si le périmètre n'a pas de subPerimeter, on regarde simplement s'il a des users
    if (perimeter.subPerimeterUser.length === 0) {
        return perimeter.users.length === 0
    }
    // Si le perimètre a des subPerimeter, on sonde de façon récursive chaque subPerimeter
    // En sortie du map, on obtient un tableau de booléen correspondant à la réponse de chacun des subPerimeter
    // Dès qu'il y a un true (un subPerimeter a un user), on renvoie true
    return perimeter.subPerimeterUser.map(hasSubPerimeterUser).filter(hasUser => !hasUser).length === 0
}

const canPerimeterBeDeactivated = (perimeter: PerimeterUser): boolean =>
    // Règle pour désactiver un périmètre : pas de user à ce périmètre ni à ses sous-niveaux
    perimeter && perimeter.users.length === 0 && hasSubPerimeterUser(perimeter)

const canPerimeterBeActivated = (perimeters: PerimeterUser[], perimeterId: string): boolean => {
    // Règle pour activer un périmètre : tous ses parents sont actifs
    const allParents = getFullUserPerimeterFromPerimeterID(perimeterId, perimeters)
    return (
        allParents.length === 1 ||
        (allParents.length > 0 &&
            allParents.slice(0, allParents.length - 1).reduce((acc, cur) => acc && cur.active, true as boolean))
    )
}

const getAllUserPerimeterSubLevelsUsers = (level: PerimeterUser[]): User[] =>
    level.reduce((acc, cur) => {
        acc.push(...cur.users, ...getAllUserPerimeterSubLevelsUsers(cur.subPerimeterUser))
        return acc
    }, [] as User[])

const getAllPerimeterAndSubLevels = (selected: Perimeter[]): string[] =>
    selected.reduce((acc, cur) => {
        acc.push(cur.id, ...getAllPerimeterAndSubLevels(cur.subPerimeter))
        return acc
    }, [] as string[])

const findInitialPerimeters = (perimeter: Perimeter, perimeterID: string): Perimeter[] => {
    if (perimeter.id === perimeterID) {
        return [perimeter]
    } else if (perimeter.subPerimeter && perimeter.subPerimeter.length !== 0) {
        const found = getAllInitialPerimetersFromPerimeterID(perimeterID, perimeter.subPerimeter)
        if (found.length > 0) {
            return [perimeter, ...found]
        }
    }
    return []
}

const getAllInitialPerimetersFromPerimeterID = (perimeterID: string, perimeters: Perimeter[]) =>
    perimeters.reduce(
        (acc, cur) => (acc.length === 0 ? findInitialPerimeters(cur, perimeterID) : acc),
        [] as Perimeter[],
    )

export {
    findUserPerimeterParents,
    getFullUserPerimeterFromPerimeterID,
    getFullUserPerimeterName,
    findUserInUserPerimeters,
    getAllUserPerimeterSubLevels,
    findUserPerimeterFromPerimeterId,
    canPerimeterBeDeactivated,
    canPerimeterBeActivated,
    getAllUserPerimeterSubLevelsUsers,
    getAllPerimeterAndSubLevels,
    findInitialPerimeters,
    getAllInitialPerimetersFromPerimeterID,
    findPerimeterParents,
    getFullPerimeterFromPerimeterID,
}
