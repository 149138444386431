import * as React from 'react'

// Components
import { View, Text, Image } from 'react-native'
import Container from '@components/icpFormContainer/icpFormContainer'

// Store
import useI18n from '@store/i18n/useI18n'

// Style
import styled from '@styles/index'

const SMALL_PICTO_SIZE = 50
const SMALL_PICTO_PADDING = 5

export default ({ values }: ReadFamilyRiskIcpProps) => {
    const i18n = useI18n()

    const renderSelectedMeasure = (families: RiskAnalysis[]) => {
        const icpFamilies = families.reduce((acc1, cur1) => {
            const risks: IcpRisk[] = cur1.risks.reduce((acc2, cur2) => {
                const measures: IcpMeasure[] = cur2.measures.reduce(
                    (acc3, cur3) => (!cur3.selected ? acc3 : [...acc3, cur3]),
                    [] as IcpMeasure[],
                )
                return measures.length === 0 ? acc2 : [...acc2, { ...cur2, measures }]
            }, [] as IcpRisk[])
            return risks.length === 0 ? acc1 : [...acc1, { ...cur1, risks }]
        }, [] as RiskAnalysis[])

        return icpFamilies.length === 0 ? (
            <NoItem>
                <NoItemLibelle>{i18n.t('components.familyIcp.noIcpMeasure')}</NoItemLibelle>
            </NoItem>
        ) : (
            icpFamilies
                .sort((a, b) => a.libelle.localeCompare(b.libelle))
                .map((f, i) => (
                    <SelectedFamilyContainer key={i} lastItem={icpFamilies.length === i + 1}>
                        <FamilySelected>
                            <SelectedImageContainer>
                                <SelectedFamilyImage
                                    source={{
                                        uri: f.picto,
                                    }}
                                />
                            </SelectedImageContainer>
                            <SelectedFamilyLibelle numberOfLines={2} ellipsizeMode="tail">
                                {f.libelle}
                            </SelectedFamilyLibelle>
                        </FamilySelected>
                        <RiskListContainer>
                            {f.risks
                                .sort((a, b) => a.libelle.localeCompare(b.libelle))
                                .map((r, j) => (
                                    <SelectedRiskContainer key={j} lastItem={f.risks.length === j + 1}>
                                        <RiskSelected>
                                            <SelectedRiskLibelle numberOfLines={2} ellipsizeMode="tail">
                                                {r.libelle}
                                            </SelectedRiskLibelle>
                                        </RiskSelected>
                                        <RiskMeasureContainer>
                                            {r.measures
                                                .sort((a, b) => a.libelle.localeCompare(b.libelle))
                                                .map((m, k) => (
                                                    <SelectedMeasureContainer
                                                        key={k}
                                                        lastItem={r.measures.length === k + 1}
                                                    >
                                                        <SelectedMeasureLibelle numberOfLines={2} ellipsizeMode="tail">
                                                            {m.libelle}
                                                        </SelectedMeasureLibelle>
                                                        <ResponsableLibelle
                                                            numberOfLines={3}
                                                            ellipsizeMode="tail"
                                                            noResponsable={m.responsables.length === 0}
                                                        >
                                                            {m.responsables.length !== 0
                                                                ? m.responsables.join(', ')
                                                                : i18n.t('components.familyIcp.noResponsable')}
                                                        </ResponsableLibelle>
                                                    </SelectedMeasureContainer>
                                                ))}
                                        </RiskMeasureContainer>
                                    </SelectedRiskContainer>
                                ))}
                        </RiskListContainer>
                    </SelectedFamilyContainer>
                ))
        )
    }

    return (
        <Container title={i18n.t('components.familyIcp.title')}>
            <React.Fragment>
                <ListTitleContainer>
                    <ListTitle numberOfLines={2} ellipsizeMode={'tail'}>
                        {i18n.t('components.familyIcp.tabTitle')}
                    </ListTitle>
                </ListTitleContainer>
                <MeasureChosenContainer>{renderSelectedMeasure(values.risksAndMeasures)}</MeasureChosenContainer>
            </React.Fragment>
        </Container>
    )
}

// TITRE DE LA LISTE DES FAMILLES CHOISIES
const ListTitleContainer = styled(View)`
    flex-direction: row;
    height: ${props => props.theme.constants.riskFamiliesListTitleContainerHeight}px;
    justify-content: space-between;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;

    border-bottom-color: ${props => props.theme.colors.homeListBorderColor};
    border-bottom-width: 2px;
`
const ListTitle = styled(Text)`
    ${props => props.theme.fonts.icpRubriqueTitle}
    text-transform: uppercase;
`
const MeasureChosenContainer = styled(View)`
    flex: 1;
    background-color: white;
    margin-top: 20px;
    margin-bottom: 50px;
`
const NoItem = styled(View)`
    flex: 1;
    min-height: 100px;
    align-items: center;
    justify-content: center;
    border: 1px solid;
    border-radius: 5px;
`
const NoItemLibelle = styled(Text)`
    ${props => props.theme.fonts.noFamilyList}
`

// LISTE DES ELEMENTS CHOISIS DANS ICP
const SelectedFamilyContainer = styled(View)<{ lastItem?: boolean }>`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    ${props => !props.lastItem && `border-bottom: 2px solid ${props.theme.colors.borderListColor};`}
`
const FamilySelected = styled(View)`
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
`
const RiskSelected = styled(View)`
    flex: 1;
`
const SelectedRiskContainer = styled(View)<{ lastItem?: boolean }>`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    ${props => !props.lastItem && `border-bottom: 2px solid ${props.theme.colors.borderListColor};`}
`
const SelectedMeasureContainer = styled(View)<{ lastItem?: boolean }>`
    padding-top: 5px;
    padding-bottom: 5px;
    ${props => !props.lastItem && `border-bottom: 2px solid ${props.theme.colors.borderListColor};`}
`
const RiskListContainer = styled(View)`
    flex: 2;
`
const RiskMeasureContainer = styled(View)`
    flex: 1;
`

//LIBELLE DES INFOS SELCTED
const SelectedFamilyLibelle = styled(Text)`
    ${props => props.theme.fonts.infoLibelle}
    padding-right: 5px;
`
const SelectedRiskLibelle = styled(Text)`
    ${props => props.theme.fonts.infoLibelle}
    padding-right: 5px;
`
const SelectedMeasureLibelle = styled(Text)`
    ${props => props.theme.fonts.infoLibelle}
    padding-right: 5px;
`

//PICTO DANS PETITE LISTE DES SELECTED
const SelectedImageContainer = styled(View)`
    margin: 10px;
    padding: ${SMALL_PICTO_PADDING}px;
    width: ${SMALL_PICTO_SIZE + SMALL_PICTO_PADDING * 2}px;
    height: ${SMALL_PICTO_SIZE + SMALL_PICTO_PADDING * 2}px;
    border-radius: ${2 * SMALL_PICTO_PADDING}px;
    background-color: ${props => props.theme.colors.pictoFamilyBackground};
`
const SelectedFamilyImage = styled(Image)`
    height: ${SMALL_PICTO_SIZE}px;
    width: ${SMALL_PICTO_SIZE}px;
`

const ResponsableLibelle = styled(Text)<{ noResponsable?: boolean }>`
    ${props => (props.noResponsable ? props.theme.fonts.noCompaniesLibelle : props.theme.fonts.companiesLibelle)};
    padding-top: 10px;
    padding-right: 5px;
    text-decoration-color: ${props =>
        props.noResponsable ? props.theme.fonts.noCompaniesLibelle.color : props.theme.fonts.companiesLibelle.color};
`
