import * as React from 'react'
import { Store } from 'effector'

const useReducer = <T, U>(store: Store<T>, reducer: (state: T) => U) => {
    const [val, setVal] = React.useState<U>(() => reducer(store.getState()))

    React.useEffect(() => {
        const sub = store.subscribe(state => setVal(reducer(state)))
        return sub.unsubscribe
    }, [store, reducer])

    return val
}

export default useReducer
