const menuSections: MenuSection[] = [
    {
        type: 'subSections',
        title: 'myICP',
        iconName: 'allIcp',
        subSections: [
            {
                type: 'pushTo',
                title: 'allIcps',
                iconName: 'myIcp',
                screenPath: '/toutesLesIcps',
            },
            {
                type: 'pushTo',
                title: 'icpToCome',
                iconName: 'myIcp',
                screenPath: '/icpAVenir',
            },
            {
                type: 'pushTo',
                title: 'icpToPlan',
                iconName: 'icpToPlan',
                screenPath: '/icpAPlanifier',
            },
            {
                type: 'pushTo',
                title: 'icpToDo',
                iconName: 'icpToDo',
                screenPath: '/icpARealiser',
            },
            {
                type: 'pushTo',
                title: 'icpToEnd',
                iconName: 'icpToEnd',
                screenPath: '/icpAFinaliser',
            },
            {
                type: 'pushTo',
                title: 'icpToApprove',
                iconName: 'icpToApprove',
                screenPath: '/icpAApprouver',
            },
        ],
    },
    {
        type: 'subSections',
        title: 'allPreventionPlan',
        iconName: 'allPpee',
        subSections: [
            {
                type: 'pushTo',
                title: 'allPpees',
                iconName: 'myPpee',
                screenPath: '/tousLesPpees',
            },
            {
                type: 'pushTo',
                title: 'ppeeInProgress',
                iconName: 'myPpee',
                screenPath: '/actifEnCours',
            },
            {
                type: 'pushTo',
                title: 'preventionPlan',
                iconName: 'ppeeActifs',
                screenPath: '/mesPlansDePrevention',
            },
            {
                type: 'pushTo',
                title: 'uploadPpee',
                iconName: 'myPpee',
                screenPath: '/televersementPpee',
            },
        ],
    },
    {
        type: 'pushTo',
        title: 'contact',
        iconName: 'contact',
        screenPath: '/contacts',
    },
    {
        type: 'subSections',
        title: 'follow',
        iconName: 'graphic',
        subSections: [
            {
                type: 'pushTo',
                title: 'graphs',
                iconName: 'plus',
                screenPath: '/graphes',
            },
            {
                type: 'pushTo',
                title: 'unknownAgencies',
                iconName: 'plus',
                screenPath: '/entitesNonReferencees',
            },
            {
                type: 'pushTo',
                title: 'unknownRisksMeasures',
                iconName: 'plus',
                screenPath: '/risquesEtMesuresNonReferencees',
            },
            {
                type: 'pushTo',
                title: 'icpMultiEntreprises',
                iconName: 'plus',
                screenPath: '/icpMultiEntreprises',
            },
            {
                type: 'pushTo',
                title: 'ppeeMultiEntreprises',
                iconName: 'plus',
                screenPath: '/ppeeMultiEntreprises',
            },
        ],
    },
    {
        type: 'subSections',
        title: 'admin',
        iconName: 'settings',
        adminOnly: true,
        subSections: [
            {
                type: 'pushTo',
                title: 'roles',
                iconName: 'plus',
                screenPath: '/roles',
            },
            {
                type: 'pushTo',
                title: 'perimeters',
                iconName: 'plus',
                screenPath: '/perimetres',
            },
            {
                type: 'pushTo',
                title: 'users',
                iconName: 'plus',
                screenPath: '/utilisateurs',
            },
            {
                type: 'pushTo',
                title: 'riskFamilies',
                iconName: 'plus',
                screenPath: '/famillesDeRisque',
            },
            {
                type: 'pushTo',
                title: 'adminCompany',
                iconName: 'plus',
                screenPath: '/administrationEntreprises',
            },
            {
                type: 'pushTo',
                title: 'modeles',
                iconName: 'plus',
                screenPath: '/trames',
            },
            {
                type: 'pushTo',
                title: 'documents',
                iconName: 'plus',
                screenPath: '/documentsAnnexes',
            },
        ],
    },
    {
        type: 'subSections',
        title: 'custom',
        iconName: 'edit',
        adminOnly: true,
        subSections: [
            {
                type: 'pushTo',
                title: 'interface',
                iconName: 'edit',
                screenPath: '/customInterface',
            },
            {
                type: 'pushTo',
                title: 'cssct',
                iconName: 'edit',
                screenPath: '/customCssct',
            },
            {
                type: 'pushTo',
                title: 'workInspection',
                iconName: 'edit',
                screenPath: '/customWorkInspection',
            },
        ],
    },
]

export default menuSections
