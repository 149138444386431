import * as React from 'react'

// Components
import { View, Text } from 'react-native'
import FieldInformation from '@components/fieldInformation/FieldInformation'
import { TouchableItemFeedback } from '@components/touchable/Touchable'
import Icon from '@components/icon/Icon'

// Store
import useI18n from '@store/i18n/useI18n'

// Style
import styled from '@styles/index'
import useTheme from '@styles/useTheme'

interface Props {
    values: Representative[]
    label?: string
}

export default (props: Props) => {
    const { values, label } = props

    const i18n = useI18n()
    const [Theme] = useTheme()
    const [itemOpened, setItemOpened] = React.useState<number | undefined>(undefined)

    return (
        <View>
            <TopContainer>
                <Flexbox>
                    <FieldInformation label={label} />
                </Flexbox>
            </TopContainer>

            <Container>
                <RowContainer>
                    <Legend>{i18n.t('components.addIcpRepresentatives.legends.name')}</Legend>
                    <Legend>{i18n.t('components.addIcpRepresentatives.legends.status')}</Legend>
                    <Legend>{i18n.t('components.addIcpRepresentatives.legends.signature')}</Legend>
                </RowContainer>
                <ContactList>
                    {values.length === 0 ? (
                        <NoContact>{i18n.t('components.addIcpRepresentatives.noRepresentative')}</NoContact>
                    ) : (
                        values.map((contact, index) => (
                            <TouchableItemFeedback
                                key={`contact${index}`}
                                onPress={() => setItemOpened(itemOpened === index ? undefined : index)}
                            >
                                <ContactContainer odd={index % 2 === 1}>
                                    <RowContainer>
                                        <Flexbox>
                                            <ContactName>{`${contact.firstName} ${contact.lastName}`}</ContactName>
                                        </Flexbox>
                                        <Flexbox>
                                            <ContactName>{contact.status}</ContactName>
                                        </Flexbox>
                                        <Flexbox>
                                            <SimpleRow>
                                                {!!contact.signatureDate ? (
                                                    <ContactName>
                                                        {i18n.t('components.addIcpRepresentatives.signatureDate', {
                                                            date: new Date(contact.signatureDate),
                                                        })}
                                                    </ContactName>
                                                ) : (
                                                    undefined
                                                )}
                                                <Icon
                                                    name={'information'}
                                                    size={Theme.constants.riskFamilyInformationIconSize}
                                                    color={
                                                        itemOpened === index
                                                            ? Theme.colors.riskFamilyItemInformationSelectedIcon
                                                            : Theme.colors.riskFamilyItemInformationIcon
                                                    }
                                                />
                                            </SimpleRow>
                                        </Flexbox>
                                    </RowContainer>

                                    {itemOpened === index && (
                                        <RowContainer>
                                            <ContactColumn double={true}>
                                                <ContactInformation>{contact.email}</ContactInformation>
                                                <ContactInformation>{contact.telephone1}</ContactInformation>
                                                <ContactInformation>{contact.telephone2}</ContactInformation>
                                            </ContactColumn>
                                            <ContactColumn>
                                                {!!contact.agencyName && (
                                                    <ContactInformation>{contact.agencyName}</ContactInformation>
                                                )}
                                            </ContactColumn>
                                        </RowContainer>
                                    )}
                                </ContactContainer>
                            </TouchableItemFeedback>
                        ))
                    )}
                </ContactList>
            </Container>
        </View>
    )
}
const SimpleRow = styled(View)`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
`
const ContactContainer = styled(View)<{ odd?: boolean }>`
    background-color: ${props => !props.odd && props.theme.colors.evenLines};
`
const Container = styled(View)`
    background-color: ${props => props.theme.colors.addIcpContactsBackground};
    padding-right: 8px;
    padding-left: 8px;
`
const RowContainer = styled(View)`
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: ${props => props.theme.constants.fieldPadding};
    padding-left: ${props => props.theme.constants.fieldPadding};
    min-height: 36px;
`
const Legend = styled(Text)`
    ${props => props.theme.fonts.rightLegend}
    flex:1;
    text-transform: uppercase;
`
const ContactList = styled(View)`
    padding-bottom: 8px;
`
const NoContact = styled(Text)`
    ${props => props.theme.fonts.perimeterName}
    text-align: center;
    padding-top: 24px;
    padding-bottom: 24px;
`
const ContactName = styled(Text)`
    ${props => props.theme.fonts.icpContactName}
`
const ContactInformation = styled(Text)<{ main?: boolean }>`
    ${props => (props.main ? props.theme.fonts.contactMainInformation : props.theme.fonts.contactInformation)}
    margin-left: ${props => !props.main && '20px'};
`
const ContactColumn = styled(View)<{ double?: boolean }>`
    flex: ${props => (props.double ? '2' : '1')};
    flex-direction: column;
    margin-bottom: 20px;
`
const Flexbox = styled(View)`
    flex: 1;
`
const TopContainer = styled(View)`
    flex-direction: row;
    padding-right: ${props => props.theme.constants.fieldPadding};
`
