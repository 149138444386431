import * as React from 'react'

// Components
import { View, Text, ActivityIndicator, ScrollView } from 'react-native'
import Picker from '@components/picker/Picker'
import SearchInput from '@components/searchInput/SearchInput'
import Icon from '@components/icon/Icon'
import Button from '@components/button/Button'
import TextInput from '@components/textInput/TextInput'
import Checkbox from '@components/checkbox/Checkbox'
import { Formik } from 'formik'
import RootScreen from '@components/rootScreen/RootScreen'
import Alert from '@components/alert/Alert'

import useI18n from '@store/i18n/useI18n'

// Utils
import * as JsSearch from 'js-search'
import accentSanitizer from '@utils/accentSanitizer'

// Api
import api from '@api/api'

// Utils
import {
    getAllUserPerimeterSubLevels,
    canPerimeterBeActivated,
    canPerimeterBeDeactivated,
    findUserPerimeterFromPerimeterId,
} from '@utils/perimeterUtils'
import useNavigation from '@layout/useNavigation'

// Style
import styled from '@styles/index'
import Logger from '@utils/logger'
import { TouchableItemFeedback } from '@components/touchable/Touchable'
import useTheme from '@styles/useTheme'

type AddPerimeterInputStatus = 'open' | 'closed'
type ActivationActionKey = 'Deactivation' | 'Activation'

const sanitizer = accentSanitizer()
let searchjs: JsSearch.Search

const initSearchJs = (documents: any[]) => {
    searchjs = new JsSearch.Search('idPerimeter')
    searchjs.searchIndex = new JsSearch.UnorderedSearchIndex()
    searchjs.indexStrategy = new JsSearch.AllSubstringsIndexStrategy()
    searchjs.tokenizer = {
        tokenize: (text: string) => text.split(' '),
    }
    searchjs.sanitizer = sanitizer
    searchjs.addIndex('libelle')
    searchjs.addDocuments(documents)
}

export default () => {
    const i18n = useI18n()
    const navigation = useNavigation()
    const [Theme] = useTheme()

    const [status, setStatus] = React.useState<Status>('loading')

    const [perimeters, setPerimeters] = React.useState<PerimeterUser[] | undefined>(undefined)
    const [direction, setDirection] = React.useState<PerimeterUser | undefined>(undefined)
    const [structure, setStructure] = React.useState<PerimeterUser | undefined>(undefined)
    const [perimeter, setPerimeter] = React.useState<PerimeterUser | undefined>(undefined)

    const [items, setItems] = React.useState<PerimeterUser[]>([])

    const [loadingResults, setLoadingResults] = React.useState<Status>('fetched')
    const [addPerimeterInputStatus, setAddPerimeterInputStatus] = React.useState<AddPerimeterInputStatus>('closed')
    const [text, setText] = React.useState<string>('')

    const searchPerimeters = (levels: PerimeterUser[], inputText: string): PerimeterUser[] => {
        const searchedList = (inputText ? searchjs.search(sanitizer.sanitize(inputText)) : levels) as PerimeterUser[]
        return searchedList
    }

    const fetchPerimeterList = () => {
        setLoadingResults('loading')
        api.admin
            .getUserListByPerimeter()
            .then(fetchedPerimeters => {
                setPerimeters(fetchedPerimeters[0].subPerimeterUser)
                // Mise à jour des filtres
                if (direction) {
                    setDirection(findUserPerimeterFromPerimeterId(direction.idPerimeter, fetchedPerimeters))
                }
                if (structure) {
                    setStructure(findUserPerimeterFromPerimeterId(structure.idPerimeter, fetchedPerimeters))
                }

                if (perimeter) {
                    setPerimeter(findUserPerimeterFromPerimeterId(perimeter.idPerimeter, fetchedPerimeters))
                }
                setStatus('fetched')
                setLoadingResults('fetched')
            })
            .catch(err => {
                Logger.error(err)
                setLoadingResults('error')
                setStatus('error')
            })
    }

    React.useEffect(fetchPerimeterList, [])

    const handleSubmit = (
        values: CreatePerimeter,
        setFieldError: (libelle: 'libelle' | 'idParent', message: string) => void,
        setFieldValue: (field: 'libelle' | 'idParent', value: any, shouldValidate?: boolean | undefined) => void,
        setSubmitting: (isSubmitting: boolean) => void,
    ) => {
        const trimedLibelle = values.libelle ? values.libelle.trim() : undefined
        if (!!trimedLibelle) {
            Alert({
                title: i18n.t('screens.perimetersAdminScreen.alerts.addPerimeterTitle'),
                content: i18n.t('screens.perimetersAdminScreen.alerts.addPerimeterContent'),
                icon: 'question',
                buttons: [
                    {
                        style: 'default',
                        text: i18n.t('screens.perimetersAdminScreen.alerts.confirmButton'),
                        onPress: () => {
                            api.admin
                                .addPerimeter({ ...values, libelle: trimedLibelle })
                                .then(() => {
                                    setFieldValue('libelle', '')
                                    Alert({
                                        title: i18n.t('screens.perimetersAdminScreen.alerts.successAddTitle'),
                                        content: i18n.t('screens.perimetersAdminScreen.alerts.successAddContent'),
                                        icon: 'success',
                                        buttons: [
                                            {
                                                style: 'default',
                                                text: 'OK',
                                                onPress: fetchPerimeterList,
                                            },
                                        ],
                                        cancelable: true,
                                        theme: Theme,
                                    })
                                })
                                .catch(err => {
                                    Alert({
                                        title: i18n.t('screens.perimetersAdminScreen.alerts.errorAddTitle'),
                                        content: i18n.t('screens.perimetersAdminScreen.alerts.errorAddContent'),
                                        icon: 'error',
                                        buttons: [
                                            {
                                                style: 'default',
                                                text: 'OK',
                                                onPress: () => undefined,
                                            },
                                        ],
                                        theme: Theme,
                                    })
                                    Logger.error(err)
                                })
                                .finally(() => setSubmitting(false))
                        },
                    },
                    {
                        style: 'cancel',
                        text: i18n.t('screens.perimetersAdminScreen.alerts.cancelButton'),
                        onPress: () => setSubmitting(false),
                    },
                ],
                cancelable: true,
                theme: Theme,
            })
        } else {
            setFieldError('libelle', i18n.t('screens.perimetersAdminScreen.required'))
            setSubmitting(false)
        }
    }

    const showImpossibleActionAlert = (key: ActivationActionKey) =>
        Alert({
            title: i18n.t(`screens.editPerimeterAdminScreen.alerts.impossible${key}Title`),
            content: i18n.t(`screens.editPerimeterAdminScreen.alerts.impossible${key}Content`),
            icon: 'error',
            buttons: [
                {
                    style: 'default',
                    text: i18n.t('screens.perimetersAdminScreen.alerts.confirmButton'),
                    onPress: () => undefined,
                },
            ],
            cancelable: true,
            theme: Theme,
        })

    const handleActiveCheck = (id: string, active: boolean) => {
        const i18nAction = active ? 'Activation' : 'Deactivation'
        Alert({
            title: i18n.t(`screens.perimetersAdminScreen.alerts.confirm${i18nAction}Title`),
            content: i18n.t(`screens.perimetersAdminScreen.alerts.confirm${i18nAction}Content`),
            icon: 'question',
            buttons: [
                {
                    style: 'default',
                    text: i18n.t('screens.perimetersAdminScreen.alerts.confirmButton'),
                    onPress: () => {
                        api.admin
                            .modifyPerimeterActivation(id, active)
                            .then(() =>
                                Alert({
                                    title: i18n.t(`screens.perimetersAdminScreen.alerts.success${i18nAction}Title`),
                                    content: i18n.t(`screens.perimetersAdminScreen.alerts.success${i18nAction}Content`),
                                    icon: 'success',
                                    buttons: [
                                        {
                                            style: 'default',
                                            text: 'OK',
                                            onPress: fetchPerimeterList,
                                        },
                                    ],
                                    theme: Theme,
                                }),
                            )
                            .catch(err => {
                                Alert({
                                    title: i18n.t(`screens.perimetersAdminScreen.alerts.error${i18nAction}Title`),
                                    content: i18n.t(`screens.perimetersAdminScreen.alerts.error${i18nAction}Content`),
                                    icon: 'error',
                                    buttons: [
                                        {
                                            style: 'default',
                                            text: 'OK',
                                            onPress: () => undefined,
                                        },
                                    ],
                                    theme: Theme,
                                })
                                Logger.error(err)
                            })
                    },
                },
                {
                    style: 'cancel',
                    text: i18n.t('screens.perimetersAdminScreen.alerts.cancelButton'),
                    onPress: () => undefined,
                },
            ],
            cancelable: true,
            theme: Theme,
        })
    }

    const changePerimeterActivation = (perimeter: PerimeterUser) => {
        if (perimeter.active) {
            if (canPerimeterBeDeactivated(perimeter)) {
                handleActiveCheck(perimeter.idPerimeter, !perimeter.active)
            } else {
                showImpossibleActionAlert('Deactivation')
            }
        } else {
            if (!!perimeters && canPerimeterBeActivated(perimeters, perimeter.idPerimeter)) {
                handleActiveCheck(perimeter.idPerimeter, !perimeter.active)
            } else {
                showImpossibleActionAlert('Activation')
            }
        }
    }

    const lastLevel =
        (!!perimeter && perimeter.subPerimeterUser) ||
        (!!structure && structure.subPerimeterUser) ||
        (!!direction && direction.subPerimeterUser) ||
        perimeters ||
        []

    const levels = getAllUserPerimeterSubLevels(lastLevel)

    initSearchJs(levels)

    React.useEffect(() => {
        const lastLevel =
            (!!perimeter && perimeter.subPerimeterUser) ||
            (!!structure && structure.subPerimeterUser) ||
            (!!direction && direction.subPerimeterUser) ||
            perimeters ||
            []

        const levels = getAllUserPerimeterSubLevels(lastLevel)

        initSearchJs(levels)

        setItems(searchPerimeters(levels || [], text.trim()).sort((a, b) => a.libelle.localeCompare(b.libelle)))
    }, [perimeters, perimeter, structure, direction, text])

    return (
        <RootScreen status={status}>
            <Formik
                initialValues={{ libelle: '', idParent: undefined } as CreatePerimeter}
                onSubmit={(values, { setFieldError, setFieldValue, setSubmitting }) =>
                    handleSubmit(values, setFieldError, setFieldValue, setSubmitting)
                }
            >
                {({ handleSubmit, values, setFieldValue, isSubmitting, errors }) => {
                    return (
                        !!perimeters && (
                            <React.Fragment>
                                <PickerLine zIndex={0}>
                                    <PickerContainer left>
                                        <Picker
                                            data={perimeters.map(p => p.libelle)}
                                            value={!!direction ? direction.libelle : undefined}
                                            onChange={index => {
                                                setDirection(perimeters[index])
                                                setStructure(undefined)
                                                setPerimeter(undefined)
                                                setFieldValue(
                                                    'idParent',
                                                    index > -1 ? perimeters[index].idPerimeter : undefined,
                                                )
                                            }}
                                            label={i18n.t('screens.perimetersAdminScreen.pickerLabels.direction')}
                                            isClearable
                                        />
                                    </PickerContainer>
                                    <PickerContainer>
                                        <Picker
                                            data={!!direction ? direction.subPerimeterUser.map(p => p.libelle) : []}
                                            value={!!structure ? structure.libelle : undefined}
                                            onChange={index => {
                                                if (!!direction) {
                                                    setStructure(direction.subPerimeterUser[index])
                                                    setPerimeter(undefined)
                                                    setFieldValue(
                                                        'idParent',
                                                        index > -1
                                                            ? direction.subPerimeterUser[index].idPerimeter
                                                            : direction.idPerimeter,
                                                    )
                                                }
                                            }}
                                            label={i18n.t('screens.perimetersAdminScreen.pickerLabels.structure')}
                                            disabled={!direction}
                                            isClearable
                                        />
                                    </PickerContainer>
                                </PickerLine>
                                <PickerLine zIndex={-1}>
                                    <PickerContainer left>
                                        <Picker
                                            data={!!structure ? structure.subPerimeterUser.map(p => p.libelle) : []}
                                            value={!!perimeter ? perimeter.libelle : undefined}
                                            onChange={index => {
                                                if (!!structure) {
                                                    setPerimeter(structure.subPerimeterUser[index])
                                                    setFieldValue(
                                                        'idParent',
                                                        index > -1
                                                            ? structure.subPerimeterUser[index].idPerimeter
                                                            : structure.idPerimeter,
                                                    )
                                                }
                                            }}
                                            label={i18n.t('screens.perimetersAdminScreen.pickerLabels.perimeter')}
                                            disabled={!structure}
                                            isClearable
                                        />
                                    </PickerContainer>
                                    <PickerContainer />
                                </PickerLine>
                                <SearchResultsContainer zIndex={-2}>
                                    <SearchInput
                                        onTextChange={setText}
                                        placeholder={i18n.t('screens.perimetersAdminScreen.searchPlaceholder')}
                                        resultsCount={items.length}
                                        value={text}
                                    ></SearchInput>
                                </SearchResultsContainer>

                                <ResultsContainer zIndex={-3}>
                                    <LegendLine>
                                        <NameLegend>
                                            <Legend>
                                                {i18n.t('screens.perimetersAdminScreen.resultsLegend.name')}
                                            </Legend>
                                        </NameLegend>
                                        <ActiveContainer>
                                            <Legend>
                                                {i18n.t('screens.perimetersAdminScreen.resultsLegend.active')}
                                            </Legend>
                                        </ActiveContainer>
                                    </LegendLine>

                                    {loadingResults === 'loading' ? (
                                        <ActivityIndicator
                                            size={Theme.constants.activityIndicatorScreenSize}
                                            color={Theme.colors.activityIndicator}
                                        />
                                    ) : items.length === 0 ? (
                                        <NoPerimeterMessage>
                                            {i18n.t('screens.perimetersAdminScreen.noPerimeter')}
                                        </NoPerimeterMessage>
                                    ) : (
                                        <ScrollView>
                                            {items.map((p, index) => (
                                                <TouchableItemFeedback
                                                    key={`perimeter${index}`}
                                                    onPress={() =>
                                                        navigation.push('/modifierPerimetre/:perimeterId', {
                                                            pathParams: { perimeterId: p.idPerimeter },
                                                        })
                                                    }
                                                >
                                                    <PerimeterLine odd={index % 2 === 1}>
                                                        <PerimeterName
                                                            numberOfLines={1}
                                                            ellipsizeMode={'tail'}
                                                            active={p.active}
                                                        >
                                                            {p.libelle}
                                                        </PerimeterName>
                                                        <EditLink>
                                                            {i18n.t('screens.perimetersAdminScreen.editLink')}
                                                        </EditLink>

                                                        <ActiveContainer>
                                                            <Checkbox
                                                                value={p.active}
                                                                onCheck={() => changePerimeterActivation(p)}
                                                                checkedColor={Theme.colors.activeToggle}
                                                                toggle
                                                            />
                                                        </ActiveContainer>
                                                    </PerimeterLine>
                                                </TouchableItemFeedback>
                                            ))}
                                        </ScrollView>
                                    )}
                                </ResultsContainer>
                                {addPerimeterInputStatus === 'open' && (
                                    <TextInputContainer>
                                        <TextInput
                                            label={i18n.t('screens.perimetersAdminScreen.inputLabel')}
                                            value={values.libelle}
                                            onTextChange={text => setFieldValue('libelle', text)}
                                            placeholder={i18n.t('screens.perimetersAdminScreen.inputPlaceholder')}
                                            required
                                            error={errors.libelle}
                                            disabled={isSubmitting}
                                        />
                                    </TextInputContainer>
                                )}
                                <ButtonContainer>
                                    {addPerimeterInputStatus === 'closed' && (
                                        <Button
                                            libelle={i18n.t('screens.perimetersAdminScreen.addPerimeterButton')}
                                            onPress={() => setAddPerimeterInputStatus('open')}
                                            status={'active'}
                                            width={250}
                                        >
                                            <ButtonContent>
                                                <Icon name={'plus'} color={Theme.colors.buttonTitleClassic} size={15} />
                                                <ButtonTitle>
                                                    {i18n.t('screens.perimetersAdminScreen.addPerimeterButton')}
                                                </ButtonTitle>
                                            </ButtonContent>
                                        </Button>
                                    )}
                                    {addPerimeterInputStatus === 'open' && (
                                        <LeftButton>
                                            <Button
                                                libelle={i18n.t('screens.perimetersAdminScreen.cancel')}
                                                onPress={() => {
                                                    setAddPerimeterInputStatus('closed')
                                                    setFieldValue('libelle', undefined)
                                                }}
                                                status={'active'}
                                            />
                                        </LeftButton>
                                    )}
                                    {addPerimeterInputStatus === 'open' && (
                                        <Button
                                            libelle={i18n.t('screens.perimetersAdminScreen.add')}
                                            onPress={handleSubmit}
                                            status={isSubmitting ? 'loading' : 'active'}
                                            buttonColor={Theme.colors.buttonBackgroundValidate}
                                        />
                                    )}
                                </ButtonContainer>
                            </React.Fragment>
                        )
                    )
                }}
            </Formik>
        </RootScreen>
    )
}

const PickerLine = styled(View)<{ zIndex: number }>`
    flex-direction: row;
    margin-bottom: 10px;
    z-index: ${props => props.zIndex};
`
const PickerContainer = styled(View)<{ left?: boolean }>`
    flex: 1;
    ${props => props.left && 'margin-right: 40px;'}
`
const SearchResultsContainer = styled(View)<{ zIndex: number }>`
    z-index: ${props => props.zIndex};
    margin-bottom: 20px;
    margin-top: 10px;
`
const ResultsContainer = styled(View)<{ zIndex: number }>`
    flex: 1;
    background-color: white;
    padding: 12px 24px;
    z-index: ${props => props.zIndex};
`
const LegendLine = styled(View)`
    flex-direction: row;
    padding-left: 24px;
    padding-right: 24px;
    margin-bottom: 8px;
`
const NameLegend = styled(View)`
    flex: 1;
`
const ActiveContainer = styled(View)`
    width: 35px;
`
const Legend = styled(Text)`
    ${props => props.theme.fonts.perimeterLegend}
    text-transform: uppercase;
`
const PerimeterLine = styled(View)<{ odd: boolean }>`
    flex-direction: row;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
    height: ${props => props.theme.constants.rightLineHeight}px;
    background-color: ${props => !props.odd && props.theme.colors.evenLines};

    &:hover {
        background-color: ${props => props.theme.colors.hoverBackground};
        & > div {
            color: ${props => props.theme.colors.hoverText};
            & > div {
                color: ${props => props.theme.colors.hoverText};
            }
        }
    }
`
const PerimeterName = styled(Text)<{ active: boolean }>`
    flex: 1;
    ${props => props.theme.fonts.perimeterName}
    ${props => props.active && 'font-family: Avenir-Heavy;'}
`
const EditLink = styled(Text)`
    ${props => props.theme.fonts.editPerimeterLink}
    margin-left: 36px;
    margin-right: 36px;
    text-decoration: underline;
`
const ButtonContainer = styled(View)`
    flex-direction: row;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 15px;
`
const ButtonContent = styled(View)`
    flex-direction: row;
    align-items: center;
`
const ButtonTitle = styled(Text)`
    ${props => props.theme.fonts.buttonTitle}
    text-transform: uppercase;
    margin-left: 10px;
`
const LeftButton = styled(View)`
    margin-right: 50px;
`
const TextInputContainer = styled(View)`
    margin-bottom: 15px;
`
const NoPerimeterMessage = styled(Text)`
    ${props => props.theme.fonts.perimeterName}
    text-align: center;
    margin-top: 15px;
`
