import { createEvent, createStore } from 'effector'

export const actions = {
    setMainColor: createEvent<string>('setMainColor'),
    setMenuLogo: createEvent<string>('setMenuLogo'),
    setName: createEvent<string>('setName'),
    reset: createEvent('reset'),
}

interface ThemeState {
    mainColor: string
    menuLogo: string
    name: string
}

const initialState: ThemeState = {
    mainColor: '#CECECE',
    menuLogo: '',
    name: '',
}

export const store = createStore<ThemeState>(initialState)
    .on(actions.setMainColor, (s, mainColor) => ({ ...s, mainColor }))
    .on(actions.setMenuLogo, (s, menuLogo) => ({ ...s, menuLogo }))
    .on(actions.setName, (s, name) => ({ ...s, name }))
    .reset(actions.reset)
