import * as React from 'react'

// Components
import { View, Text, Platform } from 'react-native'
import { TouchableItemFeedback } from '@components/touchable/Touchable'
import Icon from '@components/icon/Icon'

import useI18n from '@store/i18n/useI18n'

// Style
import styled from '@styles/index'
import useTheme from '@styles/useTheme'

interface Props {
    itemsByPage: number
    allItems: any[]
    setItemsToRender: React.Dispatch<React.SetStateAction<any[]>>
}

export default ({ itemsByPage, allItems, setItemsToRender }: Props) => {
    const i18n = useI18n()
    const [Theme] = useTheme()

    const [page, setPage] = React.useState<number>(0)

    React.useEffect(() => {
        const items = allItems.slice(page * itemsByPage, (page + 1) * itemsByPage)
        setItemsToRender(items)
    }, [page, allItems, itemsByPage, setItemsToRender])

    React.useEffect(() => {
        setPage(0)
    }, [allItems])

    const pageNumber = Math.ceil(allItems.length / itemsByPage) - 1

    if (allItems.length <= itemsByPage) {
        return null
    }

    return (
        <Pagination>
            <TouchableItemFeedback onPress={() => setPage(0)} disabled={page === 0}>
                <ActionItem isLeft disabled={page === 0}>
                    <Icon
                        name="doubleNext"
                        size={15}
                        color={page === 0 ? Theme.colors.nextDisabledColor : Theme.colors.infoIconClosed}
                    />
                </ActionItem>
            </TouchableItemFeedback>
            <TouchableItemFeedback onPress={() => setPage(page - 1)} disabled={page === 0}>
                <ActionItem isLeft disabled={page === 0}>
                    <Icon
                        name="next"
                        size={15}
                        color={page === 0 ? Theme.colors.nextDisabledColor : Theme.colors.infoIconClosed}
                    />
                </ActionItem>
            </TouchableItemFeedback>
            <ActualPage>
                {allItems.length > itemsByPage &&
                    i18n.t('components.pagination.actualPage', { number: page + 1, total: pageNumber + 1 })}
            </ActualPage>
            <TouchableItemFeedback onPress={() => setPage(page + 1)} disabled={page === pageNumber}>
                <ActionItem disabled={page === pageNumber}>
                    <Icon
                        name="next"
                        size={15}
                        color={page === pageNumber ? Theme.colors.nextDisabledColor : Theme.colors.nextColor}
                    />
                </ActionItem>
            </TouchableItemFeedback>
            <TouchableItemFeedback onPress={() => setPage(pageNumber)} disabled={page === pageNumber}>
                <ActionItem disabled={page === pageNumber}>
                    <Icon
                        name="doubleNext"
                        size={15}
                        color={page === pageNumber ? Theme.colors.nextDisabledColor : Theme.colors.infoIconClosed}
                    />
                </ActionItem>
            </TouchableItemFeedback>
        </Pagination>
    )
}

// PAGINATION
const Pagination = styled(View)`
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 15px;
`
const ActionItem = styled(View)<{ isLeft?: boolean; disabled?: boolean }>`
    transform: ${props => props.isLeft && 'rotate(180deg)'};
    margin-right: 5px;

    ${props =>
        Platform.OS === 'web' &&
        `&:hover {
        & > div {
            color: ${!props.disabled && props.theme.colors.iconHover};
        }
    }`}
`

const ActualPage = styled(Text)`
    ${props => props.theme.fonts.paginationLibelle}
`
