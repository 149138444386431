import * as React from 'react'

// Components
import { View, Platform, Text, TouchableOpacity } from 'react-native'
import { Formik } from 'formik'
import RootScreen from '@components/rootScreen/RootScreen'
import FormIcp from './editIcpFormContainer'
import ReadIcp from './readIcpFormContainer'
import { confirmAlert, basicAlert } from '@components/alert/Alerts'
import FloatButton from '@components/floatButton/FloatButton'

// Utils
import useNavigation from '@layout/useNavigation'
import useParams from '@layout/useParams'
import Logger from '@utils/logger'
import { trimPlanIcp, validatePlanIcp } from '@components/planIcp/PlanICP'
import { trimInfosIcp, validateInfosIcp } from '@components/infosIcp/InfosICP'
import { validateActorsIcp } from '@components/actorsIcp/ActorsICP'
import { validateDocumentsIcp } from '@components/documentsIcp/DocumentsICP'
import { validateProceduresIcp } from '@components/proceduresIcp/ProceduresICP'
import { validateFamilyRiskIcp } from '@components/familyRiskIcp/FamilyRiskIcp'
import { trimSiteIcp, validateSiteIcp } from '@components/siteIcp/SiteICP'
import { canUserAddICP } from '@utils/userRight'
import produce from 'immer'
import Modal from '@components/modal/Modal'
import Button from '@components/button/Button'

// Store
import * as userStore from '@store/user'
import * as navigationStore from '@store/navigation'
import useReducer from '@store/useReducer'
import useI18n from '@store/i18n/useI18n'

//Store pour hors ligne
import * as icpInfosStore from '@store/icpInfos'
import * as icpStore from '@store/icp'
import * as synchroStore from '@store/synchro'
import { IcpSyncService, getAllIcps } from '@utils/icpSyncService'

// Api
import api from '@app/api/api'

// Style
import styled from '@styles/index'
import useTheme from '@styles/useTheme'
import { getThemeFromApi } from '@utils/themeUtils'
import { createPreviewPdf } from '@utils/pdfUtils'

type FloatButtonType =
    | 'save_button'
    | 'delete_button'
    | 'signature_button'
    | 'send_approval_button'
    | 'approval_button'
    | 'preview_button'

const I18N_SCREEN_KEY = 'screens.editIcpScreen'

const emptyIcp: Icp = {
    title: '',
    planification: {
        redacteur: { id: '', firstName: '', lastName: '' },
        contributeurs: [],
        approbateur: undefined,
        perimeterId: undefined,
        date: new Date(new Date().setHours(8, 0)),
        place: '',
        contacts: [],
        declaration: {
            cssctOpened: true,
            wiOpened: false,
            workInspection: [],
            cssct: {
                byEmail: true,
                hasBeenSent: false,
                hasBeenSentByEmail: false,
            },
        },
        emailsSent: { toOnecontact: false, toCssct: false },
    },
    informations: {
        type: 'one-time',
        socle: false,
        description: '',
        startDate: new Date(new Date().setHours(0, 0)),
        endDate: new Date(new Date().setHours(23, 59)),
        places: [],
        otherOperations: [],
        interventionObject: '',
        interventionType: '',
    },
    actors: {
        agencies: [],
        representatives: [],
    },
    documents: { documentsAprr: [], documentsExt: [], attachedDocuments: [] },
    procedures: {},
    site: { aprrMarkingHandler: false },
    familyRisk: { risksAndMeasures: [] },
    documentsPpee: { documentsAprr: [], documentsExt: [], attachedDocuments: [] },
    formulairesPpee: [],
    avenantsPpee: [],
    pdfUrl: '',
    reference: '',
    raisonsAvenant: [],
}

const sectionKeys: SectionName[] = [
    'infosIcp',
    'planIcp',
    'actorsIcp',
    'documentsIcp',
    'proceduresIcp',
    'siteIcp',
    'risksIcp',
]

const trimValues = (icp: Icp): Icp => ({
    ...icp,
    planification: trimPlanIcp(icp.planification),
    informations: trimInfosIcp(icp.informations),
    site: trimSiteIcp(icp.site),
})

interface Props {
    initialAmendment?: Icp
}

const adaptInitialValues = (values: Icp, user: userStore.UserState): Icp =>
    produce(values, newValues => {
        newValues.planification.redacteur = user
            ? { id: user.id, firstName: user.firstName, lastName: user.lastName }
            : { id: '', firstName: '', lastName: '' }
    })

const filterDocumentsIcp = (values: Icp, documents: DocumentAprr[], families?: Family[]): Icp => {
    const hasFamilies = values.familyRisk && values.familyRisk.risksAndMeasures.length !== 0
    const icp = produce(values, newValues => {
        newValues.documents.documentsAprr = documents.reduce((acc, cur) => {
            if (values.documents.documentsAprr.find(d => d.id === cur.id)) {
                return [...acc, cur]
            } else {
                return acc
            }
        }, [] as DocumentAprr[])
        newValues.planification.declaration.cssct = values.planification.declaration.cssct || {
            byEmail: true,
            hasBeenSent: false,
            hasBeenSentByEmail: false,
        }
        newValues.planification.declaration.workInspection = values.planification.declaration.workInspection
        newValues.familyRisk =
            hasFamilies || !families
                ? values.familyRisk
                : {
                      risksAndMeasures: families.map(f => ({
                          ...f,
                          normalizedLibelle: f.normalizedLibelle,
                          risks:
                              (f.risks &&
                                  f.risks.map(r => ({
                                      ...r,
                                      measures: r.measures.map(m => ({
                                          libelle: m,
                                          selected: false,
                                          responsables: [],
                                      })),
                                  }))) ||
                              [],
                      })),
                  }
    })
    return icp
}

const addOrderOnDocuments = (icp: Icp): Icp => {
    // Fonctionnement pour matcher avec anciens docs sans ordre
    const hasIndexOnDocAprr = icp.documents.documentsAprr.find(doc => !!doc.number)
    const hasIndexOnDocExt = icp.documents.documentsExt.find(doc => !!doc.number)
    const hasIndexOnDocAttached = icp.documents.attachedDocuments.find(doc => !!doc.number)

    return {
        ...icp,
        documents: {
            documentsAprr: hasIndexOnDocAprr
                ? icp.documents.documentsAprr
                : icp.documents.documentsAprr.map((doc, index) => ({
                      ...doc,
                      number: index,
                  })),
            documentsExt: hasIndexOnDocExt
                ? icp.documents.documentsExt
                : icp.documents.documentsExt.map((doc, index) => ({
                      ...doc,
                      number: index,
                  })),
            attachedDocuments: hasIndexOnDocAttached
                ? icp.documents.attachedDocuments
                : icp.documents.attachedDocuments.map((doc, index) => ({
                      ...doc,
                      number: index,
                  })),
        },
    }
    // Fonctionnement pour matcher avec anciens docs sans ordre
}

export default (props: Props) => {
    const { initialAmendment } = props

    const { icpId } = useParams<{ icpId?: string }>()
    const i18n = useI18n()
    const user = useReducer(userStore.store, s => s)
    const navigation = useNavigation()
    const [Theme] = useTheme()

    const [status, setStatus] = React.useState<Status>('loading')
    const [initialValues, setInitialValues] = React.useState<Icp>(
        adaptInitialValues(initialAmendment || { ...emptyIcp, familyRisk: { risksAndMeasures: [] } }, user),
    )
    const [sectionsFilled, setSectionsFilled] = React.useState<SectionName[]>([])
    const [currentSection, setCurrentSection] = React.useState<SectionName | undefined>(undefined)

    const [launchScrollToTop, setLaunchScrollToTop] = React.useState<boolean>(false)
    const [approbation, setApprobation] = React.useState<boolean>(false)

    // Menu mobile
    const [menuMobileOpened, setMenuMobileOpened] = React.useState<boolean>(false)

    // Info pour hors ligne
    const [offlineDatas, setOfflineDatas] = React.useState<boolean>(false)
    const storeDocuments = useReducer(icpInfosStore.store, s => s.documents)
    const storeRedacteurs = useReducer(icpInfosStore.store, s => s.redacteurs)
    const storeApprobrateurs = useReducer(icpInfosStore.store, s => s.approbateurs)
    const storeCssctItems = useReducer(icpInfosStore.store, s => s.cssctItems)
    const storeWiItems = useReducer(icpInfosStore.store, s => s.wiItems)
    const storePerimeters = useReducer(icpInfosStore.store, s => s.perimeters)
    const storeInterventionTypes = useReducer(icpInfosStore.store, s => s.interventionTypes)
    const storeInterventionObjects = useReducer(icpInfosStore.store, s => s.interventionObjects)
    const storeFamilies = useReducer(icpInfosStore.store, s => s.families)
    const storeIcpList = useReducer(icpStore.store, s => s.icps)
    const storeNonSyncIcpList = useReducer(synchroStore.store, s => s.icpsNonSynchro)

    const allStoreIcpList = getAllIcps(storeNonSyncIcpList, storeIcpList)

    // Data
    const [documents, setDocuments] = React.useState<DocumentAprr[]>([])
    const [redacteurs, setRedacteurs] = React.useState<UserRedacteur[]>([])
    const [approbateurs, setApprobateurs] = React.useState<UserApprobateur[]>([])
    const [cssctItems, setCssctItems] = React.useState<ConfigurationEmails>()
    const [wiItems, setWiItems] = React.useState<ConfigurationEmails>()
    const [perimeters, setPerimeters] = React.useState<Perimeter[]>([])
    const [interventionTypes, setInterventionTypes] = React.useState<string[]>([])
    const [interventionObjects, setInterventionObjects] = React.useState<string[]>([])

    // Delete icp
    const [isDeleting, setIsDeleting] = React.useState<boolean>(false)

    const setSectionFilled = React.useMemo(
        () => (key: SectionName, isValid: boolean, secFilled: SectionName[]) => {
            if (isValid && secFilled.indexOf(key) < 0) {
                setSectionsFilled([...secFilled, key])
            }
            if (!isValid && secFilled.indexOf(key) > -1) {
                setSectionsFilled(secFilled.filter(k => k !== key))
            }
        },
        [setSectionsFilled],
    )

    const allFieldsRequired: SectionName[] = [
        'siteIcp',
        'infosIcp',
        'planIcp',
        'actorsIcp',
        'proceduresIcp',
        'risksIcp',
    ]
    const verificationFieldsRequired = allFieldsRequired.every(
        (element: SectionName) => sectionsFilled.indexOf(element) > -1,
    )
    // Pour modale et approbation
    const [approbationModalOpened, setApprobationModalOpened] = React.useState<boolean>(false)
    const [approbationInProgress, setApprobationInProgress] = React.useState<boolean>(false)

    // Gestion de l'état initial des autres bulles
    React.useEffect(() => {
        const initState: SectionName[] = []
        validatePlanIcp(initialValues.planification, i18n, isValid => isValid && initState.push('planIcp'))
        validateInfosIcp(initialValues.informations, i18n, isValid => isValid && initState.push('infosIcp'))
        validateActorsIcp(initialValues.actors, i18n, isValid => isValid && initState.push('actorsIcp'))
        validateDocumentsIcp(initialValues.documents, i18n, isValid => isValid && initState.push('documentsIcp'))
        validateProceduresIcp(initialValues.procedures, i18n, isValid => isValid && initState.push('proceduresIcp'))
        validateSiteIcp(initialValues.site, i18n, isValid => isValid && initState.push('siteIcp'))
        validateFamilyRiskIcp(i18n, isValid => isValid && initState.push('risksIcp'), initialValues.familyRisk)
        setSectionsFilled(initState)
    }, [initialValues, i18n, setSectionFilled])

    React.useEffect(() => {
        getThemeFromApi()
    }, [])

    const fetchIcp = (
        fetchedDocs: DocumentAprr[],
        fetchedApprobs: UserApprobateur[],
        fetchedFamilies: Family[],
        nav: Navigation,
    ) => {
        if (icpId) {
            api.icp
                .getIcp(icpId)
                .then(icp => {
                    if (icp.pdfUrl) {
                        if (!!icp.idParent) {
                            nav.push('/avenantPPEE/:ppeeId', {
                                ppeeId: icp.id,
                                pathParams: { ppeeId: icp.id },
                            })
                        } else {
                            nav.push('/ppee/:ppeeId', {
                                ppeeId: icp.id,
                                pathParams: { ppeeId: icp.id },
                            })
                        }
                    } else {
                        if (icp.familyRisk && icp.familyRisk.risksAndMeasures.length === 0) {
                            setInitialValues(addOrderOnDocuments(filterDocumentsIcp(icp, fetchedDocs, fetchedFamilies)))
                            setStatus('fetched')
                        } else {
                            setInitialValues(addOrderOnDocuments(filterDocumentsIcp(icp, fetchedDocs, fetchedFamilies)))
                            setStatus('fetched')
                        }
                    }
                })
                .catch(err => {
                    Logger.error("Impossible de récuperer l'icp, on prend la version stockée", err)
                    setOfflineDatas(true)
                    const icp = allStoreIcpList.find(i => i.id === icpId)
                    if (icp && Platform.OS !== 'web') {
                        setInitialValues(addOrderOnDocuments(filterDocumentsIcp(icp, fetchedDocs, fetchedFamilies)))
                        setStatus('fetched')
                    } else {
                        setStatus('error')
                    }
                })
        } else {
            if (user) {
                setInitialValues({
                    ...emptyIcp,
                    planification: {
                        ...emptyIcp.planification,
                        perimeterId: user?.perimeter?.id,
                        redacteur: { id: user.id, firstName: user.firstName, lastName: user.lastName },
                        approbateur: fetchedApprobs.find(a => a.id === user.idApprobateur) || undefined,
                    },
                    familyRisk: {
                        risksAndMeasures: fetchedFamilies.map(f => ({
                            ...f,
                            normalizedLibelle: f.normalizedLibelle,
                            risks:
                                (f.risks &&
                                    f.risks.map(r => ({
                                        ...r,
                                        measures: r.measures.map(m => ({
                                            libelle: m,
                                            selected: false,
                                            responsables: [],
                                        })),
                                    }))) ||
                                [],
                        })),
                    },
                })
                setStatus('fetched')
            } else {
                setStatus('error')
            }
        }
    }

    React.useEffect(() => {
        Promise.all([
            api.document.getAllDocuments(),
            api.users.getRedacteurList(),
            api.users.getApprobateurList(),
            api.family.getFamilyList(),
            api.contact.getCssctItems(),
            api.icp.getWiItems(),
            api.perimeter.getPerimeterList(),
            api.intervention.getInterventionObjects(),
            api.intervention.getInterventionTypes(),
        ])
            .then(([docs, redacs, approbs, fams, cssct, workInspection, perimeterList, objects, types]) => {
                setDocuments(docs.sort((a, b) => a.index - b.index))
                setRedacteurs(redacs.data.sort((a, b) => a.lastName.localeCompare(b.lastName)))
                setApprobateurs(approbs.data.sort((a, b) => a.lastName.localeCompare(b.lastName)))
                setCssctItems(cssct)
                setWiItems(workInspection)
                setPerimeters(perimeterList)
                setInterventionObjects(objects)
                setInterventionTypes(types)
                if (!!initialAmendment) {
                    setInitialValues(filterDocumentsIcp(initialValues, docs))
                    setStatus('fetched')
                } else {
                    fetchIcp(docs, approbs.data, fams, navigation)
                }
            })
            .catch(err => {
                Logger.error('Une erreur est survenue, on prend les valeurs stockées...', err)
                if (Platform.OS !== 'web') {
                    setOfflineDatas(true)
                    setDocuments(storeDocuments)
                    setRedacteurs(storeRedacteurs.sort((a, b) => a.lastName.localeCompare(b.lastName)))
                    setApprobateurs(storeApprobrateurs.sort((a, b) => a.lastName.localeCompare(b.lastName)))
                    setCssctItems(storeCssctItems)
                    setWiItems(storeWiItems)
                    setPerimeters(storePerimeters)
                    setInterventionObjects(storeInterventionObjects)
                    setInterventionTypes(storeInterventionTypes)
                    if (!!initialAmendment) {
                        setInitialValues(filterDocumentsIcp(initialValues, storeDocuments))
                        setStatus('fetched')
                    } else {
                        fetchIcp(storeDocuments, storeApprobrateurs, storeFamilies, navigation)
                    }
                } else {
                    setStatus('error')
                }
            })
        // eslint-disable-next-line
    }, [icpId])

    const handleGlobalSubmit = (values: Icp, setSubmitting: (isSubmitting: boolean) => void) => {
        const trimedValues = trimValues(values)
        const actionKey = !icpId ? 'Add' : approbation ? 'Approbation' : 'Edit'

        setSubmitting(true)
        confirmAlert(
            i18n,
            I18N_SCREEN_KEY,
            actionKey,
            () =>
                (!icpId
                    ? !!trimedValues.idParent
                        ? api.ppee.addAvenant(trimedValues.idParent, trimedValues)
                        : api.icp.addIcp(trimedValues)
                    : api.icp.editIcp({ ...trimedValues, id: icpId }, icpId)
                )
                    .then(newIcp => {
                        setInitialValues({
                            ...newIcp,
                        })
                        if (approbation && icpId) {
                            api.icp
                                .approbationIcp(icpId)
                                .then((values: Icp) => {
                                    navigationStore.actions.reset()
                                    setInitialValues({
                                        ...values,
                                        status: 'aApprouver',
                                    })
                                    basicAlert(
                                        i18n,
                                        I18N_SCREEN_KEY,
                                        actionKey,
                                        () => {
                                            setSubmitting(false)
                                            setApprobation(false)
                                        },
                                        'success',
                                        Theme,
                                    )
                                })
                                .catch(err => {
                                    basicAlert(
                                        i18n,
                                        I18N_SCREEN_KEY,
                                        actionKey,
                                        () => setSubmitting(false),
                                        'error',
                                        Theme,
                                    )
                                    setApprobation(false)
                                    Logger.error(err)
                                })
                        } else {
                            basicAlert(
                                i18n,
                                I18N_SCREEN_KEY,
                                actionKey,
                                () => {
                                    if (actionKey === 'Add') {
                                        navigation.pushFromRoot(
                                            '/icp/:icpId',
                                            {
                                                icpId: newIcp.id,
                                                pathParams: { icpId: newIcp.id },
                                            },
                                            true,
                                        )
                                    }
                                    setSubmitting(false)
                                    navigationStore.actions.reset()
                                },
                                'success',
                                Theme,
                            )
                        }
                    })
                    .catch(err => {
                        if (Platform.OS !== 'web') {
                            IcpSyncService.sendIcp(trimedValues).then(newIcpId => {
                                basicAlert(
                                    i18n,
                                    I18N_SCREEN_KEY,
                                    actionKey + 'Sync',
                                    () => {
                                        if (actionKey === 'Add') {
                                            navigation.pushFromRoot('/icp/:icpId', {
                                                icpId: newIcpId,
                                                pathParams: { icpId: newIcpId },
                                            })
                                        }
                                        setApprobation(false)
                                        setSubmitting(false)
                                        navigationStore.actions.reset()
                                    },
                                    'success',
                                    Theme,
                                )
                            })
                        } else {
                            basicAlert(i18n, I18N_SCREEN_KEY, actionKey, () => setSubmitting(false), 'error', Theme)
                            setApprobation(false)
                        }

                        Logger.error(err)
                    }),

            () => {
                setSubmitting(false)
                setApprobation(false)
            },
            Theme,
        )
    }

    const deleteIcp = (icpId: string) => {
        setIsDeleting(true)
        confirmAlert(
            i18n,
            I18N_SCREEN_KEY,
            'confirmDelete',
            () =>
                api.icp
                    .deleteIcp(icpId)
                    .then(() => {
                        Logger.info("L'icp a bien été supprimée..")
                        basicAlert(
                            i18n,
                            I18N_SCREEN_KEY,
                            'icpDeleted',
                            () => {
                                setIsDeleting(false)
                                navigation.back()
                            },
                            'success',
                            Theme,
                        )
                    })
                    .catch(() => {
                        Logger.info("L'icp n'a pas pu être supprimée..")
                        basicAlert(
                            i18n,
                            I18N_SCREEN_KEY,
                            'icpDeleted',
                            () => {
                                setIsDeleting(false)
                            },
                            'error',
                            Theme,
                        )
                    }),
            () => setIsDeleting(false),
            Theme,
        )
    }

    const handleSubmitWithoutAlert = (values: Icp, setSubmitting: (isSubmitting: boolean) => void): Promise<void> =>
        new Promise((resolve, reject) => {
            const trimedValues = trimValues(values)
            if (icpId) {
                api.icp
                    .editIcp({ ...trimedValues, id: icpId }, icpId)
                    .then((newIcp: Icp) => {
                        setInitialValues({ ...newIcp })
                        setSubmitting(false)
                        resolve()
                    })
                    .catch(err => {
                        Logger.error('Une erreur est survenue...', err)
                        if (err.message === '400 Bad email(s)') {
                            basicAlert(
                                i18n,
                                'components.addIcpContacts',
                                'sendingInvitationsWrongEmail',
                                () => {
                                    Logger.error(err)
                                },
                                'error',
                                Theme,
                            )
                        } else {
                            basicAlert(
                                i18n,
                                'screens.editIcpScreen',
                                'GlobalSaving',
                                () => {
                                    setSubmitting(false)
                                    Logger.error(err)
                                    reject()
                                },
                                'error',
                                Theme,
                            )
                        }
                    })
            } else {
                reject()
            }
        })

    const alertAfterApprobation = (
        status: 'approved' | 'refused',
        actionStatus: 'success' | 'error',
        icpId: string,
    ) => {
        basicAlert(
            i18n,
            I18N_SCREEN_KEY,
            status,
            () => {
                setApprobationInProgress(false)
                setApprobationModalOpened(false)
                if (actionStatus === 'success') {
                    if (status === 'approved') {
                        Platform.OS === 'web'
                            ? navigation.push('/ppee/:ppeeId', { pathParams: { ppeeId: icpId } })
                            : navigation.pushFromRoot('/ppee/:ppeeId', { ppeeId: icpId })
                    }
                }
            },
            actionStatus,
            Theme,
        )
    }

    const handleApprobation = (status: 'approved' | 'refused', icpId: string) => {
        setApprobationInProgress(true)
        confirmAlert(
            i18n,
            I18N_SCREEN_KEY,
            status,
            () => {
                ;(status === 'approved' ? api.icp.approveIcp(icpId) : api.icp.refuseIcp(icpId))
                    .then((nIcp: Icp) => {
                        setInitialValues(nIcp)
                        alertAfterApprobation(status, 'success', icpId)
                    })
                    .catch(er => alertAfterApprobation(status, 'error', icpId))
            },
            () => undefined,
            Theme,
        )
    }

    const renderRootScreen = (children: JSX.Element) => (
        <RootScreen
            status={status}
            customHeader={
                Platform.OS === 'web' ? (
                    undefined
                ) : (
                    <SwipeBar>
                        <SectionName numberOfLines={1} ellipsizeMode={'tail'}>
                            {i18n.t(
                                currentSection ? `components.${currentSection}.title` : 'screens.editIcpScreen.recap',
                            )}
                        </SectionName>
                        {currentSection && (
                            <BubblesContainer>
                                {sectionKeys.map(key => (
                                    <Bubble key={`bubble${key}`} selected={key === currentSection} />
                                ))}
                            </BubblesContainer>
                        )}
                    </SwipeBar>
                )
            }
            launchScrollToTop={launchScrollToTop}
            offlineDatas={offlineDatas}
        >
            {children}
        </RootScreen>
    )

    const hasSendToApproveButton = React.useMemo(
        () =>
            Platform.OS !== 'web' &&
            status === 'fetched' &&
            verificationFieldsRequired &&
            initialValues.status === 'aFinaliser',
        [status, initialValues, verificationFieldsRequired],
    )
    const hasApprobationButton = React.useMemo(
        () =>
            Platform.OS !== 'web' &&
            status === 'fetched' &&
            initialValues.status === 'aApprouver' &&
            !!user &&
            user.roleName === 'Approbateur' &&
            !initialValues.actors.representatives.find(
                actor => actor.approbation !== 'yes' && !actor.signature && actor.status === 'Présent',
            ),
        [initialValues, status, user],
    )
    const hasSaveButton = React.useMemo(() => Platform.OS !== 'web' && status === 'fetched', [status])
    const hasDeleteButton = React.useMemo(() => !!initialValues.id, [initialValues])

    const displayingFloatButtons = (
        values: Icp,
        canSave: boolean,
        canDelete: boolean,
        canSign: boolean,
        canSendApproval: boolean,
        canApprove: boolean,
    ): FloatButtonType[] => {
        const buttons: FloatButtonType[] = []

        if (canSave) {
            buttons.push('save_button')
        }
        if (canDelete) {
            buttons.push('preview_button')
            buttons.push('delete_button')
        }
        if (canSign) {
            buttons.push('signature_button')
        }
        if (canSendApproval) {
            buttons.push('send_approval_button')
        }
        if (canApprove) {
            buttons.push('approval_button')
        }
        return buttons
    }

    const renderFloatButtons = (
        buttonType: FloatButtonType,
        index: number,
        values: Icp,
        handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void,
    ) => {
        switch (buttonType) {
            case 'save_button':
                return (
                    <FloatButton
                        iconName="save"
                        onPress={handleSubmit}
                        size={20}
                        floatButtonIndex={index}
                        buttonText={i18n.t('screens.editIcpScreen.buttonText.save')}
                    />
                )
            case 'delete_button':
                return (
                    <FloatButton
                        iconName="trash"
                        onPress={() => initialValues.id && deleteIcp(initialValues.id)}
                        size={20}
                        floatButtonIndex={index}
                        buttonText={i18n.t('screens.editIcpScreen.buttonText.delete')}
                    />
                )
            case 'preview_button':
                return (
                    <FloatButton
                        iconName="eye"
                        onPress={() => createPreviewPdf(values, navigation)}
                        size={20}
                        floatButtonIndex={index}
                        buttonText={i18n.t('screens.editIcpScreen.buttonText.preview')}
                    />
                )
            case 'signature_button':
                return (
                    <FloatButton
                        iconName="signing"
                        onPress={() => {
                            setCurrentSection('actorsIcp')
                            setMenuMobileOpened(false)
                        }}
                        floatButtonIndex={index}
                        size={20}
                        buttonText={i18n.t('screens.editIcpScreen.companyValidation')}
                    />
                )
            case 'send_approval_button':
                return (
                    <FloatButton
                        iconName="sendToApprove"
                        onPress={() => {
                            setApprobation(true)
                            handleSubmit()
                        }}
                        floatButtonIndex={index}
                        size={20}
                        buttonText={i18n.t('screens.editIcpScreen.buttonText.sendToApprove')}
                    />
                )
            case 'approval_button':
                return (
                    <React.Fragment>
                        <FloatButton
                            iconName="checkmark"
                            onPress={() => setApprobationModalOpened(true)}
                            floatButtonIndex={index}
                            size={20}
                            buttonText={i18n.t('screens.editIcpScreen.buttonText.approve')}
                        />
                        <Modal visible={approbationModalOpened} onRequestClose={() => setApprobationModalOpened(false)}>
                            <ModalContent>
                                <ApprobationTitle>{i18n.t('screens.editIcpScreen.approbation.title')}</ApprobationTitle>
                                <ApprobationDescription>
                                    {i18n.t('screens.editIcpScreen.approbation.description')}
                                </ApprobationDescription>
                                <ApprobationButtons>
                                    <VerticalMarginView>
                                        <Button
                                            libelle={i18n.t('screens.editIcpScreen.approbation.approve')}
                                            onPress={() =>
                                                initialValues.id && handleApprobation('approved', initialValues.id)
                                            }
                                            status={approbationInProgress ? 'loading' : 'active'}
                                            buttonColor={Theme.colors.buttonApprobationColor}
                                        />
                                    </VerticalMarginView>
                                    <VerticalMarginView>
                                        <Button
                                            libelle={i18n.t('screens.editIcpScreen.approbation.refuse')}
                                            onPress={() =>
                                                initialValues.id && handleApprobation('refused', initialValues.id)
                                            }
                                            status={approbationInProgress ? 'loading' : 'active'}
                                            buttonColor={Theme.colors.buttonDisapprobationColor}
                                        />
                                    </VerticalMarginView>
                                </ApprobationButtons>
                            </ModalContent>
                        </Modal>
                    </React.Fragment>
                )
        }
    }

    return user && !canUserAddICP(user) ? (
        renderRootScreen(
            <ReadIcp
                values={initialValues}
                sectionsFilled={sectionsFilled}
                bubbleKeys={sectionKeys}
                onSectionChange={key => setCurrentSection(key)}
                perimeters={perimeters}
            />,
        )
    ) : (
        <Formik
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => handleGlobalSubmit(values, setSubmitting)}
        >
            {({ values, setFieldValue, handleSubmit, isSubmitting, setValues, setSubmitting }) => {
                // TODO trouver un autre moyen de gérer l'initialisatoin des values du formik
                React.useEffect(() => {
                    setValues(initialValues)
                    // eslint-disable-next-line
                }, [initialValues, setValues])

                React.useEffect(() => {
                    if (launchScrollToTop) {
                        setLaunchScrollToTop(false)
                    }
                })

                const hasSignatureButton = React.useMemo(
                    () => !!values.actors.representatives.find(a => !a.signature) && currentSection !== 'actorsIcp',
                    [values.actors.representatives],
                )

                const displayingButtons = React.useMemo(
                    () =>
                        displayingFloatButtons(
                            values,
                            hasSaveButton,
                            hasDeleteButton,
                            hasSignatureButton,
                            hasSendToApproveButton,
                            hasApprobationButton,
                        ),
                    [values, hasSignatureButton],
                )

                React.useEffect(() => {
                    if (values !== initialValues) {
                        navigationStore.actions.setAlert({
                            title: i18n.t('screens.editIcpScreen.alerts.unsavedTitle'),
                            content: i18n.t('screens.editIcpScreen.alerts.unsavedContent'),
                            confirm: i18n.t('screens.editIcpScreen.alerts.unsavedConfirm'),
                            cancel: i18n.t('screens.editIcpScreen.alerts.unsavedCancel'),
                        })
                    } else {
                        navigationStore.actions.reset()
                    }
                }, [values])

                return (
                    <React.Fragment>
                        {renderRootScreen(
                            <FormIcp
                                globalValues={values}
                                isSubmitting={isSubmitting}
                                initialValues={initialValues}
                                setSectionFilled={(key: SectionName, i18n) =>
                                    setSectionFilled(key, i18n, sectionsFilled)
                                }
                                sectionsFilled={sectionsFilled}
                                bubbleKeys={sectionKeys}
                                onSectionChange={key => setCurrentSection(key)}
                                handleSubmit={verif => {
                                    if (verif) {
                                        setApprobation(verif)
                                    }
                                    handleSubmit()
                                }}
                                handleSubmitWithoutAlert={() => handleSubmitWithoutAlert(values, setSubmitting)}
                                globalSetIcpFieldValue={setFieldValue}
                                documents={documents}
                                redacteurs={redacteurs}
                                approbateurs={approbateurs}
                                cssctItems={cssctItems}
                                wiItems={wiItems}
                                perimeters={perimeters}
                                icpId={icpId}
                                canSendEmail={
                                    !!values.planification.approbateur &&
                                    !!values.informations.description &&
                                    !!values.informations.startDate &&
                                    !!values.informations.endDate &&
                                    !!values.planification.date &&
                                    !!values.planification.place
                                }
                                setLaunchScrollToTop={setLaunchScrollToTop}
                                companies={values.actors.agencies.map(agency => agency.name)}
                                user={user}
                                handleApprobation={handleApprobation}
                                approbationInProgress={approbationInProgress}
                                interventionObjects={interventionObjects}
                                interventionTypes={interventionTypes}
                                deleteIcp={deleteIcp}
                                isDeleting={isDeleting}
                                currentSection={currentSection}
                            />,
                        )}
                        {Platform.OS !== 'web' && status === 'fetched' && (
                            <FloatButton
                                iconName={!menuMobileOpened ? 'settings' : 'cross'}
                                onPress={() => setMenuMobileOpened(!menuMobileOpened)}
                                size={20}
                            />
                        )}
                        {menuMobileOpened && (
                            <MenuOpenedBackground onPress={() => setMenuMobileOpened(false)}>
                                {displayingButtons.map((buttonType, index) =>
                                    renderFloatButtons(buttonType, index + 1, values, handleSubmit),
                                )}
                            </MenuOpenedBackground>
                        )}
                    </React.Fragment>
                )
            }}
        </Formik>
    )
}

const SwipeBar = styled(View)`
    padding-top: 6px;
    padding-bottom: 6px;
    padding-left: 12px;
    padding-right: 12px;
    background-color: ${props => props.theme.colors.mobileHeaderBackground};
    margin-top: 1px;
    align-items: center;
`
const SectionName = styled(Text)`
    ${props => props.theme.fonts.editIcpMobileStateBar};
    text-transform: uppercase;
    text-align: center;
`
const BubblesContainer = styled(View)`
    width: 100px;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 6px;
`
const Bubble = styled(View)<{ selected: boolean }>`
    height: 5px;
    width: 5px;
    border-radius: 20px;
    ${props =>
        (props.selected ? 'background-color: ' : 'border: 1px solid ') + props.theme.colors.editIcpSectionBubble};
`
// MODALE D'APPROBATION
const ModalContent = styled(View)`
    align-items: center;
    justify-content: space-around;
    background-color: ${props => props.theme.colors.documentsModalBackground};
    padding: 24px 24px 40px 24px;
`
const ApprobationTitle = styled(Text)`
    flex: 1;
    ${props => props.theme.fonts.approbationTitle};
    text-align: center;
`
const ApprobationDescription = styled(Text)`
    flex: 1;
    ${props => props.theme.fonts.approbationDescription};
    text-align: center;
`
const ApprobationButtons = styled(View)`
    flex: 1;
    flex-direction: column;
    justify-content: space-around;
`
const MenuOpenedBackground = styled(TouchableOpacity)`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(100, 100, 100, 0.5);
`
const VerticalMarginView = styled(View)`
    margin: 10px 0px;
`
