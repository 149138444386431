import * as React from 'react'

// Components

import { View, Platform, Text } from 'react-native'
import { Formik, FormikErrors } from 'formik'
import Container from '@components/icpFormContainer/icpFormContainer'
import TextInput from '@components/textInput/TextInput'
import Checkbox from '@components/checkbox/Checkbox'
import Checkboxes from '@components/checkbox/Checkboxes'
import SignaturePad from '@components/signaturePad/SignaturePad'
import AddIcpDocuments from '@components/addIcpDocuments/AddIcpDocuments'
import FieldInformation from '@components/fieldInformation/FieldInformation'

// Utils
import { produce } from 'immer'

// Store
import useI18n from '@store/i18n/useI18n'
import useReducer from '@store/useReducer'
import * as CustomInterfaceStore from '@store/customInterfaceStore'

// Style
import styled from '@styles/index'
import useTheme from '@styles/useTheme'

interface Props {
    initialValues: SiteIcp
    onValidate?: (isValid: boolean) => void
    globalSetIcpFieldValue: (value: SiteIcp) => void
}

export const trimSiteIcp = (values: SiteIcp): SiteIcp => ({
    ...values,
    installationsDescription: values.installationsDescription ? values.installationsDescription.trim() : undefined,
    disposalDescription: values.disposalDescription ? values.disposalDescription.trim() : undefined,
    otherMarkingHandler: values.otherMarkingHandler ? values.otherMarkingHandler.trim() : undefined,
})

export const validateSiteIcp = (
    values: SiteIcp,
    i18n: I18nState,
    onValidate?: (isValid: boolean) => void,
): FormikErrors<SiteIcp> => {
    const trimedValues = trimSiteIcp(values)
    const errors: FormikErrors<SiteIcp> = {}

    // eslint-disable-next-line
    if (trimedValues.needsMarking == undefined) {
        errors.needsMarking = i18n.t('screens.addUserAdminScreen.required')
    } else if (trimedValues.needsMarking) {
        if (!trimedValues.aprrMarkingHandler && !trimedValues.otherMarkingHandler) {
            errors.aprrMarkingHandler = i18n.t('screens.addUserAdminScreen.required')
        }
        if (!trimedValues.markingDescription) {
            errors.markingDescription = i18n.t('screens.addUserAdminScreen.required')
        }
    }

    if (!!onValidate) {
        onValidate(Object.keys(errors).length === 0)
    }

    return errors
}

export default (props: Props) => {
    const { initialValues, onValidate, globalSetIcpFieldValue } = props
    const i18n = useI18n()
    const [Theme] = useTheme()

    const [hasOtherHandler, setHasOtherHandler] = React.useState<boolean>(!!initialValues.otherMarkingHandler)

    const instanceName = useReducer(CustomInterfaceStore.store, s => s.name)

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={() => undefined} // On passe par le handleSubmit global sans validation
            validate={values => validateSiteIcp(values, i18n, onValidate)}
        >
            {({ values, errors, setFieldValue, isValid, setValues }) => {
                const globalSetFieldValue = (key: keyof SiteIcp, value: any) => {
                    globalSetIcpFieldValue(
                        produce(values, newValues => {
                            ;(newValues[key] as any) = value // as any pour corriger le cas des champs boolean qui posent problème
                        }),
                    )
                    setFieldValue(key, value)
                }
                const globalSetValues = (newValues: SiteIcp) => {
                    globalSetIcpFieldValue(newValues)
                    setValues(newValues)
                }
                return (
                    <Container title={i18n.t('components.siteIcp.title')} hasErrors={!isValid}>
                        <View>
                            <Section zIndex={0}>
                                <SectionTitle>{i18n.t('components.siteIcp.sectionsTitles.marking')}</SectionTitle>
                                <FieldContainer>
                                    <Checkboxes
                                        label={i18n.t('components.siteIcp.fields.needsMarking')}
                                        required={true}
                                        labels={[
                                            i18n.t('components.siteIcp.values.yes'),
                                            i18n.t('components.siteIcp.values.no'),
                                        ]}
                                        values={[
                                            // eslint-disable-next-line
                                            values.needsMarking == undefined
                                                ? ''
                                                : values.needsMarking
                                                ? i18n.t('components.siteIcp.values.yes')
                                                : i18n.t('components.siteIcp.values.no'),
                                        ]}
                                        onChange={value => {
                                            globalSetValues(
                                                value[0] === i18n.t('components.siteIcp.values.no')
                                                    ? {
                                                          ...values,
                                                          needsMarking: !value[0]
                                                              ? values.needsMarking
                                                              : value[0] === i18n.t('components.siteIcp.values.yes'),
                                                          aprrMarkingHandler: false,
                                                          otherMarkingHandler: undefined,
                                                          markingDescription: undefined,
                                                          markingDocument: undefined,
                                                          markingDrawing: undefined,
                                                      }
                                                    : {
                                                          ...values,
                                                          needsMarking: !value[0]
                                                              ? values.needsMarking
                                                              : value[0] === i18n.t('components.siteIcp.values.yes'),
                                                      },
                                            )
                                            if (value[0] === i18n.t('components.siteIcp.values.no')) {
                                                setHasOtherHandler(false)
                                            }
                                        }}
                                        radio={true}
                                        error={errors && errors.needsMarking}
                                    />
                                </FieldContainer>

                                {values.needsMarking && (
                                    <View>
                                        <FieldContainer>
                                            {errors && !!errors.aprrMarkingHandler && (
                                                <Error>{errors.aprrMarkingHandler}</Error>
                                            )}
                                            <MarkingHandlers error={errors && !!errors.aprrMarkingHandler}>
                                                <CheckboxContainer>
                                                    <Checkbox
                                                        label={i18n.t('components.siteIcp.values.aprr', {
                                                            name: instanceName,
                                                        })}
                                                        value={values.aprrMarkingHandler}
                                                        onCheck={() =>
                                                            globalSetFieldValue(
                                                                'aprrMarkingHandler',
                                                                !values.aprrMarkingHandler,
                                                            )
                                                        }
                                                    />
                                                </CheckboxContainer>
                                                <CheckboxContainer isLast={!hasOtherHandler}>
                                                    <Checkbox
                                                        label={i18n.t('components.siteIcp.values.other')}
                                                        value={hasOtherHandler}
                                                        onCheck={() => {
                                                            setHasOtherHandler(!hasOtherHandler)
                                                            if (hasOtherHandler) {
                                                                globalSetFieldValue('otherMarkingHandler', undefined)
                                                            }
                                                        }}
                                                    />
                                                </CheckboxContainer>

                                                {hasOtherHandler && (
                                                    <TextInput
                                                        value={values.otherMarkingHandler}
                                                        onTextChange={text =>
                                                            globalSetFieldValue('otherMarkingHandler', text)
                                                        }
                                                        placeholder={i18n.t('components.proceduresIcp.placeholder')}
                                                        customInputBackgroundColor={Theme.colors.riskFamilyItemInput}
                                                    />
                                                )}
                                            </MarkingHandlers>
                                        </FieldContainer>
                                        <FieldContainer>
                                            <TextInput
                                                value={values.markingDescription}
                                                label={i18n.t('components.siteIcp.fields.markingDescription')}
                                                onTextChange={text => globalSetFieldValue('markingDescription', text)}
                                                multiline={true}
                                                required={true}
                                                numberOfLines={4}
                                                error={errors && errors.markingDescription}
                                                placeholder={i18n.t(
                                                    'components.siteIcp.placeholders.markingDescription',
                                                )}
                                            />
                                        </FieldContainer>
                                        <FieldContainer>
                                            <SignaturePad
                                                label={i18n.t('components.siteIcp.fields.markingDrawing')}
                                                value={values.markingDrawing}
                                                onChange={signature => globalSetFieldValue('markingDrawing', signature)}
                                                withModal={true}
                                            />
                                        </FieldContainer>
                                        <FieldContainer>
                                            <FieldInformation label={'Document'} />
                                            <AddIcpDocuments
                                                values={!!values.markingDocument ? [values.markingDocument] : []}
                                                onChange={documents =>
                                                    globalSetFieldValue('markingDocument', documents[0])
                                                }
                                                maxDocuments={1}
                                            />
                                        </FieldContainer>
                                    </View>
                                )}
                            </Section>
                            <Section zIndex={0}>
                                <SectionTitle>{i18n.t('components.siteIcp.sectionsTitles.installation')}</SectionTitle>
                                <FieldContainer>
                                    <TextInput
                                        value={values.installationsDescription}
                                        label={i18n.t('components.siteIcp.fields.installationsDescription')}
                                        onTextChange={text => globalSetFieldValue('installationsDescription', text)}
                                        multiline={true}
                                        numberOfLines={4}
                                        error={errors && errors.installationsDescription}
                                        placeholder={i18n.t('components.siteIcp.placeholders.installationsDescription')}
                                        maxLength={255}
                                    />
                                </FieldContainer>
                                <FieldContainer>
                                    <TextInput
                                        value={values.disposalDescription}
                                        label={i18n.t('components.siteIcp.fields.disposalDescription')}
                                        onTextChange={text => globalSetFieldValue('disposalDescription', text)}
                                        multiline={true}
                                        numberOfLines={4}
                                        error={errors && errors.disposalDescription}
                                        placeholder={i18n.t('components.siteIcp.placeholders.disposalDescription')}
                                        maxLength={255}
                                    />
                                </FieldContainer>
                            </Section>
                        </View>
                    </Container>
                )
            }}
        </Formik>
    )
}

const Section = styled(View)<{ zIndex: number }>`
    z-index: ${props => props.zIndex};
    margin-bottom: ${Platform.OS === 'web' ? '24px' : '15px'};
`
const SectionTitle = styled(Text)`
    ${props => props.theme.fonts.icpFormTitle}
    font-family: Avenir-Book;
    margin-bottom: 20px;
`
const FieldContainer = styled(View)`
    margin-bottom: 24px;
`
const MarkingHandlers = styled(View)<{ error: boolean }>`
    padding-left: ${props => props.theme.constants.fieldPadding};
    padding-right: ${props => props.theme.constants.fieldPadding};
    padding-top: 10px;
    padding-bottom: 10px;
    background: white;
    ${props => props.error && 'border: ' + props.theme.colors.textInputBorderError + ' solid 1px;'}
`
const CheckboxContainer = styled(View)<{ isLast?: boolean }>`
    ${props => !props.isLast && 'margin-bottom: 10px;'}
`
const Error = styled(Text)<{ minHeight?: number }>`
    ${props => props.theme.fonts.fieldError}
    font-size: ${props => props.minHeight && props.minHeight * 0.5}px;
    padding-right: ${props => props.theme.constants.fieldPadding};
    margin-bottom: 8px;
    text-align: right;
`
